import React from 'react'
import ReactDOM from 'react-dom';
// eslint-disable-next-line no-unused-vars
import PropTypes from 'prop-types'
import {E_ToastStyle} from "../../../models/Models_Toast";
import {ExtendedTimeout, get} from "../../../utils/utils";
import CSBaseComponent from "../../CSBaseComponent";
import Fa from "../Icons/Fa";
import is from "../../../utils/is";

class ToastEntity extends CSBaseComponent {
	constructor(props) {
		super(props);

		this.state = {
			...super.state,
			progress: 0,
		};
	}

	componentDidMount() {
		const {toast} = this.props;
		this.timer = new ExtendedTimeout(
			()=>{
				this.removeToastInit();
			},
			toast.time,
			(report) => {
				this.setState({progress: report.alpha});
			},
			10
		);
	}

	componentWillUnmount() {
		this.timer.stop();
		delete this.timer;
		this.props.toast.parent.remove();
	}

	_renderIcon() {
		const {toast} = this.props;
		let icon = toast.icon;

		if (!icon) {
			switch (toast.style) {
				case E_ToastStyle.WARNING:
					icon = "exclamation-triangle";
					break;
				case E_ToastStyle.ERROR:
					icon = "times";
					break;
				case E_ToastStyle.SUCCESS:
					icon = "check";
					break;
				default:
					icon = "info";
			}
		}
		return icon && <Fa icon={icon} />
	}

	render() {
		const {toast} = this.props;

		return (
			<section
				ref={this.setRef}
				className={"toast"}
				onMouseEnter={()=>this.timer.pause()}
				onMouseLeave={()=>this.timer.start()}
				onClick={()=> {
					this.props.toast.action?.();
					this.removeToastInit()
				}}
				toast-style={toast.style}
			>
				<div className={"toast-container"}>
					{(toast.icon ||toast.style) && this._renderIcon()}
					<div>
						{
							is.string(toast.content) &&
							window.translator.translate(
								get(toast, "content.key", toast.content),
								get(toast, "content.options"),
								true
							)
							||
							toast.content
						}
					</div>
				</div>
				<progress value={this.state.progress || 0} />
			</section>
		);
	}

	removeToastInit() {
		const {toast} = this.props;
		toast.parent.classList.add("fade-out");
		setTimeout(()=>{
			toast.toaster.unmountToast(this);
			this.unmountToast();
		},300);
	}

	unmountToast() {
		ReactDOM.unmountComponentAtNode(this.props.toast.parent);
	}
}

ToastEntity.propTypes = {
	toast: PropTypes.object
};

ToastEntity.stateTypes = {
	progress: PropTypes.number
};

export default ToastEntity;
