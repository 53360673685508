import React from "react";
import PropTypes from "prop-types";
import Fa from "../../tools/Icons/Fa";
import { T_APP_ICONS } from "../../../models/constants/Constants_Shared";
import { Translate } from "react-localize-redux";
import StatisticsRevenue from "./StatisticsRevenue";

class StatisticsPage extends React.Component {
	constructor(props) {
		super(props);

		this.state = {};
	}

	render() {
		return (
			<section className={"statistics-page"}>
				<div className={"separator title"}>
					<label>
						<Fa icon={T_APP_ICONS.STATISTICS} />
						<Translate id={"statistics"}/>
					</label>
				</div>

				<StatisticsRevenue/>
			</section>
		)
	}
}

export default StatisticsPage;
