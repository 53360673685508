import {combineReducers} from 'redux';
import {Selector_Generic_Item_Edit_Reducer} from "../selectors/Selectors_Generic";
import {Selector_Service_Edit_Reducer} from "../selectors/Selectors_LocationService";
import authReducer from './authReducer';
import genericItemReducer from "./genericItemReducer";
import genericReducer from "./genericListingReducer";
import LocationReducer from './LocationReducer';
import {localizeReducer} from 'react-localize-redux';
import {Selector_CommunicationUnits_Edit_Reducer} from "../selectors/Selectors_CommunicationUnit";
import settingsReducer from "./settingsReducer";


export default combineReducers({
	auth: authReducer,
	localize: localizeReducer,
	locations: combineReducers({
		listing: genericReducer(`LOCATIONS`, `LOCATION`),
		item: LocationReducer,
		service: genericItemReducer(`SERVICE`, null, Selector_Service_Edit_Reducer),
		communicationUnits: combineReducers({
			listing: genericReducer(`COMMUNICATION_UNITS`, `COMMUNICATION_UNIT`),
			item: genericItemReducer(`COMMUNICATION_UNIT`, null, Selector_CommunicationUnits_Edit_Reducer)
		}),
	}),
	serviceTypes: combineReducers({
		listing: genericReducer(`SERVICE_TYPES`, `SERVICE_TYPE`),
		item: genericItemReducer(`SERVICE_TYPE`, null, Selector_Generic_Item_Edit_Reducer)
	}),
	serviceCategories: combineReducers({
		listing: genericReducer(`SERVICE_CATEGORIES`, `SERVICE_CATEGORY`),
		item: genericItemReducer(`SERVICE_CATEGORY`, null, Selector_Generic_Item_Edit_Reducer)
	}),
	users: combineReducers({
		listing: genericReducer(`USERS`, `USER`),
		item: genericItemReducer(`USER`, null, Selector_Generic_Item_Edit_Reducer)
	}),
	authorizations: combineReducers({
		listing: genericReducer(`AUTHORIZATIONS`, `AUTHORIZATION`),
		item: genericItemReducer(`AUTHORIZATION`, null, Selector_Generic_Item_Edit_Reducer)
	}),
	partners: combineReducers({
		listing: genericReducer(`PARTNERS`, `PARTNER`),
		item: genericItemReducer(`PARTNER`, null, Selector_Generic_Item_Edit_Reducer)
	}),
	vouchers: combineReducers({
		listing: genericReducer(`VOUCHERS`, `VOUCHER`),
		item: genericItemReducer(`VOUCHER`, null, Selector_Generic_Item_Edit_Reducer)
	}),
	tariffs: combineReducers({
		listing: genericReducer(`TARIFFS`, `TARIFF`),
		item: genericItemReducer(`TARIFF`, null, Selector_Generic_Item_Edit_Reducer)
	}),
	sessions: combineReducers({
		listing: genericReducer(`SESSIONS`, `SESSION`),
		item: genericItemReducer(`SESSION`, null, Selector_Generic_Item_Edit_Reducer)
	}),
	externalLocations: combineReducers({
		listing: genericReducer(`EXTERNAL_LOCATIONS`, `EXTERNAL_LOCATION`),
		item: genericItemReducer(`EXTERNAL_LOCATION`, null, Selector_Generic_Item_Edit_Reducer)
	}),
	feedback: combineReducers({
		listing: genericReducer(`FEEDBACK_LIST`, `FEEDBACK`),
		item: genericItemReducer(`FEEDBACK`, null, Selector_Generic_Item_Edit_Reducer)
	}),
	settings: settingsReducer,
	language: genericReducer(`LANGUAGE`),
});
