import { service_fetchLanguages } from "../../services/Service_Language";
import { Action_Generic_Fail, Action_Generic_Payload, Action_Generic_Type, GENERIC_FETCH_FULFILLED, GENERIC_FETCH_PENDING, GENERIC_FETCH_REJECTED } from "../actions/genericActions";
import { Thunk_Generic_Fetch_All } from "./Thunk_Generic";

export const Thunk_Language_Fetch_All = () => (
	Thunk_Generic_Fetch_All(
		null,
		service_fetchLanguages,
		() => Action_Generic_Type(`LANGUAGE_${GENERIC_FETCH_PENDING}`),
		(response) => Action_Generic_Payload(`LANGUAGE_${GENERIC_FETCH_FULFILLED}`, response),
		(error) => Action_Generic_Fail(`LANGUAGE_${GENERIC_FETCH_REJECTED}`, error)
	)
);

// export function Thunk_Language_Fetch_All() {
// 	return (dispatch) => {
// 		dispatch(Action_Generic_Type(`LANGUAGE_${GENERIC_FETCH_PENDING}`));
//
// 		service_fetchLanguages().then(response => {
// 			dispatch(Action_Generic_Payload(`LANGUAGE_${GENERIC_FETCH_FULFILLED}`, response));
// 		}, error => {
// 			dispatch(Action_Generic_Fail(`LANGUAGE_${GENERIC_FETCH_REJECTED}`, error));
// 		});
// 	}
// }
