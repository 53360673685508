import React from "react";
import PropTypes from "prop-types";
import InputBase from "./Base/InputBase";
import "../../../styles/components/CoordsInput.scss";
import ButtonsConstructor from "../ButtonsConstructor";
import { GOOGLE_API_KEY } from "../../../models/constants/Constants_Shared";
import { get } from "../../../utils/utils";
import CubeSpinner from "../LoadingIndicator/CubeSpinner";
import { errorHandler } from "../../../store/ResponseHandling";
import { T_SharedCustomErrorCodes } from "../../../models/Models_Shared";
import TextInput from "./TextInput";
import { Translate } from "react-localize-redux";

const MAP_ZOOM = 7;
const DEFAULT_POS = {lat: 49.687603470199775, lng: 15.064274920502529}

class CoordsInput extends InputBase {
	constructor(props) {
		super(props);

		let {lat, lng} = props.defaultValue || {};

		this.state = {
			...this.state,
			map: null,
			marker: null,
			data: {lat: parseFloat(lat) || DEFAULT_POS.lat, lng: parseFloat(lng) || DEFAULT_POS.lng},
		};
	}

	componentDidMount() {
		super.componentDidMount();

		this._initializeGoogleMapsAPI();
	}

	render() {
		const {className, style, allowManualInput} = this.props;
		const {data} = this.state;

		return (
			<section className={`coords-input ${className}`} style={style}>
				<div className={"map-frame"}>
					<CubeSpinner block={true} />
					<div name={"map"} ref={this.setRef} className={"map"}/>
				</div>
				<aside>
					<div className={"grid-table"}>
						<span/>
						<label style={{justifyContent: "flex-start"}}><Translate id={"latitude"}/></label>
						<TextInput
							readOnly={!allowManualInput}
							defaultValue={data.lat}
							path={"lat"}
							onModify={this._updateData.bind(this)}
							canInvalidate={true}
							modifyImmediately={true}
						/>

						<span/>
						<label style={{justifyContent: "flex-start"}}><Translate id={"longitude"}/></label>
						<TextInput
							readOnly={!allowManualInput}
							defaultValue={data.lng}
							path={"lng"}
							onModify={this._updateData.bind(this)}
							canInvalidate={true}
							modifyImmediately={true}
						/>
					</div>
					<ButtonsConstructor buttons={[
						{
							icon: "check",
							text: "apply",
							className: "fancy",
							action: () => this._submitCoords()
						}
					]} />
				</aside>
			</section>
		)
	}

	_initializeGoogleMapsAPI() {
		//If maps are already registered there is no point of doing so again...
		if(get(window, `google.maps`)) {
			this._initializeMap();
		} else {
			// Create the script tag, set the appropriate attributes
			let script = document.createElement('script');
			script.src = "https://maps.googleapis.com/maps/api/js?key=" + GOOGLE_API_KEY;
			script.defer = true;
			script.async = true;
			script.onload = () => this._initializeMap();

			// Append the 'script' element to 'head'
			document.head.appendChild(script);
		}
	}

	_initializeMap() {
		const {data} = this.state;
		const maps = window.google.maps;

		const map = new maps.Map(this.map, {
			zoom: MAP_ZOOM,
			center: data,
			streetViewControl: false
		});

		//Persistent marker
		const marker = new maps.Marker({
			position: data,
			map,
			draggable: true
		});

		map.addListener("click", googleEvent => {
			this._updateMarkerPosition(marker, googleEvent);
		});

		marker.addListener("drag", googleEvent => {
			this._updateMarkerPosition(marker, googleEvent, false);
		});

		marker.addListener("dragend", googleEvent => {
			this._updateMarkerPosition(marker, googleEvent);
		});

		this.setState({map, marker});

		/**
		 * Are position data not defined OR data == DEFAULT_POS but defaultValue != DEFAULT_POS.
		 *
		 * Should change viewport if input value (defaultValue) is undefined (DEFAULT_POS is used as a fallback value)
		 */
		if(
			(!data.lng && !data.lat) ||
			(this._isEqualPos(DEFAULT_POS, data) && !this._isEqualPos(DEFAULT_POS, this.props.defaultValue))
		) {
			this._getCurrentLocation();
		}
	}

	_getCurrentLocation() {
		if(navigator.geolocation) {
			navigator.geolocation.getCurrentPosition(pos => this._changeMapViewport(pos), () => {
				this._changeMapViewport({coords: {...DEFAULT_POS}});
			});
		} else {
			this._changeMapViewport({coords: {...DEFAULT_POS}});
			errorHandler({
				error: T_SharedCustomErrorCodes.GEOLOCATION_DISABLED,
				message: "Geolocation is not supported"
			}, "GEOLOCATION_NOT_SUPPORTED");
		}
	}

	_changeMapViewport(position) {
		const {map} = this.state;
		let pos = {
			lat: position.coords.latitude,
			lng: position.coords.longitude
		};

		if(map) {
			map.setCenter(pos);
			map.setZoom(MAP_ZOOM);
		}
	}

	_updateMarkerPosition(marker, googleEvent, pan = true) {
		const {map} = this.state;
		let pos = {
			lat: googleEvent.latLng.lat(),
			lng: googleEvent.latLng.lng()
		};

		pan && map.panTo(pos);
		marker.setPosition(pos);
		this.setState({data: pos});
	}

	_updateData(_, path, value) {
		const {marker, map} = this.state;

		this.setState(state => {
			let newData = {...state.data};
			newData[path] = value || null;

			if(marker) {
				let pos = {lat: parseFloat(newData.lat) || 0, lng: parseFloat(newData.lng) || 0}
				marker.setPosition(pos);
				map.panTo(pos);
			}

			return {
				data: newData
			}
		})
	}

	_submitCoords(data = this.state.data) {
		const {path, returnAsObject} = this.props;

		if(returnAsObject) {
			this.applyChange(path, data);
		} else {
			this.applyChange(`${path ? path + '.' : ''}longitude`, data.lng);
			this.applyChange(`${path ? path + '.' : ''}latitude`, data.lat);
		}
		window.modal.close();
	}

	_isEqualPos(pos1, pos2) {
		return (pos1 || {}).lat == (pos2 || {}).lat && (pos1 || {}).lng == (pos2 || {}).lng
	}

	static get propTypes() {
		return {
			...super.propTypes,
			returnAsObject: PropTypes.bool,
			allowManualInput: PropTypes.bool,
		}
	}

	static get stateTypes() {
		return {
			...super.stateTypes,
		}
	}

	static get defaultProps() {
		return {
			...super.defaultProps,
			returnAsObject: false,
			allowManualInput: true,
		}
	}
}

export default CoordsInput;
