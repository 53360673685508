import React from "react";
import ItemSelectInputBase from "../../shared/Input/Base/ItemSelectInputBase";
import LocationSelect from "./LocationSelect";
import {resolveItemName} from "../../../utils/utils";

class LocationSelectInput extends React.Component {
	render() {
		return <ItemSelectInputBase
			modalContent={<LocationSelect/>}
			onLabel={item => resolveItemName(item)}
			{...this.props}
		/>
	}
}

export default LocationSelectInput;
