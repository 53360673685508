import { Selector_Generic_Item_Root } from "./Selectors_Generic";

export const Selector_Partners_Root = state => {
	return state.partners;
};

export const Selector_Partners_Listing_Root = state => {
	return Selector_Partners_Root(state).listing;
};

export const Selector_Partners_List_Data = (state) => {
	return Selector_Partners_Listing_Root(state).data;
};

export const Selector_Partner_Root = state => {
	return Selector_Generic_Item_Root(Selector_Partners_Root, state);
};

export const Selector_Partner_Data = state => {
	return Selector_Partner_Root(state).data;
};

export const Selector_Partner_Edit = state => {
	return Selector_Partner_Root(state).edit;
};
