import React from "react";
import ItemSelectInputBase from "../../../../../shared/Input/Base/ItemSelectInputBase";
import LocationStorageServiceSelect from "./LocationStorageServiceSelect";
import {resolveItemName} from "../../../../../../utils/utils";

class LocationStorageServiceSelectInput extends React.Component {
	render() {
		return <ItemSelectInputBase
			modalContent={<LocationStorageServiceSelect/>}
			onLabel={item => resolveItemName(item)}
			{...this.props}
		/>
	}
}

export default LocationStorageServiceSelectInput;
