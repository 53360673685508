import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { Translate } from "react-localize-redux";
import is from "../../../utils/is";
import TextInput from "../../shared/Input/TextInput";
import ToggleButton from "../../shared/Input/ToggleButton";
import Fa from "../Icons/Fa";
import { getModifiedState, resolveDynamicComponent } from "../../../utils/utils";

class FiltersModal extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			...this.state,
			data: {},
		};
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		if(!is.equal(prevState.data, this.state.data)) {
			this.props.onFiltersModify(this.state.data);
		}
	}

	_renderFilterElement(filter) {
		const {type, key} = filter;

		switch (type) {
			case `text`:
				return (
					<TextInput
						defaultValue={filter.queryValue || ''}
						path={`data.${key}`}
						onModify={this._modifyFilterData.bind(this)}
						canInvalidate={true}
					/>
				);
			case `toggle`:
				return (
					<ToggleButton
						defaultValue={filter.queryValue || false}
						path={`data.${key}`}
						onModify={this._modifyFilterData.bind(this)}
						canInvalidate={true}
					/>
				);
			default:
				return React.cloneElement(filter.type, {
					defaultValue: filter.queryValue || '',
					path: `data.${key}`,
					onModify: this._modifyFilterData.bind(this),
					canInvalidate: true
				});
		}
	}

	_renderFilter(i, filter) {
		if(filter.hidden) {return null}

		return (
			<Fragment key={i}>
				{resolveDynamicComponent(filter.icon, <Fa icon={filter.icon} />) || <span/>}
				<label>{resolveDynamicComponent(filter.label, <Translate id={filter.label} />)}</label>
				{this._renderFilterElement(filter)}
			</Fragment>
		)
	}

	render() {
		const {filters} = this.props;

		return (
			<section>
				<div className={"grid-table grid-icon-auto-1fr"}>
					{
						filters.map(
							(filter, i) => this._renderFilter(i, filter)
						)
					}
				</div>
			</section>
		);
	}

	_modifyFilterData(type, path, value) {
		this.setState(state => getModifiedState(state, type, path, value));
	}

	static get propTypes() {
		return {
			...super.propTypes,
			/**
			 * @type {T_FilterItemModel}
			 */
			filters: PropTypes.array.isRequired, //Filter items

			onFiltersModify: PropTypes.func.isRequired
		}
	}

	static get stateTypes() {
		return {
			...super.stateTypes,
			data: PropTypes.object,
		}
	}

	static get defaultProps() {
		return {
			...super.defaultProps,
			filters: [],
		}
	}
}

export default FiltersModal;
