import React from "react";
import ItemSelectInputBase from "../../shared/Input/Base/ItemSelectInputBase";
import PartnerSelect from "./PartnerSelect";
import { resolveItemName } from "../../../utils/utils";

class PartnerSelectInput extends React.Component {
	render() {
		return <ItemSelectInputBase
			modalContent={<PartnerSelect/>}
			onLabel={item => resolveItemName(item)}
			{...this.props}
		/>
	}
}

export default PartnerSelectInput;
