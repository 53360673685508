import { Action_Generic_Edit_Modify, Action_Generic_Fail, Action_Generic_Payload, Action_Generic_Type, GENERIC_EDIT_CANCEL, GENERIC_EDIT_FULFILLED, GENERIC_EDIT_INIT, GENERIC_EDIT_MODIFY, GENERIC_EDIT_PENDING, GENERIC_EDIT_REJECTED, GENERIC_FETCH_FULFILLED, GENERIC_FETCH_PENDING, GENERIC_FETCH_REJECTED, GENERIC_ITEM_CLEAR } from "../genericActions";

const REDUCER_ITEM_NAME = `SERVICE`;

export const FETCH_SERVICE_PENDING = `${REDUCER_ITEM_NAME}_${GENERIC_FETCH_PENDING}`;
export const FETCH_SERVICE_FULFILLED = `${REDUCER_ITEM_NAME}_${GENERIC_FETCH_FULFILLED}`;
export const FETCH_SERVICE_REJECTED = `${REDUCER_ITEM_NAME}_${GENERIC_FETCH_REJECTED}`;

// EDIT SERVICE ACTION NAMES
export const EDIT_SERVICE_INIT = `${REDUCER_ITEM_NAME}_${GENERIC_EDIT_INIT}`;
export const EDIT_SERVICE_PENDING = `${REDUCER_ITEM_NAME}_${GENERIC_EDIT_PENDING}`;
export const EDIT_SERVICE_FULFILLED = `${REDUCER_ITEM_NAME}_${GENERIC_EDIT_FULFILLED}`;
export const EDIT_SERVICE_REJECTED = `${REDUCER_ITEM_NAME}_${GENERIC_EDIT_REJECTED}`;
export const EDIT_SERVICE_CANCELED = `${REDUCER_ITEM_NAME}_${GENERIC_EDIT_CANCEL}`;
export const EDIT_SERVICE_MODIFY = `${REDUCER_ITEM_NAME}_${GENERIC_EDIT_MODIFY}`;

export const EDIT_SERVICE_CLEAR = `${REDUCER_ITEM_NAME}_${GENERIC_ITEM_CLEAR}`

//UTILITY ACTION NAMES
export const E_ServiceActions = {
	DOOR_OPEN: "SERVICE_DOOR_OPEN",
	TAKE_PHOTO: "SERVICE_TAKE_PHOTO",
};

export const Action_Service_Fetch_Pending = () => Action_Generic_Type(FETCH_SERVICE_PENDING);
export const Action_Service_Fetch_Fulfilled = (data, ...props) => Action_Generic_Payload(FETCH_SERVICE_FULFILLED, data, ...props);
export const Action_Service_Fetch_Rejected = (error, ...props) => Action_Generic_Fail(FETCH_SERVICE_REJECTED, error, ...props);

export const Action_Service_Edit_Init = (initData, ...props) => Action_Generic_Payload(EDIT_SERVICE_INIT, initData, ...props);
export const Action_Service_Edit_Cancel = () => Action_Generic_Type(EDIT_SERVICE_CANCELED);
export const Action_Service_Edit_Modify = (type, path, change, ...props) => (
	Action_Generic_Edit_Modify(EDIT_SERVICE_MODIFY, type, path, change, ...props)
);
export const Action_Service_Edit_Pending = () => Action_Generic_Type(EDIT_SERVICE_PENDING);
export const Action_Service_Edit_Fulfilled = (data, ...props) => Action_Generic_Payload(EDIT_SERVICE_FULFILLED, data, ...props);
export const Action_Service_Edit_Rejected = (error, ...props) => Action_Generic_Fail(EDIT_SERVICE_REJECTED, error, ...props);

export const Action_Service_Clear = () => Action_Generic_Type(EDIT_SERVICE_CLEAR);
