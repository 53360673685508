import { service_fetchFeedback_Item, service_fetchFeedback_List, service_insertFeedback, service_updateFeedback } from "../../services/Service_Feedback";
import { Action_Feedback_Edit_Fulfilled, Action_Feedback_Edit_Pending, Action_Feedback_Edit_Rejected, Action_Feedback_Fetch_Fulfilled, Action_Feedback_Fetch_Pending, Action_Feedback_Fetch_Rejected, Action_Feedback_List_Fetch_Fulfilled, Action_Feedback_List_Fetch_Pending, Action_Feedback_List_Fetch_Rejected } from "../actions/feedback/feedbackActions";
import { Thunk_Generic_Edit_Insert, Thunk_Generic_Edit_Save, Thunk_Generic_Edit_Update, Thunk_Generic_Fetch_Item } from "./Thunk_Generic";
import { Selector_FeedbackItem_Edit } from "../selectors/Selectors_Feedback";

export function Thunk_Feedback_Fetch_All(queryString) {
	return (dispatch) => {
		dispatch(Action_Feedback_List_Fetch_Pending());

		service_fetchFeedback_List(queryString).then(response => {
			dispatch(Action_Feedback_List_Fetch_Fulfilled(response));
		}, error => {
			dispatch(Action_Feedback_List_Fetch_Rejected(error));
		})
	}
}

export const Thunk_Feedback_Fetch = (itemID) => (
	Thunk_Generic_Fetch_Item(
		itemID,
		service_fetchFeedback_Item,
		Action_Feedback_Fetch_Pending,
		Action_Feedback_Fetch_Fulfilled,
		Action_Feedback_Fetch_Rejected
	)
);

/**
 * Thunk Feedback Insert
 * ---
 * @returns {Promise}
 * @constructor
 */
export const Thunk_Feedback_Insert = () => (
	Thunk_Generic_Edit_Insert(
		service_insertFeedback,
		Action_Feedback_Edit_Pending,
		Action_Feedback_Edit_Fulfilled,
		Action_Feedback_Edit_Rejected,
		Selector_FeedbackItem_Edit
	)
);

export const Thunk_Feedback_Update = () => (
	Thunk_Generic_Edit_Update(
		service_updateFeedback,
		Action_Feedback_Edit_Pending,
		Action_Feedback_Edit_Fulfilled,
		Action_Feedback_Edit_Rejected,
		Selector_FeedbackItem_Edit
	)
);

export const Thunk_Feedback_Save = () => (
	Thunk_Generic_Edit_Save(
		Selector_FeedbackItem_Edit,
		Action_Feedback_Edit_Pending,
		Thunk_Feedback_Insert,
		Thunk_Feedback_Update
	)
);
