import React from 'react';
import PropTypes from 'prop-types';
import {Translate} from "react-localize-redux";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {T_APP_ICONS} from "../../../models/constants/Constants_Shared";
import {Selector_Authorizations_Listing_Root} from "../../../store/selectors/Selectors_Authorizations";
import {Thunk_Authorizations_Fetch_All} from "../../../store/thunk/Thunk_Authorizations";
import {resolveItemName} from "../../../utils/utils";
import CSBaseComponent from "../../CSBaseComponent";
import {withRouter} from 'react-router-dom';
import routes from '../../../routes/routes';
import ListingPage from "../../shared/Listing/ListingPage";
import TableListing from "../../shared/Listing/TableListing";
import TableRow from "../../shared/Listing/TableRow";
import TableColumn from "../../shared/Listing/TableColumn";
import ItemRedirect from "../../shared/ItemRedirect";
import {T_RestrictionPresets} from "../../../models/Models_Restrictions";
import {T_SortType} from "../../../models/Models_Shared";

class AuthorizationsList extends CSBaseComponent {
	_renderTemplate(authorization, i, headerItems) {
		return (
			<TableRow key={i} href={`${routes.AUTHORIZATIONS}/${authorization.id}`} headerItems={headerItems}>
				<TableColumn column={"email"}>{authorization.userAccount?.email ?? authorization.username}</TableColumn>
				<TableColumn column={"partner"}>
					{
						authorization.partner &&
						<ItemRedirect href={`${routes.PARTNERS}/${authorization.partner.id}`}>
							{resolveItemName(authorization.partner)}
						</ItemRedirect>
						||
						'-'
					}
				</TableColumn>
				<TableColumn column={"limitedTo"}>
					{
						authorization.location &&
						<ItemRedirect href={`${routes.LOCATIONS}/${authorization.location.id}`}>
							{resolveItemName(authorization.location)}
						</ItemRedirect>
						||
						authorization.service &&
						<span>{authorization.service.physicalReference ?? `#${authorization.service.id}`}</span>
						||
						authorization.serviceType &&
						<ItemRedirect href={`${routes.SERVICE_TYPES}/${authorization.serviceType.id}`}>
							{resolveItemName(authorization.serviceType)}
						</ItemRedirect>
						||
						authorization.serviceCategory &&
						<ItemRedirect href={`${routes.SERVICE_CATEGORIES}/${authorization.serviceCategory.id}`}>
							{resolveItemName(authorization.serviceCategory)}
						</ItemRedirect>
						||
						'-'
					}
				</TableColumn>
				<TableColumn column={"authorizedBy"}>
					{authorization.authorizedBy?.email}
				</TableColumn>
			</TableRow>
		);
	}

	render() {
		const {Thunk_Authorizations_Fetch_All} = this.props;

		return (
			<section className={"authorizations-list"}>
				<ListingPage
					titleIcon={T_APP_ICONS.AUTHORIZATIONS}
					titleText={"serviceAuthorizations"}
					titleLeftContent={routes.AUTHORIZATIONS}
					subscriberThunk={Thunk_Authorizations_Fetch_All}
					subscriberRootSelector={Selector_Authorizations_Listing_Root}
					headerItems={{
						email: {
							field: "username",
							sort: T_SortType.TEXT
						},
						partner: {
							field: "partner.id",
							sort: T_SortType.TEXT,
							restricted: T_RestrictionPresets.PARTNERS,
							filter: false,
						},
						limitedTo: {
							sort: false,
							filter: false,
						},
						authorizedBy: {
							field: "authorizedBy.email"
						}
					}}
					views={[
						{
							view: "table",
							default: true,
							button: {
								icon: "table",
								tippy: <Translate id={"viewMode_table"} />
							},
							content: <TableListing className={"stripes"} itemTemplate={this._renderTemplate.bind(this)} />
						},
					]}
				/>
			</section>
		);
	}

	static get propTypes() {
		return {
			Thunk_Authorizations_Fetch_All: PropTypes.func.isRequired,
		}
	}
}

const mapDispatchToProps = dispatch => (
	bindActionCreators({
		Thunk_Authorizations_Fetch_All,
	}, dispatch)
);

export default connect(null, mapDispatchToProps)(withRouter(AuthorizationsList));
