import { service_fetchUnits } from "../../../services/Service_Locations";
import { Action_Generic_Edit_Modify, Action_Generic_Fail, Action_Generic_Payload, Action_Generic_Type } from "../genericActions";

// FETCH UNITS ACTION NAME
export const FETCH_UNITS = 'FETCH_UNITS';
export const FETCH_UNITS_PENDING = 'FETCH_UNITS_PENDING';
export const FETCH_UNITS_FULFILLED = 'FETCH_UNITS_FULFILLED';
export const FETCH_UNITS_REJECTED = 'FETCH_UNITS_REJECTED';


// ACTION GENERATORS
export const fetchUnitsAction = (locationID) => ({
	type: FETCH_UNITS,
	payload: service_fetchUnits(locationID).then(data => {
		return data.content;
	})
});


/* === === === === === === ===
        Unit Actions
 === === === === === === === */
export const EDIT_UNIT_INIT = 'EDIT_UNIT_INIT';
export const EDIT_UNIT_MODIFY = 'EDIT_UNIT_MODIFY';
export const EDIT_UNIT_PENDING = 'EDIT_UNIT_PENDING';
export const EDIT_UNIT_FULFILLED = 'EDIT_UNIT_FULFILLED';
export const EDIT_UNIT_REJECTED = 'EDIT_UNIT_REJECTED';

export const Action_Unit_Init = (data, ...props) => Action_Generic_Payload(EDIT_UNIT_INIT, data, ...props);
export const Action_Unit_Send = () => Action_Generic_Type(EDIT_UNIT_PENDING);
export const Action_Unit_Send_Success = (modificationType, path, modification, ...props) => (
	Action_Generic_Edit_Modify(EDIT_UNIT_FULFILLED, modificationType, path, modification, ...props)
);
export const Action_Unit_Send_Fail = (error, ...props) => Action_Generic_Fail(EDIT_UNIT_REJECTED, error, ...props);
export const Action_Unit_Modify = (modificationType, path, modification, ...props) => (
	Action_Generic_Edit_Modify(EDIT_UNIT_MODIFY, modificationType, path, modification, ...props)
);
