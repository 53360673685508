import React from 'react';
import ReactDOM from 'react-dom/client';
import {Provider} from 'react-redux';
import configureStore from './store/store';
import App from './App';
import './styles/app.scss';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import {E_ToastStyle} from "./models/Models_Toast";

serviceWorkerRegistration.register({
	onUpdate: (worker) => {
		window.toaster.showToast({
			icon: "download",
			style: E_ToastStyle.INFO,
			content: "appUpdateFound",
			action: () => {
				worker.postMessage({type: 'SKIP_WAITING'});

				window.location.reload();
			},
			time: 10000,
		});
	}
});

let store = configureStore();

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
	<Provider store={store}>
		<App store={store} />
	</Provider>
);
