import React from "react";
import PropTypes from "prop-types";
import { T_blockModel } from "../../../../../models/Models_StorageBox";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Translate } from "react-localize-redux";
import { T_APP_ICONS } from "../../../../../models/constants/Constants_Shared";
import Fa from "../../../../tools/Icons/Fa";
import { formatAsDate } from "../../../../../utils/utils";
import { Thunk_Block_Remove, Thunk_Block_Update } from "../../../../../store/thunk/Thunk_StorageBlock";
import { E_ButtonPresets } from "../../../../../models/Models_ButtonPresets";

class StorageBlockDetail extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			...this.state,
			block: props.block,
		};
	}

	componentDidMount() {
		const {Thunk_Block_Remove, Thunk_Block_Update, block, path} = this.props;

		window.modal.modifyActiveContent({
			header: <label><Translate id={"block_detail"}/></label>,
			actionButtons: [
				{
					preset: E_ButtonPresets.REMOVE,
					action: () => {
						Thunk_Block_Remove(block.id);
						window.modal.close();
					}
				},
				{ //Prepared for block edit
					icon: T_APP_ICONS.SAVE,
					tippy: <Translate id={"save"}/>,
					action: () => Thunk_Block_Update(`${path}.blocks.#${block.id}`, this.state.block),
					hidden: true //Remove this when block update will be relevant
				},
			]
		})
	}

	render() {
		const {className, style} = this.props;
		const {block} = this.state;

		return (
			<section className={className} style={style}>
				<div className={"grid-table grid-icon-auto-1fr"}>
					<Fa icon={T_APP_ICONS.CREATED_ON}/>
					<label><Translate id={"createdOn"}/></label>
					<span>{formatAsDate(block.createdOn)}</span>
				</div>
			</section>
		)
	}

	static get propTypes() {
		return {
			...super.propTypes,
			block: PropTypes.object.isRequired,
			path: PropTypes.string,
		}
	}

	static get stateTypes() {
		return {
			...super.stateTypes,
		}
	}

	static get defaultProps() {
		return {
			...super.defaultProps,
			block: T_blockModel
		}
	}
}

const mapDispatchToProps = dispatch => (
	bindActionCreators({
		Thunk_Block_Remove,
		Thunk_Block_Update
	}, dispatch)
);

export default connect(null, mapDispatchToProps)(StorageBlockDetail);
