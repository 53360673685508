// SELECTORS are used to retrieve data from the state tree.

// Return root service object
import { Selector_Locations_Root } from "./Selectors_Location";

export function Selector_Service_Root(state) {
	return Selector_Locations_Root(state).service;
}

export function Selector_Service(state) {
	return Selector_Service_Root(state).data;
}

export function Selector_Service_Edit(state) {
	return Selector_Service_Root(state).edit;
}

export function Selector_Service_Edit_Reducer(state) {
	return state.edit;
}
