const DISPOSITION_TYPE_REGEXP = /^([!#$%&'*+.0-9A-Z^_`a-z|~-]+)[\x09\x20]*(?:$|;)/; // eslint-disable-line no-control-regex
const QESC_REGEXP = /\\([\u0000-\u007f])/g; // eslint-disable-line no-control-regex
const PARAM_REGEXP = /;[\x09\x20]*([!#$%&'*+.0-9A-Z^_`a-z|~-]+)[\x09\x20]*=[\x09\x20]*("(?:[\x20!\x23-\x5b\x5d-\x7e\x80-\xff]|\\[\x20-\x7e])*"|[ ;%,/?:@&=$!*'()#+.0-9A-Z^_`a-z|~-]+)[\x09\x20]*/g; // eslint-disable-line no-control-regex
const EXT_VALUE_REGEXP = /^([A-Za-z0-9!#$%&+\-^_`{}~]+)'(?:[A-Za-z]{2,3}(?:-[A-Za-z]{3}){0,3}|[A-Za-z]{4,8}|)'((?:%[0-9A-Fa-f]{2}|[A-Za-z0-9!#$&+.^_`|~-])+)$/;
const HEX_ESCAPE_REPLACE_REGEXP = /%([0-9A-Fa-f]{2})/g;

/**
 * RegExp to match non-latin1 characters.
 * @private
 */

const NON_LATIN1_REGEXP = /[^\x20-\x7e\xa0-\xff]/g;

/**
 * Class for parsed Content-Disposition header for v8 optimization
 *
 * @public
 * @param {string} type
 * @param {object} parameters
 * @constructor
 */

function ContentDisposition (type, parameters) {
	this.type = type;
	this.parameters = parameters;
}

/**
 * Percent decode a single character.
 *
 * @param {string} str
 * @param {string} hex
 * @return {string}
 * @private
 */

function pdecode (str, hex) {
	return String.fromCharCode(parseInt(hex, 16));
}

/**
 * Get ISO-8859-1 version of string.
 *
 * @param {string} val
 * @return {string}
 * @private
 */

function getLatin1 (val) {
	// simple Unicode -> ISO-8859-1 transformation
	return String(val).replace(NON_LATIN1_REGEXP, '?');
}

function decodeField (str) {
	let match = EXT_VALUE_REGEXP.exec(str);

	if (!match) {
		throw new TypeError('invalid extended field value');
	}

	let [_, charset, encoded] = match;
	charset = charset.toLowerCase();

	// to binary string
	let binary = encoded.replace(HEX_ESCAPE_REPLACE_REGEXP, pdecode);

	if (charset === 'iso-8859-1') {
		return getLatin1(binary);
	}
	return decodeURIComponent(encoded);
}

export function parseDispositionHeader (string) {
	if (!string || typeof string !== 'string') {
		throw new TypeError('argument string is required');
	}

	let match = DISPOSITION_TYPE_REGEXP.exec(string);

	if (!match) {
		throw new TypeError('invalid type format');
	}

	// normalize type
	let index = match[0].length;
	let type = match[1].toLowerCase();

	let key; //globalize for lower scope
	let value; //globalize for lower scope
	let names = [];
	let params = {};

	// calculate index to start at
	index = PARAM_REGEXP.lastIndex = match[0].substr(-1) === ';' ? index - 1 : index;

	// match parameters
	while ((match = PARAM_REGEXP.exec(string))) {
		if (match.index !== index) {
			throw new TypeError('invalid parameter format');
		}

		let [str, key, value] = match;
		index += str.length;
		key = key.toLowerCase();

		if (names.indexOf(key) !== -1) {
			throw new TypeError('invalid duplicate parameter')
		}

		names.push(key);

		// property with '*' has higher priority
		if (key.indexOf('*') + 1 === key.length) {
			// decode extended value
			key = key.slice(0, -1);
			value = decodeField(value);

			// overwrite existing value
			params[key] = value;
			continue
		}

		if (typeof params[key] === 'string') {
			continue;
		}

		if (value[0] === '"') {
			// remove quotes and escapes
			value = value.substr(1, value.length - 2).replace(QESC_REGEXP, '$1');
		}

		params[key] = value;
	}

	return new ContentDisposition(type, params);
}
