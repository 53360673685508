import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import PropTypes from 'prop-types';
import { withLocalize } from 'react-localize-redux';
// noinspection ES6CheckImport
import { renderToStaticMarkup } from 'react-dom/server';

import routes, { DEFAULT_ROUTE } from '../routes/routes';

import Navigation from './Navigation/Navigation';
import ExternalLocationsBrowser from "./pages/Locations/ExternalLocations/ExternalLocationsBrowser";
import LocationsBrowser from "./pages/Locations/LocationsBrowser";
import FeedbackBrowser from "./pages/Feedback/FeedbackBrowser";
import HomePage from './pages/HomePage';
import NotificationsPage from './pages/Notifications/NotificationsPage';
import PartnersBrowser from "./pages/Partners/PartnersBrowser";
import ServiceCategoriesBrowser from "./pages/ServiceCategories/ServiceCategoriesBrowser";
import ServiceTypesBrowser from "./pages/ServiceTypes/ServiceTypesBrowser";
import SessionsBrowser from "./pages/Sessions/SessionsBrowser";
import StatisticsPage from "./pages/Statistics/StatisticsPage";
import TariffsBrowser from "./pages/Tariffs/TariffsBrowser";

import enTranslations from '../translations/en.translations.json';
import csTranslations from '../translations/cs.translations.json';

import LoginPage from '../components/pages/LoginPage';
import UsersBrowser from "./pages/Users/UsersBrowser";
import VouchersBrowser from "./pages/Vouchers/VouchersBrowser";
import Database from "./tools/Database";
import Modal from "./tools/Modal";
import Toaster from "./tools/toasts/Toaster";
import Translator from "./tools/Language/Translator";
import ConfigurationsBrowser from "./pages/Configurations/ConfigurationsBrowser";
import NotFound from "./pages/NotFound";
import CubeSpinner from "./shared/LoadingIndicator/CubeSpinner";
import { css } from "../utils/utils";
import { T_SharedReduxPropTypes } from "../models/Models_Shared";
import DeliveryPage from "./pages/Delivery/DeliveryPage";
import PlaygroundPage from "./pages/PlaygroundPage";
import { Selector_Auth_Root } from "../store/selectors/Selectors_Auth";
import AuthorizeTrigger from "./tools/AuthorizeTrigger";
import PasswordRecovery from "./pages/Users/Password/PasswordRecovery";
import VerifyEmail from "./pages/Users/VerifyEmail";
import SettingsPage from "./pages/Settings/SettingsPage";
import AuthorizationsBrowser from "./pages/Authorizations/AuthorizationssBrowser";


class Main extends React.Component {
	constructor(props) {
		super(props);

		this.props.initialize({
			languages: [
				{ name: 'Čeština', code: 'cs' },
				{ name: 'English', code: 'en' }
			],
			options: { renderToStaticMarkup }
		});

		this.props.addTranslationForLanguage(csTranslations, 'cs');
		this.props.addTranslationForLanguage(enTranslations, 'en');

		const __findLanguage = () => {
			let localStored = localStorage.getItem("lang");
			if(localStored) {
				return localStored;
			}
			if (navigator.language.match("cs") != null || navigator.language.match("sk")) {
				return "cs";
			}
			return "en";
		}

		this.props.setActiveLanguage(__findLanguage());
	}

	_renderLoggedInContent() {
		return (
			<main>
				{
					window.location.pathname === routes.BASE && DEFAULT_ROUTE !== routes.BASE && <Redirect to={DEFAULT_ROUTE} />
				}
				<Navigation />
				<Switch>
					<Route exact path={routes.BASE} component={HomePage} />
					<Route exact path={routes.PLAYGROUND} component={PlaygroundPage} />
					{/* External locations section must be declared before the locations, so the /location/external won't load location with ID "external"! */}
					<Route path={routes.EXTERNAL_LOCATIONS} component={ExternalLocationsBrowser} />
					<Route path={routes.LOCATIONS} component={LocationsBrowser} />
					<Route path={routes.USERS} component={UsersBrowser} />
					<Route path={routes.AUTHORIZATIONS} component={AuthorizationsBrowser} />
					<Route path={routes.PARTNERS} component={PartnersBrowser} />
					<Route path={routes.VOUCHERS} component={VouchersBrowser} />
					<Route path={routes.SESSIONS} component={SessionsBrowser} />
					<Route path={routes.NOTIFICATIONS} component={NotificationsPage} />
					<Route path={routes.TARIFFS} component={TariffsBrowser} />
					<Route path={routes.SERVICE_TYPES} component={ServiceTypesBrowser} />
					<Route path={routes.SERVICE_CATEGORIES} component={ServiceCategoriesBrowser} />
					<Route path={routes.FEEDBACK} component={FeedbackBrowser} />
					<Route path={routes.STATISTICS} component={StatisticsPage} />
					<Route path={routes.SETTINGS} component={SettingsPage} />
					<Route path={routes.CONFIGURATION} component={ConfigurationsBrowser} />
					<Route component={NotFound} />
				</Switch>
			</main>
		)
	}

	render() {
		const { loggedIn, initialized } = this.props;

		return (
			<BrowserRouter>
				<Fragment>
					<Toaster max={5} />
					<Translator />
					<Modal />
					<Database />
					<Switch>
						<Route path={routes.DELIVERY} render={props => <DeliveryPage {...props}/>}/>
						<Route path={routes.PASSWORD_RESET} render={props => <PasswordRecovery {...props}/>}/>
						<Route path={routes.VERIFY_EMAIL} render={props => <VerifyEmail {...props}/>}/>
						<Route>
							<Fragment>
								<AuthorizeTrigger/>
								{
									initialized &&
									<Fragment>
										{
											!loggedIn &&
											<LoginPage />
											||
											this._renderLoggedInContent()
										}
									</Fragment>
									||
									<CubeSpinner style={css(`position: fixed; top: 50%; left: 50%; transform: translate(-50%, -50%); margin: 0`)} block={true} />
								}
							</Fragment>
						</Route>
					</Switch>
				</Fragment>
			</BrowserRouter>
		);
	}
}

Main.propTypes = {
	...T_SharedReduxPropTypes,
	initialize: PropTypes.func.isRequired,
	addTranslationForLanguage: PropTypes.func.isRequired,
	loggedIn: PropTypes.bool.isRequired,
};

const mapStateToProps = state => {
	const { loggedIn, initialized } = Selector_Auth_Root(state);
	return { loggedIn, initialized };
};

export default connect(mapStateToProps)(withLocalize(Main));
