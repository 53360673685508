export const T_navigationRoutes = {
	BASE: `/`,
	LOCATIONS: `/locations`,
	EXTERNAL_LOCATIONS: `/locations/external`,
	SESSIONS: `/sessions`,
	NOTIFICATIONS: `/notifications`,
	TARIFFS: `/tariffs`,
	USERS: `/users`,
	AUTHORIZATIONS: `/authorizations`,
	FEEDBACK: `/feedback`,
	PARTNERS: `/partners`,
	VOUCHERS: `/vouchers`,
	STATISTICS: `/statistics`,
	CONFIGURATION: `/configuration`,
	SERVICE_TYPES: `/configuration/service/types`,
	SERVICE_CATEGORIES: `/configuration/service/categories`,
	SETTINGS: `/configuration/settings`,
	DELIVERY: `/delivery`,
	PLAYGROUND: `/playground`,
	PASSWORD_RESET: `/password/reset`,
	VERIFY_EMAIL: `/email/verify`,
};

export const DEFAULT_ROUTE = T_navigationRoutes.LOCATIONS;

export default T_navigationRoutes;
