import React from "react";
import TextInput from "./TextInput";
import PropTypes from "prop-types";
import {Translate} from "react-localize-redux";

export const PIN_MIN_LENGTH = 6;

export class PinInput extends React.Component {
	render() {
		const {className, style, defaultValue, onModify, path} = this.props;

		return (
			<section className={`pin-input-wrapper ${className}`} style={style}>
				<TextInput
					defaultValue={defaultValue}
					onModify={onModify}
					path={path}
					behavior={{pattern: "^\\d+$"}}
				/>

				<div className={"input-error-message"} data-soft-hidden={!(defaultValue?.length > 0 && defaultValue?.length < PIN_MIN_LENGTH)}>
					<Translate id={"pinValidationError_minLength"}/>
				</div>
			</section>
		)
	}

	static get propTypes() {
		return {
			defaultValue: PropTypes.string,
			onModify: PropTypes.func,
			path: PropTypes.string,
		}
	}
}
