import { getUrlEncodedHeaders } from './apiServices/headers';
import { POST, sendRequest } from "./apiServices/apiConnector";

export const service_authenticate = (data) => (
	sendRequest({
		url: `/auth/login`,
		method: POST,
		headers: getUrlEncodedHeaders(),
		body: data
	}).then(response => response)
);
