import PropTypes from 'prop-types';
import React from 'react';
import { Translate } from "react-localize-redux";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { T_APP_ICONS } from "../../../models/constants/Constants_Shared";
import { T_SortType } from "../../../models/Models_Shared";
import routes from '../../../routes/routes';
import { Selector_ServiceCategories_Listing_Root } from "../../../store/selectors/Selectors_ServiceCategories";
import { Thunk_ServiceCategories_Fetch_All, Thunk_ServiceCategory_Insert } from "../../../store/thunk/Thunk_ServiceCategories";
import { TranslateEntry } from "../../../utils/localeUtils";
import CSBaseComponent from "../../CSBaseComponent";
import TableListing from "../../shared/Listing/TableListing";
import ListingPage from "../../shared/Listing/ListingPage";
import TableRow from "../../shared/Listing/TableRow";
import TableColumn from "../../shared/Listing/TableColumn";
import { resolveItemName } from "../../../utils/utils";

class ServiceCategoriesList extends CSBaseComponent {
	constructor(props) {
		super(props);

		this.state = {
			...super.state,
		};
	}

	_renderTemplate(serviceCategory, i, headerItems) {
		return (
			<TableRow key={i} href={`${routes.SERVICE_CATEGORIES}/${serviceCategory.id}`} headerItems={headerItems}>
				<TableColumn column={"name"}>{serviceCategory.name || <Translate id={"value_unknown"} />}</TableColumn>
				<TableColumn column={"titles"}>{<TranslateEntry entry={serviceCategory.titles} fallback={'-'} />}</TableColumn>
				<TableColumn column={"partner"}>{resolveItemName(serviceCategory.partner, undefined, undefined, '-')}</TableColumn>
				<TableColumn column={"descriptions"}>{<TranslateEntry entry={serviceCategory.descriptions} fallback={'-'} />}</TableColumn>
			</TableRow>
		);
	}

	render() {
		const {Thunk_ServiceCategories_Fetch_All} = this.props;

		return (
			<section className={"service-category-list"}>
				<ListingPage
					section={"globalConfiguration"}
					titleIcon={T_APP_ICONS.SERVICE_CATEGORIES}
					titleText={"serviceCategories"}
					titleLeftContent={routes.SERVICE_CATEGORIES}
					subscriberThunk={Thunk_ServiceCategories_Fetch_All}
					subscriberRootSelector={Selector_ServiceCategories_Listing_Root}
					headerItems={{
						name: {
							sort: T_SortType.TEXT
						},
						titles: {
							label: "title",
							field: "titles.text",
							sort: T_SortType.TEXT,
						},
						partner: {
							field: "partner.name",
							sort: T_SortType.TEXT,
							restricted: {
								permissions: {partners: "READ"},
							},
						},
						descriptions: {
							label: "description",
							field: "descriptions.text",
							sort: T_SortType.TEXT,
						},
					}}
					views={[
						{
							view: "table",
							default: true,
							button: {
								icon: "table",
								tippy: <Translate id={"viewMode_table"} />
							},
							content: <TableListing className={"stripes"} itemTemplate={this._renderTemplate} />
						},
					]}
				/>
			</section>
		);
	}
}

ServiceCategoriesList.propTypes = {
	Thunk_ServiceCategories_Fetch_All: PropTypes.func.isRequired,
};

const mapDispatchToProps = dispatch => (
	bindActionCreators({
		Thunk_ServiceCategory_Insert,
		Thunk_ServiceCategories_Fetch_All,
	}, dispatch)
);

export default connect(null, mapDispatchToProps)(ServiceCategoriesList);
