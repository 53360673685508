// eslint-disable-next-line no-unused-vars
import { DELETE, formatQueryString, POST, PUT, sendRequest } from "./apiServices/apiConnector";

export const service_fetchServiceTypes = (queryString = null) => {
	return sendRequest(`/service/type/${formatQueryString(queryString)}`).then(response => response.json());
};

export const service_fetchServiceType = (serviceTypeID) => {
	return sendRequest(`/service/type/${serviceTypeID}`).then(response => response.json());
};

export const service_insertServiceType = (body) => {
	return sendRequest([`/service/type/`, POST, body]).then(response => response.json());
};

export const service_updateServiceType = (serviceTypeID, body) => {
	return sendRequest([`/service/type/${serviceTypeID}`, PUT, body]).then(response => response.json());
};

export const service_deleteServiceType = (serviceTypeID) => {
	return sendRequest([`/service/type/${serviceTypeID}`, DELETE]).then(response => response);
};
