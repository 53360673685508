import { E_ToastStyle } from "../models/Models_Toast";
import { E_APIServiceActions, T_APP_ICONS } from "../models/constants/Constants_Shared";
import { generalizeResponseContent } from "../utils/localeUtils";
import { T_SharedCustomErrorCodes } from "../models/Models_Shared";
import { get } from "../utils/utils";

export const getIconFromError = (error) => {
	error = error || {};
	if(error == "TypeError: Failed to fetch") {
		return "cs-wifi-slash";
	}

	switch (error.status) {
		case 400: //Bad Request
			return "dizzy";
		case 401:
			return "lock"
		case 403: //Insufficient permission
		case 511:
			return "user-secret";
		case 404: //Not found
			return "question-circle";
		case 408: //Timed out
			return "stopwatch";
		case 500:
		case 501:
		case 502:
		case 503:
			return "server";
		case 412:
			return "hand-middle-finger";
		case T_SharedCustomErrorCodes.GEOLOCATION_DISABLED:
			return "cs-map-marker-slash";
		default:
			return "times-circle";
	}
};

export const willAlwaysFailError = (error) => {
	switch (get(error, `status`)) {
		case 408:
			return false;
		default:
			return true;
	}
}

export const errorHandler = (error, type, customProps = {}, suppressToast = false) => {
	let action = customProps.action || {};

	if(process.env.NODE_ENV !== "production" && !localStorage.getItem("silenceErrorResponse")) {
		console.error(error);
		console.log(error, type, action, suppressToast);
	}
	/*TODO: add handle service not available*/

	if(!suppressToast && !customProps.suppressToast) {
		window.toaster.showToast({
			style: E_ToastStyle.ERROR,
			content: generalizeResponseContent(`error_` + type, error, type, action.type),
			icon: getIconFromError(error)
		})
	}
};

export const successHandler = (data = null, success, suppressToast = false) => {
	let action = success.action || success;

	const __getIconForType = (type) => {
		switch (type) {
			case E_APIServiceActions.ITEM_FETCH:
				return "download";
			case E_APIServiceActions.ITEM_INSERT:
				return "upload";
			case E_APIServiceActions.ITEM_UPDATE:
				return "pen-square";
			case E_APIServiceActions.ITEM_DELETE:
				return T_APP_ICONS.REMOVE;
			default:
				return "check";
		}
	}

	if(!suppressToast) {
		window.toaster.showToast({
			style: E_ToastStyle.SUCCESS,
			content: `success_` + action.type,
			icon: __getIconForType(action.type)
		})
	}
}
