import React from 'react';
import { Switch, Route } from 'react-router-dom';
import routes from '../../../routes/routes';
import TariffDetail from "./TariffDetail";
import TariffsList from "./TariffsList";

class TariffsBrowser extends React.Component {

	constructor(props) {
		super(props);
	}

	render() {
		return (
			<Switch>
				<Route exact path={routes.TARIFFS}>
					<TariffsList />
				</Route>
				<Route path={`${routes.TARIFFS}/:tariffID`} render={props => <TariffDetail tariffID={parseInt(props.match.params.tariffID)} />} />
			</Switch>
		);
	}
}

export default TariffsBrowser;
