import React from 'react';
import { Switch, Route } from 'react-router-dom';
import routes from '../../../routes/routes';
import SessionDetail from "./SessionDetail";
import SessionsList from "./SessionsList";

class VoucherBrowser extends React.Component {

	constructor(props) {
		super(props);
	}

	render() {
		return (
			<Switch>
				<Route exact path={routes.SESSIONS}>
					<SessionsList />
				</Route>
				<Route path={`${routes.SESSIONS}/:sessionID`} render={props => <SessionDetail sessionID={parseInt(props.match.params.sessionID)} />} />
			</Switch>
		);
	}
}

export default VoucherBrowser;
