export const E_CommunicationUnitType = {
	QUIDO: "QUIDO",
	VIRTUAL: "VIRTUAL",
	MODERN_EXPO: "MODERN_EXPO",
	MODERN_EXPO_V2: "MODERN_EXPO_V2",
	SDS: "SDS",
	KERONG_SBU: "KERONG_SBU",
	KERONG_BU: "KERONG_BU",
};

/**
 * ```
 *  name: {String}
 *  paymentTerminalIpAddress: {String}
 *  type: {E_CommunicationUnitType}
 *  unitIpAddress: {String}
 *  unitSerialNumber: {String}
 * ```
 */
export const T_communicationUnitModel = {
	name: '',
	paymentTerminalIpAddress: '',
	type: E_CommunicationUnitType.QUIDO,
	unitIpAddress: '',
	unitSerialNumber: '',
	cameraSupported: false,
};
