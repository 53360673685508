import React from "react";
import ExpandContainer, { E_ExpandMode } from "../../../shared/ExpandContainer";
import { withRouter } from "react-router-dom";
import is from "../../../../utils/is";
import StorageServiceDetail from "./Details/StorageService/StorageServiceDetail";
import routes from "../../../../routes/routes";
import { T_SharedRouterPropTypes } from "../../../../models/Models_Shared";

class StorageServiceExpandContainer extends React.Component {
	constructor(props) {
		super(props);
	}

	render() {
		const {match, history} = this.props;
		let {serviceID, locationID, unitID} = match.params;
		serviceID = parseInt(serviceID);

		return (
			<ExpandContainer
				simpleExpansionToggle={is.valid(serviceID)}
				onExpandModeChange={expandMode => {
					if(expandMode == E_ExpandMode.COLLAPSED && is.valid(serviceID)) {
						history.push(`${routes.LOCATIONS}/${locationID}/${unitID}`);
					}
				}}
			>
				{
					is.valid(serviceID) &&
					<StorageServiceDetail
						serviceID={serviceID}
					/>
				}
			</ExpandContainer>
		)
	}

	static get propTypes() {
		return {
			...super.propTypes,
			...T_SharedRouterPropTypes,
		}
	}

	static get stateTypes() {
		return {
			...super.stateTypes,
		}
	}

	static get defaultProps() {
		return {
			...super.defaultProps,
		}
	}
}

export default withRouter(StorageServiceExpandContainer);
