import { Selector_Generic_Item_Root } from "./Selectors_Generic";

export const Selector_Sessions_Root = state => {
	return state.sessions;
};

export const Selector_Sessions_Listing_Root = state => {
	return Selector_Sessions_Root(state).listing;
};

export const Selector_Sessions_Listing_Data = state => {
	return Selector_Sessions_Listing_Root(state).data;
};

export const Selector_Session_Root = state => {
	return Selector_Sessions_Root(state).item;
};

export const Selector_Session_Data = state => {
	return Selector_Session_Root(state).data;
};

export const Selector_Session_Edit = state => {
	return Selector_Session_Root(state).edit;
};
