import React from "react";
import PropTypes from "prop-types";
import logo from '../../../images/cubesave-logo.png';
import Toolbar from "../../shared/Toolbar";
import TextInput from "../../shared/Input/TextInput";
import {Translate} from "react-localize-redux";
import QS from "query-string";
import "../../../styles/pages/Delivery/DeliveryPage.scss";
import {service_fetchSessionFromPin, service_openBoxFromPin} from "../../../services/Service_Sessions";
import {T_SharedReduxPropTypes} from "../../../models/Models_Shared";
import CubeSpinner from "../../shared/LoadingIndicator/CubeSpinner";
import ErrorBlock from "../../shared/Error/ErrorBlock";
import is from "../../../utils/is";
import ButtonsConstructor from "../../shared/ButtonsConstructor";
import Address from "../../shared/Address";
import {T_LocationAddress} from "../../../models/Models_Location";
import Fa from "../../tools/Icons/Fa";
import {E_ToastStyle} from "../../../models/Models_Toast";
import {E_DeliveryType, E_SessionType} from "../../../models/Models_Session";
import {errorHandler} from "../../../store/ResponseHandling";
import DeliveryPageMarkAsDelivered from "./DeliveryPageMarkAsDelivered";

const defaultFetchState = {
	session: {},
	fetched: false,
	fetching: false,
	failed: false,
};

class DeliveryPage extends React.Component {
	constructor(props) {
		super(props);

		let {pin} = QS.parse(props.location.search);

		this.state = {
			...this.state,
			pin,
			inputPin: pin,
			...defaultFetchState,
			sending: false,
		};
	}

	componentDidMount() {
		this._getSession();
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		if(!is.equal(prevProps.location.search, this.props.location.search)) {
			this.setState({pin: QS.parse(this.props.location.search).pin});
		}

		if(!is.equal(prevState.pin, this.state.pin)) {
			this._getSession();
		}
	}

	_renderSessionDetail() {
		const {session, sending} = this.state;

		if(session.state === "COMPLETED") {
			return (
				<div className={"completed"}>
					<Fa icon={"check"} />
					<h1><Translate id={"sessionCompleted"}/></h1>
				</div>
			)
		}

		let addressComponents = {
			from: <pre>{session.pickupAddress}</pre>,
			to: <Address
				address={session.location.address}
				model={T_LocationAddress} onModify={() => null}
				showGoogleMapsLink={true}
			/>
		}

		//Swap from - to addresses
		if(session.deliveryType === E_DeliveryType.COURIER_PICK_UP) {
			let buff = addressComponents.from;
			addressComponents.from = addressComponents.to;
			addressComponents.to = buff;
		}

		return (
			<div className={"session-detail"} data-delivery-type={session.deliveryType}>
				<div className={"address"}>
					{
						this._isForCourier() &&
						<div className={"pickup"}>
							<h2>
								<Translate id={"pickupAddress"} />:
							</h2>
							{addressComponents.from}
						</div>
					}

					<div className={"delivery"}>
						<h2><Translate id={this._isForCourier() ? "deliveryAddress" : "pickupAddress"}/>:</h2>
						{addressComponents.to}
					</div>
				</div>

				{
					session.notes &&
					<div className={"notes-wrapper"}>
						<hr/>

						<div className={"notes"}>
							<h2>
								<Translate id={"notes"} />:
							</h2>
							<pre>{session.notes}</pre>
						</div>
					</div>
				}

				<ButtonsConstructor
					className={"action-buttons"}
					wrapInNav={true}
					buttons={
						this._isForCourier() && [
							{
								text: "openBox",
								icon: "lock-open",
								action: () => this._openBox(),
								confirm: <span><Fa icon={"lock-open"} /> <Translate id={"openBox"} /></span>,
							},
							{
								text: session.deliveryType === E_DeliveryType.COURIER_PICK_UP ? "markAsPickedUpByCourier" : "markAsDelivered",
								icon: "cs-box-check",
								action: () => this._openMarkAsDeliveredDialog(),
							}
						]
						||
						[
							{
								text: "endSessionPickup",
								icon: "cs-box-check",
								action: () => this._openMarkAsDeliveredDialog(),
							},
						]
					}
					sharedProps={{className: "fancy", disabled: sending}}
				/>
			</div>
		)
	}

	render() {
		const {className, style, history} = this.props;
		const {pin, fetching, failed, inputPin, fetched} = this.state;

		return (
			<section className={`delivery-page ${className}`} style={style}>
				<Toolbar
					backButton={false}
					actionButtons={null}
					content={
						<header>
							<div className={"image-wrapper"}>
								<img src={logo} className={"invert-logo"} alt="CUBESAVE" />
							</div>

							<form onSubmit={e => {
								e.preventDefault();
								this.setState({pin: inputPin});
								inputPin != pin && history.push(`/delivery?pin=${inputPin}`);
							}}>
								<label><Translate id={"session_pin"}/>: </label>
								<TextInput
									defaultValue={pin}
									behavior={{maxLength: 6}}
									modifyImmediately={true}
									onModify={(t,p, inputPin) => this.setState({inputPin})}
								/>
								<button className={"fancy"}>
									<Translate id={"apply"}/>
								</button>
							</form>
						</header>
					}
				/>

				{fetching && <CubeSpinner/>}
				{
					failed &&
					<ErrorBlock
						title={"invalidPin"}
					/>
				}
				{fetched && this._renderSessionDetail()}
				<footer>
					{/*<LanguageToggle/>*/}
					<div>
						<a href={"tel:+420 601 110 110"}>+420 601 110 110</a>
						<span>|</span>
						<a href={"mailto:info@cubesave.cz"}>info@cubesave.cz</a>
					</div>
				</footer>
			</section>
		)
	}

	_isForCourier() {
		const {session} = this.state;
		return session.sessionType == E_SessionType.DELIVERY_COURIER;
	}

	_getSession() {
		const {pin} = this.state;

		if(pin) {
			this.setState({
				...defaultFetchState,
				fetching: true
			});
			service_fetchSessionFromPin({pin}).then(
				session => this.setState({session, fetched: true}),
				error => this.setState({failed: true})
			).finally(() => {
				this.setState({fetching: false});
			})
		}
	}

	_openMarkAsDeliveredDialog() {
		const {session, pin} = this.state;

		window.modal.open({
			body: <DeliveryPageMarkAsDelivered
				session={session}
				pin={pin}
				onCompleted={session => this.setState({session})}
			/>
		});
	}

	_openBox() {
		const {pin} = this.state;

		this.setState({sending: true});
		service_openBoxFromPin(pin).then(() => {
			window.toaster.showToast({
				content: "service_lock_open_success",
				style: E_ToastStyle.SUCCESS,
				icon: "lock-open"
			});
		}, error => {
			errorHandler(error, "OPEN_BOX_REJECTED");
		}).finally(() => {
			this.setState({sending: false});
		});
	}

	static get stateTypes() {
		return {
			...T_SharedReduxPropTypes,
			pin: PropTypes.string,
			inputPin: PropTypes.string,
			sending: PropTypes.bool,
		}
	}

	static get defaultProps() {
		return {
			...super.defaultProps,
		}
	}
}

export default DeliveryPage;
