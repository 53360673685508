import React from 'react'
import PropTypes from 'prop-types'
import {Translate, withLocalize} from "react-localize-redux";
import {connect} from "react-redux";
import {T_unitModel} from "../../../../../models/Models_StorageBox";
import {E_Visibility} from "../../../../../models/Models_Location";
import {Selector_Location_Edit_Unit} from "../../../../../store/selectors/Selectors_Location";
import TextInput from "../../../../shared/Input/TextInput";
import ToggleButton from "../../../../shared/Input/ToggleButton";
import Fa from "../../../../tools/Icons/Fa";
import Dropdown from "../../../../shared/Input/Dropdown";

class StorageUnitDetail extends React.Component {
	constructor(props) {
		super(props);

		this.state = {};
	}

	render() {
		const {Action_Unit_Modify} = this.props;

		return (
			<section>
				<div className={"grid-table grid-icon-auto-1fr"}>
					<Fa icon={"tag"} />
					<label><Translate id={"name"} /></label>
					<TextInput
						defaultValue={this.props.unit.name || ''}
						path={`name`}
						onModify={(type, path, value) => Action_Unit_Modify(type, path, value)}
					/>

					<Fa icon={"power-off"} />
					<label><Translate id={"state_ENABLED"} /></label>
					<ToggleButton
						defaultValue={this.props.unit.enabled || false}
						path={`enabled`}
						onModify={(type, path, value) => Action_Unit_Modify(type, path, value)}
					/>

					<Fa icon={"eye"} />
					<label><Translate id={"visibility"} /></label>
					<Dropdown
						options={Object.values(E_Visibility)}
						path={`visibility`}
						onModify={(type, path, value) => Action_Unit_Modify(type, path, value)}
						onLabel={option => window.translator.translate(`visibility_${option}`)}
						defaultValue={this.props.unit.visibility || E_Visibility.VISIBLE}
					/>
				</div>
			</section>
		)
	}
}

const mapStateToProps = state => {
	return {
		unit: Selector_Location_Edit_Unit(state)
	};
};

StorageUnitDetail.propTypes = {
	path: PropTypes.string.isRequired,
	unit: PropTypes.object.isRequired,
	Action_Unit_Modify: PropTypes.func.isRequired
};

StorageUnitDetail.stateTypes = {};

StorageUnitDetail.defaultProps = {
	unit: T_unitModel
};

export default connect(mapStateToProps)(withLocalize(StorageUnitDetail));
