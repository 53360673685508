import React from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {Thunk_Principal_Fetch} from "../../store/thunk/Thunk_Auth";
import {bindActionCreators} from "redux";

class AuthorizeTrigger extends React.Component {
	componentDidMount() {
		this.props.Thunk_Principal_Fetch();
	}

	render() {
		return null;
	}

	static get propTypes() {
		return {
			Thunk_Principal_Fetch: PropTypes.func.isRequired,
		}
	}
}

const mapDispatchToProps = dispatch => (
	bindActionCreators({
		Thunk_Principal_Fetch
	}, dispatch)
);

export default connect(null, mapDispatchToProps)(AuthorizeTrigger);
