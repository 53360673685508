import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';
import customIcons from './CustomIcons';
import { get } from '../../../utils/utils';

class Fa extends React.Component {
	constructor(props) {
		super(props);
		this.storedIcons = get(library, "definitions.fas", {});
		this.fasValues = Object.values(fas);

		this._tryAddIcon(props.icon);
	}

	_tryAddIcon(iconName) {
		if (this.storedIcons[iconName]) { return; }
		let icon = this.fasValues.find(icon => {
			return icon.iconName == iconName;
		});

		icon && library.add(icon);
	}

	shouldComponentUpdate(nextProps, nextState, nextContext) {
		if (nextProps.icon != this.props.icon) {
			this._tryAddIcon(nextProps.icon);
			return true;
		}
		return false;
	}

	render() {
		if (this.props.custom || this.props.icon.match(new RegExp(/^(cs-)/))) {
			return customIcons(`${this.props.icon}`.replace(/^(cs-)/, ''), this.props.className || '', this.props.style) || null;
		}
		return (
			<FontAwesomeIcon style={this.props.style} className={`fa ${this.props.className}`} icon={this.props.icon} />
		);
	}
}

Fa.propTypes = {
	icon: PropTypes.string,
	custom: PropTypes.bool,
	className: PropTypes.string,
	style: PropTypes.object,
};

export default Fa;
