import { service_deleteServiceCategory, service_fetchServiceCategories, service_fetchServiceCategory, service_insertServiceCategory, service_updateServiceCategory } from "../../services/Service_ServiceCategories";
import { Action_ServiceCategories_Fetch_Fulfilled, Action_ServiceCategories_Fetch_Pending, Action_ServiceCategories_Fetch_Rejected, Action_ServiceCategory_Edit_Fulfilled, Action_ServiceCategory_Edit_Pending, Action_ServiceCategory_Edit_Rejected, Action_ServiceCategory_Fetch_Fulfilled, Action_ServiceCategory_Fetch_Pending, Action_ServiceCategory_Fetch_Rejected } from "../actions/configuration/serviceCategoriesActions";
import { Selector_ServiceCategory_Edit } from "../selectors/Selectors_ServiceCategories";
import { Thunk_Generic_Edit_Insert, Thunk_Generic_Edit_Save, Thunk_Generic_Edit_Update, Thunk_Generic_Fetch_All, Thunk_Generic_Fetch_Item, Thunk_Generic_Remove } from "./Thunk_Generic";

export const Thunk_ServiceCategories_Fetch_All = queryString => (
	Thunk_Generic_Fetch_All(
		queryString,
		service_fetchServiceCategories,
		Action_ServiceCategories_Fetch_Pending,
		Action_ServiceCategories_Fetch_Fulfilled,
		Action_ServiceCategories_Fetch_Rejected
	)
)

export const Thunk_ServiceCategory_Insert = () => (
	Thunk_Generic_Edit_Insert(
		service_insertServiceCategory,
		Action_ServiceCategory_Edit_Pending,
		Action_ServiceCategory_Edit_Fulfilled,
		Action_ServiceCategory_Edit_Rejected,
		Selector_ServiceCategory_Edit
	)
)

export const Thunk_ServiceCategory_Fetch = serviceCategoryID => (
	Thunk_Generic_Fetch_Item(
		serviceCategoryID,
		service_fetchServiceCategory,
		Action_ServiceCategory_Fetch_Pending,
		Action_ServiceCategory_Fetch_Fulfilled,
		Action_ServiceCategory_Fetch_Rejected
	)
)

export const Thunk_ServiceCategory_Update = () => (
	Thunk_Generic_Edit_Update(
		service_updateServiceCategory,
		Action_ServiceCategory_Edit_Pending,
		Action_ServiceCategory_Edit_Fulfilled,
		Action_ServiceCategory_Edit_Rejected,
		Selector_ServiceCategory_Edit
	)
)

export const Thunk_ServiceCategory_Save = () => (
	Thunk_Generic_Edit_Save(
		Selector_ServiceCategory_Edit,
		Action_ServiceCategory_Edit_Pending,
		Thunk_ServiceCategory_Insert,
		Thunk_ServiceCategory_Update
	)
)

export const Thunk_ServiceCategory_Remove = serviceCategoryID => (
	Thunk_Generic_Remove(
		serviceCategoryID,
		service_deleteServiceCategory,
		Action_ServiceCategory_Edit_Pending,
		Action_ServiceCategory_Edit_Fulfilled,
		Action_ServiceCategory_Edit_Rejected
	)
)
