import React from "react";
// eslint-disable-next-line no-unused-vars
import PropTypes from "prop-types";
import { combineClasses, resolveDynamicComponent } from "../../utils/utils";
import { Translate } from "react-localize-redux";
import Fa from "../tools/Icons/Fa";
import "../../styles/components/Hint.scss";

class Hint extends React.Component {
	render() {
		const {className, style, icon, text, textTranslated, prefix, suffix} = this.props;

		return (
			<section className={combineClasses("hint", className)} style={style}>
				{prefix}
				<span>
					{
						resolveDynamicComponent(icon, <Fa icon={`${icon}`} />)
					}
					{
						resolveDynamicComponent(text, <span>{textTranslated ? text : <Translate id={text} />}</span>)
					}
				</span>
				{suffix}
			</section>
		);
	}

	static get propTypes() {
		return {
			...super.propTypes,
			className: PropTypes.string,
			style: PropTypes.object,

			prefix: PropTypes.any,
			suffix: PropTypes.any,

			icon: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
			text: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
			textTranslated: PropTypes.bool,
		}
	}

	static get defaultProps() {
		return {
			...super.defaultProps,
			icon: "info-circle",
			textTranslated: false,
		}
	}
}

export default Hint;
