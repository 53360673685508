/**
 * Service authorization model
 * ---
 * @type {{
 *  serviceType: T_ServiceType,
 *  partner: T_PartnerModel,
 *  authorizedBy: T_UserModel,
 *  service: T_serviceModel,
 *  userAccount: T_UserModel,
 *  location: T_locationModel,
 *  id: number,
 *  createdOn: Date,
 *  serviceCategory: T_ServiceCategory,
 *  username: string
 * }}
 */
export const T_AuthorizationModel = {
	id: undefined,
	createdOn: undefined,
	partner: null,
	location: null,
	service: null,
	serviceType: null,
	serviceCategory: null,
	userAccount: null,
	username: "",
	authorizedBy: null,
}
