import { Action_Generic_Edit_Modify, Action_Generic_Fail, Action_Generic_Payload, Action_Generic_Type, GENERIC_EDIT_CANCEL, GENERIC_EDIT_FULFILLED, GENERIC_EDIT_INIT, GENERIC_EDIT_MODIFY, GENERIC_EDIT_PENDING, GENERIC_EDIT_REJECTED, GENERIC_FETCH_FULFILLED, GENERIC_FETCH_PENDING, GENERIC_FETCH_REJECTED, GENERIC_ITEM_CLEAR } from "../genericActions";

export const SESSION_CREATE_REJECTED = "SESSION_CREATE_REJECTED";
export const SESSION_COMPLETE_REJECTED = "SESSION_COMPLETE_REJECTED";
export const SESSION_MARK_AS_DELIVERED_REJECTED = "SESSION_MARK_AS_DELIVERED_REJECTED";
export const E_SessionActions = {
	SEND_NOTIFICATION: "SESSION_SEND_NOTIFICATION",
	OPEN_BLOCK: "SESSION_OPEN_BLOCK",
};

const REDUCER_LISTING_NAME = `SESSIONS`;
const REDUCER_ITEM_NAME = `SESSION`;

export const Action_Sessions_Fetch_Pending = () => Action_Generic_Type(`${REDUCER_LISTING_NAME}_${GENERIC_FETCH_PENDING}`);
export const Action_Sessions_Fetch_Fulfilled = (list, ...props) => Action_Generic_Payload(`${REDUCER_LISTING_NAME}_${GENERIC_FETCH_FULFILLED}`, list, ...props);
export const Action_Sessions_Fetch_Rejected = (error, ...props) => Action_Generic_Fail(`${REDUCER_LISTING_NAME}_${GENERIC_FETCH_REJECTED}`, error, ...props);

export const Action_Session_Fetch_Pending = () => Action_Generic_Type(`${REDUCER_ITEM_NAME}_${GENERIC_FETCH_PENDING}`);
export const Action_Session_Fetch_Fulfilled = (item, ...props) => Action_Generic_Payload(`${REDUCER_ITEM_NAME}_${GENERIC_FETCH_FULFILLED}`, item, ...props);
export const Action_Session_Fetch_Rejected = (error, ...props) => Action_Generic_Fail(`${REDUCER_ITEM_NAME}_${GENERIC_FETCH_REJECTED}`, error, ...props);

export const Action_Session_Edit_Init = (initData, ...props) => Action_Generic_Payload(`${REDUCER_ITEM_NAME}_${GENERIC_EDIT_INIT}`, initData, ...props);
export const Action_Session_Edit_Cancel = () => Action_Generic_Type(`${REDUCER_ITEM_NAME}_${GENERIC_EDIT_CANCEL}`);
export const Action_Session_Edit_Modify = (type, path, change, ...props) => (
	Action_Generic_Edit_Modify(`${REDUCER_ITEM_NAME}_${GENERIC_EDIT_MODIFY}`, type, path, change, ...props)
);
export const Action_Session_Edit_Pending = () => Action_Generic_Type(`${REDUCER_ITEM_NAME}_${GENERIC_EDIT_PENDING}`);
export const Action_Session_Edit_Fulfilled = (data, ...props) => Action_Generic_Payload(`${REDUCER_ITEM_NAME}_${GENERIC_EDIT_FULFILLED}`, data, ...props);
export const Action_Session_Edit_Rejected = (error, ...props) => Action_Generic_Fail(`${REDUCER_ITEM_NAME}_${GENERIC_EDIT_REJECTED}`, error, ...props);

export const Action_Session_Clear = () => Action_Generic_Type(`${REDUCER_ITEM_NAME}_${GENERIC_ITEM_CLEAR}`);
