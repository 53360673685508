import React from "react";
import PropTypes from "prop-types";
import { T_ListingHeaderItem, T_SharedPropTypes } from "../../../models/Models_Shared";
import { css } from "../../../utils/utils";
import is from "../../../utils/is";

class TableColumn extends React.Component {
	constructor(props) {
		super(props);
	}

	render() {
		const {children, headerItems, column} = this.props;

		let colProps = headerItems ? headerItems.find(item => item.key == column) : T_ListingHeaderItem;
		if(colProps) {
			if(!colProps.visible) {
				return null;
			}
		} else {
			return null;
		}

		let elProps = {...this.props};
		delete elProps.headerItems;
		delete elProps.column;

		let styles = {}

		if(colProps && colProps.width) {
			styles = {
				...styles,
				...css(is.number(colProps.width) ? `flex: ${colProps.width} 1 0%` : `max-width:${colProps.width}`)
			}
		}

		styles = {
			...styles,
			...elProps.style,
			overflowWrap: "anywhere",
		}

		return (
			<span {...elProps} style={styles}>
				{children}
			</span>
		)
	}

	static get propTypes() {
		return {
			...super.propTypes,
			...T_SharedPropTypes,
			//Processed header items are in Array instead of hardcoded object format
			headerItems: PropTypes.array,
			column: PropTypes.string.isRequired,
		}
	}

	static get stateTypes() {
		return {
			...super.stateTypes,
		}
	}

	static get defaultProps() {
		return {
			...super.defaultProps,
			headerItems: null,
		}
	}
}

export default TableColumn;
