// IMPORT PACKAGE REFERENCES
import React from 'react';
import { Route, Switch } from 'react-router-dom';
// IMPORT PROJECT REFERENCES
import LocationsList from './LocationsList';
import LocationDetail from './LocationDetail';
import routes from '../../../routes/routes';


// COMPONENT

class LocationsBrowser extends React.Component {

	constructor(props) {
		super(props);
	}

	render() {
		return (
			<Switch>
				<Route exact path={routes.LOCATIONS}>
					<LocationsList />
				</Route>
				<Route path={`${routes.LOCATIONS}/:locationID/:unitID?/:serviceID?`} render={
					props => (
						<LocationDetail
							locationID={parseInt(props.match.params.locationID)}
							unitID={parseInt(props.match.params.unitID) || null}
							serviceID={parseInt(props.match.params.serviceID) || null}
						/>
					)
				} />
			</Switch>
		);
	}
}

export default LocationsBrowser;
