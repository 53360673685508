// eslint-disable-next-line no-unused-vars
import {DELETE, formatQueryString, POST, PUT, sendRequest} from "./apiServices/apiConnector";
import {Process_formatQueryString} from "../utils/processors";

export const service_fetchService = (serviceID) => {
	return sendRequest(`/service/${serviceID}`).then(response => response.json());
};

export const service_insertService = (blockID, body) => {
	return sendRequest([`/block/${blockID}/service/`, POST, body]).then(response => response.json());
};

export const service_updateService = (serviceID, body) => {
	return sendRequest([`/service/${serviceID}`, PUT, body]).then(response => response.json());
};

export const service_deleteService = (serviceID) => {
	return sendRequest([`/service/${serviceID}`, DELETE]).then(response => response);
};

export const service_fetchServiceLockHistory = (serviceID, queryString) => {
	return sendRequest(`/service/${serviceID}/lock/history${formatQueryString(queryString)}`).then(response => response.json());
};

export const service_takeServiceCameraPicture = (serviceID) => {
	return sendRequest([`/service/${serviceID}/picture`, POST]).then(response => response.blob());
};

export const service_fetchServiceReservationAvailability = (serviceID, from, to, signal) => {
	return sendRequest({
		url: `/service/${serviceID}/reservation/availability${formatQueryString(Process_formatQueryString({
			from,
			to,
		}))}`,
		signal
	}).then(response => response.json());
};

export const service_fetchServicesOfLocation = async (locationID) => {
	const response = await sendRequest(`/location/${locationID}`);
	const location = await response.json();

	const services = [];

	location.storageUnits.forEach(unit => {
		unit.frames.forEach(frame => {
			frame.blocks.forEach(block => {
				services.push(...block.services);
			});
		});
	});

	return services;
};
