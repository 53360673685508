// Icons

export const T_APP_ICONS = {
	// Section icons
	HOME: "home",
	LOCATIONS: "map-marker-alt",
	EXTERNAL_LOCATIONS: "thumbtack",
	SESSIONS: "user-lock",
	NOTIFICATIONS: "bell",
	TARIFFS: "money-check-alt",
	BACK: "arrow-left",
	USERS: "users",
	FEEDBACK: "comment-dots",
	PARTNERS: "handshake",
	VOUCHERS: "cs-gift-card",
	CONFIGURATION: "cogs",
	STATISTICS: "chart-bar",
	SERVICE_TYPES: "tools",
	SERVICE_CATEGORIES: "bath",
	AUTHORIZATIONS: "id-card-alt",

	// Actions icons
	EDIT: "pen",
	EDIT_DIALOG: "edit",
	CLOSE: "times",
	ADD: "plus",
	REMOVE: "trash-alt",
	SAVE: "save",
	FILTER: "filter",
	DOWNLOAD: "file-download",
	EXPORT: "download",

	// State icons
	HIDDEN: "low-vision",
	AUTHORIZED_ONLY: "id-card-alt",
	OCCUPIED: "user-lock",

	// Frequently used icons
	CURRENCY: "coins",
	USER: "user",
	PDF: "file-pdf",
	CREATED_ON: "calendar-plus",
	SERVICE: "concierge-bell",
	REDIRECT: "external-link-alt",
	LANGUAGE: "language",
	CLIPBOARD: "clipboard",
	NOTE: "sticky-note",
	PROFILE: "user-circle",
};

// Regex patterns
export const T_RegexPatterns = {
	// float: /^([0-9]\d*([.,])\d*|0?([.,])\d*[0-9]\d*|[0-9]\d*)$/gm,
	float: /[0-9.,e-]/gm,
	// float: /^[-+]?([0-9]*\.?\d+([eE][-+]?\d+)?)?$/gm,
	ip: /(([2]([0-4][0-9]|[5][0-5])|[0-1]?[0-9]?[0-9])[.]){3}([2]([0-4][0-9]|[5][0-5])|[0-1]?[0-9]?[0-9])|(([a-fA-F0-9]{1,4}|):){1,7}([a-fA-F0-9]{1,4}|:)/gi,
	image: /(\.jpg)|(\.png)|(\.jpeg)|(\.gif)|(\.bmp)/gi,
	xls: /(\.xls)|(\.xlsx)/,
};

// Console styles
export const CONSOLE_ERROR_BOLD = "font-size: 1.2em; font-weight: bold; color: red";
export const CONSOLE_WARN_BOLD = "font-size: 1.2em; font-weight: bold; color: orange";

// Input types
export const INPUT_TEXT = "INPUT_TEXT";
export const INPUT_NUMBER = "INPUT_NUMBER"; // Number + float
export const INPUT_TIME = "INPUT_TIME";
export const INPUT_DATE = "INPUT_DATE";

export const E_InputTypes = {
	TEXT: INPUT_TEXT,
	NUMBER: INPUT_NUMBER,
	TIME: INPUT_TIME,
	DATE: INPUT_DATE,
};

export const E_EnabledStatus = {
	ENABLED: "ENABLED",
	DISABLED: "DISABLED"
};

export const WINDOW_MIN_WIDTH = 1220; // search bar width limit

export const E_Currency = {
	CZK: "CZK",
	EUR: "EUR",
	RON: "RON"
};

export const E_UserRoles = {
	ADMIN: "ADMIN",
	USER: "USER",
	ANONYMOUS: "ANONYMOUS"
};

export const E_FileTypes = {
	FILE: "FILE",
	IMAGE: "IMAGE",
	XLS: "XLS",
	PDF: "PDF",
};

export const CS_FILTERS_QUERY_SEPARATOR = '|';
export const JSON_REACT_REPLACER_KEYS = ['$$typeof', 'type', 'ref', 'props', '_owner', '_store', '_self'];

export const E_APIServiceActions = {
	ITEMS_FETCH: "ITEMS_FETCH",
	ITEM_FETCH: "ITEM_FETCH",
	ITEM_INSERT: "ITEM_INSERT",
	ITEM_UPDATE: "ITEM_UPDATE",
	ITEM_DELETE: "ITEM_DELETE",
}

export const GOOGLE_API_KEY = "AIzaSyDC9FFRMRs7CYveqFIjiSlRH1gbc_CC_dk";
