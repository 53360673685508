import React from "react";
import { Thunk_Users_Fetch_All } from "../../../store/thunk/Thunk_Users";
import { Selector_Users_Listing_Root } from "../../../store/selectors/Selectors_Users";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import ItemSelect from "../../shared/Input/ItemSelect";
import { resolveItemName } from "../../../utils/utils";
import { Selector_Auth_Principal } from "../../../store/selectors/Selectors_Auth";
import { T_APP_ICONS } from "../../../models/constants/Constants_Shared";
import { E_Modification } from "../../../store/Constants_StoreShared";

class UserSelect extends React.Component {
	render() {
		const {Thunk_Users_Fetch_All, onModify, path, principal} = this.props;

		return <ItemSelect
			{...this.props}
			thunk={Thunk_Users_Fetch_All}
			selector={Selector_Users_Listing_Root}
			label={"selectUser"}
			onLabel={item => resolveItemName(item, `email`)}
			headerItems={{email: {}, forename: {}, surname: {}, id: {}}}
			actionButtons={(defaultButtons) => [{
				icon: T_APP_ICONS.PROFILE,
				text: "selectSelf",
				action: () => {
					onModify(E_Modification.ITEM_SET, path, principal);
					window.modal.close();
				},
			}, ...defaultButtons]}
		/>
	}

	static get propTypes() {
		return {
			...super.propTypes,
			...ItemSelect.propTypes,
		}
	}
}

const mapStateToProps = state => {
	return {principal: Selector_Auth_Principal(state)};
};

const mapDispatchToProps = dispatch => (
	bindActionCreators({
		Thunk_Users_Fetch_All
	}, dispatch)
);

export default connect(mapStateToProps, mapDispatchToProps)(UserSelect);
