import React from 'react';
import { Switch, Route } from 'react-router-dom';
import routes from '../../../routes/routes';
import ServiceTypeDetail from "./ServiceTypeDetail";
import ServiceTypesList from "./ServiceTypesList";

class ServiceTypesBrowser extends React.Component {

	constructor(props) {
		super(props);
	}

	render() {
		return (
			<Switch>
				<Route exact path={routes.SERVICE_TYPES}>
					<ServiceTypesList />
				</Route>
				<Route path={`${routes.SERVICE_TYPES}/:serviceTypeID`} render={props => <ServiceTypeDetail serviceTypeID={parseInt(props.match.params.serviceTypeID)} />} />
			</Switch>
		);
	}
}

export default ServiceTypesBrowser;
