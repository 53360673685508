import React from "react";
import PropTypes from "prop-types";
import { Translate } from "react-localize-redux";
import Dropdown from "../../../../../shared/Input/Dropdown";
import NumberInput from "../../../../../shared/Input/NumberInput";
import Fa from "../../../../../tools/Icons/Fa";
import { resolveItemName } from "../../../../../../utils/utils";

class LedConfigurationModal extends React.Component {
	constructor(props) {
		super(props);

		this.state = {};
	}

	render() {
		const {configuration, units, path, onModify} = this.props;

		return (
			<section>
				<h5><Translate id={"ledConfiguration_greenChannel"} /></h5>
				<div className={`grid-table grid-icon-auto-1fr`}>
					<span><Fa icon={"project-diagram"} /></span>
					<label><Translate id={"channel"} /></label>
					<NumberInput
						defaultValue={configuration.greenChannel || ''}
						path={`${path}.greenChannel`}
						onModify={onModify}
					/>

					<span><Fa icon={"microchip"} /></span>
					<label><Translate id={"communicationUnit"} /></label>
					<Dropdown
						options={units.content}
						onLabel={item => resolveItemName(item)}
						onValue={item => item && ({id: item.id, name: item.name}) || null}
						path={`${path}.greenCommunicationUnit`}
						onModify={onModify}
						defaultValue={configuration.greenCommunicationUnit}
						canInvalidate={true}
					/>
				</div>
				<h5 style={{marginTop: "30px"}}><Translate id={"ledConfiguration_redChannel"} /></h5>
				<div className={`grid-table grid-icon-auto-1fr`}>
					<span><Fa icon={"project-diagram"} /></span>
					<label><Translate id={"channel"} /></label>
					<NumberInput
						defaultValue={configuration.redChannel || ''}
						path={`${path}.redChannel`}
						onModify={onModify}
					/>

					<span><Fa icon={"microchip"} /></span>
					<label><Translate id={"communicationUnit"} /></label>
					<Dropdown
						options={units.content}
						onLabel={item => resolveItemName(item)}
						onValue={item => item && ({id: item.id, name: item.name}) || null}
						path={`${path}.redCommunicationUnit`}
						onModify={onModify}
						defaultValue={configuration.redCommunicationUnit}
						canInvalidate={true}
					/>
				</div>
			</section>
		)
	}
}

LedConfigurationModal.propTypes = {
	configuration: PropTypes.object.isRequired,
	units: PropTypes.object.isRequired,
	path: PropTypes.string.isRequired,

	onModify: PropTypes.func.isRequired,
};

LedConfigurationModal.stateTypes = {};

export default LedConfigurationModal;
