import React from "react";
import PropTypes from "prop-types";
import ToggleButton from "../Input/ToggleButton";
import { Translate } from "react-localize-redux";
import { EO } from "../../../utils/extensions";
import { css, resolveDynamicComponent } from "../../../utils/utils";
import Fa from "../../tools/Icons/Fa";

class TableColumnsSetup extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			...this.state,
			hiddenItems: props.hiddenItems,
		};
	}

	_renderColumnOption(data, i) {
		const {hiddenItems} = this.state;

		return (
			<div key={i}>
				<label style={css(`display: flex; justify-content: flex-start; align-items: center;`)}>
					<ToggleButton
						defaultValue={!hiddenItems.includes(data.key)}
						onModify={(t, p, value) => value ? hiddenItems.removeItem(data.key) : hiddenItems.push(data.key)}
					/>
					<span style={css(`margin-left: 15px`)}>
						{resolveDynamicComponent(data.label, <Translate id={data.label} />) || <Translate id={data.metaLabel || data.key}/>}
					</span>
				</label>
			</div>
		)
	}

	_renderOptions() {
		const {headerItems} = this.props;

		let items = headerItems;

		if(!Array.isArray(headerItems)) {
			items = EO(headerItems).toArray(true);
		}

		return items.filter(item => item.canModify).map(this._renderColumnOption.bind(this));
	}

	render() {
		const {className, style, onModify} = this.props;
		const {hiddenItems} = this.state;

		return (
			<section className={className} style={style}>
				{this._renderOptions()}

				<button className={"fancy"} onClick={() => onModify(hiddenItems)}>
					<Fa icon={"check"} />
					<span><Translate id={"accept"} /></span>
				</button>
			</section>
		)
	}

	static get propTypes() {
		return {
			...super.propTypes,
			headerItems: PropTypes.any.isRequired,
			hiddenItems: PropTypes.array.isRequired,
		}
	}

	static get stateTypes() {
		return {
			...super.stateTypes,
		}
	}

	static get defaultProps() {
		return {
			...super.defaultProps,
		}
	}
}

export default TableColumnsSetup;
