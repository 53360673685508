import {T_AddressModel} from "./Models_Shared";

export const E_MarkerType = {
	DEFAULT: "DEFAULT"
};

export const E_Visibility = {
	VISIBLE: "VISIBLE",
	HIDDEN: "HIDDEN",
	AUTHORIZED_ONLY: "AUTHORIZED_ONLY"
};

export const E_OpeningHoursType = {
	NONSTOP: "NONSTOP",
	CLOSED: "CLOSED",
	SPECIFIC: "SPECIFIC"
};

export const E_LocationAvailability = {
	AVAILABLE: "AVAILABLE",
	NOT_AVAILABLE_OUT_OF_ORDER: "NOT_AVAILABLE_OUT_OF_ORDER",
	NOT_AVAILABLE_OFF_HOURS: "NOT_AVAILABLE_OFF_HOURS",
};

export const T_LocationAddress = {
	...T_AddressModel,
	asOneLine: null,
	email: null,
	latitude: null,
	longitude: null,
	phoneNumber: null,
	url: null
};


export const T_OpeningHoursPeriod = {
	fromTime: "00:00",
	toTime: "00:00"
};

/**
 * @type {{
 *  openingType: String,
 *  weekday: String,
 *  periods: Array<T_OpeningHoursPeriod>,
 *  id: Number
 * }}
 */
export const T_OpeningHoursEntry = {
	id: null,
	openingType: E_OpeningHoursType.NONSTOP,
	periods: [],
	weekday: ''
};

/**
 * @type {{
 *  entries: Array<T_OpeningHoursPeriod>,
 *  timezone: String,
 *  twentyFourSeven: Boolean
 * }}
 */
export const T_OpeningHours = {
	entries: [
		{...T_OpeningHoursEntry, weekday: "MONDAY"},
		{...T_OpeningHoursEntry, weekday: "TUESDAY"},
		{...T_OpeningHoursEntry, weekday: "WEDNESDAY"},
		{...T_OpeningHoursEntry, weekday: "THURSDAY"},
		{...T_OpeningHoursEntry, weekday: "FRIDAY"},
		{...T_OpeningHoursEntry, weekday: "SATURDAY"},
		{...T_OpeningHoursEntry, weekday: "SUNDAY"}
	],
	timezone: '',
	twentyFourSeven: false
};

export const T_locationModel = {
	address: T_LocationAddress,
	currentInfo: [],
	description: [],
	developerDescription: '',
	images: [],
	markerType: E_MarkerType.DEFAULT,
	name: '',
	openingHours: T_OpeningHours,
	storageUnits: [],
	visibility: E_Visibility.VISIBLE,
	availabilityState: null
};

/**
 * Location Name Model
 * ---
 * @type {{images: T_ImageModel[], address: T_AddressModel, name: String, id: Number}}
 */
export const T_LocationNameModel = {
	address: T_LocationAddress,
	id: null,
	images: [],
	name: null
};
