import { service_deleteTariff, service_fetchTariff, service_fetchTariffs, service_insertTariff, service_updateTariff } from "../../services/Service_Tariffs";
import { Action_Tariff_Edit_Fulfilled, Action_Tariff_Edit_Pending, Action_Tariff_Edit_Rejected, Action_Tariff_Fetch_Fulfilled, Action_Tariff_Fetch_Pending, Action_Tariff_Fetch_Rejected, Action_Tariffs_Fetch_Fulfilled, Action_Tariffs_Fetch_Pending, Action_Tariffs_Fetch_Rejected } from "../actions/tariffs/tariffsActions";
import { Selector_Tariff_Edit } from "../selectors/Selectors_Tariff";
import { Thunk_Generic_Edit_Insert, Thunk_Generic_Edit_Save, Thunk_Generic_Edit_Update, Thunk_Generic_Fetch_All, Thunk_Generic_Fetch_Item, Thunk_Generic_Remove } from "./Thunk_Generic";

export const Thunk_Tariffs_Fetch_All = queryString => (
	Thunk_Generic_Fetch_All(
		queryString,
		service_fetchTariffs,
		Action_Tariffs_Fetch_Pending,
		Action_Tariffs_Fetch_Fulfilled,
		Action_Tariffs_Fetch_Rejected
	)
);

export const Thunk_Tariff_Fetch = itemID => (
	Thunk_Generic_Fetch_Item(
		itemID,
		service_fetchTariff,
		Action_Tariff_Fetch_Pending,
		Action_Tariff_Fetch_Fulfilled,
		Action_Tariff_Fetch_Rejected
	)
);

export const Thunk_Tariff_Insert = () => (
	Thunk_Generic_Edit_Insert(
		service_insertTariff,
		Action_Tariff_Edit_Pending,
		Action_Tariff_Edit_Fulfilled,
		Action_Tariff_Edit_Rejected,
		Selector_Tariff_Edit
	)
);

export const Thunk_Tariff_Update = () => (
	Thunk_Generic_Edit_Update(
		service_updateTariff,
		Action_Tariff_Edit_Pending,
		Action_Tariff_Edit_Fulfilled,
		Action_Tariff_Edit_Rejected,
		Selector_Tariff_Edit
	)
);

export const Thunk_Tariff_Save = () => (
	Thunk_Generic_Edit_Save(
		Selector_Tariff_Edit,
		Action_Tariff_Edit_Pending,
		Thunk_Tariff_Insert,
		Thunk_Tariff_Update
	)
);

export const Thunk_Tariff_Remove = (tariffID, replacementID) => (
	Thunk_Generic_Remove(
		{
			id: tariffID,
			replacementID
		},
		service_deleteTariff,
		Action_Tariff_Edit_Pending,
		Action_Tariff_Edit_Fulfilled,
		Action_Tariff_Edit_Rejected
	)
)
