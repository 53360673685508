import { Action_Generic_Fail, Action_Generic_Payload, Action_Generic_Type } from "./genericActions";

export const AUTHENTICATE_PENDING = 'AUTHENTICATE_PENDING';
export const AUTHENTICATE_FULFILLED = 'AUTHENTICATE_FULFILLED';
export const AUTHENTICATE_REJECTED = 'AUTHENTICATE_REJECTED';

export const AUTHENTICATE_TRUNCATE = 'AUTHENTICATE_TRUNCATE'

export const FETCH_PRINCIPAL_PENDING = 'FETCH_PRINCIPAL_PENDING';
export const FETCH_PRINCIPAL_FULFILLED = 'FETCH_PRINCIPAL_FULFILLED';
export const FETCH_PRINCIPAL_REJECTED = 'FETCH_PRINCIPAL_REJECTED';

export const Action_Auth_Pending = () => Action_Generic_Type(AUTHENTICATE_PENDING);
export const Action_Auth_Fulfilled = (response) => {
	if(response.ok) {
		localStorage.setItem('token', response.headers.get('x-auth-token'));
	}
	return Action_Generic_Type(AUTHENTICATE_FULFILLED);
};
export const Action_Auth_Rejected = (error, ...props) => Action_Generic_Fail(AUTHENTICATE_REJECTED, error, ...props);

export const Action_Fetch_Principal_Pending = () => Action_Generic_Type(FETCH_PRINCIPAL_PENDING);
export const Action_Fetch_Principal_Fulfilled = (principal, ...props) => Action_Generic_Payload(FETCH_PRINCIPAL_FULFILLED, principal, ...props);
export const Action_Fetch_Principal_Rejected = (error, ...props) => Action_Generic_Fail(FETCH_PRINCIPAL_REJECTED, error, ...props);

export const Action_Auth_LogOut = () => {
	window.localStorage.removeItem("token");
	window.location.replace("/");

	return Action_Generic_Type(AUTHENTICATE_TRUNCATE);
}
