import React from 'react'
// eslint-disable-next-line no-unused-vars
import { get } from '../utils/utils';

class CSBaseComponent extends React.Component {
	constructor(props) {
		super(props);

		this.state = {};

		this.setRef = this.setRef.bind(this);
	}

	setRef(element) {
		if (!element) {return;}

		let refName = get(element, "props.name", element.getAttribute && element.getAttribute("name") || "root");
		this[refName] = element;
	}

	componentDidMount() {
		this._isMounted = true;
	}

	componentWillUnmount() {
		this._isMounted = false;
	}

	componentDidUpdate(prevProps, prevState, snapshot) {}
	componentDidCatch(error, errorInfo) {}
	shouldComponentUpdate(nextProps, nextState, nextContext) {return true}
	static getDerivedStateFromError(error) {}
}

CSBaseComponent.propTypes = {};

CSBaseComponent.stateTypes = {};

export default CSBaseComponent;
