import React from 'react';
import routes from '../../../routes/routes';
import { NavLink } from "react-router-dom";
import { Translate } from "react-localize-redux";

class Configurations extends React.Component {

	constructor(props) {
		super(props);
	}

	render() {
		return (
			<section className={`configurations-browser`}>
				<div style={{display: "flex", padding: 40}}>
					<div className={"box-grid"} style={{margin: "auto", width: "100%"}}>
						<NavLink to={routes.SERVICE_CATEGORIES}>
							<label><Translate id={"serviceCategories"}/></label>
						</NavLink>
						<NavLink to={routes.SERVICE_TYPES}>
							<label><Translate id={"serviceTypes"}/></label>
						</NavLink>
						<NavLink to={routes.TARIFFS}>
							<label><Translate id={"tariffs"}/></label>
						</NavLink>
					</div>
				</div>
			</section>
		);
	}
}

export default Configurations;
