export const Selector_Users_Root = state => {
	return state.users;
};

export const Selector_Users_Listing_Root = state => {
	return Selector_Users_Root(state).listing;
};

export const Selector_Users_Listing_Data = state => {
	return Selector_Users_Listing_Root(state).data;
};

export const Selector_User_Root = state => {
	return Selector_Users_Root(state).item;
};

export const Selector_User_Data = state => {
	return Selector_User_Root(state).data;
};

export const Selector_User_Edit = state => {
	return Selector_User_Root(state).edit;
};
