import { EO } from "../../utils/extensions";
import is from "../../utils/is";
import { get } from "../../utils/utils";
import { EDIT_BLOCK_FULFILLED, EDIT_BLOCK_PENDING, EDIT_BLOCK_REJECTED } from "../actions/location/blockActions";
import { EDIT_FRAME_FULFILLED, EDIT_FRAME_PENDING, EDIT_FRAME_REJECTED } from "../actions/location/frameActions";
import { EDIT_SERVICE_FULFILLED } from "../actions/location/serviceActions";
import { EDIT_UNIT_FULFILLED, EDIT_UNIT_INIT, EDIT_UNIT_MODIFY, EDIT_UNIT_PENDING, EDIT_UNIT_REJECTED } from "../actions/location/unitActions";
import { Selector_Location, Selector_Location_Edit_Location, Selector_Location_Edit_Unit } from "../selectors/Selectors_Location";
import { GENERIC_EDIT_CANCEL, GENERIC_EDIT_FULFILLED, GENERIC_EDIT_INIT, GENERIC_EDIT_MODIFY, GENERIC_EDIT_PENDING, GENERIC_EDIT_REJECTED, GENERIC_FETCH_FULFILLED, GENERIC_FETCH_PENDING, GENERIC_FETCH_REJECTED } from "../actions/genericActions";
import { E_Modification } from "../Constants_StoreShared";
import { errorHandler } from "../ResponseHandling";
import { findPathComponentsByID } from "../../utils/locationUtils";


// INITIALIZE STATE
const initialState = {
	data: {},
	fetching: false,
	fetched: false,
	failed: false,
	deleted: null,
	edit: {},
};

const initialState_locationEdit = {
	location: {},
	unit: {},
};

const normalizeState = state => ({locations: {item: state}});

const handleLocationEdit = (state, action) => {
	let editLocation = EO(Selector_Location_Edit_Location(normalizeState(state))).clone(true);
	editLocation.modify(action.modificationType, action.path, action.modification);

	return {
		...state,
		edit: {
			...initialState_locationEdit,
			...state.edit,
			location: editLocation
		},
	};
};

const handleFrameUpdate = (state, action) => {
	let newEditData = EO(Selector_Location_Edit_Location(normalizeState(state))).clone(true);

	if (is.valid(newEditData)) {
		newEditData.modify(action.modificationType, action.path, action.modification);
	}

	let newLocationData = EO(Selector_Location(normalizeState(state))).clone(true);
	newLocationData.modify(action.modificationType, action.path, action.modification);

	return {
		...state,
		fetching: false,
		edit: {
			...state.edit,
			location: newEditData
		},
		data: newLocationData
	};
};

const handleUnitUpdate = (state, action) => {
	let newUnitData = EO(Selector_Location_Edit_Unit(normalizeState(state))).clone(true);
	newUnitData.modify(action.modificationType, action.path, action.modification);

	let newLocationData = EO(Selector_Location(normalizeState(state))).clone(true);
	newLocationData.modify(action.modificationType, action.path, action.modification);

	return {
		...state,
		edit: {
			...state.edit,
			unit: newUnitData
		},
		data: newLocationData
	};
};

const handleUnitFulfilled = (state, action) => {
	let newEditData = EO(Selector_Location_Edit_Location(normalizeState(state))).clone(true);

	if (is.valid(newEditData)) {
		newEditData.modify(action.modificationType, action.path, action.modification);
	}

	let newLocationData = EO(Selector_Location(normalizeState(state))).clone(true);
	newLocationData.modify(action.modificationType, action.path, action.modification);

	return {
		...state,
		fetching: false,
		edit: {
			...state.edit,
			unit: {},
			location: newEditData
		},
		data: newLocationData
	};
};

const handleBlockFulfilled = (state, action) => {
	let newEditData = EO(Selector_Location_Edit_Location(normalizeState(state))).clone(true);

	if(action.path === null) {
		let {unit, frame} = findPathComponentsByID(newEditData, action.modification, "block");

		if(unit && frame) {
			action.path = `storageUnits.#${unit.id}.frames.#${frame.id}.blocks.#${action.modification}`;
		} else {
			console.log(unit, frame);
			errorHandler({
				message: `unit OR frame has not been found in LocationReducer::handleBlockFulfilled`
			}, action.type);
			return {...state};
		}
	}

	if (is.valid(newEditData)) {
		newEditData.modify(action.modificationType, action.path, action.modification);
	}

	let newLocationData = EO(Selector_Location(normalizeState(state))).clone(true);
	newLocationData.modify(action.modificationType, action.path, action.modification);

	return {
		...state,
		fetching: false,
		edit: {
			...state.edit,
			location: newEditData
		},
		data: newLocationData
	};
};

function handleServiceFulfilled(state, action) {
	let newLocationData = EO(Selector_Location(normalizeState(state))).clone(true);

	let target;
	if(action.customProps.blockID) {
		target = findPathComponentsByID(newLocationData, action.customProps.blockID, "block");
	} else {
		target = findPathComponentsByID(newLocationData, action.payload.id, "service");
	}

	let {unit, frame, block} = target;
	block = {
		id: action.customProps.blockID,
		...block,
	};

	if(unit && frame && block.id) {
		action.path = `storageUnits.#${unit.id}.frames.#${frame.id}.blocks.#${block.id}.services.#${action.payload.id}`;
	} else {
		errorHandler({
			message: `unit OR frame OR block.id has not been found in LocationReducer::handleBlockFulfilled`
		}, action.type);
		return {...state};
	}

	let newEditData = EO(Selector_Location_Edit_Location(normalizeState(state))).clone(true);
	if (is.valid(newEditData)) {
		newEditData.modify(action.modificationType, action.path, action.payload);
	}

	newLocationData.modify(action.modificationType, action.path, action.payload);

	return {
		...state,
		fetching: false,
		edit: {
			...state.edit,
			location: newEditData
		},
		data: newLocationData
	};
}

export default (state = initialState, action) => {
	switch (action.type) {
		case "LOCATION_" + GENERIC_FETCH_PENDING:
			return {
				...state,
				edit: {},
				fetching: true,
				fetched: false,
				failed: false,
				error: {},
			};
		case "LOCATION_" + GENERIC_FETCH_FULFILLED:
			return {
				...state,
				data: action.payload,
				edit: {},
				fetching: false,
				fetched: true,
				failed: false,
			};
		case "LOCATION_" + GENERIC_FETCH_REJECTED:
			return {
				...state,
				data: {},
				edit: {},
				fetching: false,
				fetched: false,
				failed: true,
				error: action.error,
			};
		case "LOCATION_" + GENERIC_EDIT_INIT:
			return {
				...state,
				edit: {
					...initialState_locationEdit,
					location: EO(action.payload || Selector_Location(normalizeState(state))).clone(true)
				},
			};
		case "LOCATION_" + GENERIC_EDIT_CANCEL:
			return {
				...state,
				edit: initialState_locationEdit,
			};
		// eslint-disable-next-line no-case-declarations
		case "LOCATION_" + GENERIC_EDIT_MODIFY:
			return handleLocationEdit(state, action);
		case "LOCATION_" + GENERIC_EDIT_PENDING:
			return {
				...state,
				fetching: true,
				error: {},
			};
		case "LOCATION_" + GENERIC_EDIT_FULFILLED:
			if (get(action, `payload.type`) === E_Modification.ITEM_REMOVE) { //Handle delete
				return {
					deleted: get(action, `payload.id`),
					fetching: false,
					data: {},
					edit: {}
				}
			}

			return {
				...state,
				fetching: false,
				edit: {},
				data: action.payload,
			};
		case "LOCATION_" + GENERIC_EDIT_REJECTED:
			return {
				...state,
				fetching: false,
				error: action.error,
			};
		case EDIT_UNIT_INIT:
			return {
				...state,
				edit: {
					...initialState_locationEdit,
					...state.edit,
					unit: action.payload,
				}
			};
		case EDIT_UNIT_PENDING:
			return {
				...state,
				fetching: true
			};
		case EDIT_UNIT_MODIFY:
			return handleUnitUpdate(state, action);
		case EDIT_UNIT_FULFILLED:
			return handleUnitFulfilled(state, action);
		case EDIT_UNIT_REJECTED:
			return {
				...state,
				fetching: false
			};
		case EDIT_FRAME_PENDING:
			return {
				...state,
				fetching: true
			};
		case EDIT_FRAME_FULFILLED:
			return handleFrameUpdate(state, action);
		case EDIT_FRAME_REJECTED:
			return {
				...state,
				fetching: false
			};
		case EDIT_BLOCK_PENDING:
			return {
				...state,
				fetching: true
			};
		case EDIT_BLOCK_FULFILLED:
			return handleBlockFulfilled(state, action);
		case EDIT_BLOCK_REJECTED:
			return {
				...state,
				fetching: false
			};
		case EDIT_SERVICE_FULFILLED:
			return handleServiceFulfilled(state, action);
		default:
			return state;
	}
};
