import { service_deleteProfile, service_fetchProfile, service_updateProfile } from "../../services/Service_Users";
import { Process_formatUserOnFetch, Process_formatUserOnSubmit } from "../../utils/processors";
import { Action_User_Edit_Fulfilled, Action_User_Edit_Pending, Action_User_Edit_Rejected, Action_User_Fetch_Fulfilled, Action_User_Fetch_Pending, Action_User_Fetch_Rejected } from "../actions/users/usersActions";
import { Selector_User_Edit } from "../selectors/Selectors_Users";
import { Thunk_Generic_Edit_Save, Thunk_Generic_Edit_Update, Thunk_Generic_Fetch_Item, Thunk_Generic_Remove } from "./Thunk_Generic";

export const Thunk_Profile_Fetch = () => (
	Thunk_Generic_Fetch_Item(
		null,
		service_fetchProfile,
		Action_User_Fetch_Pending,
		Action_User_Fetch_Fulfilled,
		Action_User_Fetch_Rejected,
		Process_formatUserOnFetch
	)
);

export const Thunk_Profile_Update = () => (
	Thunk_Generic_Edit_Update(
		service_updateProfile,
		Action_User_Edit_Pending,
		Action_User_Edit_Fulfilled,
		Action_User_Edit_Rejected,
		Selector_User_Edit,
		Process_formatUserOnSubmit,
		Process_formatUserOnFetch
	)
);

export const Thunk_Profile_Save = () => (
	Thunk_Generic_Edit_Save(
		Selector_User_Edit,
		Action_User_Edit_Pending,
		null,
		Thunk_Profile_Update
	)
);

export const Thunk_Profile_Remove = () => (
	Thunk_Generic_Remove(
		null,
		service_deleteProfile,
		Action_User_Edit_Pending,
		Action_User_Edit_Fulfilled,
		Action_User_Edit_Rejected
	)
)
