import React from "react";

const transform = (x, y) => ({transform: `translate(${x}px, ${y}px)`});

export default (iconName, className, style) => {
	let icons = {
		//Flags
		cs:
			<svg className={`fa ${className}`} width="32" height="32" viewBox="0 0 512 336" xmlns="http://www.w3.org/2000/svg" style={style}>
				<g fill="none">
					<path d="M3.256 333.59L256 168 3.256 2.41C1.311 4.029 0 6.375 0 9.103v317.793c0 2.729 1.311 5.075 3.256 6.694z" fill="#41479B" />
					<path d="M3.256 333.59c1.53 1.274 3.425 2.134 5.571 2.134h494.345a8.829 8.829 0 0 0 8.828-8.828V168H256L3.256 333.59z" fill="#FF4B55" />
					<path d="M3.256 2.41C4.786 1.136 6.681.276 8.827.276h494.345A8.829 8.829 0 0 1 512 9.104V168H256L3.256 2.41z" fill="#F5F5F5" />
				</g>
			</svg>,

		sk:
			<svg className={`fa ${className}`} width="32" height="32" viewBox="0 0 512 336" xmlns="http://www.w3.org/2000/svg" style={style}>
				<g fill="none">
					<path d="M512.001 112.092H0V9.103A8.829 8.829 0 0 1 8.828.275h494.345a8.829 8.829 0 0 1 8.828 8.828v102.989z" fill="#F5F5F5" />
					<path d="M503.172 335.724H8.828A8.829 8.829 0 0 1 0 326.896V223.908h512v102.988a8.828 8.828 0 0 1-8.828 8.828z" fill="#FF4B55" />
					<path fill="#41479B" d="M0 112.088h512V223.9H0z" />
					<path d="M130.225 251.285c-2.613-1.501-64.018-37.216-64.018-92.113V84.138h132.414v75.034c0 54.897-61.405 90.612-64.018 92.113l-2.189 1.249-2.189-1.249z" fill="#FF4B55" />
					<path fill="#F5F5F5" d="M167.094 145.931l-20.809 2.741h-6.935v-12.418h4.161l16.646 2.741-2.254-9.677 2.254-9.677-16.646 2.74h-4.161v-4.194l2.74-20.808-9.676 2.819-9.676-2.819 2.74 20.808v4.194h-4.163l-16.645-2.74 2.254 9.677-2.254 9.677 16.645-2.741h4.163v12.418h-6.936l-20.808-2.741 2.818 9.676-2.818 9.677 20.808-2.741h6.936v31.94h13.872v-31.94h6.935l20.809 2.741-2.818-9.677z" />
					<path d="M132.414 181.241c-9.118 0-16.938 5.532-20.302 13.419-.594-.049-1.162-.178-1.767-.178-11.751 0-21.266 9.21-21.94 20.791 17.875 22.004 40.264 35.116 41.82 36.011l2.189 1.25 2.189-1.25c1.556-.895 23.945-14.006 41.82-36.011-.674-11.581-10.189-20.791-21.94-20.791-.605 0-1.173.129-1.767.178-3.364-7.887-11.184-13.419-20.302-13.419z" fill="#41479B" />
					<path d="M194.207 88.552v70.621c0 52.966-61.793 88.276-61.793 88.276s-61.793-35.31-61.793-88.276V88.552h123.586zm8.828-8.828H61.793v79.449c0 57.428 63.537 94.394 66.241 95.94l4.38 2.503 4.38-2.503c2.705-1.546 66.241-38.513 66.241-95.94V79.724z" fill="#F5F5F5" />
				</g>
			</svg>,

		en:
			<svg className={`fa ${className}`} width="32" height="32" viewBox="0 0 512 336" xmlns="http://www.w3.org/2000/svg" style={style}>
				<g fill="none">
					<path d="M503.172 335.725H8.828A8.829 8.829 0 0 1 0 326.897V9.104A8.829 8.829 0 0 1 8.828.276h494.345a8.829 8.829 0 0 1 8.828 8.828v317.793a8.83 8.83 0 0 1-8.829 8.828z" fill="#41479B" />
					<path d="M512 9.104a8.829 8.829 0 0 0-8.828-8.828h-39.495l-163.54 107.147V.276h-88.276v107.147L48.322.276H8.828A8.829 8.829 0 0 0 0 9.104v22.831l140.309 91.927H0v88.276h140.309L0 304.066v22.831a8.829 8.829 0 0 0 8.828 8.828h39.495l163.54-107.147v107.147h88.276V228.578l163.54 107.147h39.495a8.829 8.829 0 0 0 8.828-8.828v-22.831l-140.309-91.927H512v-88.276H371.691L512 31.935V9.104z" fill="#F5F5F5" />
					<g fill="#FF4B55">
						<path d="M512 141.518H282.483V.276h-52.966v141.242H0v52.965h229.517v141.242h52.966V194.483H512z" />
						<path d="M178.948 212.138L.25 328.135c.625 4.263 4.14 7.59 8.577 7.59h12.159l190.39-123.587-32.428.001v-.001zm167.44 0H313.96l190.113 123.404c4.431-.472 7.928-4.09 7.928-8.646v-7.258l-165.613-107.5zM0 18.849l161.779 105.014h32.428L5.143 1.137C2.123 2.54 0 5.555 0 9.104v9.745zm332.566 105.014L511.693 7.586c-.744-4.122-4.184-7.309-8.521-7.309h-12.647L300.138 123.863h32.428z" />
					</g>
				</g>
			</svg>,

		"edit-cancel":
			<svg className={`svg-inline--fa fa-edit-cancel fa-w-14 fa ${className}`} viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg" style={style}>
				<mask id="slashMask">
					<rect x="0" y="0" width="500" height="500" fill="white" />
					<path style={transform(0, 120)} fill="black" d="M594.53 508.63L6.18 53.9c-6.97-5.42-8.23-15.47-2.81-22.45L23.01 6.18C28.43-.8 38.49-2.06 45.47 3.37L633.82 458.1c6.97 5.42 8.23 15.47 2.81 22.45l-19.64 25.27c-5.42 6.98-15.48 8.23-22.46 2.81z" />
				</mask>
				<path style={transform(50, 0)} mask="url(#slashMask)" d="M290.74 93.24l128.02 128.02-277.99 277.99-114.14 12.6C11.35 513.54-1.56 500.62.14 485.34l12.7-114.22 277.9-277.88zm207.2-19.06l-60.11-60.11c-18.75-18.75-49.16-18.75-67.91 0l-56.55 56.55 128.02 128.02 56.55-56.55c18.75-18.76 18.75-49.16 0-67.91z" />
				<path d="M594.53 508.63L6.18 53.9c-6.97-5.42-8.23-15.47-2.81-22.45L23.01 6.18C28.43-.8 38.49-2.06 45.47 3.37L633.82 458.1c6.97 5.42 8.23 15.47 2.81 22.45l-19.64 25.27c-5.42 6.98-15.48 8.23-22.46 2.81z" />
			</svg>,
		"snooze":
			<svg className={`svg-inline--fa fa-snooze fa-w-14 fa ${className}`} viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg" style={style}>
				<path d={"M192 224H40a24 24 0 0 0-24 24v48a24 24 0 0 0 24 24h50.44L8.08 412.66A32 32 0 0 0 0 433.92V480a32 32 0 0 0 32 32h152a24 24 0 0 0 24-24v-48a24 24 0 0 0-24-24h-50.44l82.36-92.66a32 32 0 0 0 8.08-21.26V256a32 32 0 0 0-32-32zm0-32h96a16 16 0 0 0 16-16v-32a16 16 0 0 0-16-16h-34.75l57.38-57.38A32 32 0 0 0 320 48V32a32 32 0 0 0-32-32H184a16 16 0 0 0-16 16v32a16 16 0 0 0 16 16h42.75l-57.38 57.38A32 32 0 0 0 160 144v16a32 32 0 0 0 32 32zm256 80v-16a32 32 0 0 0-32-32h-96a16 16 0 0 0-16 16v32a16 16 0 0 0 16 16h34.75l-57.38 57.38A32 32 0 0 0 288 368v16a32 32 0 0 0 32 32h104a16 16 0 0 0 16-16v-32a16 16 0 0 0-16-16h-42.75l57.38-57.38A32 32 0 0 0 448 272z"} />
			</svg>,
		"gift-card":
			<svg className={`svg-inline--fa fa-gift-card fa-w-14 fa ${className}`} viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg" style={style}>
				<path d={"M528 128h-58.07c6.22-12.06 10.07-25.52 10.07-40 0-48.52-39.48-88-88-88-41.6 0-68.51 21.34-103.04 68.33C254.44 21.33 227.53 0 185.93 0c-48.52 0-88 39.48-88 88 0 14.48 3.85 27.94 10.07 40H48c-26.51 0-48 21.49-48 48v288c0 26.51 21.49 48 48 48h480c26.51 0 48-21.49 48-48V176c0-26.51-21.49-48-48-48zM392 48c22.06 0 40 17.94 40 40 0 22.05-17.94 40-40 40h-86.07c51.36-76.47 65.72-80 86.07-80zM145.93 88c0-22.06 17.94-40 40-40 19.94 0 34.58 3.27 86.07 80h-86.07c-22.06 0-40-17.95-40-40zm60.13 104l-35.72 35.72c-6.25 6.25-6.25 16.38 0 22.63l11.31 11.31c6.25 6.25 16.38 6.25 22.63 0L273.94 192h28.13l69.65 69.65c6.25 6.25 16.38 6.25 22.63 0l11.31-11.31c6.25-6.25 6.25-16.38 0-22.63L369.94 192H512v128H64V192h142.06zM64 448v-64h448v64H64z"} />
			</svg>,
		"filter-slash":
			<svg className={`svg-inline--fa fa-filter-slash fa-w-14 fa ${className}`} viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg" style={style}>
				<mask id="slashMask">
					<rect x="0" y="0" width="500" height="500" fill="white" />
					<path style={transform(0, 120)} fill="black" d="M594.53 508.63L6.18 53.9c-6.97-5.42-8.23-15.47-2.81-22.45L23.01 6.18C28.43-.8 38.49-2.06 45.47 3.37L633.82 458.1c6.97 5.42 8.23 15.47 2.81 22.45l-19.64 25.27c-5.42 6.98-15.48 8.23-22.46 2.81z" />
				</mask>
				<path style={transform(100, 30)} mask="url(#slashMask)" d="M487.976 0H24.028C2.71 0-8.047 25.866 7.058 40.971L192 225.941V432c0 7.831 3.821 15.17 10.237 19.662l80 55.98C298.02 518.69 320 507.493 320 487.98V225.941l184.947-184.97C520.021 25.896 509.338 0 487.976 0z" />
				<path d="M594.53 508.63L6.18 53.9c-6.97-5.42-8.23-15.47-2.81-22.45L23.01 6.18C28.43-.8 38.49-2.06 45.47 3.37L633.82 458.1c6.97 5.42 8.23 15.47 2.81 22.45l-19.64 25.27c-5.42 6.98-15.48 8.23-22.46 2.81z" />
			</svg>,
		"phone-office":
			<svg className={`svg-inline--fa fa-phone-office fa-w-14 fa ${className}`} viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg" style={style}>
				<path d={"M128 416h64c17.67 0 32-14.33 32-32V32c0-17.67-14.33-32-32-32h-64c-17.67 0-32 14.33-32 32v352c0 17.67 14.33 32 32 32zM528 32H256v352c0 35.29-28.71 64-64 64h-64c-35.29 0-64-28.71-64-64V32H48C21.49 32 0 53.49 0 80v384c0 26.51 21.49 48 48 48h480c26.51 0 48-21.49 48-48V80c0-26.51-21.49-48-48-48zM384 432c0 8.84-7.16 16-16 16h-32c-8.84 0-16-7.16-16-16v-32c0-8.84 7.16-16 16-16h32c8.84 0 16 7.16 16 16v32zm0-128c0 8.84-7.16 16-16 16h-32c-8.84 0-16-7.16-16-16v-32c0-8.84 7.16-16 16-16h32c8.84 0 16 7.16 16 16v32zm128 128c0 8.84-7.16 16-16 16h-32c-8.84 0-16-7.16-16-16v-32c0-8.84 7.16-16 16-16h32c8.84 0 16 7.16 16 16v32zm0-128c0 8.84-7.16 16-16 16h-32c-8.84 0-16-7.16-16-16v-32c0-8.84 7.16-16 16-16h32c8.84 0 16 7.16 16 16v32zm0-112H320V96h192v96z"} />
			</svg>,
		"construction":
			<svg className={`svg-inline--fa fa-construction fa-w-14 fa ${className}`} viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg" style={style}>
				<path xmlns="http://www.w3.org/2000/svg" d="M324 216a28 28 0 1 0-28-28 28 28 0 0 0 28 28zm-18.62 140.36l-83-53.67-29.8 109.11a16 16 0 0 0 11.22 19.64 15.8 15.8 0 0 0 4.2.56 16 16 0 0 0 15.43-11.8l18.25-66.89L288 383.23V416a16 16 0 0 0 32 0v-32.77a31.92 31.92 0 0 0-14.62-26.87zm135-9.26l-14 20.86L358.31 326l-10.53-52.75c-3.75-18.61-18.13-33.3-35.56-37.12l-24.59-7.3a48 48 0 0 0-48.78 18l-11.62 15.48a16 16 0 0 0 4.41 23.23l103.92 64 .09.07 70.67 43.48H385a19.13 19.13 0 0 0-18.21 12.51l-9.2 26.4h163.89l-54.32-84.64a16 16 0 0 0-26.75-.26zm190.67 80.78L367.37 25.3a57 57 0 0 0-94.71 0L8.89 427.89a52.87 52.87 0 0 0-2.31 54.88A56.23 56.23 0 0 0 56.29 512h527.45a56.23 56.23 0 0 0 49.71-29.27 52.82 52.82 0 0 0-2.37-54.85zm-39.84 32c-.66 1.24-2.72 4.08-7.5 4.08H56.29c-4.78 0-6.84-2.84-7.5-4.06a5.25 5.25 0 0 1 .25-5.75l263.77-402.6a9.06 9.06 0 0 1 14.41 0L591 454.19a5.27 5.27 0 0 1 .24 5.73z"/>
			</svg>,
		"box-3vert":
			<svg className={`svg-inline--fa fa-box-3vert fa-w-14 fa ${className}`} viewBox="0 0 448 512" xmlns="http://www.w3.org/2000/svg" style={style}>
				<path xmlns="http://www.w3.org/2000/svg" d="M432 360H16a16 16 0 0 0-16 16v32a16 16 0 0 0 16 16h416a16 16 0 0 0 16-16v-32a16 16 0 0 0-16-16zm0-128H16a16 16 0 0 0-16 16v32a16 16 0 0 0 16 16h416a16 16 0 0 0 16-16v-32a16 16 0 0 0-16-16zm0-128H16a16 16 0 0 0-16 16v32a16 16 0 0 0 16 16h416a16 16 0 0 0 16-16v-32a16 16 0 0 0-16-16z"/>
			</svg>,
		"box-check":
			<svg className={`svg-inline--fa fa-box-check fa-w-14 fa ${className}`} viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg" style={style}>
				<path d="M240 0H98.6c-20.7 0-39 13.2-45.5 32.8L2.5 184.6c-.8 2.4-.8 4.9-1.2 7.4H240V0zm235.2 81.7l-16.3-48.8C452.4 13.2 434.1 0 413.4 0H272v157.4C315.9 109.9 378.4 80 448 80c9.2 0 18.3.6 27.2 1.7zM208 320c0-34.1 7.3-66.6 20.2-96H0v240c0 26.5 21.5 48 48 48h256.6C246.1 468.2 208 398.6 208 320zm240-192c-106 0-192 86-192 192s86 192 192 192 192-86 192-192-86-192-192-192zm114.1 147.8l-131 130c-4.3 4.3-11.3 4.3-15.6-.1l-75.7-76.3c-4.3-4.3-4.2-11.3.1-15.6l26-25.8c4.3-4.3 11.3-4.2 15.6.1l42.1 42.5 97.2-96.4c4.3-4.3 11.3-4.2 15.6.1l25.8 26c4.2 4.3 4.2 11.3-.1 15.5z"/>
			</svg>,
		"google-maps":
			<svg className={`svg-inline--fa google-maps fa-w-14 fa ${className}`} viewBox="0 0 92.3 132.3" xmlns="http://www.w3.org/2000/svg" style={style}>
				<path fill="#1a73e8" d="M60.2 2.2C55.8.8 51 0 46.1 0 32 0 19.3 6.4 10.8 16.5l21.8 18.3L60.2 2.2z"/>
				<path fill="#ea4335" d="M10.8 16.5C4.1 24.5 0 34.9 0 46.1c0 8.7 1.7 15.7 4.6 22l28-33.3-21.8-18.3z"/>
				<path fill="#4285f4" d="M46.2 28.5c9.8 0 17.7 7.9 17.7 17.7 0 4.3-1.6 8.3-4.2 11.4 0 0 13.9-16.6 27.5-32.7-5.6-10.8-15.3-19-27-22.7L32.6 34.8c3.3-3.8 8.1-6.3 13.6-6.3"/>
				<path fill="#fbbc04" d="M46.2 63.8c-9.8 0-17.7-7.9-17.7-17.7 0-4.3 1.5-8.3 4.1-11.3l-28 33.3c4.8 10.6 12.8 19.2 21 29.9l34.1-40.5c-3.3 3.9-8.1 6.3-13.5 6.3"/>
				<path fill="#34a853" d="M59.1 109.2c15.4-24.1 33.3-35 33.3-63 0-7.7-1.9-14.9-5.2-21.3L25.6 98c2.6 3.4 5.3 7.3 7.9 11.3 9.4 14.5 6.8 23.1 12.8 23.1s3.4-8.7 12.8-23.2"/>
			</svg>,
		"cabinet-filling":
			<svg className={`svg-inline--fa fa-cabinet-filling fa-w-14 fa ${className}`} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" style={style}>
				<path d="M480 0H32C14.33 0 0 14.33 0 32v192h512V32c0-17.67-14.33-32-32-32zM352 152c0 4.42-3.58 8-8 8h-16c-4.42 0-8-3.58-8-8v-24H192v24c0 4.42-3.58 8-8 8h-16c-4.42 0-8-3.58-8-8v-40c0-8.84 7.16-16 16-16h160c8.84 0 16 7.16 16 16v40zM0 480c0 17.67 14.33 32 32 32h448c17.67 0 32-14.33 32-32V256H0v224zm160-112c0-8.84 7.16-16 16-16h160c8.84 0 16 7.16 16 16v40c0 4.42-3.58 8-8 8h-16c-4.42 0-8-3.58-8-8v-24H192v24c0 4.42-3.58 8-8 8h-16c-4.42 0-8-3.58-8-8v-40z"/>
			</svg>,
		"wifi-slash":
			<svg className={`svg-inline--fa fa-wifi-slash fa-w-14 fa ${className}`} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512" style={style}>
				<path d="M5.09 154.87c-6.66 6.16-6.79 16.59-.35 22.97l34.24 33.96c6.14 6.09 16.02 6.23 22.4.38 6.99-6.4 14.31-12.22 21.65-18.01l-64.96-50.21c-4.3 3.71-8.79 7.04-12.98 10.91zm471.75 181.9l45.42-45.21c6.52-6.46 6.29-17.06-.57-23.17-64.94-57.74-148.91-82.66-230.34-74.98l-83.16-64.27c125.94-38.36 267.75-11.01 370.43 83.05 6.38 5.85 16.26 5.71 22.4-.38l34.24-33.96c6.44-6.39 6.3-16.82-.35-22.97C496.46 26.82 298.08-.76 133.42 71.35L45.47 3.37C38.49-2.05 28.43-.8 23.01 6.18L3.37 31.45C-2.05 38.42-.8 48.47 6.18 53.9l588.36 454.73c6.98 5.43 17.03 4.17 22.46-2.81l19.64-25.27c5.42-6.97 4.17-17.03-2.81-22.45L476.84 336.77zm-358.53-68.38c-6.86 6.1-7.08 16.7-.57 23.17l34.28 34.01c5.97 5.93 15.59 6.32 21.94.8 13.35-11.6 28.01-20.66 43.15-28.55l-68.36-52.83c-10.48 7.15-20.74 14.78-30.44 23.4zM256 416c0 35.35 28.65 64 64 64 31.91 0 58.15-23.42 62.99-53.98l-88.7-68.56C271.77 367.37 256 389.82 256 416z"/>
			</svg>,
		"map-marker-slash":
			<svg className={`svg-inline--fa fa-map-marker-slash fa-w-14 fa ${className}`} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512" style={style}>
				<path d="M633.82 458.1L462.41 325.62C502.09 265.52 512 238.3 512 192 512 86.4 425.6 0 320 0c-68.2 0-128.24 36.13-162.3 90.12L45.47 3.37C38.49-2.05 28.43-.8 23.01 6.18L3.37 31.45C-2.05 38.42-.8 48.47 6.18 53.9l588.35 454.73c6.98 5.43 17.03 4.17 22.46-2.81l19.64-25.27c5.42-6.97 4.17-17.02-2.81-22.45zM300.8 502.4c9.6 12.8 28.8 12.8 38.4 0 18.6-26.69 35.23-50.32 50.14-71.47L131.47 231.62c10.71 52.55 50.15 99.78 169.33 270.78z"/>
			</svg>,
		"map-marker-edit":
			<svg className={`svg-inline--fa fa-map-marker-edit fa-w-14 fa ${className}`} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" style={style}>
				<path d="M192 0C86.4 0 0 86.4 0 192c0 76.8 25.6 99.2 172.8 310.4 9.6 12.8 28.8 12.8 38.4 0C358.4 291.2 384 268.8 384 192 384 86.4 297.6 0 192 0zm-43.17 283.15l-42.83 4.8c-5.78.62-10.57-4.27-9.95-9.95l4.8-42.83 85.54-85.54 47.98 47.98-85.54 85.54zm133.91-133.9l-28.26 28.26-47.98-47.98 28.26-28.26c7.02-7.02 18.39-7.02 25.41 0l22.57 22.57c7.02 7.01 7.02 18.39 0 25.41z"/>
			</svg>,
		"screen-rotation":
			<svg className={`svg-inline--fa fa-screen-rotation fa-w-14 fa ${className}`} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" preserveAspectRatio="xMidYMid meet" style={style}>
				<path d="M16.48 2.52c3.27 1.55 5.61 4.72 5.97 8.48h1.5C23.44 4.84 18.29 0 12 0l-.66.03 3.81 3.81 1.33-1.32zm-6.25-.77c-.59-.59-1.54-.59-2.12 0L1.75 8.11c-.59.59-.59 1.54 0 2.12l12.02 12.02c.59.59 1.54.59 2.12 0l6.36-6.36c.59-.59.59-1.54 0-2.12L10.23 1.75zm4.6 19.44L2.81 9.17l6.36-6.36 12.02 12.02-6.36 6.36zm-7.31.29C4.25 19.94 1.91 16.76 1.55 13H.05C.56 19.16 5.71 24 12 24l.66-.03-3.81-3.81-1.33 1.32z"/>
			</svg>,
		"box-5vert":
			<svg className={`svg-inline--fa fa-box-5vert fa-w-14 fa ${className}`} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 448" style={style}>
				<path d="M432,384H16A16,16,0,0,0,0,400v32a16,16,0,0,0,16,16H432a16,16,0,0,0,16-16V400A16,16,0,0,0,432,384Zm0-189H16A16,16,0,0,0,0,211v32a16,16,0,0,0,16,16H432a16,16,0,0,0,16-16V211A16,16,0,0,0,432,195Zm0-99H16A16,16,0,0,0,0,112v32a16,16,0,0,0,16,16H432a16,16,0,0,0,16-16V112A16,16,0,0,0,432,96Zm0-96H16A16,16,0,0,0,0,16V48A16,16,0,0,0,16,64H432a16,16,0,0,0,16-16V16A16,16,0,0,0,432,0Zm0,291H16A16,16,0,0,0,0,307v32a16,16,0,0,0,16,16H432a16,16,0,0,0,16-16V307A16,16,0,0,0,432,291Z"/>
			</svg>,
		"box-5vert-alt":
			<svg className={`svg-inline--fa fa-box-5vert fa-w-14 fa ${className}`} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 477.54" style={style}><title>5vert</title><g id="Layer_2" data-name="Layer 2"><g id="Layer_1-2" data-name="Layer 1"><path d="M370.29,376.91H13.71A13.71,13.71,0,0,0,0,390.63v27.43a13.71,13.71,0,0,0,13.71,13.71H370.29A13.71,13.71,0,0,0,384,418.06V390.63A13.71,13.71,0,0,0,370.29,376.91Zm0-109.71H13.71A13.71,13.71,0,0,0,0,280.91v27.43a13.71,13.71,0,0,0,13.71,13.72H370.29A13.71,13.71,0,0,0,384,308.34V280.91A13.71,13.71,0,0,0,370.29,267.2Zm0-109.71H13.71A13.71,13.71,0,0,0,0,171.2v27.43a13.71,13.71,0,0,0,13.71,13.71H370.29A13.71,13.71,0,0,0,384,198.63V171.2A13.71,13.71,0,0,0,370.29,157.49Zm0-109.72H13.71A13.71,13.71,0,0,0,0,61.49V88.91a13.71,13.71,0,0,0,13.71,13.72H370.29A13.71,13.71,0,0,0,384,88.91V61.49A13.71,13.71,0,0,0,370.29,47.77ZM340.27,439,91.14,11C85.85,1.86,76.25-2.42,69.7,1.39L46,15.19C39.45,19,38.44,29.46,43.73,38.55l249.13,428c5.29,9.09,14.89,13.37,21.44,9.56l23.7-13.8C344.55,458.54,345.56,448.08,340.27,439Z"/></g></g></svg>
	};

	return icons[iconName];
}
