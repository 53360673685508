import React from "react";
import PropTypes from "prop-types";
import Dropdown from "../Dropdown";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Thunk_Language_Fetch_All } from "../../../../store/thunk/Thunk_Language";

class DropdownPartner extends React.Component {
	constructor(props) {
		super(props);
	}

	render() {
		const {Thunk_Language_Fetch_All, path} = this.props;

		return (
			<Dropdown
				path={`${path || 'locale'}`}
				options={[]}
				thunk={() => Thunk_Language_Fetch_All}
				selector={state => state.language.data}
				onLabel={item => item.displayName}
				canInvalidate={true}
				{...this.props}
			/>
		)
	}

	static get propTypes() {
		return {
			...super.propTypes,
			path: PropTypes.string,
			defaultValue: PropTypes.any,
			onModify: PropTypes.func.isRequired,
			onValue: PropTypes.func,
			isActive: PropTypes.func,
		}
	}

	static get defaultProps() {
		return {
			...super.defaultProps,
			path: 'locale',
			isActive: (option, value) => option.languageCode == value,
			onValue: value => value && value.languageCode,
		}
	}
}

const mapDispatchToProps = dispatch => (
	bindActionCreators({
		Thunk_Language_Fetch_All
	}, dispatch)
);

export default connect(null, mapDispatchToProps)(DropdownPartner);
