import React from "react";
import ItemSelectInputBase from "../../shared/Input/Base/ItemSelectInputBase";
import ServiceTypeSelect from "./ServiceTypeSelect";

class ServiceTypeSelectInput extends React.Component {
	render() {
		return <ItemSelectInputBase
			modalContent={<ServiceTypeSelect/>}
			onLabel={value => value && window.translator.translateEntry(value.titles, null, true)}
			{...this.props}
		/>
	}
}

export default ServiceTypeSelectInput;
