import PropTypes from "prop-types";
import {API_URL} from "../services/apiServices/constants/endpoints";
import {E_ToastStyle} from "./Models_Toast";

/**
 * Action Button Confirm
 * ---
 * @type {{
 *          className: null|String,
 *          content: null|Element
 *          action: (function(): null),
 *       }}
 */
export const T_ActionButtonConfirm = {
	className: null,
	content: null,
	action: () => null
};

/**
 * Action Button model
 * ---
 * @type {{
 *          tippy: String|Element,
 *          confirm: null|<T_ActionButtonConfirm>,
 *          icon: String|Element,
 *          action: (function(): null),
 *          className: String,
 *          style: Object,
 *          text: String|Element,
 *          powerUser: Function,
 *          disabled: Boolean,
 *          disabledTippy: String|Element,
 *          preset: String,
 *          restricted: Boolean|Function
 *       }}
 */
export const T_ActionButton = {
	className: null, //ClassName
	style: null, //Style
	icon: null, //Icon
	tippy: null, //Tippy tooltip content
	text: null, //Text label next to the icon (if an icon is defined)
	confirm: null, //If action requires confirmation
	action: () => null, //Click action
	powerUser: () => null, //On right click event
	disabled: false,
	disabledTippy: null,
	preset: null,
	restricted: false,
	applyRestrictions: true,
};

/**
 * Modal options model
 * ---
 * @type {{
 *          closeButton: Boolean,
 *          closeOnBackdropClick: Boolean,
 *          body: Element,
 *          actionButtons: Array<T_ActionButton|Element>
 *        }}
 */
export const T_ModalOptionsModel = {
	closeOnBackdropClick: true,
	closeButton: true,
	body: null,
	actionButtons: []
};

/**
 * Listing content sort
 * @type {{
 *          unsorted: Boolean,
 *          sorted: Boolean,
 *          empty: Boolean
 *       }}
 */
export const T_ListingContentSort = {
	sorted: false,
	unsorted: true,
	empty: true
};

/**
 * Listing content pageable
 * @type {{
 *          paged: Boolean,
 *          pageNumber: Number,
 *          offset: Number,
 *          pageSize: Number,
 *          unpaged: Boolean,
 *          sort: <T_ListingContentSort>
 *       }}
 */
export const T_ListingContentPageable = {
	offset: 0,
	pageNumber: 0,
	pageSize: 20,
	paged: true,
	sort: {},
	unpaged: true
};

/**
 * Listing content model
 * ---
 * @type {{
 *          number: Number,
 *          last: Boolean,
 *          numberOfElements: Number,
 *          size: Number,
 *          totalPages: Number,
 *          pageable: <T_ListingContentPageable>,
 *          sort: <T_ListingContentSort>,
 *          content: Array,
 *          first: Boolean,
 *          empty: Boolean,
 *          totalElements: Number
 *       }}
 */
export const T_ListingContentModel = {
	content: [],
	empty: false,
	first: true,
	last: true,
	number: 0,
	numberOfElements: 0,
	pageable: {},
	size: 20,
	sort: {},
	totalElements: 0,
	totalPages: 1
};

/**
 * Network Response Model
 * ---
 * @type {{
 *          headers: {},
 *          bodyUsed: Boolean,
 *          redirected: Boolean,
 *          statusText: String,
 *          body: {},
 *          ok: Boolean,
 *          type: String,
 *          url: String,
 *          status: Number
 *       }}
 */
export const T_NetworkResponseModel = {
	body: {},
	bodyUsed: false,
	headers: {},
	ok: false,
	redirected: false,
	status: 0,
	statusText: '',
	type: "cors",
	url: API_URL
};

export const T_FilterItemModel = {
	icon: '', //Filter field icon
	label: '', //Filter field label
	resetValue: '', //Value that will be set upon reset (either by pressing X on the element or by resetting all the filters)
	value: '', //Default value that will be applied
	field: '', //SQL key
	operator: '=', //SQL operator
	type: '', //Input type
	restricted: null, //Permissions restriction (Object, Bool, Func)
	queryValue: null, //Value set from query string
};

export const T_SharedTippyProps = {
	arrow: true,
	duration: 200,
	theme: "cubesave"
};

/**
 * Toast Model
 * ---
 * @type {{
 *     content: Element,
 *	   time: Number,
 *	   style: E_ToastStyle,
 *	   icon: String,
 *	   action: Function,
 * }}
 */
export const T_ToastModel = {
	content: null,
	time: 5000,
	style: E_ToastStyle.DEFAULT,
	icon: "bug",
	action: () => null,
};

export const T_AddressModel = {
	city: null,
	country: null,
	street: null,
	floor: null,
	houseNumber: null,
	postalCode: null,
};

/**
 * Image Model
 * ---
 * @type {{imageUrl: String, id: Number, thumbnailUrl: String}}
 */
export const T_ImageModel = {
	id: null,
	imageUrl: null,
	thumbnailUrl: null
};

/**
 * Logo Image Model
 * ---
 * @type {{
 *          visible: Boolean,
 *          imageUrl: String,
 *          id: Number,
 *          partnerID: Number,
 *          ownerID: Number,
 *          createdOn: String<Date>,
 *          thumbnailUrl: String
 *       }}
 */
export const T_LogoImageModel = {
	...T_ImageModel,
	createdOn: null,
	id: null,
	ownerID: null,
	partnerID: null,
	visible: true
};

/**
 * Display text
 * ---
 * @type {{id: Number, text: String, languageCode: String}}
 */
export const T_DisplayText = {
	id: null,
	languageCode: null,
	text: null
};

export const T_SharedPropTypes = {
	className: PropTypes.string,
	style: PropTypes.object,
	children: PropTypes.any,
};

export const T_SharedRouterPropTypes = {
	match: PropTypes.any.isRequired,
	history: PropTypes.any.isRequired,
	location: PropTypes.any,
};

export const T_SharedReduxPropTypes = {
	fetched: PropTypes.bool,
	fetching: PropTypes.bool,
	failed: PropTypes.bool,
	deleted: PropTypes.number,
	edit: PropTypes.object,
	error: PropTypes.object,
};

export const E_PaymentState = {
	PENDING: "PENDING",
	ERROR: "ERROR",
	SUCCESS: "SUCCESS"
};

/**
 * Header Item
 * ---
 * Table listing header item.
 *
 * Some props are used in another listings as well (search)
 * @type {{
 *          filter: Boolean,
 *          visible: Boolean,
 *          width: Number|String,
 *          label: String|Function|Element,
 *          icon: String|Function|Element,
 *          sort: Boolean,
 *          path: String,
 *          field: String,
 *          key: String,
 *          canModify: Boolean,
 *          metaLabel: String|Function|Element,
 *          restricted: Object|Boolean|function(item),
 *       }}
 */
export const T_ListingHeaderItem = {
	label: null,
	icon: null,
	filter: true,
	sort: true,
	visible: true,
	path: null,
	width: 4, // css flex: {value} or max-width: {value} if {value} is not a Number
	field: null,
	key: null, //Optional, will be generated at runtime if missing
	canModify: true, //If true, this column can toggle it's visibility (f.e. used in TableColumnsSetup.js as of v0.2.0 [24.4.2020])
	metaLabel: '', //Label used in other places (f.e. used in TableColumnsSetup.js as of v0.2.0 [24.4.2020]). If label is defined this will be ignored.
	restricted: false,
};

/**
 * @enum
 * @type {{NUMERIC: string, TEXT: string, DEFAULT: string}}
 */
export const T_SortType = {
	DEFAULT: "DEFAULT",
	NUMERIC: "NUMERIC",
	TEXT: "TEXT",
};

export const T_SortTypeIcons = {
	DEFAULT: {asc: "sort-amount-up-alt", desc: "sort-amount-down-alt"},
	NUMERIC: {asc: "sort-numeric-up-alt", desc: "sort-numeric-down-alt"},
	TEXT: {asc: "sort-alpha-down", desc: "sort-alpha-up"},
};

export const T_SharedCustomErrorCodes = {
	GEOLOCATION_DISABLED: 1000
};

export const T_DateOptions_DateOnly = {
	year: "numeric",
	month: "numeric",
	day: "numeric",
};
