import React from 'react'
import ReactDOM from 'react-dom';
// eslint-disable-next-line no-unused-vars
import PropTypes from 'prop-types'
import '../../../styles/components/Toast.scss';
import {withLocalize} from "react-localize-redux";
import CSBaseComponent from "../../CSBaseComponent";
import ToastEntity from "./ToastEntity";

class Toaster extends CSBaseComponent {
	constructor(props) {
		super(props);
		window.toaster = this;

		this.toasts = [];

		this._showToast = this._showToast.bind(this);
	}

	render() {
		return <div id={"toaster"} ref={this.setRef} />
	}

	/**
	 * @private
	 * Show Toast
	 * ---
	 */
	_showToast(props) {
		if (this.props.max && this.toasts.length >= this.props.max) {
			this._removeToast(this.toasts[0]);
		}

		let parent = document.createElement("div");

		props = {
			parent: parent,
			toaster: this,
			time: 5000,
			...(props || {})
		};

		let toast = React.cloneElement(<ToastEntity toast={props}/>);
		ReactDOM.render(toast, parent);
		this.root.appendChild(parent);
		this.toasts.push(toast);
		return toast;
	}

	/**
	 * Show Toast
	 * ---
	 * @param {T_ToastModel} props
	 * @returns {*}
	 */
	showToast(props) {
		return this._showToast(props);
	}

	removeToast(item) {
		ReactDOM.unmountComponentAtNode(item.props.toast.parent)
	}

	unmountToast(item) {
		this.toasts.removeItem(item);
	}

	_removeToast(item) {
		if (item && item.removeToastInit) {
			item.removeToastInit();
		}
	}
}

Toaster.propTypes = {
	max: PropTypes.number
};

Toaster.stateTypes = {
	toasts: PropTypes.array
};

export default withLocalize(Toaster);
