export function getJSONHeaders() {
	let token = localStorage.getItem('token');

	const config = new Headers({
		Accept: 'application/json',
		'Content-Type': 'application/json'
	});

	if (token) {
		config.append('x-auth-token', token);
	}

	return config;
}

export function getFileHeaders() {
	let token = localStorage.getItem('token');

	const config = new Headers({
		Accept: 'application/json',
	});

	if (token) {
		config.append('x-auth-token', token);
	}

	return config;
}


// used when logging in
export function getUrlEncodedHeaders() {
	let token = localStorage.getItem('token');

	const config = new Headers({
		Accept: 'application/json',
		'Content-type': 'application/x-www-form-urlencoded'
	});

	if (token) {
		config.append('x-auth-token', token);
	}

	return config;
}
