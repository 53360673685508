import { service_deleteServiceType, service_fetchServiceType, service_fetchServiceTypes, service_insertServiceType, service_updateServiceType } from "../../services/Service_ServiceType";
import { Action_ServiceType_Edit_Fulfilled, Action_ServiceType_Edit_Pending, Action_ServiceType_Edit_Rejected, Action_ServiceType_Fetch_Fulfilled, Action_ServiceType_Fetch_Pending, Action_ServiceType_Fetch_Rejected, Action_ServiceTypes_Fetch_Fulfilled, Action_ServiceTypes_Fetch_Pending, Action_ServiceTypes_Fetch_Rejected } from "../actions/configuration/serviceTypesActions";
import { Thunk_Generic_Edit_Insert, Thunk_Generic_Edit_Save, Thunk_Generic_Edit_Update, Thunk_Generic_Fetch_All, Thunk_Generic_Fetch_Item, Thunk_Generic_Remove } from "./Thunk_Generic";
import { Selector_ServiceType_Edit } from "../selectors/Selectors_ServiceType";

export const Thunk_ServiceTypes_Fetch_All = queryString => (
	Thunk_Generic_Fetch_All(
		queryString,
		service_fetchServiceTypes,
		Action_ServiceTypes_Fetch_Pending,
		Action_ServiceTypes_Fetch_Fulfilled,
		Action_ServiceTypes_Fetch_Rejected
	)
);

export const Thunk_ServiceType_Fetch = serviceTypeID => (
	Thunk_Generic_Fetch_Item(
		serviceTypeID,
		service_fetchServiceType,
		Action_ServiceType_Fetch_Pending,
		Action_ServiceType_Fetch_Fulfilled,
		Action_ServiceType_Fetch_Rejected,
	)
);

export const Thunk_ServiceType_Insert = () => (
	Thunk_Generic_Edit_Insert(
		service_insertServiceType,
		Action_ServiceType_Edit_Pending,
		Action_ServiceType_Edit_Fulfilled,
		Action_ServiceType_Edit_Rejected,
		Selector_ServiceType_Edit
	)
);

export const Thunk_ServiceType_Update = () => (
	Thunk_Generic_Edit_Update(
		service_updateServiceType,
		Action_ServiceType_Edit_Pending,
		Action_ServiceType_Edit_Fulfilled,
		Action_ServiceType_Edit_Rejected,
		Selector_ServiceType_Edit
	)
);

export const Thunk_ServiceType_Save = () => (
	Thunk_Generic_Edit_Save(
		Selector_ServiceType_Edit,
		Action_ServiceType_Edit_Pending,
		Thunk_ServiceType_Insert,
		Thunk_ServiceType_Update
	)
);

export const Thunk_ServiceType_Remove = (serviceTypeID) => (
	Thunk_Generic_Remove(
		serviceTypeID,
		service_deleteServiceType,
		Action_ServiceType_Edit_Pending,
		Action_ServiceType_Edit_Fulfilled,
		Action_ServiceType_Edit_Rejected
	)
);
