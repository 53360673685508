import { E_UserRoles } from "./constants/Constants_Shared";

export const E_UserPermissionOption = {
	DISABLED: "DISABLED",
	READ: "READ",
	READ_WRITE: "READ_WRITE"
};

export const E_UserPermissions = {
	ROLE: "role",
	PARTNER_ID: "partnerID",
	LOCK_CONTROL: "lockControl",
	LOCATIONS: "locations",
	SESSIONS: "sessions",
	GLOBAL_CONFIGURATION: "globalConfiguration",
	TARIFFS: "tariffs",
	USERS: "users",
	VOUCHERS: "vouchers",
	FEEDBACK: "feedback",
	PARTNERS: "partners",
};

export const T_AnonymousPermissions = {
	[E_UserPermissions.ROLE]: E_UserRoles.ANONYMOUS,
	[E_UserPermissions.PARTNER_ID]: null,
	[E_UserPermissions.LOCK_CONTROL]: false,
	[E_UserPermissions.LOCATIONS]: E_UserPermissionOption.DISABLED,
	[E_UserPermissions.SESSIONS]: E_UserPermissionOption.DISABLED,
	[E_UserPermissions.GLOBAL_CONFIGURATION]: E_UserPermissionOption.DISABLED,
	[E_UserPermissions.TARIFFS]: E_UserPermissionOption.DISABLED,
	[E_UserPermissions.USERS]: E_UserPermissionOption.DISABLED,
	[E_UserPermissions.VOUCHERS]: E_UserPermissionOption.DISABLED,
	[E_UserPermissions.FEEDBACK]: E_UserPermissionOption.DISABLED,
	[E_UserPermissions.PARTNERS]: E_UserPermissionOption.DISABLED,
}

export const T_UserPermissions = {
	...T_AnonymousPermissions,
	[E_UserPermissions.ROLE]: E_UserRoles.USER,
	[E_UserPermissions.LOCATIONS]: E_UserPermissionOption.READ,
};

/**
 * User Model
 * ---
 * @type {{
 *          forename: String,
 *          agreedToMarketing: Boolean,
 *          permissions: T_UserPermissions,
 *          surname: String,
 *          fullName: String,
 *          paymentMethod: <T_PaymentMethod>,
 *          id: Number|null,
 *          locale: String,
 *          email: String,
 *          emailVerified: Boolean,
 *          notifications: <T_NotificationsModel>,
 *          creditBalance: {[currency]:Number}[]
 *       }}
 */
export const T_UserModel = {
	agreedToMarketing: false,
	creditBalance: [],
	email: null,
	emailVerified: false,
	password: null,
	forename: null,
	fullName: null,
	id: null,
	locale: null,
	notifications: null,
	paymentMethod: null,
	permissions: T_UserPermissions,
	surname: null,
	enabled: true,
};

/**
 * User Account Model
 * ---
 * @type {{forename: String, surname: String, id: Number, email: String}}
 */
export const T_UserAccountModel = {
	email: null,
	forename: null,
	surname: null,
	id: null,
};

/**
 * type enum
 * ---
 * @enum string
 * @readonly
 */
export const E_UserInfo_UserType = {
	COURIER: "COURIER",
	CUSTOMER: "CUSTOMER",
};


/**
 * UserInfo model
 * ---
 * @type {{
 * 	id?: number,
 * 	type: E_UserInfo_UserType,
 * 	email?: string,
 * 	phone?: string,
 * 	locale?: string
 * }}
 */
export const T_UserInfo = {
	id: undefined,
	type: E_UserInfo_UserType.COURIER,
	email: undefined,
	phone: undefined,
	locale: undefined,
};
