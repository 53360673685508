import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import {T_FilterItemModel, T_ListingHeaderItem, T_SharedRouterPropTypes} from "../../models/Models_Shared";
import {EO} from "../../utils/extensions";
import {Process_formatFilter, Process_formatLIKEQuerySelector, Process_formatQueryString, Process_formatQueryStringForURL} from "../../utils/processors";
import is from "../../utils/is";
import {get, hasOwnProperty} from "../../utils/utils";
import CSBaseComponent from "../CSBaseComponent";
import ListingFooter from "../shared/Listing/ListingFooter";
import CubeSpinner from "../shared/LoadingIndicator/CubeSpinner";
import Filter from "./Filter/Filter";
import QS from "query-string";
import {CS_FILTERS_QUERY_SEPARATOR, E_APIServiceActions} from "../../models/constants/Constants_Shared";
import {withRouter} from "react-router-dom";
import {errorHandler} from "../../store/ResponseHandling";

class Subscriber extends CSBaseComponent {
	constructor(props) {
		super(props);

		this.state = {
			...this.state,
			fetching: true,
			...this._constructInitialStates(props),
			applyFilters: true,
		};
		this._lastQS = window.location.search;
		this._handlePopState = this._handlePopState.bind(this);
	}

	componentDidMount() {
		super.componentDidMount();
		const {history} = this.props;

		this._subscribe();
		this._historyListener = history.listen(this._handlePopState);
		this._handlePopState();
	}

	componentWillUnmount() {
		super.componentWillUnmount();
		this.subscriber && this.subscriber.call && this.subscriber();
		this._historyListener();
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		const {size, activePage, sort, search, data, filtersData} = this.state;

		if(!is.equal(prevProps.search, this.props.search)) {
			this.setState({search: this.props.search});
			return;
		}

		if (
			!is.equal(prevState.search, search) ||
			!is.equal(prevState.size, size) ||
			!is.equal(prevState.activePage, activePage) ||
			!is.equal(prevState.sort, sort) ||
			!is.equal(prevState.filtersData.extract("queryValue"), filtersData.extract("queryValue")) ||
			!is.equal(prevState.filtersData.extract("value"), filtersData.extract("value"))
		) {
			this.applyFilters();
			return;
		}

		if(data && data.totalPages - 1 < activePage && activePage !== 0) {
			this.setState({activePage: data.totalPages - 1});
			return;
		}
	}

	_renderFooter() {
		const {footer} = this.props;
		const {size, data, filtersApplied} = this.state;

		if (footer) {
			let footerProps = {
				size,
				data,
				middlePagesLimit: get(footer, `options.middlePagesLimit`) || undefined,
				filtersApplied,
				onSizeChange: size => this.setState({size}),
				onPageChange: activePage => this.setState({activePage}),
				onClearFilters: () => this.clearFilters()
			};


			if (is.object(footer)) {
				if (is.valid(footer.content)) {
					return React.cloneElement(footer.content, footerProps);
				}

				if (hasOwnProperty(footer, "props")) {
					return React.cloneElement(footer, footerProps);
				}
			}
			return <ListingFooter {...footerProps} />
		}
	}

	_renderContent() {
		const {children} = this.props;
		const {data, failed, fetched, error, fetching, sort} = this.state;

		return (Array.isArray(children) ? children : [children]).map((child, i) => {
			let props = {
				key: i,
				items: data && data.content || [],
				data,
				sort,
				failed,
				fetched,
				fetching,
				error,
				onRetry: () => this.applyFilters(),
				onSort: (field, direction) => this.setState({sort: direction ? `${field},${direction}` : ''}),
			};

			if(hasOwnProperty(child, "props")) {
				return React.cloneElement(child, props)
			} else if(typeof child == "function") {
				return child(props)
			}
			return null;
		})
	}

	render() {
		const {filtersOpen, onFiltersClosed, inlineSpinner} = this.props;
		const {fetching, fetched, filtersData, applyFilters} = this.state;

		return (
			<Fragment>
				{
					inlineSpinner &&
					((!fetching || fetched) && this._renderContent() || <CubeSpinner block={true} style={{margin: "40px auto"}} />)
					||
					this._renderContent()
				}
				{this._renderFooter()}
				{
					<Filter
						filters={filtersData}
						filtersOpen={filtersOpen}
						applyFilters={applyFilters}
						onFiltersApply={this._handleFiltersApply.bind(this)}
						onFiltersReady={this._handleFiltersApply.bind(this)}
						onFiltersClosed={onFiltersClosed}
					/>
				}
				{
					!inlineSpinner && fetching && <CubeSpinner style={{marginBottom: 40}}/>
				}
			</Fragment>
		);
	}

	applyFilters() {
		this.setState(state => ({applyFilters: !state.applyFilters}));
	}

	clearFilters() {
		this._handleFiltersApply([]);
	}

	_subscribe() {
		const {rootSelector, dataSelector, dataPath} = this.props;

		if(rootSelector) {
			window.Database.subscribe( state => {
				if(!this._isMounted){return;}

				let rootData = rootSelector(state) || {};
				this.setState({
					...rootData,
					data: dataSelector ? dataSelector(state) : get(rootData, dataPath || '', {}),
				});
			}, ref => this.subscriber = ref);
		}
	}

	_combineQueryStringObjects(filters) {
		const {size, activePage, sort, search} = this.state;
		const {mergeSearch, headerItems, history, queryNotify} = this.props;

		let searchFilters = [];
		if (!mergeSearch && is.valid(search)) {
			EO(headerItems).toArray().forEach(item => {
				let {key, value} = item;
				if (value.filter === false) {
					return;
				}

				let fullData = {...T_ListingHeaderItem, ...value};
				if (fullData.filter === false) {
					return;
				}

				searchFilters.push({
					field: value.field || key,
					operator: "LIKE",
					value: Process_formatLIKEQuerySelector(search),
					join: "OR"
				})
			});
		}

		let querySharedData = {
			size,
			page: activePage,
			sort,
		};

		let queryData = {
			filters: [...(searchFilters || []), ...(filters || [])].map(filter => Process_formatFilter(filter)),
			...querySharedData,
			...(mergeSearch && {search})
		};

		if(is.valid(searchFilters) && is.valid(filters)) {
			queryData.filters = [
				{filters: (filters || []).map(filter => Process_formatFilter(filter))},
				{filters: searchFilters, join: "AND"}
			]
		}

		let urlQuery = Process_formatQueryStringForURL({
			filters: (filters || []).map(filter => (`${filter.key}:${filter.value}`.replace(/%/g, ''))).join(CS_FILTERS_QUERY_SEPARATOR),
			...querySharedData,
			search
		}, {
			...this.props,
			filters: [],
			page: 0,
			search: ''
		});

		if(queryNotify && !is.equal(urlQuery, window.location.search.replace('?',''))) {
			if(window.location.search == "" ? urlQuery != "" : true) {
				history.push({
					search: is.valid(urlQuery) ? '?' + urlQuery : ''
				});
			}
		}

		return queryData;
	}

	_constructInitialStates(props) {
		const parsedQS = QS.parse(window.location.search);
		this.props.onQueryParse(parsedQS);

		return {
			size: parseInt(parsedQS.size || props.size),
			activePage: parseInt(parsedQS.page || props.activePage),
			filtersApplied: !!parsedQS.filters,
			sort: parsedQS.sort || props.sort,
			search: parsedQS.search || props.search || '',
			filtersData: this._processFiltersData(props.filters),
		}
	}

	_handlePopState() {
		const {onQueryParse, useQuery} = this.props;

		if(!useQuery) return;

		const parsedQS = QS.parse(window.location.search);

		if(!is.equal(window.location.search, this._lastQS) && this._isMounted) {
			onQueryParse(parsedQS);
			this.setState(() => this._constructInitialStates(this.props));
		}
		this._lastQS = window.location.search;
	}

	_processFiltersData(filtersData) {
		const parsedQS = QS.parse(window.location.search);
		let filters = (parsedQS.filters || '').split(CS_FILTERS_QUERY_SEPARATOR).map(filterString => {
			let [key, value] = filterString.split(":");
			return key ? {key, value} : null;
		}).filter(i => i !== null);
		let filtersQueryData = EO().fillFromArray(filters);

		return EO(filtersData).clone(true, true).toArray().map((filterData) => {
			const {key, value: filter} = filterData;

			return {
				...T_FilterItemModel,
				key,
				...filter,
				type: filtersData[key].type,
				queryValue: filtersQueryData[key] || filter.queryValue,
			}
		});
	}

	_handleFiltersApply(filters) {
		const {thunk, onFiltersApply, service} = this.props;

		onFiltersApply(filters, this._combineQueryStringObjects(filters));
		this.setState({fetching: true, filtersApplied: filters.length > 0, filters, error: {}});

		if(service) {
			service(Process_formatQueryString(this._combineQueryStringObjects(filters))).then(data => {
				this.setState({
					fetching: false,
					fetched: true,
					failed: false,
					error: {},
					data: hasOwnProperty(data, "content") ? data : {content: data},
				});
			}, error => {
				errorHandler(error, null, {action: {type: E_APIServiceActions.ITEMS_FETCH}});
				this.setState({fetching: false, data: null, fetched: true, failed: true, error});
			});
		} else {
			thunk()(Process_formatQueryString(this._combineQueryStringObjects(filters)));
		}
	}

	static get sharedTypes() {
		return {
			size: PropTypes.number,
			activePage: PropTypes.number,
		}
	}

	static get propTypes() {
		return {
			...super.propTypes,
			...this.sharedTypes,
			...T_SharedRouterPropTypes,
			thunk: PropTypes.func,
			rootSelector: PropTypes.func,
			dataSelector: PropTypes.func,
			dataPath: PropTypes.string, //Path for data array (used if dataSelector is not defined); Defaults to 'data'
			service: PropTypes.any, //Promise

			/**
			 * @type {T_FilterItemModel}
			 */
			filters: PropTypes.object,
			filtersOpen: PropTypes.bool,
			children: PropTypes.any,
			/**
			 * @type{Boolean|Element}
			 * [true => default footer]
			 * @see ListingFooter
			 **/
			footer: PropTypes.any,
			mergeSearch: PropTypes.bool,
			inlineSpinner: PropTypes.bool,

			onFiltersApply: PropTypes.func,
			onFiltersClosed: PropTypes.func,
			queryNotify: PropTypes.bool,
			useQuery: PropTypes.bool,

			onQueryParse: PropTypes.func,
		}
	}

	static get stateTypes() {
		return {
			...super.stateTypes,
			...this.sharedTypes,
			fetching: PropTypes.bool,
			filtersApplied: PropTypes.bool,
			sort: PropTypes.string,
			applyFilters: PropTypes.bool,
		}
	}

	static get defaultProps() {
		return {
			...super.defaultProps,
			size: 20,
			activePage: 0,
			mergeSearch: false,
			onFiltersApply: (filters, query) => null,
			queryNotify: true,
			sort: "id,asc",
			dataPath: "data",
			inlineSpinner: false,
			onQueryParse: () => null,
		};
	}
}

export default withRouter(Subscriber);
