// eslint-disable-next-line no-unused-vars
import { DELETE, formatQueryString, POST, PUT, sendRequest } from "./apiServices/apiConnector";

export const service_fetchExternalLocations = (queryString = null) => {
	return sendRequest(`/location/external/${formatQueryString(queryString)}`).then(response => response.json());
};

export const service_fetchExternalLocation = (externalLocationID) => {
	return sendRequest(`/location/external/${externalLocationID}`).then(response => response.json());
};

export const service_insertExternalLocation = (body) => {
	return sendRequest([`/location/external/`, POST, body]).then(response => response.json());
};

export const service_updateExternalLocation = (externalLocationID, body) => {
	return sendRequest([`/location/external/${externalLocationID}`, PUT, body]).then(response => response.json());
};

export const service_deleteExternalLocation = (externalLocationID) => {
	return sendRequest([`/location/external/${externalLocationID}`, DELETE]).then(response => response);
};
