import { E_Visibility } from "./Models_Location";
import PropTypes from "prop-types";

export const SINGLE = "SINGLE";
export const TERMINAL = "TERMINAL";
export const BIKE_BOX = "BIKE_BOX";
export const NEW = "NEW";
export const DOUBLE_VERTICAL = "DOUBLE_VERTICAL";
export const TRIPLE_VERTICAL = "TRIPLE_VERTICAL";
export const QUADRUPLE_VERTICAL = "QUADRUPLE_VERTICAL";
export const FOUR_RECTANGLES = "FOUR_RECTANGLES";

export const E_FrameOptions = {
	FOUR_RECTANGLES,
	TERMINAL,
	BIKE_BOX,
	SINGLE_UNIT: "SINGLE_UNIT"
};

export const E_BlockOptions = {
	SINGLE,
	DOUBLE_VERTICAL,
	TRIPLE_VERTICAL,
	QUADRUPLE_VERTICAL,
	QUINTUPLE_VERTICAL: "QUINTUPLE_VERTICAL",
	CUSTOM_HEIGHT: "CUSTOM_HEIGHT",
};

export const E_Side = {
	LEFT: "LEFT",
	RIGHT: "RIGHT",
	FRONT: "FRONT",
	BACK: "BACK",
};

export const T_blockModel = {
	blockType: null,
	services: []
};

export const T_frameModel = {
	blocks: [],
	frameType: null,
	locationIdentifier: null,
	name: null,
	side: null,
	visibilityType: E_Visibility.VISIBLE
};

export const T_unitModel = {
	frames: [],
	locationIdentifier: null,
	name: null,
	visibility: E_Visibility.HIDDEN,
	enabled: true,
};

export const T_frameTypeIcons = {
	NEW: "plus",
	[E_FrameOptions.BIKE_BOX]: "bicycle",
	[E_FrameOptions.TERMINAL]: "cash-register",
	[E_FrameOptions.FOUR_RECTANGLES]: "th-large",
	[E_FrameOptions.SINGLE_UNIT]: "square",
};

export const T_frameTypeBlocksCount = {
	[E_FrameOptions.BIKE_BOX]: 1,
	[E_FrameOptions.TERMINAL]: 0,
	[E_FrameOptions.FOUR_RECTANGLES]: 4,
	[E_FrameOptions.SINGLE_UNIT]: 1,
};

export const T_blockTypeServiceCount = {
	[E_BlockOptions.SINGLE]: 1,
	[E_BlockOptions.DOUBLE_VERTICAL]: 2,
	[E_BlockOptions.TRIPLE_VERTICAL]: 3,
	[E_BlockOptions.QUADRUPLE_VERTICAL]: 4,
	[E_BlockOptions.QUINTUPLE_VERTICAL]: 5,
	[E_BlockOptions.CUSTOM_HEIGHT]: 6,//FFS: Should be dynamic
};

//Icons according to Frame type
export const T_frameIcons = {
	[E_FrameOptions.BIKE_BOX]: "bicycle",
	[E_FrameOptions.TERMINAL]: "cash-register",
	NEW: "plus"
};

//Icons according to Block type
export const T_blockTypeIcons = {
	[E_BlockOptions.SINGLE]: "square",
	[E_BlockOptions.DOUBLE_VERTICAL]: "equals",
	[E_BlockOptions.TRIPLE_VERTICAL]: "cs-box-3vert",
	[E_BlockOptions.QUADRUPLE_VERTICAL]: "align-justify",
	[E_BlockOptions.QUINTUPLE_VERTICAL]: "cs-box-5vert",
	[E_BlockOptions.CUSTOM_HEIGHT]: "cs-box-6vert",
};

//Block type whitelist for frameType (If frameType is not defined here, all block options are available!)
export const T_FrameTypeBlockOptions = {
	[E_FrameOptions.BIKE_BOX]: [E_BlockOptions.SINGLE],
}

//Icons according to Service
export const T_ServiceIcons = {};

export const T_StorageUnitSharedProps = {
	customProps: PropTypes.object,
	onFrameClick: PropTypes.func,
	onEmptyFrameClick: PropTypes.func,
	onBlockClick: PropTypes.func,
	onEmptyBlockClick: PropTypes.func,
	onServiceClick: PropTypes.func,
}
