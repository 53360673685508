import Tippy from "../../shared/Tippy";
import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { Translate } from "react-localize-redux";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";
import { bindActionCreators } from "redux";
import { E_Currency, E_UserRoles, T_APP_ICONS } from "../../../models/constants/Constants_Shared";
import { T_SharedReduxPropTypes, T_SharedTippyProps } from "../../../models/Models_Shared";
import { E_OpenBoxModes, E_SessionState, E_SessionType, T_SessionModel } from "../../../models/Models_Session";
import routes from "../../../routes/routes";
import { Action_Session_Clear, Action_Session_Edit_Cancel, Action_Session_Edit_Fulfilled, Action_Session_Edit_Init, Action_Session_Edit_Modify, Action_Session_Fetch_Fulfilled, Action_Session_Fetch_Pending, Action_Session_Fetch_Rejected, E_SessionActions, SESSION_COMPLETE_REJECTED, SESSION_MARK_AS_DELIVERED_REJECTED } from "../../../store/actions/sessions/sessionsActions";
import { Selector_Session_Data, Selector_Session_Edit, Selector_Session_Root } from "../../../store/selectors/Selectors_Sessions";
import { Thunk_Partners_Fetch_All } from "../../../store/thunk/Thunk_Partners";
import { Thunk_Session_Fetch } from "../../../store/thunk/Thunk_Sessions";
import is from "../../../utils/is";
import { copyToClipboard, css, formatAsPrice, get, resolveItemName } from "../../../utils/utils";
import ButtonsConstructor from "../../shared/ButtonsConstructor";
import Fa from "../../tools/Icons/Fa";
import "../../../styles/pages/Session/SessionDetail.scss";
import SummaryTable from "../../shared/SummaryTable";
import {service_completeDeliverySessionFromPin, service_completeSession, service_downloadSessionInvoice, service_fetchSessionTransactions, service_openSessionServiceBlock} from "../../../services/Service_Sessions";
import { EO } from "../../../utils/extensions";
import { E_ToastStyle } from "../../../models/Models_Toast";
import Toolbar from "../../shared/Toolbar";
import DetailWrapper from "../../shared/DetailWrapper";
import TableListing from "../../shared/Listing/TableListing";
import Subscriber from "../../tools/Subscriber";
import { E_ServiceActions } from "../../../store/actions/location/serviceActions";
import { errorHandler } from "../../../store/ResponseHandling";
import Restricted from "../../shared/Restrictions/Restricted";
import { T_RestrictionPresets } from "../../../models/Models_Restrictions";
import TableRow from "../../shared/Listing/TableRow";
import TableColumn from "../../shared/Listing/TableColumn";
import CSBaseComponent from "../../CSBaseComponent";
import ItemRedirect from "../../shared/ItemRedirect";
import { service_sendMaintenanceSessionNotify } from "../../../services/Service_Maintenance";
import { Process_msToDuration } from "../../../utils/processors";

class SessionDetail extends CSBaseComponent {
	constructor(props) {
		super(props);

		this.state = {
			...this.state,
			diff: 0,
			hasTransactions: false,
			sending: false,
		};
	}

	componentDidMount() {
		super.componentDidMount();
		this._initializeSession();
	}

	componentWillUnmount() {
		super.componentWillUnmount();
		const {Action_Session_Clear} = this.props;

		clearInterval(this.clock);
		Action_Session_Clear();
	}

	_renderToolbar() {
		const {edit} = this.props;
		const {sending} = this.state;
		const isEdit = is.valid(edit);
		const session = isEdit ? edit : this.props.session;

		return <Toolbar
			isEdit={isEdit}
			backUrl={routes.SESSIONS}
			content={<h1><Translate id={"session_detail"}/></h1>}
			actionButtons={[
				[E_SessionType.DELIVERY_COURIER].includes(session.sessionType) &&
				[E_SessionState.ACTIVE].includes(session.state) &&
				{
					icon: "truck-loading",
					tippy: <Translate id={"session_complete_passToCustomer"}/>,
					action: () => this._openMarkAsDeliveredDialog(),
				},
				[E_SessionType.DELIVERY_COURIER, E_SessionType.DELIVERY_CUSTOMER].includes(session.sessionType) &&
				{
					icon: "truck",
					tippy: <Translate id={"goToDeliveryPage"}/>,
					href: `/delivery?pin=${session.pin}`,
				},
				session.invoiceAvailable && {
					icon: T_APP_ICONS.PDF,
					action: () => this._downloadInvoice(),
					tippy: <Translate id={"invoice_download"} />
				},
				[E_SessionState.ACTIVE].includes(session.state) &&
				{
					icon: "envelope",
					tippy: <Translate id={"reSendNotification"}/>,
					confirm: <span><Fa icon={"envelope"} /> <Translate id={"reSendNotification"} /></span>,
					action: () => this._sendNotificationToUser(),
					disabled: sending,
				},
				[E_SessionState.ACTIVE].includes(session.state) && [E_SessionType.RESERVATION].includes(session.sessionType) && {
					icon: "door-open",
					tippy: <Translate id={"openBox"}/>,
					action: () => this._openSessionServiceBlock(),
				},
				[E_SessionState.ACTIVE, E_SessionState.PENDING, E_SessionState.RESERVATION_WAITING].includes(session.state) && {
					icon: "stop",
					tippy: <Translate id={[E_SessionType.RESERVATION].includes(session.sessionType) ? "session_reservation_stop" : "session_stop"}/>,
					action: () => null,
					// Because this is not a component! -_-
					// eslint-disable-next-line react/display-name
					confirm: body => (
						<Tippy
							{...T_SharedTippyProps}
							interactive={true}
							appendTo={document.querySelector("body")}
							theme={`remove-confirm`}
							trigger={"click"}
							hideOnClick={true}
							onCreate={inst => this.stopSessionConfirmInstance = inst}
							content={
								<ButtonsConstructor
									className={"options"}
									style={css(`display: flex; flex-direction: column;`)}
									buttons={EO(E_OpenBoxModes).toArray(true).map(option => ({
										text: `lockAction_${option}`,
										action: () => this._completeSession(option)
									}))}
								/>
							}
						>
							{body}
						</Tippy>
					)
				}
			]}
		/>;
	}

	_renderSessionState(session) {
		return (
			<div className={"session-state"}>
				{
					session.sessionType &&
					![E_SessionType.REGULAR].includes(session.sessionType) &&
					<h1>
						<span><Translate id={"type"} /></span>
						<label><Translate id={`sessionType_${session.sessionType}`} /></label>
					</h1>
				}

				<h1>
					<span><Translate id={"state"} /></span>
					<label><Translate id={`state_${session.state}`} /></label>
				</h1>
			</div>
		)
	}

	_renderUserContent(user) {
		if(user) {
			let userData = resolveItemName(user, `email`);

			return (
				<Restricted
					restricted={T_RestrictionPresets.USERS}
					fallbackContent={userData}
				>
					<ItemRedirect
						href={`${routes.USERS}/${user.id}`}
						disabled={!user.id}
						wrapChildren={true}
						className={"user-selectable"}
					>
						{userData}
					</ItemRedirect>
				</Restricted>
			)
		}

		return <Translate id={"value_unknown"} />
	}

	_renderSessionBasics(session) {
		return (
			<div className={"session-basics box-grid simplify"}>
				{
					![E_SessionType.DELIVERY_COURIER].includes(session.sessionType) &&
					<div>
						<Fa icon={T_APP_ICONS.USER} />
						<label><Translate id={"user"} /></label>
						<span>
							{
								session.user &&
								this._renderUserContent(session.user)
							}
							{
								this._renderSessionInvoiceEmailAndPhone(session)
							}
						</span>
					</div>
				}
				<div>
					<Fa icon={T_APP_ICONS.LOCATIONS} />
					<label><Translate id={"location"} /></label>
					<span>
						{
							session.location &&
							<Restricted
								restricted={T_RestrictionPresets.LOCATIONS}
								fallbackContent={resolveItemName(session.location)}
							>
								<ItemRedirect
									href={`${routes.LOCATIONS}/${session.location.id}`}
									wrapChildren={true}
								>
									{resolveItemName(session.location)}
								</ItemRedirect>
							</Restricted>
							||
							<Translate id={"value_unknown"} />
						}
					</span>
				</div>

				{
					get(session, `tariff.id`, undefined) !== null && ![E_SessionType.DELIVERY_COURIER].includes(session.sessionType) &&
					<div>
						<Fa icon={T_APP_ICONS.TARIFFS} />
						<label><Translate id={"tariff"} /></label>
						<span>
							{
								session.tariff &&
								<Restricted
									restricted={T_RestrictionPresets.TARIFFS}
									fallbackContent={resolveItemName(session.tariff)}
								>
									<ItemRedirect
										href={`${routes.TARIFFS}/${session.tariff.id}`}
										wrapChildren={true}
									>
										{resolveItemName(session.tariff)}
									</ItemRedirect>
								</Restricted>
								||
								<Translate id={"value_unknown"} />
							}
						</span>
					</div>
				}

				{
					[E_SessionType.DELIVERY_COURIER].includes(session.sessionType) &&
					session.deliveryPrice?.price &&
					<div>
						<Fa icon={"coins"} />
						<label><Translate id={"price"}/></label>
						<span>{formatAsPrice(session.deliveryPrice.price, session.deliveryPrice.currency)}</span>
					</div>
				}

				<div>
					<Fa icon={T_APP_ICONS.SERVICE} />
					<label><Translate id={"service"} /></label>
					<span>
						{
							session.service &&
							<Restricted
								restricted={T_RestrictionPresets.LOCATIONS}
								fallbackContent={resolveItemName(session.service, `physicalReference`)}
							>
								<ItemRedirect
									href={`${routes.LOCATIONS}/${session.location.id}/${session.unit && session.unit.id}/${session.service.id}`}
									wrapChildren={true}
								>
									{resolveItemName(session.service, `physicalReference`)}
								</ItemRedirect>
							</Restricted>
							||
							<Translate id={"value_unknown"} />
						}
					</span>
				</div>
				{
					session.voucher &&
					<div>
						<Fa icon={T_APP_ICONS.VOUCHERS} />
						<label><Translate id={"voucher"} /></label>
						<span>
							<Restricted
								restricted={T_RestrictionPresets.VOUCHERS}
								fallbackContent={resolveItemName(session.voucher, `voucherCode`)}
							>
								<NavLink to={`${routes.VOUCHERS}/${session.voucher.id}`}>
									{resolveItemName(session.voucher, `voucherCode`)}
								</NavLink>
							</Restricted>
						</span>
					</div>
				}
			</div>
		)
	}

	_renderSessionInvoiceEmailAndPhone(session) {
		if(!session){return null}

		return (
			<div>
				{
					session.invoiceEmail &&
					<div className={"email"}>
						<Fa icon={"at"} />
						<a className={"user-selectable"} href={`mailto:${session.invoiceEmail}`}>{session.invoiceEmail}</a>
					</div>
				}
				{
					session.phone &&
					<div className={"phone"}>
						<Fa icon={"phone-alt"} />
						<a className={"user-selectable"} href={`tel:${session.phone}`}>{session.phone}</a>
					</div>
				}
			</div>
		)
	}

	_renderDeliveryUsers(session) {
		if(session.sessionType != E_SessionType.DELIVERY_COURIER) {
			return null;
		}

		return (
			<div className={"session-basics box-grid simplify"}>
				<div>
					<Fa icon={"truck"} />
					<label><Translate id={"courier"} /></label>
					<span>
						{this._renderSessionInvoiceEmailAndPhone(session)}
					</span>
				</div>

				{
					session.deliveryContinuation &&
					<div>
						<Fa icon={T_APP_ICONS.USER}/>
						<label><Translate id={"user"}/></label>
						<span>
							{
								session.deliveryContinuation.user &&
								this._renderUserContent(session.deliveryContinuation.user)
								||
								<div>
									{
										session.deliveryContinuation.email &&
										<div className={"email"}>
											<Fa icon={"at"}/>
											<a className={"user-selectable"} href={`mailto:${session.deliveryContinuation.email}`}>{session.deliveryContinuation.email}</a>
										</div>
									}
									{
										session.deliveryContinuation.phone &&
										<div className={"phone"}>
											<Fa icon={"phone-alt"}/>
											<a className={"user-selectable"} href={`tel:${session.deliveryContinuation.phone}`}>{session.deliveryContinuation.phone}</a>
										</div>
									}
								</div>
							}
						</span>
					</div>
				}
			</div>
		)
	}

	_renderSessionService(session) {
		const pinBody = (
			<label
				style={{cursor: session.pin ? "pointer" : "default"}}
				onClick={() => session.pin && copyToClipboard(session.pin)}
			>
				{
					get(session, `pin`, "------", pin => (
						<Fragment>
							{pin}
							<Fa style={css(`font-size: 0.5em; margin-bottom: -0.5em`)} icon={T_APP_ICONS.CLIPBOARD} />
						</Fragment>
					))
				}
			</label>
		)

		return (
			<div className={"service-details"}>
				<ButtonsConstructor
					buttons={[
						{
							text: <h1>
								<span><Translate id={"session_pin"} /></span>
								<label>
									{get(session, `pin`, "------")}
									<Fa style={css(`font-size: 0.5em; margin-bottom: -0.5em`)} icon={T_APP_ICONS.CLIPBOARD} />
								</label>
							</h1>,
							textTranslated: true,
							tippy: <Translate id={"session_pin_copy"}/>,
							disabled: !session.pin,
							action: () => copyToClipboard(session.pin)
						},
						session.customID && {
							text: <h1>
								<span><Translate id={"customID"} /></span>
								<label>
									{session.customID}
									<Fa style={css(`font-size: 0.5em; margin-bottom: -0.5em`)} icon={T_APP_ICONS.CLIPBOARD} />
								</label>
							</h1>,
							textTranslated: true,
							tippy: <Translate id={"clipboard_copy"}/>,
							action: () => copyToClipboard(session.pin)
						},
					]}
				/>
			</div>
		)
	}

	_renderSessionDuration(session) {
		const {diff} = this.state;
		const timeFormat = new Intl.DateTimeFormat(window.translator.getActiveLanguage(true), {
			year: "numeric",
			month: "numeric",
			day: "numeric",
			hour: "numeric",
			minute: "numeric",
		});

		const __getStartedAt = () => {
			if(session.startedAt) {
				return timeFormat.format(new Date(session.startedAt));
			}
			return '-';
		}
		const __getEndedAt = () => {
			if(session.endedAt) {
				return timeFormat.format(new Date(session.endedAt));
			}
			return null;
		}
		const isReservation = [E_SessionType.RESERVATION].includes(session.sessionType);

		return (
			<div className={"session-duration"}>
				<div>
					<div className={"start"}>
						{
							isReservation &&
							<div className={"grid-table grid-icon-auto-1fr"}>
								<span/>
								<label><Translate id={"start"} /></label>
								<span>{__getStartedAt()}</span>

								<span/>
								<label><Translate id={"reservedFrom"}/></label>
								<span>{timeFormat.format(new Date(session.reservedFrom))}</span>
							</div>
							||
							<Fragment>
								<h2><Translate id={"start"} /></h2>
								<div className={"time"}>{__getStartedAt()}</div>
							</Fragment>
						}
					</div>
				</div>
				<div>
					<div>
						{
							session.startedAt &&
							<Fragment>
								<label><Translate id={"duration"} /></label>
								<label>
									{
										(diff > 0 || session.endedAt) &&
										Process_msToDuration(session.endedAt ? new Date(session.endedAt) - new Date(session.startedAt) : diff, {
											hideEmpty: false,
										})
										||
										'-'
									}
								</label>
							</Fragment>
						}
					</div>
				</div>
				<div>
					<div className={"end"}>
						{
							isReservation &&
							<div className={"grid-table grid-icon-auto-1fr"}>
								<span/>
								<label><Translate id={"end"}/></label>
								<span>{__getEndedAt()}</span>

								<span/>
								<label><Translate id={"reservedTo"}/></label>
								<span>{timeFormat.format(session.reservedTo)}</span>
							</div>
							||
							(
								session.endedAt &&
								<Fragment>
									<h2><Translate id={"end"} /></h2>
									<div className={"time"}>{__getEndedAt()}</div>
								</Fragment>
								||
								<h2 style={{margin: "auto"}}><Translate id={"session_inProgress"} /></h2>
							)
						}
					</div>
				</div>
			</div>
		)
	}

	_renderSessionDescription(session) {
		if(session.notes) {
			return (
				<div className={"session-description"}>
					<div className={"text-block"}>
						<label><Fa icon={T_APP_ICONS.NOTE}/> <Translate id={"notes"}/></label>
						<pre>
							{session.notes}
						</pre>
					</div>
				</div>
			)
		}
		return null;
	}

	_renderSessionSummary(session) {
		const currency = get(session, `tariff.currency`, E_Currency.CZK);
		const paidDiff = get(session, `cost.totalPaidAmount`) - get(session, `cost.total`);

		return (
			<div className={"summary"}>
				<h3><label><Translate id={"summary"} /></label></h3>

				<SummaryTable
					summary={
						<label className={`${paidDiff < 0 ? "cl-error" : "cl-success"}`}>{
							paidDiff == 0 &&
							<Translate id={"paid"} />
							||
							<Fragment>
								{
									paidDiff < 0 &&
									<Fragment>
										<Translate id={"unpaidAmount"} />:&nbsp;
									</Fragment>
								}
								{formatAsPrice(Math.abs(paidDiff), currency, this)}
							</Fragment>
						}</label>
					}
					currency={currency}
					sections={[
						{
							text: "items",
							className: "amounts",
							entries: [
								{
									text: "initialAmount",
									value: get(session, `cost.initialAmount`, <Translate id={"value_unknown"}/>)
								},
								{
									text: "additionalAmount",
									value: get(session, `cost.additionalAmount`, <Translate id={"value_unknown"}/>)
								},
								{
									text: "initialAmountBeforeDiscounts",
									value: get(session, `cost.initialAmountBeforeDiscounts`, <Translate id={"value_unknown"}/>)
								},
								{
									text: "additionalAmountBeforeDiscounts",
									value: get(session, `cost.additionalAmountBeforeDiscounts`, <Translate id={"value_unknown"}/>)
								},
							]
						},
						{
							text: "paid",
							className: "paid",
							entries: [
								{
									text: "paidByCredit",
									value: get(session, `cost.paidByCredit`, <Translate id={"value_unknown"}/>)
								},
								{
									text: "paidByCard",
									value: get(session, `cost.paidByCard`, <Translate id={"value_unknown"}/>)
								},
							]
						},
						{
							text: "total",
							className: "total",
							theme: "white",
							entries: [
								{
									text: "totalBeforeDiscounts",
									value: get(session, `cost.totalBeforeDiscounts`, <Translate id={"value_unknown"}/>)
								},
								{
									text: "total",
									value: get(session, `cost.total`, <Translate id={"value_unknown"}/>)
								},
								{
									text: "totalPaidAmount",
									value: get(session, `cost.totalPaidAmount`, <Translate id={"value_unknown"}/>)
								},
								{
									text: "unpaidAmount",
									value: get(session, `cost.unpaidAmount`, <Translate id={"value_unknown"}/>)
								},
							]
						}
					]}
				/>
			</div>
		)
	}

	_renderSessionTransactionItemTemplate(transaction, i, headerItems) {
		return (
			<TableRow key={i} headerItems={headerItems}>
				<TableColumn column={"success"}><Fa icon={transaction.success ? "check" : "cross"} /></TableColumn>
				<TableColumn column={"type"}><Translate id={"transactionType_" + transaction.type}/></TableColumn>
				<TableColumn column={"state"}>{transaction.state && <Translate id={"transactionState_" + transaction.state} /> || '-'}</TableColumn>
				<TableColumn column={"serviceType"}>{transaction.serviceType && <Translate id={`transactionServiceType_${transaction.serviceType}`} /> || '-'}</TableColumn>
				<TableColumn column={"transactionID"}>{transaction.transactionID}</TableColumn>
				<TableColumn column={"paymentMethod"}>{get(transaction, `paymentMethod.state`)}</TableColumn>
				<TableColumn column={"amount"}>{formatAsPrice(transaction.amount, transaction.currency, this) || <Translate id={"value_unknown"}/>}</TableColumn>
				<TableColumn column={"responseText"}>{transaction.responseText}</TableColumn>
			</TableRow>
		)
	}

	_renderSessionTransactions() {
		const {sessionID} = this.props;

		return (
			<div className={"session-transactions"}>
				<div className={"separator title"}>
					<label>
						<Fa icon={"money-check-alt"} />
						<Translate id={"transactions"} />
					</label>
				</div>

				<Subscriber
					footer={false}
					service={() => service_fetchSessionTransactions(sessionID)}
				>
					<TableListing
						className={"bordered"}
						canSort={false}
						itemTemplate={this._renderSessionTransactionItemTemplate.bind(this)}
						headerItems={{
							success: {
								width: "3em",
								label: ''
							},
							type: {},
							state: {},
							serviceType: {},
							transactionID: {},
							paymentMethod: {},
							amount: {},
							responseText: {},
						}}
						name={"session-transactions"}
						canModifyColumns={true}
					/>
				</Subscriber>
			</div>
		);
	}

	_renderSessionData(session) {
		const {hasTransactions} = this.state;

		return (
			<Fragment>
				{this._renderSessionState(session)}
				{this._renderSessionBasics(session)}
				{this._renderDeliveryUsers(session)}
				{this._renderSessionService(session)}
				{this._renderSessionDuration(session)}
				{this._renderSessionDescription(session)}
				{this._renderSessionSummary(session)}
				<Restricted role={E_UserRoles.ADMIN}>
					{hasTransactions && this._renderSessionTransactions()}
				</Restricted>
			</Fragment>
		)
	}

	_renderSession(session) {
		return (
			<Fragment>
				{this._renderToolbar()}
				<div className={"content-wrapper"}>
					{this._renderSessionData(session)}
				</div>
			</Fragment>
		)
	}

	render() {
		const {session, edit, sessionID} = this.props;

		return (
			<DetailWrapper
				itemID={sessionID}
				dataID={(session || {}).id}
				className={"session-detail"}
				isEdit={is.valid(edit)}
				listUrl={routes.SESSIONS}
				errorContent={"session_fetch_failed"}
				selector={Selector_Session_Root}
			>
				{this._renderSession((is.valid(edit) ? edit : session) || T_SessionModel)}
			</DetailWrapper>
		);
	}

	_initializeSession() {
		const {sessionID, Thunk_Session_Fetch, Action_Session_Edit_Init} = this.props;
		this.clock && clearInterval(this.clock);

		if (sessionID) {
			Thunk_Session_Fetch(sessionID);
			service_fetchSessionTransactions(sessionID).then(data => this.setState({hasTransactions: !!data.length}));
		} else {
			Action_Session_Edit_Init(T_SessionModel);
		}

		this.clock = setInterval(() => {
			const {session} = this.props;
			if(!this._isMounted) {
				clearInterval(this.clock);
				return;
			}

			if (session && session.startedAt && !session.endedAt) {
				let diff = Math.max(0, new Date() - new Date(session.startedAt));
				this.setState({diff});
			}

			if (session.endedAt) {
				clearInterval(this.clock);
			}
		}, 1000);
	}

	_isEditSwitch(normal, edit) {
		return is.valid(this.props.edit) ? edit : normal;
	}

	_completeSession(openMode) {
		const {sessionID, Action_Session_Edit_Fulfilled} = this.props;

		service_completeSession(sessionID, openMode, true).then(data => {
			Action_Session_Edit_Fulfilled(data.session);

			window.toaster.showToast({
				icon: "check",
				style: E_ToastStyle.SUCCESS,
				content: "session_complete_success",
			});

			if(data.success == false) {
				errorHandler({
					message: "Session was completed successfully but the doors weren't able to open"
				}, E_ServiceActions.DOOR_OPEN);
			}
		}, error => {
			errorHandler(error, SESSION_COMPLETE_REJECTED);
		});
		this.stopSessionConfirmInstance.hide();
	}

	_completeDeliverySession() {
		const {session, Action_Session_Fetch_Pending, Action_Session_Fetch_Rejected} = this.props;

		Action_Session_Fetch_Pending();
		service_completeDeliverySessionFromPin(session.pin).then(() => {
			this._initializeSession();
		}, error => {
			Action_Session_Fetch_Rejected({}, {suppressToast: true});
			errorHandler(error, SESSION_MARK_AS_DELIVERED_REJECTED);
		});
	}

	_openMarkAsDeliveredDialog() {
		window.modal.open({
			body: <div>
				<span style={css(`white-space: pre-wrap; text-align: center;`)}>
					<Translate id={"markAsDeliveredMessage"}/>
				</span>
				<ButtonsConstructor
					wrapInNav={true}
					style={css(`display: flex; margin: 10px -5px -5px`)}
					buttons={[
						{
							text: "no",
							icon: "times",
							style: css(`border-color: #333333aa`),
							action: () => window.modal.close(),
						},
						{
							text: "markAsDelivered",
							icon: "check",
							action: () => {
								this._completeDeliverySession();
								window.modal.close();
							}
						}
					]}
					sharedProps={{className: "fancy", style: {margin: 5}}}
				/>
			</div>
		})
	}

	_sendNotificationToUser() {
		const {sessionID} = this.props;

		this.setState({sending: true});
		service_sendMaintenanceSessionNotify(sessionID).then(() => {
			window.toaster.showToast({
				style: E_ToastStyle.SUCCESS,
				content: `success_${E_SessionActions.SEND_NOTIFICATION}`
			})
		}, error => {
			errorHandler(error, E_SessionActions.SEND_NOTIFICATION);
		}).finally(() => {
			this.setState({sending: false});
		});
	}

	_openSessionServiceBlock() {
		const {sessionID, Action_Session_Fetch_Fulfilled} = this.props;

		this.setState({sending: true});
		service_openSessionServiceBlock(sessionID).then(data => {
			Action_Session_Fetch_Fulfilled(data.session);

			window.toaster.showToast({
				style: E_ToastStyle.SUCCESS,
				content: "service_lock_open_success",
			});
		}, error => {
			errorHandler(error, E_ServiceActions.DOOR_OPEN);
		}).finally(() => {
			this.setState({sending: false});
		});
	}

	_downloadInvoice() {
		const {sessionID} = this.props;

		const pendingToast = window.toaster.showToast({
			content: window.translator.translate("downloading"),
			time: Infinity,
		});
		service_downloadSessionInvoice(sessionID).then(data => {
			let url = window.URL.createObjectURL(data.file);

			let a = document.createElement("a");
			a.download = data.filename;
			a.href = url;
			a.click();
			a.remove();

			window.URL.revokeObjectURL(url);

			window.toaster.showToast({
				style: E_ToastStyle.SUCCESS,
				content: "invoice_download_success",
			});
		}, () => {
			window.toaster.showToast({
				style: E_ToastStyle.ERROR,
				content: "invoice_download_error",
			});
		}).finally(() => {
			window.toaster.removeToast(pendingToast)
		});
	}
}

SessionDetail.propTypes = {
	...T_SharedReduxPropTypes,

	sessionID: PropTypes.number,
	session: PropTypes.object,
	edit: PropTypes.object,

	Thunk_Session_Fetch: PropTypes.func.isRequired,
	Action_Session_Edit_Cancel: PropTypes.func.isRequired,
	Action_Session_Edit_Init: PropTypes.func.isRequired,
	Action_Session_Edit_Modify: PropTypes.func.isRequired,
	Thunk_Partners_Fetch_All: PropTypes.func.isRequired,
};

SessionDetail.stateTypes = {
	diff: PropTypes.number,
	sending: PropTypes.bool,
};

SessionDetail.defaultProps = {
	session: T_SessionModel,
};

const mapStateToProps = state => {
	const {fetched, fetching, failed} = Selector_Session_Root(state);
	const session = Selector_Session_Data(state);
	const edit = Selector_Session_Edit(state);

	return {fetched, fetching, failed, session, edit}
};

const mapDispatchToProps = dispatch => (
	bindActionCreators({
		Action_Session_Edit_Init,
		Thunk_Session_Fetch,
		Action_Session_Edit_Cancel,
		Action_Session_Edit_Modify,
		Thunk_Partners_Fetch_All,
		Action_Session_Edit_Fulfilled,
		Action_Session_Clear,
		Action_Session_Fetch_Pending,
		Action_Session_Fetch_Fulfilled,
		Action_Session_Fetch_Rejected
	}, dispatch)
);

export default connect(mapStateToProps, mapDispatchToProps)(SessionDetail);
