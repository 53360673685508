import PropTypes from "prop-types";
import React from "react";
import {Translate, withLocalize} from "react-localize-redux";
import {T_ListingHeaderItem, T_SharedPropTypes, T_SharedReduxPropTypes, T_SortType, T_SortTypeIcons} from "../../../models/Models_Shared";
import "../../../styles/components/TableListing.scss";
import {EO} from "../../../utils/extensions";
import is from "../../../utils/is";
import {css, get, resolveDynamicComponent, resolveErrorBlockContentByError} from "../../../utils/utils";
import Fa from "../../tools/Icons/Fa";
import ButtonsConstructor from "../ButtonsConstructor";
import {withRouter} from "react-router-dom";
import ErrorBlock from "../Error/ErrorBlock";
import TableColumnsSetup from "./TableColumnsSetup";
import {willAlwaysFailError} from "../../../store/ResponseHandling";
import withRestrictions from "../Restrictions/withRestrictions";

class TableListing extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			hiddenItems: this._getHiddenItemsFromStorage() || this._getHiddenItems(this._processHeaderItems(props)),
		};
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		if(!is.equal(prevProps.headerItems, this.props.headerItems)) {
			this.setState({hiddenItems: this._getHiddenItems(this._processHeaderItems())});
		}
	}

	_renderHeader() {
		const {onSort, sort, canModifyColumns} = this.props;

		const __getSortIcon = (type = T_SortType.DEFAULT) => {
			switch (type) {
				case T_SortType.NUMERIC:
					return T_SortTypeIcons.NUMERIC;
				case T_SortType.TEXT:
					return T_SortTypeIcons.TEXT;
				default:
					return T_SortTypeIcons.DEFAULT;
			}
		};

		let sorted = `${sort}`.split(",");
		const sortOptions = ['desc','asc',''];

		return (
			<header>
				<ButtonsConstructor
					buttons={[...this._computeItemsVisibility().filter(item => item.visible).map(
						item => {
							let [key, direction] = sorted;
							let isSorted = key == item.field;
							let canSort = this.props.canSort && item.field && item.sort !== false;
							let sortDirection = isSorted ? (direction == "asc" ? "desc" : "asc") : "desc";
							let nextSortDirection = isSorted ? (sortOptions.indexOf(direction) + 1) % sortOptions.length : 0;

							let sortComponent = canSort && (
								<Fa
									className={`sort-icon`}
									icon={__getSortIcon(item.sort)[sortDirection]}
								/>
							);

							return ({
								icon: resolveDynamicComponent(item.icon, <label><Fa icon={item.icon} /></label>),
								text: resolveDynamicComponent(
									item.label,
									<label>
										<Translate id={item.label} />
										{sortComponent}
									</label>,
									sortComponent
								),
								action: () => this.props.canSort && canSort && item.sort && onSort(item.field, sortOptions[nextSortDirection]),
								style: css(is.number(item.width) ? `flex: ${item.width} 1 0%` : `max-width:${item.width}`),
								className: `${canSort && "sortable"} ${isSorted && "sorted"}`,
							})
						}
					),
					canModifyColumns && {
						icon: "cog",
						tippy: <Translate id={"columnsSetup"}/>,
						style: {maxWidth: "1.5em"},
						className: "columns-setup",
						action: () => this._openColumnsSetup(),
					}]}
				/>
			</header>
		)
	}

	_renderContent() {
		const {data, items, itemTemplate, failed, onRetry, fetched, error} = this.props;

		if(failed) {
			return <ErrorBlock {...resolveErrorBlockContentByError(error)} retry={!willAlwaysFailError(error) ? onRetry : undefined} />
		}

		let displayData = (items || get(data, `content`, []));

		if(displayData.length == 0 && fetched) {
			return (
				<h1 style={css(`margin: auto; text-align: center;`)}>
					<label style={{margin: 0}}>
						<Translate id={"noEntries"}/>
					</label>
				</h1>
			);
		}

		return displayData.map((item, i) => itemTemplate(item, i, this._computeItemsVisibility()));
	}

	render() {
		const {className, style, canModifyColumns} = this.props;

		return (
			<section className={`table-listing ${className} ${canModifyColumns ? "modify-columns-offset" : ''}`} style={style}>
				{this._renderHeader()}
				{this._renderContent()}
			</section>
		)
	}

	/**
	 *
	 * @returns T_ListingHeaderItem[]
	 * @private
	 */
	_processHeaderItems(props) {
		const {headerItems, headerTemplate} = props || this.props;

		if (Array.isArray(headerItems)) {
			return headerItems.filter(
				item => !this._isHeaderRestricted(item)
			).map(item => ({
				...headerTemplate,
				field: item,
				label: item,
				key: item,
			}));
		}

		return EO(headerItems).toArray().filter(
			item => !this._isHeaderRestricted(item.value)
		).map(item => ({
			...headerTemplate,
			key: item.key,
			label: item.key,
			field: item.key,
			...item.value
		}));
	}

	_isHeaderRestricted(item) {
		const {hasAccess} = this.props;

		if(item.restricted) {
			if(typeof item.restricted == "function") {
				return item.restricted(item);
			}
			return !hasAccess(item.restricted.role, item.restricted.permissions);
		}
	}

	_getHiddenItems(headerItems) {
		return headerItems.filter(item => item.visible == false).map(item => item.key);
	}

	_openColumnsSetup() {
		const {hiddenItems} = this.state;

		window.modal.open({
			body: <TableColumnsSetup
				headerItems={this._processHeaderItems()}
				hiddenItems={hiddenItems}
				onModify={(hiddenItems) => {
					window.modal.close();
					this._setHiddenItemsToStorage(hiddenItems);
					this.setState({hiddenItems});
				}}
			/>
		})
	}

	_computeItemsVisibility() {
		const {hiddenItems} = this.state;

		return this._processHeaderItems().map(item => ({
			...item,
			visible: hiddenItems.includes(item.key) ? false : item.visible,
		}));
	}

	_getHiddenItemsFromStorage() {
		const {name} = this.props;

		if(name) {
			let listings = JSON.parse(localStorage.getItem("listings"));
			if(listings && listings[name]) {
				return listings[name].hiddenItems;
			}
		}

		return null;
	}

	_setHiddenItemsToStorage(hiddenItems) {
		const {name} = this.props;
		if(name) {
			hiddenItems = hiddenItems || this.state.hiddenItems;

			let listings = JSON.parse(localStorage.getItem("listings")) || {};
			listings[name] = {
				...listings[name],
				hiddenItems,
			};

			localStorage.setItem("listings", JSON.stringify(listings));
		}
	}
}

TableListing.propTypes = {
	...T_SharedPropTypes,
	...T_SharedReduxPropTypes,
	data: PropTypes.object,
	/**
	 * @type {Object<String, T_ListingHeaderItem>|Array<String>}
	 */
	headerItems: PropTypes.any.isRequired,
	itemTemplate: PropTypes.func.isRequired,
	headerTemplate: PropTypes.object.isRequired,
	onSort: PropTypes.func.isRequired,
	onRetry: PropTypes.func.isRequired,
	canSort: PropTypes.bool,
	canModifyColumns: PropTypes.bool,
	sort: PropTypes.string,
	name :PropTypes.string,
};

TableListing.stateTypes = {
	hiddenItems: PropTypes.array,
};

TableListing.defaultProps = {
	headerTemplate: T_ListingHeaderItem,
	canSort: true,
	// eslint-disable-next-line react/display-name
	itemTemplate: (row, i) => (
		<a key={i}>{EO(row).toArray(true).map((item, j) => <span key={j}>{item}</span>)}</a>
	),
	onSort: () => null,
	onRetry: () => null,
};

export default withRouter(withRestrictions(withLocalize(TableListing)));
