import React from "react";
import PropTypes from "prop-types";
import { service_fetchRevenue } from "../../../services/Service_Statistics";
import { T_DateOptions_DateOnly, T_SharedReduxPropTypes } from "../../../models/Models_Shared";
import ErrorBlock from "../../shared/Error/ErrorBlock";
import CubeSpinner from "../../shared/LoadingIndicator/CubeSpinner";
import { EO } from "../../../utils/extensions";
import "../../../styles/pages/Statistics/StatisticsRevenue.scss";
import SummaryTable from "../../shared/SummaryTable";
import { Translate } from "react-localize-redux";
import { formatAsDate, resolveErrorBlockContentByError } from "../../../utils/utils";
import is from "../../../utils/is";

class StatisticsRevenue extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			...super.state,
			data: {},
			pending: true,
			error: {},
		};
	}

	componentDidMount() {
		service_fetchRevenue().then(data => {
			this.setState({data});
		}, error => {
			this.setState({error});
		}).finally(() => this.setState({pending: false}));
	}

	render() {
		const {data, pending, error} = this.state;

		return (
			<section className={"statistics-revenue"}>
				{
					is.valid(error) &&
					<ErrorBlock {...resolveErrorBlockContentByError(error)} />
					||
					(
						pending &&
						<CubeSpinner block={true} />
						||
						<SummaryTable
							style={{border: "none"}}
							summary={
								<label className={"interval"}>
									<span><Translate id={"revenue"}/></span>
									<span>{formatAsDate(data.from, T_DateOptions_DateOnly)} - {formatAsDate(data.to, T_DateOptions_DateOnly)}</span>
								</label>
							}
							sections={[
								{
									text: "dailyRevenue",
									entries: EO(data.dayRevenue).toArray().map(revenue => ({
										text: formatAsDate(revenue.key, T_DateOptions_DateOnly),
										textTranslated: true,
										value: revenue.value
									}))
								},
								{
									text: "summary",
									theme: "white",
									entries: [{
										text: "total",
										value: data.totalRevenue
									}]
								}
							]}
						/>
					)
				}
			</section>
		)
	}

	static get propTypes() {
		return {
			...super.propTypes,
		}
	}

	static get stateTypes() {
		return {
			...T_SharedReduxPropTypes,
			...super.stateTypes,
			data: PropTypes.object
		}
	}

	static get defaultProps() {
		return {
			...super.defaultProps,
		}
	}
}

export default StatisticsRevenue;
