import React from "react";
import PropTypes from "prop-types";
import { Translate } from "react-localize-redux";
import { E_Modification } from "../../../store/Constants_StoreShared";
import { EO } from "../../../utils/extensions";
import is from "../../../utils/is";
import ButtonsConstructor from "../ButtonsConstructor";
import OptionsSelectorBase from "./Base/OptionsSelectorBase";

class ItemsPickerInput extends OptionsSelectorBase {
	constructor(props) {
		super(props);

		this.state = {
			...super.state,
		};
	}

	componentDidMount() {
		super.componentDidMount();

		this.fetchData();
	}

	_renderOption(option, i, action) {
		const {onLabel} = this.props;

		return (
			<button key={i} onClick={action}>
				{onLabel(option)}
			</button>
		);
	}

	render() {
		const {className, defaultValue, path} = this.props;
		const {options} = this.state;

		let availableOptions = (options || []).filter(option => !EO(defaultValue).containsID(option.id)) || [];

		return (
			<section className={`items-picker ${className}`}>
				<header>
					<label><Translate id={"options"}/></label>
					<span style={{flex: 2}}/>
					<label><Translate id={"selected"}/></label>
				</header>
				<div>
					<div className={"options"}>
						{
							availableOptions.map(
								(o, i) => this._renderOption(o, i, () => this.applyChange(path, o, E_Modification.ARRAY_PUSH))
							)
						}
					</div>
					<ButtonsConstructor
						wrapInNav={true}
						className={"controls"}
						buttons={[
							{
								icon: "angle-double-right",
								action: () => this.applyChange(path, availableOptions, E_Modification.ARRAY_PUSH),
								disabled: !is.valid(availableOptions)
							},
							{
								icon: "angle-double-left",
								action: () => this.applyChange(path, null, E_Modification.ARRAY_CLEAR),
								disabled: !is.valid(defaultValue)
							},
						]}
					/>
					<div className={"active"}>
						{
							(defaultValue || []).map((o, i) => this._renderOption(o, i, () => this.applyChange(`${path}`, [i, 1], E_Modification.ARRAY_SPLICE)))
						}
					</div>
				</div>
			</section>
		)
	}

	static get propTypes() {
		return {
			...super.propTypes,
			onLabel: PropTypes.func.isRequired,
		}
	}

	static get stateTypes() {
		return {
			...super.stateTypes,
		}
	}

	static get defaultProps() {
		return {
			...super.defaultProps,
		}
	}
}

export default ItemsPickerInput;
