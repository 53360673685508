import React, {Fragment} from "react";
// eslint-disable-next-line no-unused-vars
import PropTypes from "prop-types";
import {combineClasses, resolveErrorBlockContentByError} from "../../../utils/utils";
import DropzoneInput from "../../shared/Input/DropzoneInput";
import ErrorBlock from "../../shared/Error/ErrorBlock";
import CubeSpinner from "../../shared/LoadingIndicator/CubeSpinner";
import {service_fetchTermsAndConditions, service_updateTermsAndConditions} from "../../../services/Service_Settings";
import {errorHandler} from "../../../store/ResponseHandling";
import {E_SettingsActions} from "../../../store/actions/configuration/settingsActions";
import ButtonsConstructor from "../../shared/ButtonsConstructor";
import {T_APP_ICONS} from "../../../models/constants/Constants_Shared";
import Fa from "../../tools/Icons/Fa";
import {Translate} from "react-localize-redux";
import is from "../../../utils/is";
import Hint from "../../shared/Hint";
import Tippy from "../../shared/Tippy";
import {T_SharedTippyProps} from "../../../models/Models_Shared";

class SettingsTermsAndConditions extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			loading: true,
			error: null,
			terms: null,
		};
	}

	componentDidMount() {
		this._fetchTerms();
	}

	componentWillUnmount() {
		const {terms} = this.state;
		URL.revokeObjectURL(terms);
	}

	_renderTerms() {
		const {loading, terms, error} = this.state;

		if(loading) {
			return <CubeSpinner block={true}/>
		}

		if(error) {
			return <ErrorBlock
				{...resolveErrorBlockContentByError(error, E_SettingsActions.FETCH_TERMS)}
				retry={() => this._fetchTerms()}
			/>;
		}

		if(terms) {
			return (
				<Fragment>
					<ButtonsConstructor
						wrapInNav={true}
						className={"action-buttons"}
						style={{fontSize: "1.5em"}}
						buttons={[
							<Fragment key={"a"}>
								<Tippy
									{...T_SharedTippyProps}
									content={<Translate id={"open_inNewWindow"}/>}
								>
									<a href={terms} target={"blank"} style={{margin: 8}}>
										<Fa icon={"external-link-alt"}/>
									</a>
								</Tippy>
							</Fragment>,
							{
								icon: T_APP_ICONS.DOWNLOAD,
								action: () => this._downloadTerms(),
								tippy: <Translate id={"download"}/>,
								style: {margin: 5},
							},
							{
								icon: T_APP_ICONS.REMOVE,
								action: () => this.setState({terms: null}),
								className: "remove-button",
								tippy: <Translate id={"remove"}/>
							}
						]}
					/>
					<iframe type={"application/pdf"} src={terms} style={{width: "100%", height: "100%", border: "5px solid var(--primary-theme-color)"}}/>
				</Fragment>
			)
		}

		return (
			<DropzoneInput
				path={``}
				defaultValue={terms}
				onModify={(t, p, value) => is.valid(value) && this._fetchTerms()}
				uploadService={this.props.uploadService}
				multiple={false}
			/>
		)
	}


	render() {
		const {className, style} = this.props;

		return (
			<section className={combineClasses("terms", className)} style={{
				height: "80vh",
				...style
			}}>
				<Hint text={this.props.saveHint}/>
				{this._renderTerms()}
			</section>
		);
	}

	_fetchTerms() {
		this.setState({error: null, loading: true});
		this.props.fetchService().then(r => {
			this.setState({terms: URL.createObjectURL(r)});
		}, (error) => {
			//Prevent generic not found error toast
			if(error.status == 404 && this.props.suppressNotFound) return;

			errorHandler(error, E_SettingsActions.FETCH_TERMS);
		}).finally(() => this.setState({loading: false,}));
	}

	_downloadTerms() {
		const {terms} = this.state;

		let a = document.createElement("a");
		a.download = window.translator.translate("termsAndConditions");
		a.href = terms;
		a.click();
		a.remove();
	}

	static get propTypes() {
		return {
			className: PropTypes.string,
			style: PropTypes.object,

			fetchService: PropTypes.func,
			uploadService: PropTypes.func,

			suppressNotFound: PropTypes.bool,
			saveHint: PropTypes.string,
		}
	}

	static get stateTypes() {
		return {
			terms: PropTypes.object,
			loading: PropTypes.bool,
			error: PropTypes.object,
		}
	}

	static get defaultProps() {
		return {
			fetchService: () => service_fetchTermsAndConditions(),
			uploadService: service_updateTermsAndConditions,
			suppressNotFound: false,
			saveHint: "termsAndConditions_saveHint",
		}
	}
}

export default SettingsTermsAndConditions;
