import Tippy from "../../shared/Tippy";
import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import '../../../styles/pages/Location/LocationDetail.scss';
import '../../../styles/pages/Location/LocationEdit.scss';
import {connect} from "react-redux";
import {Redirect, withRouter} from 'react-router-dom';
import {bindActionCreators} from "redux";
import {E_UserRoles, T_APP_ICONS} from "../../../models/constants/Constants_Shared";
import {E_OpeningHoursType, E_Visibility, T_LocationAddress, T_locationModel, T_OpeningHours} from "../../../models/Models_Location";
import {T_SharedReduxPropTypes, T_SharedTippyProps} from "../../../models/Models_Shared";
import {Action_Location_Edit_Cancel, Action_Location_Edit_Init, Action_Location_Edit_Modify} from "../../../store/actions/location/locationsActions";
import {Action_Unit_Init, Action_Unit_Modify} from "../../../store/actions/location/unitActions";
import {Selector_Location, Selector_Location_Edit_Location, Selector_Location_Item_Root} from "../../../store/selectors/Selectors_Location";
import {Thunk_Location_Fetch, Thunk_Location_Remove, Thunk_Location_Update} from "../../../store/thunk/Thunk_Location";
import {Thunk_Unit_Add, Thunk_Unit_Remove, Thunk_Unit_Save} from "../../../store/thunk/Thunk_StorageUnit";
import {getLocalizedWeekDay, TranslateEntry} from "../../../utils/localeUtils";
import ButtonsConstructor from "../../shared/ButtonsConstructor";
import TextareaInput from "../../shared/Input/TextareaInput";
import ToggleButton from "../../shared/Input/ToggleButton";
import Fa from '../../tools/Icons/Fa';
import routes from '../../../routes/routes';
import {Translate, withLocalize} from 'react-localize-redux';
import LanguageSwitch from "../../tools/Language/LanguageSwitch";
import MorphElement from "../../tools/MorphElement";
import OverflowMenu from "../../tools/OverflowMenu";
import LocationOpeningHoursModal from "./OpeningHours/LocationOpeningHoursModal";
import CommunicationUnitsList from "./CommunicationUnits/CommunicationUnitsList";
import {$$, formatAsDate, get, resolveItemName} from '../../../utils/utils';
import is from '../../../utils/is';
import {E_Side, T_unitModel} from "../../../models/Models_StorageBox";
import TextInput from "../../shared/Input/TextInput";
import LocationUnitEditModalContent from "./StorageUnit/Details/StorageUnitDetail";
import Gallery from "../../shared/Gallery";
import DropzoneInput from "../../shared/Input/DropzoneInput";
import Address from "../../shared/Address";
import Toolbar from "../../shared/Toolbar";
import DetailWrapper from "../../shared/DetailWrapper";
import StorageUnitBase from "./StorageUnit/StorageUnitBase";
import {E_ButtonPresets} from "../../../models/Models_ButtonPresets";
import {findPathComponentsByID} from "../../../utils/locationUtils";
import {service_uploadImage} from "../../../services/Service_Images";
import DropzoneImageContainer from "../../shared/Input/Dropzone/DropzoneImageContainer";
import PartnerSelectInput from "../Partners/PartnerSelectInput";
import Restricted from "../../shared/Restrictions/Restricted";
import {T_RestrictionPresets} from "../../../models/Models_Restrictions";

class LocationDetail extends React.Component {
	constructor(props) {
		super(props);

		this.state = {};
	}

	componentDidMount() {
		const {locationID, Thunk_Location_Fetch} = this.props;
		if (locationID)
			Thunk_Location_Fetch(locationID);
	}

	componentDidUpdate(prevProps, prevState) {
		const {location} = this.props;

		if (location && is.valid(location.storageUnits)) {
			if (get(prevProps, "match.params.unitID") != get(this, "props.match.params.unitID")) {
				this.setState({unitSide: E_Side.FRONT});
				let content = $$("#locationDetailContent");
				content && content.scrollTo && content.scrollTo(0, 0);
			}
		}
	}

	_renderOpeningHoursEntry(entry) {
		let closed = <p className={"cl-error"}><Translate id={"openingHours_CLOSED"} /></p>;

		switch (entry.openingType) {
			case E_OpeningHoursType.NONSTOP:
				return <p><Translate id={"openingHours_NONSTOP"} /></p>;
			case E_OpeningHoursType.CLOSED:
				return closed;
			case E_OpeningHoursType.SPECIFIC:
				if (is.empty(entry.periods)) {
					return closed;
				}
				return (
					entry.periods.map((period, i) => (
						<p key={i}>
							{period.fromTime}
							&nbsp;-&nbsp;
							{period.toTime}
							{i != entry.periods.length - 1 && ","}
						</p>
					))
				);
			default:
				return <Translate id={"value_undefined"} />
		}
	}

	/* === === === === === === ===
         RENDER OPENING HOURS
	 === === === === === === === */
	_renderOpeningHours(openingHours) {
		openingHours = {...T_OpeningHours, ...openingHours, entries: [...T_OpeningHours.entries].merge(get(openingHours, `entries`, []))};

		return (
			<div className={"opening-hours"}>
				<h3><Fa icon={"clock"} /><span><Translate id={"openingHours"} /></span></h3>
				{
					openingHours.twentyFourSeven &&
					<p><Translate id={"openingHours_NONSTOP"} /></p>
				}
				{
					!openingHours.twentyFourSeven &&
					<div>
						<div className={"entries grid-table static"}>
							{(openingHours.entries || []).map((entry, i) => (
								<Fragment key={i}>
									<label>{getLocalizedWeekDay(entry.weekday, 'long')}</label>
									<div className={"time-entries"}>
										{this._renderOpeningHoursEntry(entry)}
									</div>
								</Fragment>
							))}
							<label>
								<Fa icon={"globe"} style={{marginRight: 10}} />
								<Translate id={"timezone"} />
							</label>
							<span>{openingHours.timezone || <Translate id={"value_notProvided"} />}</span>
						</div>
					</div>
				}
			</div>
		);
	}


	/* === === === === === === ===
             RENDER UNIT
	 === === === === === === === */
	_renderUnit(unit) {
		if (!unit){return undefined;}
		const {edit} = this.props;
		const {unitSide} = this.state;

		return <StorageUnitBase
			unit={unit}
			path={`storageUnits.#${unit.id}`}
			side={unitSide}
			hasControl={!(unit.backFrame != null && unit.frontFrame != null)}
			customProps={{
				isEdit: is.valid(edit)
			}}
			onChangeUnitSide={unitSide => this.setState({unitSide})}
		/>
	}

	/* === === === === === === ===
	       RENDER ADDRESS
	 === === === === === === === */
	_renderAddress(address) {
		const {Action_Location_Edit_Modify} = this.props;
		address = address || T_LocationAddress;

		return (
			<Address
				address={address}
				onModify={Action_Location_Edit_Modify}
				model={T_LocationAddress}
				isEdit={is.valid(this.props.edit) || false}
			/>
		);
	}

	/* === === === === === === ===
			RENDER TOOLBAR
	 === === === === === === === */
	_renderToolbar(location, isEdit = false) {
		location = location || {};
		const {Action_Location_Edit_Init, Action_Location_Edit_Cancel, Action_Location_Edit_Modify, Thunk_Location_Remove, locationID, fetching, failed, Thunk_Location_Update} = this.props;

		return <Toolbar
			section={"locations"}
			isEdit={isEdit}
			backUrl={routes.LOCATIONS}
			onEditCancel={() => Action_Location_Edit_Cancel()}
			content={this._isEditSwitch(
				<h1>{location.name || <Translate id={"location_unnamedLocation"} />}</h1>,
				<TextInput
					placeholder={window.translator.translate("location_name")}
					defaultValue={get(this, `props.edit.name`, '')}
					path={`name`}
					onModify={Action_Location_Edit_Modify}
				/>
			)}
			onEdit={() => Action_Location_Edit_Init()}
			onSave={() => Thunk_Location_Update(false)}
			onRemove={() => Thunk_Location_Remove(locationID)}
			disableActionButtons={fetching || failed}
			itemID={locationID}
		/>;
	}

	/* === === === === === === ===
			RENDER STATE
	 === === === === === === === */
	_renderLocationState(location, isEdit) {
		const {Action_Location_Edit_Modify} = this.props;

		return (
			<div>
				<div className={"separator"}><label><Fa icon={"map-marker-alt"} /><Translate id={"location"} /></label></div>
				<div className={"flex-row"}>
					<div className="grid-table grid-icon-auto-1fr">
						<Fa icon={"power-off"} />
						<label><Translate id={"state_ENABLED"} /></label>
						{
							this._isEditSwitch(
								<span><Translate id={`state_${location.enabled ? "ENABLED" : "DISABLED"}`} /></span>,
								<ToggleButton
									readOnly={!isEdit}
									defaultValue={location.enabled || false}
									path={`enabled`}
									onModify={Action_Location_Edit_Modify}
								/>
							)
						}

						<Fa icon={"eye"} />
						<label><Translate id={"visibility"} /></label>
						{
							this._isEditSwitch(
								<span><Translate id={`visibility_${location.visibility}`} /></span>,
								<ToggleButton
									readOnly={!isEdit}
									defaultValue={location.visibility || E_Visibility.VISIBLE}
									path={`visibility`}
									valueMap={{
										true: E_Visibility.VISIBLE,
										false: E_Visibility.HIDDEN
									}}
									onModify={Action_Location_Edit_Modify}
								/>
							)
						}

						<Restricted permissions={{partnerID: false}}>
							<Fa icon={T_APP_ICONS.PARTNERS} />
							<label><Translate id={"partner"} /></label>
							{
								this._isEditSwitch(
									<span>{resolveItemName(location.partner) || <Translate id={"value_notProvided"} />}</span>,
									<PartnerSelectInput
										path={`partner`}
										canInvalidate={true}
										onModify={Action_Location_Edit_Modify}
										defaultValue={location.partner}
									/>
								)
							}
						</Restricted>
						{
							this._isEditSwitch(
								<Fragment>
									<Fa icon={T_APP_ICONS.CREATED_ON} />
									<label><Translate id={"createdOn"}/></label>
									<span>{formatAsDate(location.createdOn) || <Translate id={"value_unknown"} />}</span>
								</Fragment>,
								null
							)
						}
					</div>

					<div className={"opening-hours-wrapper"}>
						{this._isEditSwitch(
							this._renderOpeningHours(location.openingHours),
							<button onClick={() => this._openOpeningHoursModal(location)}>
								{this._renderOpeningHours(location.openingHours)}
								<Fa className={"action-button"} icon={T_APP_ICONS.EDIT_DIALOG} />
							</button>
						)}
					</div>
				</div>
			</div>
		)
	}

	/* === === === === === === ===
	  RENDER LOCATION INFORMATION
	 === === === === === === === */
	_renderLocationInformation(location, isEdit) {
		const {Action_Location_Edit_Modify} = this.props;

		return (
			<div>
				<div className={"separator"}><label><Fa icon={"info-circle"} /><Translate id={"information"} /></label></div>
				<div className="flex-row">
					<div>
						<MorphElement
							label={<h3><Fa icon={"pen-fancy"} /><Translate id="description" /></h3>}
							condition={!isEdit}
							pass={
								<TranslateEntry entry={location.description} showAll={true} />
							}
							fail={
								<LanguageSwitch
									path={"description"}
									value={get(this, "props.edit.description")}
									onModify={Action_Location_Edit_Modify}
								>
									<TextareaInput />
								</LanguageSwitch>
							}
						/>

						<div className={"developer-description"}>
							<h3><Fa icon={"code"} /><Translate id={"developerDescription"} /></h3>
							{
								this._isEditSwitch(
									<p>{location.developerDescription || <Translate id={`value_notProvided`} />}</p>,
									<TextareaInput
										path={`developerDescription`}
										placeholder={window.translator.translate("notes")}
										defaultValue={location.developerDescription || ''}
										onModify={Action_Location_Edit_Modify}
									/>
								)
							}
						</div>
					</div>
					<div>{this._renderAddress(location.address, isEdit)}</div>
				</div>
			</div>
		);
	}

	_renderLocationGallery(location) {
		const {Action_Location_Edit_Modify, edit} = this.props;
		const isEdit = is.valid(edit);

		if(is.empty(location.images) && !isEdit) {
			return null;
		}

		let dropzoneData = (location.images || []).map(image => ({
			...image,
			name: image.imageUrl,
		}));

		return (
			<Fragment>
				<div className={"separator"}><label><Fa icon={"images"} /><Translate id={"images"} /></label></div>
				{
					this._isEditSwitch(
						<Gallery images={location.images || []}/>,
						<DropzoneInput
							path={`images`}
							defaultValue={dropzoneData}
							onModify={Action_Location_Edit_Modify}
							uploadService={service_uploadImage}
							accept={"image/*"}
						>
							<DropzoneImageContainer/>
						</DropzoneInput>
					)
				}
			</Fragment>
		)
	}

	_renderUnitRedirect() {
		const {unitID, locationID, serviceID, location} = this.props;

		if(get(location, "storageUnits.length") > 0) {
			if(serviceID && !unitID) {
				let {unit} = findPathComponentsByID(location, serviceID);

				if(unit.id) {
					return <Redirect to={`${routes.LOCATIONS}/${locationID}/${unit.id}/${serviceID}`} />;
				}
			}

			if(!unitID || !get(location, `storageUnits.#${unitID}`)) {
				return <Redirect to={`${routes.LOCATIONS}/${locationID}/${location.storageUnits[0].id}`} />;
			}
		}
		return null;
	}

	/* === === === === === === ===
	       RENDER LOCATION
	 === === === === === === === */
	_renderLocation(location) {
		const {locationID, Thunk_Unit_Add, unitID} = this.props;
		const isEdit = is.valid(this.props.edit);
		const locationRoute = `${routes.LOCATIONS}/${locationID}`;

		return (
			<Fragment>
				{this._renderToolbar(location, isEdit)}
				{
					this._renderUnitRedirect()
				}

				<div id={"locationDetailContent"} className="location-detail-data-wrapper smooth-scroll">
					<div className="units-select">
						<OverflowMenu>
							<ButtonsConstructor
								buttons={[
									...get(location, `storageUnits`, []).map((unit, i) => ({
										text: (
											<Fragment>
												{unit.name || <Translate id={"unit_unnamedUnit"} />}
												{
													isEdit &&
													<Tippy content={<Translate id={"locationEdit_editUnit"} />} {...T_SharedTippyProps}>
														<button onClick={e => this._openUnitEditModal(e, i)}>
															<Fa icon={T_APP_ICONS.EDIT_DIALOG} />
														</button>
													</Tippy>
													||
													<div className={"state-icons"}>
														{
															!unit.enabled &&
															<Tippy
																{...T_SharedTippyProps}
																content={<Translate id={"state_DISABLED"}/>}
															>
																<span><Fa icon={"power-off"} className={"cl-error"}/></span>
															</Tippy>
														}
														{
															unit.visibility === E_Visibility.HIDDEN &&
															<Tippy
																{...T_SharedTippyProps}
																content={<Translate id={"visibility_HIDDEN"}/>}
															>
																<span><Fa icon={T_APP_ICONS.HIDDEN} /></span>
															</Tippy>
														}
														{
															unit.visibility === E_Visibility.AUTHORIZED_ONLY &&
															<Tippy
																{...T_SharedTippyProps}
																content={<Translate id={"visibility_AUTHORIZED_ONLY"}/>}
															>
																<span><Fa icon={T_APP_ICONS.AUTHORIZED_ONLY} /></span>
															</Tippy>
														}
													</div>
												}
											</Fragment>
										),
										href: `${locationRoute}/${unit.id}`,
									}))
								]}
							/>
						</OverflowMenu>
						{
							isEdit &&
							<Tippy
								{...T_SharedTippyProps}
								content={<Translate id={"unit_add"}/>}
							>
								<a className={"add-unit"} onClick={() => Thunk_Unit_Add(locationID, T_unitModel)}>
									<Fa icon={"plus"}/>
								</a>
							</Tippy>
						}
					</div>

					{this._renderUnit(get(location, "storageUnits", []).find(unit => (unit.id === parseInt(unitID))))}

					<div className="information-wrapper">
						{this._renderLocationState(location, isEdit)}
						{this._renderLocationInformation(location, isEdit)}
						<Restricted>
							{({hasAccess}) => (
								<CommunicationUnitsList
									locationID={location.id}
									canEdit={hasAccess(E_UserRoles.ADMIN, T_RestrictionPresets.EDIT("locations").permissions)}
								/>
							)}
						</Restricted>
						{this._renderLocationGallery(location)}
					</div>
				</div>
			</Fragment>
		);
	}

	/* === === === === === === ===
	            RENDER
	 === === === === === === === */
	render() {
		const {location, edit, locationID} = this.props;

		return (
			<DetailWrapper
				itemID={locationID}
				dataID={(location || {}).id}
				className={"location-detail"}
				isEdit={is.valid(edit)}
				listUrl={routes.LOCATIONS}
				errorContent={"location_fetch_failed"}
				selector={Selector_Location_Item_Root}
			>
				{this._renderLocation((is.valid(edit) ? edit : location) || T_locationModel)}
			</DetailWrapper>
		);
	}

	_isEditSwitch(normal, edit) {
		return is.valid(this.props.edit) && edit || normal;
	}

	_openUnitEditModal(e, i) {
		e.preventDefault();

		const {Action_Unit_Modify, Thunk_Unit_Save, Thunk_Unit_Remove, Action_Unit_Init} = this.props;
		const units = get(this, "props.edit.storageUnits", []);
		const unit = units[i];

		Action_Unit_Init(unit);

		window.modal.open({
			body: (
				<LocationUnitEditModalContent
					Action_Unit_Modify={Action_Unit_Modify}
					path={`storageUnits.${i}`}
				/>
			),
			closeButton: false,
			actionButtons: [
				{
					icon: "save",
					action: () => {
						Thunk_Unit_Save(unit.id);
						window.modal.close();
					}
				},
				{
					preset: E_ButtonPresets.REMOVE,
					action: () => {
						Thunk_Unit_Remove(unit.id);
						window.modal.close();
					}
				},
				{
					icon: "times",
					action: () => {window.modal.close();}
				},
			]
		});
	}

	_openOpeningHoursModal(location) {
		const {Action_Location_Edit_Modify} = this.props;
		window.modal.open({
			body: (
				<LocationOpeningHoursModal
					Action_Location_Edit_Modify={Action_Location_Edit_Modify}
				/>
			)
		})
	}
}

LocationDetail.propTypes = {
	activeTab: PropTypes.number,
	unitSide: PropTypes.string,
	...T_SharedReduxPropTypes,

	//Store Objects
	location: PropTypes.object,

	//Store Dispatchers
	Thunk_Location_Fetch: PropTypes.func.isRequired,
	Action_Location_Edit_Init: PropTypes.func.isRequired,
	Action_Location_Edit_Modify: PropTypes.func.isRequired,
	Action_Unit_Modify: PropTypes.func.isRequired,
	Action_Unit_Init: PropTypes.func.isRequired,
	Thunk_Location_Update: PropTypes.func.isRequired,
	Thunk_Unit_Add: PropTypes.func.isRequired,
	Thunk_Unit_Remove: PropTypes.func.isRequired,
	Thunk_Unit_Save: PropTypes.func.isRequired,
	Action_Location_Edit_Cancel: PropTypes.func.isRequired,
	Thunk_Location_Remove: PropTypes.func.isRequired,

	//React Router props
	history: PropTypes.any,
	match: PropTypes.any,
	locationID: PropTypes.number.isRequired,
	unitID: PropTypes.number,
	serviceID: PropTypes.number,

	language: PropTypes.object
};

LocationDetail.stateTypes = {};

// CONFIGURE REACT REDUX

const mapStateToProps = state => {
	const {fetching, fetched, failed} = Selector_Location_Item_Root(state);

	return {
		fetching, fetched, failed,
		location: Selector_Location(state),
		edit: Selector_Location_Edit_Location(state)
	};
};

const mapDispatchToProps = dispatch => (
	bindActionCreators({
		Thunk_Location_Fetch,
		Action_Location_Edit_Init,
		Action_Location_Edit_Modify,
		Action_Unit_Modify,
		Action_Unit_Init,
		Thunk_Location_Update,
		Thunk_Unit_Add,
		Thunk_Unit_Remove,
		Thunk_Unit_Save,
		Action_Location_Edit_Cancel,
		Thunk_Location_Remove,
	}, dispatch)
);

const hoc = withRouter(connect(mapStateToProps, mapDispatchToProps)(withLocalize(LocationDetail)));

export default hoc;
