import React from 'react'
import PropTypes from 'prop-types'
import InputBase from "./Base/InputBase";
import NumberInput from "./NumberInput";
import ButtonsConstructor from "../ButtonsConstructor";
import is from "../../../utils/is";
import { Process_padNumber } from "../../../utils/processors";

class TimeInput extends InputBase {
	constructor(props) {
		super(props);

		this.state = {
			...this.state,
			format: props.format,
			offset: 0
		}
	}

	render() {
		const {format} = this.props;
		const {offset, value} = this.state;
		let {hours, minutes} = this._parseValue(value);

		return (
			<div className={"time-inputs-wrapper input-wrapper"}>
				{this.renderPrefix()}
				<NumberInput
					onModify={(t, p, change) => this._processHours(change)}
					behavior={{
						clamp: {min: 0, max: format - 1},
						max: 2,
						padding: 2
					}}
					defaultValue={hours}
					modifyImmediately={true}
					name={"hours"}
					ref={this.setRef}
					onFocusSelectAll={true}
				/>
				&nbsp;:&nbsp;
				<NumberInput
					onModify={(t, p, change) => this._processMinutes(change)}
					behavior={{
						clamp: {min: 0, max: 59},
						max: 2,
						padding: 2
					}}
					defaultValue={minutes}
					modifyImmediately={true}
					name={"minutes"}
					ref={this.setRef}
					onFocusSelectAll={true}
				/>
				{
					this.state.format == 12 &&
					<ButtonsConstructor
						buttons={[
							{
								text: `AM`,
								textTranslated: true,
								action: () => this.setState({offset: 0}),
								active: offset == 0
							},
							{
								text: `PM`,
								textTranslated: true,
								action: () => this.setState({offset: 12}),
								active: offset == 12
							}
						]}
					/>
				}
				{this.renderInvalidateButton()}
				{this.renderSuffix()}
			</div>
		)
	}

	_processHours(hours) {
		let value = Process_padNumber(this._offsetHours(hours), 2, '0');
		this.debounceChange(this.props.path, `${value}:${this._parseValue(this.state.value).minutes}`);
	}

	_processMinutes(minutes) {
		let value = Process_padNumber(this._offsetHours(minutes), 2, '0');
		this.debounceChange(this.props.path, `${this._parseValue(this.state.value).hours}:${value}`);
	}

	_offsetHours(hours) {
		if (hours && !is.nan(parseInt(hours, 10))) {
			return parseInt(hours, 10) + this.state.offset;
		}
		return '';
	}

	_parseValue(value) {
		let [hours, minutes] = (value || ':').split(":");
		return {hours: hours || "00", minutes: minutes || "00"}
	}

	static get propTypes() {
		return {
			...super.propTypes,
			format: PropTypes.number
		}
	}

	static get stateTypes() {
		return {
			...super.stateTypes,
			format: PropTypes.number, //12h | 24h
			hours: PropTypes.any,
			minutes: PropTypes.any,
			offset: PropTypes.number
		}
	}

	static get defaultProps() {
		return {
			...super.defaultProps,
			format: 24,
			onModify: (a,b,c) => console.log(a,b,c)
		}
	}
}

export default TimeInput;
