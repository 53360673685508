import { Process_formatQueryString } from "../../utils/processors";
import { service_authenticate } from "../../services/Service_Auth";
import { Action_Auth_Fulfilled, Action_Auth_Pending, Action_Auth_Rejected, Action_Fetch_Principal_Fulfilled, Action_Fetch_Principal_Pending, Action_Fetch_Principal_Rejected } from "../actions/authActions";
import { service_fetchProfile } from "../../services/Service_Users";

export const Thunk_Authenticate = (username, password) => (
	(dispatch) => {
		dispatch(Action_Auth_Pending());

		service_authenticate(Process_formatQueryString({username, password})).then(response => {
			dispatch(Action_Auth_Fulfilled(response));
			dispatch(Thunk_Principal_Fetch());
		}, error => {
			dispatch(Action_Auth_Rejected(error));
		});
	}
);

export const Thunk_Principal_Fetch = () => (
	(dispatch) => {
		dispatch(Action_Fetch_Principal_Pending());

		if(localStorage.getItem('token')) {
			service_fetchProfile().then(response => {
				dispatch(Action_Fetch_Principal_Fulfilled(response));
			}, error => {
				dispatch(Action_Fetch_Principal_Rejected(error));
			});
		} else {
			dispatch(Action_Fetch_Principal_Rejected({message: "Token is not present in localStorage"}, {suppressToast: true}));
		}
	}
)
