import { service_deleteCommunicationUnit, service_fetchCommunicationUnits, service_insertCommunicationUnit, service_updateCommunicationUnit } from "../../services/Service_CommunicationUnit";
import { Action_CommunicationUnit_Edit_Fulfilled, Action_CommunicationUnit_Edit_Pending, Action_CommunicationUnit_Edit_Rejected, Action_CommunicationUnits_Fetch_Fulfilled, Action_CommunicationUnits_Fetch_Pending, Action_CommunicationUnits_Fetch_Rejected } from "../actions/location/communicationUnitActions";
import { Selector_CommunicationUnit_Edit } from "../selectors/Selectors_CommunicationUnit";
import { Thunk_Generic_Edit_Insert, Thunk_Generic_Edit_Save, Thunk_Generic_Edit_Update, Thunk_Generic_Fetch_All, Thunk_Generic_Remove } from "./Thunk_Generic";

export const Thunk_CommunicationUnit_Fetch_All = (locationID, queryString) => (
	Thunk_Generic_Fetch_All(
		queryString,
		qs => service_fetchCommunicationUnits(locationID, qs),
		Action_CommunicationUnits_Fetch_Pending,
		Action_CommunicationUnits_Fetch_Fulfilled,
		Action_CommunicationUnits_Fetch_Rejected
	)
);

export const Thunk_CommunicationUnit_Insert = (locationID) => (
	Thunk_Generic_Edit_Insert(
		(...props) => service_insertCommunicationUnit(locationID, ...props),
		Action_CommunicationUnit_Edit_Pending,
		Action_CommunicationUnit_Edit_Fulfilled,
		Action_CommunicationUnit_Edit_Rejected,
		Selector_CommunicationUnit_Edit
	)
);

export const Thunk_CommunicationUnit_Update = () => (
	Thunk_Generic_Edit_Update(
		service_updateCommunicationUnit,
		Action_CommunicationUnit_Edit_Pending,
		Action_CommunicationUnit_Edit_Fulfilled,
		Action_CommunicationUnit_Edit_Rejected,
		Selector_CommunicationUnit_Edit
	)
);

export const Thunk_CommunicationUnit_Remove = (unitID) => (
	Thunk_Generic_Remove(
		unitID,
		service_deleteCommunicationUnit,
		Action_CommunicationUnit_Edit_Pending,
		Action_CommunicationUnit_Edit_Fulfilled,
		Action_CommunicationUnit_Edit_Rejected
	)
);

export const Thunk_CommunicationUnit_Save = (locationID) => (
	Thunk_Generic_Edit_Save(
		Selector_CommunicationUnit_Edit,
		Action_CommunicationUnit_Edit_Pending,
		() => Thunk_CommunicationUnit_Insert(locationID),
		Thunk_CommunicationUnit_Update
	)
);
