import { Selector_Generic_Item_Root } from "./Selectors_Generic";

export const Selector_ServiceCategories_Root = state => {
	return state.serviceCategories;
};

export const Selector_ServiceCategories_Listing_Root = state => {
	return Selector_ServiceCategories_Root(state).listing;
};

export const Selector_ServiceCategories_Listing_Data = state => {
	return Selector_ServiceCategories_Listing_Root(state).data;
};

export const Selector_ServiceCategory_Root = state => {
	return Selector_ServiceCategories_Root(state).item;
};

export const Selector_ServiceCategory_Data = state => {
	return Selector_ServiceCategory_Root(state).data;
};

export const Selector_ServiceCategory_Edit = state => {
	return Selector_ServiceCategory_Root(state).edit;
};
