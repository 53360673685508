import { service_deleteUnit, service_insertUnit, service_updateUnit } from "../../services/Service_Locations";
import { Action_Unit_Send, Action_Unit_Send_Fail, Action_Unit_Send_Success } from "../actions/location/unitActions";
import { Selector_Location_Edit_Unit, Selector_Location_Item_Root } from "../selectors/Selectors_Location";
import { E_Modification } from "../Constants_StoreShared";

export function Thunk_Unit_Add(locationID, unitData) {
	return (dispatch, getState) => {
		if (Selector_Location_Item_Root(getState()).fetching) {return;} //Ignore if still fetching

		dispatch(Action_Unit_Send());

		service_insertUnit(locationID, JSON.stringify(unitData)).then(data => {
			dispatch(Action_Unit_Send_Success(E_Modification.ARRAY_PUSH, "storageUnits", data)); //On success
		}, (error) => {
			dispatch(Action_Unit_Send_Fail(error)); //On fail
		})
	}
}

export function Thunk_Unit_Save(unitID) {
	return (dispatch, getState) => {
		if (Selector_Location_Item_Root(getState()).fetching) {return;} //Ignore if still fetching

		let unitData = Selector_Location_Edit_Unit(getState());

		dispatch(Action_Unit_Send());

		service_updateUnit(unitID, JSON.stringify(unitData)).then(data => {
			dispatch(Action_Unit_Send_Success(E_Modification.ITEM_SET, `storageUnits.#${unitID}`, data)); //On success
		}, (error) => {
			dispatch(Action_Unit_Send_Fail(error)); //On fail
		})
	}
}

export function Thunk_Unit_Remove(unitID) {
	return (dispatch, getState) => {
		if (Selector_Location_Item_Root(getState()).fetching) {return;} //Ignore if still fetching

		dispatch(Action_Unit_Send());

		service_deleteUnit(unitID).then(data => {
			dispatch(Action_Unit_Send_Success(E_Modification.ARRAY_REMOVE_BY_ID, `storageUnits.#${data.id}`)); //On success
		}, (error) => {
			dispatch(Action_Unit_Send_Fail(error)); //On fail
		})
	}
}
