import React, {Fragment} from "react";
import PropTypes from "prop-types";
import {Translate} from "react-localize-redux";
import {T_ListingContentModel} from "../../../models/Models_Shared";
import ButtonsConstructor from "../ButtonsConstructor";
import Dropdown from "../Input/Dropdown";
import "../../../styles/components/ListingFooter.scss";
import Hint from "../Hint";

class ListingFooter extends React.Component {
	constructor(props) {
		super(props);

		const {size} = props;

		this.state = {
			size
		};
	}

	_renderPaginationButtons() {
		const {data, onPageChange} = this.props;

		if(!data) return;

		if (data.totalPages < 2) {
			return undefined;
		}

		const __buttonTemplate = (_, i) => (
			{
				text: i + 1,
				action: () => data.currentPage !== i && onPageChange(i),
				active: data.number == i
			}
		);

		let pagination = this._constructPaginationItems();

		return (
			<Fragment>
				{
					pagination.map((page, i) => {
						if (page !== null) {
							return <ButtonsConstructor key={i} buttons={[__buttonTemplate(null, page - 1)]} />;
						}
						return <label key={i}>..</label>;
					})
				}
			</Fragment>
		)
	}

	render() {
		const {onSizeChange, filtersApplied, onClearFilters} = this.props;
		const {size} = this.state;

		return (
			<footer className={"listing-footer"}>
				<div className={"active-filters-message"}>
					{
						filtersApplied &&
						<Hint
							icon={"exclamation-triangle"}
							text={"filters_enabledWarningMessage"}
							suffix={
								<button onClick={() => onClearFilters()}>
									<Translate id={"filters_clearFilters"} />
								</button>
							}
						/>
					}
				</div>

				<div className={"footer-content"}>
					<Dropdown
						className={"size-picker"}
						defaultValue={size}
						options={[2, 10, 20, 50, 100]}
						onModify={(type, path, value) => {
							if (size !== value) {
								this.setState({size: value});
								onSizeChange(value);
							}
						}}
					/>
					<div />
					<nav className={"pagination"}>
						{this._renderPaginationButtons()}
					</nav>
				</div>
			</footer>
		)
	}

	_constructPaginationItems() {
		const {data, middlePagesLimit, outerPagesLimit} = this.props;
		const {totalPages, number: activePage} = data || {};

		let delta = middlePagesLimit,
			left = activePage - delta,
			right = activePage + delta + 1,
			range = [],
			rangeWithDots = [],
			l;

		for (let i = 1; i <= totalPages; i++) {
			if (i == 1 || i == totalPages || i >= left && i < right) {
				range.push(i);
			}
		}

		for (let i of range) {
			if (l) {
				if (i - l === 2) {
					rangeWithDots.push(l + 1);
				} else if (i - l !== 1) {
					rangeWithDots.push(null);
				}
			}
			rangeWithDots.push(i);
			l = i;
		}

		return rangeWithDots;
	}
}

const sharedTypes = {
	size: PropTypes.number
};

ListingFooter.propTypes = {
	...sharedTypes,
	/**
	 * @type T_ListingContentModel
	 */
	data: PropTypes.object,
	outerPagesLimit: PropTypes.number,
	middlePagesLimit: PropTypes.number,
	filtersApplied: PropTypes.bool,

	onSizeChange: PropTypes.func.isRequired,
	onPageChange: PropTypes.func.isRequired,
	onClearFilters: PropTypes.func.isRequired,
};

ListingFooter.stateTypes = {
	...sharedTypes,
};

ListingFooter.defaultProps = {
	data: T_ListingContentModel,
	outerPagesLimit: 1,
	middlePagesLimit: 3
};

export default ListingFooter;
