import { CONSOLE_ERROR_BOLD, JSON_REACT_REPLACER_KEYS } from "../models/constants/Constants_Shared";
import { E_Modification } from "../store/Constants_StoreShared";
import is from "./is";
import { get } from "./utils";

/**
 * Select
 * ---
 * Returns items from within the provided range
 * ```
 *  [1,2,3].select(0,1) => [1,2]
 *  [1,2,3,4,5,6].select(1,4) => [2,3,4,5]
 *
 *  //Special Case
 *  [1,2,3].select() => [1,2,3]
 * ```
 * @param {Number} start Selection start
 * @param {Number|String|Function} end End selector (Number = index | String = indexOf result | Function = {Boolean} true => i as a result)
 * @returns {Array} Selection Array
 */
Array.prototype.select = function (start = 0, end = this.length - 1) {

	if (is.string(end)) {
		if (end.includes("len-")) {
			end = this.length - end.split("len-")[1];
		} else {
			return [...this].splice(start, this.indexOf(end) + 1);
		}
	} else if (is.function(end)) {
		for (let i = 0; i < this.length; i++) {
			if (end(this[i], i)) {
				return [...this].splice(start, i);
			}
		}
	} else {
		!is.number(end) && console.warn("Unknown end format. Use {Number}, {String} or {Function}", end);
	}
	return [...this].splice(start, end);
};

/**
 * Push Unique
 * ---
 * Pushes unique item(s) into the array
 * ```
 *  [1].pushUnique(2,3) => [1,2,3]
 *  [1,2].pushUnique(2,3) => [1,2,3]
 *  [1].pushUnique(1,1,1,1,1) => [1]
 * ```
 * @param {*} items Items
 */
Array.pushUnique = function(...items) {
	for (let i = 0; i < items.length; i++) {
		if(!this.includes(items[i])) {
			this.push(items[i]);
		}
	}
};

/**
 * Move Item
 * ---
 * Moves an item within the array
 * ```
 *  ["a","b","c"].moveItem("a", 2) => ["b","c","a"]
 *  ["a","b","c"].moveItem(0, 2) => ["b","c","a"]
 * ```
 * @param {*|Number} itemOrIndex Item or Index
 * @param {Number} newPosition New position
 * @returns {Array} Returns this array
 */
Array.prototype.moveItem = function(itemOrIndex, newPosition) {
	let index = !is.number(itemOrIndex) ? this.indexOf(itemOrIndex) : itemOrIndex;
	if (index != -1) {
		this.splice(newPosition, 0, this.splice(index, 1)[0]);
	}
	return this;
};

/**
 * Remove Item
 * ---
 * Removes and returns the item
 * ```
 *  ["a","b","c"].removeItem("a") => ["b","c"]
 * ```
 * @param {*} item Item
 * @returns {*} Removed Item
 */
Array.prototype.removeItem = function(item) {
	let index = this.findIndex(arrItem => (arrItem === item));
	if(index > -1) {
		return this.splice(index, 1);
	}
};

/**
 * Remove Index
 * ---
 * Removes and returns an item on the specified index
 * @param {Number} index Index
 * @returns {*} Removed Item
 * @see removeItem()
 */
Array.prototype.removeIndex = function(index) {
	return this.splice(parseInt(index), 1);
};

/**
 * Last Item
 * ---
 * Returns last item of an array
 * @returns {*} Last Item
 */
Array.prototype.lastItem = function() {
	return this[this.length - 1];
};

/**
 * Return previous before match
 * ---
 * Returns previous entry before the matching condition is fulfilled
 * ```
 *  [1,2,3].returnPreviousBeforeMatch(item => item == 2) => 1
 *
 *  //Clamp
 *  [1,2,3].returnPreviousBeforeMatch(item => item == 1) => null
 *  [1,2,3].returnPreviousBeforeMatch(item => item == 1, true) => 1
 * ```
 * @param {Function} condition Condition function
 * @param {Boolean} clamp Clamp (if index < 0 returns 0)
 * @returns {*|null}
 */
Array.prototype.returnPreviousBeforeMatch = function(condition, clamp = false) {
	for (let i = 0; i < this.length; i++) {
		if (condition(this[i])) {
			return i > 0 ? this[i-1] : (clamp && this[0] || null);
		}
	}
	return null;
};

/**
 * Subtract
 * ---
 * ```
 *  [1,2,3].subtract([1,2]) => [3]
 * ```
 * Subtracts one array from another
 * @param {Array} arr Array
 * @param {function(arr1item,arr2item,i,j)} compareFunction Compare function if necessary for deeper subtract conditions
 * @returns {Array}
 */
Array.prototype.subtract = function(arr, compareFunction = null) {
	if (is.empty(this) || is.empty(arr)){return this;}

	if (is.valid(compareFunction)) {
		let result = [];
		this.forEach((item1, i) => {
			for (let j = 0; j < arr.length; j++) {
				let item2 = arr[j];
				if (compareFunction(item1, item2, i, j)) {
					return;
				}
			}
			result.push(item1);
		});
		return result;
	}

	return this.filter(compareFunction || (item => !arr.includes(item)));
};

/**
 * Extract
 * ---
 * Extracts properties from array of {*}
 * ```
 *  [{a:{b:{c:1}}}, {a:{b:{c:2}}}].extract("a.b.c") => [1,2]
 * ```
 * @param {Function|String} extractParams Extraction parameters (path [string | array] OR [function] is allowed)
 * @returns {Array}
 */
Array.prototype.extract = function(extractParams) {
	return this.map((item, index)=>{
		if (is.function(extractParams)) {
			return extractParams(item, index)
		}
		if (is.string(extractParams) || is.array(extractParams)) {
			let path = extractParams;
			is.string(path) && (path = path.split("."));
			return get(item, path);
		}
	});
};

/**
 * Merge
 * ---
 * Merges two Arrays into one while overriding {this} with {arr} items
 * ```
 *  [1,2,3].merge([3,4]) => [3,4,3]
 *  [1].merge([2,3,4]) => [2,3,4]
 * ```
 * @param {Array} arr Array
 * @param {function(*,*):null} iteratorCallback Callback for each item (currentItem, mergedItem)
 * @return {Array} {this}
 */
Array.prototype.merge = function (arr, iteratorCallback = null) {
	if (is.empty(this) || is.empty(arr)) {
		return is.empty(this) ? [...arr] : [...this];
	}

	let result = [];
	const length = Math.max(this.length, arr.length);

	for (let i = 0; i < length; i++) {
		result.push(arr[i] || this[i]);
		iteratorCallback && iteratorCallback(this[i], result[i]);
	}
	return result;
};

/**
 * Find by ID
 * ---
 * Finds an item by id inside a shallow object
 * ```
 *  [{id: 1}, {id: 2, formatQueryString: true}, {id: 3}].findByID(2) => {id: 2, formatQueryString: true}
 * ```
 * @param {Number|String} id ID
 * @param {Boolean} returnIndex Return Index
 * @returns {number|*}
 */
Array.prototype.findByID = function (id, returnIndex = false) {
	if (returnIndex) {
		return this.findIndex(item => item.id == id);
	}
	return this.find(item => item.id == id);
};

Array.prototype.sortWithOrder = function (order, filterMatchFunction) {
	if (this.length < 2 || !is.valid(order)) { // Nothing to be sorted || no sort rule
		return [...this];
	}

	filterMatchFunction = filterMatchFunction || ((item, orderItem) => item == orderItem);
	let result = [];

	order.forEach(orderItem => {
		result = [
			...result,
			...(this.filter(item => filterMatchFunction(item, orderItem)) || [])
		];
	});
	return [...result, ...this.filter(item => !order.includes(item))];
};

Array.prototype.fillInOrder = function (length, startIndex = 0, max = null) {
	for (let i = 0; i < length; i++) {
		if(max && startIndex + i + 1 > max) {
			return this;
		}

		this.push(startIndex + i);
	}
	return this;
};



/**
 * Extended Object
 * ---
 */
class ExtendedObject {
	constructor(data) {
		Object.assign(this, {...data});
	}

	/**
	 * Clone
	 * ---
	 * Deep copy of the object
	 * @param {Boolean} keepInEO Keep as an ExtendedObject or just an Object
	 * @param {Boolean} removeReactProperties If true, removes react properties defined in JSON_REACT_REPLACER_KEYS
	 * @see JSON_REACT_REPLACER_KEYS
	 * @returns {ExtendedObject}
	 */
	clone(keepInEO = false, removeReactProperties = false) {
		const replacer = (k, v) => JSON_REACT_REPLACER_KEYS.includes(k) ? undefined : v;
		let clone = JSON.parse(JSON.stringify(this, removeReactProperties ? replacer : undefined));
		return keepInEO ? EO(clone) : clone;
	}

	/**
	 * Modify
	 * ---
	 * Modifies abject according to the {modificationType} along the {path} with {modification}
	 * @param {Object<E_Modification>} modificationType Modification type
	 * @param {String|Array<String>} path Modification path
	 * @param {*} modification Modification value
	 */
	modify(modificationType, path, modification) {
		path = (is.string(path) ? path.split(".") : path);

		if (!Array.isArray(path)) {
			return console.log("%cPath is not an Array!", CONSOLE_ERROR_BOLD, `type:`, typeof path, `path:`, path);
		}

		const __handleRemoveByID = (removePath) => {
			let parent = get(this, removePath.select(0, "len-1"), []);
			let removeIndex = parent.findByID(`${removePath.lastItem()}`.replace('#', ''), true);

			parent.splice(removeIndex, 1);
		};

		let target;
		switch (modificationType) {
			case E_Modification.ARRAY_PUSH:
				if(is.array(modification)) {
					get(this, path).push(...modification);
				} else {
					get(this, path).push(modification);
				}
				return;
			case E_Modification.ARRAY_SPLICE:
				get(this, path).splice(Array.isArray(modification) ? modification[0] : modification, modification[1] || 1);
				return;
			case E_Modification.ARRAY_REMOVE_ITEM:
				get(this, path).removeItem(modification);
				return;
			case E_Modification.ARRAY_REMOVE_BY_ID:
				__handleRemoveByID(path, modification);
				return;
			case E_Modification.ITEM_REMOVE:
				if (is.array(get(this, path.select(0, path.length - 1)))) {
					get(this, path.select(0, path.length - 1)).removeIndex(path.lastItem());
					return;
				}

				target = path.length >= 2 ? get(this, path.select(0, path.length - 2)) : this;
				delete target[path.lastItem()];
				return;
			case E_Modification.ARRAY_CLEAR:
				get(this, path, []).splice(0, get(this, path, []).length);
				return;
			default:
				this.set(path, modification)
		}
	}

	/**
	 * Set
	 * ---
	 * Set data at the end of the path. If the end of the path cannot be reached it will create what is missing.
	 * ```
	 *  a = EO({b: {}})
	 *  a.set("b.c.0.d", 1) => {b:{c:[{d:1}]}}
	 *  ...
	 *  a = EO({b:[{id: 5}]})
	 *  a.set("b.#5.a.d", 1) => {b:[{id: 5, a:[0:{d:1}]}]}
	 * ```
	 *
	 * **Notice!** Standalone numbers in a path will be processed as an Array index.
	 *
	 * **Notice!** When using a #{Number} in the path, it ALWAYS will be placed inside an {} since it's expecting {id: {Number}, ...}
	 *
	 * @param path Path
	 * @param value Value to set
	 */
	set(path, value) {
		if (is.empty(path) || !(is.array(path) || is.string(path))) { return; }
		if (is.string(path))
			path = path.split(".");

		const __findTargetFromID = (target, id) => target.findByID(id.replace('#', ''));
		const __pathAsArrayItem = (item) => !is.nan(parseInt(item)) || item === "$" || `${item}`.match(/^#/);

		let target = this;
		path.forEach((item, i) => {
			let nextPathItem = path[i + 1];

			if (i + 1 == path.length) {
				if (__pathAsArrayItem(item)) {
					if (is.nan(parseInt(item))) {
						if (__findTargetFromID(target, item)) {
							let index = target.findByID(item.replace('#', ''), true);
							target[index] = value;
						} else {
							target.push(value);
						}
					} else {
						if({}.hasOwnProperty.call(target, parseInt(item))) {
							target[parseInt(item)] = value;
						} else {
							target.push(value);
						}
					}
				} else {
					target[item] = value;
				}
			} else {
				if (__pathAsArrayItem(item)) {
					if (is.nan(parseInt(item))) {
						if (__findTargetFromID(target, item)) {
							target = __findTargetFromID(target, item);
						} else {
							let index = target.push({id: parseInt(item.replace('#', ''))});
							target = target[index - 1];
						}
					} else {
						if (!target[item]) {
							target[item] = __pathAsArrayItem(nextPathItem) ? [] : {};
						}
						target = target[item];
					}
				} else {
					if (target[item]) {
						target = target[item];
					} else {
						target[item] = __pathAsArrayItem(nextPathItem) ? [] : {};
						target = target[item];
					}
				}
			}
		});
		return this;
	}

	/**
	 * To Array
	 * ---
	 * Converts object to array
	 * ```
	 *  ({a:1, b:2}).toArray() => [{key: "a", value: 1}, {key: "b", value: 2}]
	 *  ({a:1, b:2}).toArray(true) => [1, 2]
	 * ```
	 * @param {Boolean} onlyValues Return only values
	 * @returns {Array}
	 */
	toArray(onlyValues = false) {
		let result = [];
		for (let key in this) {
			if ({}.hasOwnProperty.call(this, key)) {
				if (onlyValues) {
					result.push(this[key]);
				} else {
					result.push({
						key,
						value: this[key]
					});
				}
			}
		}
		return result;
	}

	fillFromArray(arr) {
		arr.forEach((item, i) => {
			this[item.key || i] = {}.hasOwnProperty.call(item, "value") ? item.value : item;
		});
		return this;
	}

	/**
	 * Find
	 * ---
	 * Finds item in object based on the match condition
	 *
	 * **Note** Items gets converted to Array in {key: {String}, value: {*}}
	 * ```
	 *  ({a:1, b:2}).find(item => item.value == 2) => {key: "b", value: 2}
	 * ```
	 * @param matchCondition
	 * @returns {*}
	 * @see ExtendedObject.toArray
	 * @see Array.find
	 */
	find(matchCondition) {
		let data = this.toArray();
		return data.find(matchCondition);
	}

	filter(filterCondition, keepInArray = false) {
		let data = this.toArray().filter(filterCondition);

		if (keepInArray) {
			return data
		}
		return new ExtendedObject().fillFromArray(data);
	}

	removeContents(resolver) {
		if (is.string(resolver)) {
			let targetRoot = get(this, resolver.split(".").select(0, "len-1"));
			delete targetRoot[resolver.split(".").lastItem()];
		} else if (is.function(resolver)) {
			resolver(this);
		}
		return this;
	}

	findByID(id, returnIndex = false) {
		let data = this.toArray(true);
		return data.findByID(id, returnIndex);
	}

	containsID(id) {
		return this.findByID(id, true) >= 0;
	}

	forEach(iterator, oneArg = false) {
		if(!iterator.call) {
			return;
		}

		this.toArray().forEach(item => {
			if(oneArg) {
				iterator(item);
			} else {
				iterator(item.key, item.value);
			}
		});
	}

	map(iterator = i => i) {
		return new ExtendedObject().fillFromArray(this.toArray().map(item => iterator.length > 1 ? iterator(item.key, item.value) : iterator(item)));
	}
}

// export const EA = (...data) => {
// 	console.log(new ExtendedArray(1,2,3) instanceof ExtendedArray);
// 	return new ExtendedArray(...data);
// };

/**
 * Extended Object
 * ---
 * @param {Object} data
 * @returns {ExtendedObject}
 * @constructor
 * @see ExtendedObject
 */
export const EO = (data) => {
	return data instanceof ExtendedObject ? data : new ExtendedObject(data);
};





Date.prototype.modify = function(...props) {
	if(is.empty(props)) {return this;}
	let {modifySelf, year, month, day, hour, minute, second, millisecond} = props[0];
	if(is.array(props[0]) || props.length > 1) {
		[modifySelf, year, month, day, hour, minute, second, millisecond] = props.length > 1 ? props : props[0];
	}

	let target = modifySelf ? this : new Date();

	target.setFullYear(!isNaN(parseInt(year)) ? year : this.getFullYear());
	target.setMonth(!isNaN(parseInt(month)) ? month : this.getMonth());
	target.setDate(!isNaN(parseInt(day)) ? day : this.getDate());
	target.setHours(!isNaN(parseInt(hour)) ? hour : this.getHours());
	target.setMinutes(!isNaN(parseInt(minute)) ? minute : this.getMinutes());
	target.setSeconds(!isNaN(parseInt(second)) ? second : this.getSeconds());
	target.setMilliseconds(!isNaN(parseInt(millisecond)) ? millisecond : this.getMilliseconds());

	return target;
};

Date.prototype.offset = function(...props) {
	if(is.empty(props)) {return this;}
	let {modifySelf, year, month, day, hour, minute, second, millisecond} = props[0];
	if(is.array(props[0]) || props.length > 1) {
		[modifySelf, year, month, day, hour, minute, second, millisecond] = props.length > 1 ? props : props[0];
	}

	let target = modifySelf ? this : new Date();

	target.setFullYear(this.getFullYear() + (year || 0));
	target.setMonth(this.getMonth() + (month || 0));
	target.setDate(this.getDate() + (day || 0));
	target.setHours(this.getHours() + (hour || 0));
	target.setMinutes(this.getMinutes() + (minute || 0));
	target.setSeconds(this.getSeconds() + (second || 0));
	target.setMilliseconds(this.getMilliseconds() + (millisecond || 0));

	return target;
};

Date.prototype.getMonthLength = function() {
	//Offset by a month and set date to 0; 0 == last day from previous month
	return new Date(this.getFullYear(), this.getMonth() + 1, 0).getDate();
};

export const E_Date_WeekDay = {
	MONDAY: "MONDAY",
	TUESDAY: "TUESDAY",
	WEDNESDAY: "WEDNESDAY",
	THURSDAY: "THURSDAY",
	FRIDAY: "FRIDAY",
	SATURDAY: "SATURDAY",
	SUNDAY: "SUNDAY",
};
const DATE_WEEKDAY_ARRAY = Object.values(E_Date_WeekDay);

Date.prototype.getDayKey = function() {
	return DATE_WEEKDAY_ARRAY[(this.getDate() + 2) % 7];
};

Date.prototype.setStartOfDay = function (modifySelf = false) {
	return this.modify({modifySelf, hour: 0, minute: 0, second: 0, millisecond: 0});
};

Date.prototype.setEndOfDay = function (modifySelf = false) {
	return this.modify({modifySelf, hour: 23, minute: 59, second: 59, millisecond: 999});
};
