import React from 'react';
import PropTypes from 'prop-types';
import { withLocalize } from 'react-localize-redux';
import Tippy from "../shared/Tippy";
import { T_SharedTippyProps } from "../../models/Models_Shared";
import Fa from "../tools/Icons/Fa";
import LanguageList from "../tools/Language/LanguageList";

const languagesToLocalizeCodeMap = {'cz': 'cs'};

const LanguageToggle = ({ languages, activeLanguage, setActiveLanguage }) => {

	const remapLanguageCode = (code) => {
		localStorage.setItem("lang", code);
		setActiveLanguage(languagesToLocalizeCodeMap[code] || code);
	};

	return (
		<Tippy
			{...T_SharedTippyProps}
			arrow={false}
			interactive={true}
			offset={0}
			delay={[0, 0]}
			theme={"language"}
			trigger={"click"}
			placement={"right-end"}
			content={
				<LanguageList
					showFlags={true}
					showLabels={true}
					activeLanguage={activeLanguage}
					onSelect={(lang) => {
						lang && remapLanguageCode(lang.code)
					}}
				/>
			}
		>
			<button className={"language-toggle"}>
				<Fa icon={"language"} />
			</button>
		</Tippy>
	)
};

LanguageToggle.propTypes = {
	languages: PropTypes.array.isRequired,
	activeLanguage: PropTypes.object,
	setActiveLanguage: PropTypes.func.isRequired
};

export default withLocalize(LanguageToggle);
