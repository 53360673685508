import { EO } from "./extensions";
import is from "./is";
import { hasOwnProperty } from "./utils";

export const PROCESS_INVALID = "NULL";

export const Process_matchPattern = (value, pattern) => {
	if (is.valid(pattern)) {
		return new RegExp(pattern).test(`${value}`);
	}
	return true;
};

/**
 * Eval Limit
 * ---
 * @param {*} value
 * @param {Number} limit
 * @param {String} evalDirection
 * @param {Boolean} processLength
 * @returns {Boolean}
 */
export const Process_evalLimit = (value, limit, evalDirection) => {
	return eval(`${value + "".length} ${evalDirection} ${limit}`);
};

export const Process_padNumber = (value, ...padding) => {
	if(is.empty(padding)) {return value;}
	let {length, content} = padding[0];
	if(Array.isArray(padding[0]) || padding.length > 1) {
		[length, content] = padding.length > 1 ? padding : padding[0];
	}

	if(value != undefined && content) {
		let result = ``;
		let len = `${value}`.length;
		for (let i = 0; i < length - len; i++) {
			result+= content;
		}
		return result + value;
	}
	return content ? Array(length).fill(content).join('') : value;
};

export const Process_formatLIKEQuerySelector = (data) => {
	return data && is.string(data) && data.replace(new RegExp(/ /, 'g'), '').split("").map(item => `%${item}%`).join("") || '';
};

export const Process_formatUserOnFetch = body => {
	let data = EO(body).clone(true);
	if (data && data.creditBalance) {
		data.creditBalance = EO(data.creditBalance).toArray();
	}
	return data;
};

export const Process_formatUserOnSubmit = body => {
	let data = EO(body).clone(true);
	if (data && data.creditBalance) {
		data.creditBalance = EO().fillFromArray(data.creditBalance);
	}
	return data;
};

export const Process_formatQueryString = (data) => {//FIXME
	let queryString = [];

	EO(data).toArray().forEach(entry => {
		let {key, value} = entry;

		if (is.valid(value) || (key == "sort" && is.valid(`${value}`.split(",")[1]))) {
			if (is.object(value)) {
				value = JSON.stringify(value);
			}

			if (is.string(value)) {
				value = encodeURIComponent(value);
			}

			queryString.push(`${key}=${value}`);
		}
	});
	// console.log(queryString);
	return queryString.join("&");
};

export const Process_formatQueryStringForURL = (data, defaultStates = {}) => {
	let filteredData = EO(data).filter(item => {
		if(is.empty(item.value) || (item.key == "sort" && is.empty(`${item.value}`.split(",")[1]))) {
			return false;
		}

		if(hasOwnProperty(defaultStates, item.key)) {
			if(is.equal(defaultStates[item.key], item.value)) {
				return false;
			}
		}
		return true;
	});
	return Process_formatQueryString(filteredData);
};

export const Process_formatFilter = (filterData) => {
	const {field, operator, value, join} = filterData || {};
	return { field, operator, value, join}
};

export const Process_dateToISO = (date, skipTimezoneOffset = false, setDayEndTime = false) => {
	let tzo = skipTimezoneOffset ? 0 : -date.getTimezoneOffset(),
		dif = tzo >= 0 ? '+' : '-',
		pad = function(num) {
			let norm = Math.floor(Math.abs(num));
			return (norm < 10 ? '0' : '') + norm;
		};
	if(setDayEndTime) {
		date.setHours(23);
		date.setMinutes(59);
		date.setSeconds(59);
	}
	return date.getFullYear() +
		'-' + pad(date.getMonth() + 1) +
		'-' + pad(date.getDate()) +
		'T' + pad(date.getHours()) +
		':' + pad(date.getMinutes()) +
		':' + pad(date.getSeconds()) +
		dif + pad(tzo / 60) +
		':' + pad(tzo % 60);
};

export const Process_msToDuration = (ms, options) => {
	options = {
		returnString: true,
		hideEmpty: true,
		showSec: true,
		daysPadding: 1,
		daysSuffix: 'd',
		hoursPadding: 1,
		hoursSuffix: 'h',
		minutesPadding: 1,
		minutesSuffix: 'm',
		secondsPadding: 1,
		secondsSuffix: 's',
		...options,
	}
	let {returnString, hideEmpty, showSec, daysSuffix, hoursSuffix, minutesSuffix, secondsSuffix, daysPadding, hoursPadding, minutesPadding, secondsPadding} = options;

	let seconds = Math.floor(ms / 1000);
	let minutes = Math.floor(seconds / 60);
	seconds = seconds % 60;
	let hours = Math.floor(minutes / 60);
	minutes = minutes % 60;

	let days = Math.floor(hours / 24);
	hours = hours % 24;

	if(returnString) {
		let resultString = '';
		if(days > 0 || !hideEmpty) resultString+= Process_padNumber(days, daysPadding, '0') + daysSuffix + ' ';
		if(hours > 0 || !hideEmpty) resultString+= Process_padNumber(hours, hoursPadding, '0') + hoursSuffix + ' ';
		if(minutes > 0 || !hideEmpty) resultString+= Process_padNumber(minutes, minutesPadding, '0') + minutesSuffix + ' ';
		if((seconds > 0 || !hideEmpty) && showSec) resultString+= Process_padNumber(seconds, secondsPadding, '0') + secondsSuffix + ' ';
		return resultString;
	}

	return {
		ms, seconds, minutes, hours, days,
	};
}
