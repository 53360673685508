import React from "react";
import ItemSelectInputBase from "../../shared/Input/Base/ItemSelectInputBase";
import { resolveItemName } from "../../../utils/utils";
import TariffSelect from "./TariffSelect";

class TariffSelectInput extends React.Component {
	render() {
		return <ItemSelectInputBase
			modalContent={<TariffSelect/>}
			onLabel={item => resolveItemName(item, `name`)}
			{...this.props}
		/>
	}
}

export default TariffSelectInput;
