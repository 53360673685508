import React from "react";
import PropTypes from "prop-types";
import is from "../../../utils/is";
import { service_getImageBinary, service_getImageThumbnailBinary } from "../../../services/Service_Images";
import { errorHandler } from "../../../store/ResponseHandling";
import { IMAGE_FETCH_REJECTED } from "../../../store/actions/fileActions";
import CSBaseComponent from "../../CSBaseComponent";
import ErrorBlock from "../Error/ErrorBlock";
import CubeSpinner from "../LoadingIndicator/CubeSpinner";
import "../../../styles/components/ServerImage.scss";
import placeholder from "../../../images/placeholder.png";

class ServerImage extends CSBaseComponent {
	constructor(props) {
		super(props);

		this.state = {
			...super.state,
			src: props.src || '',
			fetching: false,
			fetched: !props.src && !props.imageID,
			failed: false,
		};
	}

	componentDidMount() {
		super.componentDidMount();
		const {src} = this.props;

		!src && this._fetchImage();
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		super.componentDidUpdate(prevProps, prevState, snapshot);

		if(
			!is.equal(prevProps.src, this.props.src) ||
			!is.equal(prevProps.imageID, this.props.imageID)
		) {
			URL.revokeObjectURL(prevState.image);
			!this.props.src && this._fetchImage();

			if(!this.props.src && !this.props.imageID) {
				this.setState({image: null});
			}
		}
	}

	render() {
		const {className, style, alt, imageID, src, errorClassName, onLoad} = this.props;
		let {image, failed, fetching, fetched} = this.state;

		return (
			<div className={`server-image image-wrapper ${className || ''}`} style={style}>
				{
					failed &&
					<button onClick={() => this._fetchImage()}>
						<ErrorBlock className={errorClassName} icon={"sync"} title={<span/>} />
					</button>
				}
				{
					(!src && fetching) && <CubeSpinner block />
				}
				{
					(src || fetched) &&
					<img
						alt={alt || `Image #${imageID}`}
						src={src || image || placeholder}
						onLoad={onLoad}
					/>
				}
			</div>
		)
	}

	_fetchImage() {
		const {imageID, thumbnail} = this.props;

		if(imageID) {
			this.setState({fetching: true, image: {}, failed: false, fetched: false});
			(thumbnail ? service_getImageThumbnailBinary : service_getImageBinary)(imageID).then(image => {
				this._isMounted && this.setState({image: URL.createObjectURL(image), fetched: true});
			}, error => {
				this._isMounted && this.setState({failed: true});
				errorHandler(error, IMAGE_FETCH_REJECTED);
			}).finally(() => {
				this._isMounted && this.setState({fetching: false});
			});
		}
	}

	static get sharedTypes() {
		return {
			src: PropTypes.string,
		}
	}

	static get propTypes() {
		return {
			...super.propTypes,
			...this.sharedTypes,
			imageID: PropTypes.number,
			thumbnail: PropTypes.bool,
			alt: PropTypes.string,
			errorClassName: PropTypes.string,
			onLoad: PropTypes.func,
		}
	}

	static get stateTypes() {
		return {
			...super.stateTypes,
			...this.sharedTypes,
			image: PropTypes.any,
		}
	}

	static get defaultProps() {
		return {
			...super.defaultProps,
			src: null,
			alt: null,
			uploadProgress: null,
			errorClassName: "dark",
			onLoad: () => null,
		}
	}
}

export default ServerImage;
