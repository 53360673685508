import Tippy from "./Tippy";
import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { Translate } from "react-localize-redux";
import { T_SharedPropTypes, T_SharedTippyProps } from "../../models/Models_Shared";
import is from "../../utils/is";
import { get, hasOwnProperty, resolveDynamicComponent } from "../../utils/utils";
import Fa from "../tools/Icons/Fa";
import UserConfirmation from "./Input/UserConfirmation";
import NavLinkAlt from "./NavLinkAlt";
import { T_ButtonPresetsModel } from "../../models/Models_ButtonPresets";
import withRestrictions from "./Restrictions/withRestrictions";
import { isRestricted } from "../../utils/restrictionUtils";

class ButtonsConstructor extends React.Component {
	constructor(props) {
		super(props);

		this.state = {};
	}

	_wrapWithTippy(button, body) {
		const tippy = button.disabled ? button.disabledTippy : button.tippy;

		if (tippy) {
			let content = get(tippy, `content`)
			let data = {
				content: content || tippy,
				...(content && {...tippy}),
			}

			if(button.disabled) {
				data.theme = data.theme || "remove-confirm";
			}

			let buttonProps = this._unifyButtonProps(button);

			return (
				<Tippy
					{...T_SharedTippyProps}
					{...data}
				>
					{button.disabled ? <i className={`button-wrapper ${buttonProps.className}`}>{body}</i> : body}
				</Tippy>
			);
		}
		return body;
	}

	_wrapWithConfirm(button, buttonBody) {
		if (button.confirm) {
			if(is.function(button.confirm)) {
				return button.confirm(buttonBody, button);
			}

			return (
				<UserConfirmation
					theme={get(button, `confirm.theme`, `remove-confirm`)}
					className={get(button, `confirm.className`)}
					onConfirm={get(button, `confirm.action`, button.action)}
					confirmContent={get(button, `confirm.content`, button.confirm)}
				>
					{buttonBody}
				</UserConfirmation>
			);
		}
		return buttonBody;
	}

	_wrapWithPowerUserOptions(button, body) {
		if (button.powerUser) {
			return <Tippy
				{...T_SharedTippyProps}
				trigger={"contextmenu"}
				content={button.powerUser}
				interactive={true}
				offset={0}
				delay={0}
			>
				{body}
			</Tippy>
		}
		return body;
	}

	_renderButtonContent(button) {
		return (
			<Fragment>
				{resolveDynamicComponent(button.icon, <Fa icon={`${button.icon}`} />)}
				{resolveDynamicComponent(button.text, <span>{button.textTranslated ? button.text : <Translate id={button.text} />}</span>)}
			</Fragment>
		)
	}

	_renderBody(button) {
		if(button.href) {
			return this._renderLinkBody(button);
		}
		return this._renderButtonBody(button);
	}

	_renderLinkBody(button) {
		let buttonProps = this._unifyButtonProps(button);

		return (
			<NavLinkAlt
				className={`${buttonProps.className || ''}`}
				style={buttonProps.style}
				disabled={buttonProps.disabled}
				onContextMenu={e => {buttonProps.powerUser && e.preventDefault()}}
				isActive={() => typeof buttonProps.active == "function" ? buttonProps.active(buttonProps) : buttonProps.active}
				to={buttonProps.disabled ? '' : get(buttonProps, `href`)}
				exact={buttonProps.exact}
				{...(buttonProps.action && {onClick: buttonProps.action})}
				{...buttonProps.props}
			>
				{this._renderButtonContent(button)}
			</NavLinkAlt>
		)
	}

	_renderButtonBody(button) {
		let buttonProps = this._unifyButtonProps(button);

		return (
			<button
				className={`${buttonProps.className || ''}`}
				style={buttonProps.style}
				onClick={get(buttonProps, `confirm.action`, buttonProps.confirm) ? undefined : buttonProps.action}
				disabled={buttonProps.disabled}
				onContextMenu={e => {buttonProps.powerUser && e.preventDefault()}}
				{...(buttonProps.active && {active: `${typeof buttonProps.active == "function" ? buttonProps.active(buttonProps) : buttonProps.active}`})}
				{...buttonProps.props}
			>
				{this._renderButtonContent(button)}
			</button>
		)
	}

	_renderButton(button, i) {
		if (!button) {return undefined}

		if(button.preset) {
			button = {
				...this._getPreset(button.preset),
				...button,
			};
		}

		return (
			<Fragment key={i}>
				{
					(hasOwnProperty(button, "action") || hasOwnProperty(button, "href")) &&
					this._wrapWithPowerUserOptions(
						button,
						this._wrapWithConfirm(
							button,
							this._wrapWithTippy(
								button,
								this._renderBody(button)
							)
						)
					)
					||
					button
				}
			</Fragment>
		)
	}

	_renderButtons() {
		const {buttons, staticButtons, children} = this.props;

		return (
			<Fragment>
				{[...(buttons || []), ...(staticButtons || [])].filter(
					button => button && !button.hidden && !this._isRestricted(button)
				).map(
					(button, i) => this._renderButton(button, i)
				)}
				{children}
			</Fragment>
		);
	}

	render() {
		const {wrapInNav, className, style} = this.props;

		if (wrapInNav) {
			return <nav className={className} style={style}>{this._renderButtons()}</nav>;
		} else if (className || style) {
			return <div className={className} style={style}>{this._renderButtons()}</div>;
		}
		return this._renderButtons();
	}

	_unifyButtonProps(button) {
		const {sharedProps} = this.props;

		return {
			...sharedProps,
			...button,
			...(sharedProps.style && button.style && {
				style: {
					...sharedProps.style,
					...button.style,
				}
			})
		}
	}

	_getPreset(preset) {
		if(typeof preset == "object") {
			let presetData = T_ButtonPresetsModel[preset.type] || {};

			if(typeof presetData == "function") {
				return presetData(preset.data || {});
			}
			return presetData;
		}

		return T_ButtonPresetsModel[preset] || {};
	}

	_isRestricted(button) {
		const {hasAccess} = this.props;
		return isRestricted(button, hasAccess, button);
	}
}

ButtonsConstructor.propTypes = {
	...T_SharedPropTypes,
	wrapInNav: PropTypes.bool,
	/**
	 * @type {{buttons: Array<T_ActionButton>}}
	 */
	buttons: PropTypes.array.isRequired,
	staticButtons: PropTypes.array,
	sharedProps: PropTypes.object,

	hasAccess: PropTypes.func.isRequired,
};

ButtonsConstructor.stateTypes = {};

ButtonsConstructor.defaultProps = {
	wrapInNav: false,
	staticButtons: [],
	sharedProps: {}
};

export default withRestrictions(ButtonsConstructor);
