// eslint-disable-next-line no-unused-vars
import {DELETE, formatQueryString, POST, PUT, sendRequest} from "./apiServices/apiConnector";

export const service_fetchAuthorizations = (queryString = null) => {
	return sendRequest(`/service/authorization/${formatQueryString(queryString)}`).then(response => response.json());
};

export const service_fetchAuthorization = (authorizationID) => {
	return sendRequest(`/service/authorization/${authorizationID}`).then(response => response.json());
};

export const service_insertAuthorization = (body) => {
	const username = JSON.parse(body)?.username;
	return sendRequest([`/service/authorization/${formatQueryString(`email=${encodeURIComponent(username)}`)}`, POST, body]).then(response => response.json());
};

export const service_updateAuthorization = (authorizationID, body) => {
	return sendRequest([`/service/authorization/${authorizationID}`, PUT, body]).then(response => response.json());
};

export const service_deleteAuthorization = (authorizationID) => {
	return sendRequest([`/service/authorization/${authorizationID}`, DELETE]).then(response => response);
};
