import { service_deletePartner, service_fetchPartner, service_fetchPartners, service_insertPartner, service_updatePartner } from "../../services/Service_Partners";
import { Action_Partner_Edit_Fulfilled, Action_Partner_Edit_Pending, Action_Partner_Edit_Rejected, Action_Partner_Fetch_Fulfilled, Action_Partner_Fetch_Pending, Action_Partner_Fetch_Rejected, Action_Partners_Fetch_Fulfilled, Action_Partners_Fetch_Pending, Action_Partners_Fetch_Rejected } from "../actions/partners/partnersActions";
import { Selector_Partner_Edit } from "../selectors/Selectors_Partners";
import { Thunk_Generic_Edit_Insert, Thunk_Generic_Edit_Save, Thunk_Generic_Edit_Update, Thunk_Generic_Fetch_All, Thunk_Generic_Fetch_Item, Thunk_Generic_Remove } from "./Thunk_Generic";

export const Thunk_Partners_Fetch_All = queryString => (
	Thunk_Generic_Fetch_All(
		queryString,
		service_fetchPartners,
		Action_Partners_Fetch_Pending,
		Action_Partners_Fetch_Fulfilled,
		Action_Partners_Fetch_Rejected
	)
);

export const Thunk_Partner_Fetch = itemID => (
	Thunk_Generic_Fetch_Item(
		itemID,
		service_fetchPartner,
		Action_Partner_Fetch_Pending,
		Action_Partner_Fetch_Fulfilled,
		Action_Partner_Fetch_Rejected
	)
);

export const Thunk_Partner_Insert = () => (
	Thunk_Generic_Edit_Insert(
		service_insertPartner,
		Action_Partner_Edit_Pending,
		Action_Partner_Edit_Fulfilled,
		Action_Partner_Edit_Rejected,
		Selector_Partner_Edit
	)
);

export const Thunk_Partner_Update = () => (
	Thunk_Generic_Edit_Update(
		service_updatePartner,
		Action_Partner_Edit_Pending,
		Action_Partner_Edit_Fulfilled,
		Action_Partner_Edit_Rejected,
		Selector_Partner_Edit
	)
);

export const Thunk_Partner_Save = () => (
	Thunk_Generic_Edit_Save(
		Selector_Partner_Edit,
		Action_Partner_Edit_Pending,
		Thunk_Partner_Insert,
		Thunk_Partner_Update
	)
);

export const Thunk_Partner_Remove = (id) => (
	Thunk_Generic_Remove(
		id,
		service_deletePartner,
		Action_Partner_Edit_Pending,
		Action_Partner_Edit_Fulfilled,
		Action_Partner_Edit_Rejected
	)
);
