// eslint-disable-next-line no-unused-vars
import {DELETE, formatQueryString, POST, processBlobWithFilename, PUT, sendRequest} from "./apiServices/apiConnector";
import { Process_formatQueryString } from "../utils/processors";

export const service_fetchUsers = (queryString = null) => {
	return sendRequest(`/user/${formatQueryString(queryString)}`).then(response => response.json());
};

export const service_fetchUser = (userID) => {
	return sendRequest(`/user/${userID}`).then(response => response.json());
};

export const service_insertUser = (body) => {
	return sendRequest([`/user/`, POST, body]).then(response => response.json());
};

export const service_updateUser = (userID, body) => {
	return sendRequest([`/user/${userID}`, PUT, body]).then(response => response.json());
};

export const service_deleteUser = (userID) => {
	return sendRequest([`/user/${userID}`, DELETE]).then(response => response);
};

export const service_exportUsers = () => {
	return sendRequest(`/user/export`).then(response => response.json());
};

/**
 * Register User
 * ---
 * @param {{email: String, password: String}} body
 * @returns {Promise<{email: String, forename: String, id: Number, surname: String}>}
 */
export const service_registerUser = (body) => {
	return sendRequest([`/user/register`, POST, body]).then(response => response.json());
};

export const service_changePassword = (oldPassword, newPassword) => {
	return sendRequest([`/password/change`, POST, JSON.stringify({oldPassword, newPassword})]).then(response => response);
}

export const service_verifyEmail = (code) => {
	return sendRequest([`/user/email/verify${formatQueryString(Process_formatQueryString({code}))}`, POST]).then(response => response);
};

export const service_downloadUsersExport = (queryString) => {
	return sendRequest(`/user/export${formatQueryString(queryString)}`).then(response => processBlobWithFilename(response));
};

// === === === === ===
//      PROFILE
// === === === === ===

export const service_fetchProfile = () => {
	return sendRequest(`/user/profile`).then(response => response.json());
};

export const service_updateProfile = (_, body) => {
	return sendRequest([`/user/profile`, PUT, body]).then(response => response.json());
};

export const service_deleteProfile = () => {
	return sendRequest([`/user/profile`, DELETE]).then(response => response);
};

// === === === === ===
// EMAIL VERIFICATION
// === === === === ===

//Profile only
export const service_sendEmailVerification = () => {
	return sendRequest([`/user/email/retry`, POST]).then(response => response);
};
