// eslint-disable-next-line no-unused-vars
import { DELETE, formatQueryString, POST, PUT, sendRequest } from "./apiServices/apiConnector";

export const service_fetchVouchers = (queryString = null) => {
	return sendRequest(`/voucher/${formatQueryString(queryString)}`).then(response => response.json());
};

export const service_fetchVoucher = (voucherID) => {
	return sendRequest(`/voucher/${voucherID}`).then(response => response.json());
};

export const service_insertVoucher = (body) => {
	return sendRequest([`/voucher/`, POST, body]).then(response => response.json());
};

export const service_updateVoucher = (voucherID, body) => {
	return sendRequest([`/voucher/${voucherID}`, PUT, body]).then(response => response.json());
};

export const service_deleteVoucher = (voucherID) => {
	return sendRequest([`/voucher/${voucherID}`, DELETE]).then(response => response);
};
