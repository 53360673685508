import React from "react";
// eslint-disable-next-line no-unused-vars
import PropTypes from "prop-types";
import { resolveErrorBlockContentByError } from "../../utils/utils";
import ErrorBlock from "../shared/Error/ErrorBlock";
import CubeSpinner from "../shared/LoadingIndicator/CubeSpinner";
import QS from "query-string";
import { withRouter } from "react-router-dom";
import CSBaseComponent from "../CSBaseComponent";

class TokenVerification extends CSBaseComponent {
	constructor(props) {
		super(props);

		this.state = {
			...super.state,
			token: null,
			loading: true,
			error: null,
			tokenValid: true,
		};
	}

	componentDidMount() {
		super.componentDidMount();

		const {history} = this.props;
		this._historyListener = history.listen(() => this._handleHistoryUpdate());
		this._setTokenFromQuery();
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		super.componentDidUpdate(prevProps, prevState, snapshot);

		if(prevState.token != this.state.token) {
			this._validateToken();
		}
	}

	componentWillUnmount() {
		super.componentWillUnmount();

		if(this._historyListener) {
			this._historyListener();
			this._historyListener = null;
		}
	}

	render() {
		const {children, errorProcessor, defaultErrorContent} = this.props;
		const {loading, error, tokenValid} = this.state;

		if(loading) {
			return <CubeSpinner block={true}/>
		}

		if(error || !tokenValid) {
			return <ErrorBlock {...errorProcessor(resolveErrorBlockContentByError(error, defaultErrorContent, null))} />
		}

		return children;
	}

	_handleHistoryUpdate() {
		this._setTokenFromQuery();
	}

	_setTokenFromQuery() {
		let {code} = QS.parse(window.location.search);
		if(!code) {
			this.setState({tokenValid: false, loading: false});
		}
		else {
			this.setState({token: code, tokenValid: true});
		}
	}

	_validateToken() {
		const {verifier} = this.props;
		const {token} = this.state;

		this.setState({loading: true});
		verifier(token).then(() => {
			this.setState({tokenValid: true});
			this.props.onToken(this.state.token);
		}, error => {
			if(error.status === 404) {
				this.setState({tokenValid: false});
			}
			else {
				this.setState({tokenValid: false, error});
			}
		}).finally(() => {
			this.setState({loading: false});
		});
	}

	static get propTypes() {
		return {
			...super.propTypes,
			verifier: PropTypes.func.isRequired,
			defaultErrorContent: PropTypes.string,
			errorProcessor: PropTypes.func,
			onToken: PropTypes.func,
		}
	}

	static get stateTypes() {
		return {
			...super.stateTypes,
			token: PropTypes.string,
			tokenValid: PropTypes.bool,
			loading: PropTypes.bool,
			error: PropTypes.any,
		}
	}

	static get defaultProps() {
		return {
			...super.defaultProps,
			errorProcessor: e => e,
			onToken: () => null,
		}
	}
}

export default withRouter(TokenVerification);
