// eslint-disable-next-line no-unused-vars
import {DELETE, formatQueryString, POST, PUT, sendRequest} from "./apiServices/apiConnector";
import {service_uploadFile} from "./Service_FileUpload";
import {API_URL} from "./apiServices/constants/endpoints";

export const service_fetchPartners = (queryString = null) => {
	return sendRequest(`/partner/${formatQueryString(queryString)}`).then(response => response.json());
};

export const service_fetchPartner = (partnerID) => {
	return sendRequest(`/partner/${partnerID}`).then(response => response.json());
};

export const service_insertPartner = (body) => {
	return sendRequest([`/partner/`, POST, body]).then(response => response.json());
};

export const service_updatePartner = (partnerID, body) => {
	return sendRequest([`/partner/${partnerID}`, PUT, body]).then(response => response.json());
};

export const service_deletePartner = (partnerID) => {
	return sendRequest([`/partner/${partnerID}`, DELETE]).then(response => response);
};

export const service_fetchPartnerTermsAndConditions = partnerID => {
	return sendRequest(`/partner/${partnerID}/terms/pdf`).then(response => response.blob());
};

export const service_updatePartnerTermsAndConditions = partnerID => (file, progressChange = () => null, timeout = null) => {
	return service_uploadFile(`${API_URL}/partner/${partnerID}/terms/pdf`, file, progressChange, timeout);
};
