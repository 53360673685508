import React from 'react';
import {Translate, withLocalize} from "react-localize-redux";
import Fa from "../components/tools/Icons/Fa";
import {EO} from "./extensions";
import is from "./is";
import {E_APIServiceActions} from "../models/constants/Constants_Shared";
import {INVALID_FIELDS} from "../store/actions/sharedActions";

/**
 * Get Inflex
 * ---
 * @param props
 */
export const GetInflex = (props) => {
	// eslint-disable-next-line react/prop-types
	const {value, name, rules, clamp} = props;

	// eslint-disable-next-line react/prop-types
	let rule = value >= rules.lastItem().value ? rules.lastItem() : rules.returnPreviousBeforeMatch(rule => (rule.value > value), clamp);
	return <Translate id={`${name}${rule && rule.suffix || ""}`}/>;
};

const TranslateEntry = (props) => {
	// eslint-disable-next-line react/prop-types
	const {entry, showAll, activeLanguage, className, label, fallback} = props;

	if (!entry) return fallback || null;

	if (showAll) {
		return (
			<section className={`locale-wrapper ${className}`}>
				{label}
				<div className={`grid-table grid-icon-auto-1fr`}>
					{
						is.valid(entry) &&
						entry.map((item, i) => (
							<React.Fragment key={i}>
								<Fa icon={item.languageCode} custom={true} />
								<label>{item.languageCode}</label>
								<p>{item.text}</p>
							</React.Fragment>
						))
						||
						<Translate id={"value_notProvided"} />
					}
				</div>
			</section>
		);
	}

	let data = Array.isArray(entry) ? entry.find(k => k.languageCode === activeLanguage.code) : entry;
	return (data ? data.text : data) || fallback || null;
};

let translateEntry = withLocalize(TranslateEntry);
export {translateEntry as TranslateEntry};

export const getEnabledTranslationKeys = (enabled) => (`state_${enabled ? "ENABLED" : "DISABLED"}`);
export const getVisibilityTranslationKeys = (visibilityType) => (`visibility_${visibilityType}`);

/**
 * @enum {String}
 * Week Days
 * ---
 */
export const E_WeekDays = {
	MONDAY: "MONDAY",
	TUESDAY: "TUESDAY",
	WEDNESDAY: "WEDNESDAY",
	THURSDAY: "THURSDAY",
	FRIDAY: "FRIDAY",
	SATURDAY: "SATURDAY",
	SUNDAY: "SUNDAY",
};

/**
 *
 * @param {E_WeekDays} day Day of the week
 * @param {('short'|'long'|'narrow')} format Return format
 * @param {Boolean} capitalize Capitalize first letter
 */
export const getLocalizedWeekDay = (day = "MONDAY", format = "short", capitalize = true) => {
	let lang = window.translator.getActiveLanguage(true);

	let firstDate = new Date(0);
	let str = new Date(new Date(firstDate).setDate(5 + EO(E_WeekDays).toArray(true).indexOf(day))).toLocaleDateString(lang, {weekday: format || 'short'});

	if(capitalize && str) {
		str = str.charAt(0).toUpperCase() + str.slice(1);
	}
	return str;
};

export const generalizeResponseContent = (content, error, type, action, generalizeErrorStatus = false) => {
	switch (error) {
		case `TypeError: Failed to fetch`:
			return `error_CONNECTION_LOST`;
		default:
			break;
	}

	if(generalizeErrorStatus && error && error.status) {
		switch (error.status) {
			case 403: //Insufficient permission
			case 511:
				return `error_INSUFFICIENT_PERMISSIONS`;
			case 404: //Not found
				return `error_ENTRY_DOES_NOT_EXIST`;
			default:
				break;
		}
	}

	switch (type) {
		case INVALID_FIELDS:
			return `pleaseFillOutAllFields`;
		default:
			break;
	}

	switch (action) {
		case E_APIServiceActions.ITEMS_FETCH:
			return `error_ITEMS_FETCH`;
		case E_APIServiceActions.ITEM_FETCH:
			return `error_ITEM_FETCH`;
		case E_APIServiceActions.ITEM_INSERT:
			return `error_ITEM_INSERT`;
		case E_APIServiceActions.ITEM_UPDATE:
			return `error_ITEM_UPDATE`;
		case E_APIServiceActions.ITEM_DELETE:
			return `error_ITEM_DELETE`;
		default:
			return process.env.NODE_ENV === "production" ? "Error_GeneralUnexpectedError" : content;
	}
}
