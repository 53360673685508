import React from 'react';
import PropTypes from 'prop-types';
import { Translate } from "react-localize-redux";
import { T_APP_ICONS } from "../../../models/constants/Constants_Shared";
import CSBaseComponent from "../../CSBaseComponent";
import Fa from "../Icons/Fa";
import FiltersModal from "./FiltersModal";
import is from "../../../utils/is";
import { Process_formatLIKEQuerySelector } from "../../../utils/processors";
import { getModifiedState } from "../../../utils/utils";
import { E_Modification } from "../../../store/Constants_StoreShared";
import withRestrictions from "../../shared/Restrictions/withRestrictions";
import { isRestricted } from "../../../utils/restrictionUtils";

class Filter extends CSBaseComponent {
	constructor(props) {
		super(props);

		this.state = {
			...this.state,
			filtersData: {},
		};
	}

	componentDidMount() {
		super.componentDidMount();
		const {onFiltersReady} = this.props;

		onFiltersReady(this._unifyFilterData());
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		const {filtersOpen, applyFilters} = this.props;

		if(!is.equal(filtersOpen, prevProps.filtersOpen)) {
			if(filtersOpen) {
				this._openFilters();
			}
		}

		if(!is.equal(applyFilters, prevProps.applyFilters)) {
			this._applyFilters();
		}
	}

	render() {
		return null;
	}

	open() {
		this._openFilters();
	}

	_openFilters() {
		const {filters, onFiltersClosed} = this.props;

		window.modal.open({
			body: (
				<FiltersModal
					filters={this._processFilters(filters)}
					onFiltersModify={this._handleFilterUpdate.bind(this)}
				/>
			),
			actionButtons: [
				{
					icon: <Fa icon={"filter-slash"} custom={true} />,
					action: () => {
						this._clearFilters();
						window.modal.close();
					},
					tippy: <Translate id={`filters_clearFilters`} />,
					className: "remove-button"
				},
				{
					icon: T_APP_ICONS.SAVE,
					tippy: <Translate id={"apply"}/>,
					action: () => {
						this._applyFilters();
						window.modal.close();
					}
				}
			]
		}, () => onFiltersClosed())
	}

	_processFilters(filters) {
		const {hasAccess} = this.props;

		return filters.filter(filter => filter && filter.field && filter.type && !filter.hidden && !isRestricted(filter, hasAccess, filter));
	}

	_unifyFilterData(data = {}) {
		const {filters} = this.props;

		return filters.map(filter => {
			const {key, operator, queryValue, value: defaultValue} = filter;
			let value = data[key] || queryValue ||  defaultValue;

			return {
				...filter,
				value: operator === "LIKE" ? Process_formatLIKEQuerySelector(value) : value,
				join: filter.join || "AND",
			}
		}).filter(filter => is.valid(filter.value));
	}

	_applyFilters() {
		const {onFiltersApply} = this.props;
		const {filtersData} = this.state;

		onFiltersApply(this._unifyFilterData(filtersData))
	}

	_clearFilters() {
		this.props.onFiltersApply([]);
	}

	_handleFilterUpdate(data) {
		this.setState(state => getModifiedState(state, E_Modification.ITEM_SET, `filtersData`, data));
	}
}

Filter.propTypes = {
	/**
	 * @type {T_FilterItemModel[]}
	 */
	filters: PropTypes.array, //External data

	applyFilters: PropTypes.bool, //Listener value (any change should trigger some function regardless of the value)

	onFiltersApply: PropTypes.func.isRequired,
	onFiltersReady: PropTypes.func.isRequired,
	onFiltersClosed: PropTypes.func,
};

Filter.stateTypes = {
	filtersData: PropTypes.object
};

Filter.defaultProps = {
	onFiltersApply: () => {
		console.warn("onFiltersApply function is undefined");
		return [];
	}
};

export default withRestrictions(Filter);
