import React from "react";
// eslint-disable-next-line no-unused-vars
import PropTypes from "prop-types";
import Fa from "../../tools/Icons/Fa";
import { Translate } from "react-localize-redux";
import { css } from "../../../utils/utils";
import { service_verifyEmail } from "../../../services/Service_Users";
import TokenVerification from "../../tools/TokenVerification";
import LanguageToggle from "../../Navigation/LanguageToggle";

class VerifyEmail extends React.Component {
	render() {
		const {className, style} = this.props;

		return (
			<section className={`verify-email ${className}`} style={{...style, height: "100%", display: "flex"}}>
				<TokenVerification
					verifier={service_verifyEmail}
					defaultErrorContent={"INVALID_TOKEN"}
					errorProcessor={this._processErrorBlockProps}
				>
					<div style={css(`display: flex; align-items: center; justify-content: center; flex-direction: column; width: 100%; height: 100%`)}>
						<Fa icon={"check"} style={css(`font-size: 4em; color: var(--state-success-color)`)}/>
						<h1><label><Translate id={"success_EMAIL_VERIFIY"}/></label></h1>
						<p style={css(`margin-top: 1em`)}><Translate id={"closeWindowPrompt"}/></p>
					</div>
				</TokenVerification>
				<div style={css(`position: absolute; bottom: 0.8em; left: 0.8em; font-size: 2em`)}>
					<LanguageToggle/>
				</div>
			</section>
		)
	}

	_processErrorBlockProps(props) {
		if(props.title == "INVALID_TOKEN") {
			return {
				...props,
				title: "error_VERIFY_EMAIL_TOKEN_EXPIRED",
				icon: "clock",
			}
		}

		return props;
	}

	static get propTypes() {
		return {
			...super.propTypes,
			className: PropTypes.string,
			style: PropTypes.object,
		}
	}
}

export default VerifyEmail;
