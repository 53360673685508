import React from "react";
import PropTypes from "prop-types";
import Fa from "../../../tools/Icons/Fa";
import { Translate } from "react-localize-redux";
import TextInput from "../../../shared/Input/TextInput";
import { callErrorToast } from "../../../../utils/utils";
import { service_changePassword } from "../../../../services/Service_Users";
import { E_ToastStyle } from "../../../../models/Models_Toast";
import { errorHandler } from "../../../../store/ResponseHandling";
import CSBaseComponent from "../../../CSBaseComponent";
import ButtonsConstructor from "../../../shared/ButtonsConstructor";
import { E_ButtonPresets } from "../../../../models/Models_ButtonPresets";

class ChangePassword extends CSBaseComponent {
	constructor(props) {
		super(props);

		this.state = {
			...this.state,
			currentPassword: null,
			newPassword: null,
			newPasswordConfirm: null,
			sending: false,
		};
	}

	render() {
		const {className, style} = this.props;
		const {sending, newPassword, currentPassword, newPasswordConfirm} = this.state;

		return (
			<section className={`change-password ${className}`} style={style}>
				<form onSubmit={this._changePassword.bind(this)}>
					<div className={"grid-table grid-icon-auto-1fr"}>
						<Fa icon={"key"} />
						<label><Translate id={"password_current"}/>*</label>
						<TextInput
							type={"password"}
							path={`currentPassword`}
							onModify={this._updateData.bind(this)}
							modifyImmediately={true}
						/>

						<Fa icon={"lock"} />
						<label><Translate id={"password_new"}/>*</label>
						<TextInput
							type={"password"}
							path={`newPassword`}
							onModify={this._updateData.bind(this)}
							modifyImmediately={true}
						/>

						<span/>
						<label><Translate id={"password_new_confirm"}/>*</label>
						<TextInput
							type={"password"}
							path={`newPasswordConfirm`}
							onModify={this._updateData.bind(this)}
							modifyImmediately={true}
						/>
					</div>

					<ButtonsConstructor buttons={[{
						preset: E_ButtonPresets.PASSWORD_CHANGE,
						disabled: sending || newPassword != newPasswordConfirm || !currentPassword || !newPassword,
						style: {marginTop: 10},
						disabledTippy: <div style={{padding: 5}}><Translate id={!currentPassword || !newPassword ? "pleaseFillOutAllFields" : "passwords_mismatch"}/></div>
					}]}/>
				</form>
			</section>
		)
	}

	_updateData(t, path, password) {
		this.setState({[path]: password});
	}

	_changePassword(e) {
		e.preventDefault();
		const {currentPassword, newPassword, newPasswordConfirm} = this.state;

		if(currentPassword && newPassword && newPasswordConfirm) {
			if(newPassword === newPasswordConfirm) {
				this.setState({sending: true});
				service_changePassword(currentPassword, newPassword).then(() => {
					//Success response
					window.toaster.showToast({
						style: E_ToastStyle.SUCCESS,
						content: "passwordChangeSuccess",
					});
					window.modal.close();
				}, errorResponse => {
					errorResponse.json().then(error => {
						errorHandler(error, `INVALID_CREDENTIALS`);
					}, () => {
						//Unable to parse response; something went terribly wrong...
						callErrorToast("Error_GeneralUnexpectedError");
					});
				}).finally(() => {
					this._isMounted && this.setState({sending: false});
				});
			}
			else {
				callErrorToast("passwords_mismatch");
			}
		} else {
			callErrorToast("pleaseFillOutAllFields");
		}
	}

	static get propTypes() {
		return {
			...super.propTypes,
		}
	}

	static get stateTypes() {
		return {
			...super.stateTypes,
			currentPassword: PropTypes.string,
			newPassword: PropTypes.string,
			newPasswordConfirm: PropTypes.string,
			sending: PropTypes.bool,
		}
	}

	static get defaultProps() {
		return {
			...super.defaultProps,
		}
	}
}

export default ChangePassword;
