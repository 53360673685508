import React from 'react';
import PropTypes from 'prop-types';
import { Translate } from "react-localize-redux";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { E_EnabledStatus, T_APP_ICONS } from "../../../models/constants/Constants_Shared";
import { E_LocationAvailability, E_Visibility } from "../../../models/Models_Location";
import { Selector_Locations_Listing_Root } from "../../../store/selectors/Selectors_Location";
import { Thunk_Location_Insert, Thunk_Locations_Fetch_All } from "../../../store/thunk/Thunk_Location";
import { EO } from "../../../utils/extensions";
import { TranslateEntry } from "../../../utils/localeUtils";
import { formatAddressInOneLine, get, resolveItemName } from "../../../utils/utils";
import CSBaseComponent from "../../CSBaseComponent";
import Dropdown from "../../shared/Input/Dropdown";
import ListingPage from "../../shared/Listing/ListingPage";
import Fa from "../../tools/Icons/Fa";
import ItemListing from '../../shared/Listing/ItemListing';
import { NavLink } from 'react-router-dom';
import routes from '../../../routes/routes';
import '../../../styles/pages/Location/LocationList.scss';
import TableListing from "../../shared/Listing/TableListing";
import TableRow from "../../shared/Listing/TableRow";
import TableColumn from "../../shared/Listing/TableColumn";
import ItemRedirect from "../../shared/ItemRedirect";
import { T_RestrictionPresets } from "../../../models/Models_Restrictions";
import {service_downloadLocationsExport} from "../../../services/Service_Locations";

class LocationsList extends CSBaseComponent {
	constructor(props) {
		super(props);
	}

	_renderTableTemplate(location, i, headerItems) {
		return (
			<TableRow key={i} href={`${routes.LOCATIONS}/${location.id}`} headerItems={headerItems}>
				<TableColumn column={"icon"}>{this._getTableIconState(location)}</TableColumn>
				<TableColumn column={"name"}>{location.name || <Translate id={"location_unnamedLocation"} />}</TableColumn>
				<TableColumn column={"partner"}>
					{
						get(location, `partner.id`) &&
						<ItemRedirect href={`${routes.PARTNERS}/${location.partner.id}`}>
							{resolveItemName(location.partner)}
						</ItemRedirect>
						||
						'-'
					}
				</TableColumn>
				<TableColumn column={"address"}>{formatAddressInOneLine(location.address) || '-'}</TableColumn>
				<TableColumn column={"visibility"}>{<Translate id={`visibility_${location.visibility}`} />}</TableColumn>
				<TableColumn column={"availability"}>{<Translate id={`locationAvailability_${location.availabilityState}`} />}</TableColumn>
			</TableRow>
		)
	}

	_renderTemplate(location, i) {
		return (
			<NavLink key={i} to={`${routes.LOCATIONS}/${location.id}`} className="list-item fadeIn" available={location.availabilityState} exact={true}>
				<div className={"front"}>
					{
						location.availabilityState == E_LocationAvailability.AVAILABLE &&
						<Fa icon={T_APP_ICONS.LOCATIONS} className={"available"} />
					}
					<label>{location.name || <Translate id={"location_unnamedLocation"} />}</label>
					<div>{formatAddressInOneLine(location.address)}</div>
					<nav className={"state-icons"}>
						{
							location.availabilityState == E_LocationAvailability.NOT_AVAILABLE_OUT_OF_ORDER &&
							<Fa className={"out-of-order"} icon={"exclamation-triangle"} />
						}
						{
							location.availabilityState == E_LocationAvailability.NOT_AVAILABLE_OFF_HOURS &&
							<Fa className={"off-hours"} icon={"snooze"} custom={true} />
						}
						{
							location.visibility === E_Visibility.HIDDEN &&
							<Fa icon={T_APP_ICONS.HIDDEN} />
						}
					</nav>
				</div>
				<div className={"back"}>
					<label>{location.name || <Translate id={"location_unnamedLocation"} />}</label>
					<div><Translate id={"address"} />: {formatAddressInOneLine(location.address)}</div>
					<TranslateEntry entry={location.description} />
					<nav style={{marginTop: "auto"}}>
						{
							location.visibility !== E_Visibility.VISIBLE &&
							<div><Translate id={`visibility_${location.visibility}`} /></div>
						}
						{
							location.availabilityState !== E_LocationAvailability.AVAILABLE &&
							<div><Translate id={`locationAvailability_${location.availabilityState}`} /></div>
						}
					</nav>
				</div>
				<small>{location.id}</small>
			</NavLink>
		)
	}

	render() {
		const {Thunk_Locations_Fetch_All, Thunk_Location_Insert} = this.props;

		return (
			<section className={"location-list"}>
				<ListingPage
					section={"locations"}
					name={"locations"}
					titleIcon={T_APP_ICONS.LOCATIONS}
					titleText={"locations"}
					titleLeftContent={
						<button
							className={`list-item add`}
							onClick={() => Thunk_Location_Insert()}
						>
							<Fa icon={T_APP_ICONS.ADD} />
							<label><Translate id={"add"} /></label>
						</button>
					}
					subscriberFilters={{
						enabled: {
							icon: "power-off",
							label: "state_ENABLED",
							field: "enabled",
							operator: "=",
							type: <Dropdown
								options={EO(E_EnabledStatus).toArray(true)}
								onLabel={item => window.translator.translate(`state_${item}`)}
								valueMap={{
									"ENABLED": "true",
									"DISABLED": "false"
								}}
							/>
						},
						visibility: {
							icon: "eye",
							label: "visibility",
							field: "visibility",
							type: <Dropdown
								options={EO(E_Visibility).toArray(true)}
								onLabel={item => window.translator.translate(`visibility_${item}`)}
							/>
						}
					}}
					subscriberFooter={true}
					subscriberThunk={Thunk_Locations_Fetch_All}
					subscriberRootSelector={Selector_Locations_Listing_Root}
					headerItems={{
						icon: {
							label: "",
							width: "32px",
							sort: false,
							filter: false
						},
						name: {},
						partner: {
							field: "partner.name",
							restricted: T_RestrictionPresets.PARTNERS,
						},
						address: {
							// field: ["address.street", "address.city"] //TODO: TBD; integrate multiple fields as one filter?
							filter: false,
						},
						visibility: {
							filter: false,
						},
						availability: {
							label: "locationAvailability",
							filter: false,
						},
					}}
					views={[
						{
							view: "list",
							default: true,
							button: {
								icon: "th",
								tippy: <Translate id={"viewMode_list"} />
							},
							content: <ItemListing className={`list-wrapper`} itemTemplate={this._renderTemplate.bind(this)} />
						},
						{
							view: "table",
							button: {
								icon: "table",
								tippy: <Translate id={"viewMode_table"} />
							},
							content:
								<TableListing canModifyColumns={true} className={"stripes"} itemTemplate={this._renderTableTemplate.bind(this)} />
						}
					]}
					showExport={true}
					onExportService={(query) => service_downloadLocationsExport(query)}
				/>
			</section>
		);
	}

	_getTableIconState(location) {
		let state = location.availabilityState;

		switch (state) {
			case E_LocationAvailability.AVAILABLE:
				return <Fa style={{filter: "brightness(0.8)"}} className={"cl-success"} icon={T_APP_ICONS.LOCATIONS} />;
			case E_LocationAvailability.NOT_AVAILABLE_OFF_HOURS:
				return <Fa className={"cl-error"} icon={"snooze"} custom={true} />;
			case E_LocationAvailability.NOT_AVAILABLE_OUT_OF_ORDER:
				return <Fa className={"cl-error"} icon={"exclamation-triangle"} />;
		}
	}

	static get propTypes() {
		return {
			...super.propTypes,
			Thunk_Location_Insert: PropTypes.func.isRequired,
			Thunk_Locations_Fetch_All: PropTypes.func.isRequired,
		}
	}
}

const mapDispatchToProps = dispatch => (
	bindActionCreators({
		Thunk_Location_Insert,
		Thunk_Locations_Fetch_All
	}, dispatch)
);

export default connect(null, mapDispatchToProps)(LocationsList);
