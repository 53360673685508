// eslint-disable-next-line no-unused-vars
import { PUT, sendRequest } from "./apiServices/apiConnector";
import { service_uploadFile } from "./Service_FileUpload";
import { API_URL } from "./apiServices/constants/endpoints";

export const service_fetchSettings = () => {
	return sendRequest(`/settings`).then(response => response.json());
};

export const service_updateSettings = (_, body) => {
	return sendRequest([`/settings`, PUT, body]).then(response => response.json());
};

export const service_fetchTermsAndConditions = () => {
	return sendRequest(`/terms/pdf`).then(response => response.blob());
};

export const service_updateTermsAndConditions = (file, progressChange = () => null, timeout = null) => {
	return service_uploadFile(`${API_URL}/terms/pdf`, file, progressChange, timeout)
};
