import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { T_SharedReduxPropTypes } from "../../../models/Models_Shared";
import { E_FeedbackState, T_Feedback } from "../../../models/Models_Feedback";
import { Translate } from "react-localize-redux";
import routes from "../../../routes/routes";
import { T_APP_ICONS } from "../../../models/constants/Constants_Shared";
import Fa from "../../tools/Icons/Fa";
import is from "../../../utils/is";
import { Selector_FeedbackItem_Root } from "../../../store/selectors/Selectors_Feedback";
import { Action_Feedback_Clear, Action_Feedback_Edit_Cancel, Action_Feedback_Edit_Init, Action_Feedback_Edit_Modify } from "../../../store/actions/feedback/feedbackActions";
import Dropdown from "../../shared/Input/Dropdown";
import { get, resolveItemName } from "../../../utils/utils";
import { Thunk_Feedback_Fetch, Thunk_Feedback_Save } from "../../../store/thunk/Thunk_Feedback";
import "../../../styles/pages/Feedback/FeedbackDetail.scss"
import { EO } from "../../../utils/extensions";
import Gallery from "../../shared/Gallery";
import Toolbar from "../../shared/Toolbar";
import DetailWrapper from "../../shared/DetailWrapper";
import { T_RestrictionPresets } from "../../../models/Models_Restrictions";
import ItemRedirect from "../../shared/ItemRedirect";

class FeedbackDetail extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			...super.state,

		};
	}

	componentDidMount() {
		const {feedbackID, Thunk_Feedback_Fetch, Action_Feedback_Edit_Init} = this.props;

		if (feedbackID) {
			Thunk_Feedback_Fetch(feedbackID);
		} else {
			Action_Feedback_Edit_Init(T_Feedback);
		}
	}

	componentWillUnmount() {
		const {Action_Feedback_Clear} = this.props;

		Action_Feedback_Clear()
	}

	_renderToolbar() {
		const {edit, Action_Feedback_Edit_Cancel, feedbackID, Thunk_Feedback_Remove, fetching, failed, Action_Feedback_Edit_Init, Thunk_Feedback_Save} = this.props;
		const isEdit = is.valid(edit);

		return <Toolbar
			section={"feedback"}
			isEdit={isEdit}
			backUrl={routes.FEEDBACK}
			content={<h1><Translate id={feedbackID ? (isEdit ? "feedback_edit" : "feedback_detail") : "feedback_create"}/></h1>}
			onEditCancel={() => Action_Feedback_Edit_Cancel()}
			onEdit={() => Action_Feedback_Edit_Init()}
			onSave={() => Thunk_Feedback_Save()}
			onRemove={() => Thunk_Feedback_Remove(feedbackID)}
			disableActionButtons={fetching || failed}
			itemID={feedbackID}
		/>;
	}

	_renderFeedback(feedback) {
		const {Action_Feedback_Edit_Modify} = this.props;

		return (
			<Fragment>
				{this._renderToolbar()}
				<div className={"feedback"}>
					<div className={"grid-table grid-icon-auto-1fr"}>
						<Fa icon={"reply"}/>
						<label><Translate id={"demandResponse"}/></label>
						<span><Translate id={feedback.demandResponse ? "yes" : "no"}/></span>

						<Fa icon={"tag"}/>
						<label><Translate id={"subject"}/></label>
						<span>{feedback.subject}</span>

						<span/>
						<label><Translate id={"state"}/></label>
						{this._isEditSwitch(
							<span><Translate id={feedback.state}/></span>,
							<Dropdown
								onModify={Action_Feedback_Edit_Modify}
								path={`state`}
								defaultValue={feedback.state}
								options={EO(E_FeedbackState).toArray(true)}
							/>
						)}

						<Fa icon={T_APP_ICONS.USER}/>
						<label><Translate id={"reportedBy"}/></label>
						<span>
							{
								feedback.reporter &&
								<ItemRedirect
									href={`${routes.USERS}/${feedback.reporter.id}`}
									restricted={T_RestrictionPresets.USERS}
									wrapChildren={true}
								>
									{resolveItemName(feedback.reporter, `email`)}
								</ItemRedirect>
								||
								<Translate id={"value_unknown"}/>
							}
						</span>

						<Fa icon={T_APP_ICONS.LOCATIONS}/>
						<label><Translate id={"location"}/></label>
						<span>{
							feedback.location &&
							<ItemRedirect
								href={`${routes.LOCATIONS}/${feedback.location.id}`}
								restricted={T_RestrictionPresets.LOCATIONS}
								wrapChildren={true}
							>
								{resolveItemName(feedback.location)}
							</ItemRedirect>
							||
							<Translate id={"value_unknown"}/>
						}</span>

						<Fa icon={"pen-alt"}/>
						<label><Translate id={"description"}/></label>
						<pre>{feedback.description}</pre>
					</div>

					{
						get(feedback, `images.length`, 0) > 0 &&
						<Fragment>
							<div className={"separator"}><label><Fa icon={"images"} /><Translate id={"images"} /></label></div>
							<Gallery images={feedback.images} />
						</Fragment>
					}
				</div>
			</Fragment>
		)
	}

	render() {
		const {feedback, edit, feedbackID} = this.props;

		return (
			<DetailWrapper
				itemID={feedbackID}
				dataID={(feedback || {}).id}
				className={"feedback-detail"}
				isEdit={is.valid(edit)}
				listUrl={routes.FEEDBACK}
				errorContent={"feedback_fetch_failed"}
				selector={Selector_FeedbackItem_Root}
			>
				{this._renderFeedback((is.valid(edit) ? edit : feedback) || T_Feedback)}
			</DetailWrapper>
		);
	}

	_isEditSwitch(normal, edit) {
		return is.valid(this.props.edit) ? edit : normal;
	}

	static get propTypes() {
		return {
			...super.propTypes,
			...T_SharedReduxPropTypes,
			feedbackID: PropTypes.number,
			feedback: PropTypes.object,
		}
	}

	static get defaultProps() {
		return {
			feedback: T_Feedback,
		}
	}
}

const mapStatesToProps = state => {
	const {fetching, fetched, failed, data, edit} = Selector_FeedbackItem_Root(state);

	return {fetching, fetched, failed, feedback: data, edit};
};

const mapDispatchToProps = dispatch => (
	bindActionCreators({
		Action_Feedback_Edit_Init,
		Action_Feedback_Edit_Cancel,
		Action_Feedback_Edit_Modify,
		Thunk_Feedback_Save,
		Thunk_Feedback_Fetch,
		Action_Feedback_Clear
	}, dispatch)
);

export default connect(mapStatesToProps, mapDispatchToProps)(FeedbackDetail);
