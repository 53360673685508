import React from "react";
import PropTypes from "prop-types";
import { clamp, mapRangeUnclamped } from "../../utils/utils";
import { T_SharedPropTypes } from "../../models/Models_Shared";
import "../../styles/components/ProgressBar.scss";

class ProgressBar extends React.Component {
	constructor(props) {
		super(props);
	}

	render() {
		const {className, style, children, progress, min, max, displayProgress, displayMin, displayMax} = this.props;
		let displayProgressValue = mapRangeUnclamped(progress, min, max, displayMin, displayMax);

		return (
			<span
				className={`progress-bar ${className}`}
				style={{
					...style,
					"--progress-bar-value": clamp(mapRangeUnclamped(progress, min, max, 0, 100), 0, 100)
				}}
			>
				{
					displayProgress &&
					<label>{displayProgressValue} %</label>
				}
				{
					children &&
					React.cloneElement(children, {progress: displayProgressValue})
				}
			</span>
		);
	}

	static get propTypes() {
		return {
			...super.propTypes,
			...T_SharedPropTypes,
			progress: PropTypes.number,
			min: PropTypes.number,
			max: PropTypes.number,
			displayProgress: PropTypes.bool,
			displayMin: PropTypes.number,
			displayMax: PropTypes.number,
		}
	}

	static get defaultProps() {
		return {
			...super.defaultProps,
			min: 0,
			max: 1,
			displayProgress: false,
			displayMin: 0,
			displayMax: 100,
		}
	}
}

export default ProgressBar;
