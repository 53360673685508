export const E_FeedbackState = {
	NEW: "NEW",
	BEING_RESOLVED: "BEING_RESOLVED",
	RESOLVED: "RESOLVED",
	INVALID: "INVALID"
};

/**
 *
 * @type {{images: [], demandResponse: Boolean, subject: String, description: String, location: {}, reporter: {}, id: Number, state: E_FeedbackState}}
 */
export const T_Feedback = {
	demandResponse: false,
	description: null,
	id: null,
	images: {},
	location: {},
	reporter: {},
	state: null,
	subject: null,
};
