import React, { Fragment } from "react";
import PropTypes from "prop-types";
import CSBaseComponent from "../CSBaseComponent";
import is from "../../utils/is";
import "../../styles/components/ExpandContainer.scss";
import { Translate } from "react-localize-redux";
import ButtonsConstructor from "./ButtonsConstructor";
import { T_APP_ICONS } from "../../models/constants/Constants_Shared";

export const E_ExpandMode = {
	COLLAPSED: "COLLAPSED",
	NORMAL: "NORMAL",
	FULL: "FULL",
};

class ExpandContainer extends CSBaseComponent {
	constructor(props) {
		super(props);

		this.state = {
			...this.state,
			expandMode: props.expandMode,
			currentModeState: props.expandMode,
			style: {},
			header: {},
		};

		if(props.simpleExpansionToggle !== undefined) {
			let expandMode = props.simpleExpansionToggle ? E_ExpandMode.NORMAL : E_ExpandMode.COLLAPSED;

			this.state = {
				...this.state,
				expandMode,
				currentModeState: expandMode,
			}
		}

		this.wrapTarget = null;
	}

	componentDidMount() {
		super.componentDidMount();

		this.keyUpHandle = e => {
			switch (e.key) {
				case `Escape`:
					this._changeExpandMode(E_ExpandMode.NORMAL ? E_ExpandMode.COLLAPSED : E_ExpandMode.NORMAL);
			}
		};
		window.addEventListener("keyup", this.keyUpHandle);
		window.expandContainer = this;
	}

	componentWillUnmount() {
		super.componentWillUnmount();
		window.removeEventListener("keyup", this.keyUpHandle);
		window.expandContainer = null;
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		const {expandMode} = this.state;

		if(!is.equal(prevProps.expandMode, this.props.expandMode)) {
			this._isMounted && this.setState({expandMode: this.props.expandMode});
		}

		if(!is.equal(prevState.expandMode, expandMode)) {
			this._changeExpandMode(expandMode);
		}

		if(!is.equal(prevProps.simpleExpansionToggle, this.props.simpleExpansionToggle)) {
			this._changeExpandMode(this.props.simpleExpansionToggle ? E_ExpandMode.NORMAL : E_ExpandMode.COLLAPSED);
		}

		if(!is.equal(prevState.currentModeState, this.state.currentModeState)) {
			if(this.state.currentModeState == E_ExpandMode.COLLAPSED) {
				this._isMounted && this.setState({header: {}});
			}
		}
	}

	render() {
		const {className, style, children} = this.props;
		const {expandMode, currentModeState, header} = this.state;

		let headerDefaults = {
			resizeButton: true,
			closeButton: true,
			label: null,
		}

		let headerData = {
			...headerDefaults,
			...this.props.header,
			...header,
			actionButtons: [
				...(header.actionButtons || []),
				...(this.props.header.actionButtons || []),
			],
		}

		headerData.actionButtons = [
			...headerData.actionButtons,
			headerData.resizeButton && (
				currentModeState == E_ExpandMode.FULL &&
				{
					icon: "compress",
					tippy: <Translate id={"window"}/>,
					action: () => this._changeExpandMode(E_ExpandMode.NORMAL)
				}
				||
				{
					icon: "expand",
					tippy: <Translate id={"fullscreen"}/>,
					action: () => this._changeExpandMode(E_ExpandMode.FULL)
				}
			),
			is.boolean(headerData.closeButton) && (
				headerData.closeButton &&
				{
					icon: T_APP_ICONS.CLOSE,
					tippy: <Translate id={"close"}/>,
					action: () => this._changeExpandMode(E_ExpandMode.COLLAPSED)
				}
			)
			||
			headerData.closeButton,
		]

		return (
			<aside
				className={`expand-container ${className} expand-${currentModeState}`}
				style={{...style, ...this.state.style}}
				ref={this.setRef}
			>
				<header className={"expand-header"}>
					{headerData.label || <span/>}
					<ButtonsConstructor
						buttons={
							is.function(header.actionButtons) &&
							header.actionButtons(headerDefaults.actionButtons || [], this.props.header.actionButtons || [])
							||
							headerData.actionButtons
						}
						wrapInNav={true}
					/>
				</header>
				{
					!(
						[E_ExpandMode.COLLAPSED].includes(currentModeState) || [E_ExpandMode.COLLAPSED].includes(expandMode)
					) &&
					(Array.isArray(children) ? children : [children]).map((child, i) => (
						<Fragment key={i}>{
							child && React.cloneElement(child, {
								onUpdateHeader: (header) => this.setState({header}),
								onChangeExpandMode: (expandMode) => this._changeExpandMode(expandMode),
								isWithinModal: true,
							})
						}</Fragment>
					))
				}
			</aside>
		)
	}

	changeExpandMode(expandMode, target = null) {
		if(target) {
			this.setWrapTarget(target);
		}

		setTimeout(() => this._isMounted && this.setState({expandMode}), 100);
	}

	setWrapTarget(target, relativeTo = null) {
		this.wrapTarget = target;
		const {offsetTop, width, height, offsetLeft} = this._getWrapTargetRect(relativeTo);

		let style = {top: offsetTop, left: offsetLeft, width, height};
		this._isMounted && this.setState({style});
	}

	_changeExpandMode(expandMode = this.state.expandMode) {
		const {onExpandModeChange} = this.props;
		this._isMounted && this.setState({currentModeState: expandMode});
		clearTimeout(this._animWait);

		if(expandMode == E_ExpandMode.COLLAPSED) {
			//Transition from expanded (Normal / Full) to collapsed
			this.setWrapTarget(this.wrapTarget);
		} else {
			//Transition from collapsed to expanded (Normal / Full)
			this.setState({style: {}});
		}
		this._animWait = setTimeout(() => {
			this._isMounted && this.setState({expandMode});
			onExpandModeChange(expandMode);
		}, 300);
	}

	_getWrapTargetRect() {
		if(this.wrapTarget) {
			return {
				...(this.wrapTarget.getBoundingClientRect && this.wrapTarget.getBoundingClientRect()),
				offsetLeft: this.wrapTarget.offsetLeft,
				offsetTop: this.wrapTarget.offsetTop,
			};
		}

		return {};
	}

	static get sharedTypes() {
		return {
			//Target expand mode
			expandMode: PropTypes.string,
			header: PropTypes.any,
		}
	}

	static get propTypes() {
		return {
			...super.propTypes,
			...this.sharedTypes,
			//Alternative opening method, true = E_ExpandMode.NORMAL; false = E_ExpandMode.COLLAPSED
			simpleExpansionToggle: PropTypes.bool,
			onExpandModeChange: PropTypes.func,
		}
	}

	static get stateTypes() {
		return {
			...super.stateTypes,
			...this.sharedTypes,
			//Current expand mode
			currentModeState: PropTypes.string,
			style: PropTypes.object,
		}
	}

	static get defaultProps() {
		return {
			...super.defaultProps,
			expandMode: E_ExpandMode.COLLAPSED,
			onExpandModeChange: () => null,
			header: {},
		}
	}
}

export default ExpandContainer;
