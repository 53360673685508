import { T_DisplayText } from "./Models_Shared";
import { T_BusinessDetails } from "./Models_Partner";

export const T_SettingsModel = {
	id: undefined,
	createdOn: undefined,
	invoiceSupplier: T_BusinessDetails,
	publicContact: T_BusinessDetails,
	hotlineInfo: T_DisplayText,
};
