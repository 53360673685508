import React from "react";
import PropTypes from "prop-types";
import { Translate } from "react-localize-redux";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { T_APP_ICONS } from "../../../../models/constants/Constants_Shared";
import { E_OpeningHoursType, T_locationModel, T_OpeningHours, T_OpeningHoursPeriod } from "../../../../models/Models_Location";
import { service_fetchTimezone } from "../../../../services/Service_Utils";
import { Action_Location_Edit_Modify } from "../../../../store/actions/location/locationsActions";
import { Selector_Location_Edit_Location } from "../../../../store/selectors/Selectors_Location";
import { E_Modification } from "../../../../store/Constants_StoreShared";
import { EO } from "../../../../utils/extensions";
import is from "../../../../utils/is";
import { getLocalizedWeekDay } from "../../../../utils/localeUtils";
import { get } from "../../../../utils/utils";
import TimeInput from "../../../shared/Input/TimeInput";
import ToggleButton from "../../../shared/Input/ToggleButton";
import Dropdown from "../../../shared/Input/Dropdown";
import Fa from "../../../tools/Icons/Fa";
import "../../../../styles/pages/Location/LocationOpeningHoursModal.scss";

class LocationOpeningHoursModal extends React.Component {
	constructor(props) {
		super(props);

		this.state = {}
	}

	_renderEntry(entry, entryIndex) {
		const {Action_Location_Edit_Modify} = this.props;
		switch (entry.openingType) {
			case E_OpeningHoursType.SPECIFIC:
				return (
					<div className={"periods"}>
						{
							entry.periods.map((period, i) => (
								<div className={"period"} key={i}>
									<TimeInput
										path={`openingHours.entries.${entryIndex}.periods.${i}.fromTime`}
										defaultValue={period.fromTime}
										onModify={Action_Location_Edit_Modify}
									/>
									&nbsp;-&nbsp;
									<TimeInput
										path={`openingHours.entries.${entryIndex}.periods.${i}.toTime`}
										defaultValue={period.toTime}
										onModify={Action_Location_Edit_Modify}
									/>
									{i > 0 && <button
										className={"remove-period"}
										onClick={() => Action_Location_Edit_Modify(E_Modification.ARRAY_SPLICE, `openingHours.entries.${entryIndex}.periods`, [i, 1])}
									><Fa icon={T_APP_ICONS.CLOSE} /></button>}
								</div>
							))
						}
						<button
							className={"add-period"}
							onClick={() => this._addPeriod(entryIndex)}
						><Fa icon={"plus"} /> <Translate id={"add"} /></button>
					</div>
				);
			default:
				return undefined;
		}
	}

	render() {
		const {location, Action_Location_Edit_Modify} = this.props;
		const openingHours = {...T_OpeningHours, ...location.openingHours, entries: [...T_OpeningHours.entries].merge(get(location, `openingHours.entries`, []))};

		return (
			<section className={"opening-hours"}>
				<div className={"grid-table grid-icon-auto-1fr"}>
					<Fa icon={"clock"} />
					<label><Translate id={"openingHours_NONSTOP"} /></label>
					<ToggleButton
						onModify={Action_Location_Edit_Modify}
						path={`openingHours.twentyFourSeven`}
						defaultValue={openingHours.twentyFourSeven}
					/>
				</div>

				<div className={`entries ${openingHours.twentyFourSeven && "disabled"}`}>
					{
						openingHours.entries.map((entry, i) => (
							<div key={i} className={"entry"}>
								<div className={"entry-header"}>
									<label>{getLocalizedWeekDay(entry.weekday, 'long')}</label>

									<Dropdown
										options={EO(E_OpeningHoursType).toArray(true)}
										onLabel={value => `openingHours_${value}`}
										path={`openingHours.entries.${i}.openingType`}
										defaultValue={get(openingHours, `entries.${i}.openingType`)}
										onModify={(type, path, modify) => {
											Action_Location_Edit_Modify(type, path, modify);
											this._addPeriodIfNone(modify, `openingHours.entries.${i}.periods`, i);
										}}
									/>
								</div>
								{this._renderEntry(entry, i)}
							</div>
						))
					}
				</div>

				<div className={"grid-table grid-icon-auto-1fr"}>
					<Fa icon={"globe"} />
					<label><Translate id={"timezone"} /></label>
					<Dropdown
						options={[]}
						fetch={service_fetchTimezone}
						onLabel={value => value.offset && `${value.offset} ${value.timezone}` || value}
						onValue={value => value.timezone}
						isActive={(option, value) => value == option.timezone}
						path={`openingHours.timezone`}
						defaultValue={get(openingHours, `timezone`, '')}
						onModify={Action_Location_Edit_Modify}
					/>
				</div>
			</section>
		)
	}

	_addPeriod(entryIndex) {
		this.props.Action_Location_Edit_Modify(E_Modification.ARRAY_PUSH, `openingHours.entries.${entryIndex}.periods`, EO(T_OpeningHoursPeriod).clone())
	}

	_addPeriodIfNone(modify, path, entryIndex) {
		if (modify === E_OpeningHoursType.SPECIFIC && is.empty(get(this, `props.location.` + path))) {
			this._addPeriod(entryIndex);
		}
	}
}

LocationOpeningHoursModal.propTypes = {
	location: PropTypes.object.isRequired,
	Action_Location_Edit_Modify: PropTypes.func.isRequired
};

LocationOpeningHoursModal.stateTypes = {};

LocationOpeningHoursModal.defaultProps = {
	location: T_locationModel
};

const mapStateToProps = state => {
	return {
		location: Selector_Location_Edit_Location(state)
	};
};

const mapDispatchToProps = dispatch => (
	bindActionCreators({
		Action_Location_Edit_Modify,
	}, dispatch)
);

export default connect(mapStateToProps, mapDispatchToProps)(LocationOpeningHoursModal);
