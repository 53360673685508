import PropTypes from 'prop-types';
import React from 'react';
import { Translate } from "react-localize-redux";
import { connect } from "react-redux";
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from "redux";
import { T_APP_ICONS } from "../../../models/constants/Constants_Shared";
import { E_FeedbackState } from "../../../models/Models_Feedback";
import routes from '../../../routes/routes';
import { Selector_Feedback_Listing_Root } from "../../../store/selectors/Selectors_Feedback";
import { Thunk_Feedback_Fetch_All } from "../../../store/thunk/Thunk_Feedback";
import { get, resolveItemName } from "../../../utils/utils";
import CSBaseComponent from "../../CSBaseComponent";
import Fa from "../../tools/Icons/Fa";
import ListingPage from "../../shared/Listing/ListingPage";
import TableListing from "../../shared/Listing/TableListing";
import TableRow from "../../shared/Listing/TableRow";
import TableColumn from "../../shared/Listing/TableColumn";
import ItemRedirect from "../../shared/ItemRedirect";
import { T_RestrictionPresets } from "../../../models/Models_Restrictions";

class FeedbackList extends CSBaseComponent {
	constructor(props) {
		super(props);

		this.state = {
			...super.state,
		};
	}

	_renderTemplate(feedback, i, headerItems) {
		return (
			<TableRow key={i} href={`${routes.FEEDBACK}/${feedback.id}`} headerItems={headerItems}>
				<TableColumn column={"icon"}>{this._getStateIcon(feedback.state)}</TableColumn>
				<TableColumn column={"state"}><Translate id={feedback.state}/></TableColumn>
				<TableColumn column={"subject"}>{feedback.subject}</TableColumn>
				<TableColumn column={"location"}>
					{
						get(feedback, `location.id`) &&
						<ItemRedirect href={`${routes.LOCATIONS}/${feedback.location.id}`}>
							{resolveItemName(feedback.location)}
						</ItemRedirect>
						||
						'-'
					}
				</TableColumn>
				<TableColumn column={"reportedBy"}>
					{
						get(feedback, `reporter.id`) &&
						<ItemRedirect href={`${routes.USERS}/${feedback.reporter.id}`}>
							{resolveItemName(feedback.reporter, `email`)}
						</ItemRedirect>
						||
						'-'
					}
				</TableColumn>
				<TableColumn column={"demandResponse"}>
					{
						feedback.demandResponse &&
						<b><Translate id={"yes"}/></b>
						||
						'-'
					}
				</TableColumn>
			</TableRow>
		);
	}

	render() {
		const {Thunk_Feedback_Fetch_All} = this.props;

		return (
			<section className={"feedback-list"}>
				<ListingPage
					section={"feedback"}
					name={"feedback"}
					titleIcon={T_APP_ICONS.FEEDBACK}
					titleText={"feedback"}
					subscriberFooter={true}
					subscriberThunk={Thunk_Feedback_Fetch_All}
					subscriberRootSelector={Selector_Feedback_Listing_Root}
					headerItems={{
						icon: {
							label: "",
							width: "2em",
							sort: false,
							filter: false
						},
						state: {},
						subject: {},
						location: {
							field: "location.id",
							restricted: T_RestrictionPresets.LOCATIONS,
						},
						reportedBy: {
							field: "reporter.email",
							restricted: T_RestrictionPresets.USERS,
						},
						demandResponse: {
							filter: false
						},
					}}
					views={[
						{
							default: true,
							view: "table",
							button: {
								icon: "table",
								tippy: <Translate id={"viewMode_table"} />
							},
							content:
								<TableListing className={"stripes"} itemTemplate={this._renderTemplate.bind(this)} canModifyColumns={true} />
						}
					]}
				/>
			</section>
		);
	}

	_getStateIcon(state = E_FeedbackState.NEW) {
		switch (state) {
			case E_FeedbackState.NEW:
				return <Fa icon={"exclamation-circle"} />;
			case E_FeedbackState.BEING_RESOLVED:
				return <Fa className={"cl-warning"} icon={"clock"} />;
			case E_FeedbackState.INVALID:
				return <Fa className={"cl-error"} icon={"times"} />;
			case E_FeedbackState.RESOLVED:
				return <Fa className={"cl-success"} icon={"check"} />;
			default:
				return <span />
		}
	}
}

FeedbackList.propTypes = {
	Thunk_Feedback_Fetch_All: PropTypes.func.isRequired,
};

const mapDispatchToProps = dispatch => (
	bindActionCreators({
		Thunk_Feedback_Fetch_All,
	}, dispatch)
);

export default connect(null, mapDispatchToProps)(withRouter(FeedbackList));
