import React, {Fragment} from "react";
import PropTypes from "prop-types";
import {Translate} from "react-localize-redux";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import {bindActionCreators} from "redux";
import {E_Currency, T_APP_ICONS} from "../../../models/constants/Constants_Shared";
import {T_SharedReduxPropTypes} from "../../../models/Models_Shared";
import {E_VoucherType, T_VoucherModel} from "../../../models/Models_Voucher";
import routes from "../../../routes/routes";
import {Action_Voucher_Clear, Action_Voucher_Edit_Cancel, Action_Voucher_Edit_Init, Action_Voucher_Edit_Modify} from "../../../store/actions/vouchers/vouchersActions";
import {Selector_Generic_List_Data} from "../../../store/selectors/Selectors_Generic";
import {Selector_ServiceTypes_Root} from "../../../store/selectors/Selectors_ServiceType";
import {Selector_Voucher_Data, Selector_Voucher_Edit, Selector_Voucher_Root} from "../../../store/selectors/Selectors_Vouchers";
import {Thunk_ServiceTypes_Fetch_All} from "../../../store/thunk/Thunk_ServiceTypes";
import {Thunk_Voucher_Fetch, Thunk_Voucher_Remove, Thunk_Voucher_Save} from "../../../store/thunk/Thunk_Vouchers";
import {EO} from "../../../utils/extensions";
import is from "../../../utils/is";
import {TranslateEntry} from "../../../utils/localeUtils";
import {get, resolveItemName} from "../../../utils/utils";
import Dropdown from "../../shared/Input/Dropdown";
import ItemsPickerInput from "../../shared/Input/ItemsPickerInput";
import NumberInput from "../../shared/Input/NumberInput";
import TextInput from "../../shared/Input/TextInput";
import Fa from "../../tools/Icons/Fa";
import "../../../styles/pages/Voucher/VoucherDetail.scss";
import Toolbar from "../../shared/Toolbar";
import DetailWrapper from "../../shared/DetailWrapper";
import PartnerSelectInput from "../Partners/PartnerSelectInput";
import Restricted from "../../shared/Restrictions/Restricted";
import {T_RestrictionPresets} from "../../../models/Models_Restrictions";
import ItemRedirect from "../../shared/ItemRedirect";
import UserSelectInput from "../Users/UserSelectInput";
import RadioInput from "../../shared/Input/RadioInput";

class VoucherDetail extends React.Component {
	constructor(props) {
		super(props);

		this.state = {};
	}

	componentDidMount() {
		const {voucherID, Thunk_Voucher_Fetch, Action_Voucher_Edit_Init} = this.props;

		if (voucherID) {
			Thunk_Voucher_Fetch(voucherID);
		} else {
			Action_Voucher_Edit_Init(T_VoucherModel);
		}
	}

	componentWillUnmount() {
		const {Action_Voucher_Clear} = this.props;

		Action_Voucher_Clear();
	}

	_renderToolbar() {
		const {edit, Action_Voucher_Edit_Cancel, Thunk_Voucher_Remove, voucherID, fetching, failed, Action_Voucher_Edit_Init, Thunk_Voucher_Save} = this.props;
		const isEdit = is.valid(edit);

		return <Toolbar
			section={"vouchers"}
			isEdit={isEdit}
			backUrl={routes.VOUCHERS}
			content={<h1><Translate id={voucherID ? (isEdit ? "voucher_edit" : "voucher_detail") : "voucher_create"}/></h1>}
			onEditCancel={() => Action_Voucher_Edit_Cancel()}
			onEdit={() => Action_Voucher_Edit_Init()}
			onSave={() => Thunk_Voucher_Save()}
			onRemove={() => Thunk_Voucher_Remove(voucherID)}
			disableActionButtons={fetching || failed}
			itemID={voucherID}
		/>;
	}

	_renderCurrencySuffix(voucher) {
		return <Translate id={`currency_${voucher.currency || E_Currency.CZK}`} />;
	}

	_renderVoucherData(voucher) {
		const {Action_Voucher_Edit_Modify, edit, Thunk_ServiceTypes_Fetch_All} = this.props;
		const isEdit = is.valid(edit);

		return (
			<div>
				<div className={"separator"}><label><Fa icon={"info-circle"} /><Translate id={"information"} /></label></div>
				<div className={"grid-table grid-icon-auto-1fr"}>
					<Fa icon={T_APP_ICONS.VOUCHERS} />
					<label><Translate id={"code"} /></label>
					{
						this._isEditSwitch(
							<span>{voucher.voucherCode || <Translate id={"value_notProvided"} />}</span>,
							<TextInput
								onModify={Action_Voucher_Edit_Modify}
								path={`voucherCode`}
								defaultValue={voucher.voucherCode}
							/>
						)
					}

					<span />
					<label><Translate id={"amountLeft"} /></label>
					{
						this._isEditSwitch(
							<span>{voucher.amountLeft || <Translate id={"value_notProvided"} />}</span>,
							<NumberInput
								onModify={Action_Voucher_Edit_Modify}
								path={`amountLeft`}
								defaultValue={voucher.amountLeft}
								suffix={this._renderCurrencySuffix(voucher)}
								wrapInput={true}
							/>
						)
					}

					<Fa icon={T_APP_ICONS.CURRENCY} />
					<label><Translate id={"currency"} /></label>
					{
						this._isEditSwitch(
							<span>{voucher.currency ? this._renderCurrencySuffix(voucher) : <Translate id={"value_notProvided"} />}</span>,
							<Dropdown
								onModify={Action_Voucher_Edit_Modify}
								path={`currency`}
								defaultValue={voucher.currency}
								options={EO(E_Currency).toArray(true)}
								onLabel={item => window.translator.translate(`currency_${item}`)}
							/>
						)
					}

					<span />
					<label><Translate id={"discountPercent"} /></label>
					{
						this._isEditSwitch(
							<span>{get(voucher.discountPercent, null, <Translate id={"value_notProvided"} />, value => `${value} %`)}</span>,
							<NumberInput
								onModify={Action_Voucher_Edit_Modify}
								path={`discountPercent`}
								defaultValue={voucher.discountPercent}
								suffix={'%'}
								wrapInput={true}
								behavior={{clamp: {min: 0, max: 100}}}
							/>
						)
					}

					<Restricted>
						{({isRestricted, hasPartner}) => (
							!hasPartner() && isEdit ? isRestricted(T_RestrictionPresets.PARTNERS) : true
						) && <Fragment>
							<Fa icon={T_APP_ICONS.PARTNERS} />
							<label><Translate id={"partner"} /></label>
							{
								this._isEditSwitch(
									<span>{resolveItemName(voucher.partnerLimit) || <Translate id={"value_notProvided"} />}</span>,
									<PartnerSelectInput
										path={`partnerLimit`}
										canInvalidate={true}
										onModify={Action_Voucher_Edit_Modify}
										defaultValue={voucher.partnerLimit}
									/>
								)
							}
						</Fragment>}
					</Restricted>

					<span />
					<label><Translate id={"type"} /></label>
					{
						this._isEditSwitch(
							<span>{
								get(
									voucher.type,
									null,
									<Translate id={"value_notProvided"} />,
									value => <Translate id={`voucher_type_${value}`}/>
								)
							}</span>,
							<Translate>
								{({translate}) => (
									<RadioInput
										className={"test"}
										onModify={Action_Voucher_Edit_Modify}
										path={`type`}
										defaultValue={voucher.type}
										options={EO(E_VoucherType).toArray(true)}
										onLabel={item => translate(`voucher_type_${item}`)}
									/>
								)}
							</Translate>
						)
					}

					<Restricted
						restricted={T_RestrictionPresets.USERS}
						applyRestrictions={isEdit}
					>
						<Fa icon={T_APP_ICONS.USER} />
						<label><Translate id={"user"} /></label>
						{
							this._isEditSwitch(
								<span>{
									voucher.user &&
									<ItemRedirect
										href={`${routes.USERS}/${voucher.user.id}`}
										wrapChildren={true}
									>
										{resolveItemName(voucher.user, `email`)}
									</ItemRedirect>
									||
									<Translate id={"value_notProvided"} />
								}</span>,
								<UserSelectInput
									onModify={Action_Voucher_Edit_Modify}
									path={`user`}
									defaultValue={voucher.user}
									canInvalidate={true}
								/>
							)
						}
					</Restricted>

					<span />
					<label><Translate id={"usesLeft"} /></label>
					{
						this._isEditSwitch(
							<span>{voucher.usesLeft || <Translate id={"value_notProvided"} />}</span>,
							<NumberInput
								onModify={Action_Voucher_Edit_Modify}
								path={`usesLeft`}
								defaultValue={voucher.usesLeft}
							/>
						)
					}

					{
						this._isEditSwitch(
							<Fragment>
								<Fa icon={T_APP_ICONS.SERVICE_TYPES} />
								<label><Translate id={"serviceRestrictions"} /></label>
								<span>{
									(voucher.serviceRestrictions || []).map((service, i) => (
										<Fragment key={i}>
											<TranslateEntry entry={service.titles} />
											{i < voucher.serviceRestrictions.length - 1 && ", "}
										</Fragment>
									))
								}{is.empty(voucher.serviceRestrictions) && '-'}</span>
							</Fragment>
						)
					}
				</div>

				<Restricted restricted={T_RestrictionPresets.GLOBAL_CONFIGURATION}>{
					this._isEditSwitch(
						//Detail view is above.
						// Must be separated from the detail view so it won't be included into the grid layout
						null,
						<div className={"service-restrictions"}>
							<div className={"separator"}>
								<label><Fa icon={T_APP_ICONS.SERVICE_TYPES} /><Translate id={"serviceRestrictions"} /></label>
							</div>
							<ItemsPickerInput
								path={`serviceRestrictions`}
								defaultValue={voucher.serviceRestrictions}
								options={[]}
								onModify={Action_Voucher_Edit_Modify}
								thunk={() => Thunk_ServiceTypes_Fetch_All}
								selector={state => Selector_Generic_List_Data(Selector_ServiceTypes_Root, state)}
								onLabel={(option) => <TranslateEntry entry={option.titles}/>}
							/>
						</div>
					)
				}</Restricted>
			</div>
		)
	}

	_renderVoucher(voucher) {
		return (
			<Fragment>
				{this._renderToolbar()}
				<div className={"content-wrapper"}>
					{this._renderVoucherData(voucher)}
				</div>
			</Fragment>
		)
	}

	render() {
		const {voucher, edit, voucherID} = this.props;

		return (
			<DetailWrapper
				itemID={voucherID}
				dataID={(voucher || {}).id}
				className={"voucher-detail"}
				isEdit={is.valid(edit)}
				listUrl={routes.VOUCHERS}
				errorContent={"voucher_fetch_failed"}
				selector={Selector_Voucher_Root}
			>
				{this._renderVoucher((is.valid(edit) ? edit : voucher) || T_VoucherModel)}
			</DetailWrapper>
		);
	}

	_isEditSwitch(normal, edit) {
		return is.valid(this.props.edit) ? edit : normal;
	}
}

VoucherDetail.propTypes = {
	...T_SharedReduxPropTypes,

	voucherID: PropTypes.number,
	voucher: PropTypes.object,
	edit: PropTypes.object,

	Thunk_Voucher_Fetch: PropTypes.func.isRequired,
	Action_Voucher_Edit_Cancel: PropTypes.func.isRequired,
	Action_Voucher_Edit_Init: PropTypes.func.isRequired,
	Action_Voucher_Edit_Modify: PropTypes.func.isRequired,
	Thunk_Voucher_Save: PropTypes.func.isRequired,
	Thunk_ServiceTypes_Fetch_All: PropTypes.func.isRequired,
};

VoucherDetail.stateTypes = {};

VoucherDetail.defaultProps = {
	voucher: T_VoucherModel,
};

const mapStateToProps = state => {
	const {fetched, fetching, failed} = Selector_Voucher_Root(state);
	const voucher = Selector_Voucher_Data(state);
	const edit = Selector_Voucher_Edit(state);

	return {fetched, fetching, failed, voucher, edit}
};

const mapDispatchToProps = dispatch => (
	bindActionCreators({
		Action_Voucher_Edit_Init,
		Thunk_Voucher_Fetch,
		Action_Voucher_Edit_Cancel,
		Action_Voucher_Edit_Modify,
		Thunk_Voucher_Save,
		Thunk_ServiceTypes_Fetch_All,
		Action_Voucher_Clear,
		Thunk_Voucher_Remove
	}, dispatch)
);

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(VoucherDetail));
