import React from "react";
import PropTypes from "prop-types";
import { prebuildItemArray } from "../../../../utils/locationUtils";
import { T_blockTypeServiceCount } from "../../../../models/Models_StorageBox";
import StorageService from "./StorageService";
import Fa from "../../../tools/Icons/Fa";
import { T_APP_ICONS } from "../../../../models/constants/Constants_Shared";

class StorageBlock extends React.Component {
	constructor(props) {
		super(props);
	}

	_renderEmptyBlock() {
		const {customProps} = this.props;
		const {isEdit} = customProps;

		if(!isEdit) {return null;}

		return (
			<button>
				<Fa icon={T_APP_ICONS.ADD} />
			</button>
		)
	}

	render() {
		const {className, style, block, customProps, onBlockClick, onEmptyBlockClick} = this.props;
		const {frameID} = customProps;

		const services = prebuildItemArray(T_blockTypeServiceCount[block.blockType], block.services);

		return (
			<section
				className={`storage-block ${className || ''}`}
				style={style}
				data-block-type={block.blockType || "ADD"}
				onClick={e => {
					e.stopPropagation();

					let blockData = {...block, frameID};

					if(block.id && block.blockType) {
						onBlockClick(e, blockData);
					} else {
						onEmptyBlockClick(e, blockData);
					}
				}}
			>
				{
					block.id &&
					(services || []).map((service, i) => (
						<StorageService
							key={i}
							service={service}
							customProps={{
								...customProps,
								blockID: block.id,
								icon: customProps.icon,
							}}
							onServiceClick={this.props.onServiceClick}
						/>
					))
					||
					this._renderEmptyBlock()
				}
			</section>
		)
	}

	static get propTypes() {
		return {
			...super.propTypes,
			block: PropTypes.object,
		}
	}

	static get stateTypes() {
		return {
			...super.stateTypes,
		}
	}

	static get defaultProps() {
		return {
			...super.defaultProps,
			block: {}
		}
	}
}

export default StorageBlock;
