import React from 'react'
import PropTypes from 'prop-types'
import {CONSOLE_ERROR_BOLD, E_InputTypes, T_APP_ICONS} from "../../../../models/constants/Constants_Shared";
import {E_Modification} from "../../../../store/Constants_StoreShared";
import {PROCESS_INVALID, Process_matchPattern, Process_padNumber} from "../../../../utils/processors";
import is from "../../../../utils/is";
import {clamp, get, IdleManager, isWithinClampRange} from "../../../../utils/utils";
import {EO} from "../../../../utils/extensions";
import CSBaseComponent from "../../../CSBaseComponent";
import Fa from "../../../tools/Icons/Fa";
import Tippy from "../../Tippy";
import {T_SharedTippyProps} from "../../../../models/Models_Shared";

class InputBase extends CSBaseComponent {
	constructor(props) {
		super(props);

		this.state = {
			...this.state,
			value: props.defaultValue || '',
			isInvalid: false,
			rawValue: null,
		};

		this.idle = new IdleManager(this._applyChange.bind(this), props.idleTime || 500);

		this.setInputRef = this.setInputRef.bind(this);
	}

	componentDidMount() {
		super.componentDidMount();

		if (this.input) {
			if (this.input.type != "checkbox") {
				this.focusOut = this.input.onblur =  e => {
					if (this.lastChange) {
						let [path, type] = this.lastChange;
						this.applyChange(path, e.target.checked || e.target.value, type);
					}
				};
			}
		}
	}

	componentWillUnmount() {
		super.componentWillUnmount();
		this.idle = null;

		this.input && this.focusOut && (this.input.onblur = null);
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		super.componentDidUpdate(prevProps, prevState, snapshot);

		if (prevProps.defaultValue !== this.props.defaultValue) {
			this.setState({value: this.props.defaultValue});
		}
	}

	_renderDisabledMessage(body) {
		const {disabled, disabledMessage} = this.props;
		if(disabled && disabledMessage) {
			return (
				<Tippy
					{...T_SharedTippyProps}
					content={disabledMessage}
				>
					{body}
				</Tippy>
			)
		}
		return body;
	}

	renderInputWrapper(body) {
		const {className, style, wrapInput, disabled, internalClassName, invalid} = this.props;

		if (wrapInput) {
			return this._renderDisabledMessage(
				<div
					tabIndex={1}
					className={`input-wrapper ${className} ${internalClassName} ${disabled ? "disabled" : ''} ${invalid ? "invalid" : ''}`}
					style={style}
				>
					{body}
				</div>
			)
		}
		return body;
	}

	renderPrefix(props) {
		const {prefix} = this.props;

		return prefix && <span {...props} className={`prefix ${(props || {}).className}`}>{prefix}</span>;
	}

	renderSuffix(props) {
		const {suffix} = this.props;

		return suffix && <span {...props} className={`suffix ${(props || {}).className}`}>{suffix}</span>;
	}

	renderInvalidateButton(condition = canInvalidate => canInvalidate, props) {
		const {canInvalidate, invalidateIcon, path} = this.props;

		if (condition(canInvalidate)) {
			return (
				<a
					{...props}
					className={`invalidate-button ${(props || {}).className}`}
					onClick={e => {
						e.preventDefault();
						e.stopPropagation();
						(props || {}).onClick && (props || {}).onClick(e);

						this.applyChange(path, null)
					}}
				>
					<Fa icon={invalidateIcon} />
				</a>
			);
		}
	}

	debounceChange(path, change, type = E_Modification.ITEM_SET) {
		const {readonly, onValue, modifyImmediately} = this.props;

		if (this.props.readonly) {return;}

		let processedChange = this.processChange(change);

		if (processedChange === PROCESS_INVALID) {
			return;
		}

		this.setState({value: onValue(processedChange), rawValue: processedChange});
		if(modifyImmediately) {
			// Must be delayed since setState is async and the rawValue might be not updated yet.
			setTimeout(() => this.idle.forceTrigger(), 0);
		} else {
			this.idle.trigger();
		}
	}

	_applyChange() {
		const {path} = this.props;
		const {rawValue} = this.state;

		this.applyChange(path, rawValue);
	}

	applyChange(path, change, type = E_Modification.ITEM_SET) {
		const {readonly, onValue} = this.props;
		const behavior = {...this.props.internalBehavior, ...this.props.behavior};

		if (readonly) {return;}

		let processedChange = this.processChange(change);

		behavior.highlightInvalid && this.setState({isInvalid: processedChange == PROCESS_INVALID});
		if (processedChange === PROCESS_INVALID) {
			return;
		}


		processedChange = this.getFromValueMap(processedChange, "value");
		processedChange = onValue(processedChange);

		this.setState({value: processedChange});
		this._submitValue(type, path, processedChange);
	}

	setInputRef(target) {
		this.input = target;
	}

	getFromValueMap(key, path = "value") {
		if (!is.valid(key)) {return}

		const {valueMap} = this.props;

		if (is.object(valueMap)) {
			let item = EO(valueMap).find(item => `${item.key}` == `${key}` || `${item.value}` == `${key}`);
			return get(item, path, undefined);
		}
		return key;
	}

	processChange(change) {
		if (is.empty(change) && change != "0") {
			return change;
		}
		const {type} = this.props;
		const behavior = {...this.props.internalBehavior, ...this.props.behavior};

		if (behavior) {
			if (behavior.pattern) {
				if (!Process_matchPattern(change, behavior.pattern)) {
					return PROCESS_INVALID;
				}
			}

			if (type == E_InputTypes.NUMBER) {
				if(change === "-") {
					return change;
				}

				if (behavior.clamp) { // Min / Max input value
					change = isWithinClampRange(parseFloat(change), behavior.clamp.min, behavior.clamp.max) ? change : clamp(change, behavior.clamp.min, behavior.clamp.max);
				}

				if(behavior.max || behavior.maxLength) { // Max length of the input value
					let maxLength = (behavior.max || behavior.maxLength);
					let allZeros = `${change}`.split("").every(num => num === '0');

					if(`${parseFloat(change)}`.length > maxLength || (allZeros && `${change}`.length > maxLength)) {
						return PROCESS_INVALID;
					}
				}
			} else {
				if(behavior.max || behavior.maxLength) { // Max length of the input value
					if(`${change}`.length > (behavior.max || behavior.maxLength)) {
						return PROCESS_INVALID;
					}
				}
			}

			if (behavior.padding) { //{padding: 2} i: 1 => 01; {padding: 3} i: 5 => 005
				let padding = Process_padNumber(change, behavior.padding);

				if (padding) {
					change = padding;
				}
			}

			if (behavior.customValidator) {
				return behavior.customValidator(change);
			}
		}
		return change;
	}

	getBehavior(path) {
		const {internalBehavior, behavior} = this.props;
		return get({...internalBehavior, ...behavior}, path, null);
	}

	_submitValue(type, path, change) {
		this.props.onModify(type, path, change);
		this.lastChange = [path, type];
	}
}

InputBase.propTypes = {
	type: PropTypes.string,
	name: PropTypes.string,
	defaultValue: PropTypes.any,
	dataRoot: PropTypes.any,
	path: PropTypes.string,
	onModify: PropTypes.func.isRequired,
	debounceDelay: PropTypes.number,
	options: PropTypes.array,
	prefix: PropTypes.any,
	suffix: PropTypes.any,
	readonly: PropTypes.bool,
	disabled: PropTypes.bool,
	disabledMessage: PropTypes.any,
	valueMap: PropTypes.object,
	placeholder: PropTypes.string,
	canInvalidate: PropTypes.bool,
	invalidateIcon: PropTypes.string,
	wrapInput: PropTypes.bool,
	inputMode: PropTypes.string,
	internalClassName: PropTypes.string,
	idleTime: PropTypes.number,
	modifyImmediately: PropTypes.bool,
	inputProps: PropTypes.object,
	invalid: PropTypes.bool,

	behavior: PropTypes.object,
	internalBehavior: PropTypes.object,

	// Processing exposed functions
	onValue: PropTypes.func, // Value processor // What should be returned
};

InputBase.stateTypes = {
	value: PropTypes.any,
	isInvalid: PropTypes.bool
};

InputBase.defaultProps = {
	canInvalidate: false,
	invalidateValue: null,
	invalidateIcon: T_APP_ICONS.CLOSE,
	wrapInput: false,
	placeholder: ' ',
	onModify: (type, path, change) => {console.warn("%cDEFAULT", CONSOLE_ERROR_BOLD, type, path, change)},
	onLabel: label => label,
	onValue: (value) => value,
	idleTime: 500,
	modifyImmediately: false,
};

export default InputBase;
