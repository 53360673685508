import React from 'react';
import PropTypes from 'prop-types';
import { Translate, withLocalize } from 'react-localize-redux';
import logo from '../../images/cubesave-logo.png';
import TextInput from "../shared/Input/TextInput";
import Fa from "../tools/Icons/Fa";
import "../../styles/pages/LoginPage.scss";
import CubeSpinner from "../shared/LoadingIndicator/CubeSpinner";
import LanguageToggle from "../Navigation/LanguageToggle";
import { connect } from "react-redux";
import { Thunk_Authenticate } from "../../store/thunk/Thunk_Auth";
import { bindActionCreators } from "redux";
import { Selector_Auth_Root } from "../../store/selectors/Selectors_Auth";
import { errorHandler } from "../../store/ResponseHandling";
import { INVALID_FIELDS } from "../../store/actions/sharedActions";
import PasswordRecoveryEmail from "./Users/Password/PasswordRecoveryEmail";

class LoginPage extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			username: '',
			password: '',
		};

		this.handleSubmit = this.handleSubmit.bind(this);
	}

	handleSubmit(e) {
		e.preventDefault();
		const {Thunk_Authenticate} = this.props;

		const { username, password } = this.state;
		if (username && password) {
			Thunk_Authenticate(username, password);
		} else {
			errorHandler({
				message: "Invalid field",
			}, INVALID_FIELDS);
		}
	}

	render() {
		const { authenticating } = this.props;

		return (
			<section className={"login-page"}>
				<img src={logo} className={"img-fluid invert-logo mb-5"} alt="CUBESAVE" />
				<form name="form" onSubmit={this.handleSubmit}>
					<div className={"grid-table grid-icon-auto-1fr"}>
						<Fa icon={"at"}/>
						<label><Translate id={"email"}/></label>
						<TextInput
							type={"email"}
							name={"email"}
							defaultValue={this.state.username}
							onModify={(t, p, value) => this.setState({username: value})}
							disabled={authenticating}
							modifyImmediately={true}
						/>

						<Fa icon={"lock"}/>
						<label><Translate id={"password"}/></label>
						<TextInput
							type={"password"}
							name={"password"}
							defaultValue={this.state.password}
							onModify={(t, p, value) => this.setState({password: value})}
							disabled={authenticating}
							modifyImmediately={true}
						/>
						<span/>
						<span/>
						<button
							type={"button"}
							className={"forgotten-password"}
							onClick={() => this._openForgottenPasswordModal()}
						>
							<Translate id={"password_forgotten"}/>
						</button>
					</div>
					<button className={"fancy"} disabled={authenticating}>
						<Fa icon={"lock-open"}/>
						<span><Translate id={"logIn"}/></span>
					</button>
				</form>
				{authenticating && <CubeSpinner />}
				<LanguageToggle/>
			</section>
		);
	}

	_openForgottenPasswordModal() {
		window.modal.open({
			body: <PasswordRecoveryEmail onSubmit={() => window.modal.close()}/>,
			header: <label><Translate id={"password_forgotten"}/></label>
		})
	}

	static get propTypes() {
		return {
			...super.propTypes,
			authenticating: PropTypes.bool,

			Thunk_Authenticate: PropTypes.func.isRequired,
		}
	}

	static get stateTypes() {
		return {
			...super.stateTypes,
			username: PropTypes.string,
			password: PropTypes.string,
			submitted: PropTypes.bool,
		}
	}
}

const mapStateToProps = state => {
	const {authenticating} = Selector_Auth_Root(state);
	return {authenticating};
};

const mapDispatchToProps = dispatch => (
	bindActionCreators({
		Thunk_Authenticate
	}, dispatch)
);

export default connect(mapStateToProps, mapDispatchToProps)(withLocalize(LoginPage));
