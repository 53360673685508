import { EO } from "./extensions";
import { E_UserRoles } from "../models/constants/Constants_Shared";
import { E_UserPermissionOption } from "../models/Models_User";
import { hasOwnProperty } from "./utils";

/**
 * Get Role Levels
 * ---
 * Returns user roles in sorted array by level (as of 0.4.1 - 10.5.2020 => [ANONYMOUS, USER, ADMIN])
 * @returns {String[]}
 */
export const getRoleLevels = () => {
	return EO(E_UserRoles).toArray(true).sortWithOrder([
		E_UserRoles.ANONYMOUS,
		E_UserRoles.USER,
		E_UserRoles.ADMIN
	]);
}

/**
 * Get Permission Levels
 * ---
 * Returns user permission options in sorted array by level (as of 0.4.1 - 10.5.2020 => [DISABLED, READ, READ_WRITE])
 * @returns {String[]}
 */
export const getPermissionsLevels = () => {
	return EO(E_UserPermissionOption).toArray(true).sortWithOrder([
		E_UserPermissionOption.DISABLED,
		E_UserPermissionOption.READ,
		E_UserPermissionOption.READ_WRITE
	]);
}

export const compareLevels = (level, targetLevel, levels) => {
	return levels.indexOf(level) >= levels.indexOf(targetLevel);
};

export const compareRoleLevels = (level, targetLevel) => {
	return compareLevels(level, targetLevel, getRoleLevels());
};

export const comparePermissionLevels = (level, targetLevel) => {
	return compareLevels(level, targetLevel, getPermissionsLevels());
};

/**
 * Has Role
 * ---
 * Returns if the assigned role has the same or bigger level than the required one
 * @param {E_UserRoles} assignedRole Currently assigned role
 * @param {E_UserRoles} requiredRole Minimal required role
 * @returns {Boolean} {assignedRole} >= {requiredRole}
 */
export const hasRole = (assignedRole, requiredRole) => {
	if(!requiredRole) {return true;}
	return compareRoleLevels(assignedRole, requiredRole);
}

/**
 * Has Permissions
 * ---
 * Returns if the permissions[section] has the same or bigger level than the required one
 * @param {T_UserPermissions} permissions Permissions object
 * @param {{[T_UserPermissions]: E_UserPermissionOption}} requiredPermissions
 * @returns {Boolean}
 */
export const hasPermissions = (permissions, requiredPermissions) => {
	if(!requiredPermissions) {return true;}

	return EO(requiredPermissions).toArray().every(permission => {
		let {key, value} = permission || {};
		if(typeof value == "string") {
			//value is one of E_UserPermissionOption
			return comparePermissionLevels(permissions[key], value);
		} else {
			//Value is Boolean
			return !!permissions[key] == value;
		}
	});
}

/**
 * Has Access
 * ---
 * Unified function composed of hasRole and hasPermission as well as check for enforcing no partner or region
 * @param {T_UserPermissions} permissions
 * @param {E_UserRoles} requiredRole
 * @param {{[T_UserPermissions]: E_UserPermissionOption}|Array<{[T_UserPermissions]: E_UserPermissionOption}>} requiredPermissions
 * @param {Boolean} enforceNoPartner
 * @param {Boolean} enforceNoRegion
 * @see hasRole
 * @see hasPermissions
 * @returns {Boolean}
 */
export const hasAccess = (
	permissions = {},
	requiredRole,
	requiredPermissions,
	enforceNoPartner = false,
	enforceNoRegion = false
) => {
	return (
		hasRole(permissions.role || E_UserRoles.ANONYMOUS, requiredRole || E_UserRoles.ANONYMOUS) &&
		hasPermissions(permissions, requiredPermissions) &&
		(enforceNoPartner ? !permissions.partnerID : true) &&
		(enforceNoRegion ? !permissions.regionID : true) //TODO: TBD so I'll just leave it here just in case...
	);
}

export const isRestricted = (item, hasAccess, ...restrictedFunctionParams) => {
	if(
		hasAccess &&
		item &&
		hasOwnProperty(item, "restricted") &&
		typeof item.applyRestrictions != "undefined" ? item.applyRestrictions : true
	) {
		if(typeof item.restricted == "boolean") {
			return item.restricted;
		}

		let restricted = item.restricted || {};
		if(typeof restricted == "function") {
			return restricted(...restrictedFunctionParams);
		}
		return !hasAccess(restricted.role, restricted.permissions);
	}

	return false;
}
