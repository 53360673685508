import React from "react";
// eslint-disable-next-line no-unused-vars
import PropTypes from "prop-types";
import { resolveChildren } from "../../../utils/utils";
import { EO } from "../../../utils/extensions";
import { E_UserRoles } from "../../../models/constants/Constants_Shared";
import withRestrictions from "./withRestrictions";

class Restricted extends React.Component {
	_renderChildren() {
		const {children, principalPermissions} = this.props;

		return resolveChildren(
			children,
			false,
			{
				hasAccess: (...params) => {
					if(params.length == 0) {return null;}
					let {role, permissions, noPartner, noRegion} = params[0] || {};
					if(Array.isArray(params[0]) || params.length > 1) {
						[role, permissions, noPartner, noRegion] = params.length > 1 ? params : params[0];
					}

					return this.props.hasAccess(role, permissions, noPartner, noRegion);
				},
				hasRole: (role) => this.props.hasAccess(role),
				hasPermissions: (requiredPermissions) => this.props.hasPermissions(requiredPermissions),
				isRestricted: (restrictions = {}) => this.props.hasAccess((restrictions || {}).role, (restrictions || {}).permissions),
				hasPartner: () => !!principalPermissions.partnerID,
				hasRegion: () => !!principalPermissions.regionID,
				principalPermissions,
			}
		);
	}

	render() {
		const {role, permissions, noPartner, noRegion, applyRestrictions, fallbackContent, restricted} = this.props;

		if(!applyRestrictions) {
			return this._renderChildren();
		}

		let hasAccessResult;
		if(restricted) {
			if(typeof restricted == "function") {
				hasAccessResult = restricted();
			} else {
				hasAccessResult = this.props.hasAccess(role || restricted.role, {...restricted.permissions,...permissions}, noPartner, noRegion);
			}
		} else {
			hasAccessResult = this.props.hasAccess(role, permissions, noPartner, noRegion);
		}

		if(hasAccessResult) {
			return this._renderChildren();
		}
		return fallbackContent || null;
	}

	static get propTypes() {
		return {
			...super.propTypes,
			role: PropTypes.oneOf(EO(E_UserRoles).toArray(true)),
			permissions: PropTypes.object,
			noPartner: PropTypes.bool,
			noRegion: PropTypes.bool,
			fallbackContent: PropTypes.any,
			//Restriction override (can toggle all restrictions off)
			applyRestrictions: PropTypes.bool,
			restricted: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),

			//Permissions inherited from withRestrictions()
			principalPermissions: PropTypes.object.isRequired,
		}
	}

	static get defaultProps() {
		return {
			...super.defaultProps,
			role: E_UserRoles.ANONYMOUS,
			applyRestrictions: true,
			noPartner: false,
			noRegion: false,
		}
	}
}

export default withRestrictions(Restricted);
