import React from "react";
import PropTypes from "prop-types";
import ButtonsConstructor from "./ButtonsConstructor";
import { resolveDynamicComponent } from "../../utils/utils";
import { T_APP_ICONS } from "../../models/constants/Constants_Shared";
import is from "../../utils/is";
import { Translate } from "react-localize-redux";
import { E_ButtonPresets } from "../../models/Models_ButtonPresets";
import { T_RestrictionPresets } from "../../models/Models_Restrictions";

class Toolbar extends React.Component {
	render() {
		const {className, style, isEdit, onEditCancel, backUrl, backButton, content, actionButtons, disableActionButtons, itemID, disableSave, disabledSaveTippy, section, isRestricted, applyRestrictions, onRemove} = this.props;
		const defaultActionButtons = (
			isEdit &&
			[
				itemID > 0 &&
				{
					key: "remove",
					preset: E_ButtonPresets.REMOVE,
					action: () => onRemove(),
					disabled: disableActionButtons,
				},
				{
					key: "save",
					icon: T_APP_ICONS.SAVE,
					tippy: <Translate id={"save"}/>,
					action: () => this.props.onSave(),
					disabled: disableActionButtons || disableSave,
					disabledTippy: disabledSaveTippy
				}
			]
			||
			[{
				key: "edit",
				icon: T_APP_ICONS.EDIT,
				tippy: <Translate id={"edit"}/>,
				action: () => this.props.onEdit(),
				disabled: disableActionButtons,
				restricted: isRestricted ? isRestricted(section) : T_RestrictionPresets.EDIT(section),
				applyRestrictions,
			}]
		);

		return (
			<header
				className={`toolbar ${className}`}
				style={style}
			>
				{
					backButton !== false && (backButton
					||
					<ButtonsConstructor buttons={
						isEdit &&
						itemID != 0 &&
						[{
							tippy: <Translate id={"cancel"}/>,
							icon: "arrow-left",
							className: "back-button",
							action: () => onEditCancel()
						}]
						||
						[backUrl && {
							tippy: <Translate id={"back"}/>,
							icon: "arrow-left",
							className: "back-button",
							href: backUrl
						}]
					}/>)
				}
				{content}
				{
					(Array.isArray(actionButtons) || is.function(actionButtons)) &&
					<ButtonsConstructor
						wrapInNav={true}
						className={"action-buttons"}
						buttons={
							is.valid(actionButtons) &&
							(Array.isArray(actionButtons) ? actionButtons : actionButtons(defaultActionButtons))
							||
							defaultActionButtons
						}
					/>
					||
					resolveDynamicComponent(actionButtons, null)
				}
			</header>
		)
	}

	static get propTypes() {
		return {
			...super.propTypes,
			isEdit: PropTypes.bool,
			onEditCancel: PropTypes.func,
			backButton: PropTypes.any,
			content: PropTypes.any,
			backUrl: PropTypes.string,
			actionButtons: PropTypes.any,
			disableActionButtons: PropTypes.bool,
			disableSave: PropTypes.bool,
			itemID: PropTypes.number,
			disabledSaveTippy: PropTypes.any,
			section: PropTypes.any,

			onEdit: PropTypes.func,
			onSave: PropTypes.func,
			onRemove: PropTypes.func,
			isRestricted: PropTypes.func,
			applyRestrictions: PropTypes.bool,
		}
	}

	static get defaultProps() {
		return {
			...super.defaultProps,
			actionButtons: [],
			disableSave: false,
			applyRestrictions: true,
		}
	}
}

export default Toolbar;
