import React, { Fragment } from "react";
import PropTypes from "prop-types";
import Fa from "../tools/Icons/Fa";
import { T_APP_ICONS } from "../../models/constants/Constants_Shared";
import NavLinkAlt from "./NavLinkAlt";
import { T_SharedPropTypes, T_SharedTippyProps } from "../../models/Models_Shared";
import withRestrictions from "./Restrictions/withRestrictions";
import Tippy from "./Tippy";
import { resolveDynamicComponent } from "../../utils/utils";

class ItemRedirect extends React.Component {
	render() {
		const {className, style, children, href, disabled, wrapChildren, icon} = this.props;

		let isRestricted = this._isRestricted();
		let isDisabled = disabled || isRestricted;

		if(isDisabled) {
			return children;
		}

		//The stupid wrapping between children and <NavLinkAlt> as well as children and <Fa> must be present to display space between children and redirect button
		return this._wrapWithTippy(
			<Fragment>
				{!wrapChildren && children} <NavLinkAlt
					className={`item-redirect ${className}`}
					style={style}
					onClick={e => e.stopPropagation()}
					to={href}
				>
					{wrapChildren && children} {this._wrapWithTippy(resolveDynamicComponent(icon, <Fa icon={icon}/>), !wrapChildren)}
				</NavLinkAlt>
			</Fragment>,
			wrapChildren
		)
	}

	_isRestricted() {
		const {restricted} = this.props;

		if(typeof restricted == "function") {
			return restricted();
		}
		return !this.props.hasAccess(restricted.role, restricted.permissions);
	}

	_wrapWithTippy(content, wrapChildren) {
		const {tippy} = this.props;
		if(tippy && wrapChildren) {
			return <Tippy {...T_SharedTippyProps} content={tippy}>
				<span>{content}</span>
			</Tippy>
		}

		return content;
	}

	static get propTypes() {
		return {
			...super.propTypes,
			...T_SharedPropTypes,
			href: PropTypes.string.isRequired,
			disabled: PropTypes.bool,
			wrapChildren: PropTypes.bool,
			restricted: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
			tippy: PropTypes.any,
			icon: PropTypes.any,
		}
	}

	static get defaultProps() {
		return {
			...super.defaultProps,
			disabled: false,
			wrapChildren: false,
			restricted: {},
			icon: T_APP_ICONS.REDIRECT,
		}
	}
}

export default withRestrictions(ItemRedirect);
