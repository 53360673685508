import React from "react";
import PropTypes from "prop-types";
import { Redirect } from "react-router-dom";
import CubeSpinner from "./LoadingIndicator/CubeSpinner";
import { resolveDynamicComponent, resolveErrorBlockContentByError } from "../../utils/utils";
import ErrorBlock from "./Error/ErrorBlock";
import { T_SharedPropTypes, T_SharedReduxPropTypes } from "../../models/Models_Shared";
import { connect } from "react-redux";

class DetailWrapper extends React.Component {
	render() {
		const {className, style, isEdit, itemID, listUrl, itemUrl, children, errorContent, dataID, state, selector, spinnerProps, errorBlockProps} = this.props;
		const {fetched, fetching, failed, deleted, error} = selector ? selector(state) : this.props;

		if(itemID !== undefined) {
			if(deleted == itemID) {
				return <Redirect to={listUrl} />
			}

			if(itemID == 0 && dataID > 0) {
				return <Redirect to={(itemUrl || listUrl) + '/' + dataID} />
			}
		}

		return (
			<section className={className} edit={`${isEdit}`} style={style}>
				{
					(fetched || itemID == 0) && children
				}
				{
					fetching && <CubeSpinner {...spinnerProps} />
				}
				{
					failed &&
					resolveDynamicComponent(
						errorContent,
						<ErrorBlock
							{...resolveErrorBlockContentByError(error, errorContent)}
							{...errorBlockProps}
						/>
					)
				}
			</section>
		);
	}

	static get propTypes() {
		return {
			...super.propTypes,
			...T_SharedPropTypes,
			...T_SharedReduxPropTypes,
			isEdit: PropTypes.bool,
			//ID value from router
			itemID: PropTypes.number,
			//ID of the displayed entity
			dataID: PropTypes.number,
			//Where to redirect if the content is deleted
			listUrl: PropTypes.string,
			//Optional handle if the item has some weird route that does not result from listUrl (e.g. listUrl = "/locations" and item = "/locations/:itemID")
			itemUrl: PropTypes.string,
			errorContent: PropTypes.any,
			selector: PropTypes.func,
			state: PropTypes.object,
			spinnerProps: PropTypes.object,
			errorBlockProps: PropTypes.object,
		}
	}

	static get stateTypes() {
		return {
			...super.stateTypes,
		}
	}

	static get defaultProps() {
		return {
			...super.defaultProps,
			state: {},
			spinnerProps: {},
			errorBlockProps: {},
			listUrl: '',
		}
	}
}

const mapStateToProps = state => {
	return {state};
};

export default connect(mapStateToProps)(DetailWrapper);
