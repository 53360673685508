import React from 'react';
import PropTypes from 'prop-types';
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {Thunk_Partners_Fetch_All} from "../../../store/thunk/Thunk_Partners";
import {resolveItemName} from "../../../utils/utils";
import CSBaseComponent from "../../CSBaseComponent";
import {NavLink} from 'react-router-dom';
import routes from '../../../routes/routes';
import ListingPage from "../../shared/Listing/ListingPage";
import {T_APP_ICONS} from "../../../models/constants/Constants_Shared";
import Fa from "../../tools/Icons/Fa";
import {Translate} from "react-localize-redux";
import {Selector_Partners_Listing_Root} from "../../../store/selectors/Selectors_Partners";
import TableListing from "../../shared/Listing/TableListing";
import TableRow from "../../shared/Listing/TableRow";
import TableColumn from "../../shared/Listing/TableColumn";

class PartnerList extends CSBaseComponent {
	constructor(props) {
		super(props);
	}

	_renderTemplate(partner, i, headerItems) {
		const businessDetails = partner.businessDetails || {};
		return (
			<TableRow key={i} href={`${routes.PARTNERS}/${partner.id}`} headerItems={headerItems}>
				<TableColumn column={"name"}>{resolveItemName(partner)}</TableColumn>
				<TableColumn column={"companyName"}>{businessDetails.companyName || '-'}</TableColumn>
				<TableColumn column={"country"}>{businessDetails.country || '-'}</TableColumn>
				<TableColumn column={"city"}>{businessDetails.city || '-'}</TableColumn>
			</TableRow>
		);
	}

	render() {
		const {Thunk_Partners_Fetch_All} = this.props;
		return (
			<section className={"partner-list"}>
				<ListingPage
					section={"partners"}
					titleIcon={T_APP_ICONS.PARTNERS}
					titleText={"partners"}
					titleLeftContent={
						<NavLink to={`${routes.PARTNERS}/0`} className={`list-item add`}>
							<Fa icon={T_APP_ICONS.ADD} />
							<label><Translate id={"add"} /></label>
						</NavLink>
					}
					subscriberFooter={true}
					subscriberThunk={Thunk_Partners_Fetch_All}
					subscriberRootSelector={Selector_Partners_Listing_Root}
					headerItems={{
						name:{},
						companyName: {
							field: "businessDetails.companyName",
						},
						country: {
							field: "businessDetails.country",
							label: "address_country"
						},
						city: {
							field: "businessDetails.city",
							label: "address_city"
						}
					}}
					views={[
						{
							view: "table",
							default: true,
							button: {
								icon: "table",
								tippy: <Translate id={"viewMode_table"} />
							},
							content:
								<TableListing className={"stripes"} itemTemplate={this._renderTemplate.bind(this)} />
						}
					]}
				/>
			</section>
		);
	}

	static get propTypes() {
		return {
			Thunk_Partners_Fetch_All: PropTypes.func.isRequired,
		}
	}
}

const mapDispatchToProps = dispatch => (
	bindActionCreators({
		Thunk_Partners_Fetch_All,
	}, dispatch)
);

export default connect(null, mapDispatchToProps)(PartnerList);
