import React from "react";
import {T_SharedDropzoneInputPropTypes} from "../DropzoneInput";
import {getFileIcon, getFileType} from "../../../../utils/dropzoneUtils";
import Fa from "../../../tools/Icons/Fa";
import ButtonsConstructor from "../../ButtonsConstructor";
import {E_ButtonPresets} from "../../../../models/Models_ButtonPresets";
import {T_APP_ICONS} from "../../../../models/constants/Constants_Shared";
import "../../../../styles/components/Dropzone/DropzoneFileContainer.scss";
import {get} from "../../../../utils/utils";
import {Translate} from "react-localize-redux";
import ProgressBar from "../../ProgressBar";

class DropzoneFileContainer extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			...this.state,

		};
	}

	_renderFilePreview(file, i) {
		file = file || {};
		const {onFileRemove, onFileErrorReUpload} = this.props;

		const type = getFileType(file);
		const icon = getFileIcon(type);
		let uploadData = file.data || {};
		let isUploading = !file.data.uploaded && !file.data.error;

		return (
			<div className={`file-preview ${file.data.error ? "failed" : ''}`} key={i}>
				{
					icon && <Fa icon={icon} />
				}
				<label>
					{
						// Unable to use get() or resolveItemName() because name is not own property of File... -_-
						file.blob instanceof File ? file.blob.name : get(file, `response.id`, <Translate id={"value_unknown"}/>, v => '#' + v)
					}
				</label>
				{
					isUploading &&
					<ProgressBar progress={file.data.progress} max={100} />
				}

				<ButtonsConstructor
					buttons={[
						!uploadData.error && {
							preset: E_ButtonPresets.REMOVE,
							icon: T_APP_ICONS.CLOSE,
							confirm: null,
							action: e => {
								e.stopPropagation();
								onFileRemove(file);
							},
						}
						||
						{
							icon: "sync",
							action: e => {
								e.stopPropagation();
								onFileErrorReUpload(file);
							}
						}
					]}
				/>
			</div>
		)
	}

	render() {
		const {className, style, files} = this.props;

		return (
			<section className={`dropzone-file-container ${className}`} style={style}>
				{(files || []).map(this._renderFilePreview.bind(this))}
			</section>
		)
	}

	static get propTypes() {
		return {
			...super.propTypes,
			...T_SharedDropzoneInputPropTypes,
		}
	}

	static get stateTypes() {
		return {
			...super.stateTypes,
		}
	}

	static get defaultProps() {
		return {
			...super.defaultProps,
		}
	}
}

export default DropzoneFileContainer;
