import React from 'react';
import PropTypes from 'prop-types';
import "../../styles/components/Modal.scss";
import { T_APP_ICONS } from "../../models/constants/Constants_Shared";
import { T_ModalOptionsModel } from "../../models/Models_Shared";
import CSBaseComponent from "../CSBaseComponent";
import ButtonsConstructor from "../shared/ButtonsConstructor";
import { Translate } from "react-localize-redux";
import is from "../../utils/is";

class Modal extends CSBaseComponent {
	constructor(props) {
		super(props);

		this.state = {
			...super.state,
			content: [],
			activeContentIndex: 0,
		}
	}

	componentDidMount() {
		window.modal = this;
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		const {content} = this.state;

		if(!this.state.isOpen && content.length >= 1) {
			this.shadow.classList.add("fadeIn");
			this.root.classList.add("fadeIn");
			this._onModalOpen();
		}

		if(this.state.isOpen && content.length == 0) {
			this.shadow.classList.add("fade-out");
			this.root.classList.remove("fadeIn");
			this.root.classList.add("fade-out");
			this._onModalClose();
		}

		if(content.length < prevState.content.length) {
			let lastItem = prevState.content.lastItem();
			lastItem.onCloseFallback && lastItem.onCloseFallback();
		}
	}

	render() {
		let {content, activeContentIndex} = this.state;
		let activeContent = content[activeContentIndex] || {};

		return (
			<div className={"cs-modal-container"} ref={this.setRef}>
				<div className={"overlay"} name={"shadow"} ref={this.setRef} onClick={() => this._onBackdropClick()} />
				<section className={`cs-modal ${activeContent.className || ''}`} style={activeContent.style}>
					<header>
						{activeContent.header}
						<ButtonsConstructor
							buttons={[
								...(activeContent.actionButtons || []),
								is.boolean(activeContent.closeButton) && (
									activeContent.closeButton &&
									{
										icon: T_APP_ICONS.CLOSE,
										tippy: <Translate id={"close"}/>,
										action: () => this.close()
									}
								)
								||
								activeContent.closeButton
							]}
							wrapInNav={true}
						/>
					</header>
					{
						content.map((item, i) => (
							<div key={i} style={activeContentIndex != i && {display: "none"} || {}}>
								{item.body}
							</div>
						))
					}
				</section>
			</div>
		)
	}

	/**
	 * Open
	 * ---
	 * Opens modal according the options
	 * @param options {T_ModalOptionsModel} Construct options
	 * @param onCloseFallback {Function}
	 */
	open(options, onCloseFallback = () => null) {
		this.setState(state => {
			let newContent = [...state.content];
			newContent.push({
				...T_ModalOptionsModel,
				...options,
				onCloseFallback
			});

			return {
				content: newContent,
				activeContentIndex: newContent.length - 1
			}
		});
	}

	close(removeAll = false) {
		this.setState(state => {
			if(removeAll) {
				return {
					content: [],
					activeContentIndex: 0,
				}
			}

			let newContent = [...state.content];
			newContent.splice(-1, 1);

			return {
				content: newContent,
				activeContentIndex: Math.max(0, newContent.length - 1)
			}
		});
	}

	modifyActiveContent(options) {
		this.setState(state => {
			let newContent = [...state.content];
			let activeContent = newContent.splice(state.activeContentIndex, 1)[0];

			return {
				content: [
					...newContent,
					{
						...activeContent,
						...options,
					},
				]
			}
		});
	}

	_onBackdropClick() {
		let {content, activeContentIndex} = this.state;
		let activeContent = content[activeContentIndex] || {};
		if (activeContent.closeOnBackdropClick) {
			this.close();
		}
	}

	_onModalOpen() {
		this.setState({isOpen: true});
		this.keyUpHandle = e => {
			switch (e.key) {
				case `Escape`:
					this.close();
			}
		};
		window.addEventListener("keyup", this.keyUpHandle);
		this.props.onModalOpen();
	}

	_onModalClose() {
		this.setState({isOpen: false});
		window.removeEventListener("keyup", this.keyUpHandle);
		this.props.onModalClose();
	}
}

Modal.propTypes = {
	onModalOpen: PropTypes.func,
	onModalClose: PropTypes.func,
};

Modal.stateTypes = {
	content: PropTypes.array,
	isOpen: PropTypes.bool,
	activeContentIndex: PropTypes.number,
};

Modal.defaultProps = {
	onModalOpen: () => null,
	onModalClose: () => null,
};

export default Modal;
