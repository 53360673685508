import React from 'react'
// eslint-disable-next-line no-unused-vars
import InputBase from "./Base/InputBase";
import ItemSelectorInput from "./ItemSelectorInput";

class RadioInput extends InputBase {
	constructor(props) {
		super(props);

		this.state = {
			...this.state,
		}
	}

	render() {
		return this.renderInputWrapper(
			<ItemSelectorInput {...this.props} className={''} style={undefined}/>
		);
	}

	static get propTypes() {
		return {
			...super.propTypes,
		}
	}

	static get stateTypes() {
		return {
			...super.stateTypes,
		}
	}

	static get defaultProps() {
		return {
			...super.defaultProps,
			wrapInput: true,
			internalClassName: "radio-input"
		}
	}
}

export default RadioInput;
