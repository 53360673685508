import { EO } from "../../utils/extensions";
import { GENERIC_EDIT_CANCEL, GENERIC_EDIT_FULFILLED, GENERIC_EDIT_INIT, GENERIC_EDIT_MODIFY, GENERIC_EDIT_PENDING, GENERIC_EDIT_REJECTED, GENERIC_FETCH_FULFILLED, GENERIC_FETCH_PENDING, GENERIC_FETCH_REJECTED, GENERIC_ITEM_CLEAR } from "../actions/genericActions";
import { get } from "../../utils/utils";
import { handleGenericModificationFulfilled } from "../../utils/storeUtils";

const initialState = {
	data: {},
	edit: {},
	fetching: false,
	fetched: false,
	failed: false,
	error: {},
	deleted: null
};

function handleGenericModify(state, action, editSelector, normalizationFunction) {
	let newEditData = EO(editSelector(normalizationFunction(state))).clone(true);
	newEditData.modify(action.modificationType, action.path, action.modification);

	return {
		...state,
		edit: newEditData,
	};
}

export default (key, additionalReducer = null, editSelector = () => null, normalizationFunction = state => state) => {
	return (state = initialState, action) => {
		switch (action.type) {
			case `${key}_${GENERIC_FETCH_PENDING}`:
				return {
					...state,
					data: {},
					edit: {},
					fetching: true,
					fetched: false,
					failed: false,
					error: {},
				};
			case `${key}_${GENERIC_FETCH_FULFILLED}`:
				return {
					...state,
					data: action.payload,
					edit: {},
					fetching: false,
					fetched: true,
					failed: false
				};
			case `${key}_${GENERIC_FETCH_REJECTED}`:
				return {
					...state,
					data: {},
					edit: {},
					fetching: false,
					fetched: false,
					failed: true,
					error: action.error,
				};
			case `${key}_${GENERIC_EDIT_INIT}`:
				return {
					...state,
					fetched: true,
					fetching: false,
					failed: false,
					edit: action.payload || EO(state.data).clone(true),
				};
			case `${key}_${GENERIC_EDIT_CANCEL}`:
				return {
					...state,
					edit: {}
				};
			case `${key}_${GENERIC_EDIT_MODIFY}`:
				return handleGenericModify(state, action, editSelector, normalizationFunction);
			case `${key}_${GENERIC_EDIT_PENDING}`:
				return {
					...state,
					fetching: true,
					error: {},
				};
			case `${key}_${GENERIC_EDIT_FULFILLED}`:
				return handleGenericModificationFulfilled(
					state,
					action,
					{
						deleted: get(action, `payload.id`),
						fetching: false,
						data: {},
						edit: {}
					},
					{
						...state,
						fetching: false,
						data: EO(action.payload || action.modification).clone(true),
						edit: {}
					}
				)
			case `${key}_${GENERIC_EDIT_REJECTED}`:
				return {
					...state,
					fetching: false,
					error: action.error,
				};
			case `${key}_${GENERIC_ITEM_CLEAR}`:
				return {
					...initialState,
				};
			default:
				return additionalReducer && additionalReducer(state, action) || state;
		}
	}
}
