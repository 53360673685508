import { errorHandler } from "../ResponseHandling";

export const GENERIC_FETCH_PENDING = "FETCH_PENDING";
export const GENERIC_FETCH_FULFILLED = "FETCH_FULFILLED";
export const GENERIC_FETCH_REJECTED = "FETCH_REJECTED";

export const GENERIC_EDIT_INIT = "EDIT_INIT";
export const GENERIC_EDIT_CANCEL = "EDIT_CANCEL";
export const GENERIC_EDIT_MODIFY = "EDIT_MODIFY";
export const GENERIC_EDIT_PENDING = "EDIT_PENDING";
export const GENERIC_EDIT_FULFILLED = "EDIT_FULFILLED";
export const GENERIC_EDIT_REJECTED = "EDIT_REJECTED";

export const GENERIC_ITEM_CLEAR = "ITEM_CLEAR";


export const Action_Generic_Type = (type) => {
	return {
		type
	}
};

export const Action_Generic_Payload = (type, payload, customProps = {}) => {
	return {
		type,
		payload,
		customProps
	}
};

export const Action_Generic_Edit_Modify = (type, modificationType, path, modification, customProps = {}) => {
	return {
		type,
		modificationType,
		path,
		modification,
		customProps,
	};
};

export const Action_Generic_Fail = (type, error, customProps = {}) => {
	error.actionType = type;
	errorHandler(error, type, (customProps || {}));
	return {
		type,
		error,
		customProps
	}
};
