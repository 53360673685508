export const E_ToastStyle = {
	DEFAULT: "DEFAULT",
	INFO: "INFO",
	SUCCESS: "SUCCESS",
	WARNING: "WARNING",
	ERROR: "ERROR"
};

export const Toast_InternalServerError = {
	content: "genericError_internalServerError",
	icon: "bug",
	style: E_ToastStyle.ERROR
};