import React from "react";
import PropTypes from "prop-types";
import "../../styles/components/Gallery.scss";
import Fa from "../tools/Icons/Fa";
import ButtonsConstructor from "./ButtonsConstructor";
import { T_APP_ICONS } from "../../models/constants/Constants_Shared";
import is from "../../utils/is";
import ServerImage from "./Image/ServerImage";

class Gallery extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			...super.state,
			activeImage: null,
		};
	}

	render() {
		const {className, images} = this.props;
		const {activeImage} = this.state;

		return (
			<section className={`gallery ${className}`}>
				<div className={"thumbnails"}>
					{
						(images || []).map((img, i) => (
							img &&
							<div key={i} className={"thumbnail"}>
								<ServerImage key={i} imageID={img.id} thumbnail={true} />
								<button onClick={() => this.setState({activeImage: img})}>
									<Fa icon={"search-plus"} />
								</button>
							</div>
						))
					}
				</div>
				{
					is.valid(activeImage) &&
					<div className={"gallery-modal"}>
						<ButtonsConstructor className={"action-buttons"} wrapInNav={true} buttons={[{
							icon: T_APP_ICONS.CLOSE,
							action: () => this.setState({activeImage: null})
						}]}/>

						<div className={"content"}>
							<label className={"active-index"}>{(images || []).findIndex(img => img.id == activeImage.id) + 1} / {images.length}</label>
							<div className={"active-image"}>
								<ServerImage imageID={(activeImage || {}).id} />
								<ButtonsConstructor wrapInNav={true} buttons={[
									{icon: "chevron-left", action: () => this._changeImage(-1)},
									{icon: "chevron-right",action: () => this._changeImage(1)}
								]}/>
							</div>
							<ButtonsConstructor className={"thumbnails"} buttons={(images || []).map(img => ({
								text: <ServerImage imageID={img.id} thumbnail={true} />,
								action: () => this.setState({activeImage: img}),
								active: activeImage.id == img.id
							}))}/>
						</div>
					</div>
				}
			</section>
		)
	}

	_changeImage(direction) {
		const {images} = this.props;
		const {activeImage} = this.state;
		const currentIndex = (images || []).findIndex(img => img.id == activeImage.id);
		let targetIndex = currentIndex + direction;
		if(targetIndex < 0) {
			targetIndex = images.length - 1;
		}

		this.setState({activeImage: images[targetIndex % images.length]})
	}

	static get propTypes() {
		return {
			...super.propTypes,
			images: PropTypes.array,
		}
	}

	static get stateTypes() {
		return {
			...super.stateTypes,
			activeImage: PropTypes.object
		}
	}

	static get defaultProps() {
		return {
			...super.defaultProps,
		}
	}
}

export default Gallery;
