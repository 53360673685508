import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { Translate } from "react-localize-redux";
import NumberInput from "../../../../../shared/Input/NumberInput";
import { E_ReservationRestrictionType, T_ServiceConfiguration, T_ServiceReservationRestriction } from "../../../../../../models/Models_StorageService";
import { E_WeekDays, getLocalizedWeekDay } from "../../../../../../utils/localeUtils";
import Dropdown from "../../../../../shared/Input/Dropdown";
import TimeInput from "../../../../../shared/Input/TimeInput";
import ButtonsConstructor from "../../../../../shared/ButtonsConstructor";
import { E_Modification } from "../../../../../../store/Constants_StoreShared";
import { connect } from "react-redux";
import { Selector_Service_Edit } from "../../../../../../store/selectors/Selectors_LocationService";
import { get } from "../../../../../../utils/utils";
import "../../../../../../styles/pages/Location/StorageUnit/ServiceReservationRestrictionsModal.scss";

class ServiceReservationRestrictionsModal extends React.Component {
	_renderTimeRestrictions(restrictions) {
		const {path: configurationPath, onModify} = this.props;
		const basePath = `${configurationPath}.reservationRestrictions`;

		return restrictions.map((restriction, i) => {
			const path = `${basePath}.${i}`;

			return (
				<Fragment key={i}>
					<div className={"restriction"}>
						<Dropdown
							options={Object.values(E_WeekDays)}
							path={`${path}.weekDay`}
							onModify={onModify}
							defaultValue={restriction.weekDay}
							onLabel={(v) => getLocalizedWeekDay(v, "long")}
						/>

						<div className={"period"}>
							<TimeInput
								path={`${path}.fromTime`}
								onModify={onModify}
								defaultValue={restriction.fromTime}
							/>

							&nbsp;-&nbsp;

							<TimeInput
								path={`${path}.toTime`}
								onModify={onModify}
								defaultValue={restriction.toTime}
							/>
						</div>

						<Translate>
							{({translate}) => (
								<Dropdown
									options={Object.values(E_ReservationRestrictionType)}
									path={`${path}.type`}
									onModify={onModify}
									defaultValue={restriction.type}
									onLabel={v => translate(`reservationRestrictionType_${v}`)}
								/>
							)}
						</Translate>

						<ButtonsConstructor buttons={[
							{
								icon: "times",
								text: "remove",
								className: "fancy remove-button",
								action: () => this._removeRestriction(i)
							}
						]}/>
					</div>
				</Fragment>
			)
		})
	}

	render() {
		const {edit, path, onModify} = this.props;
		const configuration = edit.serviceConfiguration || T_ServiceConfiguration;

		return (
			<section className={"service-reservation-restrictions"}>
				<div className={`grid-table grid-icon-auto-1fr`}>
					<span/>
					<label><Translate id={"serviceConfiguration_reservationBlockMinutes"}/></label>
					<NumberInput
						defaultValue={configuration.reservationBlockMinutes || ''}
						path={`${path}.reservationBlockMinutes`}
						onModify={onModify}
						suffix={
							<span><Translate id={"minutes"}/></span>}
						wrapInput={true}
						behavior={{clamp: {min: 0}}}
					/>
				</div>

				<h2>
					<Translate id={"timeRestrictions"}/>
				</h2>

				<div className={"restrictions"}>
					{this._renderTimeRestrictions(get(configuration, "reservationRestrictions", []))}

					<ButtonsConstructor buttons={[
						{
							icon: "plus",
							text: "add",
							className: "add-button fancy full-span",
							action: () => this._addRestriction()
						}
					]}/>
				</div>
			</section>
		)
	}

	_addRestriction() {
		const {onModify, path} = this.props;

		onModify(E_Modification.ARRAY_PUSH, `${path}.reservationRestrictions`, T_ServiceReservationRestriction);
	}

	_removeRestriction(index) {
		const {onModify, path} = this.props;

		onModify(E_Modification.ARRAY_SPLICE, `${path}.reservationRestrictions`, [index, 1]);
	}

	static get propTypes() {
		return {
			edit: PropTypes.object,
			path: PropTypes.string.isRequired,

			onModify: PropTypes.func.isRequired,
		}
	}
}

const mapStateToProps = state => {
	return {
		edit: Selector_Service_Edit(state)
	};
};

export default connect(mapStateToProps)(ServiceReservationRestrictionsModal);
