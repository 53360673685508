import React from "react";
import PropTypes from "prop-types";
import { T_SharedPropTypes } from "../../models/Models_Shared";
import { NavLink } from "react-router-dom";
import RouterListener from "./RouterListener";
import CSBaseComponent from "../CSBaseComponent";

class NavLinkAlt extends CSBaseComponent {
	constructor(props) {
		super(props);

		this.state = {
			...this.state,
			isActive: this._computeIsActive(),
		};

		this._handleUpdateHistory = this._handleUpdateHistory.bind(this);
	}

	componentDidMount() {
		this.setState({
			isActive: this._computeIsActive()
		});
	}

	componentWillUnmount() {
		//Disable listener
		this._listener && this._listener();
	}

	render() {
		const {children, to, className, style, activeClassName, disabled, activeStyle} = this.props;
		const {isActive} = this.state;

		let aProps = {
			...this.props,
			className: `${className || ''} ${isActive && activeClassName}`,
			style: {...style, ...activeStyle},
		};
		[
			"to",
			"exact",
			"strict",
			"activeClassName",
			"activeStyle",
			"isActive",
			"aria-current",
			"onClick"
		].forEach(key => delete aProps[key]);

		if(disabled) {
			return <a
				{...aProps}
			>
				{children}
				<RouterListener onHistoryChange={this._registerListener.bind(this)}/>
			</a>
		}

		//this.setRef must be used to allow tippy system to retrieve reference
		return (
			<a
				ref={this.setRef}
				{...aProps}
				href={to}
				onClick={this._handleOnClick.bind(this)}
			>
				{children}
				<RouterListener onHistoryChange={this._registerListener.bind(this)}/>
			</a>
		)
	}

	_handleUpdateHistory() {
		this.setState({
			isActive: this._computeIsActive()
		})
	}

	_registerListener(history) {
		this.history = history;
		this._listener = history.listen(this._handleUpdateHistory);
	}

	/**
	 * @private
	 * Handle onClick
	 * ---
	 * Handles on anchor click to emulate same behavior as basic anchor but without whole page reload
	 * @param {MouseEvent} event
	 * @see Link
	 */
	_handleOnClick(event) {
		const {onClick, replace, to} = this.props;
		if(onClick) onClick(event);

		if(
			!event.defaultPrevented && // onClick prevented default
			event.button === 0 && // ignore everything but left clicks
			!this.props.target && // let browser handle "target=_blank" etc.
			!this._isModifiedEvent(event) // ignore clicks with modifier keys
		) {
			event.preventDefault();

			if(replace) {
				this.history.replace(to);
			}
			else {
				this.history.push(to);
			}
		}
	}

	_isModifiedEvent(event) {
		return !!(event.metaKey || event.altKey || event.ctrlKey || event.shiftKey);
	}

	_computeIsActive() {
		const {isActive} = this.props;
		let match = this._computeMatch(window.location.pathname)
		return (typeof isActive === "function" ? (isActive ? isActive(match, window.location) : null) : isActive) || match;
	}

	_computeMatch(pathname) {
		const {to, exact} = this.props;
		return exact ? pathname == to : pathname.includes(to);
	}

	static get propTypes() {
		return {
			...super.propTypes,
			...T_SharedPropTypes,
			to: PropTypes.string,
			//Used in isActive detection
			exact: PropTypes.bool,
			strict: PropTypes.bool,
			location: PropTypes.object,
			activeClassName: PropTypes.string,
			activeStyle: PropTypes.object,
			isActive: PropTypes.func,
			"aria-current": PropTypes.oneOf(["page", "step", "location", "date", "time", "true"]),
			disabled: PropTypes.bool,
		}
	}

	static get stateTypes() {
		return {
			...super.stateTypes,
			isActive: PropTypes.bool
		}
	}

	static get defaultProps() {
		return {
			...super.defaultProps,
			...NavLink.defaultProps,
			activeClassName: "active",
		}
	}
}

export default NavLinkAlt;
