import { service_deleteVoucher, service_fetchVoucher, service_fetchVouchers, service_insertVoucher, service_updateVoucher } from "../../services/Service_Vouchers";
import { Action_Voucher_Edit_Fulfilled, Action_Voucher_Edit_Pending, Action_Voucher_Edit_Rejected, Action_Voucher_Fetch_Fulfilled, Action_Voucher_Fetch_Pending, Action_Voucher_Fetch_Rejected, Action_Vouchers_Fetch_Fulfilled, Action_Vouchers_Fetch_Pending, Action_Vouchers_Fetch_Rejected } from "../actions/vouchers/vouchersActions";
import { Selector_Voucher_Edit } from "../selectors/Selectors_Vouchers";
import { Thunk_Generic_Edit_Insert, Thunk_Generic_Edit_Save, Thunk_Generic_Edit_Update, Thunk_Generic_Fetch_All, Thunk_Generic_Fetch_Item, Thunk_Generic_Remove } from "./Thunk_Generic";

export const Thunk_Vouchers_Fetch_All = queryString => (
	Thunk_Generic_Fetch_All(queryString, service_fetchVouchers, Action_Vouchers_Fetch_Pending, Action_Vouchers_Fetch_Fulfilled, Action_Vouchers_Fetch_Rejected)
);

export const Thunk_Voucher_Fetch = itemID => (
	Thunk_Generic_Fetch_Item(itemID, service_fetchVoucher, Action_Voucher_Fetch_Pending, Action_Voucher_Fetch_Fulfilled, Action_Voucher_Fetch_Rejected)
);

export const Thunk_Voucher_Insert = () => (
	Thunk_Generic_Edit_Insert(service_insertVoucher, Action_Voucher_Edit_Pending, Action_Voucher_Edit_Fulfilled, Action_Voucher_Edit_Rejected, Selector_Voucher_Edit)
);

export const Thunk_Voucher_Update = () => (
	Thunk_Generic_Edit_Update(service_updateVoucher, Action_Voucher_Edit_Pending, Action_Voucher_Edit_Fulfilled, Action_Voucher_Edit_Rejected, Selector_Voucher_Edit)
);

export const Thunk_Voucher_Save = () => (
	Thunk_Generic_Edit_Save(Selector_Voucher_Edit, Action_Voucher_Edit_Pending, Thunk_Voucher_Insert, Thunk_Voucher_Update)
);

export const Thunk_Voucher_Remove = voucherID => (
	Thunk_Generic_Remove(voucherID, service_deleteVoucher, Action_Voucher_Edit_Pending, Action_Voucher_Edit_Fulfilled, Action_Voucher_Edit_Rejected)
);
