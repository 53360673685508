import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { Translate } from "react-localize-redux";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import { T_SharedReduxPropTypes } from "../../../models/Models_Shared";
import { E_ControlType, T_DimensionsDetail, T_ServiceType } from "../../../models/Models_StorageService";
import routes from "../../../routes/routes";
import { Action_ServiceType_Clear, Action_ServiceType_Edit_Cancel, Action_ServiceType_Edit_Init, Action_ServiceType_Edit_Modify } from "../../../store/actions/configuration/serviceTypesActions";
import { Selector_Generic_List_Data } from "../../../store/selectors/Selectors_Generic";
import { Selector_ServiceCategories_Root } from "../../../store/selectors/Selectors_ServiceCategories";
import { Selector_ServiceType_Data, Selector_ServiceType_Edit, Selector_ServiceType_Root } from "../../../store/selectors/Selectors_ServiceType";
import { Thunk_ServiceCategories_Fetch_All } from "../../../store/thunk/Thunk_ServiceCategories";
import { Thunk_ServiceType_Fetch, Thunk_ServiceType_Remove, Thunk_ServiceType_Save } from "../../../store/thunk/Thunk_ServiceTypes";
import { EO } from "../../../utils/extensions";
import is from "../../../utils/is";
import { TranslateEntry } from "../../../utils/localeUtils";
import { get, resolveItemName } from "../../../utils/utils";
import Dropdown from "../../shared/Input/Dropdown";
import TextareaInput from "../../shared/Input/TextareaInput";
import Fa from "../../tools/Icons/Fa";
import LanguageSwitch from "../../tools/Language/LanguageSwitch";
import MorphElement from "../../tools/MorphElement";
import Toolbar from "../../shared/Toolbar";
import DetailWrapper from "../../shared/DetailWrapper";
import ItemRedirect from "../../shared/ItemRedirect";
import { T_RestrictionPresets } from "../../../models/Models_Restrictions";
import Restricted from "../../shared/Restrictions/Restricted";
import TariffSelectInput from "../Tariffs/TariffSelectInput";
import NumberInput from "../../shared/Input/NumberInput";
import '../../../styles/pages/Configuration/ServiceTypeDetail.scss';

class ServiceTypeDetail extends React.Component {
	constructor(props) {
		super(props);

		this.state = {};
	}

	componentDidMount() {
		const {serviceTypeID, Thunk_ServiceType_Fetch, Action_ServiceType_Edit_Init} = this.props;

		if (serviceTypeID) {
			Thunk_ServiceType_Fetch(serviceTypeID);
		} else {
			Action_ServiceType_Edit_Init(T_ServiceType);
		}
	}

	componentWillUnmount() {
		const {Action_ServiceType_Clear} = this.props;

		Action_ServiceType_Clear();
	}

	_renderToolbar() {
		const {edit, Action_ServiceType_Edit_Cancel, serviceTypeID, fetching, failed, Thunk_ServiceType_Remove, Action_ServiceType_Edit_Init, Thunk_ServiceType_Save} = this.props;
		const isEdit = is.valid(edit);

		return <Toolbar
			section={"globalConfiguration"}
			isEdit={isEdit}
			backUrl={routes.SERVICE_TYPES}
			content={<h1><Translate id={serviceTypeID ? (isEdit ? "serviceType_edit" : "serviceType_detail") : "serviceType_create"}/></h1>}
			onEditCancel={() => Action_ServiceType_Edit_Cancel()}
			onEdit={() => Action_ServiceType_Edit_Init()}
			onSave={() => Thunk_ServiceType_Save()}
			onRemove={() => Thunk_ServiceType_Remove(serviceTypeID)}
			disableActionButtons={fetching || failed}
			itemID={serviceTypeID}
		/>;
	}

	_renderDimensionsSection(dimensionsDetail) {
		const {edit, Action_ServiceType_Edit_Modify} = this.props;
		const isEdit = is.valid(edit);

		return (
			<div className={"dimensions"}>
				<div className={"separator"}><label><Fa icon={"ruler-combined"} /><Translate id={"serviceType_dimensions"} /></label></div>
				{
					this._isEditSwitch(
						<div className={"grid-table grid-auto-1fr"}>
							<label>X</label>
							<span>{dimensionsDetail.x} mm</span>
							<label>Y</label>
							<span>{dimensionsDetail.y} mm</span>
							<label>Z</label>
							<span>{dimensionsDetail.z} mm</span>
						</div>,
						<div className={"dimensions-inputs-wrapper grid-table grid-auto-1fr"}>
							<label>X</label>
							<NumberInput
								onModify={Action_ServiceType_Edit_Modify}
								defaultValue={dimensionsDetail.x}
								path={`dimensionsDetail.x`}
								suffix={"mm"}
								wrapInput={true}
							/>
							<label>Y</label>
							<NumberInput
								onModify={Action_ServiceType_Edit_Modify}
								defaultValue={dimensionsDetail.y}
								path={`dimensionsDetail.y`}
								suffix={"mm"}
								wrapInput={true}
							/>
							<label>Z</label>
							<NumberInput
								onModify={Action_ServiceType_Edit_Modify}
								defaultValue={dimensionsDetail.z}
								path={`dimensionsDetail.z`}
								suffix={"mm"}
								wrapInput={true}
							/>
						</div>
					)
				}
				<MorphElement
					label={<h4><Fa icon={"pen-alt"} /><Translate id="verbalDescription" /></h4>}
					condition={!isEdit}
					pass={
						<TranslateEntry entry={dimensionsDetail.dimensionsText} showAll={true} />
					}
					fail={
						<LanguageSwitch
							path={"dimensionsDetail.dimensionsText"}
							value={dimensionsDetail.dimensionsText}
							onModify={Action_ServiceType_Edit_Modify}
						>
							<TextareaInput />
						</LanguageSwitch>
					}
				/>
			</div>
		)
	}

	_renderServiceTypeData(serviceType) {
		const {edit, Action_ServiceType_Edit_Modify, Thunk_ServiceCategories_Fetch_All} = this.props;
		const isEdit = is.valid(edit);

		return (
			<Fragment>
				<div className={"grid-table grid-icon-auto-1fr"}>
					<span />
					<label><Translate id={"serviceType_controlType"} /></label>
					{
						this._isEditSwitch(
							<span><Translate id={`serviceType_controlType_${serviceType.controlType}`} /></span>,
							<Translate>
								{({translate}) => (
									<Dropdown
										defaultValue={serviceType.controlType}
										options={EO(E_ControlType).toArray(true)}
										onLabel={item => translate(`serviceType_controlType_${item}`)}
										onModify={Action_ServiceType_Edit_Modify}
										path={`controlType`}
									/>
								)}
							</Translate>
						)
					}

					<Restricted
						restricted={T_RestrictionPresets.TARIFFS}
						applyRestrictions={isEdit}
					>
						<span />
						<label><Translate id={"tariff"} /></label>
						{
							this._isEditSwitch(
								<span>
									<ItemRedirect
										href={`${routes.TARIFFS}/${get(serviceType, `tariff.id`)}`}
										restricted={T_RestrictionPresets.TARIFFS}
										wrapChildren={true}
									>
										{resolveItemName(serviceType.tariff, undefined, undefined, <Translate id={"value_notProvided"} />)}
									</ItemRedirect>
								</span>,
								<TariffSelectInput
									onModify={Action_ServiceType_Edit_Modify}
									path={`tariff`}
									defaultValue={serviceType.tariff}
								/>
							)
						}
					</Restricted>

					<span />
					<label><Translate id={"serviceCategory"} /></label>
					{
						this._isEditSwitch(
							<span>
								<ItemRedirect
									href={`${routes.SERVICE_CATEGORIES}/${get(serviceType, `serviceCategory.id`)}`}
									wrapChildren={true}
								>
									{resolveItemName(serviceType.serviceCategory, undefined, undefined, <Translate id={"value_notProvided"} />)}
								</ItemRedirect>
							</span>,
							<Dropdown
								onModify={Action_ServiceType_Edit_Modify}
								path={`serviceCategory`}
								defaultValue={serviceType.serviceCategory}
								onLabel={serviceCategory => resolveItemName(serviceCategory)}
								onValue={value => ({id: value.id, name: value.name})}
								thunk={() => Thunk_ServiceCategories_Fetch_All}
								selector={state => Selector_Generic_List_Data(Selector_ServiceCategories_Root, state)}
							/>
						)
					}
				</div>

				{
					this._renderDimensionsSection(get(serviceType, "dimensionsDetail", T_DimensionsDetail))
				}

				<div className={"separator"}><label><Fa icon={"tags"} /><Translate id={"description"} /></label></div>

				<div>
					<MorphElement
						label={<h4><Fa icon={"tags"} /><Translate id="titles" /></h4>}
						condition={!isEdit}
						pass={
							<TranslateEntry entry={serviceType.titles} showAll={true} />
						}
						fail={
							<LanguageSwitch
								path={"titles"}
								value={serviceType.titles}
								onModify={Action_ServiceType_Edit_Modify}
							>
								<TextareaInput />
							</LanguageSwitch>
						}
					/>
				</div>

				<div>
					<MorphElement
						label={<h4><Fa icon={"tags"} /><Translate id="subtitles" /></h4>}
						condition={!isEdit}
						pass={
							<TranslateEntry entry={serviceType.subtitles} showAll={true} />
						}
						fail={
							<LanguageSwitch
								path={"subtitles"}
								value={serviceType.subtitles}
								onModify={Action_ServiceType_Edit_Modify}
							>
								<TextareaInput />
							</LanguageSwitch>
						}
					/>
				</div>

				<div>
					<MorphElement
						label={<h4><Fa icon={"pen-fancy"} /><Translate id="description" /></h4>}
						condition={!isEdit}
						pass={
							<TranslateEntry entry={serviceType.descriptions} showAll={true} />
						}
						fail={
							<LanguageSwitch
								path={"descriptions"}
								value={serviceType.descriptions}
								onModify={Action_ServiceType_Edit_Modify}
							>
								<TextareaInput />
							</LanguageSwitch>
						}
					/>
				</div>
			</Fragment>
		)
	}

	_renderServiceType(serviceType) {
		return (
			<Fragment>
				{this._renderToolbar()}
				<div className={"service-type content-wrapper"}>
					{this._renderServiceTypeData(serviceType)}
				</div>
			</Fragment>
		)
	}

	render() {
		const {serviceType, edit, serviceTypeID} = this.props;

		return (
			<DetailWrapper
				itemID={serviceTypeID}
				dataID={(serviceType || {}).id}
				className={"service-type-detail"}
				isEdit={is.valid(edit)}
				listUrl={routes.SERVICE_TYPES}
				errorContent={"serviceType_fetch_failed"}
				selector={Selector_ServiceType_Root}
			>
				{this._renderServiceType((is.valid(edit) ? edit : serviceType) || T_ServiceType)}
			</DetailWrapper>
		);
	}

	_isEditSwitch(normal, edit) {
		return is.valid(this.props.edit) ? edit : normal;
	}
}

ServiceTypeDetail.propTypes = {
	...T_SharedReduxPropTypes,

	serviceTypeID: PropTypes.number,
	serviceType: PropTypes.object,
	edit: PropTypes.object,

	Thunk_ServiceType_Fetch: PropTypes.func.isRequired,
	Action_ServiceType_Edit_Cancel: PropTypes.func.isRequired,
	Action_ServiceType_Edit_Init: PropTypes.func.isRequired,
	Action_ServiceType_Edit_Modify: PropTypes.func.isRequired,
	Thunk_ServiceCategories_Fetch_All: PropTypes.func.isRequired,
	Thunk_ServiceType_Save: PropTypes.func.isRequired,
};

ServiceTypeDetail.stateTypes = {};

ServiceTypeDetail.defaultProps = {
	serviceType: T_ServiceType,
};

const mapStateToProps = state => {
	const {fetched, fetching, failed} = Selector_ServiceType_Root(state);
	const serviceType = Selector_ServiceType_Data(state);
	const edit = Selector_ServiceType_Edit(state);

	return {fetched, fetching, failed, serviceType, edit}
};

const mapDispatchToProps = dispatch => (
	bindActionCreators({
		Thunk_ServiceType_Fetch,
		Action_ServiceType_Edit_Cancel,
		Action_ServiceType_Edit_Init,
		Action_ServiceType_Edit_Modify,
		Thunk_ServiceCategories_Fetch_All,
		Thunk_ServiceType_Save,
		Thunk_ServiceType_Remove,
		Action_ServiceType_Clear
	}, dispatch)
);

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ServiceTypeDetail));
