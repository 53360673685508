// Return generic communication units object
import { Selector_Locations_Root } from "./Selectors_Location";

export function Selector_CommunicationUnits_Root(state) {
	return Selector_Locations_Root(state).communicationUnits;
}

export function Selector_CommunicationUnits_Listing_Root(state) {
	return Selector_CommunicationUnits_Root(state).listing;
}

export function Selector_CommunicationUnits_Listing_Data(state) {
	return Selector_CommunicationUnits_Listing_Root(state).data;
}

export function Selector_CommunicationUnit_Edit(state) {
	return Selector_CommunicationUnits_Root(state).item.edit;
}

export function Selector_CommunicationUnits_Listing_Reducer(state) {
	return state.listing;
}

export function Selector_CommunicationUnits_Edit_Reducer(state) {
	return state.edit;
}
