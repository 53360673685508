import React from 'react';
import { Switch, Route } from 'react-router-dom';
import ExternalLocationDetail from "./ExternalLocationDetail";
import ExternalLocationsList from "./ExternalLocationsList";
import routes from '../../../../routes/routes';

class ExternalLocationsBrowser extends React.Component {

	constructor(props) {
		super(props);
	}

	render() {
		return (
			<Switch>
				<Route exact path={routes.EXTERNAL_LOCATIONS}>
					<ExternalLocationsList />
				</Route>
				<Route path={`${routes.EXTERNAL_LOCATIONS}/:locationID/:unitID?`} render={props => <ExternalLocationDetail locationID={parseInt(props.match.params.locationID)} />} />
			</Switch>
		);
	}
}

export default ExternalLocationsBrowser;
