import React from 'react';
import { Switch, Route } from 'react-router-dom';
import routes from '../../../routes/routes';
import ServiceCategoryDetail from "./ServiceCategoryDetail";
import ServiceCategoriesList from "./ServiceCategoriesList";

class ServiceCategoriesBrowser extends React.Component {

	constructor(props) {
		super(props);
	}

	render() {
		return (
			<Switch>
				<Route exact path={routes.SERVICE_CATEGORIES}>
					<ServiceCategoriesList />
				</Route>
				<Route path={`${routes.SERVICE_CATEGORIES}/:serviceCategoryID`} render={props => <ServiceCategoryDetail serviceCategoryID={parseInt(props.match.params.serviceCategoryID)} />} />
			</Switch>
		);
	}
}

export default ServiceCategoriesBrowser;
