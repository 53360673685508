import React, {Fragment} from "react";
import PropTypes from "prop-types";
import TextInput from "../../shared/Input/TextInput";
import {EO} from "../../../utils/extensions";
import {E_DeliveryType, T_DeliverySessionRequestModel} from "../../../models/Models_Session";
import TextareaInput from "../../shared/Input/TextareaInput";
import {css, get, resolveItemName} from "../../../utils/utils";
import is from "../../../utils/is";
import Fa from "../../tools/Icons/Fa";
import {E_Currency, T_APP_ICONS} from "../../../models/constants/Constants_Shared";
import {Translate} from "react-localize-redux";
import NumberInput from "../../shared/Input/NumberInput";
import Dropdown from "../../shared/Input/Dropdown";
import {service_createNewDelivery} from "../../../services/Service_Sessions";
import routes from "../../../routes/routes";
import {withRouter} from "react-router-dom";
import ButtonsConstructor from "../../shared/ButtonsConstructor";
import "../../../styles/pages/Session/NewDeliveryModal.scss";
import DropdownLanguage from "../../shared/Input/Dropdowns/DropdownLanguage";
import UserSelect from "../Users/UserSelect";
import {errorHandler} from "../../../store/ResponseHandling";
import {SESSION_CREATE_REJECTED} from "../../../store/actions/sessions/sessionsActions";
import {E_Modification} from "../../../store/Constants_StoreShared";
import CubeSpinner from "../../shared/LoadingIndicator/CubeSpinner";
import {PIN_MIN_LENGTH, PinInput} from "../../shared/Input/PinInput";
import {E_ToastStyle} from "../../../models/Models_Toast";
import {E_UserInfo_UserType} from "../../../models/Models_User";

const E_Sections = {
	COURIER_DETAILS: "COURIER_DETAILS",
	DELIVERY_DETAILS: "DELIVERY_DETAILS",
	DELIVERY_CONTINUATION: "DELIVERY_CONTINUATION",
	DELIVERY_PRICE: "DELIVERY_PRICE",
}

class NewDeliveryModal extends React.Component {
	_sectionsFlow = Object.values(E_Sections);

	constructor(props) {
		super(props);

		this.state = {
			sending: false,
			deliveryType: E_DeliveryType.COURIER_DROP_OFF,
			data: T_DeliverySessionRequestModel,
			continuation: T_DeliverySessionRequestModel,
			activePage: this._sectionsFlow[0]
		};
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		const {deliveryType} = this.state;
		if(prevState.deliveryType !== deliveryType) {
			if(deliveryType === E_DeliveryType.COURIER_PICK_UP) {
				this._sectionsFlow = [E_Sections.DELIVERY_CONTINUATION, E_Sections.COURIER_DETAILS, E_Sections.DELIVERY_DETAILS];
			}
			else {
				this._sectionsFlow = Object.values(E_Sections);
			}

			this.setState({
				activePage: Object.values(this._sectionsFlow)[0],
			});
		}
	}

	_renderCourierDetails(data, onModify) {
		const {deliveryType} = this.state;

		return (
			<form className={"grid-table grid-icon-auto-1fr"} onSubmit={e => e.preventDefault()}>
				<span/>
				<span/>
				<button
					style={{padding: "5px 10px", justifyContent: "center"}}
					className={"fancy"}
					onClick={() => this._openCourierSelect(data, onModify)}
				>
					<Translate id={"selectFromUsers"}/>
				</button>
				<span className={"placeholder"}/>
				<span className={"placeholder"}/>

				<Fa icon={"at"} />
				<label><Translate id={"email"}/></label>
				<TextInput
					type={"email"}
					onModify={onModify}
					defaultValue={data.user?.email}
					path={`user.email`}
					modifyImmediately={true}
				/>

				<Fa icon={"phone-alt"} />
				<label><Translate id={"phoneNumber"}/></label>
				<TextInput
					type={"tel"}
					onModify={onModify}
					defaultValue={data.user?.phone}
					path={`user.phone`}
					modifyImmediately={true}
				/>

				<Fa icon={"language"} />
				<label><Translate id={"locale"}/></label>
				<DropdownLanguage
					onModify={onModify}
					defaultValue={data.user?.locale}
					path={`user.locale`}
				/>

				<Fa icon={"city"} />
				<label><Translate id={deliveryType === E_DeliveryType.COURIER_PICK_UP ? "deliveryAddress" : "pickupAddress"} /></label>
				<span/>

				<TextareaInput
					className={"full-span"}
					onModify={onModify}
					defaultValue={data.address}
					path={`address`}
					style={{minHeight: 200}}
					modifyImmediately={true}
				/>
				<span className={"placeholder"}/>
				<span className={"placeholder"}/>
			</form>
		)
	}

	_renderDeliveryDetails(data, onModify) {
		return (
			<form className={"grid-table grid-icon-auto-1fr"} onSubmit={e => {e.preventDefault(); this._offsetActivePage(1)}}>
				<Fa icon={T_APP_ICONS.NOTE}/>
				<label><Translate id={"notes"} /></label>
				<span/>

				<TextareaInput
					className={"full-span"}
					onModify={onModify}
					defaultValue={data.notes}
					path={`notes`}
					style={{minHeight: 200}}
					modifyImmediately={true}
				/>
				<span className={"placeholder"}/>
				<span className={"placeholder"}/>

				<Fa icon={"barcode"}/>
				<label><Translate id={"customID"}/></label>
				<span/>

				<TextInput
					className={"full-span"}
					onModify={onModify}
					defaultValue={data.customID}
					path={`customID`}
					modifyImmediately={true}
				/>
				<span className={"placeholder"}/>
				<span className={"placeholder"}/>

				<Fa icon={"key"}/>
				<label><Translate id={"customPIN"}/></label>
				<span/>

				<PinInput
					className={"full-span"}
					onModify={onModify}
					defaultValue={data.customPin}
					path={`customPin`}
					modifyImmediately={true}
				/>
				<span className={"placeholder"}/>
				<span className={"placeholder"}/>
			</form>
		)
	}

	_renderDeliveryContinuation(data, onModify) {
		return (
			<div className={"grid-table grid-icon-auto-1fr"}>
				<Fa icon={T_APP_ICONS.USER} />
				<label><Translate id={"user"}/></label>
				<button
					className={"no-style"}
					onClick={() => this._openUserSelect(data, onModify)}
				>
					<TextInput
						defaultValue={data.user?.id ? resolveItemName(get(data, `user`), `email`) : null}
						suffix={<Fa icon={T_APP_ICONS.EDIT_DIALOG}/>}
						path={`user`}
						onModify={onModify}
						canInvalidate={true}
						readOnly={true}
					/>
				</button>

				<h4 className={"full-span"}>-- <Translate id={"or"}/> --</h4>
				<span className={"placeholder"}/>
				<span className={"placeholder"}/>


				<Fa icon={"at"} />
				<label><Translate id={"email"}/></label>
				<TextInput
					type={"email"}
					onModify={onModify}
					defaultValue={data.user?.email || get(data, `user.email`)}
					path={`user.email`}
					disabled={is.valid(get(data, `user.id`))}
					modifyImmediately={true}
				/>

				<Fa icon={"phone-alt"} />
				<label><Translate id={"phoneNumber"}/></label>
				<TextInput
					type={"tel"}
					onModify={onModify}
					defaultValue={data.user?.phone || get(data, `user.phone`)}
					path={`user.phone`}
					disabled={is.valid(get(data, `user.id`))}
					modifyImmediately={true}
				/>

				<Fa icon={T_APP_ICONS.LANGUAGE} />
				<label><Translate id={"locale"}/></label>
				<DropdownLanguage
					onModify={onModify}
					defaultValue={data.user?.locale || get(data, `user.locale`)}
					path={`user.locale`}
					disabled={is.valid(get(data, `user.id`))}
				/>

				<span style={{height: 40}}/>
				<span/>
				<span/>

				<Fa icon={"key"} style={{marginTop: "-1.5em"}} />
				<label style={{marginTop: "-1.5em"}}><Translate id={"customerCustomPin"}/></label>
				<PinInput
					onModify={onModify}
					defaultValue={data.customPin || get(data, `user.defaultPin`)}
					path={`customPin`}
				/>
			</div>
		)
	}

	_renderPrice(data, onModify) {
		return (
			<form className={"grid-table grid-icon-auto-1fr"} onSubmit={e => e.preventDefault()}>
				<span/>
				<label><Translate id={"price"} /></label>
				<NumberInput
					wrapInput={true}
					onModify={onModify}
					defaultValue={data.price.price}
					path={`price.price`}
					suffix={
						<span>
							<Translate id={`currency_${data.price.currency || E_Currency.CZK}`} />
						</span>
					}
					modifyImmediately={true}
				/>

				<Fa icon={"coins"} />
				<label><Translate id={"currency"} /></label>
				<Dropdown
					onModify={onModify}
					defaultValue={data.price.currency}
					path={`price.currency`}
					options={EO(E_Currency).toArray(true)}
					onLabel={item => window.translator.translate(`currency_${item}`)}
					modifyImmediately={true}
				/>

				<span/>
				<label><Translate id={"taxPercent"} /></label>
				<NumberInput
					wrapInput={true}
					onModify={onModify}
					defaultValue={data.price.taxPercent}
					path={`price.taxPercent`}
					suffix={<span>%</span>}
					modifyImmediately={true}
				/>
			</form>
		)
	}

	_renderDeliveryForm(data, continuation) {
		const {activePage} = this.state;

		const onModify = (t, p, v) => this._modifyData(t, p, v);
		const onModifyContinuation = (t, p, v) => this._modifyContinuation(t, p, v);

		switch (activePage) {
			case E_Sections.COURIER_DETAILS:
				return this._renderCourierDetails(data, onModify);
			case E_Sections.DELIVERY_DETAILS:
				return this._renderDeliveryDetails(data, onModify);
			case E_Sections.DELIVERY_CONTINUATION:
				return this._renderDeliveryContinuation(continuation, onModifyContinuation);
			case E_Sections.DELIVERY_PRICE:
				return this._renderPrice(continuation, onModifyContinuation);
		}
	}

	render() {
		const {className, style} = this.props;
		const {sending, activePage, deliveryType, data, continuation} = this.state;

		let isUserDefined = (
			!!get(continuation, `user.id`) ||
			!!get(continuation, `user.email`) ||
			!!get(continuation, `user.phone`)
		);

		const isCourierPinInvalid = data.customPin?.length > 0 && data.customPin?.length < PIN_MIN_LENGTH;
		const isCustomerPinInvalid = continuation.customPin?.length > 0 && continuation.customPin?.length < PIN_MIN_LENGTH;

		return (
			<section className={`new-delivery ${className}`} style={style}>
				<div className={"type-selection"}>
					<ButtonsConstructor
						buttons={[
							{
								icon: "",
								text: `deliveryType_${E_DeliveryType.COURIER_DROP_OFF}`,
								action: () => this.setState({deliveryType: E_DeliveryType.COURIER_DROP_OFF}),
								active: deliveryType === E_DeliveryType.COURIER_DROP_OFF,
							},
							{
								icon: "",
								text: `deliveryType_${E_DeliveryType.COURIER_PICK_UP}`,
								action: () => this.setState({deliveryType: E_DeliveryType.COURIER_PICK_UP}),
								active: deliveryType === E_DeliveryType.COURIER_PICK_UP,
							}
						]}
					/>
				</div>

				<div className={"delivery-details"}>
					<ButtonsConstructor
						buttons={[
							deliveryType === E_DeliveryType.COURIER_PICK_UP &&
							{
								icon: "truck-loading",
								text: <div>
									<Translate id={"customerDetails"}/>
									{
										(
											(activePage == E_Sections.DELIVERY_PRICE ? !isUserDefined : false) ||
											(activePage == E_Sections.DELIVERY_PRICE ? isCustomerPinInvalid : false)
										) &&
										<div className={"error-badge"}>
											<Fa className={"cl-error"} icon={"exclamation-circle"}/>
										</div>
									}
								</div>,
								className: "fancy",
								action: () => this.setState({activePage: E_Sections.DELIVERY_CONTINUATION}),
								active: activePage == E_Sections.DELIVERY_CONTINUATION,
							},
							{
								icon: T_APP_ICONS.USER,
								text: "courierDetails",
								className: "fancy",
								action: () => this.setState({activePage: E_Sections.COURIER_DETAILS}),
								active: activePage == E_Sections.COURIER_DETAILS,
							},
							{
								icon: "info-circle",
								text: <div>
									<Translate id={"deliveryDetails"}/>
									{
										(
											(activePage == E_Sections.DELIVERY_PRICE || activePage == E_Sections.DELIVERY_CONTINUATION ? isCourierPinInvalid : false)
										) &&
										<div className={"error-badge"}>
											<Fa className={"cl-error"} icon={"exclamation-circle"}/>
										</div>
									}
								</div>,
								className: "fancy",
								action: () => this.setState({activePage: E_Sections.DELIVERY_DETAILS}),
								active: activePage == E_Sections.DELIVERY_DETAILS,
							},
							deliveryType !== E_DeliveryType.COURIER_PICK_UP &&
							{
								icon: "truck-loading",
								text: <div>
									<Translate id={"deliveryContinuation"}/>
									{
										(
											(activePage == E_Sections.DELIVERY_PRICE ? !isUserDefined : false) ||
											(activePage == E_Sections.DELIVERY_PRICE ? isCustomerPinInvalid : false)
										) &&
										<div className={"error-badge"}>
											<Fa className={"cl-error"} icon={"exclamation-circle"}/>
										</div>
									}
								</div>,
								className: "fancy",
								action: () => this.setState({activePage: E_Sections.DELIVERY_CONTINUATION}),
								active: activePage == E_Sections.DELIVERY_CONTINUATION,
							},
							deliveryType !== E_DeliveryType.COURIER_PICK_UP &&
							{
								icon: "coins",
								text: "deliveryPrice",
								className: "fancy",
								action: () => this.setState({activePage: E_Sections.DELIVERY_PRICE}),
								active: activePage == E_Sections.DELIVERY_PRICE,
							},
						]}
						wrapInNav={true}
					/>

					<div className={"form-wrapper"}>
						<div className={"delivery-form"}>
							{this._renderDeliveryForm(data, continuation)}
						</div>
						<ButtonsConstructor
							buttons={[
								(
									deliveryType === E_DeliveryType.COURIER_PICK_UP ? activePage !== E_Sections.DELIVERY_DETAILS : activePage !== this._sectionsFlow.lastItem()
								) && {
									text: (
										<Fragment>
											<Translate id={"next"} />
											<Fa style={css("margin-left: 10px")} icon={"arrow-right"} />
										</Fragment>
									),
									action: () => this._offsetActivePage(1),
									className: "fancy next",
									disabled: (
										(activePage === E_Sections.DELIVERY_DETAILS && isCourierPinInvalid) ||
										(deliveryType !== E_DeliveryType.COURIER_PICK_UP && activePage === E_Sections.DELIVERY_CONTINUATION && (!isUserDefined || isCustomerPinInvalid))
									),
									disabledTippy: this._getContinueDisabledTippyContent(!isUserDefined, isCourierPinInvalid),
								}
								||
								(
									deliveryType === E_DeliveryType.COURIER_DROP_OFF &&
									{
										icon: "truck",
										text: "createDelivery",
										disabled: sending || !isUserDefined || isCourierPinInvalid || isCustomerPinInvalid,
										disabledTippy: this._getContinueDisabledTippyContent(!isUserDefined, isCourierPinInvalid, isCustomerPinInvalid),
										action: () => this._createDelivery()
									}
									||
									{
										icon: "truck",
										text: "createDelivery",
										disabled: sending || isCourierPinInvalid,
										disabledTippy: this._getContinueDisabledTippyContent(false, isCourierPinInvalid, undefined),
										action: () => this._createDelivery()
									}
								)
							]}
							sharedProps={{className: "fancy"}}
						/>
					</div>
				</div>

				{
					sending &&
					<div className={"spinner-wrapper"}>
						<CubeSpinner block={true}/>
					</div>
				}
			</section>
		)
	}

	_modifyData(type, path, value) {
		this.setState(state => {
			let newData = EO(state.data).clone(true);
			newData.modify(type, path, value);
			console.log(newData);
			return {data: newData};
		});
	}

	_modifyContinuation(type, path, value) {
		this.setState(state => {
			let newData = EO(state.continuation).clone(true);
			newData.modify(type, path, value);
			return {continuation: newData};
		});
	}

	_modifyCourierAsUser(value, onModify) {
		onModify(E_Modification.ITEM_SET, `user`, value);
		onModify(E_Modification.ITEM_SET, `customPin`, get(value, `defaultPin`));
	}

	_offsetActivePage(direction) {
		this.setState(state => {
			let pages = this._sectionsFlow;
			let activePage = pages.indexOf(state.activePage);

			return {
				activePage: pages[activePage + direction] || state.activePage
			}
		});
	}

	_openUserSelect(data, onModify) {
		window.modal.open({
			body: (
				<UserSelect
					onModify={(type, path, value) => {
						if(value) {
							//Set custom PIN only if there isn't one already
							if(!data.customPin) {
								onModify(E_Modification.ITEM_SET, "customPin", value.defaultPin);
							}
						}

						onModify(type, "user", value);
					}}
					path={`user`}
					defaultValue={data.user?.id ? data.user : null}
				/>
			),
		})
	}

	_openCourierSelect(data, onModify) {
		window.modal.open({
			body: (
				<UserSelect
					onModify={(t,p, value) => this._modifyCourierAsUser(value, onModify)}
					defaultValue={data.user?.id ? data.user : null}
					isActive={(option, value) => option && value && (option.email == value || option.id == value.id)}
				/>
			),
		})
	}

	_createDelivery() {
		const {history, serviceID} = this.props;
		const {deliveryType} = this.state;
		let data = EO(this.state.data).clone();
		let continuation = EO(this.state.continuation).clone();

		data.serviceID = serviceID;
		data.user.type = E_UserInfo_UserType.COURIER;
		data.deliveryType = deliveryType;

		continuation.serviceID = serviceID;
		continuation.deliveryType = deliveryType;
		if(continuation.user) {
			continuation.user.type = E_UserInfo_UserType.CUSTOMER;
		}

		if(deliveryType === E_DeliveryType.COURIER_DROP_OFF) {
			data.continuation = continuation;
		}
		else {
			data.price = null;
			continuation.price = null;

			continuation.continuation = data;
		}

		if(
			deliveryType === E_DeliveryType.COURIER_PICK_UP &&
			!continuation.user?.id &&
			!continuation.user?.email &&
			!continuation.user?.phone
		) {
			continuation.continuation = null
		}

		this.setState({sending: true});
		service_createNewDelivery(JSON.stringify(deliveryType === E_DeliveryType.COURIER_DROP_OFF || !continuation.continuation ? data : continuation)).then(data => {
			window.modal.close();
			history.push(`${routes.SESSIONS}/${data.id}`);
		}, error => {
			error.clone().json().then((jsonError) => {
				if(jsonError.error === "DUPLICATE_PIN") {
					window.toaster.showToast({
						style: E_ToastStyle.ERROR,
						content: "error_SESSION_CREATE_PIN_REJECTED",
						icon: "key"
					});
				}
				else {
					errorHandler(error, SESSION_CREATE_REJECTED);
				}
			});
			this.setState({sending: false});
		});
	}

	_getContinueDisabledTippyContent(userInvalid, courierPinInvalid, customerPinInvalid) {
		if(userInvalid) return <div style={{margin: 5}}><Translate id={"selectUserFirst"}/></div>;
		if(courierPinInvalid) return <div style={{margin: 5}}><Translate id={"pinValidationError_minLength"}/></div>;
		if(customerPinInvalid) return <div style={{margin: 5}}><Translate id={"pinValidationError_minLength"}/></div>;
	}

	static get propTypes() {
		return {
			serviceID: PropTypes.number.isRequired,
		}
	}

	static get stateTypes() {
		return {
			data: PropTypes.object,
			sending: PropTypes.bool,
		}
	}
}

export default withRouter(NewDeliveryModal);
