import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import {Translate, withLocalize} from 'react-localize-redux';
import '../../styles/components/Navigation.scss';
import {T_APP_ICONS} from "../../models/constants/Constants_Shared";
import navigationItems from '../../models/Models_Navigation';
import {css, get, hasParent} from "../../utils/utils";
import CSBaseComponent from "../CSBaseComponent";
import ButtonsConstructor from "../shared/ButtonsConstructor";
import Fa from "../tools/Icons/Fa";
import LanguageToggle from './LanguageToggle';
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {Selector_Auth_Principal} from "../../store/selectors/Selectors_Auth";
import routes from "../../routes/routes";
import is from "../../utils/is";
import {withRouter} from "react-router-dom";
import {Action_Auth_LogOut} from "../../store/actions/authActions";
import ChangePassword from "../pages/Users/Password/ChangePassword";
import withRestrictions from "../shared/Restrictions/withRestrictions";
import {isRestricted} from "../../utils/restrictionUtils";
import {T_SharedRouterPropTypes} from "../../models/Models_Shared";

// The Navigation creates links that can be used to navigate between routes.
class Navigation extends CSBaseComponent {
	constructor(props) {
		super(props);

		this.state = {
			...super.state,
			showMenu: false,
			showFullMenu: false,
			toggledSection: null
		};

		this._handleOutsideClick = this._handleOutsideClick.bind(this);
	}

	componentDidMount() {
		super.componentDidMount();

		//TODO: TBD; Remove if there is no button as a main navigation button (e.g. configuration as of v0.5.1 27.5.2020)
		this._historyListener = this.props.history.listen(() => this.setState({}));

		window.addEventListener("click", this._handleOutsideClick);
	}

	componentWillUnmount() {
		super.componentWillUnmount();

		this._historyListener();
		window.removeEventListener("click", this._handleOutsideClick);
	}

	_renderIconContent(section) {
		const {toggledSection} = this.state;

		return (
			<div onClick={() => this.setState({showFullMenu: false, toggledSection: null})} className={"icon-wrapper"}>
				<Fa icon={section.icon}/>
				{
					section.subRoutes &&
					section.section != "principal" &&
					!this._isSectionRestricted(section) &&
					<Fa className={"fake-expander"} icon={section.section == toggledSection ? "chevron-right" : "ellipsis-v"} />
				}
			</div>
		);
	}

	_renderTextContent(section) {
		const {toggledSection} = this.state;

		return (
			<div className={`button-content ${is.valid(section.subRoutes) ? 'has-sub-routes' : ''}`}>
				<label onClick={() => this.setState({showFullMenu: false, toggledSection: null})}>
					{section.textTranslated ? section.text : <Translate id={section.text}/>}
				</label>
				{
					<div
						className={`expander ${(!section.subRoutes || section.section == "principal") ? "no-interaction" : ''}`}
						onClick={e => {
							e.preventDefault();
							this._toggleSection(section);
						}}
					>
						<Fa icon={section.section == toggledSection ? "chevron-right" : "chevron-down"} />
					</div>
				}
			</div>
		);
	}

	_renderMainNavigationButtons(sections) {
		const {showMenu, showFullMenu} = this.state;

		return (
			<ButtonsConstructor
				buttons={[
					showMenu == true &&
					{
						icon: <div className={"icon-wrapper"}><Fa icon={T_APP_ICONS.CLOSE}/></div>,
						action: () => this.setState({showMenu: false, toggledSection: null}),
						style: {marginBottom: 20},
					}
					||
					{
						icon: <div className={"icon-wrapper"}><Fa icon={showFullMenu ? T_APP_ICONS.CLOSE : "bars"}/></div>,
						action: () => this.setState((state) => ({showFullMenu: !state.showFullMenu, toggledSection: null})),
						style: {marginBottom: 20},
					},
					...sections.map(section => this._processMainNavigationButton(this._getValidNavigationButton(section)))
				]}
				wrapInNav={true}
			/>
		)
	}

	_renderSubsection(section) {
		const {toggledSection} = this.state;

		return (
			<nav className={`${section.section} ${section.section == toggledSection ? "visible" : ''}`}>
				<label>
					{
						section.section == "principal" &&
						this._getUserText()
						||
						<Translate id={section.section}/>
					}
				</label>
				<ButtonsConstructor
					buttons={
						(section.subRoutes || []).filter(
							item => !this._isRestricted(item)
						).map(item => ({
							...item,
							href: this._processHref(item),
							icon: <div className={"icon-wrapper"}><Fa icon={item.icon}/></div>,
							text: (
								<div className={"button-content"}>
									<label>
										<Translate id={`${item.text}`}/>
									</label>
								</div>
							),
							active: () => this._isActiveLink(item),
							props: {
								onClick: e => {
									item.action && item.action(e);
									this.setState({showFullMenu: false, toggledSection: null});
								}
							},
							restricted: null,
						}))
					}
				/>
			</nav>
		)
	}

	_renderSubsections(sections) {
		return (
			<div className={"subsections"}>
				{sections.filter(section => section.subRoutes).map((section, i) => (
					<Fragment key={i}>
						{this._renderSubsection(section)}
					</Fragment>
				))}
			</div>
		)
	}

	_renderNavContent() {
		const sections = this._constructNavigationSections();
		return (
			<Fragment>
				{this._renderMainNavigationButtons(sections)}
				<footer>
					<LanguageToggle/>
					<label>{process.env.REACT_APP_VERSION}</label>
				</footer>
				<div className={"curtain icon-curtain"} />
				<div className={"curtain"} style={css("width: 100%; z-index: 1")} />
				{this._renderSubsections(sections)}
			</Fragment>
		);
	}

	render() {
		const {showFullMenu, showMenu} = this.state;

		return (
			<Fragment>
				<ButtonsConstructor buttons={[{
					className: "open-menu",
					action: () => this.setState({showMenu: true, showFullMenu: true}),
					icon: "bars"
				}]} />

				<section className={"navigation"} extended={`${showFullMenu}`} ref={this.setRef} visible={`${showMenu}`}>
					{this._renderNavContent()}
				</section>
			</Fragment>
		);
	}

	_getPrincipalSection() {
		const {principal} = this.props;

		if(principal) {
			return {
				text: this._getUserText(),
				textTranslated: true,
				icon: T_APP_ICONS.PROFILE,
				section: "principal",
				subRoutes: [
					{
						text: "profile",
						href: ({principal}) => `${routes.USERS}/${principal.id}`,
						icon: "user",
					},
					{
						text: "password_change",
						icon: "key",
						action: () => this._openChangePassword(),
						style: {margin: 0}
					},
					{
						text: "logOut",
						action: () => Action_Auth_LogOut(),
						icon: "lock",
					},
				],
				style: {marginBottom: 20}
			};
		}
		return null;
	}

	_getUserText() {
		const {principal} = this.props;

		if(principal) {
			if(`${principal.email}`.length < 15) {
				return principal.email;
			}

			if(!is.empty(principal.fullName) && `${principal.fullName}`.length < 15) {
				return principal.fullName
			}

			if(is.valid(principal.forename) && is.valid(principal.surname) && !is.empty(principal.forename) && !is.empty(principal.surname)) {
				return `${principal.forename.charAt(0)}${principal.surname.charAt(0)}`.toUpperCase();
			}
		}
		return <Translate id={"principal"}/>
	}

	_constructNavigationSections() {
		const principalSection = this._getPrincipalSection();
		return [
			principalSection,
			...navigationItems
		].filter(item => item && !this._isSectionRestricted(item));
	}

	_processMainNavigationButton(section) {
		return {
			...section,
			href: this._processHref(section),
			action: this._processNavigationButtonClick(section),
			icon: this._renderIconContent(section),
			text: this._renderTextContent(section),
			active: () => this._isActiveLink(section),
		}
	}

	_getValidNavigationButton(section) {
		if(section) {
			if(section.subRoutes) {
				let availableSubRoutes = section.subRoutes.filter(subRoute => !this._isRestricted(subRoute));
				//If only one subRoute is available and main item is restricted
				if(availableSubRoutes.length == 1 && this._isRestricted(section)) {
					return {
						...availableSubRoutes[0],
						section: section.section,
					};
				}
			}

			return section;
		}
	}

	_processNavigationButtonClick(section) {
		if(this._isRestricted(section)) {
			return e => {
				e.preventDefault();
				this._toggleSection(section);
				get(
					section,
					`props.onClick`,
					get(
						section,
						`action`,
						() => null
					))(e);
			}
		}

		//Get onClick from section.props
		return get(
			section,
			`props.onClick`,
			//Get action from section.action
			get(
				section,
				`action`,
				section.href ? null : () => this._toggleSection(section)
			)
		);
	}

	_isRestricted(item) {
		const {hasAccess} = this.props;
		return isRestricted(item, hasAccess);
	}

	_isSectionRestricted(item) {
		return this._isRestricted(item) && (item.subRoutes || []).every(subItem => this._isRestricted(subItem));
	}

	_toggleSection(item) {
		const {toggledSection} = this.state;
		this.setState({toggledSection: item.section == toggledSection ? null : item.section});
	}

	_isActiveLink(item) {
		return(
			window.location.pathname !== '/' &&
			(
				window.location.pathname.includes(this._processHref(item, true)) ||
				!!(item.subRoutes || []).find(route => (
					window.location.pathname.includes(this._processHref(route, true))
				))
			)
		)
	}

	_processHref(item, testMeta = false) {
		if(item) {
			const {principal} = this.props;
			const __testProperty = (obj, prop) => typeof obj[prop] == "function" ? obj[prop]({principal}) : obj[prop];

			return __testProperty(item, `href`) || (testMeta ? __testProperty(item, `metaHref`) : null) || null;
		}
	}

	_handleOutsideClick(e) {
		const {showFullMenu, showMenu, toggledSection} = this.state;

		if((showFullMenu && !showMenu) || toggledSection) {
			if(!hasParent(e.target, this.root)) {
				this.setState({showFullMenu: false, toggledSection: null});
			}
		}
	}

	_openChangePassword() {
		window.modal.open({
			body: <ChangePassword/>,
			header: <label><Translate id={"password_change"}/></label>
		});
	}

	static get propTypes() {
		return {
			...super.propTypes,
			...T_SharedRouterPropTypes,
			principal: PropTypes.object,
		}
	}

	static get stateTypes() {
		return {
			...super.stateTypes,
			showMenu: PropTypes.boolean,
			showFullMenu: PropTypes.boolean,
			toggledSection: PropTypes.string,
		}
	}

	static get defaultProps() {
		return {
			...super.defaultProps,
			principal: {}
		}
	}
}

const mapStateToProps = state => {
	return {
		principal: Selector_Auth_Principal(state),
	};
};

const mapDispatchToProps = dispatch => (
	bindActionCreators({
		Action_Auth_LogOut
	}, dispatch)
);

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(withRestrictions(withLocalize(Navigation))));
