import React from "react";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import ItemSelect from "../../shared/Input/ItemSelect";
import {Selector_ServiceTypes_Listing_Root} from "../../../store/selectors/Selectors_ServiceType";
import {Thunk_ServiceTypes_Fetch_All} from "../../../store/thunk/Thunk_ServiceTypes";

class ServiceTypeSelect extends React.Component {
	render() {
		const {Thunk_ServiceTypes_Fetch_All} = this.props;

		return <ItemSelect
			thunk={Thunk_ServiceTypes_Fetch_All}
			selector={Selector_ServiceTypes_Listing_Root}
			label={"serviceType_select"}
			headerItems={{titles: {field: "titles.text"}, id: {}}}
			{...this.props}
		/>
	}

	static get propTypes() {
		return ItemSelect.propTypes
	}
}

const mapDispatchToProps = dispatch => (
	bindActionCreators({
		Thunk_ServiceTypes_Fetch_All
	}, dispatch)
);

export default connect(null, mapDispatchToProps)(ServiceTypeSelect);
