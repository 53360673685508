import { EO } from "../../utils/extensions";
import { GENERIC_EDIT_FULFILLED, GENERIC_EDIT_PENDING, GENERIC_FETCH_FULFILLED, GENERIC_FETCH_PENDING, GENERIC_FETCH_REJECTED } from "../actions/genericActions";
import { get } from "../../utils/utils";
import { E_Modification } from "../Constants_StoreShared";

const initialState = {
	data: {},
	fetching: false,
	fetched: false,
	failed: false,
	error: {},
};

function handleGenericItemEdit(state, action) {
	let newEditData = EO(state.data).clone(true);
	let payload = action.payload || {};

	if(payload.type === E_Modification.ITEM_REMOVE) {
		newEditData.modify(E_Modification.ARRAY_REMOVE_BY_ID, `content.#${payload.id}`);
	} else {
		newEditData.modify(action.modificationType, `content.#${get(payload, `modification.id`, (payload || {}).id)}`, payload);
	}
	return {
		...state,
		fetching: false,
		data: newEditData
	};
}

export default (listingKey, itemKey, additionalReducer = null) => {
	return (state = initialState, action) => {
		switch (action.type) {
			case `${listingKey}_${GENERIC_FETCH_PENDING}`:
				return {
					...state,
					fetching: true,
					fetched: false,
					failed: false,
					error: {},
				};
			case `${listingKey}_${GENERIC_FETCH_FULFILLED}`:
				return {
					...state,
					data: action.payload,
					fetching: false,
					fetched: true,
					failed: false
				};
			case `${listingKey}_${GENERIC_FETCH_REJECTED}`:
				return {
					...state,
					data: {},
					fetching: false,
					fetched: false,
					failed: true,
					error: action.error,
				};
			case `${listingKey}_${GENERIC_EDIT_PENDING}`:
				return {
					...state,
					fetching: true,
				};
			case `${itemKey}_${GENERIC_EDIT_FULFILLED}`:
				return handleGenericItemEdit(state, action);
			default:
				return additionalReducer && additionalReducer(state, action) || state;
		}
	}
}
