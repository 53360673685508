import React from "react";
import PropTypes from "prop-types";
import "../../../styles/components/input.scss";
import is from "../../../utils/is";
import Fa from "../../tools/Icons/Fa";
import TextInput from "./TextInput";
import InputBase from "./Base/InputBase";
import ItemSelectorInput from "./ItemSelectorInput";
import ReactDOM from "react-dom";
import { computeDropdownContentPosition, css, hasParent } from "../../../utils/utils";

class Dropdown extends InputBase {
	constructor(props) {
		super(props);

		this.state = {
			...this.state,
			value: props.defaultValue,
			refreshContent: false,
			visible: props.visible || false,
			labelValue: '',
		}

		this._updateContentPosition = this._updateContentPosition.bind(this);
		this._handleOutsideClick = this._handleOutsideClick.bind(this);
		this._handleKeyUp = this._handleKeyUp.bind(this);
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		super.componentDidUpdate(prevProps, prevState, snapshot);

		const {visible} = this.state;

		if(!is.equal(prevState.visible, visible)) {
			if(visible) {
				document.addEventListener("scroll", this._updateContentPosition, true);
				window.addEventListener("resize", this._updateContentPosition);
				document.addEventListener("click", this._handleOutsideClick);
				document.addEventListener("keyup", this._handleKeyUp);
				this.props.onOpen();
			} else {
				document.removeEventListener("scroll", this._updateContentPosition, true);
				window.removeEventListener("resize", this._updateContentPosition);
				document.removeEventListener("click", this._handleOutsideClick);
				document.removeEventListener("keyup", this._handleKeyUp);
				this.props.onClose();
			}
		}

		if(!is.equal(prevProps.visible, this.props.visible)) {
			this.setState({visible: this.props.visible});
		}
	}

	componentWillUnmount() {
		super.componentWillUnmount();
		document.removeEventListener("scroll", this._updateContentPosition, true);
		window.removeEventListener("resize", this._updateContentPosition);
		document.removeEventListener("click", this._handleOutsideClick);
		document.removeEventListener("keyup", this._handleKeyUp);
	}

	_renderDropdownContent(relocate = true) {
		const {refreshContent, value} = this.state;
		const {content, options, contentClassName} = this.props;

		relocate && setTimeout(() => this._updateContentPosition(), 100);

		return (
			<div className={`dropdown-content ${contentClassName}`} name={"content"} ref={this.setRef}>
				{
					React.cloneElement(content, {
						...this.props,
						children: content.children,
						options,
						refreshContent,
						defaultValue: this.getFromValueMap(value, "key"),
						valueMap: null,
						onValue: v => v,
						onLabelValue: (value) => this.setState({labelValue: value}),
						onModify: (type, path, value) => {
							this.applyChange(path, value, type);
							setTimeout(() => this._closeDropdown(), 100);
						}
					})
				}
			</div>
		);
	}

	render() {
		const {labelValue, visible} = this.state;
		const {canInvalidate, children, className, path, disabled, disabledMessage, closeOnClick, trigger, valueMap} = this.props;

		return (
			<span
				className={`dropdown`}
				name={"trigger"}
				ref={this.setRef}
				{...trigger != "manual" && {
					//Register trigger event (e.g. onClick, onFocus, ...)
					[`on${trigger}`]: (e) => {
						if(!disabled && (closeOnClick || !hasParent(e.target, this.content))) {
							if(trigger == "Focus") {
								this._openDropdown()
							} else {
								this._isMounted && this.setState({visible: !visible});
							}
						}
					},
					...(trigger == "Focus" && {
						onBlur: () => {
							//Must be delayed to prevent blink when unfocusing and focusing again
							setTimeout(() => !hasParent(document.activeElement, this.trigger, this.content) && this._closeDropdown());
						}
					})
				}}
			>
				{
					children
					||
					<TextInput
						className={`${className}`}
						readOnly={true}
						defaultValue={labelValue}
						suffix={<Fa icon={"caret-down"} />}
						canInvalidate={canInvalidate && is.valid(labelValue)}
						path={path}
						onModify={(type, path, change) => this.applyChange(path, change)}
						disabled={disabled}
						disabledMessage={disabledMessage}
						valueMap={valueMap}
					/>
				}
				{
					visible &&
					ReactDOM.createPortal(this._renderDropdownContent(), document.querySelector("body"))
					||
					<div style={{display: "none"}}>{this._renderDropdownContent(false)}</div>
				}
			</span>
		)
	}

	_openDropdown() {
		this.setState(state => ({visible: true, refreshContent: !state.refreshContent}));
	}

	_closeDropdown() {
		this._isMounted && this.setState({visible: false});
	}

	_updateContentPosition(e = {}) {
		if(!hasParent(e.target, this.content)) {
			this.content && this.content.removeAttribute("style");
			css(computeDropdownContentPosition(this.trigger, this.content), this.content);
		}
	}

	_handleOutsideClick(e) {
		if(!hasParent(e.target, this.content, this.trigger)) {
			this._closeDropdown();
		}
	}

	_handleKeyUp(e) {
		if(e.key == "Escape") {
			e.preventDefault();
			e.stopPropagation();

			this._closeDropdown();
		}
	}

	static get propTypes() {
		return {
			...super.propTypes,
			...ItemSelectorInput.propTypes,
			closeOnClick: PropTypes.bool,
			contentClassName: PropTypes.string,
			onOpen: PropTypes.func,
			onClose: PropTypes.func,
		}
	}

	static get stateTypes() {
		return {
			...super.stateTypes,
		}
	}

	static get defaultProps() {
		return {
			...super.defaultProps,
			...ItemSelectorInput.defaultProps,
			content: <ItemSelectorInput/>,
			closeOnClick: false,
			hideOnClick: true,
			trigger: "Click",
			onOpen: () => null,
			onClose: () => null,
		}
	}
}

export default Dropdown;
