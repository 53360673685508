import React from 'react';
import PropTypes from 'prop-types';
import "../../../styles/components/CubeSpinner.scss";

class CubeSpinner extends React.Component {
	constructor(props) {
		super(props);

		this.state = {}
	}

	render() {
		const {className, block, style} = this.props;

		return (
			<div className={`sk-cube-grid ${className}`} block={`${block}`} style={style}>
				{
					Array(9).fill(null).map((_, i) => (
						<div key={i} className={`sk-cube sk-cube${i + 1}`} />
					))
				}
			</div>
		)
	}
}

CubeSpinner.propTypes = {
	className: PropTypes.string,
	block: PropTypes.bool,
	style: PropTypes.object
};

CubeSpinner.stateTypes = {};

export default CubeSpinner;
