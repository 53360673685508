import React from 'react';
import PropTypes from 'prop-types';
import { T_SharedTippyProps } from "../../../models/Models_Shared";
import CSBaseComponent from "../../CSBaseComponent";
import Tippy from "../Tippy";

class UserConfirmation extends CSBaseComponent {
	constructor(props) {
		super(props);

		window.userConfirm = this;
	}

	render() {
		return (
			<Tippy
				{...T_SharedTippyProps}
				theme={this.props.theme}
				trigger={"click"}
				interactive={true}
				onCreate={instance => this.tippyInstance = instance}
				appendTo={document.querySelector("body")}
				content={
					<button onClick={() => {
						this.tippyInstance && this.tippyInstance.hide();
						this.props.onConfirm();
					}} className={this.props.className}>
						{this.props.confirmContent}
					</button>
				}
			>
				{this.props.children}
			</Tippy>
		)
	}
}

UserConfirmation.propTypes = {
	theme: PropTypes.string,
	children: PropTypes.any,
	confirmContent: PropTypes.any.isRequired,
	onConfirm: PropTypes.func.isRequired,
};

UserConfirmation.stateTypes = {};

export default UserConfirmation;
