// eslint-disable-next-line no-unused-vars
import { formatQueryString, POST, sendRequest } from "./apiServices/apiConnector";
import { Process_formatQueryString } from "../utils/processors";

export const service_openServiceNow = (serviceID, actionID) => {
	return sendRequest([`/maintenance/service/${serviceID}/openNow${formatQueryString(Process_formatQueryString({actionID}))}`, POST]).then(response => response);
};

export const service_openUnitNow = (unitID, scope) => {
	return sendRequest([`/maintenance/unit/${unitID}/openNow${formatQueryString(scope)}`, POST]).then(response => response);
};

export const service_sendMaintenanceSessionNotify = (sessionID) => {
	return sendRequest([`/maintenance/session/${sessionID}/notify`, POST]).then(response => response);
}
