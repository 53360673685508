import { E_Modification } from "../Constants_StoreShared";
import { successHandler } from "../ResponseHandling";
import { E_APIServiceActions } from "../../models/constants/Constants_Shared";

export function Thunk_Generic_Fetch_All(queryString = null, service, pendingAction, successAction, failAction, successResponse = () => null, failResponse = () => null) {
	let actionResponse = {
		action: {type: E_APIServiceActions.ITEMS_FETCH}
	}

	return (dispatch) => {
		dispatch(pendingAction());

		service(queryString).then(response => {
			successResponse(response, actionResponse);
			dispatch(successAction(response));
		}, error => {
			failResponse(error);
			dispatch(failAction(error, actionResponse));
		});
	}
}

export function Thunk_Generic_Fetch_Item(itemID = null, service, pendingAction, successAction, failAction, processor = body => body, successResponse = () => null, failResponse = () => null) {
	let actionResponse = {
		action: {type: E_APIServiceActions.ITEM_FETCH}
	}

	return (dispatch) => {
		dispatch(pendingAction());

		service(itemID).then(response => {
			successResponse(processor(response), actionResponse);
			dispatch(successAction(processor(response)));
		}, error => {
			failResponse(error);
			dispatch(failAction(error, actionResponse));
		});
	}
}

export function Thunk_Generic_Edit_Insert(service, pendingAction, successAction, failAction, selector, submitProcessor = body => body, fetchProcessor = body => body, successResponse = successHandler, failResponse = () => null) {
	let actionResponse = {
		action: {type: E_APIServiceActions.ITEM_INSERT}
	}

	return (dispatch, getState) => {
		dispatch(pendingAction());

		let body = submitProcessor(selector(getState()));

		service(JSON.stringify(body)).then(response => {
			successResponse(fetchProcessor(response), actionResponse);
			dispatch(successAction(fetchProcessor(response)));
		}, error => {
			failResponse(error);
			dispatch(failAction(error, actionResponse));
		});
	}
}

export function Thunk_Generic_Edit_Update(service, pendingAction, successAction, failAction, selector, submitProcessor = body => body, fetchProcessor = body => body, successResponse = successHandler, failResponse = () => null) {
	let actionResponse = {
		action: {type: E_APIServiceActions.ITEM_UPDATE}
	}

	return (dispatch, getState) => {
		dispatch(pendingAction());

		let body = submitProcessor(selector(getState()));

		service(body.id, JSON.stringify(body)).then(response => {
			successResponse(fetchProcessor(response), actionResponse);
			dispatch(successAction(fetchProcessor(response)));
		}, error => {
			failResponse(error);
			dispatch(failAction(error, actionResponse));
		});
	}
}

export function Thunk_Generic_Edit_Save(selector, pendingAction, insertThunk, updateThunk) {
	return (dispatch, getState) => {
		dispatch(pendingAction);

		let body = selector(getState());

		if (body.id) {
			dispatch(updateThunk());
		} else {
			dispatch(insertThunk());
		}
	}
}

export function Thunk_Generic_Remove(id, service, pendingAction, successAction, failAction, successResponse = successHandler, failResponse = () => null) {
	let action = {
		type: E_Modification.ITEM_REMOVE,
		id: typeof id == "object" ? id.id : id,
	};
	let actionResponse = {
		action: {type: E_APIServiceActions.ITEM_DELETE}
	}

	return dispatch => {
		dispatch(pendingAction);
		service(id).then(() => {
			successResponse(null, actionResponse);
			dispatch(successAction(action));
		}, error => {
			failResponse();
			dispatch(failAction(error, actionResponse));
		})
	}
}
