import React from "react";
import { T_APP_ICONS } from "./constants/Constants_Shared";
import { Translate } from "react-localize-redux";
import Fa from "../components/tools/Icons/Fa";

export const E_ButtonPresets = {
	REMOVE: "REMOVE",
	PASSWORD_CHANGE: "PASSWORD_CHANGE",
}

export const T_ButtonPresetsModel = {
	[E_ButtonPresets.REMOVE]: {
		icon: T_APP_ICONS.REMOVE,
		tippy: <Translate id={"remove"}/>,
		className: "remove-button",
		confirm: <span><Fa icon={"trash-alt"} /> <Translate id={"remove"} /></span>,
	},
	[E_ButtonPresets.PASSWORD_CHANGE]: {
		icon: "lock",
		text: "password_change",
		//Button is inside a form thus by pressing the form will submit even without any button action
		action: () => null,
		className: "fancy",
	}
}
