export const Selector_Generic_List_Root = (root, state) => {
	return root(state).listing;
};

export const Selector_Generic_List_Data = (root, state) => {
	return Selector_Generic_List_Root(root, state).data;
};

export const Selector_Generic_Item_Root = (root, state) => {
	return root(state).item;
};

export const Selector_Generic_Item = (root, state) => {
	return Selector_Generic_Item_Root(root, state).data;
};

export const Selector_Generic_Item_Edit = (root, state) => {
	return Selector_Generic_Item_Root(root, state).edit;
};

export const Selector_Generic_Item_Edit_Reducer = state => {
	return state.edit;
};
