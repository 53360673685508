import {E_Visibility} from "./Models_Location";
import {T_DisplayText} from "./Models_Shared";
import {E_WeekDays} from "../utils/localeUtils";

export const OPEN = "OPEN";
export const CLOSED = "CLOSED";
export const UNAVAILABLE = "UNAVAILABLE";
export const SINGLE_USE = "SINGLE_USE";
export const RENTAL = "RENTAL";

export const E_LockState = {
	OPEN,
	CLOSED,
	UNAVAILABLE
};

export const E_OneTimeEndEvent = {
	OPEN,
	CLOSE: "CLOSE"
};

export const E_ControlType = {
	SINGLE_USE,
	RENTAL,
	RESERVATION: "RESERVATION",
};

export const T_ServiceConfiguration = {
	oneTimeEndEvent: null,
	oneTimeUseBlockTimeMinutes: 0,
	rentalLimitHours: 0,
	reservationBlockMinutes: 60,
};

/**
 * Reservation restriction type enum
 * ---
 * @enum string
 * @readonly
 */
export const E_ReservationRestrictionType = {
	UNAVAILABLE: "UNAVAILABLE",
	HIDE: "HIDE",
};

/**
 *
 * @type {{
 *  weekDay: E_WeekDays,
 *  type: E_ReservationRestrictionType,
 *  fromTime: string,
 *  toTime: string,
 * }}
 */
export const T_ServiceReservationRestriction = {
	weekDay: E_WeekDays.MONDAY,
	fromTime: "00:00",
	toTime: "00:00",
	type: E_ReservationRestrictionType.UNAVAILABLE,
};

/**
 * Service action model
 * ---
 * @type {{
 *      communicationUnit: T_communicationUnitModel,
 *      outputChannel: Number,
 *      displayName: T_DisplayText[],
 *      icon: T_ImageModel,
 *      reservationRestrictions: T_ServiceReservationRestriction[],
 *      id: Number,
 *      createdOn: Date,
 *      enabled: Boolean,
 *      primary: Boolean,
 * }}
 */
export const T_ServiceActionModel = {
	id: undefined,
	createdOn: null,
	communicationUnit: null,
	displayName: [],
	enabled: true,
	icon: null,
	outputChannel: null,
	primary: false,
};

/**
 * Service Model
 * ---
 * @type {{
 *      activeSession: <T_ActiveSession>,
 *      communicationUnit: <T_communicationUnitModel>,
 *      enabled: Boolean,
 *      id: Number,
 *      ledConfiguration: <T_LedConfiguration>,
 *      lockConfiguration: <T_LockConfiguration>,
 *      lockState: <E_LockState>,
 *      occupied: Boolean,
 *      orderPosition: Number,
 *      outputChannel: Number,
 *      physicalReference: String,
 *      serviceConfiguration: <T_ServiceConfiguration>,
 *      serviceType: <T_ServiceType>,
 *      visibility: <E_Visibility>,
 *      actions: T_ServiceActionModel[],
 * }}
 */
export const T_serviceModel = {
	activeSession: null,
	enabled: true,
	id: null,
	ledConfiguration: null,
	lockConfiguration: null,
	lockState: E_LockState.CLOSED,
	occupied: false,
	orderPosition: 0,
	physicalReference: null,
	serviceConfiguration: T_ServiceConfiguration,
	serviceType: null,
	visibilityType: E_Visibility.VISIBLE,
	actions: [],
	reservationRestrictions: [],
};

export const T_LedConfiguration = {
	greenChannel: 0,
	greenCommunicationUnit: {},
	redChannel: 0,
	redCommunicationUnit: {}
};

export const T_LockConfiguration = {
	communicationUnit: {},
	feedbackChannel: 0
};

/**
 * Service Category Model
 * ---
 * @type {{image: <T_ImageModel>, name: String, id: Number, titles: <T_DisplayText>, descriptions: <T_DisplayText>}}
 */
export const T_ServiceCategory = {
	descriptions: [],
	id: null,
	image: null,
	name: '',
	titles: []
};

export const T_DimensionsDetail = {
	dimensionsText: [],
	volume: 0,
	x: 0,
	y: 0,
	z: 0,
};

/**
 * Service Type Model
 * ---
 * @type {{
 *     id: Number,
 *     controlType: String,
 *     description: T_DisplayText[],
 *     dimensions: String,
 *     serviceCategory: <T_ServiceCategory>,
 *     dimensionsDetail: T_DimensionsDetail,
 *     subtitles: Object,
 *     tariff: <T_TariffModel>,
 *     titles: T_DisplayText[]
 *     subtitles: T_DisplayText[]
 * }}
 */
export const T_ServiceType = {
	id: null,
	controlType: '',
	descriptions: [],
	dimensions: '',
	dimensionsDetail: T_DimensionsDetail,
	serviceCategory: null,
	tariff: null,
	titles: [],
	subtitles: [],
};
