import React from "react";
import PropTypes from "prop-types";
import Subscriber from "../../tools/Subscriber";
import {withRouter} from "react-router-dom";
import ItemSelectorInput from "./ItemSelectorInput";
import TextInput from "../../shared/Input/TextInput";
import {resolveDynamicComponent} from "../../../utils/utils";
import InputBase from "./Base/InputBase";
import Fa from "../../tools/Icons/Fa";
import "../../../styles/shared/ItemSelect.scss";
import {Translate} from "react-localize-redux";
import is from "../../../utils/is";
import {T_SharedPropTypes} from "../../../models/Models_Shared";
import ButtonsConstructor from "../ButtonsConstructor";


class ItemSelect extends InputBase {
	constructor(props) {
		super(props);

		this.state = {
			...this.state,
			search: '',
		};
	}

	componentDidMount() {
		this._updateModalContent()
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		super.componentDidUpdate(prevProps, prevState, snapshot);
		const {value} = this.state;


		if(!is.equal(prevState.value, value)) {
			this._updateModalContent();
		}
	}

	render() {
		const {className, style, match, history, thunk, selector, defaultValue, onLabel, isActive, headerItems, onRestrict, path, actionButtons, service, canSearch, children, showFooter} = this.props;
		const {search, value} = this.state;
		const defaultActionButtons = [
			{
				icon: "check",
				text: "accept",
				disabled: !is.valid(value),
				disabledTippy: <div style={{padding: 5}}><Translate id={"selectSomethingFirst"}/></div>,
				action: () => {
					this.applyChange(path, value);
					window.modal.close();
				},
			}
		];

		return (
			<section className={`item-select ${className}`} style={{...style, width: 600, height: 600}}>
				{
					canSearch &&
					<Translate>
						{({translate}) => (
							<TextInput
								prefix={
									<Fa icon={"search"}/>}
								placeholder={translate("search")}
								onModify={(t, p, search) => this.setState({search})}
								canInvalidate={true}
							/>
						)}
					</Translate>
				}
				<div className={"options-wrapper"}>
					<Subscriber
						thunk={() => thunk}
						service={service}
						rootSelector={selector}
						search={search}
						footer={showFooter}
						match={match}
						history={history}
						queryNotify={false}
						headerItems={headerItems}
						inlineSpinner={true}
					>
						{({items, key}) => {
							return <ItemSelectorInput
								key={key}
								options={items}
								onModify={(t,p, value) => this.setState({value})}
								onLabel={onLabel}
								defaultValue={defaultValue}
								isActive={isActive}
								onRestrict={onRestrict}
							>
								{children}
							</ItemSelectorInput>;
						}}
					</Subscriber>
				</div>

				<ButtonsConstructor
					wrapInNav={true}
					className={"action-buttons"}
					buttons={typeof actionButtons === "function" ? actionButtons(defaultActionButtons, this) : [...defaultActionButtons, actionButtons]}
					sharedProps={{className: "fancy"}}
				/>
			</section>
		)
	}

	applyChange() {
		const {value} = this.state;

		return super.applyChange(this.props.path, value);
	}

	_updateModalContent() {
		const {label} = this.props;

		window.modal.modifyActiveContent({
			header: resolveDynamicComponent(label, <label><Translate id={label} /></label>),
		});
	}

	static get propTypes() {
		return {
			...super.propTypes,
			...T_SharedPropTypes,
			label: PropTypes.any,
			thunk: PropTypes.func,
			selector: PropTypes.func,
			service: PropTypes.func,
			isActive: PropTypes.func,
			headerItems: PropTypes.object.isRequired,
			onRestrict: PropTypes.func,
			actionButtons: PropTypes.oneOfType([PropTypes.array, PropTypes.func]),
			canSearch: PropTypes.bool,
			showFooter: PropTypes.bool,
		}
	}

	static get stateTypes() {
		return {
			...super.stateTypes,
			search: PropTypes.string,
		}
	}

	static get defaultProps() {
		return {
			...super.defaultProps,
			headerItems: {id: {}},
			canSearch: true,
			showFooter: true,
		}
	}
}

export default withRouter(ItemSelect);
