import React from "react";
import PropTypes from "prop-types";
import { EO } from "../../utils/extensions";
import { get, hasOwnProperty, resolveErrorBlockContentByError } from "../../utils/utils";
import ButtonsConstructor from "./ButtonsConstructor";
import "../../styles/components/ViewSwitch.scss";
import ErrorBlock from "./Error/ErrorBlock";
import { willAlwaysFailError } from "../../store/ResponseHandling";

class ViewSwitch extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			...this.state,
			active: this._getActiveViewFromStorage() || this._getDefaultView(props)
		};
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		if(prevState.active !== this.state.active) {
			this._setActiveViewToStorage();
		}
	}

	_wrapWithDiv(content) {
		const {className} = this.props;

		if(className) {
			return (
				<div className={className}>
					{content}
				</div>
			)
		}
		return content;
	}

	render() {
		const {options, children, header, failed, onRetry, error} = this.props;
		const {active} = this.state;
		const activeContent = get(options.find(option => option.view === active), `content`) || {};

		return (
			<section className={"view-switch"}>
				{
					(header || options.length > 1) &&
					<header className={"view-header"}>
						{header}
						{
							options.length > 1 &&
							<ButtonsConstructor
								wrapInNav={true}
								className={"action-buttons"}
								buttons={
									(options || []).map(option => {
										const button = option.button;
										let isActive = option.view === active;

										return {
											...button,
											active: isActive,
											action: () => this.setState({active: option.view}),
											disabled: failed,
										}
									})
								}
							/>
						}
					</header>
				}
				{
					failed &&
					<ErrorBlock
						{...resolveErrorBlockContentByError(error)}
						retry={!willAlwaysFailError(error) ? onRetry : undefined}
						style={{borderTop: "5px solid var(--primary-theme-color)"}}
					/>
					||
					this._wrapWithDiv(
						React.cloneElement(
							activeContent,
							{
								...EO({...this.props}).removeContents(obj => {
									delete obj.className;
									delete obj.options;
									delete obj.children;
								}),
								//Can be nullified by setting name={null} on the child component
								name: activeContent.props && hasOwnProperty(activeContent.props, "name") ? activeContent.props.name : this.props.name,
							}
						)
					)
				}
				{children}
			</section>
		)
	}

	_getDefaultView(props = this.props) {
		return get(
			props.options.find(option => option.default === true),
			`view`,
			get(props, `options.0.view`)
		)
	}

	_getActiveViewFromStorage() {
		const {name} = this.props;

		if(name) {
			let viewSwitch = JSON.parse(localStorage.getItem("viewSwitch"));
			if(viewSwitch && viewSwitch[name]) {
				return viewSwitch[name].activeView;
			}
		}

		return null;
	}

	_setActiveViewToStorage(activeView) {
		const {name} = this.props;
		if(name) {
			activeView = activeView || this.state.active;

			let viewSwitch = JSON.parse(localStorage.getItem("viewSwitch")) || {};
			viewSwitch[name] = {
				...viewSwitch[name],
				activeView: activeView == this._getDefaultView() ? null : activeView,
			};

			localStorage.setItem("viewSwitch", JSON.stringify(viewSwitch));
		}
	}
}

ViewSwitch.propTypes = {
	options: PropTypes.array.isRequired,
	children: PropTypes.any,
	className: PropTypes.string,
	header: PropTypes.any,
	failed: PropTypes.bool,
	propagateFailed: PropTypes.bool,
	onRetry: PropTypes.func,
	name: PropTypes.string,
	error: PropTypes.any,
};

ViewSwitch.stateTypes = {
	active: PropTypes.string.isRequired,
};

ViewSwitch.defaultProps = {
	options: [],
	propagateFailed: true,
};

export default ViewSwitch;
