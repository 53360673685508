import { T_navigationRoutes } from '../routes/routes';
import { E_UserRoles, T_APP_ICONS } from "./constants/Constants_Shared";
import { E_UserPermissionOption } from "./Models_User";

const navigationItems = [
	// {
	// 	text: "home",
	// 	href: T_navigationRoutes.BASE,
	// 	icon: T_APP_ICONS.HOME,
	// 	exact: true,
	// 	section: "home"
	// },
	{
		text: "locations",
		href: T_navigationRoutes.LOCATIONS,
		icon: T_APP_ICONS.LOCATIONS,
		section: "locations",
		subRoutes: [
			{
				text: "locations_external",
				href: T_navigationRoutes.EXTERNAL_LOCATIONS,
				icon: T_APP_ICONS.EXTERNAL_LOCATIONS,
			},
		],
		restricted: {
			role: E_UserRoles.ADMIN,
			permissions: {locations: E_UserPermissionOption.READ}
		}
	},
	{
		text: "sessions",
		href: T_navigationRoutes.SESSIONS,
		icon: T_APP_ICONS.SESSIONS,
		section: "sessions",
		restricted: {
			role: E_UserRoles.ADMIN,
			permissions: {sessions: E_UserPermissionOption.READ}
		}
	},
	{
		text: "users",
		href: T_navigationRoutes.USERS,
		icon: T_APP_ICONS.USERS,
		section: "users",
		subRoutes: [
			{
				text: "feedback",
				href: T_navigationRoutes.FEEDBACK,
				icon: T_APP_ICONS.FEEDBACK,
				restricted: {
					role: E_UserRoles.ADMIN,
					permissions: {feedback: E_UserPermissionOption.READ}
				}
			},
			{
				text: "partners",
				href: T_navigationRoutes.PARTNERS,
				icon: T_APP_ICONS.PARTNERS,
				restricted: {
					role: E_UserRoles.ADMIN,
					permissions: {
						partners: E_UserPermissionOption.READ,
						partnerID: false
					},
				}
			},
			{
				text: "partner",
				href: ({principal}) => `${T_navigationRoutes.PARTNERS}/${principal.permissions.partnerID}`,
				icon: T_APP_ICONS.PARTNERS,
				restricted: {
					role: E_UserRoles.ADMIN,
					permissions: {
						partnerID: true
					}
				}
			},
			{
				text: "vouchers",
				href: T_navigationRoutes.VOUCHERS,
				icon: T_APP_ICONS.VOUCHERS,
				restricted: {
					role: E_UserRoles.ADMIN,
					permissions: {vouchers: E_UserPermissionOption.READ}
				}
			},
			{
				text: "serviceAuthorizations",
				href: T_navigationRoutes.AUTHORIZATIONS,
				icon: T_APP_ICONS.AUTHORIZATIONS,
				restricted: {
					role: E_UserRoles.ADMIN,
				}
			}
		],
		restricted: {
			role: E_UserRoles.ADMIN,
			permissions: {users: E_UserPermissionOption.READ}
		}
	},
	{
		text: "statistics",
		href: T_navigationRoutes.STATISTICS,
		icon: T_APP_ICONS.STATISTICS,
		section: "statistics",
		restricted: {
			role: E_UserRoles.ADMIN,
			permissions: {partnerID: false},
		}
	},
	{
		text: "configuration",
		//href: T_navigationRoutes.CONFIGURATION, //TODO: There is nothing relevant inside the config page so this is link is not necessary. Might come in handy later...
		metaHref: T_navigationRoutes.CONFIGURATION,
		icon: T_APP_ICONS.CONFIGURATION,
		section: "configuration",
		subRoutes: [
			{
				text: "serviceTypes",
				href: T_navigationRoutes.SERVICE_TYPES,
				icon: T_APP_ICONS.SERVICE_TYPES,
				restricted: {
					role: E_UserRoles.ADMIN,
					permissions: {globalConfiguration: E_UserPermissionOption.READ}
				}
			},
			{
				text: "serviceCategories",
				href: T_navigationRoutes.SERVICE_CATEGORIES,
				icon: T_APP_ICONS.SERVICE_CATEGORIES,
				restricted: {
					role: E_UserRoles.ADMIN,
					permissions: {globalConfiguration: E_UserPermissionOption.READ}
				}
			},
			{
				text: "tariffs",
				href: T_navigationRoutes.TARIFFS,
				icon: T_APP_ICONS.TARIFFS,
				restricted: {
					role: E_UserRoles.ADMIN,
					permissions: {tariffs: E_UserPermissionOption.READ}
				}
			},
			{
				text: "settings",
				href: T_navigationRoutes.SETTINGS,
				icon: "cog",
				restricted: {
					role: E_UserRoles.ADMIN,
					permissions: {partnerID: false},
				}
			}
		],
		restricted: {
			role: E_UserRoles.ADMIN,
			permissions: {globalConfiguration: E_UserPermissionOption.READ}
		}
	},
	process.env.NODE_ENV === "development" && {
		text: "Playground",
		textTranslated: true,
		href: T_navigationRoutes.PLAYGROUND,
		icon: "cube",
		section: "playground"
	}
];

export default navigationItems;
