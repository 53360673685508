import PropTypes from 'prop-types';
import React from 'react';
import { Translate, withLocalize } from "react-localize-redux";
import { connect } from "react-redux";
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from "redux";
import { T_APP_ICONS } from "../../../models/constants/Constants_Shared";
import { T_SharedTippyProps, T_SortType } from "../../../models/Models_Shared";
import routes from '../../../routes/routes';
import { Selector_Sessions_Listing_Root } from "../../../store/selectors/Selectors_Sessions";
import { Thunk_Session_Insert, Thunk_Sessions_Fetch_All } from "../../../store/thunk/Thunk_Sessions";
import { css, formatAsPrice, get, resolveItemName } from "../../../utils/utils";
import CSBaseComponent from "../../CSBaseComponent";
import Fa from "../../tools/Icons/Fa";
import ListingPage from "../../shared/Listing/ListingPage";
import TableListing from "../../shared/Listing/TableListing";
import { E_SessionState, E_SessionType } from "../../../models/Models_Session";
import TableColumn from "../../shared/Listing/TableColumn";
import Tippy from "../../shared/Tippy";
import TableRow from "../../shared/Listing/TableRow";
import ItemRedirect from "../../shared/ItemRedirect";
import { T_RestrictionPresets } from "../../../models/Models_Restrictions";
import {service_downloadSessionsExport} from "../../../services/Service_Sessions";
import {EO} from "../../../utils/extensions";
import Dropdown from "../../shared/Input/Dropdown";

class SessionsList extends CSBaseComponent {
	constructor(props) {
		super(props);

		this.state = {
			...super.state,
		};
	}

	_renderTemplate(session, i, headerItems) {
		const {activeLanguage} = this.props;

		return (
			<TableRow key={i} href={`${routes.SESSIONS}/${session.id}`} headerItems={headerItems}>
				<TableColumn column={"icon"} className={"elevate"}>
					<Tippy
						{...T_SharedTippyProps}
						content={
							<Translate id={
								(
									session.sessionType &&
									![E_SessionType.REGULAR].includes(session.sessionType)
								) ? `sessionType_${session.sessionType}` : `state_${session.state}`
							}/>
						}
					>
						<span style={{pointerEvents: "all"}}>
							{this._getSessionIcon(session)}
						</span>
					</Tippy>
				</TableColumn>
				<TableColumn column={"state"}><Translate id={`state_${session.state}`} /></TableColumn>
				<TableColumn column={"sessionType"}><Translate id={`sessionType_${session.sessionType}`}/></TableColumn>
				<TableColumn column={"email"} className={"ellipsis"}>
					{
						get(session, `user.id`) &&
						<ItemRedirect href={`${routes.USERS}/${session.user.id}`}>
							{resolveItemName(session.user, `email`)}
						</ItemRedirect>
						||
						session.invoiceEmail
						||
						'-'
					}
				</TableColumn>
				<TableColumn column={"startedAt"}>{
					session.startedAt &&
					(new Date(session.startedAt)).toLocaleString(activeLanguage.code)
					||
					<Translate id={`state_${session.state}`} />
				}</TableColumn>
				<TableColumn column={"endedAt"}>{
					session.endedAt &&
					new Date(session.endedAt).toLocaleString(activeLanguage.code)
					||
					<Translate id={"session_inProgress"} />
				}</TableColumn>
				<TableColumn column={"customID"}>
					{session.customID || '-'}
				</TableColumn>
				<TableColumn column={"location"}>
					{
						get(session, `location.id`) &&
						<ItemRedirect href={`${routes.LOCATIONS}/${session.location.id}`}>
							{resolveItemName(session.location)}
						</ItemRedirect>
						||
						'-'
					}
				</TableColumn>
				<TableColumn column={"service"}>
					{
						get(session, `location.id`) && get(session, `service.id`) &&
						<ItemRedirect href={`${routes.LOCATIONS}/${session.location.id}/undefined/${session.service.id}`}>
							{session.service.physicalReference}
						</ItemRedirect>
						||
						get(session, `service.physicalReference`, '-')
					}
				</TableColumn>
				<TableColumn column={"unpaidAmount"}>
					{get(session, `cost.unpaidAmount`, '', unpaid => (
						unpaid > 0 &&
						<b className={"cl-error double-contrast"}>
							-{formatAsPrice(unpaid, get(session, `service.tariff.currency`, "CZK"), this)}
						</b>
						||
						<Translate id={"paid"} />
					))}
				</TableColumn>
				<TableColumn column={"invoice"}>
					{session.invoiceAvailable && <a><Fa icon={"file-pdf"} /> </a>}
				</TableColumn>
			</TableRow>
		);
	}

	render() {
		const {Thunk_Sessions_Fetch_All} = this.props;

		return (
			<section className={"sessions-list"}>
				<ListingPage
					titleIcon={T_APP_ICONS.SESSIONS}
					titleText={"sessions"}
					subscriberThunk={Thunk_Sessions_Fetch_All}
					subscriberRootSelector={Selector_Sessions_Listing_Root}
					headerItems={{
						icon: {
							label: '',
							width: "2em",
							sort: false,
							filter: false,
							metaLabel: "sessionStateIcon"
						},
						state:{sort: false, filter: false},
						sessionType: {
							sort: false,
							filter: false,
							visible: false,
						},
						email: {
							sort: T_SortType.TEXT,
							field: "user.email",
							restricted: T_RestrictionPresets.USERS,
						},
						startedAt: {},
						endedAt: {},
						customID: {},
						location: {
							field: "service.storageBlock.frame.storageUnit.location.name",
							restricted: T_RestrictionPresets.LOCATIONS,
						},
						service: {
							field: "service.physicalReference",
							width: "5em",
							restricted: T_RestrictionPresets.LOCATIONS,
						},
						unpaidAmount: {
							filter: false,
							sort: false,
						},
						invoice: {
							label: "",
							icon: "file-pdf",
							width: "2em",
							filter: false,
							sort: false,
							visible: false,
							canModify: false,//TODO: Remove this when invoice download is implemented
						},
						invoiceEmail: {
							visible: false,
							canModify: false,
						},
						phone: {
							visible: false,
							canModify: false,
						},
					}}
					subscriberFilters={{
						service: {
							icon: "tag",
							label: "name",
							field: "service.id",
							type: "text",
							hidden: true,
							operator: "=",
						},
						visibility: {
							label: "state",
							field: "state",
							type: <Dropdown
								options={EO(E_SessionState).toArray(true)}
								onLabel={item => window.translator.translate(`state_${item}`)}
							/>
						}
					}}
					subscriberSort={"id,desc"}
					views={[
						{
							view: "table",
							content: <TableListing name={"sessions"} className={"stripes"} itemTemplate={this._renderTemplate.bind(this)} canModifyColumns={true} />
						},
					]}
					showExport={true}
					onExportService={(query) => service_downloadSessionsExport(query)}
				/>
			</section>
		);
	}

	_getSessionIcon(session) {
		if(session.sessionType == E_SessionType.DELIVERY_COURIER) {
			return <Fa icon={"truck"} />
		}

		if(session.sessionType == E_SessionType.DELIVERY_CUSTOMER) {
			return <Fa icon={"cs-box-check"} />;
		}

		switch (session.state) {
			case E_SessionState.RESERVATION_WAITING:
				return <Fa icon={"hourglass-start"} />;
			case E_SessionState.COMPLETED:
				return <Fa icon={"check"} />;
			case E_SessionState.PENDING:
				return <Fa icon={"clock"} />;
			case E_SessionState.ACTIVE:
				return <Fa icon={"minus"} />
			case E_SessionState.CANCELED:
				return <Fa icon={"times"} style={css(`color: var(--state-error-color)`)} />
		}
	}
}

SessionsList.propTypes = {
	Thunk_Session_Insert: PropTypes.func.isRequired,
	Thunk_Sessions_Fetch_All: PropTypes.func.isRequired,
};

const mapDispatchToProps = dispatch => (
	bindActionCreators({
		Thunk_Session_Insert,
		Thunk_Sessions_Fetch_All,
	}, dispatch)
);

export default connect(null, mapDispatchToProps)(withRouter(withLocalize(SessionsList)));
