export const Selector_Authorizations_Root = state => {
	return state.authorizations;
};

export const Selector_Authorizations_Listing_Root = state => {
	return Selector_Authorizations_Root(state).listing;
};

export const Selector_Authorizations_Listing_Data = state => {
	return Selector_Authorizations_Listing_Root(state).data;
};

export const Selector_Authorization_Root = state => {
	return Selector_Authorizations_Root(state).item;
};

export const Selector_Authorization_Data = state => {
	return Selector_Authorization_Root(state).data;
};

export const Selector_Authorization_Edit = state => {
	return Selector_Authorization_Root(state).edit;
};
