import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { Translate } from "react-localize-redux";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { E_CommunicationUnitType } from "../../../../models/Models_CommunicationUnit";
import { Action_CommunicationUnit_Edit_Cancel, Action_CommunicationUnit_Edit_Init, Action_CommunicationUnit_Edit_Modify } from "../../../../store/actions/location/communicationUnitActions";
import { Selector_CommunicationUnit_Edit } from "../../../../store/selectors/Selectors_CommunicationUnit";
import { EO } from "../../../../utils/extensions";
import Dropdown from "../../../shared/Input/Dropdown";
import TextInput from "../../../shared/Input/TextInput";
import Fa from "../../../tools/Icons/Fa";
import { T_APP_ICONS } from "../../../../models/constants/Constants_Shared";
import { formatAsDate } from "../../../../utils/utils";
import ToggleButton from "../../../shared/Input/ToggleButton";

class CommunicationUnitEditModal extends React.Component {
	constructor(props) {
		super(props);

		this.state = {};
	}

	componentDidMount() {
		this.props.Action_CommunicationUnit_Edit_Init();
	}

	componentWillUnmount() {
		this.props.Action_CommunicationUnit_Edit_Cancel();
	}

	render() {
		const {edit, Action_CommunicationUnit_Edit_Modify} = this.props;

		return (
			<section>
				<div className={"grid-table grid-icon-auto-1fr"}>
					{
						edit.createdOn &&
						<Fragment>
							<Fa icon={T_APP_ICONS.CREATED_ON}/>
							<label><Translate id={"createdOn"}/></label>
							<span>{formatAsDate(edit.createdOn)}</span>
						</Fragment>
					}

					<Fa icon={"tag"} />
					<label><Translate id={`name`} /></label>
					<TextInput
						path={`name`}
						defaultValue={edit.name || ''}
						onModify={Action_CommunicationUnit_Edit_Modify}
					/>

					<Fa icon={"cash-register"} />
					<label><Translate id={`paymentTerminalIpAddress`} /></label>
					<TextInput
						path={`paymentTerminalIpAddress`}
						defaultValue={edit.paymentTerminalIpAddress || ''}
						onModify={Action_CommunicationUnit_Edit_Modify}
					/>

					<Fa icon={"microchip"} />
					<label><Translate id={`type`} /></label>
					<Translate>{({translate}) => (
						<Dropdown
							options={EO(E_CommunicationUnitType).toArray(true)}
							onModify={Action_CommunicationUnit_Edit_Modify}
							path={`type`}
							defaultValue={edit.type || ''}
							onLabel={item => item && translate(`communicationUnitType_${item}`, undefined, {onMissingTranslation: () => item})}
						/>
					)}
					</Translate>

					<Fa icon={"satellite-dish"} />
					<label><Translate id={`unitIpAddress`} /></label>
					<TextInput
						path={`unitIpAddress`}
						defaultValue={edit.unitIpAddress || ''}
						onModify={Action_CommunicationUnit_Edit_Modify}
					/>

					<Fa icon={"barcode"} />
					<label><Translate id={`unitSerialNumber`} /></label>
					<TextInput
						path={`unitSerialNumber`}
						defaultValue={edit.unitSerialNumber || ''}
						onModify={Action_CommunicationUnit_Edit_Modify}
					/>

					<Fa icon={"camera"} />
					<label><Translate id={`cameraSupported`} /></label>
					<ToggleButton
						path={`cameraSupported`}
						defaultValue={edit.cameraSupported || false}
						onModify={Action_CommunicationUnit_Edit_Modify}
					/>
				</div>
			</section>
		)
	}
}

CommunicationUnitEditModal.propTypes = {
	edit: PropTypes.object.isRequired,

	Action_CommunicationUnit_Edit_Modify: PropTypes.func.isRequired,
	Action_CommunicationUnit_Edit_Init: PropTypes.func.isRequired,
	Action_CommunicationUnit_Edit_Cancel: PropTypes.func.isRequired,
};

CommunicationUnitEditModal.stateTypes = {};

const mapStateToProps = state => {
	const edit = Selector_CommunicationUnit_Edit(state);

	return {
		edit
	};
};
const mapDispatchToProps = dispatch => (
	bindActionCreators({
		Action_CommunicationUnit_Edit_Modify,
		Action_CommunicationUnit_Edit_Init,
		Action_CommunicationUnit_Edit_Cancel,
	}, dispatch)
);

export default connect(mapStateToProps, mapDispatchToProps)(CommunicationUnitEditModal);
