import React, {Fragment} from "react";
import PropTypes from "prop-types";
import {T_AddressModel, T_SharedTippyProps} from "../../models/Models_Shared";
import {CONSOLE_ERROR_BOLD} from "../../models/constants/Constants_Shared";
import Fa from "../tools/Icons/Fa";
import {Translate} from "react-localize-redux";
import TextInput from "./Input/TextInput";
import {css, get} from "../../utils/utils";
import NumberInput from "./Input/NumberInput";
import {EO} from "../../utils/extensions";
import is from "../../utils/is";
import CoordsInput from "./Input/CoordsInput";
import Tippy from "./Tippy";

class Address extends React.Component {
	constructor(props) {
		super(props);
	}

	_renderPhone(data, icon = "phone", label = "phoneNumber", addressComponentPath = "phoneNumber") {
		const {onModify, path, disabled} = this.props;
		const phoneNumber = get(data, addressComponentPath);

		return (
			<Fragment>
				<Fa icon={icon} />
				<label><Translate id={label} /></label>
				{this._isEditSwitch(
					<span>{phoneNumber ? <a href={`tel:${phoneNumber}`}>{phoneNumber} <Fa icon={"external-link-alt"} /></a> : <Translate id="value_notProvided" />}</span>,
					<TextInput
						defaultValue={get(data, addressComponentPath)}
						path={`${path}.${addressComponentPath}`}
						onModify={onModify}
						disabled={disabled}
					/>
				)}
			</Fragment>
		)
	}

	_renderUrl(data, icon = "link", label = "address_url", addressComponentPath = "url") {
		const {onModify, path, disabled} = this.props;
		const url = get(data, addressComponentPath);

		return (
			<Fragment>
				<Fa icon={icon} />
				<label><Translate id={label} /></label>
				{this._isEditSwitch(
					<span>{url ? <a href={url}>{url} <Fa icon={"external-link-alt"} /></a> : <Translate id="value_notProvided" />}</span>,
					<TextInput
						defaultValue={url}
						path={`${path}.${addressComponentPath}`}
						onModify={onModify}
						disabled={disabled}
					/>
				)}
			</Fragment>
		)
	}

	_renderPlainString(data, icon, label, addressComponentPath) {
		const {onModify, path, disabled} = this.props;

		return (
			<Fragment>
				{icon ? <Fa icon={icon}/> : <div/>}
				<label><Translate id={label} /></label>
				{this._isEditSwitch(
					<span>{get(data, addressComponentPath, <Translate id="value_notProvided" />)}</span>,
					<TextInput
						defaultValue={get(data, addressComponentPath)}
						path={`${path}.${addressComponentPath}`}
						onModify={onModify}
						disabled={disabled}
					/>
				)}
			</Fragment>
		)
	}

	_renderPlainNumber(data, icon, label, addressComponentPath) {
		const {onModify, path, disabled} = this.props;

		return (
			<Fragment>
				<Fa icon={icon} />
				<label><Translate id={label} /></label>
				{this._isEditSwitch(
					<span>{get(data, addressComponentPath, <Translate id="value_notProvided" />)}</span>,
					<NumberInput
						defaultValue={get(data, addressComponentPath)}
						path={`${path}.${addressComponentPath}`}
						onModify={onModify}
						disabled={disabled}
						wrapInput={true}
					/>
				)}
			</Fragment>
		)
	}

	_renderEmail(data, icon = "at", label = "email", addressComponentPath = "email") {
		const {onModify, path, disabled} = this.props;
		const email = get(data, addressComponentPath);

		return (
			<Fragment>
				<Fa icon={icon} />
				<label><Translate id={label} /></label>
				{this._isEditSwitch(
					<span>{email ? <a href={`mailto:${email}`}>{email} <Fa icon={"external-link-alt"} /></a> : <Translate id="value_notProvided" />}</span>,
					<TextInput
						defaultValue={email}
						path={`${path}.${addressComponentPath}`}
						onModify={onModify}
						disabled={disabled}
					/>
				)}
			</Fragment>
		)
	}

	_renderCoords(data) {
		const {disabled} = this.props;

		return (
			<Fragment>
				<Fa icon={"map-marked-alt"} />
				<label><Translate id={"coords"} /></label>
				{this._isEditSwitch(
					<span>
						{
							data.longitude && data.latitude &&
							<Fragment>
								{data.latitude}, {data.longitude}
								<Tippy
									{...T_SharedTippyProps}
									content={<Translate id={"openMaps"}/>}
								>
									<a
										target={"_blank"}
										href={`https://www.google.com/maps/search/?api=1&query=${data.latitude},${data.longitude}`}
										style={{marginLeft: 5}}
										rel={"noopener noreferrer"}
									>
										<Fa icon={"cs-google-maps"}/>
									</a>
								</Tippy>
							</Fragment>
							||
							<Translate id="value_notProvided" />
						}
					</span>,
					<button
						style={{margin: "5px 0", flexWrap: "wrap"}}
						className={"no-style"}
						onClick={() => this._openSelectCoords()}
						disabled={disabled}
					>
						<TextInput
							defaultValue={data.longitude}
							readonly={true}
							style={{marginBottom: -3}}
						/>
						<TextInput
							defaultValue={data.latitude}
							readonly={true}
							suffix={<Fa icon={"cs-map-marker-edit"}/>}
						/>
					</button>
				)}
			</Fragment>
		)
	}

	_renderAddressComponents() {
		const {model, address, order} = this.props;

		const __getComponent = (type) => {
			switch (type) {
				case "city":
					return data => this._renderPlainString(data, "city", "address_city", "city");
				case "country":
					return data => this._renderPlainString(data, "globe-europe", "address_country", "country");
				case "street":
					return data => this._renderPlainString(data, "road", "address_street", "street");
				case "floor":
					return data => this._renderPlainString(data, "layer-group", "address_floor", "floor");
				case "houseNumber":
					return data => this._renderPlainString(data, "home", "address_houseNumber", "houseNumber");
				case "postalCode":
					return data => this._renderPlainString(data, "mail-bulk", "address_postalCode", "postalCode");
				case "email":
					return data => this._renderEmail(data);
				case "landLine":
					return data => this._renderPhone(data, "cs-phone-office", "landLine", "landLine");
				case "cellPhone":
					return data => this._renderPhone(data, undefined, undefined, "cellPhone");
				case "phoneNumber":
					return data => this._renderPhone(data);
				case "webUrl":
					return data => this._renderUrl(data, undefined, undefined, "webUrl");
				case "url":
					return data => this._renderUrl(data);
				case "coords":
					return data => this._renderCoords(data);
				case "companyName":
					return data => this._renderPlainString(data, "trademark", "companyName", "companyName");
				case "companyRegistrationNumber":
					return data => this._renderPlainString(data, undefined, "companyRegistrationNumber", "companyRegistrationNumber");
				case "contactEmail":
					return data => this._renderEmail(data, undefined, "contactEmail", "contactEmail");
				case "logo":
					return data => this._renderPlainString(data, undefined, "logo", "logo");
				case "taxIDNumber":
					return data => this._renderPlainString(data, undefined, "taxIDNumber", "taxIDNumber");
				case "companyAdditionalInfo":
					return data => this._renderPlainString(data, undefined, "companyAdditionalInfo", "companyAdditionalInfo");
				default:
					return null;
			}
		};

		let fullModel = {...model};
		if(Object.keys(model).includes("longitude") && Object.keys(model).includes("latitude")) {
			fullModel.coords = {long: model.longitude, lat: model.latitude};
			delete fullModel.longitude;
			delete fullModel.latitude;
		}

		return Object.keys(fullModel).sort(
			(a,b) => order.indexOf(a) - order.indexOf(b)
		).map((item, i) => {
			let component = __getComponent(item);
			return <Fragment key={i}>{component && component({...model, ...address})}</Fragment>
		});
	}

	_renderGoogleMapsLink() {
		const {address} = this.props;

		if(address && ["country", "city", "street"].every(component => is.valid(address[component]))) {
			let addressString = EO(address).filter(
				item => ["city", "country", "street", "houseNumber", "postalCode"].includes(item.key),
				true
			).map(item => item.value).join(' ');

			return (
				<a
					className={"fancy full-span invert"}
					target={"_blank"}
					href={`https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(addressString)}`}
					style={css(`padding: 5px; display: flex; justify-content: center; margin-top: 10px`)}
					rel={"noopener noreferrer"}
				>
					<Fa icon={"cs-google-maps"} style={css(`font-size: 1.3em`)} />
					<span><Translate id={"openMaps"} /></span>
				</a>
			)
		}
	}

	_wrapWithSection(content) {
		const {className, wrapWithSection, showGoogleMapsLink} = this.props;

		if(wrapWithSection || className) {
			return (
				<section className={`address ${className} grid-table grid-icon-auto-1fr`}>
					{content}
					{showGoogleMapsLink && this._renderGoogleMapsLink()}
				</section>
			)
		}
		return (
			<Fragment>
				{content}
				{showGoogleMapsLink && this._renderGoogleMapsLink()}
			</Fragment>
		);
	}

	render() {
		return this._wrapWithSection(this._renderAddressComponents());
	}

	_isEditSwitch(normal, edit) {
		const {isEdit} = this.props;
		return isEdit ? edit : normal;
	}

	_openSelectCoords() {
		const {onModify, address, path} = this.props;
		const {longitude, latitude} = address || {};

		window.modal.open({
			body: <CoordsInput
				defaultValue={{
					lng: longitude,
					lat: latitude
				}}
				onModify={onModify}
				path={path}
			/>,
			className: "full-content",
			header: <label><Translate id={"coords"}/></label>
		})
	}

	static get propTypes() {
		return {
			...super.propTypes,
			address: PropTypes.object,
			isEdit: PropTypes.bool,
			onModify: PropTypes.func.isRequired,
			wrapWithSection: PropTypes.bool,
			model: PropTypes.object.isRequired,
			order: PropTypes.array,
			showGoogleMapsLink: PropTypes.bool,
			path: PropTypes.string,
		}
	}

	static get defaultProps() {
		return {
			...super.defaultProps,
			address: T_AddressModel,
			onModify: () => console.log("%conModify is undefined!", CONSOLE_ERROR_BOLD),
			wrapWithSection: true,
			isEdit: false,
			model: {},
			order: ["country", "city", "postalCode", "street", "houseNumber", "floor", "phoneNumber", "url", "email", "coords"],
			showGoogleMapsLink: false,
			path: 'address',
		}
	}
}

export default Address;
