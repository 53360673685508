import React, { Fragment } from 'react'
// eslint-disable-next-line no-unused-vars
import PropTypes from 'prop-types'
import { valid } from "../../../utils/utils";
import InputBase from "./Base/InputBase";
import Fa from "../../tools/Icons/Fa";

class TextInput extends InputBase {
	constructor(props) {
		super(props);

		this.state = {
			...this.state,
			passwordHidden: props.passwordHidden,
			type: props.type || "text",
		}
	}

	render() {
		const {placeholder, path, readOnly, inputMode, disabled, autoComplete, name} = this.props;
		const {passwordHidden, type, value} = this.state;

		return this.renderInputWrapper(
			<Fragment>
				{this.renderPrefix()}
				<input
					type={passwordHidden ? type || "text" : "text"}
					autoComplete={autoComplete ? "on" : "off"}
					inputMode={inputMode}
					placeholder={placeholder}
					className={`text-input`}
					ref={this.setInputRef}
					value={valid(value, '')}
					onChange={e => this.debounceChange(path, e.target.value)}
					disabled={disabled}
					readOnly={readOnly}
					name={name}
				/>
				{
					this.props.type == "password" &&
					<button
						tabIndex={-1}
						className={"password-reveal"}
						type={"button"}
						onClick={() => this.setState(state => ({passwordHidden: !state.passwordHidden}))}
						style={{minWidth: "2em"}}
					>
						<Fa icon={`eye${passwordHidden ? '-slash' : ''}`}/>
					</button>
				}
				{this.renderSuffix()}
				{this.renderInvalidateButton()}
			</Fragment>
		);
	}

	static get propTypes() {
		return {
			...super.propTypes,
			passwordHidden: PropTypes.bool,
			type: PropTypes.string,
			autoComplete: PropTypes.bool,
		}
	}

	static get stateTypes() {
		return {
			...super.stateTypes,
		}
	}

	static get defaultProps() {
		return {
			...super.defaultProps,
			wrapInput: true,
			passwordHidden: true,
		}
	}
}

export default TextInput;
