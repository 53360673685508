export const DEV_API_URL = `https://dev.api.cubesave.cz`;
export const PROD_API_URL = `https://api.cubesave.cz`;

const getApiUrl = () => {
	const apiOverride = localStorage.getItem("api") ?? localStorage.getItem("apiUrl");
	if(apiOverride) return apiOverride

	switch (window.location.hostname) {
		case "app.cubesave.cz":
			return PROD_API_URL;
		default:
			return DEV_API_URL;
	}
};

export const API_URL = getApiUrl();
