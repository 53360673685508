import React, {Fragment} from "react";
import PropTypes from "prop-types";
import {Translate} from "react-localize-redux";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import {bindActionCreators} from "redux";
import {E_Currency, T_APP_ICONS} from "../../../models/constants/Constants_Shared";
import {T_SharedReduxPropTypes} from "../../../models/Models_Shared";
import {T_TariffModel, T_TariffReservationCost, T_TimeBracketModel} from "../../../models/Models_Tariff";
import routes from "../../../routes/routes";
import {Action_Tariff_Clear, Action_Tariff_Edit_Cancel, Action_Tariff_Edit_Init, Action_Tariff_Edit_Modify} from "../../../store/actions/tariffs/tariffsActions";
import {E_Modification} from "../../../store/Constants_StoreShared";
import {Selector_Tariff_Data, Selector_Tariff_Edit, Selector_Tariff_Root} from "../../../store/selectors/Selectors_Tariff";
import {Thunk_Tariff_Fetch, Thunk_Tariff_Remove, Thunk_Tariff_Save} from "../../../store/thunk/Thunk_Tariffs";
import {EO} from "../../../utils/extensions";
import is from "../../../utils/is";
import {formatAsPrice, get, resolveItemName} from "../../../utils/utils";
import ButtonsConstructor from "../../shared/ButtonsConstructor";
import Dropdown from "../../shared/Input/Dropdown";
import NumberInput from "../../shared/Input/NumberInput";
import TextareaInput from "../../shared/Input/TextareaInput";
import TextInput from "../../shared/Input/TextInput";
import ToggleButton from "../../shared/Input/ToggleButton";
import Fa from "../../tools/Icons/Fa";
import "../../../styles/pages/Tariff/TariffDetail.scss";
import Toolbar from "../../shared/Toolbar";
import DetailWrapper from "../../shared/DetailWrapper";
import TariffSelect from "./TariffSelect";
import PartnerSelectInput from "../Partners/PartnerSelectInput";
import ItemRedirect from "../../shared/ItemRedirect";
import {T_RestrictionPresets} from "../../../models/Models_Restrictions";
import Restricted from "../../shared/Restrictions/Restricted";
import TimeInput from "../../shared/Input/TimeInput";
import {E_WeekDays, getLocalizedWeekDay} from "../../../utils/localeUtils";

class TariffDetail extends React.Component {
	constructor(props) {
		super(props);

		this.state = {};
	}

	componentDidMount() {
		const {tariffID, Thunk_Tariff_Fetch, Action_Tariff_Edit_Init} = this.props;

		if (tariffID) {
			Thunk_Tariff_Fetch(tariffID);
		} else {
			Action_Tariff_Edit_Init(T_TariffModel);
		}
	}

	componentWillUnmount() {
		const {Action_Tariff_Clear} = this.props;

		Action_Tariff_Clear();
	}

	_renderToolbar() {
		const {edit, Action_Tariff_Edit_Cancel, tariffID, fetching, failed, Action_Tariff_Edit_Init, Thunk_Tariff_Save} = this.props;
		const isEdit = is.valid(edit);

		return <Toolbar
			section={"tariffs"}
			isEdit={isEdit}
			backUrl={routes.TARIFFS}
			content={<h1><Translate id={tariffID ? (isEdit ? "tariff_edit" : "tariff_detail") : "tariff_create"}/></h1>}
			onEditCancel={() => Action_Tariff_Edit_Cancel()}
			onEdit={() => Action_Tariff_Edit_Init()}
			onSave={() => Thunk_Tariff_Save()}
			onRemove={() => this._openSubstituteTariff()}
			actionButtons={buttons => buttons.map(button => button.key == "remove" ? {
				...button,
				confirm: <span><Fa icon={"trash-alt"} /> <Translate id={"tariff_substitute_select"} /></span>,
			} : button)}
			disableActionButtons={fetching || failed}
			itemID={tariffID}
		/>;
	}

	_renderCurrencySuffix(tariff) {
		return <Translate id={`currency_${tariff.currency || E_Currency.CZK}`} />;
	}

	_renderTariffBracket(bracket, i, tariff) {
		const {Action_Tariff_Edit_Modify} = this.props;

		return (
			<Fragment key={i}>
				{
					/*Property key is "disabled" but context is "enabled" so the inversion is necessary*/
					this._isEditSwitch(
						<span><Translate id={bracket.disabled ? "no" : "yes"} /></span>,
						<ToggleButton
							defaultValue={!bracket.disabled}
							onModify={Action_Tariff_Edit_Modify}
							path={`timeBrackets.${i}.disabled`}
							onValue={v => !v}
							label={"state_ENABLED"}
						/>
					)
				}
				{
					this._isEditSwitch(
						<span>{bracket.maxMinutes} <Translate id={"minutes"/*TODO: Integrate inflex*/} /></span>,
						<NumberInput
							defaultValue={bracket.maxMinutes}
							onModify={Action_Tariff_Edit_Modify}
							path={`timeBrackets.${i}.maxMinutes`}
							wrapInput={true}
							suffix={<Translate id={"minutes"/*TODO: Integrate inflex*/} />}
							behavior={{clamp: {min: 0}}}
						/>
					)
				}
				{
					get(
						bracket.price,
						null,
						<Translate id={"value_notProvided"} />,
						price => (
							<Fragment>
								{
									this._isEditSwitch(
										<span>{formatAsPrice(price, tariff.currency)}</span>,
										<NumberInput
											defaultValue={bracket.price}
											onModify={Action_Tariff_Edit_Modify}
											path={`timeBrackets.${i}.price`}
											wrapInput={true}
											suffix={this._renderCurrencySuffix(tariff)}
											behavior={{clamp: {min: 0}}}
										/>
									)
								}
								<span>{
									this._isEditSwitch(
										null,
										<ButtonsConstructor
											buttons={[
												{
													icon: T_APP_ICONS.CLOSE,
													tippy: <Translate id={"remove"} />,
													className: "remove-button",
													action: () => Action_Tariff_Edit_Modify(E_Modification.ARRAY_SPLICE, `timeBrackets`, [i, 1])
												}
											]}
										/>
									)
								}</span>
							</Fragment>
						)
					)
				}
			</Fragment>
		);
	}

	_renderTariffReservationCost(cost, i, tariff) {
		const {Action_Tariff_Edit_Modify} = this.props;

		return (
			<Fragment key={i}>
				{/* Weekday */}
				{
					this._isEditSwitch(
						<span>{getLocalizedWeekDay(cost.weekDay, "long")}</span>,
						<Dropdown
							defaultValue={cost.weekDay}
							options={Object.values(E_WeekDays)}
							path={`reservationCosts.${i}.weekDay`}
							onModify={Action_Tariff_Edit_Modify}
							onLabel={(v) => getLocalizedWeekDay(v, "long")}
						/>
					)
				}

				{/* From time */}
				{
					this._isEditSwitch(
						<span>{cost.fromTime}</span>,
						<TimeInput
							defaultValue={cost.fromTime}
							onModify={Action_Tariff_Edit_Modify}
							path={`reservationCosts.${i}.fromTime`}
						/>
					)
				}

				{/* To time */}
				{
					this._isEditSwitch(
						<span>{cost.toTime}</span>,
						<TimeInput
							defaultValue={cost.toTime}
							onModify={Action_Tariff_Edit_Modify}
							path={`reservationCosts.${i}.toTime`}
						/>
					)
				}

				{/* Cost */}
				{
					get(
						cost,
						"hourCost",
						<Translate id={"value_notProvided"} />,
						price => (
							this._isEditSwitch(
								<span>{formatAsPrice(price, tariff.currency)}</span>,
								<NumberInput
									defaultValue={cost.hourCost}
									onModify={Action_Tariff_Edit_Modify}
									path={`reservationCosts.${i}.hourCost`}
									wrapInput={true}
									suffix={this._renderCurrencySuffix(tariff)}
									behavior={{clamp: {min: 0}}}
								/>
							)
						)
					)
				}

				<span>{
					this._isEditSwitch(
						null,
						<ButtonsConstructor
							buttons={[
								{
									icon: T_APP_ICONS.CLOSE,
									tippy: <Translate id={"remove"} />,
									className: "remove-button",
									action: () => Action_Tariff_Edit_Modify(E_Modification.ARRAY_SPLICE, `reservationCosts`, [i, 1])
								}
							]}
						/>
					)
				}</span>
			</Fragment>
		);
	}

	_renderTariffBrackets(tariff = T_TariffModel) {
		const {Action_Tariff_Edit_Modify, edit} = this.props;
		const isEdit = is.valid(edit);

		return (
			<Fragment>
				<div className={"separator"}>
					<div />
					<label><Fa icon={"stopwatch"} /><Translate id={"tariff_brackets"} /></label>
					<div>
						{
							isEdit &&
							<button className={"grid-button"} onClick={() => {
								Action_Tariff_Edit_Modify(E_Modification.ARRAY_PUSH, `timeBrackets`, T_TimeBracketModel);
							}}>
								<Fa icon={T_APP_ICONS.ADD} />
								<Translate id={"add"} />
							</button>
						}
					</div>
				</div>
				<div className={"brackets"}>
					{
						<div className={`grid-table no-label-content ${isEdit ? "edit" : ''}`}>
							<label className={"justify-start"}><Translate id={"state_ENABLED"} /></label>
							<label className={"justify-start"}><Translate id={"duration"} /></label>
							<label className={"justify-start"}><Translate id={"price"} /></label>
							<label />
							{
								(tariff.timeBrackets || []).map((bracket, i) => this._renderTariffBracket(bracket, i, tariff))
							}
						</div>
					}
				</div>
			</Fragment>
		);
	}

	_renderTariffReservationCosts(tariff = T_TariffModel) {
		const {Action_Tariff_Edit_Modify, edit} = this.props;
		const isEdit = is.valid(edit);

		return (
			<Fragment>
				<div className={"separator"}>
					<div />
					<label><Fa icon={"user-lock"} /><Translate id={"tariff_reservationCosts"} /></label>
					<div>
						{
							isEdit &&
							<button className={"grid-button"} onClick={() => {
								Action_Tariff_Edit_Modify(E_Modification.ARRAY_PUSH, `reservationCosts`, T_TariffReservationCost);
							}}>
								<Fa icon={T_APP_ICONS.ADD} />
								<Translate id={"add"} />
							</button>
						}
					</div>
				</div>
				<div className={"reservation-costs"}>
					{
						<div className={`grid-table no-label-content ${isEdit ? "edit" : ''}`}>
							<label className={"justify-start"}><Translate id={"day"} /></label>
							<label className={"justify-start"}><Translate id={"from"} /></label>
							<label className={"justify-start"}><Translate id={"to"} /></label>
							<label className={"justify-start"}><Translate id={"price"} /></label>
							<label />
							{
								(tariff.reservationCosts || []).map((cost, i) => this._renderTariffReservationCost(cost, i, tariff))
							}
						</div>
					}
				</div>
			</Fragment>
		);
	}

	_renderTariffData(tariff) {
		const {Action_Tariff_Edit_Modify, edit} = this.props;
		const isEdit = is.valid(edit);
		const priceValue = amount => formatAsPrice(amount, tariff.currency || "CZK", this);

		return (
			<div>
				<div className={"separator"}><label><Fa icon={"info-circle"} /><Translate id={"information"} /></label></div>
				<div className={"grid-table grid-icon-auto-1fr"}>
					<Fa icon={"tag"} />
					<label><Translate id={"name"} /></label>
					{
						this._isEditSwitch(
							<span>{tariff.name || <Translate id={"value_notProvided"} />}</span>,
							<TextInput
								onModify={Action_Tariff_Edit_Modify}
								path={`name`}
								defaultValue={tariff.name}
							/>
						)
					}

					<span />
					<label><Translate id={"tariff_authorizationAmount"} /></label>
					{
						this._isEditSwitch(
							<span>{
								get(
									tariff,
									`authorizationAmount`,
									<Translate id={"value_notProvided"} />,
									priceValue
								)
							}</span>,
							<NumberInput
								wrapInput={true}
								onModify={Action_Tariff_Edit_Modify}
								path={`authorizationAmount`}
								defaultValue={tariff.authorizationAmount}
								suffix={this._renderCurrencySuffix(tariff)}
							/>
						)
					}

					<Fa icon={T_APP_ICONS.CURRENCY} />
					<label><Translate id={"currency"} /></label>
					{
						this._isEditSwitch(
							<span>{tariff.currency ? this._renderCurrencySuffix(tariff) : <Translate id={"value_notProvided"} />}</span>,
							<Dropdown
								onModify={Action_Tariff_Edit_Modify}
								path={`currency`}
								defaultValue={tariff.currency}
								options={EO(E_Currency).toArray(true)}
								onLabel={item => window.translator.translate(`currency_${item}`)}
							/>
						)
					}

					<span />
					<label><Translate id={"tariff_oneTimeCost"} /></label>
					{
						this._isEditSwitch(
							<span>{
								get(
									tariff,
									`oneTimeCost`,
									<Translate id={"value_notProvided"} />,
									priceValue
								)
							}</span>,
							<NumberInput
								wrapInput={true}
								onModify={Action_Tariff_Edit_Modify}
								path={`oneTimeCost`}
								defaultValue={tariff.oneTimeCost}
								suffix={this._renderCurrencySuffix(tariff)}
							/>
						)
					}

					<span />
					<label><Translate id={"tariff_reservationHourCost"} /></label>
					{
						this._isEditSwitch(
							<span>{
								get(
									tariff,
									`reservationHourCost`,
									<Translate id={"value_notProvided"} />,
									priceValue
								)
							}</span>,
							<NumberInput
								wrapInput={true}
								onModify={Action_Tariff_Edit_Modify}
								path={`reservationHourCost`}
								defaultValue={tariff.reservationHourCost}
								suffix={this._renderCurrencySuffix(tariff)}
							/>
						)
					}

					<span />
					<label><Translate id={"tariff_overTimeCost"} /></label>
					{
						this._isEditSwitch(
							<span>{
								get(
									tariff,
									`overtimePenaltyCost`,
									<Translate id={"value_notProvided"} />,
									priceValue
								)
							}</span>,
							<NumberInput
								wrapInput={true}
								onModify={Action_Tariff_Edit_Modify}
								path={`overtimePenaltyCost`}
								defaultValue={tariff.overtimePenaltyCost}
								suffix={this._renderCurrencySuffix(tariff)}
							/>
						)
					}

					<Restricted
						applyRestrictions={isEdit}
						restricted={T_RestrictionPresets.PARTNERS}
					>
						<Fa icon={T_APP_ICONS.PARTNERS} />
						<label><Translate id={"partner"} /></label>
						{
							this._isEditSwitch(
								<span>{
									tariff.partner &&
									<ItemRedirect
										restricted={T_RestrictionPresets.PARTNERS}
										href={`${routes.PARTNERS}/${tariff.partner.id}`}
										wrapChildren={true}
									>
										{resolveItemName(tariff.partner)}
									</ItemRedirect>
									||
									<Translate id={"value_notProvided"} />
								}</span>,
								<PartnerSelectInput
									path={`partner`}
									canInvalidate={true}
									onModify={Action_Tariff_Edit_Modify}
									defaultValue={tariff.partner}
								/>
							)
						}
					</Restricted>

					<span />
					<label><Translate id={"taxPercent"} /></label>
					{
						this._isEditSwitch(
							<span>{tariff.taxPercent || <Translate id={"value_notProvided"} />} {tariff.taxPercent && "%"}</span>,
							<NumberInput
								wrapInput={true}
								onModify={Action_Tariff_Edit_Modify}
								path={`taxPercent`}
								defaultValue={tariff.taxPercent}
								suffix={"%"}
							/>
						)
					}

					<Fa icon={"pen-alt"} />
					<label><Translate id={"description"} /></label>
					{
						this._isEditSwitch(
							<span>{tariff.description || <Translate id={"value_notProvided"} />}</span>,
							<TextareaInput
								onModify={Action_Tariff_Edit_Modify}
								path={`description`}
								defaultValue={tariff.description}
							/>
						)
					}
				</div>
				{(is.valid(tariff.timeBrackets) || isEdit) && this._renderTariffBrackets(tariff)}
				{(is.valid(tariff.reservationCosts ) || isEdit) && this._renderTariffReservationCosts(tariff)}
			</div>
		)
	}

	_renderTariff(tariff) {
		return (
			<Fragment>
				{this._renderToolbar()}
				<div className={"content-wrapper"}>
					{this._renderTariffData(tariff)}
				</div>
			</Fragment>
		)
	}

	render() {
		const {tariff, edit, tariffID} = this.props;

		return (
			<DetailWrapper
				itemID={tariffID}
				dataID={(tariff || {}).id}
				className={"tariff-detail"}
				isEdit={is.valid(edit)}
				listUrl={routes.TARIFFS}
				errorContent={"tariff_fetch_failed"}
				selector={Selector_Tariff_Root}
			>
				{this._renderTariff((is.valid(edit) ? edit : tariff) || T_TariffModel)}
			</DetailWrapper>
		);
	}

	_isEditSwitch(normal, edit) {
		return is.valid(this.props.edit) ? edit : normal;
	}

	_openSubstituteTariff() {
		const {tariffID, Thunk_Tariff_Remove} = this.props;

		window.modal.open({
			body: <TariffSelect
				onModify={(t,p, tariff) => {
					Thunk_Tariff_Remove(tariffID, tariff.id);
					window.modal.close();
				}}
				onRestrict={(tariff) => tariff && tariff.id != tariffID}
				label={"tariff_substitute_selection"}
			/>,
		})
	}
}

TariffDetail.propTypes = {
	...T_SharedReduxPropTypes,

	tariffID: PropTypes.number,
	tariff: PropTypes.object,
	edit: PropTypes.object,

	Thunk_Tariff_Fetch: PropTypes.func.isRequired,
	Action_Tariff_Edit_Cancel: PropTypes.func.isRequired,
	Action_Tariff_Edit_Init: PropTypes.func.isRequired,
	Action_Tariff_Edit_Modify: PropTypes.func.isRequired,
	Thunk_Tariff_Save: PropTypes.func.isRequired,
};

TariffDetail.stateTypes = {};

TariffDetail.defaultProps = {
	tariff: T_TariffModel,
};

const mapStateToProps = state => {
	const {fetched, fetching, failed} = Selector_Tariff_Root(state);
	const tariff = Selector_Tariff_Data(state);
	const edit = Selector_Tariff_Edit(state);

	return {fetched, fetching, failed, tariff, edit}
};

const mapDispatchToProps = dispatch => (
	bindActionCreators({
		Action_Tariff_Edit_Init,
		Thunk_Tariff_Fetch,
		Action_Tariff_Edit_Cancel,
		Action_Tariff_Edit_Modify,
		Thunk_Tariff_Save,
		Action_Tariff_Clear,
		Thunk_Tariff_Remove
	}, dispatch)
);

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(TariffDetail));
