import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { T_SharedPropTypes } from "../../models/Models_Shared";
import { formatAsPrice, resolveDynamicComponent } from "../../utils/utils";
import { Translate } from "react-localize-redux";
import "../../styles/components/SummaryTable.scss";
import is from "../../utils/is";

/**
 * Summary table sections
 * ---
 * @type {
 *   [{
 *       entries: [{
 *           textTranslated: Boolean,
 *           text: String|Element,
 *           value: String|Number|Element
 *       }],
 *       textTranslated: Boolean,
 *       gridClassName: String,
 *       name: String,
 *       format: String,
 *       className: String,
 *       theme: String,
 *       text: String|Element,
 *       notPrice: Boolean,
 *   }]
 * }
 */
export const T_SummaryTableSections = [
	{
		name: '', // Optional, will be ignored if className is set
		text: '', // Label
		textTranslated: false,
		className: '',
		format: "data-table", //Table format
		gridClassName: 'stripes',
		theme: '',
		entries: [
			{
				text: '',
				textTranslated: false,
				value: '',
				notPrice: false,
			}
		]
	}
]

class SummaryTable extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			...super.state,

		};
	}

	render() {
		const {summary, sections, header, children, className, style, currency, footer} = this.props;

		return (
			<section className={`summary-table ${className}`} style={style}>
				{header}
				<div className={"payment-summary"}>
					{summary}
				</div>
				{
					(sections || []).map((section, i) => (
						<div key={i} className={section.className || section.name}>
							<label>{resolveDynamicComponent(section.text, section.textTranslated ? section.text : <Translate id={section.text}/>)}</label>

							<div className={`grid-table ${section.format || "data-table"} ${section.gridClassName || "stripes"} ${section.theme ? "theme-" + section.theme : ''}`}>
								{
									(section.entries || []).filter(entry => !!entry).map((entry, j) => (
										<Fragment key={j}>
											<label>{resolveDynamicComponent(entry.text, entry.textTranslated ? entry.text : <Translate id={entry.text}/>)}</label>
											<span className={"with-suffix"}>
												{
													(is.string(entry.value) || is.number(entry.value)) &&
													!entry.notPrice &&
													(entry.currency || currency) &&
													formatAsPrice(entry.value, entry.currency || currency, this)
													||
													entry.value
												}
											</span>
										</Fragment>
									))
								}
							</div>
						</div>
					))
				}
				{footer}
				{children}
			</section>
		)
	}

	static get propTypes() {
		return {
			...super.propTypes,
			...T_SharedPropTypes,
			summary: PropTypes.any,
			sections: PropTypes.array.isRequired,
			currency: PropTypes.string,
			header: PropTypes.any,
			footer: PropTypes.any,
		}
	}

	static get stateTypes() {
		return {
			...super.stateTypes,
		}
	}

	static get defaultProps() {
		return {
			...super.defaultProps,
			summary: "",
			/** @type {T_SummaryTableSections} **/
			sections: [],
		}
	}
}

export default SummaryTable;
