import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { Translate } from "react-localize-redux";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import { T_SharedReduxPropTypes } from "../../../models/Models_Shared";
import { T_ServiceCategory } from "../../../models/Models_StorageService";
import routes from "../../../routes/routes";
import { Action_ServiceCategory_Clear, Action_ServiceCategory_Edit_Cancel, Action_ServiceCategory_Edit_Init, Action_ServiceCategory_Edit_Modify } from "../../../store/actions/configuration/serviceCategoriesActions";
import { Selector_ServiceCategory_Data, Selector_ServiceCategory_Edit, Selector_ServiceCategory_Root } from "../../../store/selectors/Selectors_ServiceCategories";
import { Thunk_ServiceCategory_Fetch, Thunk_ServiceCategory_Remove, Thunk_ServiceCategory_Save } from "../../../store/thunk/Thunk_ServiceCategories";
import is from "../../../utils/is";
import { TranslateEntry } from "../../../utils/localeUtils";
import TextareaInput from "../../shared/Input/TextareaInput";
import TextInput from "../../shared/Input/TextInput";
import Fa from "../../tools/Icons/Fa";
import LanguageSwitch from "../../tools/Language/LanguageSwitch";
import MorphElement from "../../tools/MorphElement";
import Toolbar from "../../shared/Toolbar";
import DetailWrapper from "../../shared/DetailWrapper";
import Restricted from "../../shared/Restrictions/Restricted";
import { T_RestrictionPresets } from "../../../models/Models_Restrictions";
import { T_APP_ICONS } from "../../../models/constants/Constants_Shared";
import { resolveItemName } from "../../../utils/utils";
import ItemRedirect from "../../shared/ItemRedirect";
import PartnerSelectInput from "../Partners/PartnerSelectInput";

class ServiceCategoryDetail extends React.Component {
	constructor(props) {
		super(props);

		this.state = {};
	}

	componentDidMount() {
		const {serviceCategoryID, Thunk_ServiceCategory_Fetch, Action_ServiceCategory_Edit_Init} = this.props;

		if (serviceCategoryID) {
			Thunk_ServiceCategory_Fetch(serviceCategoryID);
		} else {
			Action_ServiceCategory_Edit_Init(T_ServiceCategory);
		}
	}

	componentWillUnmount() {
		const {Action_ServiceCategory_Clear} = this.props;

		Action_ServiceCategory_Clear();
	}

	_renderToolbar() {
		const {edit, Action_ServiceCategory_Edit_Cancel, Thunk_ServiceCategory_Remove, serviceCategoryID, fetching, failed, Action_ServiceCategory_Edit_Init, Thunk_ServiceCategory_Save} = this.props;
		const isEdit = is.valid(edit);

		return <Toolbar
			section={"globalConfiguration"}
			isEdit={isEdit}
			backUrl={routes.SERVICE_CATEGORIES}
			content={<h1><Translate id={serviceCategoryID ? (isEdit ? "serviceCategory_edit" : "serviceCategory_detail") : "serviceCategory_create"}/></h1>}
			onEditCancel={() => Action_ServiceCategory_Edit_Cancel()}
			onEdit={() => Action_ServiceCategory_Edit_Init()}
			onSave={() => Thunk_ServiceCategory_Save()}
			onRemove={() => Thunk_ServiceCategory_Remove(serviceCategoryID)}
			disableActionButtons={fetching || failed}
			itemID={serviceCategoryID}
		/>;
	}

	_renderServiceCategoryData(serviceCategory) {
		const {edit, Action_ServiceCategory_Edit_Modify} = this.props;
		const isEdit = is.valid(edit);

		return (
			<div className={`content-wrapper`}>
				<div className={"grid-table grid-icon-auto-1fr"}>
					<Fa icon={"tag"} />
					<label><Translate id={"name"} /></label>
					{
						this._isEditSwitch(
							<span>{serviceCategory.name || <Translate id={"value_unknown"} />}</span>,
							<TextInput
								onModify={Action_ServiceCategory_Edit_Modify}
								defaultValue={serviceCategory.name}
								path={`name`}
							/>
						)
					}
					{
						is.empty(serviceCategory.titles) && !isEdit &&
						<Fragment>
							<span />
							<label><Translate id={"titles"} /></label>
							<span><Translate id={"value_unknown"} /></span>
						</Fragment>
					}
					{
						is.empty(serviceCategory.descriptions) && !isEdit &&
						<Fragment>
							<span />
							<label><Translate id={"description"} /></label>
							<span><Translate id={"value_unknown"} /></span>
						</Fragment>
					}
					<Restricted
						noPartner={true}
						restricted={T_RestrictionPresets.PARTNERS}
						applyRestrictions={isEdit}
					>
						<Fa icon={T_APP_ICONS.PARTNERS} />
						<label><Translate id={"partner"} /></label>
						{
							this._isEditSwitch(
								<span>{
									serviceCategory.partner &&
									<ItemRedirect
										href={`${routes.PARTNERS}/${serviceCategory.partner.id}`}
										wrapChildren={true}
										restricted={T_RestrictionPresets.PARTNERS}
									>
										{resolveItemName(serviceCategory.partner)}
									</ItemRedirect>
									||
									<Translate id={"value_notProvided"}/>
								}</span>,
								<PartnerSelectInput
									path={`partner`}
									canInvalidate={true}
									onModify={Action_ServiceCategory_Edit_Modify}
									defaultValue={serviceCategory.partner}
								/>
							)
						}
					</Restricted>
				</div>
				<div>
					<MorphElement
						label={<h4><Fa icon={"tags"} /><Translate id="titles" /></h4>}
						condition={!isEdit}
						pass={
							<TranslateEntry entry={serviceCategory.titles} showAll={true} />
						}
						fail={
							<LanguageSwitch
								path={"titles"}
								value={serviceCategory.titles}
								onModify={Action_ServiceCategory_Edit_Modify}
							>
								<TextareaInput />
							</LanguageSwitch>
						}
					/>
				</div>

				<div>
					<MorphElement
						label={<h4><Fa icon={"pen-fancy"} /><Translate id="description" /></h4>}
						condition={!isEdit}
						pass={
							<TranslateEntry entry={serviceCategory.descriptions} showAll={true} />
						}
						fail={
							<LanguageSwitch
								path={"descriptions"}
								value={serviceCategory.descriptions}
								onModify={Action_ServiceCategory_Edit_Modify}
							>
								<TextareaInput />
							</LanguageSwitch>
						}
					/>
				</div>
			</div>
		)
	}

	_renderServiceCategory(serviceCategory) {
		return (
			<Fragment>
				{this._renderToolbar()}
				<div className={"service-category"}>
					{this._renderServiceCategoryData(serviceCategory)}
				</div>
			</Fragment>
		);
	}

	render() {
		const {serviceCategory, edit, serviceCategoryID} = this.props;

		return (
			<DetailWrapper
				itemID={serviceCategoryID}
				dataID={(serviceCategory || {}).id}
				className={"serviceCategory-detail"}
				isEdit={is.valid(edit)}
				listUrl={routes.SERVICE_CATEGORIES}
				errorContent={"serviceCategory_fetch_failed"}
				selector={Selector_ServiceCategory_Root}
			>
				{this._renderServiceCategory((is.valid(edit) ? edit : serviceCategory) || T_ServiceCategory)}
			</DetailWrapper>
		)
	}

	_isEditSwitch(normal, edit) {
		return is.valid(this.props.edit) ? edit : normal;
	}
}

ServiceCategoryDetail.propTypes = {
	...T_SharedReduxPropTypes,

	serviceCategory: PropTypes.object.isRequired
};

ServiceCategoryDetail.stateTypes = {};

ServiceCategoryDetail.defaultProps = {
	serviceCategory: T_ServiceCategory,

	Action_ServiceCategory_Edit_Init: PropTypes.func.isRequired,
	Action_ServiceCategory_Edit_Cancel: PropTypes.func.isRequired,
	Action_ServiceCategory_Edit_Modify: PropTypes.func.isRequired,
	Thunk_ServiceCategory_Fetch: PropTypes.func.isRequired,
	Thunk_ServiceCategory_Save: PropTypes.func.isRequired,
	Thunk_ServiceCategory_Remove: PropTypes.func.isRequired,
};

const mapStateToProps = state => {
	const {fetched, fetching, failed} = Selector_ServiceCategory_Root(state);
	const serviceCategory = Selector_ServiceCategory_Data(state);
	const edit = Selector_ServiceCategory_Edit(state);

	return {fetched, fetching, failed, serviceCategory, edit}
};

const mapDispatchToProps = dispatch => (
	bindActionCreators({
		Action_ServiceCategory_Edit_Init,
		Action_ServiceCategory_Edit_Cancel,
		Action_ServiceCategory_Edit_Modify,
		Thunk_ServiceCategory_Fetch,
		Thunk_ServiceCategory_Save,
		Thunk_ServiceCategory_Remove,
		Action_ServiceCategory_Clear
	}, dispatch)
);

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ServiceCategoryDetail));
