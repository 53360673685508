import React from 'react';
import {Route, Switch} from 'react-router-dom';
import routes from '../../../routes/routes';
import AuthorizationsDetail from "./AuthorizationsDetail";
import AuthorizationsList from "./AuthorizationsList";


class UserBrowser extends React.Component {

	constructor(props) {
		super(props);
	}

	render() {
		return (
			<Switch>
				<Route exact path={routes.AUTHORIZATIONS}>
					<AuthorizationsList />
				</Route>
				<Route path={`${routes.AUTHORIZATIONS}/:authorizationID`} render={props => <AuthorizationsDetail authorizationID={parseInt(props.match.params.authorizationID)} />} />
			</Switch>
		);
	}
}

export default UserBrowser;
