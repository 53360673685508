import React from "react";
// eslint-disable-next-line no-unused-vars
import PropTypes from "prop-types";
import { hasAccess, hasPermissions, hasRole } from "../../../utils/restrictionUtils";
import { connect } from "react-redux";
import { Selector_Auth_Principal } from "../../../store/selectors/Selectors_Auth";
import { E_UserRoles } from "../../../models/constants/Constants_Shared";

/**
 * A public higher-order component to access the imperative API
 */
export const withRestrictions = Component => {
	class WithRestrictions extends React.Component {
		render() {
			return (
				<Component
					hasAccess={this.hasAccess.bind(this)}
					hasRole={this.hasRole.bind(this)}
					hasPermissions={this.hasPermissions.bind(this)}
					{...this.props}
				/>
			);
		}

		hasAccess(testRole, testPermissions, testNoPartner, testNoRegion) {
			const {principalPermissions} = this.props;

			return hasAccess(
				principalPermissions || {},
				testRole,
				testPermissions,
				testNoPartner,
				testNoRegion,
			);
		}

		hasRole(testRole) {
			const {principalPermissions} = this.props;

			return hasRole(
				principalPermissions ? principalPermissions.role : E_UserRoles.ANONYMOUS,
				testRole,
			);
		}

		hasPermissions(testPermissions) {
			const {principalPermissions} = this.props;

			return hasPermissions(
				principalPermissions || {},
				testPermissions,
			);
		}

		static get displayName() {
			return "withRestrictions(" + (Component.displayName || Component.name) + ")"
		}

		static get wrappedComponent() {
			return Component;
		}

		static get propTypes() {
			return {
				...super.propTypes,
				principalPermissions: PropTypes.object,
			}
		}

		static get defaultProps() {
			return {
				...super.defaultProps,
				principalPermissions: {},
			}
		}
	}

	const mapStateToProps = state => {
		const {permissions: principalPermissions} = Selector_Auth_Principal(state) || {};

		return {principalPermissions};
	};

	return connect(mapStateToProps)(WithRestrictions);
};

export default withRestrictions;
