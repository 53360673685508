import { E_Modification } from "../store/Constants_StoreShared";
import { get } from "./utils";

export const handleGenericModificationFulfilled = (state, action, deleteState, modificationState) => {
	if (get(action, `payload.type`) === E_Modification.ITEM_REMOVE) { //Handle delete
		return deleteState;
	}

	return modificationState;
}
