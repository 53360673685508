import PropTypes from 'prop-types';
import React from 'react';
import { Translate } from "react-localize-redux";
import { connect } from "react-redux";
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from "redux";
import { E_Currency, T_APP_ICONS } from "../../../models/constants/Constants_Shared";
import routes from '../../../routes/routes';
import { Selector_Tariffs_Listing_Root } from "../../../store/selectors/Selectors_Tariff";
import { Thunk_Tariff_Insert, Thunk_Tariffs_Fetch_All } from "../../../store/thunk/Thunk_Tariffs";
import {downloadFile, formatAsPrice, get, resolveItemName} from "../../../utils/utils";
import CSBaseComponent from "../../CSBaseComponent";
import TableListing from "../../shared/Listing/TableListing";
import ListingPage from "../../shared/Listing/ListingPage";
import TableRow from "../../shared/Listing/TableRow";
import TableColumn from "../../shared/Listing/TableColumn";
import ItemRedirect from "../../shared/ItemRedirect";
import { T_RestrictionPresets } from "../../../models/Models_Restrictions";
import {service_downloadTariffExport} from "../../../services/Service_Tariffs";

class TariffsList extends CSBaseComponent {
	constructor(props) {
		super(props);

		this.state = {
			...super.state,
		};
	}

	_renderTemplate(tariff, i, headerItems) {
		return (
			<TableRow key={i} href={`${routes.TARIFFS}/${tariff.id}`} headerItems={headerItems}>
				<TableColumn column={"name"}>{tariff.name || <Translate id={"value_unknown"} />}</TableColumn>
				<TableColumn column={"oneTimeCost"}>{formatAsPrice(tariff.oneTimeCost, tariff.currency || E_Currency.CZK)}</TableColumn>
				<TableColumn column={"overtimePenaltyCost"}>{formatAsPrice(tariff.overtimePenaltyCost, tariff.currency || E_Currency.CZK)}</TableColumn>
				<TableColumn column={"partner"}>
					{
						get(tariff, `partner.id`) &&
						<ItemRedirect href={`${routes.PARTNERS}/${tariff.partner.id}`}>
							{resolveItemName(tariff.partner)}
						</ItemRedirect>
						||
						'-'
					}
				</TableColumn>
				<TableColumn column={"taxPercent"}>{get(tariff, `taxPercent`, '-', v => v+" %")}</TableColumn>
			</TableRow>
		);
	}

	render() {
		const {Thunk_Tariffs_Fetch_All} = this.props;

		return (
			<section className={"location-list"}>
				<ListingPage
					section={"tariffs"}
					titleIcon={T_APP_ICONS.TARIFFS}
					titleText={"tariffs"}
					titleLeftContent={routes.TARIFFS}
					subscriberThunk={Thunk_Tariffs_Fetch_All}
					subscriberRootSelector={Selector_Tariffs_Listing_Root}
					headerItems={{
						name: {},
						oneTimeCost: {
							label: "tariff_oneTimeCost"
						},
						overtimePenaltyCost: {
							label: "tariff_overTimeCost"
						},
						partner: {
							field: "partner.id",
							restricted: T_RestrictionPresets.PARTNERS,
						},
						taxPercent: {
							width: 2
						},
					}}
					views={[
						{
							view: "table",
							default: true,
							button: {
								icon: "table",
								tippy: <Translate id={"viewMode_table"} />
							},
							content: <TableListing className={"stripes"} itemTemplate={this._renderTemplate.bind(this)} />
						},
					]}
					showExport={true}
					onExportService={(query) => service_downloadTariffExport(query)}
				/>
			</section>
		);
	}
}

TariffsList.propTypes = {
	Thunk_Tariff_Insert: PropTypes.func.isRequired,
	Thunk_Tariffs_Fetch_All: PropTypes.func.isRequired,
};

const mapDispatchToProps = dispatch => (
	bindActionCreators({
		Thunk_Tariff_Insert,
		Thunk_Tariffs_Fetch_All,
	}, dispatch)
);

export default connect(null, mapDispatchToProps)(withRouter(TariffsList));
