import React from 'react'
// eslint-disable-next-line no-unused-vars
import PropTypes from 'prop-types'
import { Translate, withLocalize } from "react-localize-redux";
import '../../../styles/components/LanguageSwitch.scss';
import { T_APP_ICONS } from "../../../models/constants/Constants_Shared";
import { T_DisplayText } from "../../../models/Models_Shared";
import { E_Modification } from "../../../store/Constants_StoreShared";
import { Thunk_Language_Fetch_All } from "../../../store/thunk/Thunk_Language";
import { get } from '../../../utils/utils';
import ButtonsConstructor from "../../shared/ButtonsConstructor";
import Fa from "../Icons/Fa";
import is from "../../../utils/is";
import OverflowMenu from "../OverflowMenu";
import CSBaseComponent from "../../CSBaseComponent";
import Dropdown from "../../shared/Input/Dropdown";

class LanguageSwitch extends CSBaseComponent {
	constructor(props) {
		super(props);

		this.state = {
			activeTab: 0,
			availableLanguages: [],
		};
	}

	componentDidMount() {
		super.componentDidMount();

		window.Database.get(state => state.language.data, Thunk_Language_Fetch_All).then(
			data => this._isMounted && this.setState({availableLanguages: data})
		);
	}

	_renderLanguageOptionsButtons() {
		const {path, onModify, value} = this.props;
		const {availableLanguages} = this.state;

		let unusedLanguages = availableLanguages.filter(
			lang => !(value && value.find(item => item.languageCode == lang.languageCode))
		);

		return (
			<div className={"language-options-select"}>
				{unusedLanguages.map((lang, i) => {
					return (
						<button
							key={i}
							onClick={() => {
								onModify(
									E_Modification.ARRAY_PUSH,
									path,
									{...T_DisplayText, languageCode: lang.languageCode}
								);
								this.tippyInstance && this.tippyInstance.hide();
							}}
						>
							{lang.displayName}
						</button>
					)
				})}
			</div>
		);
	}

	render() {
		const {label, path, value, onModify, children} = this.props;
		const {activeTab, availableLanguages} = this.state;

		let unusedLanguages = availableLanguages.filter(
			lang => !(value && value.find(item => item.languageCode == lang.languageCode))
		);

		return (
			<section className={"language-switch"}>
				<div className={"header"}>
					{label}
					<OverflowMenu
						suffix={
							!!unusedLanguages.length &&
							<Dropdown
								options={unusedLanguages}
								onLabel={lang => lang && lang.displayName}
								isActive={() => false}
								onModify={(t,p,lang) => {
									onModify(
										E_Modification.ARRAY_PUSH,
										path,
										{
											...T_DisplayText,
											languageCode: lang.languageCode
										}
									);
								}}
							>
								<button>
									<Fa icon={T_APP_ICONS.ADD} />
								</button>
							</Dropdown>
						}
					>
						<ButtonsConstructor
							buttons={
								availableLanguages.filter(
									lang => value && value.find(item => item.languageCode == lang.languageCode)
								).map(lang => {
									let dataIndex = value.findIndex(item => item.languageCode == lang.languageCode);

									return {
										text: (
											<label>
												{lang.displayName}
												<a onClick={() => {
													onModify(E_Modification.ITEM_REMOVE, `${path}.${dataIndex}`);
													if(dataIndex == activeTab) {
														setTimeout(() => {
															this._isMounted && this.setState({
																activeTab: 0
															});
														}, 0);
													}
												}}>
													<Fa icon={T_APP_ICONS.CLOSE} />
												</a>
											</label>
										),
										action: () => this._isMounted && this.setState({activeTab: dataIndex}),
										active: get(value, `${activeTab}.languageCode`) == lang.languageCode,
									}
								})
							}
						/>
					</OverflowMenu>
				</div>
				<div className={"language-container"}>
					{
						is.valid(value) &&
						React.Children.toArray(children).map(child => {
							let props = {
								...child.props,
								path: `${path}.${activeTab}.text`,
								defaultValue: get(value, `${activeTab}.text`, ""),
								onModify: onModify
							};

							// noinspection JSCheckFunctionSignatures
							return React.cloneElement(child, props);
						})
						||
						<span className={"no-value"}>
							<Translate id={"languageSwitch_noTranslation"} />
						</span>
					}
				</div>
			</section>
		);
	}

}

LanguageSwitch.propTypes = {
	label: PropTypes.object,

	children: PropTypes.element.isRequired,
	onModify: PropTypes.func.isRequired,

	//Value and path for passed children to handle
	value: PropTypes.any,
	path: PropTypes.string,

	// React Localize Redux props
	languages: PropTypes.array.isRequired
};

LanguageSwitch.stateTypes = {
	activeTab: PropTypes.number.isRequired,
	availableLanguages: PropTypes.array,
};

export default withLocalize(LanguageSwitch);
