import React, { Component } from 'react';
import { css } from "../../utils/utils";
import Fa from "../tools/Icons/Fa";

class HomePage extends Component {
	render() {
		return (
			<div style={css("display: flex;width: 100%")}>
				<h1 style={css("margin: auto; font-size: 5em")}><Fa icon={"construction"} custom={true}/> <label>V přípravě</label></h1>
			</div>
		);
	}
}

HomePage.propTypes = {
};


export default HomePage;
