import React from "react";
import PropTypes from "prop-types";
import { T_StorageUnitSharedProps } from "../../../../models/Models_StorageBox";
import StorageFrame from "./StorageFrame";
import { get, sortBySortOrder } from "../../../../utils/utils";
import CSBaseComponent from "../../../CSBaseComponent";

class StorageUnitRow extends CSBaseComponent {
	constructor(props) {
		super(props);

		this.state = {
			...this.state,

		};
	}

	componentDidMount() {
		super.componentDidMount();

		function __horizontalScroll(e) { // this = reference to this.wrapperSOME_SIDE not this class!
			// noinspection JSSuspiciousNameCombination
			this.scrollLeft += e.deltaY;
			if (this.scrollLeft != 0) {
				e.preventDefault();
			}
		}

		this.scroll = this.root.addEventListener("mousewheel", __horizontalScroll);
	}

	render() {
		const {className, style, frames, customProps, scrollEnabled} = this.props;
		const {isEdit} = customProps;

		return (
			<section className={`storage-unit-row ${className || ''} ${scrollEnabled ? "scroll" : ''}`} style={style} ref={this.setRef}>
				<div className={"border-indicator"} />
				{
					(frames || []).sort(sortBySortOrder).map((frame, i) => (
						<StorageFrame
							key={i}
							frame={frame}
							customProps={customProps}
							onFrameClick={this.props.onFrameClick}
							onEmptyFrameClick={this.props.onEmptyFrameClick}
							onBlockClick={this.props.onBlockClick}
							onEmptyBlockClick={this.props.onEmptyBlockClick}
							onServiceClick={this.props.onServiceClick}
						/>
					))
				}
				{
					isEdit &&
					<StorageFrame
						frame={{
							frameType: "ADD",
							orderPosition: get(frames, `$.orderPosition`, frames.length),
						}}
						onEmptyFrameClick={this.props.onEmptyFrameClick}
					/>
				}
				<div className={"border-indicator"} />
			</section>
		)
	}

	static get propTypes() {
		return {
			...super.propTypes,
			...T_StorageUnitSharedProps,
			frames: PropTypes.array,
			scrollEnabled: PropTypes.bool,
		}
	}

	static get stateTypes() {
		return {
			...super.stateTypes,
		}
	}

	static get defaultProps() {
		return {
			...super.defaultProps,
			unit: {},
			scrollEnabled: true,
		}
	}
}

export default StorageUnitRow;
