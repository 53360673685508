// eslint-disable-next-line no-unused-vars
import { DELETE, formatQueryString, POST, PUT, sendRequest } from "./apiServices/apiConnector";

export const service_fetchCommunicationUnits = (locationID, queryString) => (
	sendRequest(`/location/${locationID}/communication/${formatQueryString(queryString)}`).then(response => response.json())
);

export const service_fetchCommunicationUnit = (unitID) => (
	sendRequest(`/communication/${unitID}`).then(response => response.json())
);

export const service_insertCommunicationUnit = (locationID, data) => (
	sendRequest([`/location/${locationID}/communication/`, POST, data]).then(response => response.json())
);

export const service_updateCommunicationUnit = (unitID, data) => (
	sendRequest([`/communication/${unitID}`, PUT, data]).then(response => response.json())
);

export const service_deleteCommunicationUnit = (unitID) => (
	sendRequest([`/communication/${unitID}`, DELETE]).then(response => response)
);
