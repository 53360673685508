import React from "react";
// eslint-disable-next-line no-unused-vars
import PropTypes from "prop-types";
import { service_passwordResetFinish, service_passwordResetVerifyToken } from "../../../../services/Service_Password";
import TextInput from "../../../shared/Input/TextInput";
import Fa from "../../../tools/Icons/Fa";
import { Translate } from "react-localize-redux";
import ButtonsConstructor from "../../../shared/ButtonsConstructor";
import { E_ButtonPresets } from "../../../../models/Models_ButtonPresets";
import { callErrorToast, css } from "../../../../utils/utils";
import { errorHandler } from "../../../../store/ResponseHandling";
import { E_ToastStyle } from "../../../../models/Models_Toast";
import LanguageToggle from "../../../Navigation/LanguageToggle";
import TokenVerification from "../../../tools/TokenVerification";
import CubeSpinner from "../../../shared/LoadingIndicator/CubeSpinner";

class PasswordRecovery extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			...this.state,
			token: null,
			sending: false,
			password: null,
			passwordRepeat: null,
			passwordChanged: false,
		};
	}

	_renderForm() {
		const {password, passwordRepeat} = this.state;

		return (
			<form style={{margin: "auto"}} onSubmit={this._handleFormSubmit.bind(this)}>
				<h1 style={{textAlign:"center"}}><label><Translate id={"password_recovery"}/></label></h1>
				<div className={"grid-table grid-icon-auto-1fr"} style={{margin: "15px 0"}}>
					<Fa icon={"key"}/>
					<label><Translate id={"password"}/></label>
					<TextInput
						type={"password"}
						path={`password`}
						defaultValue={password}
						onModify={this._handleModify.bind(this)}
						modifyImmediately={true}
					/>
					<Fa icon={"key"}/>
					<label><Translate id={"password_repeat"}/></label>
					<TextInput
						type={"password"}
						path={`passwordRepeat`}
						defaultValue={passwordRepeat}
						onModify={this._handleModify.bind(this)}
						modifyImmediately={true}
					/>
				</div>

				<ButtonsConstructor buttons={[{
					preset: E_ButtonPresets.PASSWORD_CHANGE,
					disabled: !this._canSubmit(),
					disabledTippy: this._getSubmitFailContent() && <div style={{padding: 5}}><Translate id={this._getSubmitFailContent()}/></div>
				}]}/>
			</form>
		)
	}

	_renderContent() {
		const {passwordChanged} = this.state;

		if(passwordChanged) {
			return (
				<div style={css(`display: flex; align-items: center; justify-content: center; flex-direction: column; width: 100%; height: 100%`)}>
					<Fa icon={"check"} style={css(`font-size: 4em; color: var(--state-success-color)`)}/>
					<h1><label><Translate id={"success_PASSWORD_RESET_FINISH"}/></label></h1>
					<p style={css(`margin-top: 1em`)}><Translate id={"closeWindowPrompt"}/></p>
				</div>
			);
		}

		return this._renderForm();
	}

	render() {
		const {className, style} = this.props;
		const {sending} = this.state;

		return (
			<section className={`password-recovery ${className}`} style={{...style, height: "100%", display: "flex"}}>
				<TokenVerification
					verifier={service_passwordResetVerifyToken}
					defaultErrorContent={"INVALID_TOKEN"}
					errorProcessor={this._processErrorBlockProps}
					onToken={(token) => this.setState({token})}
				>
					{this._renderContent()}
				</TokenVerification>
				<div style={css(`position: absolute; bottom: 0.8em; left: 0.8em; font-size: 2em`)}>
					<LanguageToggle/>
				</div>
				{sending && <CubeSpinner/>}
			</section>
		)
	}

	_handleModify(t, key, value) {
		this.setState({[key]: value});
	}

	_processErrorBlockProps(props = {}) {
		if(props.title == "INVALID_TOKEN") {
			return {
				...props,
				title: "error_PASSWORD_RESET_TOKEN_EXPIRED",
				icon: "clock",
			}
		}

		return props;
	}

	_handleFormSubmit(e) {
		e.preventDefault();
		const {token, password} = this.state;

		if(!this._canSubmit()) {
			callErrorToast(this._getSubmitFailContent());
			return;
		}

		this.setState({sending: true});
		service_passwordResetFinish(token, password).then(() => {
			window.toaster.showToast({
				style: E_ToastStyle.SUCCESS,
				content: "success_PASSWORD_RESET_FINISH",
			});
			this.setState({passwordChanged: true});
		}, error => {
			errorHandler(error, "PASSWORD_RESET_FINISH");
		}).finally(() => {
			this.setState({sending: false});
		});
	}

	_canSubmit() {
		const {sending, password, passwordRepeat} = this.state;
		return !(sending || password != passwordRepeat || !password || !passwordRepeat);
	}

	_getSubmitFailContent() {
		const {password, passwordRepeat} = this.state;
		if(!password || !passwordRepeat) {
			return `pleaseFillOutAllFields`;
		} else if(password != passwordRepeat) {
			return `passwords_mismatch`;
		}
		return null;
	}

	static get propTypes() {
		return {
			...super.propTypes,
			className: PropTypes.string,
			style: PropTypes.object,
		}
	}

	static get stateTypes() {
		return {
			...super.stateTypes,
			token: PropTypes.string,
			sending: PropTypes.bool,
			password: PropTypes.string,
			passwordRepeat: PropTypes.string,
			passwordChanged: PropTypes.bool,
		}
	}
}

export default PasswordRecovery;
