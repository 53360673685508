import React from "react";
import PropTypes from "prop-types";
import {E_FrameOptions, T_frameIcons, T_frameTypeBlocksCount, T_StorageUnitSharedProps} from "../../../../models/Models_StorageBox";
import {prebuildItemArray} from "../../../../utils/locationUtils";
import StorageBlock from "./StorageBlock";
import Fa from "../../../tools/Icons/Fa";
import {T_APP_ICONS} from "../../../../models/constants/Constants_Shared";
import {E_Visibility} from "../../../../models/Models_Location";

class StorageFrame extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			...this.state,

		};
	}

	_renderEmptyFrame() {
		const {frame, customProps} = this.props;
		const {isEdit} = customProps;

		switch (frame.frameType) {
			case E_FrameOptions.TERMINAL:
				return <Fa icon={T_frameIcons[frame.frameType]} />;
			case "ADD":
				return <Fa icon={T_APP_ICONS.ADD} />;
			default:
				return isEdit && <Fa icon={T_APP_ICONS.EDIT} />;
		}
	}

	_renderFrameBlocks() {
		const {frame, customProps} = this.props;
		let blocks = prebuildItemArray(T_frameTypeBlocksCount[frame.frameType], frame.blocks);

		return (blocks || []).map((block, i) => (
			<StorageBlock
				key={i}
				block={block}
				customProps={{
					...customProps,
					frameID: frame.id,
					icon: T_frameIcons[frame.frameType] || customProps.icon,
				}}
				onBlockClick={this.props.onBlockClick}
				onEmptyBlockClick={this.props.onEmptyBlockClick}
				onServiceClick={this.props.onServiceClick}
			/>
		));
	}

	_renderFrameContent() {
		const {frame} = this.props;

		if(T_frameTypeBlocksCount[frame.frameType]) {
			return this._renderFrameBlocks();
		} else {
			return this._renderEmptyFrame();
		}
	}

	render() {
		const {className, style, frame, onFrameClick, onEmptyFrameClick} = this.props;

		return (
			<section
				className={`storage-frame ${className || ''}`}
				style={style}
				data-frame-type={frame.frameType || "NONE"}
				onClick={e => {
					e.stopPropagation();

					if(frame.id && frame.frameType) {
						onFrameClick(e, frame);
					} else {
						onEmptyFrameClick(e, frame);
					}
				}}
				data-simple={`${!T_frameTypeBlocksCount[frame.frameType]}`}
			>
				{this._renderFrameContent()}
				<span className={"state-icons"}>
					{frame.visibilityType == E_Visibility.HIDDEN && <Fa icon={T_APP_ICONS.HIDDEN}/>}
					{frame.visibilityType == E_Visibility.AUTHORIZED_ONLY && <Fa icon={T_APP_ICONS.AUTHORIZED_ONLY}/>}
				</span>
			</section>
		)
	}

	static get propTypes() {
		return {
			...super.propTypes,
			...T_StorageUnitSharedProps,
			frame: PropTypes.object,
		}
	}

	static get stateTypes() {
		return {
			...super.stateTypes,
		}
	}

	static get defaultProps() {
		return {
			...super.defaultProps,
			frame: {},
			customProps: {}
		}
	}
}

export default StorageFrame;
