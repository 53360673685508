// eslint-disable-next-line no-unused-vars
import { DELETE, formatQueryString, POST, PUT, sendRequest } from "./apiServices/apiConnector";

export const service_fetchFeedback_List = (queryString = null) => {
	return sendRequest(`/feedback/${formatQueryString(queryString)}`).then(response => response.json());
};

export const service_fetchFeedback_Item = (feedbackID) => {
	return sendRequest(`/feedback/${feedbackID}`).then(response => response.json());
};

export const service_insertFeedback = (body) => {
	return sendRequest([`/feedback/`, POST, body]).then(response => response.json());
};

export const service_updateFeedback = (feedbackID, body) => {
	return sendRequest([`/feedback/${feedbackID}`, PUT, body]).then(response => response.json());
};

export const service_deleteFeedback = (feedbackID) => {
	return sendRequest([`/feedback/${feedbackID}`, DELETE]).then(response => response);
};
