import { Thunk_Generic_Edit_Update, Thunk_Generic_Fetch_Item } from "./Thunk_Generic";
import { service_fetchSettings, service_updateSettings } from "../../services/Service_Settings";
import { Action_Settings_Edit_Fulfilled, Action_Settings_Edit_Pending, Action_Settings_Edit_Rejected, Action_Settings_Fetch_Fulfilled, Action_Settings_Fetch_Pending, Action_Settings_Fetch_Rejected } from "../actions/configuration/settingsActions";
import { T_BusinessDetails } from "../../models/Models_Partner";

export const Thunk_Settings_Fetch = () => (
	Thunk_Generic_Fetch_Item(
		undefined,
		service_fetchSettings,
		Action_Settings_Fetch_Pending,
		Action_Settings_Fetch_Fulfilled,
		Action_Settings_Fetch_Rejected
	)
);

export const Thunk_Settings_Update = () => (
	Thunk_Generic_Edit_Update(
		service_updateSettings,
		Action_Settings_Edit_Pending,
		Action_Settings_Edit_Fulfilled,
		Action_Settings_Edit_Rejected,
		state => state.settings.edit,
		data => {
			if(!data.invoiceSupplier) {
				return {
					...data,
					invoiceSupplier: T_BusinessDetails,
				}
			}
			return data;
		}
	)
);
