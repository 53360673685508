import PropTypes from 'prop-types';
import React from 'react';
import {Translate} from "react-localize-redux";
import {connect} from "react-redux";
import {NavLink, withRouter} from 'react-router-dom';
import {bindActionCreators} from "redux";
import {T_APP_ICONS} from "../../../models/constants/Constants_Shared";
import routes from '../../../routes/routes';
import {Selector_ServiceTypes_Listing_Root} from "../../../store/selectors/Selectors_ServiceType";
import {Thunk_ServiceType_Insert, Thunk_ServiceTypes_Fetch_All} from "../../../store/thunk/Thunk_ServiceTypes";
import CSBaseComponent from "../../CSBaseComponent";
import ItemListing from '../../shared/Listing/ItemListing';
import ListingPage from "../../shared/Listing/ListingPage";
import TableListing from "../../shared/Listing/TableListing";
import {get, resolveItemName} from "../../../utils/utils";
import TableRow from "../../shared/Listing/TableRow";
import TableColumn from "../../shared/Listing/TableColumn";
import ItemRedirect from "../../shared/ItemRedirect";
import {T_RestrictionPresets} from "../../../models/Models_Restrictions";

class ServiceTypesList extends CSBaseComponent {
	constructor(props) {
		super(props);

		this.state = {
			...super.state,
		};
	}

	_renderTableTemplate(serviceType, i, headerItems) {
		return (
			<TableRow key={i} href={`${routes.SERVICE_TYPES}/${serviceType.id}`} headerItems={headerItems}>
				<TableColumn column={"titles"}>{window.translator.translateEntry(serviceType.titles, null, true)}</TableColumn>
				<TableColumn column={"dimensions"}>{serviceType.dimensions || <Translate id={"value_notProvided"} />}</TableColumn>
				<TableColumn column={"controlType"}>{serviceType.controlType ? <Translate id={`serviceType_controlType_${serviceType.controlType}`}/> : ''}</TableColumn>
				<TableColumn column={"tariff"}>
					{
						get(serviceType, `tariff.id`) &&
						<ItemRedirect href={`${routes.TARIFFS}/${serviceType.tariff.id}`}>
							{resolveItemName(serviceType.tariff)}
						</ItemRedirect>
					}
				</TableColumn>
			</TableRow>
		);
	}

	_renderTemplate(serviceType) {
		return (
			<NavLink to={`${routes.SERVICE_TYPES}/${serviceType.id}`} className="list-item fadeIn" exact={true} style={{minHeight: 100}}>
				<div className={"front back"}>
					<label><h2>{window.translator.translateEntry(serviceType.titles, null, true)}</h2></label>
					<span>{serviceType.dimensions}</span>
				</div>
				<small>{serviceType.id}</small>
			</NavLink>
		);
	}

	render() {
		const {Thunk_ServiceTypes_Fetch_All} = this.props;

		return (
			<section className={"service-types-list"}>
				<ListingPage
					section={"globalConfiguration"}
					titleIcon={T_APP_ICONS.SERVICE_TYPES}
					titleText={"serviceTypes"}
					titleLeftContent={routes.SERVICE_TYPES}
					subscriberThunk={Thunk_ServiceTypes_Fetch_All}
					subscriberRootSelector={Selector_ServiceTypes_Listing_Root}
					headerItems={{
						titles: {
							field: "titles.text"
						},
						dimensions: {
							label: "serviceType_dimensions",
							sort: false,
							filter: false,
						},
						controlType: {
							label: "serviceType_controlType",
							filter: false,
							sort: false,
						},
						tariff: {
							restricted: T_RestrictionPresets.TARIFFS,
							field: "tariff.name"
						},
					}}
					name={"service-types"}
					views={[
						{
							view: "list",
							default: true,
							button: {
								icon: "th",
								tippy: <Translate id={"viewMode_list"} />
							},
							content: <ItemListing className={`list-wrapper`} itemTemplate={this._renderTemplate.bind(this)} />
						},
						{
							view: "table",
							button: {
								icon: "table",
								tippy: <Translate id={"viewMode_table"} />
							},
							content:
								<TableListing className={"stripes"} itemTemplate={this._renderTableTemplate.bind(this)} />
						}
					]}
				/>
			</section>
		);
	}
}

ServiceTypesList.propTypes = {
	Thunk_ServiceType_Insert: PropTypes.func.isRequired,
	Thunk_ServiceTypes_Fetch_All: PropTypes.func.isRequired,
};

const mapDispatchToProps = dispatch => (
	bindActionCreators({
		Thunk_ServiceType_Insert,
		Thunk_ServiceTypes_Fetch_All,
	}, dispatch)
);

export default connect(null, mapDispatchToProps)(withRouter(ServiceTypesList));
