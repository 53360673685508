import { Selector_Generic_Item_Root } from "./Selectors_Generic";

export const Selector_Vouchers_Root = state => {
	return state.vouchers;
};

export const Selector_Vouchers_Listing_Root = state => {
	return Selector_Vouchers_Root(state).listing;
};

export const Selector_Vouchers_Listing_Data = state => {
	return Selector_Vouchers_Listing_Root(state).data;
};

export const Selector_Voucher_Root = state => {
	return Selector_Vouchers_Root(state).item;
};

export const Selector_Voucher_Data = state => {
	return Selector_Voucher_Root(state).data;
};

export const Selector_Voucher_Edit = state => {
	return Selector_Voucher_Root(state).edit;
};
