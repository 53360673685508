import { service_deleteExternalLocation, service_fetchExternalLocation, service_fetchExternalLocations, service_insertExternalLocation, service_updateExternalLocation } from "../../services/Service_ExternalLocations";
import { Action_ExternalLocation_Edit_Fulfilled, Action_ExternalLocation_Edit_Pending, Action_ExternalLocation_Edit_Rejected, Action_ExternalLocation_Fetch_Fulfilled, Action_ExternalLocation_Fetch_Pending, Action_ExternalLocation_Fetch_Rejected, Action_ExternalLocations_Fetch_Fulfilled, Action_ExternalLocations_Fetch_Pending, Action_ExternalLocations_Fetch_Rejected } from "../actions/externalLocations/externalLocationsActions";
import { Thunk_Generic_Edit_Insert, Thunk_Generic_Edit_Save, Thunk_Generic_Edit_Update, Thunk_Generic_Fetch_All, Thunk_Generic_Fetch_Item, Thunk_Generic_Remove } from "./Thunk_Generic";
import { Selector_ExternalLocation_Edit } from "../selectors/Selectors_ExternalLocations";

export const Thunk_ExternalLocations_Fetch_All = queryString => (
	Thunk_Generic_Fetch_All(
		queryString,
		service_fetchExternalLocations,
		Action_ExternalLocations_Fetch_Pending,
		Action_ExternalLocations_Fetch_Fulfilled,
		Action_ExternalLocations_Fetch_Rejected
	)
);

export const Thunk_ExternalLocation_Fetch = locationID => (
	Thunk_Generic_Fetch_Item(
		locationID,
		service_fetchExternalLocation,
		Action_ExternalLocation_Fetch_Pending,
		Action_ExternalLocation_Fetch_Fulfilled,
		Action_ExternalLocation_Fetch_Rejected,
	)
);

export const Thunk_ExternalLocation_Insert = () => (
	Thunk_Generic_Edit_Insert(
		service_insertExternalLocation,
		Action_ExternalLocation_Edit_Pending,
		Action_ExternalLocation_Edit_Fulfilled,
		Action_ExternalLocation_Edit_Rejected,
		Selector_ExternalLocation_Edit
	)
);

export const Thunk_ExternalLocation_Update = () => (
	Thunk_Generic_Edit_Update(
		service_updateExternalLocation,
		Action_ExternalLocation_Edit_Pending,
		Action_ExternalLocation_Edit_Fulfilled,
		Action_ExternalLocation_Edit_Rejected,
		Selector_ExternalLocation_Edit
	)
);

export const Thunk_ExternalLocation_Save = () => (
	Thunk_Generic_Edit_Save(
		Selector_ExternalLocation_Edit,
		Action_ExternalLocation_Edit_Pending,
		Thunk_ExternalLocation_Insert,
		Thunk_ExternalLocation_Update
	)
);

export const Thunk_ExternalLocation_Delete = (locationID) => (
	Thunk_Generic_Remove(
		locationID,
		service_deleteExternalLocation,
		Action_ExternalLocation_Edit_Pending,
		Action_ExternalLocation_Edit_Fulfilled,
		Action_ExternalLocation_Edit_Rejected
	)
);
