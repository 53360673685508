// eslint-disable-next-line no-unused-vars
import { POST, sendRequest } from "./apiServices/apiConnector";

/**
 * Service - Password Reset
 * ---
 * Initializes password reset process
 * @param {String} email
 * @returns {Promise}
 */
export const service_passwordReset = (email) => {
	return sendRequest([`/password/reset`, POST, JSON.stringify({email})]).then(response => response);
};

export const service_passwordResetVerifyToken = (token) => {
	return sendRequest([`/password/reset/verify`, POST, JSON.stringify({token})]).then(response => response);
};

export const service_passwordResetFinish = (token, newPassword) => {
	return sendRequest([`/password/reset/finish`, POST, JSON.stringify({token, newPassword})]).then(response => response);
};
