import React from "react";
import PropTypes from "prop-types";
import ListingPage from "../../../../../shared/Listing/ListingPage";
import { service_fetchServiceLockHistory } from "../../../../../../services/Service_Service";
import TableListing from "../../../../../shared/Listing/TableListing";
import { Translate } from "react-localize-redux";
import { T_SortType } from "../../../../../../models/Models_Shared";
import { formatAsDate, resolveItemName } from "../../../../../../utils/utils";

class LockHistory extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			...super.state,

		};
	}

	render() {
		const {serviceID} = this.props;

		return (
			<ListingPage
				className={"footer-static absolute-overlap"}
				subscriberService={qs => service_fetchServiceLockHistory(serviceID, qs)}
				queryNotify={false}
				header={
					<div className={"separator title compact"} style={{position: "relative", zIndex: -1, margin: 0}}>
						<label><Translate id={"lockHistory"}/></label>
					</div>
				}
				headerItems={{
					id: {
						sort: T_SortType.NUMERIC
					},
					createdOn: {},
					sender: {},
					success: {}
				}}
				subscriberSort={`id,desc`}
				views={<TableListing className={"stripes"} itemTemplate={(item, i) => (
					<button key={i}>
						<span>#{item.id}</span>
						<span>{formatAsDate(item.createdOn)}</span>
						<span>{resolveItemName(item.sender, `email`)}</span>
						<span><Translate id={item.success ? "yes" : "no"} /></span>
					</button>
				)} />}
			/>
		)
	}

	static get propTypes() {
		return {
			...super.propTypes,
			serviceID: PropTypes.number,
		}
	}
}

export default LockHistory;
