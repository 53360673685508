import { E_BlockOptions, E_FrameOptions, T_blockModel } from "../../models/Models_StorageBox";
import { service_deleteFrame, service_insertFrame, service_updateFrame } from "../../services/Service_Locations";
import { Action_Frame_Add_Send_Fail, Action_Frame_Remove_Send_Fail, Action_Frame_Send, Action_Frame_Send_Success, Action_Frame_Update_Send_Fail } from "../actions/location/frameActions";
import { Selector_Location_Edit_Location, Selector_Location_Item_Root } from "../selectors/Selectors_Location";
import { E_Modification } from "../Constants_StoreShared";
import { Thunk_Block_Add } from "./Thunk_StorageBlock";
import { get } from "../../utils/utils";
import { successHandler } from "../ResponseHandling";
import { E_APIServiceActions } from "../../models/constants/Constants_Shared";

export function Thunk_Frame_Add(unitID, path, frameData) {
	return (dispatch, getState) => {
		if (Selector_Location_Item_Root(getState()).fetching) {return;} //Ignore if still fetching

		dispatch(Action_Frame_Send());

		service_insertFrame(unitID, JSON.stringify(frameData)).then(data => {
			dispatch(Action_Frame_Send_Success(E_Modification.ARRAY_PUSH, path, data)); //On success
		}, (error) => {
			dispatch(Action_Frame_Add_Send_Fail(error)); //On fail
		})
	}
}

export function Thunk_Frame_Remove(frameID) {
	let actionResponse = {
		action: {type: E_APIServiceActions.ITEM_DELETE}
	}

	return (dispatch, getState) => {
		if (Selector_Location_Item_Root(getState()).fetching) {
			return;
		} //Ignore if still fetching

		let location = Selector_Location_Edit_Location(getState());
		let unit = get(location, `storageUnits`, []).find(unit => get(unit, `frames`, []).findByID(frameID)) || {};
		let path = `storageUnits.#${unit.id}.frames.#${frameID}`;

		dispatch(Action_Frame_Send());
		service_deleteFrame(frameID).then(() => {
			successHandler(null, actionResponse);
			dispatch(Action_Frame_Send_Success(
				E_Modification.ARRAY_REMOVE_BY_ID,
				path
			)); //On success
		}, (error) => {
			dispatch(Action_Frame_Remove_Send_Fail(error)); //On fail
		});
	}
}

export function Thunk_Frame_Update(path, frameData) {
	return (dispatch, getState) => {
		if (Selector_Location_Item_Root(getState()).fetching) {return;} //Ignore if still fetching

		dispatch(Action_Frame_Send());

		service_updateFrame(frameData.id, JSON.stringify(frameData)).then(() => {
			dispatch(Action_Frame_Send_Success(
				E_Modification.ITEM_SET,
				path,
				frameData
			));

			if ([E_FrameOptions.BIKE_BOX].includes(frameData.frameType)) {
				dispatch(Thunk_Block_Add(frameData.id, {...T_blockModel, blockType: E_BlockOptions.SINGLE}, `${path}.blocks`));
			}
		}, (error) => {
			dispatch(Action_Frame_Update_Send_Fail(error)); //On fail
		});
	}
}
