import React from "react";
import PropTypes from "prop-types";
import Dropdown from "../../shared/Input/Dropdown";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { formatAsPrice, get, resolveItemName } from "../../../utils/utils";
import { T_APP_ICONS } from "../../../models/constants/Constants_Shared";
import Fa from "../../tools/Icons/Fa";
import { Translate, withLocalize } from "react-localize-redux";
import { Selector_Vouchers_Listing_Data } from "../../../store/selectors/Selectors_Vouchers";
import { Thunk_Vouchers_Fetch_All } from "../../../store/thunk/Thunk_Vouchers";
import { Thunk_Users_Fetch_All } from "../../../store/thunk/Thunk_Users";
import { Selector_Auth_Principal } from "../../../store/selectors/Selectors_Auth";
import { withRouter } from "react-router-dom";
import { service_insertSession } from "../../../services/Service_Sessions";
import routes from "../../../routes/routes";
import ButtonsConstructor from "../../shared/ButtonsConstructor";
import UserSelect from "../Users/UserSelect";
import TextInput from "../../shared/Input/TextInput";
import { errorHandler } from "../../../store/ResponseHandling";
import { SESSION_CREATE_REJECTED } from "../../../store/actions/sessions/sessionsActions";
import ItemRedirect from "../../shared/ItemRedirect";

class NewSessionModal extends React.Component {
	constructor(props) {
		super(props);

		const availableBrackets = this._getAvailableBrackets(props);

		this.state = {
			...super.state,
			activeTariff: props.tariff,
			activeBracket: props.bracket || availableBrackets.length == 1 ? availableBrackets[0] : undefined,
			activeVoucher: props.voucher,
			activeUser: props.user,
		};
	}

	componentDidMount() {
		const {activeUser} = this.state;

		if(!activeUser) {
			window.Database.get(Selector_Auth_Principal).then(activeUser => this.setState({activeUser}));
		}
	}

	render() {
		const {Thunk_Vouchers_Fetch_All} = this.props;
		const {activeTariff, activeBracket, activeVoucher, activeUser} = this.state;
		const availableBrackets = this._getAvailableBrackets();
		const __getBracketLabel = (item, translate) => (
			item ? `${item.maxMinutes} ${translate("minutes")} / ${formatAsPrice(item.price, activeTariff.currency, this)}` : null
		);

		return (
			<section>
				<div className={"grid-table grid-icon-auto-1fr"}>
					<Fa icon={"clock"} />
					<label><Translate id={"timeBracket"}/>*</label>
					<Translate>
						{({translate}) => availableBrackets.length > 1 && (
							<Dropdown
								options={availableBrackets}
								onLabel={item => __getBracketLabel(item, translate)}
								onModify={(t,p, activeBracket) => this.setState({activeBracket})}
								isActive={item => item.id == this._getActiveID(activeBracket)}
								defaultValue={activeBracket}
								disabled={!activeTariff}
								disabledMessage={<Translate id={"tariffMustBeSelected"}/>}
								canInvalidate={true}
							/>
						)
						||
						__getBracketLabel(availableBrackets[0], translate)
						||
						<span style={{display: "block"}}>
							<ItemRedirect
								href={`${routes.TARIFFS}/${(activeTariff || {}).id}`}
								tippy={<Translate id={"goToTariff"}/>}
							>
								<Translate id={"noEntries"}/>
							</ItemRedirect>
						</span>}
					</Translate>

					<Fa icon={T_APP_ICONS.VOUCHERS} />
					<label><Translate id={"voucher"}/></label>
					<Dropdown
						selector={Selector_Vouchers_Listing_Data}
						thunk={() => Thunk_Vouchers_Fetch_All}
						defaultValue={activeVoucher}
						isActive={item => item.id == this._getActiveID(activeVoucher)}
						onLabel={item => resolveItemName(item, `voucherCode`)}
						onModify={(t,p, activeVoucher) => this.setState({activeVoucher})}
						canInvalidate={true}
					/>

					<Fa icon={T_APP_ICONS.USER} />
					<label><Translate id={"user"}/>*</label>
					<button
						className={"no-style"}
						onClick={() => this._openUserSelect()}
					>
						<TextInput
							defaultValue={resolveItemName(activeUser, `email`)}
							suffix={<Fa icon={T_APP_ICONS.EDIT_DIALOG}/>}
						/>
					</button>
				</div>

				<ButtonsConstructor buttons={[{
					icon: "user-lock",
					text: "reserve",
					className: "fancy",
					action: () => this._createNewSession(),
					disabled: !this._getActiveID(activeUser, false) || !this._getActiveID(activeBracket, false),
					disabledTippy: <div style={{margin: 5}}><Translate id={"selectTimeBracketFirst"}/></div>,
					style: {marginTop: 10}
				}]}/>
			</section>
		)
	}

	_getActiveID(base, fallback = -1) {
		return get(base, `id`, fallback)
	}

	_getAvailableBrackets(props) {
		const activeTariff = props ? props.tariff : this.state.activeTariff;
		return get(activeTariff, `timeBrackets`, []).filter(bracket => !bracket.disabled)
	}

	_createNewSession() {
		const {serviceID, history} = this.props;
		const {activeBracket, activeVoucher, activeUser} = this.state;

		service_insertSession(JSON.stringify({
			serviceID,
			timeBracketID: this._getActiveID(activeBracket),
			voucherCode: get(activeVoucher, "voucherCode", undefined),
			locale: 'cs',
			userID: this._getActiveID(activeUser),
			activateNow: true,
		})).then(data => {
			window.modal.close();
			history.push(`${routes.SESSIONS}/${data.id}`);
		}, error => {
			errorHandler(error, SESSION_CREATE_REJECTED)
		});
	}

	_openUserSelect() {
		const {activeUser} = this.state;

		window.modal.open({
			body: (
				<UserSelect
					defaultValue={activeUser}
					onModify={(t,p, activeUser) => this.setState({activeUser})}
				/>
			),
		})
	}

	static get propTypes() {
		return {
			...super.propTypes,
			serviceID: PropTypes.number.isRequired,
			tariff: PropTypes.object.isRequired,
			bracket: PropTypes.object,
			voucher: PropTypes.object,
			user: PropTypes.object,
			Thunk_Vouchers_Fetch_All: PropTypes.func.isRequired,
			Thunk_Users_Fetch_All: PropTypes.func.isRequired,
			history: PropTypes.object.isRequired,
		}
	}

	static get stateTypes() {
		return {
			...super.stateTypes,
		}
	}

	static get defaultProps() {
		return {
			...super.defaultProps,
		}
	}
}

const mapDispatchToProps = dispatch => (
	bindActionCreators({
		Thunk_Vouchers_Fetch_All,
		Thunk_Users_Fetch_All,
	}, dispatch)
);

export default connect(null, mapDispatchToProps)(withRouter(withLocalize(NewSessionModal)));
