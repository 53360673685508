import React, {Fragment} from "react";
import PropTypes from "prop-types";
import {Translate, withLocalize} from "react-localize-redux";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {T_SharedReduxPropTypes} from "../../../models/Models_Shared";
import routes from "../../../routes/routes";
import {Action_Authorization_Clear, Action_Authorization_Edit_Cancel, Action_Authorization_Edit_Init, Action_Authorization_Edit_Modify} from "../../../store/actions/authorizations/authorizationsActions";
import {Selector_Authorization_Data, Selector_Authorization_Edit, Selector_Authorization_Root} from "../../../store/selectors/Selectors_Authorizations";
import {Thunk_Language_Fetch_All} from "../../../store/thunk/Thunk_Language";
import {Thunk_Authorization_Fetch, Thunk_Authorization_Remove, Thunk_Authorization_Save} from "../../../store/thunk/Thunk_Authorizations";
import is from "../../../utils/is";
import Fa from "../../tools/Icons/Fa";
import "../../../styles/pages/Authorization/AuthorizationDetail.scss";
import Toolbar from "../../shared/Toolbar";
import DetailWrapper from "../../shared/DetailWrapper";
import {Selector_Auth_Principal} from "../../../store/selectors/Selectors_Auth";
import {Thunk_Profile_Fetch, Thunk_Profile_Remove, Thunk_Profile_Save} from "../../../store/thunk/Thunk_Profile";
import CubeSpinner from "../../shared/LoadingIndicator/CubeSpinner";
import {T_AuthorizationModel} from "../../../models/Models_Authorization";
import PartnerSelectInput from "../Partners/PartnerSelectInput";
import {T_RestrictionPresets} from "../../../models/Models_Restrictions";
import Restricted from "../../shared/Restrictions/Restricted";
import ItemRedirect from "../../shared/ItemRedirect";
import {TranslateEntry} from "../../../utils/localeUtils";
import {resolveItemName} from "../../../utils/utils";
import ServiceTypeSelectInput from "../ServiceTypes/ServiceTypeSelectInput";
import {E_UserRoles, T_APP_ICONS} from "../../../models/constants/Constants_Shared";
import ServiceCategorySelectInput from "../ServiceCategories/ServiceCategorySelectInput";
import TextInput from "../../shared/Input/TextInput";
import Dropdown from "../../shared/Input/Dropdown";
import withRestrictions from "../../shared/Restrictions/withRestrictions";
import {isRestricted} from "../../../utils/restrictionUtils";
import LocationSelectInput from "../Locations/LocationSelectInput";
import LocationStorageServiceSelectInput from "../Locations/StorageUnit/Details/StorageService/LocationStorageServiceSelectInput";

const E_RestrictionType = {
	LOCATION: "LOCATION",
	SERVICE: "SERVICE",
	SERVICE_TYPE: "SERVICE_TYPE",
	SERVICE_CATEGORY: "SERVICE_CATEGORY",
}

class AuthorizationsDetail extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			sending: false,
			activeRestriction: E_RestrictionType.LOCATION,
		};
	}

	componentDidMount() {
		const {authorizationID, Thunk_Authorization_Fetch, Action_Authorization_Edit_Init, principal, Thunk_Profile_Fetch} = this.props;

		if(authorizationID) {
			if(authorizationID == principal.id) {
				Thunk_Profile_Fetch();
			}
			else {
				Thunk_Authorization_Fetch(authorizationID);
			}
		}
		else {
			Action_Authorization_Edit_Init(T_AuthorizationModel);
		}
	}

	componentWillUnmount() {
		const {Action_Authorization_Clear} = this.props;

		Action_Authorization_Clear();
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		const {edit} = this.props;

		if(!prevProps.edit && prevProps.edit !== edit) {
			this.setState({
				activeRestriction: this._resolveRestrictionType(edit),
			});
		}

		if(prevState.activeRestriction !== this.state.activeRestriction) {
			this.props.Action_Authorization_Edit_Init({
				...this.props.edit,
				...({
					location: null,
					service: null,
					serviceType: null,
					serviceCategory: null,
				})
			});
		}
	}

	_renderToolbar() {
		const {edit, Action_Authorization_Edit_Cancel, Thunk_Authorization_Remove, authorizationID, fetching, failed, Action_Authorization_Edit_Init, Thunk_Authorization_Save} = this.props;
		const isEdit = is.valid(edit);

		return <Toolbar
			isEdit={isEdit}
			backUrl={routes.AUTHORIZATIONS}
			content={<h1><Translate id={authorizationID ? (isEdit ? "serviceAuthorization_edit" : "serviceAuthorization_detail") : "serviceAuthorization_create"} /></h1>}
			onEditCancel={() => Action_Authorization_Edit_Cancel()}
			onEdit={() => Action_Authorization_Edit_Init()}
			onSave={() => Thunk_Authorization_Save()}
			onRemove={() => Thunk_Authorization_Remove(authorizationID)}
			disableActionButtons={fetching || failed}
			itemID={authorizationID}
		/>;
	}

	_renderAuthorization(authorization) {
		const {Action_Authorization_Edit_Modify, hasAccess, edit, authorizationID} = this.props;
		const {sending, activeRestriction} = this.state;
		const isEdit = is.valid(edit)
		const onModify = (...args) => Action_Authorization_Edit_Modify(...args)

		authorization = isEdit ? edit : authorization;

		const restrictionType = this._resolveRestrictionType(authorization);

		return (
			<Fragment>
				{this._renderToolbar()}
				<div className={"authorization"}>
					<div className={"grid-table grid-icon-auto-1fr"}>
						<Fa icon={T_APP_ICONS.USER} />
						<label><Translate id={"username"} /></label>
						{this._isEditSwitch(
							<span>{authorization.userAccount?.email ?? authorization.username}</span>,
							<>
								{
									authorizationID == 0 &&
									<TextInput
										defaultValue={authorization.username}
										path={"username"}
										onModify={onModify}
									/>
									||
									<span>{authorization.userAccount?.email ?? authorization.username}</span>
								}
							</>
						)}

						<Fa icon={T_APP_ICONS.PARTNERS} />
						<label><Translate id={"partner"} /></label>
						{
							this._isEditSwitch(
								<span>{
									authorization.partner &&
									<ItemRedirect
										href={`${routes.PARTNERS}/${authorization.partner.id}`}
										wrapChildren={true}
										restricted={T_RestrictionPresets.PARTNERS}
									>
										{resolveItemName(authorization.partner)}
									</ItemRedirect>
									||
									<Translate id={"value_notProvided"} />
								}</span>,
								<Restricted role={E_UserRoles.ADMIN} noPartner={true}>
									<PartnerSelectInput
										defaultValue={authorization.partner}
										path={"partner"}
										onModify={onModify}
									/>
								</Restricted>
							)
						}

						{
							this._isEditSwitch(
								(
									authorization.location ||
									authorization.service ||
									authorization.serviceType ||
									authorization.serviceCategory
								) &&
								<>
									<Fa icon={"lock"} />
									<span style={{gridColumn: "2 span"}}>
										<Translate
											id={"limitedToTypeLink"}
											data={{
												type: <Translate id={`serviceAuthorization_restrictionType_${restrictionType}`}/>,
												link: <>
													{
														authorization.location &&
														<ItemRedirect
															href={`${routes.LOCATIONS}/${authorization.location.id}`}
															wrapChildren={true}
															restricted={T_RestrictionPresets.LOCATIONS}
														>
															{resolveItemName(authorization.location)}
														</ItemRedirect>
													}
													{
														authorization.service &&
														<span>{authorization.service.physicalReference ?? `#${authorization.service.id}`}</span>
													}
													{
														authorization.serviceType &&
														<ItemRedirect
															href={`${routes.SERVICE_TYPES}/${authorization.serviceType.id}`}
															wrapChildren={true}
															restricted={T_RestrictionPresets.GLOBAL_CONFIGURATION}
														>
															<TranslateEntry entry={authorization.serviceType.titles} showAll={false} />
														</ItemRedirect>
													}
													{
														authorization.serviceCategory &&
														<ItemRedirect
															href={`${routes.SERVICE_TYPES}/${authorization.serviceCategory.id}`}
															wrapChildren={true}
															restricted={T_RestrictionPresets.GLOBAL_CONFIGURATION}
														>
															<TranslateEntry entry={authorization.serviceCategory.titles} showAll={false} />
														</ItemRedirect>
													}
												</>
											}}
										/>
									</span>
								</>,
								<>
									<Fa icon={"lock"} />
									<label><Translate id={"limitTo"} /></label>
									<Translate>
										{({translate}) => (
											<Dropdown
												options={Object.values(E_RestrictionType).filter(type => {
													switch (type) {
														case E_RestrictionType.LOCATION:
														case E_RestrictionType.SERVICE:
															return !isRestricted({restricted: T_RestrictionPresets.LOCATIONS}, hasAccess);
														case E_RestrictionType.SERVICE_TYPE:
														case E_RestrictionType.SERVICE_CATEGORY:
															return !isRestricted({restricted: T_RestrictionPresets.GLOBAL_CONFIGURATION}, hasAccess);
														default:
															return true;
													}
												})}
												onLabel={value => translate(`serviceAuthorization_restrictionType_${value}`)}
												onModify={(_t, _p, value) => this.setState({activeRestriction: value})}
												defaultValue={activeRestriction}
											/>
										)}
									</Translate>

									{
										activeRestriction === E_RestrictionType.LOCATION &&
										<>
											<Fa icon={T_APP_ICONS.LOCATIONS} />
											<label><Translate id={"location"} /></label>
											<LocationSelectInput
												defaultValue={authorization.location}
												path={"location"}
												onModify={onModify}
												canInvalidate={true}
											/>
										</>
									}

									{
										activeRestriction === E_RestrictionType.SERVICE &&
										<>
											<Fa icon={T_APP_ICONS.SERVICE} />
											<label><Translate id={"service"} /></label>
											<LocationStorageServiceSelectInput
												defaultValue={authorization.service}
												path={"service"}
												onModify={onModify}
												onLabel={item => item ? item?.physicalReference ?? `#${item.id}` : null}
												canInvalidate={true}
											/>
										</>
									}

									{
										activeRestriction === E_RestrictionType.SERVICE_TYPE &&
										<>
											<Fa icon={"tools"} />
											<label><Translate id={"serviceType"} /></label>
											<ServiceTypeSelectInput
												defaultValue={authorization.serviceType}
												path={"serviceType"}
												onModify={onModify}
												canInvalidate={true}
											/>
										</>
									}

									{
										activeRestriction === E_RestrictionType.SERVICE_CATEGORY &&
										<>
											<Fa icon={T_APP_ICONS.SERVICE_CATEGORIES} />
											<label><Translate id={"serviceCategory"} /></label>
											<ServiceCategorySelectInput
												defaultValue={authorization.serviceCategory}
												path={"serviceCategory"}
												onModify={onModify}
												canInvalidate={true}
											/>
										</>
									}
								</>
							)
						}

						{this._isEditSwitch(
							<>
								<Fa icon={"user-edit"}/>
								<label><Translate id={"authorizedBy"}/></label>
								<span>{authorization.authorizedBy?.email}</span>
							</>,
							null,
						)}
					</div>
				</div>
				{sending && <CubeSpinner />}
			</Fragment>
		)
	}

	render() {
		const {authorization, edit, authorizationID} = this.props;

		return (
			<DetailWrapper
				itemID={authorizationID}
				dataID={(authorization || {}).id}
				className={"authorization-detail"}
				isEdit={is.valid(edit)}
				listUrl={routes.AUTHORIZATIONS}
				errorContent={"serviceAuthorization_fetch_failed"}
				selector={Selector_Authorization_Root}
			>
				{this._renderAuthorization((is.valid(edit) ? edit : authorization) || T_AuthorizationModel)}
			</DetailWrapper>
		);
	}

	_isEditSwitch(normal, edit) {
		return is.valid(this.props.edit) ? edit : normal;
	}

	_resolveRestrictionType(authorization) {
		if(authorization.location) return E_RestrictionType.LOCATION;
		if(authorization.service) return E_RestrictionType.SERVICE;
		if(authorization.serviceType) return E_RestrictionType.SERVICE_TYPE;
		if(authorization.serviceCategory) return E_RestrictionType.SERVICE_CATEGORY;

		return null;
	}
}

AuthorizationsDetail.propTypes = {
	...T_SharedReduxPropTypes,

	authorization: PropTypes.object.isRequired,
	authorizationID: PropTypes.number.isRequired,

	principal: PropTypes.object,

	Thunk_Authorization_Fetch: PropTypes.func.isRequired,
	Action_Authorization_Edit_Modify: PropTypes.func.isRequired,
	Action_Authorization_Edit_Init: PropTypes.func.isRequired,
	Action_Authorization_Edit_Cancel: PropTypes.func.isRequired,
	Thunk_Language_Fetch_All: PropTypes.func.isRequired,
	Thunk_Authorization_Save: PropTypes.func.isRequired,
	Thunk_Authorization_Remove: PropTypes.func.isRequired,
};

AuthorizationsDetail.stateTypes = {
	sending: PropTypes.bool
};

AuthorizationsDetail.defaultProps = {
	authorization: T_AuthorizationModel
};

const mapStateToProps = state => {
	const {fetching, fetched, failed} = Selector_Authorization_Root(state);
	const authorization = Selector_Authorization_Data(state);
	const edit = Selector_Authorization_Edit(state);
	const principal = Selector_Auth_Principal(state);

	return {fetching, fetched, failed, authorization, edit, principal};
};

const mapDispatchToProps = dispatch => (
	bindActionCreators({
		Action_Authorization_Edit_Init,
		Action_Authorization_Edit_Modify,
		Thunk_Authorization_Fetch,
		Action_Authorization_Edit_Cancel,
		Thunk_Language_Fetch_All,
		Thunk_Authorization_Save,
		Thunk_Authorization_Remove,
		Thunk_Profile_Fetch,
		Thunk_Profile_Save,
		Thunk_Profile_Remove,
		Action_Authorization_Clear,
	}, dispatch)
);

export default connect(mapStateToProps, mapDispatchToProps)(withRestrictions(withLocalize(AuthorizationsDetail)));
