import { E_UserPermissionOption } from "./Models_User";
import { E_UserRoles } from "./constants/Constants_Shared";

export const T_RestrictionPresets = {
	EDIT: (section) => section && {permissions: {[section]: E_UserPermissionOption.READ_WRITE}},
	LOCK_CONTROL: {role: E_UserRoles.ADMIN, permissions: {lockControl: true}},

	//Sections
	LOCATIONS: {permissions:{locations: E_UserPermissionOption.READ}},
	SESSIONS: {permissions:{sessions: E_UserPermissionOption.READ}},
	FEEDBACK: {permissions:{feedback: E_UserPermissionOption.READ}},
	GLOBAL_CONFIGURATION: {permissions:{globalConfiguration: E_UserPermissionOption.READ}},
	PARTNERS: {permissions:{partners: E_UserPermissionOption.READ}},
	TARIFFS: {permissions:{tariffs: E_UserPermissionOption.READ}},
	USERS: {permissions:{users: E_UserPermissionOption.READ}},
	VOUCHERS: {permissions:{vouchers: E_UserPermissionOption.READ}},
};
