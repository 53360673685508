import React from 'react'
// eslint-disable-next-line no-unused-vars
import PropTypes from 'prop-types'
import { withLocalize } from "react-localize-redux";
import Fa from "../Icons/Fa";
import { get } from "../../../utils/utils";
import "../../../styles/components/LanguageList.scss";

class LanguageList extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			selectedLanguage: props.activeLanguage
		};

		this._selectLanguage = this._selectLanguage.bind(this);
	}

	_renderLanguage(language) {
		return (
			<div>
				{this.props.showFlags && <Fa icon={language.code} custom={true} />}
				&nbsp;
				{this.props.showLabels && <span>{language.name}</span>}
			</div>
		);
	}

	_renderLanguageSelectButton(language) {
		return (
			<button
				lang={language.code}
				active={`${language.code == this.state.selectedLanguage.code}`}
				onClick={() => this._selectLanguage(language)}
			>
				{this._renderLanguage(language)}
			</button>
		)
	}

	_renderLanguageEntry(language, i) {
		return (
			<div key={i}>
				{this.props.onSelect && this._renderLanguageSelectButton(language)}
				{!this.props.onSelect && this._renderLanguage(language)}
			</div>
		)
	}

	render() {
		return (
			<section className={"language-list"}>
				{
					this.props.languages.map((lang, i) => {
						return this._renderLanguageEntry(lang, i)
					})
				}
			</section>
		);
	}

	_selectLanguage(language) {
		if (language) {
			this.setState({selectedLanguage: language});
			this.props.onSelect(language);
		}
	}
}

LanguageList.propTypes = {
	showFlags: PropTypes.bool,
	showLabels: PropTypes.bool,
	onSelect: PropTypes.func,

	languages: PropTypes.array.isRequired,
	activeLanguage: PropTypes.object
};

LanguageList.stateTypes = {
	selectedLanguage: PropTypes.object
};

export default withLocalize(LanguageList);
