import React, { Fragment } from 'react'
// eslint-disable-next-line no-unused-vars
import { Translate } from "react-localize-redux";
import { valid } from "../../../utils/utils";
import InputBase from "./Base/InputBase";

class TextareaInput extends InputBase {
	constructor(props) {
		super(props);
	}

	render() {
		const {className, style, placeholder, path} = this.props;
		const {value} = this.state;

		return this.renderInputWrapper(
			<Fragment>
				{this.renderPrefix()}
				<Translate>
					{({translate}) =>
						<textarea
							ref={this.setInputRef}
							className={className}
							style={style}
							value={valid(value, '')}
							onChange={e => {this.debounceChange(path, e.target.value)}}
							placeholder={placeholder || translate("textarea_placeholder")}
						/>}
				</Translate>
				{this.renderSuffix()}
				{this.renderInvalidateButton()}
			</Fragment>
		);
	}
}

TextareaInput.propTypes = {};

TextareaInput.stateTypes = {};

export default TextareaInput;
