import React from 'react'
// eslint-disable-next-line no-unused-vars
import PropTypes from 'prop-types'
import InputBase from "./Base/InputBase";
import "../../../styles/components/input.scss";
import { parseBool, resolveDynamicComponent } from "../../../utils/utils";
import { Translate } from "react-localize-redux";

class ToggleButton extends InputBase {
	constructor(props) {
		super(props);
	}

	renderInputWrapper(body) {
		const {label} = this.props;

		if(label) {
			return (
				<label>
					<span>{resolveDynamicComponent(label, <Translate id={label}/>)}:&nbsp;</span>
					{super.renderInputWrapper(body)}
				</label>
			)
		}
		return super.renderInputWrapper(body);
	}

	render() {
		const {className, style} = this.props;

		return (
			<div className={`input-container toggle-button ${className}`} style={style}>
				{
					this.renderInputWrapper(
						<input
							type={"checkbox"}
							ref={this.setInputRef}
							checked={parseBool(this.getFromValueMap(this.state.value, "key")) || false}
							onChange={e => this.debounceChange(this.props.path, e.target.checked)}
							id={"input"}
						/>
					)
				}
			</div>
		);
	}

	static get propTypes() {
		return {
			...super.propTypes,
			label: PropTypes.any
		}
	}

	static get defaultProps() {
		return {
			...super.defaultProps,
			modifyImmediately: true,
		}
	}
}

export default ToggleButton;
