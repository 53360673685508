import { Action_Generic_Edit_Modify, Action_Generic_Fail, Action_Generic_Payload, Action_Generic_Type, GENERIC_EDIT_CANCEL, GENERIC_EDIT_FULFILLED, GENERIC_EDIT_INIT, GENERIC_EDIT_MODIFY, GENERIC_EDIT_PENDING, GENERIC_EDIT_REJECTED, GENERIC_FETCH_FULFILLED, GENERIC_FETCH_PENDING, GENERIC_FETCH_REJECTED } from "../genericActions";

export const E_SettingsActions = {
	FETCH: "SETTINGS_FETCH",
	FETCH_TERMS: "SETTINGS_FETCH_TERMS",
};

const REDUCER_ITEM_NAME = `SETTINGS`;

export const Action_Settings_Fetch_Pending = () => Action_Generic_Type(`${REDUCER_ITEM_NAME}_${GENERIC_FETCH_PENDING}`);
export const Action_Settings_Fetch_Fulfilled = (data, ...props) => Action_Generic_Payload(`${REDUCER_ITEM_NAME}_${GENERIC_FETCH_FULFILLED}`, data, ...props);
export const Action_Settings_Fetch_Rejected = (error, ...props) => Action_Generic_Fail(`${REDUCER_ITEM_NAME}_${GENERIC_FETCH_REJECTED}`, error, ...props);

export const Action_Settings_Edit_Init = (initData, ...props) => Action_Generic_Payload(`${REDUCER_ITEM_NAME}_${GENERIC_EDIT_INIT}`, initData, ...props);
export const Action_Settings_Edit_Cancel = () => Action_Generic_Type(`${REDUCER_ITEM_NAME}_${GENERIC_EDIT_CANCEL}`);
export const Action_Settings_Edit_Modify = (type, path, change, ...props) => (
	Action_Generic_Edit_Modify(`${REDUCER_ITEM_NAME}_${GENERIC_EDIT_MODIFY}`, type, path, change, ...props)
);
export const Action_Settings_Edit_Pending = () => Action_Generic_Type(`${REDUCER_ITEM_NAME}_${GENERIC_EDIT_PENDING}`);
export const Action_Settings_Edit_Fulfilled = (data, ...props) => Action_Generic_Payload(`${REDUCER_ITEM_NAME}_${GENERIC_EDIT_FULFILLED}`, data, ...props);
export const Action_Settings_Edit_Rejected = (error, ...props) => Action_Generic_Fail(`${REDUCER_ITEM_NAME}_${GENERIC_EDIT_REJECTED}`, error, ...props);
