import React from "react";
// eslint-disable-next-line no-unused-vars
import PropTypes from "prop-types";
import TextInput from "../../../shared/Input/TextInput";
import { Translate } from "react-localize-redux";
import { T_SharedPropTypes } from "../../../../models/Models_Shared";
import { service_passwordReset } from "../../../../services/Service_Password";
import { errorHandler } from "../../../../store/ResponseHandling";
import { E_ToastStyle } from "../../../../models/Models_Toast";
import CubeSpinner from "../../../shared/LoadingIndicator/CubeSpinner";

class PasswordRecoveryEmail extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			...this.state,
			email: null,
			sending: false,
		};
	}

	render() {
		const {className, style} = this.props;
		const {email, sending} = this.state;

		return (
			<section className={`forgotten-password-email ${className}`} style={style}>
				<form onSubmit={this._handleSubmit.bind(this)}>
					<Translate>
						{({translate}) => (
							<TextInput
								type={"email"}
								onModify={(t,p,email) => this.setState({email})}
								defaultValue={''}
								placeholder={translate("email")}
								style={{width: "100%"}}
								modifyImmediately={true}
								autoComplete={true}
								name={"email"}
							/>
						)}
					</Translate>
					<button className={"fancy"} style={{marginTop: 15}} disabled={!email || sending}>
						<Translate id={"sendRecoveryEmail"}/>
					</button>
				</form>
				{sending && <CubeSpinner style={{zIndex: 999}}/>}
			</section>
		)
	}

	_handleSubmit(e) {
		e.preventDefault();
		const {onSubmit} = this.props;
		const {email} = this.state;

		this.setState({sending: true});
		service_passwordReset(email).then(() => {
			onSubmit();
			window.toaster.showToast({
				icon: "paper-plane",
				style: E_ToastStyle.SUCCESS,
				content: "success_PASSWORD_RESET_FULFILLED"
			})
		}, error => {
			this.setState({sending: false});
			errorHandler(error, "PASSWORD_RESET_REJECTED");
		});
	}

	static get propTypes() {
		return {
			...super.propTypes,
			...T_SharedPropTypes,
			onSubmit: PropTypes.func,
		}
	}

	static get stateTypes() {
		return {
			...super.stateTypes,
			email: PropTypes.string,
			sending: PropTypes.bool,
		}
	}

	static get defaultProps() {
		return {
			onSubmit: () => null,
		}
	}
}

export default PasswordRecoveryEmail;
