import {E_Currency} from "./constants/Constants_Shared";

/**
 * @enum {String}
 * Session states
 */
export const E_SessionState = {
	PENDING: "PENDING",
	ACTIVE: "ACTIVE",
	COMPLETED: "COMPLETED",
	CANCELED: "CANCELED",
	RESERVATION_WAITING: "RESERVATION_WAITING",
};

export const E_SessionType = {
	REGULAR: "REGULAR",
	RESERVATION: "RESERVATION",
	DELIVERY_COURIER: "DELIVERY_COURIER",
	DELIVERY_CUSTOMER: "DELIVERY_CUSTOMER",
};

export const E_OpenBoxModes = {
	DO_NOT_OPEN: "DO_NOT_OPEN",
	OPEN_NOW: "OPEN_NOW",
	OPEN_DELAYED: "OPEN_DELAYED",
};

export const E_ReservationState = {
	AVAILABLE: "AVAILABLE",
	UNAVAILABLE: "UNAVAILABLE",
	RESERVED: "RESERVED",
};

/**
 * Session Cost Model
 * ---
 * @type {{
 *          paidByCard: Number,
 *          total: Number,
 *          totalBeforeDiscounts: Number,
 *          totalPaidAmount: Number,
 *          unpaidAmount: Number,
 *          additionalAmountBeforeDiscounts: Number,
 *          additionalAmount: Number,
 *          initialAmount: Number,
 *          paidByCredit: Number,
 *          initialAmountBeforeDiscounts: Number
 *       }}
 */
export const T_SessionCostModel = {
	additionalAmount: null,
	additionalAmountBeforeDiscounts: null,
	initialAmount: null,
	initialAmountBeforeDiscounts: null,
	paidByCard: null,
	paidByCredit: null,
	total: null,
	totalBeforeDiscounts: null,
	totalPaidAmount: null,
	unpaidAmount: null
};

/**
 * Session Model
 * ---
 * @type {{
 *          cost: T_SessionCostModel,
 *          voucher: T_VoucherModel,
 *          startedAt: Date,
 *          invoiceEmail: String,
 *          limitTime: String,
 *          paymentResponseText: String,
 *          pin: String,
 *          cardNumbers: String[],
 *          phone: String,
 *          endedAt: Date,
 *          service: T_ServiceMobileModel,
 *          timeBracket: Object,
 *          location: T_LocationNameModel,
 *          tariff: T_TariffModel,
 *          id: NumberConstructor,
 *          state: E_SessionState,
 *          paymentState: E_PaymentState,
 *          invoiceAvailable: Boolean,
 *          user: T_UserAccountModel,
 *          sessionType: E_SessionType,
 *          reservedFrom: Number,
 *          reservedTo: Number,
 *          deliveryType: E_DeliveryType,
 *       }}
 */
export const T_SessionModel = {
	cardNumbers: [],
	cost: {},
	endedAt: null,
	id: null,
	invoiceAvailable: false,
	invoiceEmail: null,
	limitTime: null,
	location: {},
	paymentResponseText: null,
	paymentState: null,
	phone: null,
	pin: null,
	service: {},
	startedAt: null,
	state: null,
	tariff: {},
	timeBracket: {},
	user: {},
	voucher: {},
	sessionType: null,
	reservedFrom: null,
	reservedTo: null,
	deliveryType: null,
};

/**
 * Active Session Mobile Model
 * ---
 * @type {{limitTime: String, startedAt: String, id: Number, user: T_UserAccountModel}}
 */
export const T_ActiveSessionMobileModel = {
	id: null,
	limitTime: null,
	startedAt: null,
	user: {}
};

/**
 * Delivery type enum
 * ---
 * @enum string
 * @readonly
 */
export const E_DeliveryType = {
	COURIER_DROP_OFF: "COURIER_DROP_OFF",
	COURIER_PICK_UP: "COURIER_PICK_UP"
}

/**
 * DeliveryPage Session Request Model
 * ---
 * @type {{
 *          deliveryType: E_DeliveryType,
 *          user: T_UserInfo,
 *          notes: string,
 *          address: string,
 *          continuation: T_DeliverySessionRequestModel,
 *          price: {
 *              price: number,
 *              taxPercent: number,
 *              currency: string
 *          },
 *          serviceID: number,
 *          customID: string,
 *          customPin: string,
 *       }}
 */
export const T_DeliverySessionRequestModel = {
	deliveryType: E_DeliveryType.COURIER_DROP_OFF,
	serviceID: 0,
	address: null,
	notes: null,
	customID: null,
	customPin: null,
	continuation: null,
	user: null,
	price: {
		price: 0,
		currency: E_Currency.CZK,
		taxPercent: 21
	}
};
