import React from "react";
import ItemSelectInputBase from "../../shared/Input/Base/ItemSelectInputBase";
import ServiceCategorySelect from "./ServiceCategorySelect";
import {resolveItemName} from "../../../utils/utils";

class ServiceCategorySelectInput extends React.Component {
	render() {
		return <ItemSelectInputBase
			modalContent={<ServiceCategorySelect/>}
			onLabel={item => resolveItemName(item)}
			{...this.props}
		/>
	}
}

export default ServiceCategorySelectInput;
