import React from "react";
import PropTypes from "prop-types";
import OptionsSelectorBase from "./Base/OptionsSelectorBase";
import ButtonsConstructor from "../ButtonsConstructor";
import is from "../../../utils/is";
import CubeSpinner from "../LoadingIndicator/CubeSpinner";
import {css} from "../../../utils/utils";
import {T_SharedPropTypes} from "../../../models/Models_Shared";
import {Translate} from "react-localize-redux";

export const ItemSelectorContext = React.createContext({});

class ItemSelectorInput extends OptionsSelectorBase {
	constructor(props) {
		super(props);
	}

	componentDidMount() {
		super.componentDidMount();

		this.fetchData();
		this._notifyLabelValue();
		this._focusOnActive();
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		super.componentDidUpdate(prevProps, prevState, snapshot);
		const {refreshContent} = this.props;
		const {options, value} = this.state;

		if(!is.equal(refreshContent, prevProps.refreshContent)) {
			this.fetchData();
			this._notifyLabelValue();
			this._focusOnActive();
		}

		if(!is.equal(options, prevState.options)) {
			this._notifyLabelValue();
			this._focusOnActive();
		}

		if(!is.equal(value, prevState.value)) {
			this._notifyLabelValue();
		}
	}

	_renderOptions() {
		const {value, options} = this.state;
		const {onRestrict, isActive, children} = this.props;
		const filteredOptions = options?.filter?.(option => onRestrict(option)) ?? [];

		if(filteredOptions.length) {
			if(children) {
				return (
					<ItemSelectorContext.Provider value={{
						options,
						filteredOptions,
						isActive: option => isActive(option, this.getFromValueMap(value, "key")),
						selectOption: (option) => this.selectOption(option),
					}}>
						{children}
					</ItemSelectorContext.Provider>
				);
			}

			return (
				<ButtonsConstructor
					buttons={filteredOptions.map((option, i) => ({
						text: this.getTranslate(option),
						textTranslated: true,
						active: isActive(option, this.getFromValueMap(value, "key")),
						action: () => {
							if(!isActive(option, this.getFromValueMap(value, "key"))) {
								this.selectOption(option);
							}
						},
						props: {
							autoFocus: isActive(option, this.getFromValueMap(value, "key")),
						}
					}))}
				/>
			)
		}

		return (
			<label style={css(`flex: 1; display: flex; justify-content: center; align-items: center;`)}>
				<Translate id={"noEntries"}/>
			</label>
		);
	}

	render() {
		const {pending} = this.state;
		const {style, className} = this.props;

		if(pending) {
			return <CubeSpinner block={true} style={css(`padding: 10px; --cube-color: currentColor;`)}/>
		}

		return (
			<div
				ref={this.setRef}
				name={"options"}
				className={`options ${className}`}
				style={style}
			>
				{this._renderOptions()}
			</div>
		)
	}

	_notifyLabelValue() {
		const {onLabelValue, isActive} = this.props;
		const {options, value} = this.state;
		onLabelValue(this.getTranslate((options || []).find(option => isActive(option, this.getFromValueMap(value, "key")))));
	}

	_focusOnActive() {
		clearTimeout(this._focusTimeout);
		this._focusTimeout = setTimeout(() => {
			let parent = this.options;
			if(parent) {
				let active = parent.querySelector("[active]");
				if(active && parent) {
					let offset = active.offsetTop - this.options.getBoundingClientRect().height / 2;
					parent && parent.scrollTo && parent.scrollTo(0, offset);
				}
			}
		}, 200);
	}

	static get propTypes() {
		return {
			...super.propTypes,
			...T_SharedPropTypes,
			fetch: PropTypes.func,
			selector: PropTypes.func,
			thunk: PropTypes.func,

			// Processing exposed functions
			onRestrict: PropTypes.func, // Restriction processor // Is restricted; false = restricted => should not be seen
			isActive: PropTypes.func, // Active processor // Which item should be active; true = active
		}
	}

	static get stateTypes() {
		return {
			...super.stateTypes,
		}
	}

	static get defaultProps() {
		return {
			...super.defaultProps,
			onRestrict: () => true, // Restriction processor // Is restricted; false = restricted => should not be seen
			isActive: (option, value) => option == value || (option && value && option.id && value.id && option.id == value.id),
			onLabelValue: v => v,
		}
	}
}

export default ItemSelectorInput;
