import { AUTHENTICATE_FULFILLED, AUTHENTICATE_PENDING, AUTHENTICATE_REJECTED, AUTHENTICATE_TRUNCATE, FETCH_PRINCIPAL_FULFILLED, FETCH_PRINCIPAL_PENDING, FETCH_PRINCIPAL_REJECTED } from '../actions/authActions';
import { GENERIC_EDIT_FULFILLED } from "../actions/genericActions";
import { get } from "../../utils/utils";
import { EO } from "../../utils/extensions";

function handlePossibleProfileUpdate(state, action) {
	if(get(state, `principal.id`, -2) == get(action, `payload.id`, -1)) {
		return {
			...state,
			principal: EO(action.payload).clone(true),
		};
	}
	return state;
}

// INITIALIZE STATE

const initialState = {
	principal: null,
	authenticating: false,
	authenticated: false,
	loggedIn: false,
	fetching: false,
	fetched: false,
	failed: false,
	initialized: false,
};

// REDUCER

export default (state = initialState, action) => {
	switch (action.type) {
		case AUTHENTICATE_PENDING:
			return {
				...state,
				authenticating: true,
				authenticated: false,
			};
		case AUTHENTICATE_FULFILLED:
			return {
				...state,
				authenticating: false,
				authenticated: true,
			};
		case AUTHENTICATE_REJECTED:
			return {
				...state,
				loggedIn: false,
				authenticating: false,
				authenticated: false,
			};

		case FETCH_PRINCIPAL_PENDING:
			return {
				...state,
				loggedIn: false,
				fetching: true,
				fetched: false,
				failed: false
			};
		case FETCH_PRINCIPAL_FULFILLED:
			return {
				...state,
				principal: action.payload,
				loggedIn: true,
				fetching: false,
				fetched: true,
				failed: false,
				initialized: true,
			};
		case FETCH_PRINCIPAL_REJECTED:
			return {
				...state,
				principal: null,
				loggedIn: false,
				fetching: false,
				fetched: false,
				failed: true,
				initialized: true,
			};
		case AUTHENTICATE_TRUNCATE:
			return {
				...state,
				...initialState
			}
		case `USER_${GENERIC_EDIT_FULFILLED}`:
			return handlePossibleProfileUpdate(state, action);
		default:
			return state;
	}
};
