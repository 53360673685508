import React from "react";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import ItemSelect from "../../../../../shared/Input/ItemSelect";
import {Thunk_Location_Fetch} from "../../../../../../store/thunk/Thunk_Location";
import LocationSelect from "../../../LocationSelect";
import PropTypes from "prop-types";
import {resolveItemName} from "../../../../../../utils/utils";
import {service_fetchServicesOfLocation} from "../../../../../../services/Service_Service";

const E_Page = {
	LOCATION: "LOCATION",
	SERVICE: "SERVICE",
}

class LocationStorageServiceSelect extends React.Component {
	constructor() {
		super();

		this.state = {
			page: E_Page.LOCATION,
			location: null,
		}
	}

	render() {
		const {page, location} = this.state;

		if(page === E_Page.LOCATION) {
			return <LocationSelect
				defaultValue={location}
				onLabel={item => resolveItemName(item)}
				onModify={(_t, _p, location) => this.setState({location})}
				actionButtons={(defaultButtons, selectionComponent) => {
					defaultButtons = [...defaultButtons]

					defaultButtons[0] = {
						...defaultButtons[0],
						icon: "arrow-right",
						text: "continue",
						action: () => {
							selectionComponent.applyChange();
							this.setState({page: E_Page.SERVICE});
						}
					}

					return defaultButtons
				}}
			/>
		}


		if(location?.id) {
			return <ItemSelect
				canSearch={false}
				showFooter={false}
				service={() => service_fetchServicesOfLocation(location.id)}
				label={"locationService_select"}
				headerItems={{name: {}, id: {}}}
				actionButtons={(defaultActionButtons) => {
					return [
						{
							icon: "arrow-left",
							text: "back",
							action: () => this.setState({page: E_Page.LOCATION, location: null}),
						},
						...defaultActionButtons
					];
				}}
				{...this.props}
			/>
		}
	}

	static get propTypes() {
		return ItemSelect.propTypes
	}

	static get stateTypes() {
		return {
			page: PropTypes.string.isRequired,
			location: PropTypes.object,

			Thunk_Location_Fetch: PropTypes.func.isRequired,
		}
	}
}

const mapDispatchToProps = dispatch => (
	bindActionCreators({
		Thunk_Location_Fetch
	}, dispatch)
);

export default connect(null, mapDispatchToProps)(LocationStorageServiceSelect);
