import React from 'react'
// eslint-disable-next-line no-unused-vars
import PropTypes from 'prop-types'
import { withLocalize } from "react-localize-redux";
import is from "../../../utils/is";

/**
 * Utility class for outside of store's reach translations
 */
class Translator extends React.Component {
	constructor(props) {
		super(props);

		this.state = {};

		window.translator = this;
	}

	render() {return null}

	/**
	 * Translate
	 * ---
	 * @param {String} key Translation Key
	 * @param {Object} options Translation options
	 * @param {Boolean} suppressInvalid Suppress invalid translation warning
	 * @return {String} Translated text
	 */
	translate(key, options = {}, suppressInvalid = false) {
		if (is.string(key)) {
			suppressInvalid && (options = {...options, onMissingTranslation: this.onMissingTranslation});
			return this.props.translate(`${key}`, null, options);
		}
		return key;
	}

	onMissingTranslation({translationId}) {
		return translationId;
	}

	translateEntry(entries, options = {}, suppressInvalid = false) {
		return this.translate(
			(
				entries.find(option => option.languageCode == this.props.activeLanguage.code) ||
				entries.find(option => option.languageCode == "cs") ||
				entries[0] ||
				{}
			).text,
			options,
			suppressInvalid
		);
	}

	getActiveLanguage(onlyCode = false) {
		const {activeLanguage} = this.props;
		return onlyCode ? activeLanguage.code : activeLanguage;
	}
}

Translator.propTypes = {
	// React Localize Redux
	translate: PropTypes.func.isRequired,
	activeLanguage: PropTypes.object
};

Translator.stateTypes = {};

export default withLocalize(Translator);
