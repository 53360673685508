import React from 'react';
import { Switch, Route } from 'react-router-dom';
import routes from '../../../routes/routes';
import UserDetail from "./UserDetail";
import UsersList from "./UsersList";

class UserBrowser extends React.Component {

	constructor(props) {
		super(props);
	}

	render() {
		return (
			<Switch>
				<Route exact path={routes.USERS}>
					<UsersList />
				</Route>
				<Route path={`${routes.USERS}/:userID/:userSection?`} render={props => <UserDetail userID={parseInt(props.match.params.userID)} />} />
			</Switch>
		);
	}
}

export default UserBrowser;
