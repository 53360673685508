// eslint-disable-next-line no-unused-vars
import { DELETE, formatQueryString, POST, PUT, sendRequest } from "./apiServices/apiConnector";

export const service_fetchServiceCategories = (queryString = null) => {
	return sendRequest(`/service/category/${formatQueryString(queryString)}`).then(response => response.json());
};

export const service_fetchServiceCategory = (serviceCategoryID) => {
	return sendRequest(`/service/category/${serviceCategoryID}`).then(response => response.json());
};

export const service_insertServiceCategory = (body) => {
	return sendRequest([`/service/category/`, POST, body]).then(response => response.json());
};

export const service_updateServiceCategory = (serviceCategoryID, body) => {
	return sendRequest([`/service/category/${serviceCategoryID}`, PUT, body]).then(response => response.json());
};

export const service_deleteServiceCategory = (serviceCategoryID) => {
	return sendRequest([`/service/category/${serviceCategoryID}`, DELETE]).then(response => response);
};
