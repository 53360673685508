export const Selector_Feedback_Root = state => {
	return state.feedback;
};

export const Selector_Feedback_Listing_Root = state => {
	return Selector_Feedback_Root(state).listing;
};

export const Selector_Feedback_Listing_Data = state => {
	return Selector_Feedback_Listing_Root(state).data;
};

export const Selector_FeedbackItem_Root = state => {
	return Selector_Feedback_Root(state).item;
};

export const Selector_FeedbackItem_Data = state => {
	return Selector_FeedbackItem_Root(state).data;
};

export const Selector_FeedbackItem_Edit = state => {
	return Selector_FeedbackItem_Root(state).edit;
};
