import React from "react";
import PropTypes from "prop-types";
import {T_frameModel} from "../../../../../models/Models_StorageBox";
import Dropdown from "../../../../shared/Input/Dropdown";
import {EO} from "../../../../../utils/extensions";
import {E_Visibility} from "../../../../../models/Models_Location";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {Thunk_Frame_Remove, Thunk_Frame_Update} from "../../../../../store/thunk/Thunk_StorageFrame";
import {Translate} from "react-localize-redux";
import {T_APP_ICONS} from "../../../../../models/constants/Constants_Shared";
import Fa from "../../../../tools/Icons/Fa";
import {formatAsDate} from "../../../../../utils/utils";
import {E_ButtonPresets} from "../../../../../models/Models_ButtonPresets";

class StorageFrameDetail extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			...this.state,
			frame: props.frame,
		};
	}

	componentDidMount() {
		const {Thunk_Frame_Update, Thunk_Frame_Remove, frame, path} = this.props;

		window.modal.modifyActiveContent({
			header: <label><Translate id={"frame_detail"}/></label>,
			actionButtons: [
				{
					preset: E_ButtonPresets.REMOVE,
					action: () => {
						Thunk_Frame_Remove(frame.id);
						window.modal.close();
					}
				},
				{
					icon: T_APP_ICONS.SAVE,
					tippy: <Translate id={"save"}/>,
					action: () => {
						Thunk_Frame_Update(`${path}.frames.#${frame.id}`, this.state.frame);
						window.modal.close();
					},
				},
			]
		})
	}

	render() {
		const {className, style} = this.props;
		const {frame} = this.state;

		return (
			<section className={className} style={style}>
				<div className={"grid-table grid-icon-auto-1fr"}>
					<Fa icon={T_APP_ICONS.CREATED_ON}/>
					<label><Translate id={"createdOn"}/></label>
					<span>{formatAsDate(frame.createdOn)}</span>

					<Fa icon={"eye"} />
					<label><Translate id={"visibility"}/></label>
					<Dropdown
						options={EO(E_Visibility).toArray(true)}
						path={`visibilityType`}
						onModify={(t,path, value) => this.setState(state => ({frame: {...state.frame, [path]: value}}))}
						onLabel={option => window.translator.translate(`visibility_${option}`)}
						defaultValue={frame.visibilityType}
					/>
				</div>
			</section>
		)
	}

	static get propTypes() {
		return {
			...super.propTypes,
			frame: PropTypes.object.isRequired,
			path: PropTypes.string,
		}
	}

	static get stateTypes() {
		return {
			...super.stateTypes,
		}
	}

	static get defaultProps() {
		return {
			...super.defaultProps,
			frame: T_frameModel
		}
	}
}

const mapDispatchToProps = dispatch => (
	bindActionCreators({
		Thunk_Frame_Update,
		Thunk_Frame_Remove,
	}, dispatch)
);

export default connect(null, mapDispatchToProps)(StorageFrameDetail);
