import React, { Fragment } from "react";
// eslint-disable-next-line no-unused-vars
import PropTypes from "prop-types";
import { Thunk_Settings_Fetch, Thunk_Settings_Update } from "../../../store/thunk/Thunk_Settings";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import DetailWrapper from "../../shared/DetailWrapper";
import { Action_Settings_Edit_Modify, E_SettingsActions } from "../../../store/actions/configuration/settingsActions";
import { Translate } from "react-localize-redux";
import Toolbar from "../../shared/Toolbar";
import { T_SettingsModel } from "../../../models/Models_Settings";
import Address from "../../shared/Address";
import { T_BusinessDetails } from "../../../models/Models_Partner";
import LanguageSwitch from "../../tools/Language/LanguageSwitch";
import TextareaInput from "../../shared/Input/TextareaInput";
import SettingsTermsAndConditions from "./SettingsTermsAndConditions";

class SettingsPage extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			...super.state,
		};
	}

	componentDidMount() {
		const {Thunk_Settings_Fetch} = this.props;
		Thunk_Settings_Fetch();
	}

	/* === === === === === === ===
			RENDER TOOLBAR
	 === === === === === === === */
	_renderToolbar() {
		const {fetching, failed, Thunk_Settings_Update} = this.props;

		return <Toolbar
			section={"settings-page"}
			isEdit={true}
			itemID={0}
			content={<h1><Translate id={"settings"} /></h1>}
			onSave={() => Thunk_Settings_Update()}
			disableActionButtons={fetching || failed}
		/>;
	}

	_renderAddress(addressData, path) {
		const {Action_Settings_Edit_Modify} = this.props;

		return (
			<Address
				address={addressData}
				path={path}
				onModify={Action_Settings_Edit_Modify}
				model={T_BusinessDetails}
				isEdit={true}
			/>
		)
	}

	_renderSettings(settings) {
		const {Action_Settings_Edit_Modify} = this.props;

		return (
			<Fragment>
				{this._renderToolbar()}

				<div className={"content-wrapper"}>
					<LanguageSwitch
						label={<Translate id={"hotlineInfo"}/>}
						path={"hotlineInfo"}
						value={settings.hotlineInfo}
						onModify={Action_Settings_Edit_Modify}
					>
						<TextareaInput />
					</LanguageSwitch>

					{/*Public contact info*/}
					<div className={"separator"}>
						<label><Translate id={"settings_publicContact"}/></label>
					</div>
					{this._renderAddress(settings.publicContact, "publicContact")}

					{/*Invoice supplier*/}
					<div className={"separator"}>
						<label><Translate id={"settings_invoiceSupplier"}/></label>
					</div>
					{this._renderAddress(settings.invoiceSupplier, "invoiceSupplier")}

					<div className={"separator"}>
						<label><Translate id={"termsAndConditions"}/></label>
					</div>

					<SettingsTermsAndConditions/>
				</div>
			</Fragment>
		);
	}

	render() {
		const {settings} = this.props;

		return (
			<DetailWrapper
				className={"settings"}
				isEdit={true}
				errorContent={`error_${E_SettingsActions.FETCH}`}
				selector={state => state.settings}
			>
				{this._renderSettings(settings || T_SettingsModel)}
			</DetailWrapper>
		);
	}

	static get propTypes() {
		return {
			...super.propTypes,
			fetching: PropTypes.bool,
			fetched: PropTypes.bool,
			failed: PropTypes.bool,
			error: PropTypes.object,
			settings: PropTypes.object,

			Thunk_Settings_Fetch: PropTypes.func,
			Thunk_Settings_Update: PropTypes.func,
			Action_Settings_Edit_Modify: PropTypes.func,
		}
	}

	static get stateTypes() {
		return {
			...super.stateTypes,
		}
	}

	static get defaultProps() {
		return {
			...super.defaultProps,
			settings: T_SettingsModel,
		}
	}
}

const mapStateToProps = state => {
	return {
		settings: state.settings.edit,
	};
};

const mapDispatchToProps = dispatch => (
	bindActionCreators({
		Thunk_Settings_Fetch,
		Thunk_Settings_Update,
		Action_Settings_Edit_Modify,
	}, dispatch)
);

export default connect(mapStateToProps, mapDispatchToProps)(SettingsPage);
