import React, {Fragment} from "react";
import PropTypes from "prop-types";
import {Thunk_Service_Fetch, Thunk_Service_Remove, Thunk_Service_Save} from "../../../../../../store/thunk/Thunk_StorageService";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import is from "../../../../../../utils/is";
import {Selector_Service, Selector_Service_Edit, Selector_Service_Root} from "../../../../../../store/selectors/Selectors_LocationService";
import {Selector_CommunicationUnits_Listing_Data} from "../../../../../../store/selectors/Selectors_CommunicationUnit";
import {Translate} from "react-localize-redux";
import {E_ControlType, E_LockState, T_LedConfiguration, T_LockConfiguration, T_ServiceConfiguration, T_serviceModel} from "../../../../../../models/Models_StorageService";
import {getVisibilityTranslationKeys, TranslateEntry} from "../../../../../../utils/localeUtils";
import ToggleButton from "../../../../../shared/Input/ToggleButton";
import {E_Visibility} from "../../../../../../models/Models_Location";
import Fa from "../../../../../tools/Icons/Fa";
import Dropdown from "../../../../../shared/Input/Dropdown";
import {css, get, resolveItemName} from "../../../../../../utils/utils";
import ButtonsConstructor from "../../../../../shared/ButtonsConstructor";
import routes from "../../../../../../routes/routes";
import {Process_formatQueryStringForURL} from "../../../../../../utils/processors";
import {E_UserRoles, T_APP_ICONS} from "../../../../../../models/constants/Constants_Shared";
import TextInput from "../../../../../shared/Input/TextInput";
import LedConfigurationModal from "./LedConfigurationModal";
import LockConfigurationModal from "./LockConfigurationModal";
import ServiceConfigurationModal from "./ServiceConfigurationModal";
import LockHistory from "./LockHistory";
import NewSessionModal from "../../../../Sessions/NewSessionModal";
import NewDeliveryModal from "../../../../Sessions/NewDeliveryModal";
import {service_openServiceNow} from "../../../../../../services/Service_Maintenance";
import {E_ToastStyle} from "../../../../../../models/Models_Toast";
import "../../../../../../styles/pages/Location/StorageUnit/StorageServiceDetail.scss";
import {Action_Service_Clear, Action_Service_Edit_Cancel, Action_Service_Edit_Init, Action_Service_Edit_Modify, E_ServiceActions} from "../../../../../../store/actions/location/serviceActions";
import {Thunk_ServiceTypes_Fetch_All} from "../../../../../../store/thunk/Thunk_ServiceTypes";
import QS from "query-string";
import {E_ExpandMode} from "../../../../../shared/ExpandContainer";
import {E_ButtonPresets} from "../../../../../../models/Models_ButtonPresets";
import {withRouter} from "react-router-dom";
import DetailWrapper from "../../../../../shared/DetailWrapper";
import {errorHandler} from "../../../../../../store/ResponseHandling";
import {T_RestrictionPresets} from "../../../../../../models/Models_Restrictions";
import Restricted from "../../../../../shared/Restrictions/Restricted";
import ItemRedirect from "../../../../../shared/ItemRedirect";
import ServiceCameraModal from "./ServiceCameraModal";
import ReservationCalendar from "../../../../Sessions/ReservationCalendar/ReservationCalendar";
import NewReservationModal from "../../../../Sessions/NewReservationModal";
import StorageServiceActionsDetail from "./StorageServiceActionsDetail";
import ServiceReservationRestrictionsModal from "./ServiceReservationRestrictionsModal";
import PartnerSelectInput from "../../../../Partners/PartnerSelectInput";
import ServiceTypeSelectInput from "../../../../ServiceTypes/ServiceTypeSelectInput";

class StorageServiceDetail extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			...this.state,
			blockID: null,
		};
	}

	componentDidMount() {
		this._initializeService();
		this._updateHeader();
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		const {serviceID, fetched, edit, service} = this.props;

		if(!is.equal(prevProps.serviceID, serviceID)) {
			this._initializeService();
		}

		if(
			!is.equal(prevProps.serviceID, serviceID) ||
			!is.equal(prevProps.fetched, fetched) ||
			!is.equal(prevProps.edit, edit) ||
			!is.equal(prevProps.service, service)
		) {
			this._updateHeader();
		}
	}

	shouldComponentUpdate(nextProps, nextState, nextContext) {
		if(is.equal(nextProps.serviceID, nextProps.deleted)) {
			//Is within location unit modal; (not window.modal!)
			if(this.props.isWithinModal) {
				this.props.onChangeExpandMode(E_ExpandMode.COLLAPSED);
			} else {
				window.modal.close();
			}

			return false;
		}

		return true;
	}

	componentWillUnmount() {
		const {Action_Service_Clear} = this.props;

		Action_Service_Clear();
	}

	_renderService(service) {
		const {Action_Service_Edit_Modify, communicationUnits, edit, Thunk_ServiceTypes_Fetch_All, isWithinModal} = this.props;
		const isEdit = is.valid(edit);
		const primaryAction = get(service, "actions", []).find(action => action.primary);

		return (
			<div className={"flex-row service-data"}>
				<div className={"service-settings"}>
					<div className={"grid-table grid-icon-auto-1fr"}>
						{
							!isWithinModal &&
							<Fragment>
								<Fa icon={"tag"} />
								<label><Translate id={"name"} /></label>
								{
									this._isEditSwitch(
										<span>{get(service, `physicalReference`, <Translate id={"service_unnamed"} />)}</span>,
										<TextInput
											path={`physicalReference`}
											defaultValue={edit.physicalReference || ''}
											onModify={Action_Service_Edit_Modify}
											placeholder={window.translator.translate("service_name")}
										/>
									)
								}
							</Fragment>
						}

						<Fa icon={"power-off"} />
						<label><Translate id={"state_ENABLED"} /></label>
						{
							this._isEditSwitch(
								<span>{<Translate id={service.enabled ? "yes" : "no"} />}</span>,
								<ToggleButton
									path={`enabled`}
									defaultValue={service.enabled || false}
									onModify={Action_Service_Edit_Modify}
								/>
							)
						}

						<Fa icon={"eye"} />
						<label><Translate id={"visibility"} /></label>
						{
							this._isEditSwitch(
								<span>{<Translate id={getVisibilityTranslationKeys(service.visibilityType)} />}</span>,
								<Dropdown
									contentClassName={"dark"}
									options={Object.values(E_Visibility)}
									path={`visibilityType`}
									onModify={Action_Service_Edit_Modify}
									onLabel={option => window.translator.translate(getVisibilityTranslationKeys(option))}
									defaultValue={service.visibilityType || E_Visibility.HIDDEN}
								/>
							)
						}

						{
							this._isEditSwitch(
								<Fragment>
									<Fa icon={"lock"} />
									<label><Translate id={"lockState"} /></label>
									<span><Translate id={`lockState_${service.lockState || E_LockState.UNAVAILABLE}`} /></span>
								</Fragment>
							)
						}

						<Restricted
							applyRestrictions={isEdit}
							restricted={T_RestrictionPresets.GLOBAL_CONFIGURATION}
						>
							<Fa icon={"tools"} />
							<label><Translate id={"serviceType"} /></label>
							{
								this._isEditSwitch(
									<span>
										{
											get(service, `serviceType.id`) &&
											<ItemRedirect
												wrapChildren={true}
												href={`${routes.SERVICE_TYPES}/${service.serviceType.id}`}
												restricted={T_RestrictionPresets.GLOBAL_CONFIGURATION}
											>
												<TranslateEntry entry={service.serviceType.titles} />
											</ItemRedirect>
											||
											<Translate id={"value_notProvided"} />
										}
									</span>,
									<ServiceTypeSelectInput
										defaultValue={service.serviceType}
										path={`serviceType`}
										onModify={Action_Service_Edit_Modify}
										canInvalidate={true}
									/>
								)
							}
						</Restricted>

						<Restricted role={E_UserRoles.ADMIN}>
							<Fa icon={T_APP_ICONS.PARTNERS} />
							<label><Translate id={"limitToPartner"} /></label>
							{
								this._isEditSwitch(
									service.partnerLimit &&
									<span>
										<ItemRedirect href={`${routes.PARTNERS}/${service.partnerLimit.id}`} wrapChildren={true}>
											<span>{resolveItemName(service.partnerLimit)}</span>
										</ItemRedirect>
									</span>
									||
									'-',
									<PartnerSelectInput
										path={`partnerLimit`}
										defaultValue={service.partnerLimit}
										onModify={Action_Service_Edit_Modify}
									/>
								)
							}
						</Restricted>
					</div>

					<StorageServiceActionsDetail
						actions={service.actions}
						isEdit={isEdit}
						onModify={Action_Service_Edit_Modify}
						communicationUnits={get(communicationUnits, "content", [])}
						onExecuteAction={(actionID) => this._unlock(actionID)}
					/>
				</div>

				<ButtonsConstructor
					wrapInNav={true}
					className={"service-buttons"}
					buttons={
						isEdit &&
						[
							{
								icon: "palette",
								text: "ledConfiguration_openEdit",
								action: () => this._openConfiguration(`led`)
							},
							{
								icon: "lock",
								text: "lockConfiguration_openEdit",
								action: () => this._openConfiguration(`lock`)
							},
							{
								icon: "cogs",
								text: "serviceConfiguration_openEdit",
								action: () => this._openConfiguration(`service`)
							},
							[E_ControlType.RESERVATION].includes(get(service, "serviceType.controlType")) && {
								icon: "user-slash",
								text: "reservationRestrictions_openEdit",
								action: () => this._openConfiguration(`reservationRestrictions`)
							}
						]
						||
						[
							{
								icon: "history",
								text: "history",
								href: `${routes.SESSIONS}?${Process_formatQueryStringForURL({
									filters: `service:${service.id}`
								})}`,
								action: () => window.modal.close(),
								restricted: T_RestrictionPresets.SESSIONS
							},
							{
								icon: "unlock-alt",
								text: "unlock",
								action: () => this._unlock(),
								confirm: <span><Fa icon={"unlock-alt"} /> <Translate id={"unlock"} /></span>,
								restricted: T_RestrictionPresets.LOCK_CONTROL
							},
							service.activeSession && {
								text: "activeSession_redirect",
								icon: T_APP_ICONS.OCCUPIED,
								href: `${routes.SESSIONS}/${get(service, `activeSession.id`)}`,
								action: () => window.modal.close(),
								restricted: T_RestrictionPresets.SESSIONS
							},
							([E_ControlType.RESERVATION].includes(get(service, "serviceType.controlType")) ? true : !service.activeSession) && {
								text: "reserve",
								icon: T_APP_ICONS.SESSIONS,
								action: () => {
									if([E_ControlType.RESERVATION].includes(get(service, "serviceType.controlType"))) {
										this._createReservation();
									}
									else {
										this._createNewSession()
									}
								},
								disabled: !get(service, `serviceType.id`),
								disabledTippy: <div style={{padding: 5}}><Translate id={"serviceType_mustBeDefined"} /></div>
							},
							!service.activeSession && {
								text: "newDelivery",
								icon: "truck",
								action: () => this._createNewDelivery(),
								disabled: !get(service, `serviceType.id`),
								disabledTippy: <div style={{padding: 5}}><Translate id={"serviceType_mustBeDefined"} /></div>
							},
							[E_ControlType.RESERVATION].includes(get(service, "serviceType.controlType")) && {
								icon: "hourglass-start",
								text: "reservationCalendar",
								action: () => this._openReservationCalendar(),
								restricted: {role: E_UserRoles.ADMIN},
							},
							{
								icon: "lock",
								text: "lockHistory",
								action: () => this._openLockHistory(),
								restricted: {role: E_UserRoles.ADMIN}
							},
							get(primaryAction, "communicationUnit.cameraSupported", false) && {
								icon: "camera",
								text: "cameraRecord",
								action: () => this._openServiceCameraModal(),
								restricted: {role: E_UserRoles.ADMIN, ...T_RestrictionPresets.EDIT("locations")}
							}
						]
					}
				/>
			</div>
		)
	}

	render() {
		const {className, style, service, edit, match, serviceID} = this.props;
		const {locationID, unitID} = match.params;

		return (
			<DetailWrapper
				itemID={serviceID}
				dataID={(service || {}).id}
				className={`storage-service-detail ${className} dark`}
				style={style}
				isEdit={is.valid(edit)}
				listUrl={`${routes.LOCATIONS}/${locationID}/${unitID}/${service.id}`}
				errorContent={"service_fetch_failed"}
				selector={Selector_Service_Root}
				spinnerProps={{block: true, style: {"--cube-color": "var(--secondary-theme-color)"}}}
				errorBlockProps={{className: "overlap-container border dark"}}
			>
				{this._renderService((is.valid(edit) ? edit : service) || T_serviceModel)}
			</DetailWrapper>
		);
	}

	_isEditSwitch(normal, edit) {
		return is.valid(this.props.edit) ? edit : normal;
	}

	_initializeService() {
		const {serviceID, Thunk_Service_Fetch, Action_Service_Edit_Init} = this.props;

		if(serviceID > 0) {
			Thunk_Service_Fetch(serviceID);
		} else if(serviceID === 0) {
			let {position, block} = QS.parse(window.location.search);
			block && this.setState({blockID: block});

			Action_Service_Edit_Init({
				...T_serviceModel,
				orderPosition: position || 0,
			});
		}
	}

	_openConfiguration(configuration) {
		const {edit, Action_Service_Edit_Modify, communicationUnits} = this.props;

		switch (configuration) {
			case `led`:
				return window.modal.open({
					header: <label><Translate id={"ledConfiguration"}/></label>,
					body: (
						<LedConfigurationModal
							configuration={edit.ledConfiguration || T_LedConfiguration}
							onModify={Action_Service_Edit_Modify}
							units={communicationUnits}
							path={`ledConfiguration`}
						/>
					)
				});
			case `lock`:
				return window.modal.open({
					header: <label><Translate id={"lockConfiguration"}/></label>,
					body: (
						<LockConfigurationModal
							configuration={edit.lockConfiguration || T_LockConfiguration}
							onModify={Action_Service_Edit_Modify}
							units={communicationUnits}
							path={`lockConfiguration`}
						/>
					)
				});
			case `service`:
				return window.modal.open({
					header: <label><Translate id={"serviceConfiguration"}/></label>,
					body: (
						<ServiceConfigurationModal
							configuration={edit.serviceConfiguration || T_ServiceConfiguration}
							onModify={Action_Service_Edit_Modify}
							path={`serviceConfiguration`}
						/>
					)
				});
			case `reservationRestrictions`:
				return window.modal.open({
					header: <label><Translate id={"reservationRestrictions"}/></label>,
					className: "full-height-content",
					style: {width: 850},
					body: (
						<ServiceReservationRestrictionsModal
							onModify={Action_Service_Edit_Modify}
							path={`serviceConfiguration`}
						/>
					)
				});
		}
	}

	_openLockHistory() {
		const {serviceID} = this.props;
		window.modal.open({
			body: <LockHistory serviceID={serviceID}/>,
			className: "full-content",
		})
	}

	_openServiceCameraModal() {
		const {serviceID} = this.props;
		window.modal.open({
			header: <label><Translate id={"cameraRecord"}/></label>,
			body: <ServiceCameraModal serviceID={serviceID}/>,
		})
	}

	_createNewSession() {
		const {serviceID, service} = this.props;
		window.modal.open({
			body: <NewSessionModal serviceID={serviceID} tariff={get(service, `serviceType.tariff`)}/>,
			header: <label><Translate id={"reserve"}/></label>
		})
	}

	_openReservationCalendar() {
		const {serviceID} = this.props;
		window.modal.open({
			body: (
				<div className={"absolute-overlap"}>
					<div className={"separator title compact"} style={css(`position: relative; z-index: -1; margin: 0px;`)}>
						<label><Translate id={"reservationCalendar"}/></label>
					</div>
					<ReservationCalendar serviceID={serviceID} edit={false} onRedirect={() => window.modal.close()}/>
				</div>
			),
			className: "full-content",
		});
	}

	_createReservation() {
		const {serviceID} = this.props;
		window.modal.open({
			body: <NewReservationModal serviceID={serviceID}/>,
			header: <label><Translate id={"reserve"}/></label>,
			className: "full-content",
		});
	}

	_createNewDelivery() {
		const {serviceID} = this.props;

		window.modal.open({
			body: <NewDeliveryModal serviceID={serviceID} />,
			className: "wide-content",
			header: <label><Translate id={"newDelivery"}/></label>
		})
	}

	_unlock(actionID) {
		const {serviceID} = this.props;
		service_openServiceNow(serviceID, actionID).then(() => {
			window.toaster.showToast({
				style: E_ToastStyle.SUCCESS,
				icon: "lock-open",
				content: "service_lock_open_success",
			});
		}, error => {
			errorHandler(error, E_ServiceActions.DOOR_OPEN);
		})
	}

	_updateHeader() {
		const {isWithinModal, onUpdateHeader} = this.props;
		const {serviceID, fetched, Action_Service_Edit_Init, Action_Service_Edit_Modify, edit, service, Thunk_Service_Remove, Action_Service_Edit_Cancel} = this.props;
		const isEdit = is.valid(edit);

		if (!fetched) {return;}

		let actionButtons  = [
			{
				style: {fontSize: "0.9em"},
				icon: T_APP_ICONS.EDIT,
				tippy: <Translate id={"service_edit"} />,
				action: () => Action_Service_Edit_Init(service),
				restricted: T_RestrictionPresets.EDIT("locations"),
			}
		];

		if (is.valid(edit)) {
			actionButtons = [
				service.id && {
					preset: E_ButtonPresets.REMOVE,
					action: () => Thunk_Service_Remove(serviceID),
				},
				{
					className: "saveService",
					icon: T_APP_ICONS.SAVE,
					tippy: <Translate id={`save`} />,
					action: () => this._saveService(),
				}
			];
		}

		if(isWithinModal) {
			onUpdateHeader({
				label: this._isEditSwitch(
					<h1>{get(service, `physicalReference`, <Translate id={"service_unnamed"} />)}</h1>,
					<TextInput
						className={"dark"}
						path={`physicalReference`}
						defaultValue={edit.physicalReference || ''}
						onModify={Action_Service_Edit_Modify}
						placeholder={window.translator.translate("service_name")}
					/>
				),
				actionButtons,
				closeButton: isEdit && serviceID > 0 ? {
					icon: T_APP_ICONS.CLOSE,
					tippy: <Translate id={"cancel"}/>,
					action: () => Action_Service_Edit_Cancel(),
				} : true
			});
		} else {
			window.modal.modifyActiveContent({
				header: (
					<label
						className={"cl-secondary"}
						style={css(`font-size: 1.3em;`)}
					>
						{get(this._isEditSwitch(service, edit), `physicalReference`, <Translate id={"service_unnamed"} />)}
					</label>
				),
				actionButtons,
				closeButton: isEdit && serviceID > 0 ? {
					icon: T_APP_ICONS.CLOSE,
					tippy: <Translate id={"cancel"}/>,
					action: () => Action_Service_Edit_Cancel(),
				} : true,
			});
		}
	}

	_saveService() {
		const {Thunk_Service_Save} = this.props;
		let {block} = QS.parse(window.location.search);

		Thunk_Service_Save(block);
	}

	static get propTypes() {
		return {
			...super.propTypes,
			serviceID: PropTypes.number,

			isWithinModal: PropTypes.bool,
		}
	}

	static get stateTypes() {
		return {
			...super.stateTypes,
			blockID: PropTypes.number,
		}
	}

	static get defaultProps() {
		return {
			...super.defaultProps,
			isWithinModal: false,
			onUpdateHeader: () => null,
		}
	}
}

const mapStateToProps = state => {
	const {fetching, fetched, failed, deleted} = Selector_Service_Root(state);

	return {
		fetching, fetched, failed, deleted,
		service: Selector_Service(state),
		edit: Selector_Service_Edit(state),
		communicationUnits: Selector_CommunicationUnits_Listing_Data(state)
	}
};

const mapDispatchToProps = dispatch => (
	bindActionCreators({
		Thunk_Service_Fetch,
		Action_Service_Edit_Init,
		Action_Service_Edit_Modify,
		Action_Service_Edit_Cancel,
		Action_Service_Clear,
		Thunk_Service_Save,
		Thunk_Service_Remove,
		Thunk_ServiceTypes_Fetch_All,
	}, dispatch)
);

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(StorageServiceDetail));
