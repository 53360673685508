import { Selector_Generic_Item_Root } from "./Selectors_Generic";

export const Selector_ServiceTypes_Root = (state) => {
	return state.serviceTypes;
};

export const Selector_ServiceTypes_Listing_Root = state => {
	return Selector_ServiceTypes_Root(state).listing;
};

export const Selector_ServiceTypes_Listing_Data = state => {
	return Selector_ServiceTypes_Listing_Root(state).data;
};

export const Selector_ServiceType_Root = state => {
	return Selector_ServiceTypes_Root(state).item;
};

export const Selector_ServiceType_Data = state => {
	return Selector_ServiceType_Root(state).data;
};

export const Selector_ServiceType_Edit = state => {
	return Selector_ServiceType_Root(state).edit;
};
