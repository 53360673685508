import { Selector_Generic_Item_Root } from "./Selectors_Generic";

export const Selector_Tariffs_Root = state => {
	return state.tariffs;
};

export const Selector_Tariffs_Listing_Root = state => {
	return Selector_Tariffs_Root(state).listing;
};

export const Selector_Tariffs_Listing_Data = state => {
	return Selector_Tariffs_Listing_Root(state).data;
};

export const Selector_Tariff_Root = state => {
	return Selector_Tariffs_Root(state).item;
};

export const Selector_Tariff_Data = state => {
	return Selector_Tariff_Root(state).data;
};

export const Selector_Tariff_Edit = state => {
	return Selector_Tariff_Root(state).edit;
};
