import React from 'react';
import PropTypes from 'prop-types';
import { LocalizeProvider, withLocalize } from 'react-localize-redux';
import Main from './components/Main';

class App extends React.Component {

	constructor(props) {
		super(props);
	}

	render() {
		const { store } = this.props;

		return (
			<LocalizeProvider store={store}>
				<Main />
			</LocalizeProvider>
		);
	}
}

App.propTypes = {
	setActiveLanguage: PropTypes.func.isRequired,
	store: PropTypes.object.isRequired
};

export default withLocalize(App);


