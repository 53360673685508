import React from 'react';
import { Switch, Route } from 'react-router-dom';
import routes from '../../../routes/routes';
import FeedbackDetail from "./FeedbackDetail";
import FeedbackList from "./FeedbackList";

class VoucherBrowser extends React.Component {

	constructor(props) {
		super(props);
	}

	render() {
		return (
			<Switch>
				<Route exact path={routes.FEEDBACK}>
					<FeedbackList />
				</Route>
				<Route path={`${routes.FEEDBACK}/:feedbackID`} render={props => <FeedbackDetail feedbackID={parseInt(props.match.params.feedbackID)} />} />
			</Switch>
		);
	}
}

export default VoucherBrowser;
