import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { EO } from "../../../../utils/extensions";
import { E_BlockOptions, E_FrameOptions, T_blockTypeIcons, T_FrameTypeBlockOptions, T_frameTypeIcons } from "../../../../models/Models_StorageBox";
import ExpandContainer, { E_ExpandMode } from "../../../shared/ExpandContainer";
import { E_PickerType } from "./StorageUnitBase";
import ButtonsConstructor from "../../../shared/ButtonsConstructor";
import { Translate } from "react-localize-redux";
import { get } from "../../../../utils/utils";
import { E_ButtonPresets } from "../../../../models/Models_ButtonPresets";

class StorageExpandContainers extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			...this.state,
		};
	}

	_renderFrameSelect() {
		const {onFrameSelect} = this.props;

		return <ButtonsConstructor
			wrapInNav={true}
			className={"frame-select"}
			buttons={EO(E_FrameOptions).toArray(true).map(option => ({
				icon: T_frameTypeIcons[option],
				action: () => onFrameSelect(option)
			}))}
			sharedProps={{className: "fancy"}}
		/>;
	}

	_renderBlockSelect() {
		const {onBlockSelect, unit, pickerTarget} = this.props;

		let frame = get(unit, `frames`, []).findByID(pickerTarget.frameID || -1) || {};
		let blockWhitelist = T_FrameTypeBlockOptions[frame.frameType];

		return <ButtonsConstructor
			wrapInNav={true}
			className={"frame-select"}
			buttons={EO(E_BlockOptions).toArray(true).filter(
				option => !blockWhitelist || (blockWhitelist || []).includes(option)
			).map(option => ({
				icon: T_blockTypeIcons[option],
				text: "blockEdit_blockType_" + option,
				action: () => onBlockSelect(option)
			}))}
			sharedProps={{className: "fancy"}}
		/>;
	}

	render() {
		const {pickerType, onPickerInvalidate, onFrameRemove} = this.props;

		return (
			<Fragment>
				<ExpandContainer
					simpleExpansionToggle={!!pickerType}
					onExpandModeChange={change => change == E_ExpandMode.COLLAPSED && onPickerInvalidate()}
					header={{
						label: (
							pickerType &&
							<h1>
								<Translate id={pickerType == E_PickerType.FRAME ? "frameEdit_selectFrameType" : "blockEdit_selectBlockType"}/>
							</h1>
						),
						actionButtons: [
							pickerType == E_PickerType.FRAME && {
								preset: E_ButtonPresets.REMOVE,
								action: () => onFrameRemove(this.props.pickerTarget.id),
							}
						]
					}}
				>
					{
						pickerType && (pickerType == E_PickerType.FRAME ? this._renderFrameSelect() : this._renderBlockSelect())
					}
				</ExpandContainer>
			</Fragment>
		)
	}

	static get propTypes() {
		return {
			...super.propTypes,
			onFrameSelect: PropTypes.func.isRequired,
			onBlockSelect: PropTypes.func.isRequired,
			onPickerInvalidate: PropTypes.func.isRequired,

			onFrameRemove: PropTypes.func.isRequired,
		}
	}

	static get stateTypes() {
		return {
			...super.stateTypes,
		}
	}

	static get defaultProps() {
		return {
			...super.defaultProps,
			onFrameSelect: () => null,
			onBlockSelect: () => null,
		}
	}
}

export default StorageExpandContainers;
