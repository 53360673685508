import {applyMiddleware, createStore} from 'redux';
import thunk from 'redux-thunk';
import rootReducer from './reducers/rootReducer';
import {createLogger} from 'redux-logger';

const env = process.env.REACT_APP_FORCE_ENV || process.env.NODE_ENV || 'development';

export default () => {
	const logger = createLogger({
		collapsed: true
	});

	let devMiddleware = env !== "production" ? [logger] : [];

	let store = createStore(
		rootReducer,
		applyMiddleware(thunk, ...devMiddleware)
	);

	window.RootStore = store;

	return store;
};
