import React from "react";
import PropTypes from "prop-types";
import { T_SharedPropTypes } from "../../../models/Models_Shared";
import NavLinkAlt from "../NavLinkAlt";

class TableRow extends React.Component {
	render() {
		const {className, style, children, headerItems, href, exact} = this.props;

		let aProps = {...this.props};
		Object.keys(TableRow.rootPropTypes).forEach(key => delete aProps[key]);

		return (
			<button
				className={`table-item-row ${className}`}
				style={style}
				{...!href && aProps}
			>
				{
					href && <NavLinkAlt to={href} {...aProps} className={"row-redirect"} exact={exact} />
				}
				{
					children &&
					(Array.isArray(children) ? children : [children]).map((child, i) => {
						return child && React.cloneElement(
							child,
							{
								key: i,
								...typeof child.type !== "string" && {
									headerItems
								},
							}
						)
					})
				}
			</button>
		)
	}

	static get rootPropTypes() {
		return {
			...super.propTypes,
			...T_SharedPropTypes,
			headerItems: PropTypes.array,
			href: PropTypes.string,
			//Used in isActive detection
			exact: PropTypes.bool,
		}
	}

	static get propTypes() {
		return {
			...this.rootPropTypes,
		}
	}

	static get stateTypes() {
		return {
			...super.stateTypes,
		}
	}

	static get defaultProps() {
		return {
			...super.defaultProps,
			//Processed header items are in Array instead of hardcoded object format
			headerItems: null,
			href: '',
			exact: true,
		}
	}
}

export default TableRow;
