import React from "react";
// eslint-disable-next-line no-unused-vars
import PropTypes from "prop-types";
import { combineClasses, resolveErrorBlockContentByError } from "../../../../../../utils/utils";
import ButtonsConstructor from "../../../../../shared/ButtonsConstructor";
import { service_takeServiceCameraPicture } from "../../../../../../services/Service_Service";
import CubeSpinner from "../../../../../shared/LoadingIndicator/CubeSpinner";
import ErrorBlock from "../../../../../shared/Error/ErrorBlock";
import { errorHandler } from "../../../../../../store/ResponseHandling";
import { E_ServiceActions } from "../../../../../../store/actions/location/serviceActions";
import ServerImage from "../../../../../shared/Image/ServerImage";
import '../../../../../../styles/pages/Location/StorageUnit/ServiceCameraModal.scss';

class ServiceCameraModal extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			...super.state,
			loading: true,
			error: null,
			image: null,
			width: null,
			height: null,
		};
	}

	componentDidMount() {
		this._takePicture();
	}

	render() {
		const {className, style} = this.props;
		const {loading, error, image} = this.state;

		return (
			<section className={combineClasses("service-camera-modal", className)} style={style}>
				<div className={"image-container"}>
					<ServerImage src={image} onLoad={(e) => {
						this.setState({
							width: e.target.naturalWidth,
							height: e.target.naturalHeight,
						});
					}}/>
					{
						(loading || error) &&
						<div className={"overlay"}>
							{
								loading &&
								<CubeSpinner block={true} />
								||
								(
									error &&
									<ErrorBlock {...resolveErrorBlockContentByError(error, E_ServiceActions.TAKE_PHOTO)} />
								)
							}
						</div>
					}
				</div>
				<ButtonsConstructor buttons={[{
					icon: "camera",
					text: "takePicture",
					className: "fancy",
					action: () => this._takePicture(),
				}]}/>
			</section>
		);
	}

	_takePicture() {
		const {serviceID} = this.props;

		this.setState({loading: true, error: null});
		service_takeServiceCameraPicture(serviceID).then(image => {
			this.setState({image: window.URL.createObjectURL(image)})
		}, (error) => {
			this.setState({error});
			errorHandler(error, E_ServiceActions.TAKE_PHOTO);
		}).finally(() => {
			this.setState({loading: false});
		});
	}

	static get propTypes() {
		return {
			...super.propTypes,
			serviceID: PropTypes.number,
		}
	}

	static get stateTypes() {
		return {
			...super.stateTypes,
		}
	}

	static get defaultProps() {
		return {
			...super.defaultProps,
		}
	}
}

export default ServiceCameraModal;
