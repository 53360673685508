import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import ItemSelect from "../../shared/Input/ItemSelect";
import { resolveItemName } from "../../../utils/utils";
import { Thunk_Tariffs_Fetch_All } from "../../../store/thunk/Thunk_Tariffs";
import { Selector_Tariffs_Listing_Root } from "../../../store/selectors/Selectors_Tariff";

class TariffSelect extends React.Component {
	render() {
		const {Thunk_Tariffs_Fetch_All, label} = this.props;

		return <ItemSelect
			{...this.props}
			thunk={Thunk_Tariffs_Fetch_All}
			selector={Selector_Tariffs_Listing_Root}
			label={label || "tariff_select"}
			onLabel={item => resolveItemName(item, `name`)}
			headerItems={{name: {}, currency: {}, id: {}}}
		/>
	}

	static get propTypes() {
		return {
			...super.propTypes,
			...ItemSelect.propTypes,
		}
	}
}

const mapDispatchToProps = dispatch => (
	bindActionCreators({
		Thunk_Tariffs_Fetch_All
	}, dispatch)
);

export default connect(null, mapDispatchToProps)(TariffSelect);
