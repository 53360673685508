export const T_TimeBracketModel = {
	id: null,
	disabled: false,
	maxMinutes: 0,
	price: 0
};

/**
 * Tariff reservation cost model
 * ---
 * @type {{
 *          id: number,
 *          createdOn: Date,
 *          weekDay: string,
 *          fromTime: string,
 *          toTime: string,
 *          hourCost: number,
 *       }}
 */
export const T_TariffReservationCost = {
	id: undefined,
	createdOn: undefined,
	weekDay: "MONDAY",
	fromTime: "00:00",
	toTime: "00:00",
	hourCost: 0,
}

/**
 * Tariff Model
 * ---
 * @type {{
 *          authorizationAmount: Number,
 *          name: String,
 *          id: Number,
 *          oneTimeCost: Number,
 *          timeBrackets: T_TimeBracketModel[],
 *          description: String,
 *          taxPercent: Number,
 *          overtimePenaltyCost: Number,
 *          currency: E_Currency,
 *          partner: {id: Number, name: String},
 *          reservationCosts: T_TariffReservationCost[],
 *       }}
 */
export const T_TariffModel = {
	authorizationAmount: 0,
	currency: null,
	description: null,
	id: null,
	name: null,
	oneTimeCost: 0,
	overtimePenaltyCost: 0,
	partner: null,
	taxPercent: 21,
	timeBrackets: [],
	reservationHourCost: 0,
	reservationCosts: [],
};
