import React from "react";
import PropTypes from "prop-types";
import { Translate } from "react-localize-redux";
import { T_APP_ICONS } from "../../../../../../models/constants/Constants_Shared";
import { E_OneTimeEndEvent } from "../../../../../../models/Models_StorageService";
import { EO } from "../../../../../../utils/extensions";
import Dropdown from "../../../../../shared/Input/Dropdown";
import NumberInput from "../../../../../shared/Input/NumberInput";
import Fa from "../../../../../tools/Icons/Fa";

class ServiceConfigurationModal extends React.Component {
	constructor(props) {
		super(props);

		this.state = {};
	}

	render() {
		const {configuration, path, onModify} = this.props;

		return (
			<section>
				<div className={`grid-table grid-icon-auto-1fr`}>
					<span />
					<label><Translate id={"serviceConfiguration_oneTimeEndEvent"} /></label>
					<Translate>
						{({translate}) => (
							<Dropdown
								defaultValue={configuration.oneTimeEndEvent}
								options={EO(E_OneTimeEndEvent).toArray(true)}
								path={`${path}.oneTimeEndEvent`}
								onModify={onModify}
								onLabel={item => translate(`serviceConfiguration_oneTimeEndEvent_${item}`, null, true)}
							/>
						)}
					</Translate>

					<span><Fa icon={T_APP_ICONS.OCCUPIED} /> </span>
					<label><Translate id={"serviceConfiguration_oneTimeUseBlockTimeMinutes"} /></label>
					<NumberInput
						defaultValue={configuration.oneTimeUseBlockTimeMinutes || ''}
						path={`${path}.oneTimeUseBlockTimeMinutes`}
						onModify={onModify}
						suffix={<span><Translate id={"minutes"} /></span>}
						wrapInput={true}
						behavior={{clamp: {min: 0}}}
					/>

					<span><Fa icon={"user-clock"} /> </span>
					<label><Translate id={"serviceConfiguration_rentalLimitHours"} /></label>
					<NumberInput
						defaultValue={configuration.rentalLimitHours || ''}
						path={`${path}.rentalLimitHours`}
						onModify={onModify}
						suffix={<span><Translate id={"hours"} /></span>}
						wrapInput={true}
						behavior={{clamp: {min: 0}}}
					/>
				</div>
			</section>
		)
	}
}

ServiceConfigurationModal.propTypes = {
	configuration: PropTypes.object.isRequired,
	path: PropTypes.string.isRequired,

	onModify: PropTypes.func.isRequired,
};

ServiceConfigurationModal.stateTypes = {};

export default ServiceConfigurationModal;
