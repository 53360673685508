import { JSON_REACT_REPLACER_KEYS } from "../models/constants/Constants_Shared";

const is = {};

// Type checks
/* -------------------------------------------------------------------------- */

// is a given value Arguments?
is.arguments = function (value) {	// fallback check is for IE
	return toString.call(value) === '[object Arguments]' ||
		(value != null && typeof value === 'object' && 'callee' in value);
};

// is a given value Array?
is.array = Array.isArray || function (value) {	// check native isArray first
	return toString.call(value) === '[object Array]';
};

// is a given value Boolean?
is.boolean = function (value) {
	return value === true || value === false || toString.call(value) === '[object Boolean]';
};

// is a given value Char?
is.char = function (value) {
	return is.string(value) && value.length === 1;
};

// is a given value Date Object?
is.date = function (value) {
	return toString.call(value) === '[object Date]';
};

is.today = function(date) {
	let now = new Date();
	return date.getFullYear() == now.getFullYear() && date.getMonth() == now.getMonth() && date.getDate() == now.getDate();
};

is.past = function(possiblePastDate, compareWith = new Date()) {
	return new Date(compareWith) - new Date(possiblePastDate) > 0;
};

is.sameDay = function(date1, date2 = new Date()) {
	return new Date(date1).setStartOfDay() - new Date(date2).setStartOfDay() == 0;
};

is.sameMonth = function(date1, date2 = new Date()) {
	return new Date(date1).getFullYear() == new Date(date2).getFullYear() && new Date(date1).getMonth() == new Date(date2).getMonth();
};

// is a given object a DOM node?
is.domNode = function (object) {
	return is.object(object) && object.nodeType > 0;
};

// is a given value Error object?
is.error = function (value) {
	return toString.call(value) === '[object Error]';
};

// is a given value function?
is['function'] = function (value) {	// fallback check is for IE
	return toString.call(value) === '[object Function]' || typeof value === 'function';
};

// is given value a pure JSON object?
is.json = function (value) {
	return toString.call(value) === '[object Object]';
};

// is a given value NaN?
is.nan = function (value) {	// NaN is number :) Also it is the only value which does not equal itself
	return value !== value;
};

// is a given value null?
is['null'] = function (value) {
	return value === null;
};

// is a given value number?
is.number = function (value) {
	return !is.nan(value) && toString.call(value) === '[object Number]';
};

// is a given value object?
is.object = function (value) {
	return Object(value) === value;
};

// is a given value RegExp?
is.regexp = function (value) {
	return toString.call(value) === '[object RegExp]';
};

// are given values same type?
// prevent NaN, Number same type check
is.sameType = function (value, other) {
	var tag = toString.call(value);
	if (tag !== toString.call(other)) {
		return false;
	}
	if (tag === '[object Number]') {
		return !is.any.nan(value, other) || is.all.nan(value, other);
	}
	return true;
};
// sameType method does not support 'all' and 'any' interfaces
is.sameType.api = ['not'];

// is a given value String?
is.string = function (value) {
	return toString.call(value) === '[object String]';
};

// is a given value undefined?
is.undefined = function (value) {
	return value === void 0;
};

// is a given value window?
// setInterval method is only available for window object
is.windowObject = function (value) {
	return value != null && typeof value === 'object' && 'setInterval' in value;
};

// Presence checks
/* -------------------------------------------------------------------------- */

//is a given value empty? Objects, arrays, strings
is.empty = function (value) {
	if(is.date(value)) {
		return false;
	}

	if (is.object(value)) {
		var length = Object.getOwnPropertyNames(value).length;
		if (length === 0 || (length === 1 && is.array(value)) ||
			(length === 2 && is.arguments(value))) {
			return true;
		}
		return false;
	}
	return value === '';
};

is.valid = function (value) {
	return value != null && value != "Invalid Date" && !is.nan(value) && !is.empty(value);
};

// is a given value existy?
is.existy = function (value) {
	return value != null;
};

// are given values equal? supports numbers, strings, regexes, booleans
// TODO: Add object and array support
is.equal = function (value, other, comparisonExceptions = [], exceptionValue = undefined) {
	if (comparisonExceptions.includes(value) || comparisonExceptions.includes(other)) {
		return exceptionValue !== undefined ? exceptionValue : true;
	}

	if (is.nan(value) || is.nan(other)) {
		return false;
	}

	if(!value && !other) {
		return value === other;
	}

	const replacer = (k, v) => JSON_REACT_REPLACER_KEYS.includes(k) ? undefined : v;
	return JSON.stringify(value, replacer) == JSON.stringify(other, replacer);
};

// is a given number integer?
is.integer = function (n) {
	return is.number(n) && n % 1 === 0;
};

// is a given number negative?
is.negative = function (n) {
	return is.number(n) && n < 0;
};

// is a given number within minimum and maximum parameters?
is.within = function (n, min, max) {
	return is.all.number(n, min, max) && n > min && n < max;
};

// Environment checks
/* -------------------------------------------------------------------------- */


window.DEBUG_IS = is;
export default is;
