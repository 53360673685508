import {getFileHeaders} from "./apiServices/headers";
import {errorHandler} from "../store/ResponseHandling";
import {FILE_UPLOAD_REJECTED} from "../store/actions/fileActions";
import {T_FileUploadDataModel} from "../models/Models_Service";
import {POST} from "./apiServices/apiConnector";

/**
 * Upload file
 * ---
 * @param {String} uploadTarget
 * @param {File} file
 * @param {Number} timeout
 * @param {function(File, T_FileUploadDataModel)} onProgressChange
 */
export const service_uploadFile = (uploadTarget, file, onProgressChange = () => null, timeout = null) => {
	return new Promise((resolve, reject) => {
		if(!uploadTarget) {
			reject({message: "uploadTarget undefined"});
		}

		const __configureXHR = xhr => {
			let headers = getFileHeaders();
			headers.forEach((value, key) => {
				xhr.setRequestHeader(key, value);
			});
			timeout && xhr.setRequestHeader("timeout", timeout);
		};

		const xhr = new XMLHttpRequest();
		const formData = new FormData();
		formData.append(file.formDataName || "file", file, file.name);

		xhr.upload.onloadstart = () => onProgressChange(file, {...T_FileUploadDataModel});
		xhr.upload.onerror = () => {
			let status = xhr.status;
			errorHandler({
				status,
			}, FILE_UPLOAD_REJECTED);
			xhr.abort();
			reject(status);
		}
		xhr.upload.onprogress = function(e) {
			const {loaded, total} = e;
			if(file.abort) {
				xhr.abort();
				return;
			}

			let data = {
				...T_FileUploadDataModel,
				uploaded: loaded == total,
				loaded,
				total,
				progress: ~~(loaded / total * 100),
				indeterminate: loaded <= 0 || loaded >= total,
				processing: loaded == total,
			}
			onProgressChange(file, data);
		}

		xhr.onreadystatechange = function() {
			const {readyState, status, response} = this;
			if(readyState === XMLHttpRequest.DONE) {
				if (status === 0 || (status >= 200 && status < 400)) {
					onProgressChange(file, {
						...T_FileUploadDataModel,
						uploaded: true,
						progress: 100,
						processing: false,
					});
					resolve(response);
				} else {
					errorHandler({
						status,
						processing: false,
					}, FILE_UPLOAD_REJECTED);
					xhr.abort();
					reject(status);
				}
			}
		}

		xhr.open(POST, uploadTarget, true);
		__configureXHR(xhr);

		xhr.send(formData);
	});
};
