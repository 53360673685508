import React from 'react';
import { Switch, Route } from 'react-router-dom';
import routes from '../../../routes/routes';
import VoucherDetail from "./VoucherDetail";
import VouchersList from "./VouchersList";

class VoucherBrowser extends React.Component {

	constructor(props) {
		super(props);
	}

	render() {
		return (
			<Switch>
				<Route exact path={routes.VOUCHERS}>
					<VouchersList />
				</Route>
				<Route path={`${routes.VOUCHERS}/:voucherID`} render={props => <VoucherDetail voucherID={parseInt(props.match.params.voucherID)} />} />
			</Switch>
		);
	}
}

export default VoucherBrowser;
