import React, { Fragment } from "react";
import PropTypes from "prop-types";
import Fa from "../../../tools/Icons/Fa";
import is from "../../../../utils/is";
import { Selector_ExternalLocation_Data, Selector_ExternalLocation_Edit, Selector_ExternalLocation_Root } from "../../../../store/selectors/Selectors_ExternalLocations";
import { connect } from "react-redux";
import { T_SharedReduxPropTypes } from "../../../../models/Models_Shared";
import { T_ExternalLocationModel } from "../../../../models/Models_ExternalLocation";
import { bindActionCreators } from "redux";
import { Translate } from "react-localize-redux";
import routes from "../../../../routes/routes";
import { Action_ExternalLocation_Clear, Action_ExternalLocation_Edit_Cancel, Action_ExternalLocation_Edit_Init, Action_ExternalLocation_Edit_Modify } from "../../../../store/actions/externalLocations/externalLocationsActions";
import { Thunk_ExternalLocation_Delete, Thunk_ExternalLocation_Fetch, Thunk_ExternalLocation_Save } from "../../../../store/thunk/Thunk_ExternalLocations";
import TextInput from "../../../shared/Input/TextInput";
import Address from "../../../shared/Address";
import { T_LocationAddress } from "../../../../models/Models_Location";
import MorphElement from "../../../tools/MorphElement";
import { TranslateEntry } from "../../../../utils/localeUtils";
import TextareaInput from "../../../shared/Input/TextareaInput";
import LanguageSwitch from "../../../tools/Language/LanguageSwitch";
import "../../../../styles/pages/ExternalLocation/ExternalLocationDetail.scss";
import Gallery from "../../../shared/Gallery";
import DropzoneInput from "../../../shared/Input/DropzoneInput";
import Toolbar from "../../../shared/Toolbar";
import DetailWrapper from "../../../shared/DetailWrapper";
import { service_uploadImage } from "../../../../services/Service_Images";
import DropzoneImageContainer from "../../../shared/Input/Dropzone/DropzoneImageContainer";

class ExternalLocationDetail extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			...super.state,
		};
	}

	componentDidMount() {
		const {locationID, Thunk_ExternalLocation_Fetch, Action_ExternalLocation_Edit_Init} = this.props;

		if (locationID) {
			Thunk_ExternalLocation_Fetch(locationID);
		} else {
			Action_ExternalLocation_Edit_Init(T_ExternalLocationModel);
		}
	}

	componentWillUnmount() {
		const {Action_ExternalLocation_Clear} = this.props;

		Action_ExternalLocation_Clear();
	}

	_renderToolbar() {
		const {edit, Action_ExternalLocation_Edit_Cancel, Thunk_ExternalLocation_Delete, locationID, fetching, failed, Action_ExternalLocation_Edit_Init, Thunk_ExternalLocation_Save} = this.props;
		const isEdit = is.valid(edit);

		return <Toolbar
			isEdit={isEdit}
			backUrl={routes.EXTERNAL_LOCATIONS}
			content={<h1><Translate id={locationID ? (isEdit ? "location_external_edit" : "location_external_detail") : "location_external_create"}/></h1>}
			onEditCancel={() => Action_ExternalLocation_Edit_Cancel()}
			onEdit={() => Action_ExternalLocation_Edit_Init()}
			onSave={() => Thunk_ExternalLocation_Save()}
			onRemove={() => Thunk_ExternalLocation_Delete(locationID)}
			disableActionButtons={fetching || failed}
			itemID={locationID}
		/>;
	}

	_renderLocationGallery(location) {
		const {Action_ExternalLocation_Edit_Modify, edit} = this.props;
		const isEdit = is.valid(edit);

		if(!is.valid(location.image) && !isEdit) {
			return null;
		}

		return (
			<Fragment>
				<div className={"separator"}><label><Fa icon={"images"} /><Translate id={"images"} /></label></div>
				{
					this._isEditSwitch(
						<Gallery images={[location.image]}/>,
						<DropzoneInput
							path={`image`}
							defaultValue={location.image}
							onModify={Action_ExternalLocation_Edit_Modify}
							uploadService={service_uploadImage}
							multiple={false}
						>
							<DropzoneImageContainer/>
						</DropzoneInput>
					)
				}
			</Fragment>
		)
	}

	_renderLocationContent(location) {
		const {Action_ExternalLocation_Edit_Modify} = this.props;
		const isEdit = is.valid(this.props.edit);

		return (
			<div className={"external-location"}>
				<div className={"grid-table grid-icon-auto-1fr"}>
					<Fa icon={"tag"} />
					<label><Translate id={"location_name"}/></label>
					{
						this._isEditSwitch(
							<span>{location.name}</span>,
							<TextInput
								onModify={Action_ExternalLocation_Edit_Modify}
								path={`name`}
								defaultValue={location.name}
							/>
						)
					}
				</div>

				<div className={"separator"}><label><Fa icon={"city"} /><Translate id={"address"} /></label></div>

				<Address
					onModify={Action_ExternalLocation_Edit_Modify}
					isEdit={isEdit}
					address={location.address}
					model={T_LocationAddress}
				/>

				<MorphElement
					label={<h3><Fa icon={"pen-fancy"} /><Translate id="description" /></h3>}
					condition={!isEdit}
					pass={
						<TranslateEntry entry={location.description} showAll={true} />
					}
					fail={
						<LanguageSwitch
							path={"description"}
							value={location.description}
							onModify={Action_ExternalLocation_Edit_Modify}
						>
							<TextareaInput />
						</LanguageSwitch>
					}
				/>

				{this._renderLocationGallery(location)}
			</div>
		);
	}

	_renderLocation(location) {
		const isEdit = is.valid(this.props.edit);

		return (
			<Fragment>
				{this._renderToolbar(location, isEdit)}
				{this._renderLocationContent(location)}
			</Fragment>
		);
	}

	render() {
		const {location, edit, locationID} = this.props;

		return (
			<DetailWrapper
				itemID={locationID}
				dataID={(location || {}).id}
				className={"external-location-detail"}
				isEdit={is.valid(edit)}
				listUrl={routes.EXTERNAL_LOCATIONS}
				errorContent={"location_fetch_failed"}
				selector={Selector_ExternalLocation_Root}
			>
				{this._renderLocation((is.valid(edit) ? edit : location) || T_ExternalLocationModel)}
			</DetailWrapper>
		);
	}

	_isEditSwitch(normal, edit) {
		return is.valid(this.props.edit) ? edit : normal;
	}

	static get propTypes() {
		return {
			...T_SharedReduxPropTypes,
			location: PropTypes.object,
			edit: PropTypes.object,
			locationID: PropTypes.number,
		}
	}

	static get defaultProps() {
		return {
			location: T_ExternalLocationModel
		}
	}
}

const mapStateToProps = state => {
	const {fetching, fetched, failed} = Selector_ExternalLocation_Root(state);

	return {
		fetching, fetched, failed,
		location: Selector_ExternalLocation_Data(state),
		edit: Selector_ExternalLocation_Edit(state)
	};
};

const mapDispatchToProps = dispatch => (
	bindActionCreators({
		Thunk_ExternalLocation_Fetch,
		Action_ExternalLocation_Edit_Init,
		Thunk_ExternalLocation_Save,
		Thunk_ExternalLocation_Delete,
		Action_ExternalLocation_Edit_Cancel,
		Action_ExternalLocation_Edit_Modify,
		Action_ExternalLocation_Clear
	}, dispatch)
);

export default connect(mapStateToProps, mapDispatchToProps)(ExternalLocationDetail);
