import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import '../../../styles/components/input.scss';
import { E_InputTypes, T_RegexPatterns } from "../../../models/constants/Constants_Shared";
import { clamp, get, valid } from "../../../utils/utils";
import InputBase from "./Base/InputBase";

class NumberInput extends InputBase {
	constructor(props) {
		super(props);

		this.state = {
			...this.state,
			value: `${props.defaultValue || 0}`,
			focused: false,
		};

		this._handleChange = this._handleChange.bind(this);
	}

	componentDidMount() {
		super.componentDidMount();

		this.setState({value: this.processChange(this.state.value)});

		if (this.input) {
			this.wheelEvent = this.input.addEventListener("wheel", e=>{
				if (this.state.focused) {
					e.preventDefault();
					this._handleScroll(e);
				}
			});

			this.keyDownEvent = this.input.addEventListener("keydown", e => {
				if (this.state.focused) {
					["ArrowDown", "ArrowUp"].includes(e.key) && e.preventDefault();
					e.deltaY = e.key === "ArrowDown" ? 1 : e.key === "ArrowUp" ? -1 : 0;
					this._handleScroll(e);
				}
			});
		}
	}

	componentWillUnmount() {
		super.componentWillUnmount();

		this.input.removeEventListener("wheel", this.wheelEvent);
		this.input.removeEventListener("keydown", this.keyDownEvent);
	}

	render() {
		const {step, onFocusSelectAll, inputProps, disabled} = this.props;
		const {value} = this.state;

		return this.renderInputWrapper(
			<Fragment>
				{this.renderPrefix()}
				<input
					ref={this.setInputRef}
					type={"text"}
					inputMode={"decimal"}
					step={step}
					onChange={(e) => {this._handleChange(e)}}
					value={valid(value, '')}
					disabled={disabled}
					onFocus={() => {
						this.setState({focused: true})

						if(onFocusSelectAll) {
							this.input.select();
						}
					}}
					onBlur={() => {this.setState({focused: false})}}
					{...inputProps}
				/>
				{this.renderSuffix()}
				{this.renderInvalidateButton()}
			</Fragment>
		);
	}

	_handleChange(e) {
		this.debounceChange(this.props.path, e.target.value);
	}

	_handleScroll(e) {
		if (!e.deltaY) {return;}

		const {step} = this.props;
		const {value} = this.state;

		let changeValueBy = (e) => {
			let inc = step || 1;

			if (e.shiftKey) {
				inc*= 100;
			}

			if (e.ctrlKey) {
				e.stopPropagation();
				inc*= 10;
			}
			return inc;
		};

		let fixedLength = get(`${step}`.split("."), `1.length`, 0);
		let prevValueRounded = parseFloat(value);
		if(step < 1) {
			prevValueRounded = parseFloat(prevValueRounded.toFixed(fixedLength));
		}

		this.setState({
			value: this.processChange(
				parseFloat(((prevValueRounded || 0) - clamp(e.deltaY, -changeValueBy(e), changeValueBy(e))).toFixed(fixedLength))
			)
		});
		this.debounceChange(this.props.path, e.target.value);
	}

	static get propTypes() {
		return {
			...super.propTypes,
			step: PropTypes.number,
		}
	}

	static get stateTypes() {
		return {
			...super.stateTypes,
			focused: PropTypes.bool,
		}
	}

	static get defaultProps() {
		return {
			...super.defaultProps,
			step: 1,
			type: E_InputTypes.NUMBER,
			internalBehavior: {
				autoValidate: true,
				pattern: T_RegexPatterns.float
			}
		}
	}
}

export default NumberInput
