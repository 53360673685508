import React, {Fragment} from "react";
import {T_SharedDropzoneInputPropTypes} from "../DropzoneInput";
import ProgressBar from "../../ProgressBar";
import ButtonsConstructor from "../../ButtonsConstructor";
import ServerImage from "../../Image/ServerImage";
import {E_ButtonPresets} from "../../../../models/Models_ButtonPresets";
import {getFileType} from "../../../../utils/dropzoneUtils";
import {get} from "../../../../utils/utils";
import "../../../../styles/components/Dropzone/DropzoneImageContainer.scss";
import Fa from "../../../tools/Icons/Fa";
import CubeSpinner from "../../LoadingIndicator/CubeSpinner";

class DropzoneImageContainer extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			...this.state,

		};
	}

	_renderFilePreview(file, i) {
		const {onFileRemove, onFileErrorReUpload} = this.props;
		let filePreview = this._constructFilePreview(file.blob);
		let data = file.data || {};
		let isUploading = !data.uploaded && !data.error;

		return (
			<div key={i} className={`image-preview ${data.error ? "failed" : ''}`}>
				{
					data.processing &&
					<CubeSpinner className={"spinner"} block={true}/>
					||
					isUploading &&
					<ProgressBar className={"vertical"} progress={data.progress} max={100}/>
					||
					<Fragment>
						<ServerImage src={filePreview.url} imageID={get(file, `response.id`)} thumbnail={true} />
						{
							file.data.error &&
							<div className={"error-wrapper"}>
								<Fa icon={"exclamation-circle"} className={"error-icon"} />
							</div>
						}
						<ButtonsConstructor
							buttons={[
								file.data.error &&
								{
									icon: "sync",
									action: (e) => {
										e.stopPropagation();
										onFileErrorReUpload(file)
									}
								}
								||
								{
									preset: E_ButtonPresets.REMOVE,
									confirm: null,
									action: (e) => {
										e.stopPropagation();
										onFileRemove(file);
									},
								}
							]}
							wrapInNav={true}
						/>
					</Fragment>
				}
			</div>
		)

	}

	render() {
		const {className, style, files} = this.props;

		return (
			<section className={`dropzone-image-container ${className}`} style={style}>
				{(files || []).map(this._renderFilePreview.bind(this))}
			</section>
		)
	}

	_constructFilePreview(blobFile) {
		blobFile = blobFile || {};
		return {
			type: getFileType(blobFile),
			url: blobFile.thumbnailUrl || (blobFile instanceof Blob && URL.createObjectURL(blobFile)) || null
		};
	}

	static get propTypes() {
		return {
			...super.propTypes,
			...T_SharedDropzoneInputPropTypes,
		}
	}

	static get stateTypes() {
		return {
			...super.stateTypes,
		}
	}

	static get defaultProps() {
		return {
			...super.defaultProps,
			onFileRemove: () => null,
			onFileErrorReUpload: () => null,
		}
	}
}

export default DropzoneImageContainer;
