import { get } from '../../utils/utils';

// SELECTORS are used to retrieve data from the state tree.

// Return Locations root (Base root of the location & locations reducer)
export function Selector_Locations_Root(state) {
	return state.locations;
}

// Return Location listing root
export function Selector_Locations_Listing_Root(state) {
	return Selector_Locations_Root(state).listing
}

// Return Locations listing data
export function Selector_Locations_Listing(state) {
	return Selector_Locations_Listing_Root(state).data;
}

// Return Location item root
export function Selector_Location_Item_Root(state) {
	return Selector_Locations_Root(state).item
}

// Return generic location object
export function Selector_Location(state) {
	return Selector_Location_Item_Root(state).data;
}

// Return root location edit object
export function Selector_Location_Edit_Root(state) {
	return Selector_Location_Item_Root(state).edit;
}

// Return location edit object
export function Selector_Location_Edit_Location(state) {
	return Selector_Location_Edit_Root(state).location;
}

// Return location unit edit object
export function Selector_Location_Edit_Unit(state) {
	return Selector_Location_Edit_Root(state).unit;
}

// Return location unit edit object or unit edit index
export function Selector_Unit(state, unitID, getIndex = false) {
	return get(Selector_Location_Edit_Location(state), `storageUnits`).findByID(unitID, getIndex);
}

// Return location frame edit object
export function Selector_Frame(state, path) {
	return get(Selector_Location_Edit_Location(state), path);
}
