import { get } from "./utils";

export const prebuildItemArray = (size = 1, itemsToFill = [], additionalProps) => {
	return Array().fillInOrder(size).map(i => (
		(itemsToFill || []).find(item => item.orderPosition == i)
		||
		{
			orderPosition: i,
			...additionalProps,
		}
	));
};

/**
 * Find path components by ID
 * ---
 * Traces unit, frame and block by provided ID
 * @param {Object} root Staring object
 * @param {Number} id Target ID
 * @param {("frame"|"block"|"service")} part Target part (this determines where the {id} should be)
 * @returns {{unit: Object, block: Object, frame: Object}}
 */
export const findPathComponentsByID = (root, id, part = "service") => {
	let targetUnit, targetFrame, targetBlock;

	if(part == "service") {
		targetUnit = get(root, `storageUnits`, []).find(unit => {
			targetFrame = get(unit, `frames`, []).find(frame => {
				targetBlock = get(frame, `blocks`, []).find(block => {
					return get(block, `services`, []).findByID(id);
				});
				return targetBlock;
			});
			return targetFrame;
		});
	} else if(part == "block") {
		targetUnit = get(root, `storageUnits`, []).find(unit => {
			targetFrame = get(unit, `frames`, []).find(frame => {
				targetBlock = get(frame, `blocks`, []).findByID(id);
				return targetBlock;
			});
			return targetFrame;
		});
	} else if(part == "frame") {
		targetUnit = get(root, `storageUnits`, []).find(unit => {
			targetFrame = get(unit, `frames`, []).findByID(id);
			return targetFrame;
		});
	}

	return {
		unit: targetUnit,
		frame: targetFrame,
		block: targetBlock,
	}
}
