import { service_deleteLocation, service_fetchLocation, service_fetchLocations, service_insertLocation, service_updateLocation } from "../../services/Service_Locations";
import { Selector_Location_Edit_Location } from "../selectors/Selectors_Location";
import { Thunk_Generic_Edit_Insert, Thunk_Generic_Edit_Update, Thunk_Generic_Fetch_All, Thunk_Generic_Fetch_Item, Thunk_Generic_Remove } from "./Thunk_Generic";
import { Action_Location_Edit_Fulfilled, Action_Location_Edit_Pending, Action_Location_Edit_Rejected, Action_Location_Fetch_Fulfilled, Action_Location_Fetch_Pending, Action_Location_Fetch_Rejected, Action_Locations_Fetch_Fulfilled, Action_Locations_Fetch_Pending, Action_Locations_Fetch_Rejected } from "../actions/location/locationsActions";
import { T_locationModel } from "../../models/Models_Location";
import { E_ToastStyle } from "../../models/Models_Toast";

export const Thunk_Locations_Fetch_All = queryString => (
	Thunk_Generic_Fetch_All(
		queryString,
		service_fetchLocations,
		Action_Locations_Fetch_Pending,
		Action_Locations_Fetch_Fulfilled,
		Action_Locations_Fetch_Rejected
	)
)

export const Thunk_Location_Insert = modelOverride => (
	Thunk_Generic_Edit_Insert(
		service_insertLocation,
		Action_Locations_Fetch_Pending,
		Action_Location_Edit_Fulfilled,
		Action_Location_Edit_Rejected,
		() => modelOverride || T_locationModel,
		undefined,
		undefined,
		() => {
			window.toaster.showToast({
				style: E_ToastStyle.SUCCESS,
				content: "locationEdit_insertLocationSuccess",
			})
		}
	)
)

export const Thunk_Location_Fetch = locationID => (
	Thunk_Generic_Fetch_Item(
		locationID,
		service_fetchLocation,
		Action_Location_Fetch_Pending,
		Action_Location_Fetch_Fulfilled,
		Action_Location_Fetch_Rejected
	)
)

export const Thunk_Location_Update = keepUnits => (
	Thunk_Generic_Edit_Update(
		service_updateLocation,
		Action_Location_Edit_Pending,
		Action_Location_Edit_Fulfilled,
		Action_Location_Edit_Rejected,
		Selector_Location_Edit_Location,
		data => {
			data = {...data};
			if(!keepUnits) {
				delete data.storageUnits;
			}
			return data;
		}
	)
)

export const Thunk_Location_Remove = locationID => (
	Thunk_Generic_Remove(
		locationID,
		service_deleteLocation,
		Action_Location_Edit_Pending,
		Action_Location_Edit_Fulfilled,
		Action_Location_Edit_Rejected
	)
)
