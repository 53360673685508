import React from "react";
import PropTypes from "prop-types";
import is from "../../utils/is";

class Database extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			store: window.RootStore
		};
		window.Database = this;
	}

	render() {
		return null;
	}

	/**
	 * Get
	 * ---
	 * Get data from store or fetch them if none are present
	 * @param {Function} selector Selector
	 * @param {Function} fetchThunk Fetch thunk
	 * @param {Function} onPending Pending callback
	 * @returns {Promise}
	 */
	get(selector = () => null, fetchThunk = () => null, onPending = () => null) {
		const {store} = this.state;

		return new Promise((resolve) => {
			onPending();

			let selectedData = selector(store.getState());
			if (this._validateData(selectedData)) {
				resolve(selectedData);
			} else {
				let subscriber = store.subscribe(() => {
					let newData = selector(store.getState());

					if (is.valid(newData)) {
						subscriber();
						resolve(newData);
					}
				});

				store.dispatch(fetchThunk());
			}
		});
	}

	/**
	 * Get Repeated
	 * ---
	 * Register subscriber for repeated data reception, the data will be automatically retrieved from store until {registerSubscriber}() will be called
	 * @param selector Selector - Redux data selector
	 * @param fetchThunk Fetch Thunk - Thunk for data fetch from server
	 * @param onPending On pending - Fetch pending state function ( can be used to display loading )
	 * @param onFetch On Fetch - Fetch data from redux function ( only local data, no back-end request )
	 * @param registerSubscriber Register subscriber - Terminate subscription function
	 */
	getRepeated(selector = () => null, fetchThunk = () => null, onPending = () => null, onFetch = () => null, registerSubscriber = () => null) {
		const {store} = this.state;

		if (fetchThunk) {
			onPending();
		}

		this.subscribe(selector, onFetch, registerSubscriber);

		if (fetchThunk) {
			let selectedData = selector(store.getState());
			if (this._validateData(selectedData)) {
				onFetch(selectedData);
			} else {
				store.dispatch(fetchThunk);
			}
		}
	}

	/**
	 * Subscribe
	 * ---
	 * @param onStateUpdate
	 * @param registerSubscriber
	 * @see getRepeated
	 */
	subscribe(onStateUpdate, registerSubscriber) {
		const {store} = this.state;

		let subscriber = store.subscribe(() => {
			onStateUpdate(store.getState());
		});
		registerSubscriber(subscriber);
	}

	getState() {
		const {store} = this.state;
		return store.getState();
	}

	_validateData(data) {
		return is.valid(data); //TODO: add time comparison for refresh
	}
}

Database.propTypes = {};

Database.stateTypes = {
	store: PropTypes.object.isRequired
};

export default Database;
