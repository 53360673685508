import PropTypes from 'prop-types';
import React from 'react';
import {Translate} from "react-localize-redux";
import {connect} from "react-redux";
import {withRouter} from 'react-router-dom';
import {bindActionCreators} from "redux";
import {T_APP_ICONS} from "../../../models/constants/Constants_Shared";
import {E_VoucherType} from "../../../models/Models_Voucher";
import routes from '../../../routes/routes';
import {Selector_Vouchers_Listing_Root} from "../../../store/selectors/Selectors_Vouchers";
import {Thunk_Vouchers_Fetch_All} from "../../../store/thunk/Thunk_Vouchers";
import {get, resolveItemName} from "../../../utils/utils";
import CSBaseComponent from "../../CSBaseComponent";
import ListingPage from "../../shared/Listing/ListingPage";
import TableListing from "../../shared/Listing/TableListing";
import TableRow from "../../shared/Listing/TableRow";
import TableColumn from "../../shared/Listing/TableColumn";
import ItemRedirect from "../../shared/ItemRedirect";

class VouchersList extends CSBaseComponent {
	constructor(props) {
		super(props);

		this.state = {
			...super.state,
		};
	}

	_renderTemplate(voucher, i, headerItems) {
		return (
			<TableRow key={i} href={`${routes.VOUCHERS}/${voucher.id}`} headerItems={headerItems}>
				<TableColumn column={"voucherCode"}>{voucher.voucherCode}</TableColumn>
				<TableColumn column={"user"}>
					{
						get(voucher, `user.id`) &&
						<ItemRedirect href={`${routes.USERS}/${voucher.user.id}`}>
							{resolveItemName(voucher.user, `email`)}
						</ItemRedirect>
						||
						'-'
					}
				</TableColumn>
				<TableColumn column={"discountPercent"}>{get(voucher.discountPercent, null, "", item => `${item} %`)}</TableColumn>
				<TableColumn column={"usesLeft"}>{voucher.usesLeft || '-'}</TableColumn>
				<TableColumn column={"amountLeft"}>{voucher.amountLeft || '-'}</TableColumn>
				<TableColumn column={"currency"}>{voucher.currency || '-'}</TableColumn>
				<TableColumn column={"type"}>{(voucher.type == E_VoucherType.PERCENT_DISCOUNT ? "%" : <Translate id={`voucher_type_${voucher.type}`}/>) || '-'}</TableColumn>
				<TableColumn column={"partnerLimit"}>{resolveItemName(voucher.partnerLimit)}</TableColumn>
			</TableRow>
		);
	}

	render() {
		const {Thunk_Vouchers_Fetch_All} = this.props;

		return (
			<section className={"voucher-list"}>
				<ListingPage
					section={"vouchers"}
					name={"vouchers"}
					titleIcon={T_APP_ICONS.VOUCHERS}
					titleText={"vouchers"}
					titleLeftContent={routes.VOUCHERS}
					subscriberThunk={Thunk_Vouchers_Fetch_All}
					subscriberRootSelector={Selector_Vouchers_Listing_Root}
					headerItems={{
						voucherCode: {
							label: "code",
							width: 2
						},
						user: {
							field: "user.id"
						},
						discountPercent: {
							width: 2
						},
						usesLeft: {
							width: 3
						},
						amountLeft: {
							width: 3
						},
						currency: {
							width: 1
						},
						type: {},
						partnerLimit: {
							label: "partner_limit",
							filter: false,
							sort: false,
						},
					}}
					views={[
						{
							view: "table",
							default: true,
							button: {
								icon: "table",
								tippy: <Translate id={"viewMode_table"} />
							},
							content: <TableListing name={"vouchers"} className={"stripes"} itemTemplate={this._renderTemplate.bind(this)} canModifyColumns={true} />
						},
					]}
				/>
			</section>
		);
	}
}

VouchersList.propTypes = {
	Thunk_Vouchers_Fetch_All: PropTypes.func.isRequired,
};

const mapDispatchToProps = dispatch => (
	bindActionCreators({
		Thunk_Vouchers_Fetch_All,
	}, dispatch)
);

export default connect(null, mapDispatchToProps)(withRouter(VouchersList));
