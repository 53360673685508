export const Selector_ExternalLocations_Root = state => {
	return state.externalLocations;
};

export const Selector_ExternalLocations_Listing_Root = state => {
	return Selector_ExternalLocations_Root(state).listing;
};

export const Selector_ExternalLocations_Listing_Data = state => {
	return Selector_ExternalLocations_Listing_Root(state).data;
};

export const Selector_ExternalLocation_Root = state => {
	return Selector_ExternalLocations_Root(state).item;
};

export const Selector_ExternalLocation_Data = state => {
	return Selector_ExternalLocation_Root(state).data;
};

export const Selector_ExternalLocation_Edit = state => {
	return Selector_ExternalLocation_Root(state).edit;
};
