import React from "react";
import PropTypes from "prop-types";
import {Translate, withLocalize} from "react-localize-redux";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {T_communicationUnitModel} from "../../../../models/Models_CommunicationUnit";
import {T_APP_ICONS} from "../../../../models/constants/Constants_Shared";
import {Selector_CommunicationUnits_Listing_Root} from "../../../../store/selectors/Selectors_CommunicationUnit";
import {Thunk_CommunicationUnit_Fetch_All, Thunk_CommunicationUnit_Remove, Thunk_CommunicationUnit_Save} from "../../../../store/thunk/Thunk_CommunicationUnits";
import is from "../../../../utils/is";
import ListingPage from "../../../shared/Listing/ListingPage";
import Fa from "../../../tools/Icons/Fa";
import "../../../../styles/pages/Location/CommunicationUnitsList.scss";
import TableListing from "../../../shared/Listing/TableListing";
import CommunicationUnitEditModal from "./CommunicationUnitEditModal";
import {E_ButtonPresets} from "../../../../models/Models_ButtonPresets";
import TableRow from "../../../shared/Listing/TableRow";
import TableColumn from "../../../shared/Listing/TableColumn";
import {Action_CommunicationUnit_Fetch_Fulfilled} from "../../../../store/actions/location/communicationUnitActions";

class CommunicationUnitsList extends React.Component {
	constructor(props) {
		super(props);

		this.state = {};
	}

	_communicationUnitRowTemplate(unit, i, headerItems) {
		const {canEdit} = this.props;

		return (
			<TableRow key={i} onClick={() => canEdit && this._openEditCommunicationUnit(unit)} headerItems={headerItems}>
				<TableColumn column={"name"}>{unit.name || '-'}</TableColumn>
				<TableColumn column={"paymentTerminalIpAddress"}>{unit.paymentTerminalIpAddress || '-'}</TableColumn>
				<TableColumn column={"type"}><Translate id={`communicationUnitType_${unit.type}`} options={{onMissingTranslation: () => unit.type}}/></TableColumn>
				<TableColumn column={"unitIpAddress"}>{unit.unitIpAddress || '-'}</TableColumn>
				<TableColumn column={"unitSerialNumber"}>{unit.unitSerialNumber || '-'}</TableColumn>
			</TableRow>
		)
	}

	render() {
		const {locationID, Thunk_CommunicationUnit_Fetch_All, canEdit} = this.props;

		return (
			<section className={"communication-units"}>
				<ListingPage
					subscriberInlineSpinner={true}
					className={" "}
					subscriberClassName={"bordered footer-static"}
					queryNotify={false}
					useQuery={false}
					header={
						<div className={"separator"}>
							{
								canEdit &&
								<div>
									<button onClick={() => this._openEditCommunicationUnit({...T_communicationUnitModel, locationID})}>
										<Fa icon={T_APP_ICONS.ADD} />
										<label><Translate id={"add"} /></label>
									</button>
								</div>
							}
							<label><Fa icon={"microchip"} /> <Translate id={"communicationUnits"} /></label>
						</div>
					}
					subscriberThunk={queryString => Thunk_CommunicationUnit_Fetch_All(locationID, queryString)}
					subscriberRootSelector={Selector_CommunicationUnits_Listing_Root}
					canSearch={false}
					headerItems={{
						name: {},
						paymentTerminalIpAddress: {},
						type: {},
						unitIpAddress: {},
						unitSerialNumber: {},
					}}
					views={[
						{
							view: "table",
							default: true,
							content: <TableListing
								itemTemplate={this._communicationUnitRowTemplate.bind(this)}
								canModifyColumns={true}
								name={"communication-units"}
							/>
						}
					]}
				/>
			</section>
		)
	}

	_openEditCommunicationUnit(unit) {
		const {Thunk_CommunicationUnit_Save, Thunk_CommunicationUnit_Remove, locationID} = this.props;

		this.props.Action_CommunicationUnit_Fetch_Fulfilled(unit);

		window.modal.open({
			body: (
				<CommunicationUnitEditModal />
			),
			actionButtons: [
				{
					icon: "save",
					action: () => {
						Thunk_CommunicationUnit_Save(locationID);
						window.modal.close();
					}
				},
				is.valid(unit.id) && {
					preset: E_ButtonPresets.REMOVE,
					action: () => {
						Thunk_CommunicationUnit_Remove(unit.id);
						window.modal.close();
					}
				}
			]
		});
	}

	static get propTypes() {
		return {
			...super.propTypes,
			locationID: PropTypes.number.isRequired,
			canEdit: PropTypes.bool,

			Thunk_CommunicationUnit_Fetch_All: PropTypes.func.isRequired,
			Thunk_CommunicationUnit_Save: PropTypes.func.isRequired,
			Thunk_CommunicationUnit_Remove: PropTypes.func.isRequired,
			Action_CommunicationUnit_Fetch_Fulfilled: PropTypes.func.isRequired,
		}
	}

	static get stateTypes() {
		return {
			...super.stateTypes,
		}
	}

	static get defaultProps() {
		return {
			...super.defaultProps,
			locationID: -1,
			canEdit: true,
		}
	}
}

const mapDispatchToProps = dispatch => (
	bindActionCreators({
		Thunk_CommunicationUnit_Fetch_All,
		Thunk_CommunicationUnit_Save,
		Thunk_CommunicationUnit_Remove,
		Action_CommunicationUnit_Fetch_Fulfilled
	}, dispatch)
);

export default connect(null, mapDispatchToProps)(withLocalize(CommunicationUnitsList));
