import "tippy.js/dist/tippy.css";
import tippy from 'tippy.js';

import React, {Children, cloneElement, forwardRef, useEffect, useLayoutEffect, useRef, useState} from 'react';
import {createPortal} from 'react-dom';
import PropTypes from 'prop-types';
import {get} from "../../utils/utils";

export { default as tippy } from 'tippy.js';

function _objectWithoutPropertiesLoose(source, excluded) {
	if(source == null) return {};
	let target = {};
	let sourceKeys = Object.keys(source);
	let key,
		i;

	for(i = 0; i < sourceKeys.length; i++) {
		key = sourceKeys[i];
		if(excluded.indexOf(key) >= 0) continue;
		target[key] = source[key];
	}

	return target;
}

let isBrowser = typeof window !== 'undefined' && typeof document !== 'undefined';

function preserveRef(ref, node) {
	if(ref) {
		if(typeof ref === 'function') {
			ref(node);
		}

		if({}.hasOwnProperty.call(ref, 'current')) {
			ref.current = node;
		}
	}
}

function ssrSafeCreateDiv() {
	return isBrowser && document.createElement('div');
}

function updateClassName(tooltip, action, classNames) {
	classNames.split(/\s+/).forEach(function (name) {
		if(name) {
			tooltip.classList[action](name);
		}
	});
}

function useInstance(initialValue) {
	if(initialValue === void 0) {
		initialValue = {};
	}

	// Using refs instead of state as it's recommended to not store imperative
	// values in state due to memory problems in React(?)
	let ref = useRef();

	if(!ref.current) {
		ref.current = typeof initialValue === 'function' ? initialValue() : initialValue;
	}

	return ref.current;
}

// get around it, we can conditionally useEffect on the server (no-op) and
// useLayoutEffect in the browser. We need useLayoutEffect because we want Tippy
// to perform sync mutations to the DOM elements after renders to prevent
// jitters/jumps, especially when updating content.

let useIsomorphicLayoutEffect = isBrowser ? useLayoutEffect : useEffect;

function TippyComponent(_ref) {
	let children = _ref.children,
		content = _ref.content,
		className = _ref.className,
		onCreate = _ref.onCreate,
		isVisible = _ref.isVisible,
		isEnabled = _ref.isEnabled,
		visible = _ref.visible,
		enabled = _ref.enabled,
		_ref$ignoreAttributes = _ref.ignoreAttributes,
		ignoreAttributes = _ref$ignoreAttributes === void 0 ? true : _ref$ignoreAttributes,
		restOfNativeProps = _objectWithoutPropertiesLoose(_ref, ["children", "content", "className", "onCreate", "isVisible", "isEnabled", "visible", "enabled", "ignoreAttributes", "multiple"]);

	// `isVisible` / `isEnabled` renamed to `visible` / `enabled`
	enabled = enabled !== undefined ? enabled : isEnabled !== undefined ? isEnabled : true;
	visible = visible !== undefined ? visible : isVisible;
	let isControlledMode = visible !== undefined;

	let _useState = useState(false),
		mounted = _useState[0],
		setMounted = _useState[1];

	let component = useInstance(function () {
		return {
			container: ssrSafeCreateDiv(),
			renders: 1
		};
	});

	let options = {
		...{
			ignoreAttributes: ignoreAttributes,
		},
		...restOfNativeProps,
		content: component.container
	};

	if(isControlledMode) {
		options.trigger = 'manual';
	}

	useIsomorphicLayoutEffect(function () {
		if(!component || (component && !component.ref)) {return;}

		/**
		 * If react component is passed into tippy, this would fail if getRef() or this.root (in component's scope) is not set and referring to the ref part of the component's render function.
		 *
		 * If something breaks this IF is probably the cause...
		 *
		 * @see NavLinkAlt
		 */
		let ref;
		if(component.ref.getRef || component.ref.root) {
			if(component.ref.getRef) {
				ref = component.ref.getRef();
			} else {
				ref = component.ref.root;
			}
		} else {
			ref = component.ref;
		}

		let instance = tippy(ref, options);
		component.instance = instance;

		if(onCreate) {
			onCreate(instance);
		}

		if(!enabled) {
			instance.disable();
		}

		if(visible) {
			instance.show();
		}

		setMounted(true);
		return function () {
			instance.destroy();
		};
	}, [children.type]);

	useIsomorphicLayoutEffect(function () {
		// Prevent this effect from running on 1st render
		if(component.renders === 1) {
			component.renders++;
			return;
		}

		let instance = component.instance;
		instance.setProps(options);

		if(enabled) {
			instance.enable();
		}
		else {
			instance.disable();
		}

		if(isControlledMode) {
			if(visible) {
				instance.show();
			}
			else {
				instance.hide();
			}
		}
	});

	useIsomorphicLayoutEffect(function () {
		if(className) {
			let tooltip = get(component, `instance.popper`);
			if(tooltip) {
				updateClassName(tooltip, 'add', className);
				return function () {
					updateClassName(tooltip, 'remove', className);
				};
			}
		}
	}, [className]);

	return React.createElement(React.Fragment, null, cloneElement(children, {
		ref: function ref(node) {
			component.ref = node;
			preserveRef(children.ref, node);
		}
	}), mounted && createPortal(content, component.container));
}

let arrayOrNumber = PropTypes.oneOfType([PropTypes.number, PropTypes.array]);
TippyComponent.propTypes = {
	content: PropTypes.any.isRequired,
	a11y: PropTypes.bool,
	allowHTML: PropTypes.bool,
	//'fade' | 'scale' | 'shift-toward' | 'perspective' | 'shift-away',
	animation: PropTypes.string,
	//'parent' | Element | ((ref: Element) => Element),
	appendTo: PropTypes.any,
	//'describedby' | 'labelledby' | null,
	aria: PropTypes.string,
	arrow: PropTypes.bool,
	//'sharp' | 'round'
	arrowType: PropTypes.string,
	//'scrollParent' | 'window' | 'viewport' | HTMLElement,
	boundary: PropTypes.string,
	//number | [number, number]
	delay: arrayOrNumber,
	//number | [number, number]
	duration: arrayOrNumber,
	// flip: PropTypes.bool,
	//'flip' | Placement[]
	flipBehavior: PropTypes.string,
	flipOnUpdate: PropTypes.bool,
	//Boolean | 'vertical' | 'horizontal' | 'initial'
	followCursor: PropTypes.bool,
	//Boolean | 'toggle'
	hideOnClick: PropTypes.bool,
	ignoreAttributes: PropTypes.bool,
	inertia: PropTypes.bool,
	interactive: PropTypes.bool,
	interactiveBorder: PropTypes.number,
	interactiveDebounce: PropTypes.number,
	lazy: PropTypes.bool,
	//number | string
	maxWidth: arrayOrNumber,
	// multiple: PropTypes.bool,
	//number | string
	offset: arrayOrNumber,
	onHidden: PropTypes.func,
	onHide: PropTypes.func,
	onMount: PropTypes.func,
	onShow: PropTypes.func,
	onShown: PropTypes.func,
	onTrigger: PropTypes.func,
	popperOptions: PropTypes.object,
	role: PropTypes.string,
	showOnInit: PropTypes.bool,
	//'small' | 'regular' | 'large'
	size: PropTypes.string,
	sticky: PropTypes.bool,
	target: PropTypes.string,
	//'dark' | 'light' | 'light-border' | 'google'
	theme: PropTypes.string,
	touch: PropTypes.bool,
	touchHold: PropTypes.bool,
	trigger: PropTypes.string,
	triggerTarget: PropTypes.any,
	updateDuration: PropTypes.number,
	wait: PropTypes.func,
	zIndex: PropTypes.number,
};

let Tippy = forwardRef(function TippyWrapper(_ref2, _ref3) {
	let children = _ref2.children,
		props = _objectWithoutPropertiesLoose(_ref2, ["children"]);

	return React.createElement(TippyComponent, props, cloneElement(children, {
		ref: function ref(node) {
			preserveRef(_ref3, node);
			preserveRef(children.ref, node);
		}
	}));
});

function TippyGroup(_ref) {
	let children = _ref.children,
		props = _objectWithoutPropertiesLoose(_ref, ["children"]);

	let component = useInstance({
		instances: []
	});
	useEffect(function () {
		component.instances = component.instances.filter(function (i) {
			return !i.state.isDestroyed;
		});
		tippy.group(component.instances, props);
	});
	return Children.map(children, function (child) {
		return cloneElement(child, {
			onCreate: function onCreate(instance) {
				if(child.props.onCreate) {
					child.props.onCreate(instance);
				}

				component.instances.push(instance);
			}
		});
	});
}

TippyGroup.propTypes = {
	children: PropTypes.arrayOf(PropTypes.element).isRequired
};

export default Tippy;
export { TippyGroup };
