import React from 'react';
import PropTypes from 'prop-types';

class MorphElement extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			condition: props.condition
		};
	}

	render() {
		const props = {...this.props, condition: undefined, pass: undefined, fail: undefined};
		if (this.props.condition) {
			return React.cloneElement(this.props.pass, props);
		}
		return React.cloneElement(this.props.fail, props);
	}
}

MorphElement.propTypes = {
	condition: PropTypes.bool.isRequired,
	pass: PropTypes.any.isRequired,
	fail: PropTypes.any.isRequired
};

MorphElement.stateTypes = {
	condition: PropTypes.bool.isRequired
};

export default MorphElement;
