import React from "react";
// eslint-disable-next-line no-unused-vars
import PropTypes from "prop-types";
import { combineClasses, get } from "../../../../../../utils/utils";
import { Translate } from "react-localize-redux";
import LanguageSwitch from "../../../../../tools/Language/LanguageSwitch";
import TextareaInput from "../../../../../shared/Input/TextareaInput";
import ToggleButton from "../../../../../shared/Input/ToggleButton";
import Fa from "../../../../../tools/Icons/Fa";
import TextInput from "../../../../../shared/Input/TextInput";
import Dropdown from "../../../../../shared/Input/Dropdown";
import { T_ServiceActionModel } from "../../../../../../models/Models_StorageService";
import { connect } from "react-redux";
import { Selector_Service_Edit } from "../../../../../../store/selectors/Selectors_LocationService";
import DropzoneInput from "../../../../../shared/Input/DropzoneInput";
import { service_getImages, service_uploadImage } from "../../../../../../services/Service_Images";
import DropzoneImageContainer from "../../../../../shared/Input/Dropzone/DropzoneImageContainer";
import ItemSelect from "../../../../../shared/Input/ItemSelect";
import ButtonsConstructor from "../../../../../shared/ButtonsConstructor";
import ServerImage from "../../../../../shared/Image/ServerImage";
import { ItemSelectorContext } from "../../../../../shared/Input/ItemSelectorInput";
import { E_Modification } from "../../../../../../store/Constants_StoreShared";

class StorageServiceActionEditModal extends React.Component {
	render() {
		const {className, style, service, onModify, path, communicationUnits} = this.props;
		const action = get(service, path, T_ServiceActionModel);

		return (
			<section className={combineClasses(className, "service-action-edit")} style={{...style, height: "100%"}}>
				<LanguageSwitch
					label={<Translate id={"name"}/>}
					path={`${path}.displayName`}
					value={action.displayName}
					onModify={onModify}
				>
					<TextareaInput />
				</LanguageSwitch>

				<div className={"grid-table grid-icon-auto-1fr"}>
					<Fa icon={"power-off"}/>
					<label><Translate id={"state_ENABLED"}/></label>
					<ToggleButton
						defaultValue={action.enabled}
						path={`${path}.enabled`}
						onModify={onModify}
					/>

					<Fa icon={"project-diagram"} />
					<label><Translate id={"outputChannel"} /></label>
					<TextInput
						defaultValue={action.outputChannel || ''}
						path={`${path}.outputChannel`}
						onModify={onModify}
					/>

					<Fa icon={"microchip"} />
					<label><Translate id={"communicationUnit"} /></label>
					<Dropdown
						contentClassName={"dark"}
						path={`${path}.communicationUnit`}
						defaultValue={action.communicationUnit}
						options={communicationUnits}
						onLabel={item => item.name}
						onValue={item => item && ({id: item.id, name: item.name}) || null}
						onModify={onModify}
						canInvalidate={true}
					/>
				</div>

				<label><Translate id={"icon"}/></label>
				<DropzoneInput
					path={`${path}.icon`}
					defaultValue={action.icon}
					onModify={onModify}
					uploadService={service_uploadImage}
					accept={"image/*"}
					multiple={false}
				>
					<DropzoneImageContainer/>
				</DropzoneInput>

				<h4 style={{justifyContent: "center", marginTop: 15}}>-- <Translate id={"or"}/> --</h4>

				<button
					className={"fancy"}
					onClick={() => this._openImageGallery(action.icon)}
					disabled={!!action.icon}
				>
					<Fa icon={"images"}/>
					<span><Translate id={"selectFromGallery"}/></span>
				</button>
			</section>
		);
	}

	_openImageGallery(activeIcon) {
		const {onModify, path} = this.props;

		window.modal.open({
			body: <ItemSelect
				service={() => service_getImages([{
					field: "imageCategory",
					operator: "=",
					value: "ACTION_ICON",
				}])}
				defaultValue={activeIcon}
				label={"gallery"}
				canSearch={false}
				onModify={(t, p, value) => {
					onModify(E_Modification.ITEM_SET, `${path}.icon`, value);
				}}
			>
				<ItemSelectorContext.Consumer>
					{(itemSelectContext) => {
						return (
							<ButtonsConstructor
								style={{display: "flex", flexWrap: "wrap"}}
								buttons={
									itemSelectContext.filteredOptions.map(option => ({
										active: itemSelectContext.isActive(option),
										text: <ServerImage imageID={option.id} style={{width: "100%", height: "100%"}}/>,
										action: () => itemSelectContext.selectOption(option),
										style: {
											width: 100,
											height: 100,
										}
									}))
								}
							/>
						);
					}}
				</ItemSelectorContext.Consumer>
			</ItemSelect>
		});
	}

	static get propTypes() {
		return {
			...super.propTypes,
			service: PropTypes.object,
			onModify: PropTypes.func.isRequired,
			communicationUnits: PropTypes.array,
		}
	}

	static get defaultProps() {
		return {
			...super.defaultProps,
			onModify: (type, path, value) => null,
			communicationUnits: [],
		}
	}
}

const mapStateToProps = state => {
	let service = Selector_Service_Edit(state);
	return {
		service,
	};
};

export default connect(mapStateToProps)(StorageServiceActionEditModal);
