import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { E_Side, T_frameModel, T_FrameTypeBlockOptions } from "../../../../models/Models_StorageBox";
import "../../../../styles/pages/Location/StorageUnit/StorageUnit.scss";
import is from "../../../../utils/is";
import ButtonsConstructor from "../../../shared/ButtonsConstructor";
import Fa from "../../../tools/Icons/Fa";
import ViewSwitch from "../../../shared/ViewSwitch";
import TableListing from "../../../shared/Listing/TableListing";
import { Translate } from "react-localize-redux";
import StorageUnitRow from "./StorageUnitRow";
import { get, resolveItemName } from "../../../../utils/utils";
import { EO } from "../../../../utils/extensions";
import TableColumn from "../../../shared/Listing/TableColumn";
import { TranslateEntry } from "../../../../utils/localeUtils";
import StorageExpandContainers from "./StorageExpandContainers";
import CSBaseComponent from "../../../CSBaseComponent";
import StorageServiceExpandContainer from "./StorageServiceExpandContainer";
import StorageServiceDetail from "./Details/StorageService/StorageServiceDetail";
import { connect } from "react-redux";
import { Thunk_Frame_Add, Thunk_Frame_Remove, Thunk_Frame_Update } from "../../../../store/thunk/Thunk_StorageFrame";
import { bindActionCreators } from "redux";
import { errorHandler } from "../../../../store/ResponseHandling";
import { EDIT_BLOCK_REJECTED } from "../../../../store/actions/location/blockActions";
import { Thunk_Block_Add, Thunk_Block_Remove, Thunk_Block_Update } from "../../../../store/thunk/Thunk_StorageBlock";
import StorageFrameDetail from "./Details/StorageFrameDetail";
import StorageBlockDetail from "./Details/StorageBlockDetail";
import { T_APP_ICONS } from "../../../../models/constants/Constants_Shared";
import { service_openUnitNow } from "../../../../services/Service_Maintenance";
import { E_ToastStyle } from "../../../../models/Models_Toast";
import routes from "../../../../routes/routes";
import ItemRedirect from "../../../shared/ItemRedirect";
import TableRow from "../../../shared/Listing/TableRow";
import { T_RestrictionPresets } from "../../../../models/Models_Restrictions";

export const E_SelectionMode = {
	FRAME: "FRAME",
	BLOCK: "BLOCK",
	SERVICE: "SERVICE",
};

export const E_EditMode = {
	MOVE: "MOVE",
	EDIT: "EDIT",
	DELETE: "DELETE",
};

export const E_PickerType = {
	FRAME: "FRAME",
	BLOCK: "BLOCK",
}

class StorageUnitBase extends CSBaseComponent {
	constructor(props) {
		super(props);

		this.state = {
			...this.state,
			side: props.side || E_Side.FRONT,
			selectionMode: E_SelectionMode.SERVICE,
			editMode: E_EditMode.EDIT,
			scrollSide: props.side || E_Side.FRONT,
			pickerType: null,
			pickerTarget: {},
		};
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		super.componentDidUpdate(prevProps, prevState, snapshot);

		if(!is.equal(prevProps.side, this.props.side)) {
			this.setState({side: this.props.side});
		}

		if(!is.equal(prevState.side, this.state.side)) {
			this.setState({scrollSide: null});
			setTimeout(() => this.setState({scrollSide: this.state.side}), 300);
		}
	}

	_renderTableTemplate(service, i, headerItems) {
		return (
			<TableRow key={i} headerItems={headerItems} onClick={() => this._openServiceDetail(service.id)}>
				<TableColumn column={"physicalReference"}>
					{service.physicalReference || '-'}
				</TableColumn>
				<TableColumn column={"enabled"}>
					{
						service.enabled &&
						<Translate id={"yes"}/>
						||
						<b className={"cl-error"}><Translate id={"no"}/></b>
					}
				</TableColumn>
				<TableColumn column={"occupied"}>

					{
						service.occupied &&
						<ItemRedirect href={`${routes.SESSIONS}/${service.activeSession.id}`}>
							<Translate id={"yes"}/>
						</ItemRedirect>
						||
						'-'
					}
				</TableColumn>
				<TableColumn column={"tariff"}>
					{
						get(service, `serviceType.tariff.id`) &&
						<ItemRedirect href={`${routes.TARIFFS}/${service.serviceType.tariff.id}`}>
							{resolveItemName(get(service, `serviceType.tariff`), `name`)}
						</ItemRedirect>
						||
						'-'
					}
				</TableColumn>
				<TableColumn column={"serviceCategory"}>
					{
						get(service, `serviceType.serviceCategory.id`) &&
						<ItemRedirect href={`${routes.SERVICE_CATEGORIES}/${service.serviceType.serviceCategory.id}`}>
							<TranslateEntry entry={get(service, `serviceType.serviceCategory.titles`)} />
						</ItemRedirect>
						||
						'-'
					}
				</TableColumn>
				<TableColumn column={"serviceType"}>
					{
						get(service, `serviceType.id`) &&
						<ItemRedirect href={`${routes.SERVICE_TYPES}/${service.serviceType.id}`}>
							<TranslateEntry entry={get(service, `serviceType.titles`)} />
						</ItemRedirect>
						||
						'-'
					}
				</TableColumn>
			</TableRow>
		)
	}

	_renderUnitView() {
		const {hasControl, customProps, unit, Thunk_Frame_Remove} = this.props;
		const {side, selectionMode, editMode, scrollSide} = this.state;
		const {isEdit} = customProps || {};

		return (
			<Fragment>
				<header className={"storage-unit-header"}>
					<input
						type={"checkbox"}
						checked={side == E_Side.FRONT}
						onChange={e => this.setState({side: e.target.checked ? E_Side.FRONT : E_Side.BACK})}
						className={`cube`}
						disabled={!hasControl}
					/>

					<ButtonsConstructor
						wrapInNav={true}
						buttons={
							isEdit ? [
								{
									text: <label><Translate id={"selectors"}/></label>,
									action: () => null,
									style: {pointerEvents: "none"},
								},
								{
									icon: "columns",
									tippy: <Translate id={"frame"}/>,
									action: () => this.setState({selectionMode: E_SelectionMode.FRAME}),
									className: "fancy",
									active: E_SelectionMode.FRAME == selectionMode
								},
								{
									icon: "th-large",
									tippy: <Translate id={"block"}/>,
									action: () => this.setState({selectionMode: E_SelectionMode.BLOCK}),
									className: "fancy",
									active: E_SelectionMode.BLOCK == selectionMode
								},
								{
									icon: T_APP_ICONS.SERVICE,
									tippy: <Translate id={"service"}/>,
									action: () => this.setState({selectionMode: E_SelectionMode.SERVICE}),
									className: "fancy",
									active: E_SelectionMode.SERVICE == selectionMode
								},
								/*{action: () => null,className: "spacer"},
								{ //TODO: Implement drag & drop behavior
									icon: "arrows-alt",
									tippy: <Translate id={"move"}/>,
									action: () => this.setState({editMode: E_EditMode.MOVE}),
									className: "fancy",
									active: E_EditMode.MOVE == editMode
								},
								{
									icon: T_APP_ICONS.EDIT_DIALOG,
									tippy: <Translate id={"edit"}/>,
									action: () => this.setState({editMode: E_EditMode.EDIT}),
									className: "fancy",
									active: E_EditMode.EDIT == editMode
								},
								{
									icon: T_APP_ICONS.REMOVE,
									tippy: <Translate id={"remove"}/>,
									action: () => this.setState({editMode: E_EditMode.DELETE}),
									className: "fancy",
									active: E_EditMode.DELETE == editMode
								},*/
							] : [
								{
									icon: "unlock-alt",
									text: "emergencyOpen",
									className: "unit-control",
									action: () => this._openUnit(),
									confirm: <span><Fa icon={"unlock-alt"} /> {window.translator.translate("emergencyOpen")}</span>,
									restricted: T_RestrictionPresets.LOCK_CONTROL
								}
							]
						}
					/>
				</header>

				<div className={"scene"}>
					<hr />
					<div
						className={`box show-${(side || '').toLowerCase()}`}
						data-edit-mode={isEdit ? editMode : 'NONE'}
						data-select-mode={isEdit ? selectionMode : 'NONE'}
					>
						{EO(E_Side).toArray(true).map((side, i) => (
							<Fragment key={i}>
								<div className={"box__face box__face--"+`${side}`.toLocaleLowerCase()}>
									<div
										className={"face-content"}
									>
										<StorageUnitRow
											frames={get(unit, `frames`, []).filter(frame => frame.side == side)}
											customProps={customProps}
											{...this._getClickFunctions()}
											scrollEnabled={scrollSide == side}
										/>
									</div>
								</div>
							</Fragment>
						))}
					</div>

					<StorageExpandContainers
						ref={this.setRef}
						name={"expandContainers"}
						onFrameSelect={ this._updateFrameType.bind(this)}
						onBlockSelect={this._updateBlockType.bind(this)}
						onPickerInvalidate={() => this.setState({pickerType: null, pickerTarget: {}})}
						pickerType={this.state.pickerType}
						unit={unit}
						pickerTarget={this.state.pickerTarget}
						onFrameRemove={(frameID) => {
							Thunk_Frame_Remove(frameID);
							this.setState({pickerTarget: null, pickerType: null});
						}}
					/>

					<StorageServiceExpandContainer/>
				</div>
			</Fragment>
		)
	}

	render() {
		const {className, style} = this.props;

		return (
			<section className={`storage-unit ${className || ''}`} style={style}>
				<ViewSwitch
					options={[
						{
							view: "unit",
							default: true,
							button: {
								icon: "cs-cabinet-filling",
								tippy: <Translate id={"viewMode_unit"} />
							},
							content: this._renderUnitView(),
						},
						{
							view: "table",
							button: {
								icon: "table",
								tippy: <Translate id={"viewMode_table"} />
							},
							content: (
								<TableListing
									fetched={true}
									itemTemplate={this._renderTableTemplate.bind(this)}
									headerItems={{
										physicalReference: {label: "service_name"},
										enabled: {label: "state_ENABLED"},
										occupied: {},
										tariff: {},
										serviceCategory: {},
										serviceType: {},
									}}
									canSort={false}
									data={this._setupTableData()}
									canModifyColumns={true}
									name={"location-services"}
									style={{maxHeight: "100vh", margin: 40}}
									className={"bordered stripes"}
								/>
							)
						}
					]}
				/>
			</section>
		)
	}

	_setupTableData() {
		const {unit} = this.props;

		let services = get(unit, `frames`, []).map(
			frame => frame.blocks.map(block => block.services)
		).flat(2).sort(
			(a,b) => {
				a = a.physicalReference;
				b = b.physicalReference;

				return a > b ? 1 : b > a ? -1 : 0;
			}
		);

		return {
			content: services
		}
	}

	_getClickFunctions() {
		const {customProps} = this.props;
		const {isEdit} = customProps;
		const {editMode} = this.state;

		const defaults = {
			onFrameClick: (e, frame) => null,
			onEmptyFrameClick: (e, frame) => null,
			onBlockClick: (e, block) => null,
			onEmptyBlockClick: (e, block) => null,
			onServiceClick: (e, service) => null,
		}

		if(!isEdit) {
			return defaults;
		}

		switch (editMode) {
			case E_EditMode.DELETE:
				return {
					//Any frame that has frameType property defined
					onFrameClick: (e, frame) => this._removeFrame(frame),
					//Any frame that has NOT frameType property defined
					onEmptyFrameClick: (e, frame) => this._removeFrame(frame, false),
					//Any block that has blockType property defined
					onBlockClick: (e, block) => this._removeBlock(block),
					//Any block that has NOT blockType property defined
					onEmptyBlockClick: (e, block) => this._removeBlock(block, false),
					onServiceClick: (e, service) => null,
				}
			case E_EditMode.EDIT:
				return {
					//Any frame that has frameType property defined
					onFrameClick: (e, frame) => this._openFrameDetail(frame),
					//Any frame that has NOT frameType property defined
					onEmptyFrameClick: (e, frame) => this._handleEmptyFrameClick(e, frame),
					//Any block that has blockType property defined
					onBlockClick: (e, block) => this._openBlockDetail(block),
					//Any block that has NOT blockType property defined
					onEmptyBlockClick: (e, block) => this._handleEmptyBlockClick(block),
					onServiceClick: (e, service) => null,
				}
			default:
				return defaults;
		}
	}

	_openServiceDetail(serviceID) {
		window.modal.open({
			body: <StorageServiceDetail serviceID={serviceID} />,
			className: "dark wide-content",
		})
	}

	_openFrameDetail(frame) {
		const {path} = this.props;

		window.modal.open({
			body: <StorageFrameDetail frame={frame} path={path}/>,
		})
	}

	_handleEmptyFrameClick(e, frame) {
		if(frame.frameType == "ADD") {
			//Create new empty frame
			this._createFrame(frame.orderPosition);
		} else {
			//Open frame select
			this.setState({
				pickerType: E_PickerType.FRAME,
				pickerTarget: frame,
			});
		}
	}

	_createFrame(orderPosition) {
		const {unit, path, Thunk_Frame_Add} = this.props;
		const {side} = this.state;

		Thunk_Frame_Add(
			unit.id,
			`${path}.frames`,
			{
				...T_frameModel,
				side,
				orderPosition
			}
		)
	}

	_updateFrameType(frameType) {
		const {Thunk_Frame_Update, path} = this.props;
		let frame = this.state.pickerTarget;

		Thunk_Frame_Update(`${path}.frames.#${frame.id}`, {
			...frame,
			frameType
		});
		this.setState({pickerTarget: null, pickerType: null});
	}

	_handleEmptyBlockClick(block) {
		const {unit} = this.props;
		let frame = get(unit, `frames`, []).findByID(block.frameID) || {};
		let blockOptions = T_FrameTypeBlockOptions[frame.frameType];
		if(!blockOptions || (blockOptions && blockOptions.length > 1)) {
			//Open block select
			this.setState({
				pickerType: E_PickerType.BLOCK,
				pickerTarget: block,
			});
		} else {
			this._updateBlockType(blockOptions[0], block);
		}
	}

	_updateBlockType(blockType, blockOverride = null) {
		const {Thunk_Block_Add, Thunk_Block_Update, path, unit} = this.props;
		let block = blockOverride || this.state.pickerTarget;

		if(block) {
			let frame = block.frameID ? {id: block.frameID} : get(unit, `frames`, []).find(frame => !!frame.blocks.findByID(block.id));

			if(frame) {
				let blockData = {
					...block,
					blockType,
					customHeight: 6,//FFS: Should be dynamic
				};

				if(block.id) {
					Thunk_Block_Update(`${path}.frames.#${frame.id}.blocks.${block.id}`, blockData);
				} else {
					Thunk_Block_Add(frame.id, blockData, `${path}.frames.#${frame.id}.blocks.${block.id}`)
				}

				this.setState({
					pickerTarget: null,
					pickerType: null
				});
			}
			else {
				errorHandler({
					message: "Frame is not defined in StorageUnitBase::_updateBlockType"
				}, EDIT_BLOCK_REJECTED);
			}
		} else {
			errorHandler({
				message: "Block is not defined in StorageUnitBase::_updateBlockType"
			}, EDIT_BLOCK_REJECTED);
		}
	}

	_openBlockDetail(block) {
		const {path} = this.props;

		window.modal.open({
			body: <StorageBlockDetail block={block} path={path}/>,
		})
	}

	_removeFrame(frame, requireConfirm = true) {//TODO: add confirm
		const {Thunk_Frame_Remove} = this.props;

		Thunk_Frame_Remove(frame.id);
	}

	_removeBlock(block, requireConfirm = true) {//TODO: add confirm
		const {Thunk_Block_Remove} = this.props;

		Thunk_Block_Remove(block.id);
	}

	_openUnit() {
		const {unit} = this.props;

		service_openUnitNow((unit || {}).id, "scope=ALL").then(() => {
			window.toaster.showToast({
				style: E_ToastStyle.SUCCESS,
				icon: "lock-open",
				content: "unit_emergencyOpen_success",
			});
		}, error => {
			errorHandler(error, "UNIT_EMERGENCY_OPEN_FAILED");
		});
	}

	static get propTypes() {
		return {
			...super.propTypes,
			unit: PropTypes.object,
			path: PropTypes.string,
			side: PropTypes.string,
			hasControl: PropTypes.bool,
			customProps: PropTypes.object,
			scrollSide: PropTypes.string,

			onChangeHasControl: PropTypes.func,
		}
	}

	static get stateTypes() {
		return {
			...super.stateTypes,
			side: PropTypes.bool,
			pickerType: PropTypes.string,
			pickerTarget: PropTypes.object,
		}
	}

	static get defaultProps() {
		return {
			...super.defaultProps,
			customProps: {},
			hasControl: true,
			onChangeHasControl: () => null,
		}
	}
}

const mapDispatchToProps = dispatch => (
	bindActionCreators({
		Thunk_Frame_Add,
		Thunk_Frame_Remove,
		Thunk_Frame_Update,
		Thunk_Block_Add,
		Thunk_Block_Update,
		Thunk_Block_Remove
	}, dispatch)
);

export default connect(null, mapDispatchToProps)(StorageUnitBase);
