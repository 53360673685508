import { Action_Generic_Edit_Modify, Action_Generic_Fail, Action_Generic_Payload, Action_Generic_Type, GENERIC_EDIT_CANCEL, GENERIC_EDIT_FULFILLED, GENERIC_EDIT_INIT, GENERIC_EDIT_MODIFY, GENERIC_EDIT_PENDING, GENERIC_EDIT_REJECTED, GENERIC_FETCH_FULFILLED, GENERIC_FETCH_PENDING, GENERIC_FETCH_REJECTED } from "../genericActions";

const REDUCER_LISTING_NAME = `COMMUNICATION_UNITS`;
const REDUCER_ITEM_NAME = `COMMUNICATION_UNIT`;

export const Action_CommunicationUnits_Fetch_Pending = () => Action_Generic_Type(`${REDUCER_LISTING_NAME}_${GENERIC_FETCH_PENDING}`);
export const Action_CommunicationUnits_Fetch_Fulfilled = (list, ...props) => Action_Generic_Payload(`${REDUCER_LISTING_NAME}_${GENERIC_FETCH_FULFILLED}`, list, ...props);
export const Action_CommunicationUnits_Fetch_Rejected = (error, ...props) => Action_Generic_Fail(`${REDUCER_LISTING_NAME}_${GENERIC_FETCH_REJECTED}`, error, ...props);

export const Action_CommunicationUnit_Fetch_Pending = () => Action_Generic_Type(`${REDUCER_ITEM_NAME}_${GENERIC_FETCH_PENDING}`);
export const Action_CommunicationUnit_Fetch_Fulfilled = (data, ...props) => Action_Generic_Payload(`${REDUCER_ITEM_NAME}_${GENERIC_FETCH_FULFILLED}`, data, ...props);
export const Action_CommunicationUnit_Fetch_Rejected = (error, ...props) => Action_Generic_Fail(`${REDUCER_ITEM_NAME}_${GENERIC_FETCH_REJECTED}`, error, ...props);

export const Action_CommunicationUnit_Edit_Init = (initData, ...props) => Action_Generic_Payload(`${REDUCER_ITEM_NAME}_${GENERIC_EDIT_INIT}`, initData, ...props);
export const Action_CommunicationUnit_Edit_Cancel = () => Action_Generic_Type(`${REDUCER_ITEM_NAME}_${GENERIC_EDIT_CANCEL}`);
export const Action_CommunicationUnit_Edit_Modify = (type, path, change, ...props) => (
	Action_Generic_Edit_Modify(`${REDUCER_ITEM_NAME}_${GENERIC_EDIT_MODIFY}`, type, path, change, ...props)
);
export const Action_CommunicationUnit_Edit_Pending = () => Action_Generic_Type(`${REDUCER_ITEM_NAME}_${GENERIC_EDIT_PENDING}`);
export const Action_CommunicationUnit_Edit_Fulfilled = (data, ...props) => Action_Generic_Payload(`${REDUCER_ITEM_NAME}_${GENERIC_EDIT_FULFILLED}`, data, ...props);
export const Action_CommunicationUnit_Edit_Rejected = (error, ...props) => Action_Generic_Fail(`${REDUCER_ITEM_NAME}_${GENERIC_EDIT_REJECTED}`, error, ...props);
