import React from "react";
import ErrorBlock from "../shared/Error/ErrorBlock";
import { NavLink } from "react-router-dom";
import { Translate } from "react-localize-redux";
import Fa from "../tools/Icons/Fa";
import { T_APP_ICONS } from "../../models/constants/Constants_Shared";
import { DEFAULT_ROUTE } from "../../routes/routes";

class NotFound extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			...super.state,

		};
	}

	render() {
		return (
			<section className={`not-found`}>
				<ErrorBlock
					title={"page_notFound"}
					message={
						<NavLink to={DEFAULT_ROUTE}>
							<Fa icon={T_APP_ICONS.BACK}/>
							&nbsp;
							<Translate id={"back_mainPage"}/>
						</NavLink>
					}
				/>
			</section>
		)
	}
}

export default NotFound;
