import {T_AddressModel} from "./Models_Shared";

/**
 * Business Details
 * ---
 * @type {{
 *          companyRegistrationNumber: String,
 *          contactEmail: String,
 *          companyName: String,
 *          landLine: String,
 *          taxIDNumber: String,
 *          webUrl: String,
 *          companyAdditionalInfo: String,
 *          logo: T_LogoImageModel,
 *          cellPhone: String
 *       }}
 */
export const T_BusinessDetails = {
	...T_AddressModel,
	cellPhone: null,
	companyAdditionalInfo: null,
	companyName: null,
	companyRegistrationNumber: null,
	contactEmail: null,
	landLine: null,
	taxIDNumber: null,
	webUrl: null,
	logo: null,
};


/**
 * Partner Model
 * ---
 * @type {{
 *          hiddenDescription: String,
 *          name: String,
 *          id: Number,
 *          businessDetails: T_BusinessDetails,
 *          parent: T_PartnerModel,
 *          children: [T_PartnerModel],
 *       }}
 */
export const T_PartnerModel = {
	name: null,
	id: null,
	hiddenDescription: null,
	businessDetails: T_BusinessDetails,
	parent: null,
	children: [],
};
