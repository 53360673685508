import React from "react";
import PropTypes from "prop-types";
import Dropdown from "../../shared/Input/Dropdown";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {get, resolveItemName} from "../../../utils/utils";
import {E_UserRoles, T_APP_ICONS} from "../../../models/constants/Constants_Shared";
import Fa from "../../tools/Icons/Fa";
import {Translate, withLocalize} from "react-localize-redux";
import {Selector_Vouchers_Listing_Data} from "../../../store/selectors/Selectors_Vouchers";
import {Thunk_Vouchers_Fetch_All} from "../../../store/thunk/Thunk_Vouchers";
import {Thunk_Users_Fetch_All} from "../../../store/thunk/Thunk_Users";
import {withRouter} from "react-router-dom";
import {service_insertSession} from "../../../services/Service_Sessions";
import routes from "../../../routes/routes";
import ButtonsConstructor from "../../shared/ButtonsConstructor";
import UserSelect from "../Users/UserSelect";
import TextInput from "../../shared/Input/TextInput";
import {errorHandler} from "../../../store/ResponseHandling";
import {SESSION_CREATE_REJECTED} from "../../../store/actions/sessions/sessionsActions";
import ReservationCalendar from "./ReservationCalendar/ReservationCalendar";
import '../../../styles/pages/Session/NewReservationModal.scss';
import {Selector_Auth_Principal} from "../../../store/selectors/Selectors_Auth";
import withRestrictions from "../../shared/Restrictions/withRestrictions";

class NewReservationModal extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			...super.state,
			selection: props.selection || [],
			activeVoucher: props.voucher,
			activeUser: props.user,
		};
	}

	render() {
		const {serviceID, Thunk_Vouchers_Fetch_All} = this.props;
		const {activeUser, activeVoucher, selection} = this.state;

		return (
			<section className={"new-reservation-modal"}>
				<ReservationCalendar
					serviceID={serviceID}
					onSelectionChange={(selection) => this.setState({selection})}
					onSummary={(summaryData) => this.setState({summaryData})}
					voucherCode={get(activeVoucher, "voucherCode", undefined)}
					screenVerticalOrientation={window.innerWidth < 1200}
				/>

				<div className={"reservation-details"}>
					<label><Translate id={"user"}/>*</label>
					<button
						className={"no-style"}
						onClick={() => this._openUserSelect()}
					>
						<TextInput
							defaultValue={resolveItemName(activeUser, `email`)}
							suffix={<Fa icon={T_APP_ICONS.EDIT_DIALOG}/>}
						/>
					</button>

					<label><Translate id={"voucher"}/></label>
					<Dropdown
						selector={Selector_Vouchers_Listing_Data}
						thunk={() => Thunk_Vouchers_Fetch_All}
						defaultValue={activeVoucher}
						isActive={item => item.id == this._getActiveID(activeVoucher)}
						onLabel={item => resolveItemName(item, `voucherCode`)}
						onModify={(t,p, activeVoucher) => this.setState({activeVoucher})}
						canInvalidate={true}
					/>

					<ButtonsConstructor wrapInNav={true} className={"action-buttons"} buttons={[{
						icon: "user-lock",
						text: "reserve",
						className: "fancy submit",
						action: () => this._createNewSession(),
						disabled: !selection.length,
						disabledTippy: <div style={{margin: 5}}><Translate id={"selectTimeBracketFirst"}/></div>,
					}]}/>
				</div>
			</section>
		)
	}

	_getActiveID(base, fallback = undefined) {
		return get(base, `id`, fallback)
	}

	_createNewSession() {
		const {serviceID, history, hasAccess} = this.props;
		const {activeVoucher, activeUser, selection} = this.state;

		service_insertSession(JSON.stringify({
			serviceID,
			voucherCode: get(activeVoucher, "voucherCode", undefined),
			locale: 'cs',
			userID: this._getActiveID(activeUser),
			reservationFrom: selection[0].from,
			reservationTo: selection.lastItem().to,
			activateNow: hasAccess(E_UserRoles.ADMIN),
		})).then(data => {
			window.modal.close();
			history.push(`${routes.SESSIONS}/${data.id}`);
		}, error => {
			errorHandler(error, SESSION_CREATE_REJECTED);
		});
	}

	_openUserSelect() {
		const {activeUser} = this.state;

		window.modal.open({
			body: (
				<UserSelect
					defaultValue={activeUser}
					onModify={(t,p, activeUser) => this.setState({activeUser})}
				/>
			),
		})
	}

	static get propTypes() {
		return {
			...super.propTypes,
			serviceID: PropTypes.number.isRequired,
			voucher: PropTypes.object,
			user: PropTypes.object,
			selection: PropTypes.array,
			history: PropTypes.object.isRequired,

			Thunk_Vouchers_Fetch_All: PropTypes.func.isRequired,
			Thunk_Users_Fetch_All: PropTypes.func.isRequired,
		}
	}

	static get stateTypes() {
		return {
			...super.stateTypes,
		}
	}

	static get defaultProps() {
		return {
			...super.defaultProps,
		}
	}
}

const mapStateToProps = state => {
	return {
		user: Selector_Auth_Principal(state),
	};
};

const mapDispatchToProps = dispatch => (
	bindActionCreators({
		Thunk_Vouchers_Fetch_All,
		Thunk_Users_Fetch_All,
	}, dispatch)
);

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(withRestrictions(withLocalize(NewReservationModal))));
