import React from "react";
import ItemSelectInputBase from "../../shared/Input/Base/ItemSelectInputBase";
import { resolveItemName } from "../../../utils/utils";
import UserSelect from "./UserSelect";

class UserSelectInput extends React.Component {
	render() {
		return <ItemSelectInputBase
			modalContent={<UserSelect/>}
			onLabel={item => resolveItemName(item, `email`)}
			{...this.props}
		/>
	}
}

export default UserSelectInput;
