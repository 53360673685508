import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import ItemSelect from "../../shared/Input/ItemSelect";
import { Selector_Partners_Listing_Root } from "../../../store/selectors/Selectors_Partners";
import { Thunk_Partners_Fetch_All } from "../../../store/thunk/Thunk_Partners";

class PartnerSelect extends React.Component {
	render() {
		const {Thunk_Partners_Fetch_All} = this.props;

		return <ItemSelect
			thunk={Thunk_Partners_Fetch_All}
			selector={Selector_Partners_Listing_Root}
			label={"partner_select"}
			headerItems={{name: {}, id: {}}}
			{...this.props}
		/>
	}

	static get propTypes() {
		return {
			...super.propTypes,
			...ItemSelect.propTypes,
		}
	}
}

const mapDispatchToProps = dispatch => (
	bindActionCreators({
		Thunk_Partners_Fetch_All
	}, dispatch)
);

export default connect(null, mapDispatchToProps)(PartnerSelect);
