/**
 * Service Mobile Model
 * @type {{
 *          serviceType: T_ServiceType,
 *          activeSession: T_ActiveSessionMobileModel,
 *          orderPosition: Number,
 *          id: Number,
 *          enabled: Boolean,
 *          occupied: Boolean,
 *          physicalReference: Number
 *       }}
 */
export const T_ServiceMobileModel= {
	activeSession: {},
	enabled: true,
	id: 0,
	occupied: true,
	orderPosition: 0,
	physicalReference: 0,
	serviceType: {}
};

export const T_FileUploadDataModel = {
	error: false,
	uploaded: false,
	loaded: 0,
	total: 0,
	progress: 0,
	indeterminate: false,
}
