import { Action_Generic_Edit_Modify, Action_Generic_Fail, Action_Generic_Type } from "../genericActions";

// EDIT FRAME ACTION NAMES
export const EDIT_FRAME_PENDING = 'EDIT_FRAME_PENDING';
export const EDIT_FRAME_FULFILLED = 'EDIT_FRAME_FULFILLED';
export const EDIT_FRAME_REJECTED = 'EDIT_FRAME_REJECTED';

export const Action_Frame_Send = () => Action_Generic_Type(EDIT_FRAME_PENDING);
export const Action_Frame_Send_Success = (type, path, modification, ...props) => Action_Generic_Edit_Modify(EDIT_FRAME_FULFILLED, type, path, modification, ...props);
export const Action_Frame_Add_Send_Fail = (error, ...props) => Action_Generic_Fail(EDIT_FRAME_REJECTED, error, ...props);
export const Action_Frame_Remove_Send_Fail = (error, ...props) => Action_Generic_Fail(EDIT_FRAME_REJECTED, error, ...props); //TODO set another type matching delete state
export const Action_Frame_Update_Send_Fail = (error, ...props) => Action_Generic_Fail(EDIT_FRAME_REJECTED, error, ...props); //TODO set another type matching update state
