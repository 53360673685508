// eslint-disable-next-line no-unused-vars
import { DELETE, formatQueryString, GET, PUT, sendRequest } from "./apiServices/apiConnector";
import { API_URL } from "./apiServices/constants/endpoints";
import { service_uploadFile } from "./Service_FileUpload";
import { Process_formatQueryString } from "../utils/processors";

/**
 * Get Image
 * ---
 * @param {Number} imageID
 * @returns {Promise<{id: Number, imageUrl: String, thumbnailUrl: String}>}
 */
export const service_getImage = (imageID) => {
	return sendRequest([`/image/${imageID}`, GET]).then(response => response.json());
};

export const service_getImages = (filters) => {
	return sendRequest(`/image/${formatQueryString(Process_formatQueryString({filters}))}`).then(response => response.json());
};

/**
 * Get Image
 * ---
 * @param {Number} imageID
 * @param {String} body
 * @returns {Promise<{id: Number, imageUrl: String, thumbnailUrl: String}>}
 */
export const service_updateImage = (imageID, body) => {
	return sendRequest([`/image/${imageID}`, PUT, body]).then(response => {
		return response.json();
	});
};

export const service_deleteImage = (imageID) => {
	return sendRequest([`/image/${imageID}`, DELETE]).then(response => {
		return response;
	});
};

export const service_getImageBinary = (imageID) => {
	return sendRequest([`/image/data/${imageID}`, GET]).then(response => {
		return response.blob();
	});
};

export const service_getImageThumbnailBinary = (imageID) => {
	return sendRequest([`/image/data/${imageID}/thumbnail`, GET]).then(response => {
		return response.blob();
	});
};

/**
 * Upload image
 * ---
 * @param {File} imageFile
 * @param {function(File, T_FileUploadDataModel)} progressChange
 * @param {Number} timeout
 */
export const service_uploadImage = (imageFile, progressChange = () => null, timeout = null) => {
	return service_uploadFile(`${API_URL}/image/upload`, imageFile, progressChange, timeout);
}
