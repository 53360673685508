import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import "../../../styles/pages/ItemList/ItemList.scss";
import { css } from "../../../utils/utils";
import { Translate } from "react-localize-redux";
import { T_SharedPropTypes, T_SharedReduxPropTypes } from "../../../models/Models_Shared";

class ItemListing extends React.Component {

	constructor(props) {
		super(props);
		this.state = {}
	}

	renderListItem = (itemData, i) => (
		<Fragment key={itemData.id || i}>
			{this.props.itemTemplate && this.props.itemTemplate(itemData, i)}
		</Fragment>
	);

	renderList = (items) => (
		items.map((item, i) => this.renderListItem(item, i))
	);

	_wrapWithDiv(body) {
		const {className} = this.props;

		if (className) {
			return (
				<div style={{flex: 1, width: "100%", overflow: "hidden auto"}}>
					<div className={className}>
						{body}
					</div>
				</div>
			)
		}
		return body;
	}

	render() {
		const {items, fetched} = this.props;

		if ((items || []).length > 0)
			return this._wrapWithDiv(this.renderList(items));
		return fetched && (
			<h1 style={css(`
				position: absolute;
				top: 50%;
				left: 50%;
				text-align: center;
				transform: translate(-50%)
			`)}>
				<label>
					<Translate id={"noEntries"}/>
				</label>
			</h1>
		) || null;
	}

}

ItemListing.propTypes = {
	...T_SharedPropTypes,
	...T_SharedReduxPropTypes,
	itemTemplate: PropTypes.func.isRequired,
	items: PropTypes.array
};

ItemListing.stateTypes = {};

export default ItemListing
