/*es-lint-disable*/
import React from "react";
import CoordsInput from "../shared/Input/CoordsInput";

class PlaygroundPage extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			dropzoneData: [{
				id: 6,
				imageUrl: "dasdasd",
				thumbnailUrl: "sodjisajd",
			}, {
				id: 363
			}],
		}
	}

	render() {
		return (
			<section>
				<div>
					<CoordsInput
						onModify={(t,p,v) => console.log(t,p,v)}
					/>
				</div>
			</section>
		)
	}

	static get propTypes() {
		return {
			...super.propTypes,
		}
	}

	static get stateTypes() {
		return {
			...super.stateTypes,
		}
	}

	static get defaultProps() {
		return {
			...super.defaultProps,
		}
	}
}

export default PlaygroundPage;
