import { E_FileTypes, T_RegexPatterns } from "../models/constants/Constants_Shared";

export const getFileType = (file) => {
	let name = file && file.name || '';

	if(name.match(T_RegexPatterns.image)) {
		return E_FileTypes.IMAGE;
	} else if(name.match(T_RegexPatterns.xls)) {
		return E_FileTypes.XLS;
	} else if(name.match(/(\.pdf)/)) {
		return E_FileTypes.PDF
	}
	return E_FileTypes.FILE;
}

export const getFileIcon = (fileType) => {
	if(fileType == E_FileTypes.IMAGE){
		return;
	}

	switch (fileType) {
		case E_FileTypes.XLS:
			return "file-excel";
		case E_FileTypes.PDF:
			return "file-pdf";
		default:
			return "file";
	}
}
