import { service_fetchSession, service_fetchSessions } from "../../services/Service_Sessions";
import { Action_Session_Fetch_Fulfilled, Action_Session_Fetch_Pending, Action_Session_Fetch_Rejected, Action_Sessions_Fetch_Fulfilled, Action_Sessions_Fetch_Pending, Action_Sessions_Fetch_Rejected } from "../actions/sessions/sessionsActions";
import { Thunk_Generic_Fetch_All, Thunk_Generic_Fetch_Item } from "./Thunk_Generic";

export const Thunk_Sessions_Fetch_All = queryString => (
	Thunk_Generic_Fetch_All(
		queryString,
		service_fetchSessions,
		Action_Sessions_Fetch_Pending,
		Action_Sessions_Fetch_Fulfilled,
		Action_Sessions_Fetch_Rejected
	)
);

export const Thunk_Session_Insert = () => null;
// (
// 	Thunk_Generic_Edit_Insert(
// 		service_insertSession,
// 		Action_Session_Edit_Pending,
// 		Action_Session_Edit_Fulfilled,
// 		Action_Session_Edit_Rejected,
// 		Selector_Session_Edit_Data
// 	)
// );

export const Thunk_Session_Fetch = itemID => (
	Thunk_Generic_Fetch_Item(
		itemID,
		service_fetchSession,
		Action_Session_Fetch_Pending,
		Action_Session_Fetch_Fulfilled,
		Action_Session_Fetch_Rejected
	)
);
