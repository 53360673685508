import React from "react";
// eslint-disable-next-line no-unused-vars
import PropTypes from "prop-types";
import {combineClasses, css} from "../../../utils/utils";
import ErrorBlock from "../../shared/Error/ErrorBlock";
import ButtonsConstructor from "../../shared/ButtonsConstructor";
import CubeSpinner from "../../shared/LoadingIndicator/CubeSpinner";
import {E_DeliveryType, E_OpenBoxModes, E_SessionType} from "../../../models/Models_Session";
import {Translate} from "react-localize-redux";
import {service_completeDeliverySessionFromPin, service_completeSessionFromPin} from "../../../services/Service_Sessions";
import {SESSION_COMPLETE_REJECTED, SESSION_MARK_AS_DELIVERED_REJECTED} from "../../../store/actions/sessions/sessionsActions";
import {errorHandler} from "../../../store/ResponseHandling";
import {E_ToastStyle} from "../../../models/Models_Toast";

class DeliveryPageMarkAsDelivered extends React.Component {
	constructor() {
		super();

		this.state = {
			sending: false,
		};
	}

	render() {
		const {className, style, session} = this.props;
		const {sending} = this.state;

		return (
			<section className={combineClasses("delivery-page-mark-as-delivered", className)} style={style}>
				<span style={css(`white-space: pre-wrap; text-align: center;`)}>
					<Translate id={this._isForCourier() ? (session.deliveryType === E_DeliveryType.COURIER_PICK_UP ? "markAsPickedUpByCourierMessage" : "markAsDeliveredMessage") : "markAsRetrievedMessage"}/>
				</span>
				<ErrorBlock
					title={this._isForCourier() ? "noFurtherBoxAccessWarning" : "boxWillNowOpen"}
					style={css(`border: 3px solid var(--state-error-color); margin: 10px 0`)}
				/>
				<ButtonsConstructor
					wrapInNav={true}
					style={css(`display: flex; margin: 10px -5px -5px`)}
					buttons={[
						{
							text: "no",
							icon: "times",
							style: css(`border-color: #333333aa`),
							action: () => window.modal.close(),
						},
						{
							text: this._isForCourier() ? (session.deliveryType === E_DeliveryType.COURIER_PICK_UP ? "markAsPickedUpByCourier" : "markAsDelivered") : "endSessionPickup",
							icon: "check",
							action: () => this._isForCourier() ? this._completeDeliverySession() : this._completeSession(),
							disabled: sending,
						}
					]}
					sharedProps={{className: "fancy", style: {margin: 5}}}
				/>

				{
					sending &&
					<div className={"loading-overlay"}>
						<CubeSpinner block={true}/>
					</div>
				}
			</section>
		);
	}

	_isForCourier() {
		return this.props.session.sessionType == E_SessionType.DELIVERY_COURIER;
	}

	_completeSession() {
		const {pin} = this.props;

		this.setState({sending: true});
		service_completeSessionFromPin(pin, E_OpenBoxModes.OPEN_NOW).then(
			session => this._handleData(session),
			error => errorHandler(error, SESSION_COMPLETE_REJECTED)
		).finally(() => {
			this.setState({sending: false});
		});
	}

	_completeDeliverySession() {
		const {pin} = this.props;

		this.setState({sending: true});
		service_completeDeliverySessionFromPin(pin, E_OpenBoxModes.DO_NOT_OPEN).then(
			session => this._handleData(session),
			error => errorHandler(error, SESSION_MARK_AS_DELIVERED_REJECTED)
		).finally(() => {
			this.setState({sending: false});
		});
	}

	_handleData(data) {
		this.props.onCompleted(data.session || data);

		window.toaster.showToast({
			content: "markAsDelivered_success",
			style: E_ToastStyle.SUCCESS,
			icon: "check"
		});

		window.modal.close();
	}

	static get propTypes() {
		return {
			className: PropTypes.string,
			style: PropTypes.object,
			children: PropTypes.any,

			pin: PropTypes.string.isRequired,
			session: PropTypes.object.isRequired,

			onCompleted: PropTypes.func.isRequired,
		}
	}

	static get stateTypes() {
		return {}
	}

	static get defaultProps() {
		return {}
	}
}

export default DeliveryPageMarkAsDelivered;
