import { service_deleteBlock, service_insertBlock, service_updateBlock } from "../../services/Service_Locations";
import { Action_Block_Send, Action_Block_Send_Fail, Action_Block_Send_Success } from "../actions/location/blockActions";
import { Selector_Location_Item_Root } from "../selectors/Selectors_Location";
import { E_Modification } from "../Constants_StoreShared";
import { Thunk_Generic_Remove } from "./Thunk_Generic";

export function Thunk_Block_Add(frameID, blockData, path) {
	return (dispatch, getState) => {
		if (Selector_Location_Item_Root(getState()).fetching) {return;} //Ignore if still fetching

		dispatch(Action_Block_Send());

		service_insertBlock(frameID, JSON.stringify(blockData)).then(data => {
			dispatch(Action_Block_Send_Success(E_Modification.ARRAY_PUSH, path.split(".").select(0, "blocks"), data)); //On success
		}, (error) => {
			dispatch(Action_Block_Send_Fail(error)); //On fail
		});
	}
}

export function Thunk_Block_Update(path, blockData) {
	return (dispatch, getState) => {
		if (Selector_Location_Item_Root(getState()).fetching) {return;} //Ignore if still fetching

		service_updateBlock(blockData.id, JSON.stringify(blockData)).then(data => {
			dispatch(Action_Block_Send_Success(E_Modification.ITEM_SET, path, data)); //On success
		}, (error) => {
			dispatch(Action_Block_Send_Fail(error)); //On fail
		});
	}
}

export const Thunk_Block_Remove = blockID => (
	Thunk_Generic_Remove(
		blockID,
		service_deleteBlock,
		Action_Block_Send,
		(data) => Action_Block_Send_Success(E_Modification.ARRAY_REMOVE_BY_ID, null, data.id),
		Action_Block_Send_Fail
	)
)
