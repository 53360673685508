import React from 'react';
import PropTypes from 'prop-types';
import {Translate} from "react-localize-redux";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {T_APP_ICONS} from "../../../../models/constants/Constants_Shared";
import {Selector_ExternalLocations_Listing_Root} from "../../../../store/selectors/Selectors_ExternalLocations";
import {Thunk_ExternalLocation_Insert, Thunk_ExternalLocations_Fetch_All} from "../../../../store/thunk/Thunk_ExternalLocations";
import {TranslateEntry} from "../../../../utils/localeUtils";
import CSBaseComponent from "../../../CSBaseComponent";
import ItemListing from '../../../shared/Listing/ItemListing';
import {NavLink} from 'react-router-dom';
import routes from '../../../../routes/routes';
import '../../../../styles/pages/Location/LocationList.scss';
import ListingPage from "../../../shared/Listing/ListingPage";
import TableListing from "../../../shared/Listing/TableListing";
import {formatAddressInOneLine} from "../../../../utils/utils";
import TableRow from "../../../shared/Listing/TableRow";
import TableColumn from "../../../shared/Listing/TableColumn";

class ExternalLocationsList extends CSBaseComponent {
	constructor(props) {
		super(props);

		this.state = {
			...super.state,
		};
	}

	_renderTemplate(location, i) {
		return (
			<NavLink key={i} to={`${routes.EXTERNAL_LOCATIONS}/${location.id}`} className="list-item fadeIn" available={location.availabilityState} exact={true}>
				<div className={"front"}>
					<label>{location.name || <Translate id={"location_unnamedLocation"} />}</label>
				</div>
				<div className={"back"}>
					<label>{location.name || <Translate id={"location_unnamedLocation"} />}</label>
					<TranslateEntry entry={location.description} />
				</div>
				<small>{location.id}</small>
			</NavLink>
		)
	}

	_renderTableTemplate(location, i, headerItems) {
		return (
			<TableRow key={i} href={`${routes.EXTERNAL_LOCATIONS}/${location.id}`} available={location.availabilityState} headerItems={headerItems}>
				<TableColumn column={"name"}>{location.name || <Translate id={"location_unnamedLocation"}/>}</TableColumn>
				<TableColumn column={"description"}><TranslateEntry entries={location.description}/></TableColumn>
				<TableColumn column={"address"}>{formatAddressInOneLine(location.address) || <Translate id={"value_notProvided"}/>}</TableColumn>
			</TableRow>
		)
	}

	render() {
		const {Thunk_ExternalLocations_Fetch_All} = this.props;

		return (
			<section className={"external-location-list"}>
				<ListingPage
					section={"locations"}
					titleIcon={T_APP_ICONS.EXTERNAL_LOCATIONS}
					titleText={"locations_external"}
					titleLeftContent={routes.EXTERNAL_LOCATIONS}
					subscriberFooter={true}
					subscriberThunk={Thunk_ExternalLocations_Fetch_All}
					subscriberRootSelector={Selector_ExternalLocations_Listing_Root}
					headerItems={{
						name: {},
						description: {
							field: "description.text"
						},
						address: {
							filter: false,
						},
					}}
					name={"external-locations"}
					views={[
						{
							view: "list",
							default: true,
							button: {
								icon: "th",
								tippy: <Translate id={"viewMode_list"} />
							},
							content: <ItemListing className={`list-wrapper`} itemTemplate={this._renderTemplate.bind(this)} />
						},
						{
							view: "table",
							button: {
								icon: "table",
								tippy: <Translate id={"viewMode_table"} />
							},
							content:
								<TableListing className={"stripes"} itemTemplate={this._renderTableTemplate.bind(this)} />
						}
					]}
				/>
			</section>
		);
	}
}

ExternalLocationsList.propTypes = {
	Thunk_ExternalLocations_Fetch_All: PropTypes.func.isRequired,
};

const mapDispatchToProps = dispatch => (
	bindActionCreators({
		Thunk_ExternalLocation_Insert,
		Thunk_ExternalLocations_Fetch_All
	}, dispatch)
);

export default connect(null, mapDispatchToProps)(ExternalLocationsList);
