import {service_deleteAuthorization, service_fetchAuthorization, service_fetchAuthorizations, service_insertAuthorization, service_updateAuthorization} from "../../services/Service_Authorizations";
import {Action_Authorization_Edit_Fulfilled, Action_Authorization_Edit_Pending, Action_Authorization_Edit_Rejected, Action_Authorization_Fetch_Fulfilled, Action_Authorization_Fetch_Pending, Action_Authorization_Fetch_Rejected, Action_Authorizations_Fetch_Fulfilled, Action_Authorizations_Fetch_Pending, Action_Authorizations_Fetch_Rejected} from "../actions/authorizations/authorizationsActions";
import {Selector_Authorization_Edit} from "../selectors/Selectors_Authorizations";
import {Thunk_Generic_Edit_Insert, Thunk_Generic_Edit_Save, Thunk_Generic_Edit_Update, Thunk_Generic_Fetch_All, Thunk_Generic_Fetch_Item, Thunk_Generic_Remove} from "./Thunk_Generic";

export const Thunk_Authorizations_Fetch_All = queryString => (
	Thunk_Generic_Fetch_All(
		queryString,
		service_fetchAuthorizations,
		Action_Authorizations_Fetch_Pending,
		Action_Authorizations_Fetch_Fulfilled,
		Action_Authorizations_Fetch_Rejected
	)
);

export const Thunk_Authorization_Insert = () => (
	Thunk_Generic_Edit_Insert(
		service_insertAuthorization,
		Action_Authorization_Edit_Pending,
		Action_Authorization_Edit_Fulfilled,
		Action_Authorization_Edit_Rejected,
		Selector_Authorization_Edit,
	)
);

export const Thunk_Authorization_Fetch = authorizationID => (
	Thunk_Generic_Fetch_Item(
		authorizationID,
		service_fetchAuthorization,
		Action_Authorization_Fetch_Pending,
		Action_Authorization_Fetch_Fulfilled,
		Action_Authorization_Fetch_Rejected,
	)
);

export const Thunk_Authorization_Update = () => (
	Thunk_Generic_Edit_Update(
		service_updateAuthorization,
		Action_Authorization_Edit_Pending,
		Action_Authorization_Edit_Fulfilled,
		Action_Authorization_Edit_Rejected,
		Selector_Authorization_Edit,
	)
);

export const Thunk_Authorization_Save = () => (
	Thunk_Generic_Edit_Save(
		Selector_Authorization_Edit,
		Action_Authorization_Edit_Pending,
		Thunk_Authorization_Insert,
		Thunk_Authorization_Update
	)
);

export const Thunk_Authorization_Remove = authorizationID => (
	Thunk_Generic_Remove(
		authorizationID,
		service_deleteAuthorization,
		Action_Authorization_Edit_Pending,
		Action_Authorization_Edit_Fulfilled,
		Action_Authorization_Edit_Rejected
	)
)
