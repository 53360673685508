import { service_deleteService, service_fetchService, service_insertService, service_updateService } from "../../services/Service_Service";
import { Action_Service_Edit_Fulfilled, Action_Service_Edit_Pending, Action_Service_Edit_Rejected, Action_Service_Fetch_Fulfilled, Action_Service_Fetch_Pending, Action_Service_Fetch_Rejected } from "../actions/location/serviceActions";
import { Selector_Service_Edit } from "../selectors/Selectors_LocationService";
import { Thunk_Generic_Edit_Insert, Thunk_Generic_Edit_Save, Thunk_Generic_Edit_Update, Thunk_Generic_Fetch_Item, Thunk_Generic_Remove } from "./Thunk_Generic";

export const Thunk_Service_Fetch = (serviceID) => (
	Thunk_Generic_Fetch_Item(
		serviceID,
		service_fetchService,
		Action_Service_Fetch_Pending,
		Action_Service_Fetch_Fulfilled,
		Action_Service_Fetch_Rejected
	)
);

export const Thunk_Service_Insert = (blockID) => (
	Thunk_Generic_Edit_Insert(
		(...props) => service_insertService(blockID, ...props),
		Action_Service_Edit_Pending,
		(data) => Action_Service_Edit_Fulfilled(data, {blockID}),
		Action_Service_Edit_Rejected,
		Selector_Service_Edit
	)
);

export const Thunk_Service_Update = () => (
	Thunk_Generic_Edit_Update(
		service_updateService,
		Action_Service_Edit_Pending,
		Action_Service_Edit_Fulfilled,
		Action_Service_Edit_Rejected,
		Selector_Service_Edit
	)
);

export const Thunk_Service_Save = (blockID) => (
	Thunk_Generic_Edit_Save(
		Selector_Service_Edit,
		Action_Service_Edit_Pending,
		() => Thunk_Service_Insert(blockID),
		Thunk_Service_Update
	)
);

export const Thunk_Service_Remove = (serviceID) => (
	Thunk_Generic_Remove(
		serviceID,
		service_deleteService,
		Action_Service_Edit_Pending,
		Action_Service_Edit_Fulfilled,
		Action_Service_Edit_Rejected
	)
);
