import { GENERIC_EDIT_FULFILLED, GENERIC_FETCH_FULFILLED } from "../actions/genericActions";
import genericItemReducer from "./genericItemReducer";

const initialState = {
	edit: {},
	fetching: false,
	fetched: false,
	failed: false,
	error: {},
	deleted: null
};

const genericReducer = (state, action) => genericItemReducer(`SETTINGS`, undefined, s => s.edit)(state, action);

const handleEditFulfilled = (state, action) => {
	let genericEditFulfilledData = genericReducer(state, action);
	return {
		...genericEditFulfilledData,
		edit: genericEditFulfilledData.data,
	};
};

export default (state = initialState, action) => {
	switch (action.type) {
		case `SETTINGS_${GENERIC_FETCH_FULFILLED}`:
			return {
				...state,
				edit: action.payload,
				fetching: false,
				fetched: true,
				failed: false
			};
		case `SETTINGS_${GENERIC_EDIT_FULFILLED}`:
			return handleEditFulfilled(state, action, genericReducer);
		default:
			return genericReducer(state, action);
	}
}
