import React, {Fragment} from "react";
import PropTypes from "prop-types";
import Dropzone from "react-dropzone";
import is from "../../../utils/is";
import "../../../styles/components/input.scss"
import {CONSOLE_WARN_BOLD} from "../../../models/constants/Constants_Shared";
import Fa from "../../tools/Icons/Fa";
import {Translate} from "react-localize-redux";
import {E_Modification} from "../../../store/Constants_StoreShared";
import DropzoneFileContainer from "./Dropzone/DropzoneFileContainer";
import {formatBytes, get} from "../../../utils/utils";
import {T_FileUploadDataModel} from "../../../models/Models_Service";
import {errorHandler} from "../../../store/ResponseHandling";
import {FILE_UPLOAD_REJECTED, FILE_UPLOAD_REJECTED_TOO_BIG} from "../../../store/actions/fileActions";

export const T_SharedDropzoneInputPropTypes = {
	files: PropTypes.array,
	onFileRemove: PropTypes.func,
	onFileErrorReUpload: PropTypes.func,
}

class DropzoneInput extends React.Component {
	constructor(props) {
		super(props);

		let files = this._processDefaultValue(props.defaultValue, props.multiple);

		this.state = {
			...super.state,
			files,
		};
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		const {onModify, path, multiple} = this.props;
		const {files} = this.state;

		if(!is.equal(prevProps.defaultValue, this.props.defaultValue)) {
			this.setState({files: this._processDefaultValue(this.props.defaultValue, this.props.multiple)});
			return;
		}

		if(!is.equal(prevState.files, files)) {
			if((files || []).every(file => !!file.response)) {
				if(multiple) {
					onModify(E_Modification.ITEM_SET, path, (files || []).map(file => file.response));
				} else {
					onModify(E_Modification.ITEM_SET, path, get(files, `0.response`, null));
				}
			}
		}
	}

	_renderDropzoneContent({getRootProps, getInputProps}) {
		const {wrapChildrenInDropzone, multiple, maxSize} = this.props;
		const {files} = this.state;

		if(!multiple && files.length >= 1) {
			return this._renderChildren();
		}

		return (
			<div {...getRootProps()} className={"dropzone-trigger"}>
				<div className={`dropzone-hint ${files.length > 0 ? '' : 'center'}`}>
					<p><Translate id={"dropzoneHint"}/></p>
					<p>
						{this._processAccept()}
						&nbsp;(<Translate id={"maximum_short"}/> {formatBytes(maxSize)})
					</p>
				</div>
				<Fa className={"icon"} icon={"file-upload"} />

				<input {...getInputProps()} />
				{wrapChildrenInDropzone && this._renderChildren()}
			</div>
		)
	}

	_renderChildren() {
		let {children} = this.props;
		const {files} = this.state;

		if(!Array.isArray(children)) {
			children = [children];
		}

		if(!is.empty(children)) {
			return children.map((child, i) => (
				<Fragment key={i}>
					{
						React.cloneElement(child, {
							files,
							onFileRemove: this._removeFile.bind(this),
							onFileErrorReUpload: this._uploadFile.bind(this),
						})
					}
				</Fragment>
			));
		}
	}

	render() {
		const {className, wrapChildrenInDropzone, style, accept, maxSize, multiple} = this.props;

		return (
			<div className={`input-wrapper dropzone ${className}`} style={style}>
				<Dropzone
					onDrop={files => {
						files = files.map(file => ({
							id: Math.round(Math.random() * 1000),
							blob: file,
							data: {},
						}));

						this.setState(state => ({
							files: [
								...(state.files || []),
								...files
							]
						}));

						files.forEach(file => {
							this._uploadFile(file);
						});
					}}
					accept={accept}
					maxSize={maxSize}
					multiple={multiple}
					onDropRejected={files => {
						files.forEach(file => {
							errorHandler({
								message: file.size > maxSize ? "File too big" : "Dropzone generic error response"
							}, file.size > maxSize ? FILE_UPLOAD_REJECTED_TOO_BIG : FILE_UPLOAD_REJECTED);
						})
					}}
				>
					{(dropzoneContext) => this._renderDropzoneContent(dropzoneContext)}
				</Dropzone>
				{!wrapChildrenInDropzone && this._renderChildren()}
			</div>
		)
	}

	_processAccept() {
		const {accept} = this.props;
		if(accept == undefined) {return null;}

		const __handlePart = (part) => {
			switch (part) {
				case "image/*":
					return <Translate id={"images"}/>;
			}
		};

		let parts = accept.split(",");
		return (
			<Fragment>(
				{
					parts.map((part, i) => <Fragment key={i}>{__handlePart(part)}</Fragment>)
				}
			)</Fragment>
		);
	}

	_processDefaultValue(defaultValue, multiple = true) {
		const {files} = this.state || {}; //State will be undefined when this function is called from the constructor

		const sharedData = {
			blob: null,
			data: {
				...T_FileUploadDataModel,
				uploaded: true,
			},
		};

		if(!multiple) {
			if(is.valid(defaultValue)) {
				return [{
					...sharedData,
					response: defaultValue,
				}];
			}
			return [];
		}

		let unchangedFiles = (files || []).filter(file => (defaultValue || []).findByID(get(file, `response.id`)));
		let filesToAdd = (defaultValue || []).subtract(unchangedFiles, (a, b) => a.id == get(b, `response.id`));
		return [
			...unchangedFiles,
			...filesToAdd.map(file => ({
				...sharedData,
				response: file,
			})),
		]
	}

	_uploadFile(file) {
		const {uploadService} = this.props;

		uploadService(
			file.blob,
			(_, data) => this._updateFile(file, data)
		).then(response => {
			this._updateFile(file, undefined, response ? JSON.parse(response) : undefined)
		}, error => {
			this._updateFile(file, {
				...file.data,
				error: true,
			});
		});
	}

	_updateFile(file, data, response) {
		this.setState(state => {
			let newFiles = [...state.files];
			let activeFile = newFiles.findByID(file.id) || {};
			newFiles.splice(newFiles.findByID(file.id, true), 1, {
				...activeFile,
				data: data === undefined ? activeFile.data : data,
				response,
			});
			return {
				files: newFiles
			}
		});
	}

	_removeFile(file) {
		const {onModify, path, multiple} = this.props;
		let {progress} = file.data || {};

		if(progress > 0 && progress < 0 && file.blob) {
			file.blob.abort = true;
		}

		if(multiple) {
			onModify(E_Modification.ARRAY_REMOVE_BY_ID, `${path}.#${file.response.id}`);
		} else {
			onModify(E_Modification.ITEM_SET, path, null);
		}
	}

	static get propTypes() {
		return {
			...super.propTypes,
			defaultValue: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
			onModify: PropTypes.func.isRequired,
			uploadService: PropTypes.func.isRequired,
			path: PropTypes.string.isRequired,
			wrapChildrenInDropzone: PropTypes.bool,
			/**
			 * @see https://github.com/react-dropzone/attr-accept
			 */
			accept: PropTypes.string,
			//In Bytes
			maxSize: PropTypes.number,
			multiple: PropTypes.bool,
		}
	}

	static get stateTypes() {
		return {
			...super.stateTypes,
			files: PropTypes.array,
		}
	}

	static get defaultProps() {
		return {
			...super.defaultProps,
			defaultValue: [],
			wrapChildrenInDropzone: true,
			uploadService: () => console.log("%c uploadService is not defined", CONSOLE_WARN_BOLD),
			accept: undefined,
			children: <DropzoneFileContainer/>,
			maxSize: 5000000,
			multiple: true,
		}
	}
}

export default DropzoneInput;
