import React from 'react';
import { Switch, Route } from 'react-router-dom';
import routes from '../../../routes/routes';
import PartnerDetail from "./PartnerDetail";
import PartnersList from "./PartnersList";

class PartnerBrowser extends React.Component {

	constructor(props) {
		super(props);
	}

	render() {
		return (
			<Switch>
				<Route exact path={routes.PARTNERS}>
					<PartnersList />
				</Route>
				<Route path={`${routes.PARTNERS}/:partnerID`} render={props => <PartnerDetail partnerID={parseInt(props.match.params.partnerID)} />} />
			</Switch>
		);
	}
}

export default PartnerBrowser;
