import React from "react";
// eslint-disable-next-line no-unused-vars
import PropTypes from "prop-types";
import TextInput from "../TextInput";
import Fa from "../../../tools/Icons/Fa";
import { T_APP_ICONS } from "../../../../models/constants/Constants_Shared";
import ItemSelectorInput from "../ItemSelectorInput";

class ItemSelectInputBase extends React.Component {
	render() {
		const {className, style, defaultValue, onLabel, canInvalidate, onModify, path} = this.props;

		return (
			<button
				className={`no-style ${className}`}
				style={style}
				onClick={() => this._openSelect()}
			>
				<TextInput
					onModify={onModify}
					path={path}
					defaultValue={onLabel(defaultValue)}
					suffix={<Fa icon={T_APP_ICONS.EDIT_DIALOG}/>}
					canInvalidate={canInvalidate}
				/>
			</button>
		)
	}

	_openSelect() {
		const {modalContent} = this.props;
		let modalContentProps = {...this.props}
		delete modalContentProps.className;
		delete modalContentProps.style;
		delete modalContentProps.modalContent;

		window.modal.open({
			body: React.cloneElement(modalContent, modalContentProps),
		});
	}

	static get propTypes() {
		return {
			...super.propTypes,
			...ItemSelectorInput.propTypes,
			modalContent: PropTypes.any,
		}
	}

	static get defaultProps() {
		return {
			...super.defaultProps,
			...ItemSelectorInput.defaultProps,
			modalContent: null
		}
	}
}

export default ItemSelectInputBase;
