import React, { Fragment } from "react";
// eslint-disable-next-line no-unused-vars
import PropTypes from "prop-types";
import Fa from "../../../../../tools/Icons/Fa";
import { Translate } from "react-localize-redux";
import { combineClasses, get, sortByID } from "../../../../../../utils/utils";
import "../../../../../../styles/pages/Location/StorageUnit/StorageServiceActionsDetail.scss";
import { T_APP_ICONS } from "../../../../../../models/constants/Constants_Shared";
import { TranslateEntry } from "../../../../../../utils/localeUtils";
import ButtonsConstructor from "../../../../../shared/ButtonsConstructor";
import StorageServiceActionEditModal from "./StorageServiceActionEditModal";
import { E_Modification } from "../../../../../../store/Constants_StoreShared";
import { T_ServiceActionModel } from "../../../../../../models/Models_StorageService";
import ServerImage from "../../../../../shared/Image/ServerImage";
import { T_RestrictionPresets } from "../../../../../../models/Models_Restrictions";

class StorageServiceActionsDetail extends React.Component {
	_renderActionDetailsWrapper(action, i, body) {
		const {isEdit} = this.props;
		const sharedProps = {
			className: "action-details grid-table grid-icon-auto-1fr",
		}

		if(isEdit) {
			return (
				<button
					{...sharedProps}
					onClick={() => isEdit ? this._editAction(action, i) : this._executeAction(action)}
					style={{textAlign: "left"}}
				>
					{body}
				</button>
			)
		}

		return (
			<div {...sharedProps}>
				{body}
			</div>
		);
	}

	_renderAction(action, i) {
		const {isEdit} = this.props;

		return (
			<div className={combineClasses("action-wrapper", action.primary && "primary",)}>
				<div className={"primary-tag"}>
					<b style={{justifyContent: "flex-end"}}><Translate id={"primary"}/></b>
				</div>
				<div className={combineClasses(
					"action",
					action.primary && "primary",
					!action.enabled && "disabled",
					isEdit && "edit",
				)}>
					<ServerImage imageID={get(action, "icon.id")} thumbnail={true}/>
					{
						this._renderActionDetailsWrapper(
							action,
							i,
							<Fragment>
								<span/>
								<label><Translate id={"name"}/></label>
								<span>
									<TranslateEntry entry={action.displayName} fallback={'-'} />
								</span>

								<Fa icon={"project-diagram"} />
								<label><Translate id={"outputChannel"} /></label>
								<span>{get(action, `outputChannel`, <Translate id={"value_notProvided"} />)}</span>

								<Fa icon={"microchip"} />
								<label><Translate id={"communicationUnit"} /></label>
								<span>{get(action, `communicationUnit.name`, <Translate id={"value_notProvided"} />)}</span>
							</Fragment>
						)
					}

					<ButtonsConstructor
						wrapInNav={true}
						className={"action-buttons"}
						buttons={[
							{
								icon: "star",
								tippy: <Translate id={"setAsPrimary"}/>,
								action: () => this._setActionAsPrimary(action, i),
								disabled: action.primary,
								restricted: !isEdit,
							},
							{
								icon: T_APP_ICONS.REMOVE,
								tippy: <Translate id={"remove"}/>,
								className: "remove-button",
								action: () => this._deleteAction(action, i),
								restricted: !isEdit,
							},
							{
								icon: "unlock-alt",
								tippy: <Translate id={"unlock"}/>,
								action: () => this._executeAction(action, i),
								restricted: isEdit ? true : T_RestrictionPresets.LOCK_CONTROL,
								confirm: <span><Fa icon={"unlock-alt"} /> <Translate id={"unlock"} /></span>,
								disabled: !action.communicationUnit,
							}
						]}
					/>
				</div>
			</div>
		);
	}

	render() {
		const {className, style, actions, isEdit} = this.props;

		return (
			<section className={combineClasses("service-actions", className)} style={style}>
				{
					actions.sort(sortByID).map((action, i) => (
						<Fragment key={i}>
							{this._renderAction(action, i)}
						</Fragment>
					))
				}

				{
					isEdit &&
					<button className={"fancy add-action"} onClick={() => this._addAction()} style={{border: "none"}}>
						<Fa icon={T_APP_ICONS.ADD}/>
						<span><Translate id={"add"}/></span>
					</button>
				}
			</section>
		);
	}

	_isEditSwitch(a, b) {
		const {isEdit} = this.props;
		return isEdit ? b : a;
	}

	_addAction() {
		const {onModify, actions} = this.props;
		onModify(E_Modification.ARRAY_PUSH, `actions`, actions.length > 0 ? T_ServiceActionModel : {...T_ServiceActionModel, primary: true});
	}

	_editAction(action, i) {
		const {onModify, communicationUnits} = this.props;

		window.modal.open({
			body: (
				<StorageServiceActionEditModal
					onModify={onModify}
					communicationUnits={communicationUnits}
					path={`actions.${i}`}
				/>
			)
		})
	}

	_setActionAsPrimary(action, i) {
		const {onModify, actions} = this.props;
		onModify(
			E_Modification.ITEM_SET,
			`actions`,
			actions.map((action, ai) => ({
				...action,
				primary: ai == i,
			}))
		);
	}

	_deleteAction(action, i) {
		const {onModify} = this.props;
		onModify(E_Modification.ARRAY_SPLICE, `actions`, [i, 1]);
	}

	_executeAction(action) {
		const {onExecuteAction} = this.props;

		onExecuteAction(action.id);
	}

	static get propTypes() {
		return {
			...super.propTypes,
			actions: PropTypes.array,
			isEdit: PropTypes.bool,
			onModify: PropTypes.func.isRequired,
			communicationUnits: PropTypes.array,

			onExecuteAction: PropTypes.func,
		}
	}

	static get defaultProps() {
		return {
			...super.defaultProps,
			actions: [],
			isEdit: false,
			onModify: (type, path, value) => null,
			communicationUnits: [],
			onExecuteAction: (actionID) => null,
		}
	}
}

export default StorageServiceActionsDetail;
