import { E_Currency } from "./constants/Constants_Shared";

export const E_VoucherType = {
	PERCENT_DISCOUNT: "PERCENT_DISCOUNT",
	CURRENCY_AMOUNT: "CURRENCY_AMOUNT"
};

/**
 * Voucher Model
 * ---
 * @type {{
 *          amountLeft: Number,
 *          discountPercent: Number,
 *          serviceRestrictions: null[],
 *          partnerLimit: {id: Number, name: String},
 *          currency: E_Currency,
 *          id: Number,
 *          usesLeft: Number,
 *          type: E_VoucherType,
 *          user: {email: String, forename: String, id: Number, surname: String},
 *          voucherCode: String
 *       }}
 */
export const T_VoucherModel = {
	amountLeft: 0,
	currency: E_Currency.CZK,
	discountPercent: 0,
	id: null,
	partnerLimit: null,
	serviceRestrictions: [],
	type: E_VoucherType.PERCENT_DISCOUNT,
	user: null,
	usesLeft: 0,
	voucherCode: null,
};
