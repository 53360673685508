import React, {Fragment} from "react";
import PropTypes from "prop-types";
import {withRouter} from "react-router-dom";
import routes from "../../../../routes/routes";
import ButtonsConstructor from "../../../shared/ButtonsConstructor";
import Fa from "../../../tools/Icons/Fa";
import {formatQueryString} from "../../../../services/apiServices/apiConnector";
import {Process_formatQueryString} from "../../../../utils/processors";
import {E_Visibility} from "../../../../models/Models_Location";
import {T_ServiceIcons} from "../../../../models/Models_StorageBox";
import {T_APP_ICONS} from "../../../../models/constants/Constants_Shared";

class StorageService extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			...this.state,

		};
	}

	_renderServiceContent() {
		const {service} = this.props;

		if(!service.id) {
			return <Fragment>
				<Fa icon={"times"} />
			</Fragment>
		}

		return (
			<span>
				{service.physicalReference}
			</span>
		);
	}

	render() {
		const {className, style, service, customProps, onServiceClick} = this.props;
		const {isEdit} = customProps;
		let icon = T_ServiceIcons[service.serviceType] || customProps.icon;

		let buttonProps = {
			text: (
				<Fragment>
					<div>
						{icon && <Fa icon={icon} />}
						{this._renderServiceContent()}
					</div>
					{
						service.id !== undefined &&
						<span className={"state-icons"}>
							{service.occupied && <Fa className={"occupied"} icon={"user-lock"} />}
							{service.visibilityType == E_Visibility.HIDDEN && <Fa icon={"low-vision"} />}
							{service.visibilityType == E_Visibility.AUTHORIZED_ONLY && <Fa icon={T_APP_ICONS.AUTHORIZED_ONLY} />}
							{!service.enabled && <Fa icon={"power-off"} className={"cl-error"} style={{opacity: 0.8}} />}
						</span>
					}
				</Fragment>
			),
			className: `storage-service ${className || ''}`,
			style,
			href: this._getAHref(),
			action: e => {
				e.stopPropagation();
				onServiceClick(e);
			},
			disabled: !service.id && !isEdit && !service.accessible,
			exact: true,
			props: {
				enabled: `${service.enabled}`,
				empty: `${!service.id}`,
				"service-id": `${service.id}`
			}
		}

		buttonProps.className = (buttonProps.className || '') + (icon ? " has-icon" : '');

		return (
			<ButtonsConstructor buttons={[buttonProps]} />
		)
	}

	_getAHref() {
		const {service, match, customProps} = this.props;
		const {locationID, unitID} = match.params;
		const {isEdit, blockID} = customProps;

		if(isEdit && !service.id) {
			return `${routes.LOCATIONS}/${locationID}/${unitID}/0${formatQueryString(Process_formatQueryString({
				position: service.orderPosition,
				block: blockID,
			}))}`;
		}

		if(!service.accessible) return;

		return `${routes.LOCATIONS}/${locationID}/${unitID}/${service.id}`;
	}

	static get propTypes() {
		return {
			...super.propTypes,
			service: PropTypes.object,
		}
	}

	static get stateTypes() {
		return {
			...super.stateTypes,
		}
	}

	static get defaultProps() {
		return {
			...super.defaultProps,
			service: {},
		}
	}
}

export default withRouter(StorageService);
