import React, {Fragment} from "react";
import PropTypes from "prop-types";
import {Translate} from "react-localize-redux";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import {bindActionCreators} from "redux";
import {T_SharedReduxPropTypes} from "../../../models/Models_Shared";
import {T_BusinessDetails, T_PartnerModel} from "../../../models/Models_Partner";
import routes from "../../../routes/routes";
import {Action_Partner_Clear, Action_Partner_Edit_Cancel, Action_Partner_Edit_Init, Action_Partner_Edit_Modify} from "../../../store/actions/partners/partnersActions";
import {Selector_Partner_Data, Selector_Partner_Edit, Selector_Partner_Root} from "../../../store/selectors/Selectors_Partners";
import {Thunk_Partner_Fetch, Thunk_Partner_Remove, Thunk_Partner_Save, Thunk_Partners_Fetch_All} from "../../../store/thunk/Thunk_Partners";
import is from "../../../utils/is";
import {css, get, resolveItemName} from "../../../utils/utils";
import TextareaInput from "../../shared/Input/TextareaInput";
import TextInput from "../../shared/Input/TextInput";
import Fa from "../../tools/Icons/Fa";
import "../../../styles/pages/Partner/PartnerDetail.scss";
import Toolbar from "../../shared/Toolbar";
import DetailWrapper from "../../shared/DetailWrapper";
import SettingsTermsAndConditions from "../Settings/SettingsTermsAndConditions";
import {service_fetchPartnerTermsAndConditions, service_updatePartnerTermsAndConditions} from "../../../services/Service_Partners";
import PartnerSelectInput from "./PartnerSelectInput";
import Restricted from "../../shared/Restrictions/Restricted";
import {E_UserRoles} from "../../../models/constants/Constants_Shared";
import ItemRedirect from "../../shared/ItemRedirect";

class PartnerDetail extends React.Component {
	constructor(props) {
		super(props);

		this.state = {};
	}

	componentDidMount() {
		const {partnerID, Thunk_Partner_Fetch, Action_Partner_Edit_Init} = this.props;

		if (partnerID) {
			Thunk_Partner_Fetch(partnerID);
		} else {
			Action_Partner_Edit_Init(T_PartnerModel);
		}
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		const {partnerID, Thunk_Partner_Fetch} = this.props;

		if(partnerID && prevProps.partnerID !== partnerID) {
			Thunk_Partner_Fetch(partnerID);
		}
	}

	componentWillUnmount() {
		const {Action_Partner_Clear} = this.props;

		Action_Partner_Clear();
	}

	_renderToolbar() {
		const {edit, Action_Partner_Edit_Cancel, Thunk_Partner_Remove, partnerID, fetching, failed, Action_Partner_Edit_Init, Thunk_Partner_Save} = this.props;
		const isEdit = is.valid(edit);

		return <Toolbar
			section={"partners"}
			isEdit={isEdit}
			backUrl={routes.PARTNERS}
			content={<h1><Translate id={partnerID ? (isEdit ? "partner_edit" : "partner_detail") : "partner_create"}/></h1>}
			onEditCancel={() => Action_Partner_Edit_Cancel()}
			onEdit={() => Action_Partner_Edit_Init()}
			onSave={() => Thunk_Partner_Save()}
			onRemove={() => Thunk_Partner_Remove(partnerID)}
			disableActionButtons={fetching || failed}
			itemID={partnerID}
		/>;
	}

	_renderBusinessDetails(partner) {
		const businessDetails = get(partner, `businessDetails`, T_BusinessDetails);
		const {Action_Partner_Edit_Modify} = this.props;

		return (
			<Fragment>
				<div className={"separator"}><label><Fa icon={"phone-office"} custom={true} /><Translate id={"businessDetails"} /></label></div>

				<div className={"grid-table grid-icon-auto-1fr"}>
					<span><Fa icon={"globe-europe"} /></span>
					<label><Translate id={"address_country"} /></label>
					{this._isEditSwitch(
						<span>{businessDetails.country || <Translate id={"value_notProvided"} />}</span>,
						<TextInput
							onModify={Action_Partner_Edit_Modify}
							path={`businessDetails.country`}
							defaultValue={businessDetails.country}
						/>
					)}

					<span><Fa icon={"city"} /></span>
					<label><Translate id={"address_city"} /></label>
					{this._isEditSwitch(
						<span>{businessDetails.city || <Translate id={"value_notProvided"} />}</span>,
						<TextInput
							onModify={Action_Partner_Edit_Modify}
							path={`businessDetails.city`}
							defaultValue={businessDetails.city}
						/>
					)}

					<span><Fa icon={"mail-bulk"} /></span>
					<label><Translate id={"address_postalCode"} /></label>
					{this._isEditSwitch(
						<span>{businessDetails.postalCode || <Translate id={"value_notProvided"} />}</span>,
						<TextInput
							onModify={Action_Partner_Edit_Modify}
							path={`businessDetails.postalCode`}
							defaultValue={businessDetails.postalCode}
						/>
					)}

					<span><Fa icon={"road"} /></span>
					<label><Translate id={"address_street"} /></label>
					{this._isEditSwitch(
						<span>{businessDetails.street || <Translate id={"value_notProvided"} />}</span>,
						<TextInput
							onModify={Action_Partner_Edit_Modify}
							path={`businessDetails.street`}
							defaultValue={businessDetails.street}
						/>
					)}

					<span><Fa icon={"home"} /></span>
					<label><Translate id={"address_houseNumber"} /></label>
					{this._isEditSwitch(
						<span>{businessDetails.houseNumber || <Translate id={"value_notProvided"} />}</span>,
						<TextInput
							onModify={Action_Partner_Edit_Modify}
							path={`businessDetails.houseNumber`}
							defaultValue={businessDetails.houseNumber}
						/>
					)}

					<span><Fa icon={"layer-group"} /></span>
					<label><Translate id={"address_floor"} /></label>
					{this._isEditSwitch(
						<span>{businessDetails.floor || <Translate id={"value_notProvided"} />}</span>,
						<TextInput
							onModify={Action_Partner_Edit_Modify}
							path={`businessDetails.floor`}
							defaultValue={businessDetails.floor}
							behavior={{clamp: {min: 0}}}
						/>
					)}

					<span style={{height: 20}} />
					<span />
					<span />

					<span><Fa icon={"mobile-alt"} /></span>
					<label><Translate id={"phoneNumber"} /></label>
					{this._isEditSwitch(
						<span>{businessDetails.cellPhone || <Translate id={"value_notProvided"} />}</span>,
						<TextInput
							onModify={Action_Partner_Edit_Modify}
							path={`businessDetails.cellPhone`}
							defaultValue={businessDetails.cellPhone}
						/>
					)}

					<span><Fa icon={"trademark"} /></span>
					<label><Translate id={"companyName"} /></label>
					{this._isEditSwitch(
						<span>{businessDetails.companyName || <Translate id={"value_notProvided"} />}</span>,
						<TextInput
							onModify={Action_Partner_Edit_Modify}
							path={`businessDetails.companyName`}
							defaultValue={businessDetails.companyName}
						/>
					)}

					<span><Fa icon={"registered"} /></span>
					<label><Translate id={"companyRegistrationNumber"} /></label>
					{this._isEditSwitch(
						<span>{businessDetails.companyRegistrationNumber || <Translate id={"value_notProvided"} />}</span>,
						<TextInput
							onModify={Action_Partner_Edit_Modify}
							path={`businessDetails.companyRegistrationNumber`}
							defaultValue={businessDetails.companyRegistrationNumber}
						/>
					)}

					<span><Fa icon={"at"} /></span>
					<label><Translate id={"contactEmail"} /></label>
					{this._isEditSwitch(
						<span>{businessDetails.contactEmail || <Translate id={"value_notProvided"} />}</span>,
						<TextInput
							onModify={Action_Partner_Edit_Modify}
							path={`businessDetails.contactEmail`}
							defaultValue={businessDetails.contactEmail}
						/>
					)}

					<span><Fa icon={"phone-office"} custom={true} /></span>
					<label><Translate id={"landLine"} /></label>
					{this._isEditSwitch(
						<span>{businessDetails.landLine || <Translate id={"value_notProvided"} />}</span>,
						<TextInput
							onModify={Action_Partner_Edit_Modify}
							path={`businessDetails.landLine`}
							defaultValue={businessDetails.landLine}
						/>
					)}

					<span />
					<label><Translate id={"taxIDNumber"} /></label>
					{this._isEditSwitch(
						<span>{businessDetails.taxIDNumber || <Translate id={"value_notProvided"} />}</span>,
						<TextInput
							onModify={Action_Partner_Edit_Modify}
							path={`businessDetails.taxIDNumber`}
							defaultValue={businessDetails.taxIDNumber}
						/>
					)}

					<span><Fa icon={"link"} /></span>
					<label><Translate id={"address_url"} /></label>
					{this._isEditSwitch(
						<span>{businessDetails.webUrl || <Translate id={"value_notProvided"} />}</span>,
						<TextInput
							onModify={Action_Partner_Edit_Modify}
							path={`businessDetails.webUrl`}
							defaultValue={businessDetails.webUrl}
						/>
					)}

					<span />
					<label style={css("height: 100%")}><Translate id={"companyAdditionalInfo"} /></label>
					{this._isEditSwitch(
						<pre>{businessDetails.companyAdditionalInfo || <Translate id={"value_notProvided"} />}</pre>,
						<TextareaInput
							onModify={Action_Partner_Edit_Modify}
							path={`businessDetails.companyAdditionalInfo`}
							defaultValue={businessDetails.companyAdditionalInfo}
							style={css("min-height: 200px")}
						/>
					)}
				</div>
			</Fragment>
		);
	}

	_renderTermsAndConditions(partner) {
		return (
			<div>
				<div className={"separator"}>
					<label><Translate id={"termsAndConditions"}/></label>
				</div>

				<SettingsTermsAndConditions
					fetchService={() => service_fetchPartnerTermsAndConditions(partner.id)}
					uploadService={service_updatePartnerTermsAndConditions(partner.id)}
					suppressNotFound={true}
					saveHint={"termsAndConditions_partnerSaveHint"}
				/>
			</div>
		)
	}

	_renderPartnerData(partner) {
		const {Action_Partner_Edit_Modify, edit} = this.props;
		const isEdit = is.valid(edit);

		return (
			<div>
				<div className={"grid-table grid-icon-auto-1fr"}>
					<span />
					<label><Translate id={"name"} /></label>
					{this._isEditSwitch(
						<span>{partner.name}</span>,
						<TextInput
							onModify={Action_Partner_Edit_Modify}
							path={`name`}
							defaultValue={partner.name}
						/>
					)}

					{
						this._isEditSwitch(
							partner.parent &&
							<>
								<span />
								<label><Translate id={"parentPartner"} /></label>
								<span>
									<ItemRedirect href={`${routes.PARTNERS}/${partner.parent.id}`} wrapChildren={true}>
										{resolveItemName(partner.parent)}
									</ItemRedirect>
								</span>
							</>,
							<>
								<span />
								<label><Translate id={"parentPartner"} /></label>
								<Restricted role={E_UserRoles.ADMIN} noPartner={true}>
									<PartnerSelectInput
										path={`parent`}
										canInvalidate={true}
										onModify={Action_Partner_Edit_Modify}
										defaultValue={partner.parent}
										onRestrict={option => option.id != partner.id && !partner.children?.findByID?.(option.id)}
									/>
								</Restricted>
							</>
						)
					}

					{this._isEditSwitch(
						partner.children?.length &&
						<>
							<span/>
							<label><Translate id={"childPartners"} /></label>
							<div>
								{
									partner.children.map(child => {
										return (
											<div key={child.id}>
												<ItemRedirect href={`${routes.PARTNERS}/${child.id}`} wrapChildren={true}>
													{resolveItemName(child)}
												</ItemRedirect>
											</div>
										)
									})
								}
							</div>
						</>
						||
						null
					)}
				</div>
				{this._renderBusinessDetails(partner)}
				{this._renderTermsAndConditions(partner)}
			</div>
		)
	}

	_renderPartner(partner) {
		return (
			<Fragment>
				{this._renderToolbar()}
				<div className={"content-wrapper"}>
					{this._renderPartnerData(partner)}
				</div>
			</Fragment>
		)
	}

	render() {
		const {partner, edit, partnerID} = this.props;

		return (
			<DetailWrapper
				itemID={partnerID}
				dataID={(partner || {}).id}
				className={"partner-detail"}
				isEdit={is.valid(edit)}
				listUrl={routes.PARTNERS}
				errorContent={"partner_fetch_failed"}
				selector={Selector_Partner_Root}
			>
				{this._renderPartner((is.valid(edit) ? edit : partner) || T_PartnerModel)}
			</DetailWrapper>
		);
	}

	_isEditSwitch(normal, edit) {
		return is.valid(this.props.edit) ? edit : normal;
	}
}

PartnerDetail.propTypes = {
	...T_SharedReduxPropTypes,

	partnerID: PropTypes.number,
	partner: PropTypes.object,
	edit: PropTypes.object,

	Thunk_Partner_Fetch: PropTypes.func.isRequired,
	Action_Partner_Edit_Cancel: PropTypes.func.isRequired,
	Action_Partner_Edit_Init: PropTypes.func.isRequired,
	Action_Partner_Edit_Modify: PropTypes.func.isRequired,
	Thunk_Partners_Fetch_All: PropTypes.func.isRequired,
	Thunk_Partner_Save: PropTypes.func.isRequired,
};

PartnerDetail.stateTypes = {};

PartnerDetail.defaultProps = {
	partner: T_PartnerModel,
};

const mapStateToProps = state => {
	const {fetched, fetching, failed} = Selector_Partner_Root(state);
	const partner = Selector_Partner_Data(state);
	const edit = Selector_Partner_Edit(state);

	return {fetched, fetching, failed, partner, edit}
};

const mapDispatchToProps = dispatch => (
	bindActionCreators({
		Action_Partner_Edit_Init,
		Thunk_Partner_Fetch,
		Action_Partner_Edit_Cancel,
		Action_Partner_Edit_Modify,
		Thunk_Partners_Fetch_All,
		Thunk_Partner_Remove,
		Thunk_Partner_Save,
		Action_Partner_Clear
	}, dispatch)
);

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(PartnerDetail));
