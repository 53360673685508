import React from "react";
import PropTypes from "prop-types";
import { Translate, withLocalize } from "react-localize-redux";
import is from "../../../utils/is";
import Fa from "../../tools/Icons/Fa";
import { resolveDynamicComponent } from "../../../utils/utils";
import { T_SharedPropTypes } from "../../../models/Models_Shared";
import ButtonsConstructor from "../ButtonsConstructor";
import "../../../styles/components/ErrorBlock.scss";

class ErrorBlock extends React.Component {
	constructor(props) {
		super(props);
	}

	render() {
		const {title, message, retry, icon, className, style, actionButtons} = this.props;

		return (
			<section className={`error-block ${className}`} role="alert" style={style}>
				<div>
					{(is.string(icon) || !icon) ? <Fa icon={icon || `exclamation-circle`} style={{fontSize: `2em`}} /> : icon}
					<span className="alert-title">
						{resolveDynamicComponent(title, <Translate id={title || "Error_GeneralUnexpectedError"} />) || <Translate id={"Error_GeneralUnexpectedError"}/>}
					</span>
					<p className="alert-message">
						{resolveDynamicComponent(message, <Translate id={message}/>)}
					</p>
					<ButtonsConstructor
						wrapInNav={true}
						buttons={[
							retry !== undefined &&
							(
								is.function(retry) && {
									icon: "redo-alt",
									text: "retry",
									action: retry,
								}
								||
								retry
							),
							...actionButtons
						]}
					/>
				</div>
			</section>
		)
	}

	static get propTypes() {
		return {
			...super.propTypes,
			...T_SharedPropTypes,
			icon: PropTypes.any,
			title: PropTypes.any,
			message: PropTypes.any.isRequired,
			retry: PropTypes.func,
			actionButtons: PropTypes.array
		}
	}

	static get stateTypes() {
		return {
			...super.stateTypes,
		}
	}

	static get defaultProps() {
		return {
			...super.defaultProps,
			message: '',
			actionButtons: []
		}
	}
}

export default withLocalize(ErrorBlock);
