import React from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";

class RouterListener extends React.Component {
	render() {
		return null;
	}

	componentDidMount() {
		const {history, onHistoryChange} = this.props;
		onHistoryChange(history);
	}

	static get propTypes() {
		return {
			...super.propTypes,
			onHistoryChange: PropTypes.func.isRequired,
		}
	}
}

export default withRouter(RouterListener);
