import Tippy from "../shared/Tippy";
import React from "react";
import PropTypes from "prop-types";
import is from "../../utils/is";
import { IdleManager } from "../../utils/utils";
import CSBaseComponent from "../CSBaseComponent";
import Fa from "./Icons/Fa";

class OverflowMenu extends CSBaseComponent {
	constructor(props) {
		super(props);

		this._handleResize = this._handleResize.bind(this);
	}

	componentDidMount() {
		super.componentDidMount();

		window.addEventListener("resize", this._handleResize);
		this.setState(this.state); //Force re-render
		this.idle = new IdleManager(this._handleSort.bind(this), 500);
	}

	componentWillUnmount() {
		super.componentWillUnmount();

		window.removeEventListener("resize", this._handleResize);
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		this.idle.forceTrigger();
	}

	render() {
		const {className, children, prefix, suffix} = this.props;

		return (
			<section className={`overflow-menu ${className}`}>
				{prefix}
				<nav name={"nav"} ref={this.setRef}>
					{children}
				</nav>
				<Tippy
					appendTo={document.querySelector("body")}
					trigger={"click"}
					interactive={true}
					theme={"dropdown"}
					className={"unit-select-dropdown dark"}
					placement={"top-end"}
					duration={100}
					offset={0}
					onCreate={instance => this.tippyInstance = instance}
					content={
						<div className={"overflow-container"}>
							<nav name={"overflow"} ref={this.setRef} onClick={() => {this.tippyInstance.hide()}}>
								{children}
							</nav>
						</div>
					}
				>
					<a id={"more"} style={{display: "none"}} name={"more"} ref={this.setRef}>
						<Fa icon={"ellipsis-v"} />
					</a>
				</Tippy>
				{suffix}
			</section>
		)
	}

	_handleResize() {
		this.idle.forceTrigger();
	}

	_handleSort() {
		this.tippyInstance.hide();
		const __isOverflowing = item => item.offsetTop > 0 && item.style.display != "none";

		window.requestAnimationFrame(() => {
			let children = [...this.nav.children];
			let overflowItems = [];

			children.forEach(child => child.removeAttribute("style"));

			children.forEach(child => {
				if (__isOverflowing(child)) {
					overflowItems.push(child.href);
					child.style.display = "none";
				}
			});

			if (is.valid(overflowItems)) {
				this.more.removeAttribute("style");
				let overflowChildren = [...this.overflow.children];
				overflowChildren.forEach(child => {
					child.style.display = overflowItems.includes(child.href) ? "flex" : "none";
					if (overflowItems.includes(child.href) && child.classList.contains("active")) {
						this.more.style.background = "var(--tertiary-theme-color)";
					}
				});
			} else {
				this.more.style.display = "none";
			}
		})
	}
}

OverflowMenu.propTypes = {
	className: PropTypes.string,
	children: PropTypes.any,
	prefix: PropTypes.any,
	suffix: PropTypes.any,
};

OverflowMenu.stateTypes = {};

export default OverflowMenu;
