import { Action_Generic_Edit_Modify, Action_Generic_Fail, Action_Generic_Type } from "../genericActions";

// EDIT BLOCK ACTION NAMES
export const EDIT_BLOCK_PENDING = 'EDIT_BLOCK_PENDING';
export const EDIT_BLOCK_FULFILLED = 'EDIT_BLOCK_FULFILLED';
export const EDIT_BLOCK_REJECTED = 'EDIT_BLOCK_REJECTED';

export const Action_Block_Send = () => Action_Generic_Type(EDIT_BLOCK_PENDING);
export const Action_Block_Send_Success = (modificationType, path, modification, ...props) => (
	Action_Generic_Edit_Modify(EDIT_BLOCK_FULFILLED, modificationType, path, modification, ...props)
);
export const Action_Block_Send_Fail = (error, ...props) => Action_Generic_Fail(EDIT_BLOCK_REJECTED, error, ...props);
