export const MODIFICATION_ARRAY_PUSH = 'MODIFICATION_ARRAY_PUSH';
export const MODIFICATION_ARRAY_SPLICE = 'MODIFICATION_ARRAY_SPLICE';
export const MODIFICATION_ARRAY_REMOVE_ITEM = 'MODIFICATION_ARRAY_REMOVE_ITEM';
export const MODIFICATION_ITEM_SET = 'MODIFICATION_ITEM_SET';
export const MODIFICATION_ITEM_REMOVE = 'MODIFICATION_ITEM_REMOVE';
export const ARRAY_REMOVE_BY_ID = 'ARRAY_REMOVE_BY_ID';

export const E_Modification = {
	ARRAY_PUSH: MODIFICATION_ARRAY_PUSH,
	ARRAY_SPLICE: MODIFICATION_ARRAY_SPLICE,
	ARRAY_CLEAR: "ARRAY_CLEAR",
	ARRAY_REMOVE_ITEM: MODIFICATION_ARRAY_REMOVE_ITEM,
	ARRAY_REMOVE_BY_ID: ARRAY_REMOVE_BY_ID,
	ITEM_SET: MODIFICATION_ITEM_SET,
	ITEM_REMOVE: MODIFICATION_ITEM_REMOVE
};
