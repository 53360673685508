import { service_deleteUser, service_fetchUser, service_fetchUsers, service_insertUser, service_updateUser } from "../../services/Service_Users";
import { Process_formatUserOnFetch, Process_formatUserOnSubmit } from "../../utils/processors";
import { Action_User_Edit_Fulfilled, Action_User_Edit_Pending, Action_User_Edit_Rejected, Action_User_Fetch_Fulfilled, Action_User_Fetch_Pending, Action_User_Fetch_Rejected, Action_Users_Fetch_Fulfilled, Action_Users_Fetch_Pending, Action_Users_Fetch_Rejected } from "../actions/users/usersActions";
import { Selector_User_Edit } from "../selectors/Selectors_Users";
import { Thunk_Generic_Edit_Insert, Thunk_Generic_Edit_Save, Thunk_Generic_Edit_Update, Thunk_Generic_Fetch_All, Thunk_Generic_Fetch_Item, Thunk_Generic_Remove } from "./Thunk_Generic";

export const Thunk_Users_Fetch_All = queryString => (
	Thunk_Generic_Fetch_All(
		queryString,
		service_fetchUsers,
		Action_Users_Fetch_Pending,
		Action_Users_Fetch_Fulfilled,
		Action_Users_Fetch_Rejected
	)
);

export const Thunk_User_Insert = () => (
	Thunk_Generic_Edit_Insert(
		service_insertUser,
		Action_User_Edit_Pending,
		Action_User_Edit_Fulfilled,
		Action_User_Edit_Rejected,
		Selector_User_Edit,
		Process_formatUserOnSubmit,
		Process_formatUserOnFetch
	)
);

export const Thunk_User_Fetch = userID => (
	Thunk_Generic_Fetch_Item(
		userID,
		service_fetchUser,
		Action_User_Fetch_Pending,
		Action_User_Fetch_Fulfilled,
		Action_User_Fetch_Rejected,
		Process_formatUserOnFetch
	)
);

export const Thunk_User_Update = () => (
	Thunk_Generic_Edit_Update(
		service_updateUser,
		Action_User_Edit_Pending,
		Action_User_Edit_Fulfilled,
		Action_User_Edit_Rejected,
		Selector_User_Edit,
		Process_formatUserOnSubmit,
		Process_formatUserOnFetch
	)
);

export const Thunk_User_Save = () => (
	Thunk_Generic_Edit_Save(
		Selector_User_Edit,
		Action_User_Edit_Pending,
		Thunk_User_Insert,
		Thunk_User_Update
	)
);

export const Thunk_User_Remove = userID => (
	Thunk_Generic_Remove(
		userID,
		service_deleteUser,
		Action_User_Edit_Pending,
		Action_User_Edit_Fulfilled,
		Action_User_Edit_Rejected
	)
)
