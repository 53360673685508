import React from "react";
import PropTypes from "prop-types";
import "../../../../styles/components/input.scss";
import is from "../../../../utils/is";
import InputBase from "./InputBase";
import { errorHandler } from "../../../../store/ResponseHandling";
import { E_APIServiceActions } from "../../../../models/constants/Constants_Shared";

class OptionsSelectorBase extends InputBase {
	constructor(props) {
		super(props);

		this.state = {
			...this.state,
			options: props.options,
		}
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		super.componentDidUpdate(prevProps, prevState, snapshot);

		const {options} = this.props;
		if(!is.equal(options, prevProps.options)){
			this.setState({options});
		}
	}

	fetchData() {
		const {selector, thunk, fetch} = this.props;

		if (fetch) {
			this.setState({pending: true});
			fetch().then(
				options => this._isMounted && this.setState({options}),
				error => {
					errorHandler(error, null, {action: {type: E_APIServiceActions.ITEMS_FETCH}});
					this.setState({error: true})
				}
			).finally(
				() => this._isMounted && this.setState({pending: false})
			);
			return;
		}

		if (!selector) {
			return;
		}

		window.Database.get(
			selector,
			thunk,
			() => this._isMounted && this.setState({pending: true})
		).then(
			data => this._isMounted && this.setState({options: Array.isArray(data) ? data : data.content})
		).finally(
			() => this._isMounted && this.setState({pending: false})
		);
	}

	selectOption(option) {
		this.applyChange(this.props.path, option);
	}

	selectOptionFromIndex(i) {
		const {options} = this.state;

		this.selectOption(options[i]);
	}

	getTranslate(value) {
		if (!value) {
			return '';
		}

		let onLabelValue = this.props.onLabel(value);

		if (is.string(onLabelValue)) {
			return window.translator.translate(
				onLabelValue,
				null,
				true
			).replace(new RegExp(/_/, 'g'), ' ');
		}
		return onLabelValue;
	}

	static get propTypes() {
		return {
			...super.propTypes,
			options: PropTypes.array.isRequired,
			fetch: PropTypes.func,
			selector: PropTypes.func,
			thunk: PropTypes.func,

			onLabel: PropTypes.func, // Label processor // What should be displayed
		}
	}

	static get stateTypes() {
		return {
			...super.stateTypes,
			options: PropTypes.array.isRequired,
		}
	}

	static get defaultProps() {
		return {
			...super.defaultProps,
			options: [],
			onLabel: value => value,
		}
	}
}

export default OptionsSelectorBase;
